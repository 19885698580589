import axios from 'axios'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { managersList } from '../constants/mangersList'
import { useAuth } from '../utils/auth'
import { addNotification } from './shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md mt-3 appearance-none relative block w-96 px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

export default function SignupForm() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [accountManagerEmail, setAccountManagerEmail] = useState('')
    const [roles, setRoles] = useState([])
    const [currency, setCurrency] = useState('')
    const auth = useAuth()

    const navigate = useNavigate()

    const handleChange = useCallback(
        (e) => {
            const isChecked = e.target.checked
            if (isChecked) {
                setRoles([...roles, e.target.value])
            } else {
                const index = roles.indexOf(e.target.value)
                roles.splice(index, 1)
                setRoles(roles)
            }
        },
        [roles]
    )

    const handleInputChange = (e) => {
        e.preventDefault()
        const { id, value } = e.target
        if (id === 'firstName') {
            e.preventDefault()
            setFirstName(value)
        }
        if (id === 'lastName') {
            e.preventDefault()
            setLastName(value)
        }
        if (id === 'email') {
            e.preventDefault()
            setEmail(value)
        }
        if (id === 'password') {
            e.preventDefault()
            setPassword(value)
        }
        if (id === 'companyName') {
            e.preventDefault()
            setCompanyName(value)
        }
        if (id === 'accountManagerEmail') {
            e.preventDefault()
            setAccountManagerEmail(value)
        }
    }
    const handleManagerChange = (e) => {
        setAccountManagerEmail(e.target.value)
    }
    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        createAccount()
    }
    const createAccount = () => {
        const token = auth.user?.token?.token
        axios
            .post(
                `${baseUrl}/user/register`,
                {
                    firstName,
                    lastName,
                    email,
                    password,
                    roles,
                    currency,
                    companyName,
                    accountManagerEmail,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then(() => {
                toast.success('User Created', { hideProgressBar: true })
                addNotification({
                    message: 'User Created',
                    type: 'success',
                })
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            })
    }
    const goBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <form className="flex m-3 items-center flex-col">
                <div className="form-body">
                    <div className="my-3">
                        <label className="font-medium">First name</label>
                        <input
                            className={fixedInputClass}
                            type="text"
                            value={firstName}
                            onChange={(e) => handleInputChange(e)}
                            id="firstName"
                            placeholder="First Name"
                        />
                    </div>
                    <div className="lastname">
                        <label className="font-medium">Last name</label>

                        <input
                            type="text"
                            name=""
                            id="lastName"
                            value={lastName}
                            className={fixedInputClass}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Last Name"
                        />
                    </div>
                    <div className="companyName my-3">
                        <label className="font-medium">Company</label>

                        <input
                            type="text"
                            name=""
                            id="companyName"
                            value={companyName}
                            className={fixedInputClass}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Company Name"
                        />
                    </div>
                    <div className="accountMangerEmail">
                        <label className="font-medium">Manager email</label>
                        <div className="mt-3">
                            <select
                                id="accountManagerEmail"
                                onChange={(e) => handleManagerChange(e)}
                                className="w-96 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-1.5"
                            >
                                <option value="" className="bg-b9green1">
                                    Manager Email
                                </option>
                                {managersList.map((list) => (
                                    <option key={list.email} value={list.email}>
                                        {list.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="my-3">
                        <label className="font-medium">Currency</label>
                        <div className="mt-3">
                            <select
                                id="currency"
                                onChange={(e) => {
                                    handleCurrencyChange(e)
                                }}
                                className="w-96  border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-1.5"
                            >
                                <option value="" className="bg-b9green1 ">
                                    Currency
                                </option>
                                <option value="EUR" label="Euro">
                                    EUR
                                </option>
                                <option value="SEK" label="Swedish krona">
                                    SEK
                                </option>
                                <option value="GBP" label="British pound">
                                    GBP
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="my-3">
                        <label className="font-medium">User email</label>

                        <input
                            type="email"
                            id="email"
                            className={fixedInputClass}
                            value={email}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Email"
                        />
                    </div>
                    <div className="my-3">
                        <label className="font-medium">Password</label>

                        <input
                            className={fixedInputClass}
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Password"
                        />
                    </div>
                    <div className="my-3 ">
                        <label className="font-medium">Roles</label>
                        <div className="flex mt-3 justify-evenly">
                            <div className="mr-2">
                                <input
                                    name="usersList"
                                    id="admin"
                                    type="checkbox"
                                    onChange={(e) => handleChange(e)}
                                    value="admin"
                                    className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="ml-2 text-sm font-medium  dark:text-gray-900">
                                    Admin
                                </label>
                            </div>
                            <div className="mr-2">
                                <input
                                    name="usersList"
                                    id="customer"
                                    type="checkbox"
                                    value="customer"
                                    onChange={(e) => handleChange(e)}
                                    className="w-3 m-0 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="ml-2 text-sm font-medium  dark:text-gray-900">
                                    Customer
                                </label>
                            </div>
                            <div className="mr-2">
                                <input
                                    name="usersList"
                                    id="rma"
                                    type="checkbox"
                                    value="rma"
                                    onChange={(e) => handleChange(e)}
                                    className="w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="ml-2 text-sm font-medium dark:text-gray-900">
                                    RMA
                                </label>
                            </div>
                            <div className="">
                                <input
                                    name="usersList"
                                    id="inventory"
                                    type="checkbox"
                                    value="inventory"
                                    onChange={(e) => handleChange(e)}
                                    className="w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="ml-2 text-sm font-medium  dark:text-gray-900">
                                    Inventory
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-start mt-5">
                    <button
                        type="button"
                        onClick={goBack}
                        className="relative mr-10 justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        Back
                    </button>
                    <button
                        className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        onClick={handleSubmit}
                        type="submit"
                    >
                        Create User
                    </button>
                </div>
            </form>
        </div>
    )
}
