// Column.tsx
import React, { ReactNode, CSSProperties } from 'react'

interface ColumnProps {
    children: ReactNode
    className?: string
    inlineStyle?: CSSProperties // Add an optional inline style prop
}

export const Column: React.FC<ColumnProps> = ({
    children,
    className,
    inlineStyle,
}) => {
    return (
        <div className={`flex flex-col ${className}`} style={inlineStyle}>
            {children}
        </div>
    )
}
