import React, { useCallback, useEffect, useState } from 'react'
import Head from './components/Head';
// import Filter from '../../Inventory/Inhouse/components/Filter'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useFetch } from '../../../../hooks/useFetch';
import { useAuth } from '../../../../utils/auth';
import WarrantyTable from './components/WarrantyTable';
import { usePost } from '../../../../hooks/usePost';
const baseUrl = process.env.REACT_APP_baseUrl;

export default function Warranty() {
  const [warrantyData, setWarrantyData] = useState<any>(null);

  const {sendRequest} = usePost()

  const { getRequest: getWarrantyItems, data: itemsData } = useFetch(
    `${baseUrl}/warranty/get-warranty-items`
  );
  useEffect(() => {
    getWarrantyItems();
  }, [getWarrantyItems]);

  useEffect(() => {
    setWarrantyData(itemsData);
  }, [itemsData]);

  const refetch = () => {
    getWarrantyItems();
  };

  const handleSearchResult = useCallback(async (searchTerm: any) => {
    try {
      const response = await sendRequest(
        `${baseUrl}/warranty/warranty-search`,
        { imei: searchTerm },
      );
      setWarrantyData(response.data);
    } catch (error) {
      console.error(error);
      toast.error('Error in search');
    }
  }, [sendRequest]);

  return (
    <>
      <Head  />
      {/* <Filter handleSearchResult={handleSearchResult} /> */}
      <WarrantyTable
        refetch={refetch}
        itemsData={warrantyData}
      />
    </>
  )
}
