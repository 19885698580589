import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import logo from '../assets/logo.png'
import { Button } from '../components/base/Button'
import { Text } from '../components/base/Text'
import { TextField } from '../components/base/Textfield'
import Card from '../components/compound/Page/Card/Card'
import { useAuth } from '../utils/auth'

export default function ChangePassword() {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_baseUrl

    const auth = useAuth()
    const token = auth?.user?.token?.token

    const handleOldPassword = (e: any) => {
        e.preventDefault()
        setOldPassword(e.target.value)
    }

    const handleNewPassword = (e: any) => {
        e.preventDefault()
        setNewPassword(e.target.value)
    }
    const handleSubmit = (e: any) => {
        e.preventDefault()
        axios
            .put(
                `${baseUrl}/auth/changepassword`,
                {
                    oldPassword,
                    newPassword,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                toast.success('Password changed, login again', {
                    hideProgressBar: true,
                })               
                setTimeout(function () {
                    auth?.logout()
                    navigate('/')
                }, 4000)
            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data.message}`, {
                    hideProgressBar: true,
                })
            })
    }

    return (
        <>
            <Card className="">
                <form onSubmit={handleSubmit}>
                    <div className="px-5 py-10">
                        <div className="flex justify-center">
                            <img alt="" className="h-20 w-36" src={logo} />
                        </div>
                        <div className="flex justify-center mt-5">
                            <Text
                                colorV="blue"
                                variant="heading"
                                weightV="bold"
                            >
                                Password Change
                            </Text>
                        </div>
                        <div>
                            <div className="my-2">
                                <Text
                                    colorV="blue"
                                    variant="label"
                                    weightV="bold"
                                >
                                    Old password
                                </Text>
                            </div>
                            <TextField
                                value={oldPassword}
                                onChange={handleOldPassword}
                                placeholder="Enter new Password"
                            />
                        </div>
                        <div>
                            <div className="my-2">
                                <Text
                                    colorV="blue"
                                    variant="label"
                                    weightV="bold"
                                >
                                    New password
                                </Text>
                            </div>
                            <TextField
                                value={newPassword}
                                onChange={handleNewPassword}
                                placeholder="Enter new Password"
                            />
                        </div>
                        <div className="flex justify-center mt-7 ">
                            <Button
                                onClick={handleSubmit}
                                className="w-full rounded-2xl py-3 "
                            >
                                <Text
                                    colorV="white"
                                    variant="body"
                                    weightV="bold"
                                >
                                    Change Password
                                </Text>
                            </Button>
                        </div>
                    </div>
                </form>
            </Card>
        </>
        // <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        //   <div className="max-w-md w-full space-y-8">
        //     <Header heading="Change Password" paragraph="" linkName="" linkUrl="" />
        //     <div className="my-3">
        //       <label>Old password</label>
        //       <input
        //         className={fixedInputClass}
        //         type="password"
        //         value={oldPassword}
        //         onChange={(e) => handleOldPassword(e)}
        //         id="email"
        //         placeholder={"Enter old password"}
        //       />
        //     </div>
        //     <div className="my-3">
        //       <label>New password</label>
        //       <input
        //         className={fixedInputClass}
        //         type="password"
        //         value={newPassword}
        //         onChange={(e) => handleNewPassword(e)}
        //         id="email"
        //         placeholder={"Enter new password"}
        //       />
        //     </div>
        //     <div className="flex  ">
        //       <button
        //         type="button"
        //         onClick={goBack}
        //         className="relative mr-10 justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
        //       >
        //         Back
        //       </button>
        //       <button
        //         className="group relative  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
        //         onClick={handleSubmit}
        //         type="submit"
        //       >
        //         Change Password
        //       </button>
        //     </div>
        //   </div>
        // </div>
    )
}
