import { useCallback, useState } from 'react';
import { Button } from '../../../../../base/Button';
import { Text } from '../../../../../base/Text';
import { TextField } from '../../../../../base/Textfield';
import { Column } from '../../../../../layout/Col';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { toast } from 'react-toastify';
import { usePost } from '../../../../../../hooks/usePost';
import { Row } from '../../../../../layout/Row';
import { Icon } from '../../../../../base/Icon';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { addNotification } from '../../../../../shared/Notification/Notification';

const baseUrl = process.env.REACT_APP_baseUrl;

export const Receive = () => {
    const [imei, setImei] = useState<any>('');
    const [isLoading, setIsLoading] = useState(false);
    const [imeis, setImeis] = useState<any[]>([]);

    const { sendRequest } = usePost()

    const handleImeiChange = useCallback((newValue: any) => {
        setImei(newValue);
    }, []);
    const handleRemoveImei = (imeiToRemove) => {
        const updatedImeis = imeis.filter((imei) => imei !== imeiToRemove);
        setImeis(updatedImeis);
    };
    const handleAddImei = useCallback(() => {
        setImeis([...imeis, imei]);
        setImei('')
    }, [imei, imeis])

    const handleReceive = useCallback(async (e: any) => {
        e.preventDefault();
        if (!imeis || imeis.length === 0) {
            toast.error('Please add IMEIs');
            return;
        }

        setIsLoading(true); // Set loading to true before making the request

        const urlItem = `${baseUrl}/transit/receive-transit`;

        try {
            const response = await sendRequest(urlItem, { imeis });
            const { successes, errors } = response?.data || {};
            if (errors && errors.length > 0) {
                const errorMessages = errors.map((error: any) => error.message).join(', ');
                toast.error(`Errors: ${errorMessages}`, { hideProgressBar: true });
            } else if (successes && successes.length > 0) {
                const successMessages = successes.map((success: any) => success.message).join(', ');
                toast.success(`Successes: ${successMessages}`, { hideProgressBar: true });
                addNotification({
                    message: `IMEIs received: ${successMessages}`,
                    type: 'success',
                });
                setImei('');
            }
        } catch (error) {
            toast.error(`Error: ${error?.response?.data?.message}`, {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false); // Set loading to false after the request is completed
            setImeis([])
        }
    }, [imeis, sendRequest]);



    return (
        <Row className='gap-x-10'>
            <Column className="w-8/12">
                <PrimaryCard className='p-4 '>
                    <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'> Scan IMEI</Text>
                    <div className='flex mt-10 mb-16 h-20 items-center justify-center'>
                        <div className="items-center h-28 px-4 justify-center rounded bg-white relative border-radius-5 w-11/12 mx-auto my-auto">
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', left: '0', borderRadius: '5px 0 0 0', borderTop: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', right: '0', borderRadius: '0 5px 0 0', borderTop: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', right: '0', borderRadius: '0 0 5px 0', borderBottom: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', left: '0', borderRadius: '0 0 0 5px', borderBottom: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                            <Row className=" mt-10 gap-x-3 items-center justify-center">
                                <TextField
                                    value={imei}
                                    onChange={handleImeiChange}
                                    placeholder="IMEI"
                                    autoFocus
                                    width="20rem"
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault(); // Prevent form submission
                                            handleAddImei();
                                        }
                                    }}
                                />
                                <Button
                                    onClick={handleAddImei}
                                    className=''
                                    backgroundColor="lgblue"
                                    disabled={isLoading}
                                >
                                    <Text colorV="white" variant="title" weightV="normal">
                                        Add IMEI
                                    </Text>
                                </Button>
                            </Row>
                        </div>
                    </div>

                </PrimaryCard>
            </Column>
            <Column className="w-1/2">
                <PrimaryCard className='p-5 '>
                    <RowAlignCenterSpaced className='mb-7'>
                        <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'>
                            IMEI list
                        </Text>
                        <Column className='my-3 w-72'>
                            {imeis.map((imei) => (
                                <RowAlignCenterSpaced key={imei} className='gap-x-20 my-1'>
                                    <Text colorV="blue" variant="title" weightV="bold">
                                        {imei}
                                    </Text>
                                    <Icon
                                        className='hover:cursor-pointer'
                                        onClick={() => handleRemoveImei(imei)}
                                        name="cross" color="black" size={20} />
                                </RowAlignCenterSpaced>
                            ))}
                        </Column>
                    </RowAlignCenterSpaced>
                    <Row className='justify-end my-4'>
                        {imeis.length > 0 &&
                            <Button
                                onClick={handleReceive}
                                className='w-56'
                                backgroundColor="lgblue"
                                disabled={isLoading} // Disable the button when loading
                            >
                                <Text colorV="white" variant="title" weightV="normal">
                                    {isLoading ? 'Loading...' : 'Received'}
                                </Text>
                            </Button>
                        }
                    </Row>

                </PrimaryCard>
            </Column>
        </Row>
    );
};
