import React from 'react'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Flex } from '../../layout/Flex'
import { Text } from '../../base/Text'

export const Head:React.FC<{ data: any }>  = ({data}) => {
    return (
        <Flex className="w-full">
            <RowAlignCenterSpaced className="gap-x-4  w-full">
                <Text colorV="blue" variant="head" weightV="bold">
                    Purchase Orders  <span className='ml-9'></span> {data?.saleBatchNumber}
                </Text>
            </RowAlignCenterSpaced>
        </Flex>
    )
}
