import { createContext, useContext, useEffect, useState } from 'react'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState(null)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const login = (user) => {
        const company = localStorage.getItem('activeCompany')
        const access = localStorage.getItem('activeProfile')
        setSelectedCompany(JSON.parse(company))
        if (access) {
            setSelectedProfile(JSON.parse(access))
        }
        setUser(user)
    }
    const logout = () => {
        setUser(null)
        localStorage.removeItem('user')
        localStorage.removeItem('activeCompany')
        localStorage.removeItem('activeProfile')
        localStorage.removeItem('notification')
        console.clear()
        console.log('Logged out')
    }

    useEffect(() => {
        const usr = localStorage.getItem('user')
        const company = localStorage.getItem('activeCompany')
        const profile = localStorage.getItem('activeProfile')
        if (usr) {
            setUser(JSON.parse(usr))
        }
        if (company && company !== undefined) {
            setSelectedCompany(JSON.parse(company || ''))
        }
        if (
            profile &&
            profile !== null &&
            profile !== undefined &&
            profile !== 'undefined'
        ) {
            setSelectedProfile(JSON.parse(profile))
        }
    }, [])

    return (
        <AuthContext.Provider
            value={{ user, login, logout, selectedCompany, selectedProfile }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
