import React, { ReactNode } from 'react'
import { Navbar } from '../Navbars/Navbar'
import { Pagebg } from '../../components/compound/Page/Pagebg'
import { Column } from '../../components/layout/Col'
import { Flex } from '../../components/layout/Flex'
import { Row } from '../../components/layout/Row'
import { SubNavbar } from '../Navbars/SubNavbar'
import { BelowNavbar } from '../Navbars/BelowNavbar'
import Logo2 from '../../assets/logo2.png'
import { useAuth } from '../../utils/auth'
import { Permissions } from '../../constant/Storage'
import { useNavigation } from './BaseLayout'
import { AccessEnums, AccessPermissions } from '../../constant/UserAccess'

interface SalesLayoutProps {
    children: ReactNode
}

export const SalesLayout: React.FC<SalesLayoutProps> = ({ children }) => {
    const auth = useAuth();
    const { initialNavItems, belowNavItems } = useNavigation();
    const pointOfSaleCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.PointOfSale && acc.permission.includes(AccessPermissions.Admin));
    const SaleInventoryAccess = auth?.selectedProfile?.access?.some((acc) => 
        acc?.module === AccessEnums.SalesInventory &&
        acc.permission.includes(AccessPermissions.View) ||
        acc.permission.includes(AccessPermissions.Edit)
      );
    const subNavItems = [
        SaleInventoryAccess && { text: 'Sales inventory', to: '/sales/sales-inventory' },
        pointOfSaleCheck ? { text: 'Point of sale', to: '/sales/point-of-sale' } : null,
        pointOfSaleCheck && { text: 'Sales order', to: '/sales/sales-order' },
    ].filter(Boolean);
    // const belowNavItems = [
        // { text: 'Slack', icon: 'slack', to: '/slack', iconSize: 20 },
        // {
        //     text: 'Phone check',
        //     icon: 'tick-mobile',
        //     to: '/phonecheck',
        //     iconSize: 18,
        // },
        // { text: 'GDrive', icon: 'g-drive', to: '/gdrive', iconSize: 20 },
        // { text: 'Logout', icon: 'logout', to: '/logout', iconSize: 20 },
    // ]
    return (
        <div className="bg-base01 flex flex-row">
                <Column className="gap-y-1 p-5">
                    <div className="logo">
                        <img alt="" className="h-14 w-28" src={Logo2} />
                    </div>
                    <Row className="gap-x-2">
                        <Navbar initialNavItems={initialNavItems} />
                        <SubNavbar
                            heading="Sales"
                            initialNavItems={subNavItems}
                        />
                    </Row>
                    <BelowNavbar
                        className="mt-3"
                        initialNavItems={belowNavItems}
                    />
                </Column>
            <div className='flex-1 p-5'>
                {children}
            </div>
        </div>
    )
}
