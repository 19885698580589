import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Column } from '../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../base/Text'
import {
    AccessEnums,
    accessList,
    management,
    myRma,
    inventory,
    production,
    sparePartsInventory,
    rma,
    stocktaking,
    sales,
    AccessPermissions,
} from '../../../../../constant/UserAccess'
import { Row } from '../../../../layout/Row'
import { Circle } from '../../../../layout/Circle'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { useAuth } from '../../../../../utils/auth'

export const Permissions: React.FC<{
    accessIncoming: any[]
    onClose: (access: any[]) => void
    onConfirm: (selectedOptions: string[]) => void
}> = ({ onClose, accessIncoming, onConfirm }) => {
    const [isManagementCheck, setIsManagementCheck] = useState(false)
    const [access, setAccess] = useState(accessList)
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])
    const [selectedValues, setSelectedValues] = useState<string[]>([]) // New state for selected values
    const auth = useAuth()
console.log({accessList});
    const handleModuleAdminPermissionChange = useCallback(
        (moduleIncoming: any) => {
            const parsedAccessList = access.map(({ module, permission }) =>
                module === moduleIncoming
                    ? {
                        module: module,
                        permission: permission?.includes('admin')
                            ? []
                            : ['admin'],
                    }
                    : { module, permission }
            )
            setAccess(parsedAccessList)
        },
        [access]
    )

    const handleModuleSubPermissionChange = useCallback(
        (moduleIncoming: any, subPermission: AccessPermissions) => {
            const parsedAccessList = access.map(({ module, permission }) =>
                module === moduleIncoming
                    ? {
                        module: module,
                        permission: permission?.includes(subPermission)
                            ? permission.filter(
                                (subPer) => subPer !== subPermission
                            )
                            : [...permission, subPermission],
                    }
                    : { module, permission }
            )
            setAccess(parsedAccessList)
        },
        [access]
    )

    useEffect(() => {
        if (accessIncoming?.length) {
            setAccess(accessIncoming)
        }
    }, [accessIncoming])

    const handleConfirm = () => {
        onConfirm(selectedOptions)
    }

    const handleNestedConfirm = (selectedOptions) => {
        setSelectedValues(selectedOptions) // Update selectedValues from child components
    }

    const handleSelectedValuesChange = (values) => {
        setSelectedValues(values)
    }

    const allSelectedValues = [...selectedOptions, ...selectedValues]

    const isParentModuleAllow = (
        module: any,
        subPermission: AccessPermissions
    ) => {
        for (const moduleObj of access) {
            if (moduleObj.module === module) {
                if (moduleObj?.permission?.includes(subPermission)) {
                    return true
                } else {
                    return false
                }
            }
        }
        return false
    }

    const isSubModulePermissionAllow = (
        module: string,
        permission: 'read' | 'write' | 'full'
    ) => {
        for (const moduleObj of access) {
            if (moduleObj.module === module) {
                if (moduleObj.permission[permission] === true) {
                    return true
                } else {
                    return false
                }
            }
        }
    }

    const handleClose = useCallback(() => {
        onClose([])
    }, [onClose])

    const savePermission = useCallback(() => {
        onClose(access)
    }, [access, onClose])

    const isParentSelected = useCallback(
        (parentModule) => {
            if (parentModule === 'management') {
                let isChecked = true
                const managementEnumValues = management.map(
                    (manage) => manage.value
                )
                const managementFoundValues = []
                console.log(access)
                access.forEach((accessSingle) => {
                    if (managementEnumValues.includes(accessSingle.module)) {
                        managementFoundValues.push(accessSingle)
                    }
                })
                for (const managementAccess of managementFoundValues) {
                    if (!managementAccess.permission.length) {
                        isChecked = false
                    }
                }
                return isChecked
            } else if (parentModule === 'inventory') {
                const foundInventory = access.find(
                    (accessSingle) =>
                        accessSingle.module === AccessEnums.InHouseInventory
                )
                if (foundInventory) {
                    if (foundInventory?.permission?.length === 3) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } else if (parentModule === 'sparePartsInventory') {
                const foundInventory = access.find(
                    (accessSingle) =>
                        accessSingle.module === AccessEnums.SparePartsInventory
                )
                if (foundInventory) {
                    if (foundInventory?.permission?.length === 3) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (parentModule === 'sales') {
                const foundSale = access.filter(accessSingle =>
                    accessSingle.module === AccessEnums.PointOfSale ||
                    accessSingle.module === AccessEnums.SalesInventory
                );

                if (foundSale.length > 0) {
                    const isPermissionValid = foundSale.every(accessSingle => {
                        if (accessSingle.module === AccessEnums.PointOfSale && accessSingle.permission.length === 1) {
                            return true;
                        } else if (accessSingle.module === AccessEnums.SalesInventory && accessSingle.permission.length === 2) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    return isPermissionValid;
                } else {
                    return false;
                }

            }
            else if (parentModule === 'rma') {
                const foundRMA = access.find(
                    (accessSingle) =>
                        accessSingle.module === AccessEnums.RMA
                )
                if (foundRMA) {
                    if (foundRMA?.permission?.length === 2) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (parentModule === 'my_rma') {
                const foundRMA = access.find(
                    (accessSingle) =>
                        accessSingle.module === AccessEnums.MyRMA
                )
                if (foundRMA) {
                    if (foundRMA?.permission?.length === 1) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (parentModule === 'stocktaking') {
                const relevantAccess = access.filter(accessSingle =>
                    accessSingle.module === AccessEnums.StocktakingAndApp ||
                    accessSingle.module === AccessEnums.Script ||
                    accessSingle.module === AccessEnums.StocktakingReport
                );
                if (relevantAccess.length > 0) {
                    if (relevantAccess.every(accessSingle => accessSingle.permission.length === 1)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            else if (parentModule === 'production_environment') {
                const relevantAccess = access.filter(accessSingle =>
                    accessSingle.module === AccessEnums.Grading ||
                    accessSingle.module === AccessEnums.Repair ||
                    accessSingle.module === AccessEnums.Diagnostics ||
                    accessSingle.module === AccessEnums.Polishing ||
                    accessSingle.module === AccessEnums.ManualSorting ||
                    accessSingle.module === AccessEnums.Transit
                );
                if (relevantAccess.length > 0) {
                    if (relevantAccess.every(accessSingle => accessSingle.permission.length === 1)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        [access]
    )

    const selectAll = useCallback(
        (parentModule: string) => {
            if (parentModule === 'management') {
                const currenStatus = isParentSelected('management')
                const managementValues = management.map(
                    (manage) => manage.value
                )
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        if (managementValues.includes(module)) {
                            return {
                                module: module,
                                permission: currenStatus ? [] : ['admin'],
                            }
                        } else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            } else if (parentModule === 'inventory') {
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        const currenStatus = isParentSelected('inventory')

                        if (module === AccessEnums.InHouseInventory) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['view', 'add', 'edit'],
                            }
                        } else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            } else if (parentModule === 'sparePartsInventory') {
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        const currenStatus = isParentSelected(
                            'sparePartsInventory'
                        )

                        if (module === AccessEnums.SparePartsInventory) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['view', 'add', 'edit'],
                            }
                        } else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            }
            else if (parentModule === 'sales') {
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        const currenStatus = isParentSelected(
                            'sales'
                        )

                        if (module === AccessEnums.PointOfSale) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['admin'],
                            }
                        } else if (module == AccessEnums.SalesInventory) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['view', "edit"],
                            }
                        }
                        else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            }
            else if (parentModule === 'rma') {
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        const currenStatus = isParentSelected(
                            'rma'
                        )

                        if (module === AccessEnums.RMA) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['view', 'edit'],
                            }
                        } else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            }
            else if (parentModule === 'my_rma') {
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        const currenStatus = isParentSelected(
                            'my_rma'
                        )

                        if (module === AccessEnums.MyRMA) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['admin'],
                            }
                        } else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            }
            else if (parentModule === 'stocktaking') {
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        const currenStatus = isParentSelected(
                            'stocktaking'
                        )

                        if (module === AccessEnums.StocktakingAndApp) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['admin'],
                            }
                        } else if (module === AccessEnums.StocktakingReport) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['admin'],
                            }
                        } else if (module === AccessEnums.Script) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['admin'],
                            }
                        }
                        else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            }
            else if (parentModule === 'production_environment') {
                const parsedAccessList = access.map(
                    ({ module, permission }) => {
                        const currenStatus = isParentSelected(
                            'production_environment'
                        )

                        if (module === AccessEnums.Grading || module === AccessEnums.Repair || module === AccessEnums.Polishing || module === AccessEnums.Diagnostics || module === AccessEnums.ManualSorting || module === AccessEnums.Transit) {
                            return {
                                module: module,
                                permission: currenStatus
                                    ? []
                                    : ['admin'],
                            }
                        } else {
                            return { module, permission }
                        }
                    }
                )
                setAccess(parsedAccessList)
            }
        },
        [access, isParentSelected]
    )
    const filterProduction = useCallback(() => {
        const filteredOptions = production.filter(
            (option) =>
                (auth.selectedCompany.permissions.includes('Grading') &&
                    option.title === 'Grading') ||
                (auth.selectedCompany.permissions.includes('Repair') &&
                    option.title === 'Repair') ||
                (auth.selectedCompany.permissions.includes('Diagnostics') &&
                    option.title === 'Diagnostics') ||
                (auth.selectedCompany.permissions.includes('Polishing') &&
                    option.title === 'Polishing') ||
                (auth.selectedCompany.permissions.includes('Manual Sorting') &&
                    option.title === 'Manual Sorting') ||
                (auth.selectedCompany.permissions.includes('Transit') &&
                    option.title === 'Transit')
        )
        return filteredOptions
    }, [auth.selectedCompany.permissions])

    const filteredOptionsArray = filterProduction()

    return (
        <ModalBox
            heading="Manage access"
            onClose={handleClose}
            top="50%"
            height="auto" // Set height to 100%
            width="660px"
        >
            <Column className=" h-[500px]  mb-2">
                <Row className="gap-x-12 mb-5 overflow-auto">
                    <Column className=" gap-y-3 ">
                        {auth.selectedCompany.permissions.includes(
                            'Management'
                        ) && (
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Management
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentSelected('management')}
                                            onChange={() => selectAll('management')}
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            )}
                        {auth.selectedCompany.permissions.includes(
                            'Management'
                        ) &&
                            management.map((option) => (
                                <RowAlignCenterSpaced
                                    className="gap-x-10"
                                    key={option.value}
                                >
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="normal"
                                    >
                                        {option.title}
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentModuleAllow(
                                                option.value,
                                                AccessPermissions.Admin
                                            )}
                                            onChange={() =>
                                                handleModuleAdminPermissionChange(
                                                    option.value
                                                )
                                            }
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            ))}

                        {auth.selectedCompany.permissions.includes(
                            'Inventory'
                        ) && (
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Inventory
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentSelected('inventory')}
                                            onChange={() => selectAll('inventory')}
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            )}
                        <div className="pl-5">
                            {auth.selectedCompany.permissions.includes(
                                'Inventory'
                            ) &&
                                inventory.map((option) => (
                                    <RowAlignCenterSpaced
                                        className="gap-x-10 mb-2"
                                        key={option.value}
                                    >
                                        <Text
                                            colorV="grey"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            <RowAlignCenter className="gap-x-2">
                                                <Circle />

                                                {option.title}
                                            </RowAlignCenter>
                                        </Text>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isParentModuleAllow(
                                                    AccessEnums.InHouseInventory,
                                                    option.subPermission
                                                )}
                                                onChange={() =>
                                                    handleModuleSubPermissionChange(
                                                        AccessEnums.InHouseInventory,
                                                        option.subPermission
                                                    )
                                                }
                                            />
                                        </label>
                                    </RowAlignCenterSpaced>
                                ))}
                        </div>
                        {auth.selectedCompany.permissions.includes(
                            'Inventory'
                        ) && (
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Spare parts Inventory
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentSelected(
                                                'sparePartsInventory'
                                            )}
                                            onChange={() =>
                                                selectAll('sparePartsInventory')
                                            }
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            )}
                        <div className="pl-5">
                            {auth.selectedCompany.permissions.includes(
                                'Inventory'
                            ) &&
                                sparePartsInventory.map((option) => (
                                    <RowAlignCenterSpaced
                                        className="gap-x-10 mb-2"
                                        key={option.value}
                                    >
                                        <Text
                                            colorV="grey"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            <RowAlignCenter className="gap-x-2">
                                                <Circle />

                                                {option.title}
                                            </RowAlignCenter>
                                        </Text>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isParentModuleAllow(
                                                    AccessEnums.SparePartsInventory,
                                                    option.subPermission
                                                )}
                                                onChange={() =>
                                                    handleModuleSubPermissionChange(
                                                        AccessEnums.SparePartsInventory,
                                                        option.subPermission
                                                    )
                                                }
                                            />
                                        </label>
                                    </RowAlignCenterSpaced>
                                ))}
                        </div>
                    </Column>
                    <Column className="  gap-y-3">
                        {auth.selectedCompany.permissions.includes('Sales') && (
                            <>
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Sales
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentSelected('sales')}
                                            onChange={() => selectAll('sales')}
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="normal"
                                    >
                                        Point of sale
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentModuleAllow(
                                                AccessEnums.PointOfSale,
                                                AccessPermissions.Admin
                                            )}
                                            onChange={() =>
                                                handleModuleAdminPermissionChange(
                                                    AccessEnums.PointOfSale
                                                )
                                            }
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            </>
                        )}
                        <div className="pl-5 text-[]">
                            {auth.selectedCompany.permissions.includes(
                                'Sales'
                            ) &&
                                sales.map((option) => (
                                    <RowAlignCenterSpaced
                                        className="gap-x-10 mb-2"
                                        key={option.value}
                                    >
                                        <Text
                                            colorV="grey"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            <RowAlignCenter className="gap-x-2">
                                                <Circle />

                                                {option.title}
                                            </RowAlignCenter>
                                        </Text>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isParentModuleAllow(
                                                    AccessEnums.SalesInventory,
                                                    option.subPermission
                                                )}
                                                onChange={() =>
                                                    handleModuleSubPermissionChange(
                                                        AccessEnums.SalesInventory,
                                                        option.subPermission
                                                    )
                                                }
                                            />
                                        </label>
                                    </RowAlignCenterSpaced>
                                ))}
                        </div>
                        {auth.selectedCompany.permissions.includes('RMA') && (
                            <RowAlignCenterSpaced>
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="bold"
                                >
                                    RMA
                                </Text>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isParentSelected('rma')}
                                        onChange={() => selectAll('rma')}
                                    />
                                </label>
                            </RowAlignCenterSpaced>
                        )}
                        <div className="pl-5">
                            {auth.selectedCompany.permissions.includes('RMA') &&
                                rma.map((option) => (
                                    <RowAlignCenterSpaced
                                        className="gap-x-10 mb-2"
                                        key={option.value}
                                    >
                                        <Text
                                            colorV="grey"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            <RowAlignCenter className="gap-x-2">
                                                <Circle />

                                                {option.title}
                                            </RowAlignCenter>
                                        </Text>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isParentModuleAllow(
                                                    AccessEnums.RMA,
                                                    option.subPermission
                                                )}
                                                onChange={() =>
                                                    handleModuleSubPermissionChange(
                                                        AccessEnums.RMA,
                                                        option.subPermission
                                                    )
                                                }
                                            />
                                        </label>
                                    </RowAlignCenterSpaced>
                                ))}
                        </div>
                        {auth.selectedCompany.permissions.includes(
                            'My RMA'
                        ) && (
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        MY RMA
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentSelected('my_rma')}
                                            onChange={() => selectAll('my_rma')}
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            )}
                        {auth.selectedCompany.permissions.includes('My RMA') &&
                            myRma.map((option, index) => (
                                <RowAlignCenterSpaced
                                    className="gap-x-10"
                                    key={index}
                                >
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="normal" // Make the first option bold
                                    >
                                        {option.title}
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentModuleAllow(
                                                option.value,
                                                AccessPermissions.Admin
                                            )}
                                            onChange={() =>
                                                handleModuleAdminPermissionChange(
                                                    option.value
                                                )
                                            }
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            ))}

                        {auth.selectedCompany.permissions.includes(
                            'Stocktaking + App'
                        ) && (
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        StockTaking
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentSelected(
                                                'stocktaking'
                                            )}
                                            onChange={() =>
                                                selectAll('stocktaking')
                                            }
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            )}
                        {auth.selectedCompany.permissions.includes(
                            'Stocktaking + App'
                        ) &&
                            stocktaking.map((option, index) => (
                                <RowAlignCenterSpaced
                                    className="gap-x-10"
                                    key={index}
                                >
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="normal" // Make the first option bold
                                    >
                                        {option.title}
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentModuleAllow(
                                                option.value,
                                                AccessPermissions.Admin
                                            )}
                                            onChange={() =>
                                                handleModuleAdminPermissionChange(
                                                    option.value
                                                )
                                            }
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            ))}

                        {(auth?.selectedCompany?.permissions?.includes('Repair') ||
                            auth?.selectedCompany?.permissions?.includes('Grading') ||
                            auth?.selectedCompany?.permissions?.includes('Manual Sorting') ||
                            auth?.selectedCompany?.permissions?.includes('Diagnostics') ||
                            auth?.selectedCompany?.permissions?.includes('Transit') ||
                            auth?.selectedCompany?.permissions?.includes('Polishing')) && (
                                <RowAlignCenterSpaced>
                                    <Text colorV="blue" variant="title" weightV="bold">
                                        Production environment
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isParentSelected('production_environment')}
                                            onChange={() => selectAll('production_environment')}
                                        />
                                    </label>
                                </RowAlignCenterSpaced>)}
                        {filteredOptionsArray.map((option, index) => (
                            <RowAlignCenterSpaced
                                className="gap-x-10"
                                key={index}
                            >
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="normal"
                                >
                                    {option.title}
                                </Text>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isParentModuleAllow(
                                            option.value,
                                            AccessPermissions.Admin
                                        )}
                                        onChange={() =>
                                            handleModuleAdminPermissionChange(
                                                option.value
                                            )
                                        }
                                    />
                                </label>
                            </RowAlignCenterSpaced>
                        ))}
                    </Column>
                </Row>
                <Row className="w-full">
                    <Button className=" w-full" onClick={savePermission}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Select Access
                        </Text>
                    </Button>
                </Row>
            </Column>
        </ModalBox>
    )
}
