import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useFetch } from '../../../../../../hooks/useFetch'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Text } from '../../../../../base/Text'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { usePost } from '../../../../../../hooks/usePost'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

// ExportContent Component
export const AddCode: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [code, setCode] = useState<string>('')
    const [codeDescription, setCodeDescription] = useState<string>('')
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendRequest } = usePost()
    const handleCodeChange = (newValue: any) => {
        setCode(newValue)
    }
    const handleCodeDescriptionChange = (newValue: any) => {
        setCodeDescription(newValue)
    }
    const { getRequest: getCodes } = useFetch(
        `${baseUrl}/code/get-codes`
    )

    useEffect(() => {
        getCodes()
    }, [getCodes])

    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault()
        if (!code) {
            alert('Please, add code label')
            return
        } else if (!codeDescription) {
            alert('Please, add description')
            return
        }
        const urlItem = `${baseUrl}/code/add-code`
        await sendRequest(
            urlItem,
            {
                label: code,
                description: codeDescription,
            },
        )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Code added successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data}`, {
                    hideProgressBar: true,
                })
            })
    }, [code, codeDescription, onClose, sendRequest])

    return (
        <ModalBox
            heading="Create new code"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[180px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Code
                    </Text>
                    <TextField
                        value={code}
                        onChange={handleCodeChange}
                        placeholder="Enter code"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Code Description
                    </Text>
                    <TextField
                        value={codeDescription}
                        onChange={handleCodeDescriptionChange}
                        placeholder="Enter description"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' backgroundColor="lgblue" onClick={handleSubmit}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Save
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
