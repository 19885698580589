import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../../../base/Button';
import { Text } from '../../../../../base/Text';
import { TextBackground } from '../../../../../base/TextBackground';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Table } from '../../../../../shared/Table';
import { AddCodeEvent } from './Modals/AddCodeEvent';
import { DeleteCodeEvent } from './Modals/DeleteCodeEvent';
import { useFetch } from '../../../../../../hooks/useFetch';
import { ModalBox } from '../../../../../base/ModelBox';

const baseUrl = process.env.REACT_APP_baseUrl;

export const SupplierCodeEvent: React.FC<{ onClose: () => void; item: any;onBack: () => void  }> = ({ onClose, item, onBack }) => {
    const [isCodeModal, setIsCodeModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedCode, setSelectedCode] = useState()
    const { getRequest: getEvents, data: eventsData } = useFetch(
        `${baseUrl}/sorting-logic/get-manual-sorting-event-description/${item.Id}`
    );

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    const handleBack = useCallback(() => {
        onBack()
    }, [onBack]);
    
    const handleCodeEvent = () => {
        console.log("first")
        setIsCodeModal(true);
    };

    const handleSelectChange = (option, item) => {
        setSelectedCode(item)
        setIsDeleteModal(true);
    };

    const handleClose = () => {
        getEvents();
        setIsCodeModal(false);
        setIsDeleteModal(false);
    };

    const columns = ['Code', 'Model', 'Operating system'];
    let extractedData = [];
    if (eventsData) {
        extractedData = eventsData?.rules?.map((item) => {
            return {
                Id: item?._id,
                'Code': item?.code?.label,
                'Model': item?.model?.modelName,
                'Operating system': item?.operatingSystem
            };
        });
    }
    return (
        <>
            <RowAlignCenterSpaced>
                <Text colorV="blue" variant="heading" weightV="bold">
                   Supplier warranty
                </Text>
                <TextBackground className="w-44" backgroundColor="b9white">
                    {item?.['Status name']}
                </TextBackground>
                <TextBackground className="w-44" backgroundColor="b9white">
                    {item?.['Status type']}
                </TextBackground>
                <Button onClick={handleCodeEvent} className="w-40" backgroundColor="lgblue">
                    <Text colorV="white" variant="title" weightV="normal">
                        Add new rule
                    </Text>
                </Button>
                <Button onClick={handleBack} className="w-24" backgroundColor="blue">
                    <Text colorV="white" variant="title" weightV="normal">
                        Back
                    </Text>
                </Button>
            </RowAlignCenterSpaced>
            <PrimaryCard className="w-full mt-4">
                <Table
                    options={['Delete']}
                    columns={columns}
                    showActions={true}
                    handleDropdownChange={handleSelectChange}
                    data={extractedData}
                />
            </PrimaryCard>
            {isCodeModal && <AddCodeEvent onClose={handleClose} item={item} />}
            {isDeleteModal && <DeleteCodeEvent selectedCode={selectedCode} onClose={handleClose} item={item} />}
        </>

    );
};
