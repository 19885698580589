import React, { useCallback, useEffect, useState } from 'react'
import Head from './Head'
import RmaTable from './component/RmaTable'
import { useFetch } from '../../../../hooks/useFetch'
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess'
import { useAuth } from '../../../../utils/auth'
import { Filter } from './component/Filter'
import { Row } from '../../../layout/Row'
const baseUrl = process.env.REACT_APP_baseUrl

export const CustomerRma = () => {
    const [filterData, setFilterData] = useState(null)
    const auth = useAuth();
    const viewRmaCreditNotesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.RMA && acc.permission.includes(AccessPermissions.View));

    const { getRequest, data } = useFetch(`${baseUrl}/rma/get-rma-company`)

    useEffect(() => {
        getRequest()
    }, [getRequest])

    const refetch = useCallback(() => {
        getRequest()
    }, [getRequest])

    const handleFilterChange = useCallback((data) => {
        setFilterData(data)
    }, [])

    return (
        <>
            <Row className='gap-x-3 pb-5 pt-2 items-center justify-center'>
                <Head refetch={refetch} />
                <Filter refetch={refetch} itemsData={data} onDataChange={handleFilterChange} />
            </Row>
            {viewRmaCreditNotesCheck && <RmaTable data={filterData?.length > 0 ? filterData : data} />}
        </>
    )
}
