import React from 'react'
import Header from '../components/shared/Header/Header'
import { Breadcrumb } from '../components/base/Breadcrumb'
import { Row } from '../components/layout/Row'
import { useBreadcrumb } from '../hooks/useBreadcrumb'
import { Column } from '../components/layout/Col'
import { Flex } from '../components/layout/Flex'
import { Customerrma } from '../components/customerPortal/CustomerRma/Customerrma'

export const CustomerMyRMA = () => {
    const breadcrumbItems = useBreadcrumb()

    return (
        <>
            <Column className="">
                <Header />
                <Flex>
                    <Column className=" w-full">
                        <Row className="mb-3 mt-2 w-full">
                            <Breadcrumb items={breadcrumbItems} separator="/" />
                        </Row>
                        <Customerrma />
                    </Column>
                </Flex>
            </Column>
        </>
    )
}
