import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { Button } from '../../base/Button'
import { ModalBox } from '../../base/ModelBox'
import { TextBackground } from '../../base/TextBackground'
import { Column } from '../../layout/Col'
import { Row } from '../../layout/Row'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Text } from '../../base/Text'
import { useFetch } from '../../../hooks/useFetch'
import moment from 'moment'
import { usePost } from '../../../hooks/usePost'
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
const baseUrl = process.env.REACT_APP_baseUrl

export const InvoiceHistory: React.FC<{ onClose: () => void; item: any;  }> = ({
    onClose,
    item,

}) => {
const [remainingDays, setRemainingDays] = useState()
const { sendRequest } = usePost();

// const { getRequest: getInvoiceItems, data: itemsData } = useFetch(
//     `${baseUrl}/inventory/get-invoice-items`
// );

// useEffect(() => {
//     getInvoiceItems()
// }, [getInvoiceItems])


    const handleDownload = useCallback(async() => {
        const invoiceNumber = item?.invoiceNumber;
        const response:any = await sendRequest(
            `${baseUrl}/inventory/get-history-invoice-items/${invoiceNumber}`,
            {
                invoiceNumber
            },
        )
        const csvArray = []
        if (response?.data?.length > 0) {
            response?.data?.forEach((itm) => {
                csvArray.push({
                    Imei: itm?.imei || '',
                });
            });
            let csv = ' '
            for (let row = 0; row < csvArray?.length; row++) {
                const keysAmount = Object.keys(csvArray[row]).length
                let keysCounter = 0
                if (row === 0) {
                    for (const key in csvArray[row]) {
                        csv +=
                            key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                }
                keysCounter = 0
                for (const key in csvArray[row]) {
                    csv +=
                        csvArray[row][key] +
                        (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
                keysCounter = 0
            }
            const data = new Blob([csv], { type: 'text/csv' })
            saveCsv(data, 'invoice.csv')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [item?.invoiceNumber, sendRequest]);

    function saveCsv(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = filename
        a.click()
    }

    useEffect(() => {
        if (item?.createdAt && item?.rmaSupplier != null) {
            const endDate = moment(item.createdAt).add(1, 'days').add(item.rmaSupplier, 'days');
            const daysLeft = endDate.diff(moment(), 'days');

            const remainingDaysString:any = daysLeft > 0
                ? `${daysLeft} day${daysLeft !== 1 ? 's' : ''} left`
                : daysLeft === 0
                    ? 'Today is the last day'
                    : 'The deadline has passed';

            setRemainingDays(remainingDaysString);
        } else {
            setRemainingDays(null);
        }
    }, [item]);
    return (
        <ModalBox
            heading="Invoice detail"
            onClose={onClose}
            top="50%"
            height="auto"
            width="430px"
        >
            <Column className="mt-3 gap-y-4 h-[290px] overflow-auto">
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Invoice number
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.invoiceNumber}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Supplier
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.supplier?.companyName}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Received
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {moment(item?.createdAt).format('DD.MM.YYYY')}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        RMA
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        <Text colorV="mid-blue" variant="title" weightV="normal">
                            {remainingDays}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <Row className="gap-x-4 mt-4">
                    <Button
                        className='w-52'
                        onClick={handleDownload}
                        backgroundColor="lgblue"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Download IMEI list
                        </Text>
                    </Button>

                </Row>
            </Column>
        </ModalBox>
    )
}
