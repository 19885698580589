import React, { useEffect, useState, useCallback } from 'react'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Text } from '../../../../base/Text'
import { Button } from '../../../../base/Button'
import { Column } from '../../../../layout/Col'
import { ModalBox } from '../../../../base/ModelBox'
import { AddModel } from '../DialougeBoxes/Add/AddModel'
import { useFetch } from '../../../../../hooks/useFetch'
import { DeleteModel } from '../DialougeBoxes/Delete/DeleteModel'
import { EditModel } from '../DialougeBoxes/Edit/EditModel'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
const baseUrl = process.env.REACT_APP_baseUrl

export const Models = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [selectedModel, setSelectedModel] = useState<any>()

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )

    useEffect(() => {
        getModels()
    }, [getModels])
    const openModal = (option) => {
        setSelectedOption(option)
        if (option === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (option === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const closeModal = useCallback(() => {
        setIsModalOpen(false)
        setSelectedOption(null)
        setIsEdit(false)
        setIsDelete(false)
        getModels()
    }, [getModels])

    let extractedData = []
    if (modelsData) {
        extractedData = modelsData?.map((item) => {
            return {
                id: item?._id,
                Model: item?.modelName,
                OS: item?.os,
                Device: item?.device || '',
                Storage: item?.storage.toString() || '',
            }
        })
    }
console.log(modelsData)
    const columns = ['Model', 'OS', 'Device', 'Storage']

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item)
        console.log(`Selected Option: ${optionValue}`)
        setSelectedOption(optionValue)
        if (optionValue === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (optionValue === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }
    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditModel item={selectedModel} onClose={closeModal} />
        } else if (isDelete) {
            return <DeleteModel item={selectedModel} onClose={closeModal} />
        } else {
            return <AddModel onClose={closeModal} />
        }
    }, [isEdit, isDelete, selectedModel, closeModal])

    return (
        <>
            <Column className='w-full'>
                <RowAlignCenterSpaced className="mb-5 ">
                    <Text
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        Manage Models
                    </Text>
                    <Button
                        backgroundColor="lgblue"
                        onClick={() => openModal('Add New Model')}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Add new model
                        </Text>
                    </Button>
                </RowAlignCenterSpaced>
                <PrimaryCard className='w-full'>
                    <Table
                        handleDropdownChange={handleSelectChange}
                        columns={columns}
                        options={['Edit', 'Delete']}
                        data={extractedData}
                        showActions={true}
                    />
                </PrimaryCard>
                {isModalOpen && (renderModalComponent()
                )}
            </Column>
        </>
    )
}
