import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Row } from '../../../../layout/Row'
import { TextArea } from '../../../../base/Textarea'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { useEdit } from '../../../../../hooks/useEdit'
import { SparepartsDetail } from '../../Repair/components/SparepartsDetail'
import { IconButton } from '../../../../base/IconButton'
import { MyIcon } from '../../../../base/MyIcon'
import axios from 'axios'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl
interface RepairCodesProps {
    handleNotes: (newValue: any) => void
    data: any
    refetch: () => void
}
export default function WarrantyCodes({ data, refetch }: RepairCodesProps) {
    const [acceptance, setAcceptance] = useState<any>()
    const [comment, setComment] = useState<any>('')
    const { sendEditRequest } = useEdit()
    const [isSparepartsDetailOpen, setIsSparepartsDetailOpen] = useState(false)
    const [spareParts, setSpareParts] = useState([])
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany;
    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }

    useEffect(() => {
        setAcceptance(data?.sortingEventKey)
        setComment(data?.comment)
    }, [data?.comment, data?.sortingEventKey])
    const handleSpareparts = () => {
        setIsSparepartsDetailOpen(true)
        refetch()
    }
    const handleDeleteSparePart = (sparepart: any) => {
        axios({
            method: 'delete',
            url: `${baseUrl}/item/delete-sparePart/${data?._id}`,
            data: {
                sparePartId: sparepart?.sparePart?._id,
                pieces: sparepart?.pieces,
            },
            headers: {
                Authorization: token,
                Company: company._id,

            },
        })
            .then((response) => {
                toast.success(response.data)
                addNotification({
                    message: 'Sparepart has been deleted successfully',
                    type: 'success',
                })
                refetch()
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            )
    }

    const list = useCallback((sparePartResponse, item) => {
        console.log({ sparePartResponse });
        const innerSpareParts = []
        sparePartResponse?.map((sparePart) => {
            console.log({ sparePart });
            const models = sparePart?.models.map((mdl) => {
                return mdl?.modelName
            })
            if (models.includes(item?.model?.modelName)) {
                innerSpareParts.push({
                    label: sparePart?.name,
                    value: sparePart?._id,
                })
            }
        })
        setSpareParts(innerSpareParts)
    }, [])

    const getSparePart = useCallback(
        async (itemLocal) => {
            try {
                const response = await axios.get(
                    `${baseUrl}/stock/get-spare-part-pdf`,
                    {
                        headers: {
                            Authorization: token,
                            Company: company._id,
                        },
                    }
                )
                console.log(response);
                list(response?.data, itemLocal)
            } catch (error) {
                toast.error(error.response?.data?.message, {
                    hideProgressBar: true,
                })
            }
        },
        [company._id, list, token]
    )
    const handleAcceptanceChange = useCallback(async (event) => {
        setAcceptance(event.target.value)
        await sendEditRequest(
            `${baseUrl}/rma/update-rma-item-status/${data?._id}`,
            {
                acceptance: event.target.value,
                comment
            },
        )
            .then((response) => {
                toast.success(`Status added`, { hideProgressBar: true })
                addNotification({
                    message: 'Status has been updated successfully',
                    type: 'success',
                })
                setAcceptance("")
                refetch()
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [comment, data?._id, refetch, sendEditRequest])

    useEffect(() => {
        if (data) {
            getSparePart(data)
        }
    }, [getSparePart, data])
    return (
        <>
            <Flex className="w-full mt-5 gap-x-3">
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full">
                        <Text colorV="blue" weightV="bold" variant="title">
                            Issue to be fixed
                        </Text>
                        <Row className="gap-x-3 mt-4">
                            {data?.codes?.map((code: any) => (
                                <div
                                    key={code}
                                    className="bg-b9blue   h-9 rounded-lg px-4  flex justify-center items-center"
                                >
                                    <Text
                                        colorV="white"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        {code}
                                    </Text>
                                </div>
                            ))}
                        </Row>
                    </Column>
                </PrimaryCard>
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full">
                        <div style={{ width: '18rem' }}>
                            <select
                                id="acceptance"
                                value={acceptance}
                                defaultValue={acceptance}
                                onChange={handleAcceptanceChange}
                                className="w-full bg-b9blue text-white hover:cursor-pointer text-sm   py-2 px-1"                        >
                                <option value="" >Status</option>
                                <option value="warranty_repaired">Warranty repaired</option>
                                <option value="warranty_cant_fix">Warranty cant fix</option>
                            </select>
                        </div>
                    </Column>
                </PrimaryCard>
            </Flex>
            <Flex className="w-full mt-5 gap-x-3">
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full gap-y-8">
                        <Row className="gap-x-3">
                            <Text colorV="blue" weightV="bold" variant="title">
                                Spare parts
                            </Text>
                            {spareParts?.length > 0 ? (
                                <>
                                    {!data?.repair?.finishAt && !data?.repair?.cancelAt ? (
                                        <IconButton name="add" onClick={handleSpareparts} />
                                    ) : (
                                        <Text colorV="blue" weightV="normal" variant="title">
                                            Repair is finished / cancelled.
                                        </Text>
                                    )}
                                </>
                            ) : (
                                <Text colorV="blue" weightV="normal" variant="title">
                                    No sparepart found for {data?.model?.modelName}
                                </Text>
                            )}

                        </Row>
                        <Row className="flex flex-wrap gap-x-3 gap-y-3">
                            {data?.spareParts?.map((sparepart: any) => (
                                <div
                                    key={data?._id}
                                    className="bg-b9white relative h-8 rounded-lg px-7 py-1 flex justify-center items-center"
                                >
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        {sparepart?.sparePart?.name} (
                                        {sparepart?.pieces})
                                    </Text>
                                    {!data?.repair?.finishAt && !data?.repair?.cancelAt && (
                                        <div
                                            onClick={() => handleDeleteSparePart(sparepart)}
                                            style={iconContainerStyles}
                                        >
                                            <MyIcon size={16} name="cross"></MyIcon>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Row>
                    </Column>
                </PrimaryCard>
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full gap-y-2">
                        <Text colorV="blue" weightV="bold" variant="title">
                            Comment
                        </Text>
                        <TextArea
                            className="w-full"
                            value={comment}
                            onChange={handleCommentChange}
                            placeholder="Enter comment"
                        />
                    </Column>
                </PrimaryCard>
            </Flex>
            {isSparepartsDetailOpen && (
                <SparepartsDetail
                    item={data}
                    sparepartsData={spareParts}
                    onClose={() => {
                        setIsSparepartsDetailOpen(false)
                        getSparePart(data)
                        refetch()
                    }}
                />
            )}
        </>
    )
}

const iconContainerStyles: CSSProperties = {
    position: 'absolute',
    top: -10,
    right: -9,
    padding: '4px', // Add padding for better aesthetics
    cursor: 'pointer', // Add cursor style to indicate it's clickable
}