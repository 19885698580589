export const managersList = [
    {
        name: 'Sergei Tarasenko',
        email: 'sergei@bridge9.fi',
    },
    {
        name: 'Jan Pyykönen',
        email: 'jan@bridge9.fi',
    },
    {
        name: 'Antonio Rocha',
        email: 'antonio@bridge9.fi',
    },
    {
        name: 'Farzad Tayefeh',
        email: 'farzad@bridge9.fi',
    },
    {
        name: 'Jani Konsin',
        email: 'jani@bridge9.fi',
    },
]
