import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Table } from '../../../../shared/Table';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess';
import { useAuth } from '../../../../../utils/auth';
import { Column } from '../../../../layout/Col';
import { Text } from '../../../../base/Text';
import { useFetch } from '../../../../../hooks/useFetch';
const baseUrl = process.env.REACT_APP_baseUrl

export const CreditNoteArchive = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedModel, setSelectedModel] = useState<any>();
    const auth = useAuth();
    const editRmaCreditNotesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.RMA && acc.permission.includes(AccessPermissions.Edit));
    const { getRequest: getCreditNotes, data } = useFetch(
        `${baseUrl}/creditnote/get-credit-notes`
    )

    useEffect(() => {
        getCreditNotes()
    }, [getCreditNotes])

    const columns = [
        'Credit Note N',
        'Customer',
        'Issued',
        'Status',
        'Value',
        'Issued for RMA',
        'Account manager',
    ];

    let extractedData = [];
    if (data) {
        const archiveData = data?.filter((item) => item?.archiveStatus === true);

        extractedData = archiveData?.map((item:any) => {
            return {
                    id: item?._id,
                    'Credit Note N': item?.creditNumber,
                    Customer: item?.customer?.companyName,
                    Issued: moment(item?.createdAt).format('DD.MM.YY'),
                    Value: item?.creditValue,
                    'Issued for RMA': item?.issuedFor?.rmaNumber,
                    Status: item?.status,
                    'Account manager': item?.customer?.accountManager?.accountManager,
                    path: item?.filePath,
                    customerId:item?.customer?._id,
                    item:item,
            };
        });
    }
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOption(null);
    };

    const handleDropdownChange = (optionValue, item) => {
        setSelectedModel(item);
        setSelectedOption(optionValue);
        setIsModalOpen(true);
    };



    return (
        <>
            <Column className=" w-full my-3 gap-y-5">
                <Text
                    style={{ width: '250px' }}
                    colorV="blue"
                    variant="head"
                    weightV="bold"
                >
                    Credit notes
                </Text>
                <PrimaryCard className="w-full">
                    <Table
                        columns={columns}
                        data={extractedData}
                        clickable={true}
                        showActions={false}
                        handleDropdownChange={handleDropdownChange}
                        options={editRmaCreditNotesCheck ? ['Edit', 'Delete', 'Archive', 'Download PDF'] : ['Delete', 'Archive', 'Download PDF']}
                    />
                </PrimaryCard>
            </Column>
        </>
    );
};
