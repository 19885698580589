import React, { ReactNode } from 'react'
import { Navbar } from '../Navbars/Navbar'
import { Column } from '../../components/layout/Col'
import { Row } from '../../components/layout/Row'
import { SubNavbar } from '../Navbars/SubNavbar'
import { BelowNavbar } from '../Navbars/BelowNavbar'
import Logo2 from '../../assets/logo2.png'
import { useNavigation } from './BaseLayout'
import { AccessEnums, AccessPermissions } from '../../constant/UserAccess'
import { useAuth } from '../../utils/auth'
interface InventoryLayoutProps {
    children: ReactNode
}

export const InventoryLayout: React.FC<InventoryLayoutProps> = ({
    children,
}) => {
    const { initialNavItems, belowNavItems } = useNavigation();
    const auth = useAuth();
    const isInventoryAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.InHouseInventory && acc.permission.includes(AccessPermissions.View || AccessPermissions.Add || AccessPermissions.Edit))
    const isSparePartInventoryAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.SparePartsInventory && acc.permission.includes(AccessPermissions.View || AccessPermissions.Add || AccessPermissions.Edit))
    const subNavItems = [
        isInventoryAccess && { text: 'In-house inventory', to: '/inventory/inhouse' },
        isSparePartInventoryAccess && {
            text: 'Spare parts inventory',
            to: '/inventory/spareparts',
        },
        isInventoryAccess && {
            text: 'Invoice',
            to: '/inventory/invoice',
        },
    ].filter(Boolean)

    // const belowNavItems = [
    // { text: 'Slack', icon: 'slack', to: '/slack', iconSize: 20 },
    // {
    //     text: 'Phone check',
    //     icon: 'tick-mobile',
    //     to: '/phonecheck',
    //     iconSize: 18,
    // },
    // { text: 'GDrive', icon: 'g-drive', to: '/gdrive', iconSize: 20 },
    // { text: 'Logout', icon: 'logout', to: '/logout', iconSize: 20 },
    // ]
    return (
        <div className="bg-base01 flex flex-row">
                <Column className="gap-y-1 p-5">
                    <div className="logo">
                        <img alt="" className="h-14 w-28" src={Logo2} />
                    </div>
                    <Row className="gap-x-2">
                        <Navbar initialNavItems={initialNavItems} />
                        <SubNavbar
                            heading="Inventory"
                            initialNavItems={subNavItems}
                        />
                    </Row>
                    <BelowNavbar
                        className="mt-3"
                        initialNavItems={belowNavItems}
                    />
                </Column>
            <div className='p-5 flex-1 hide-scrollbar'>
                {/* <div className='w-full'> */}
                {children}
            </div>
        </div>
    )
}
