import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../base/Text'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { MyIcon } from '../../../../base/MyIcon'
import { Permissions } from './Permissions'
import { Fill } from '../../../../layout'
import { usePost } from '../../../../../hooks/usePost'
import { useEdit } from '../../../../../hooks/useEdit'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const AddUser: React.FC<{ user?: any; onClose: () => void }> = ({
    user,
    onClose,
}) => {
    const [access, setAccess] = useState([])
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [accountManagerEmail, setAccountManagerEmail] = useState<string>('')
    const [showPermission, setShowPermission] = useState(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const auth = useAuth()
    const company = auth.selectedCompany
    const { sendRequest } = usePost()
    const { sendEditRequest } = useEdit()
    const handleFirstNameChange = (newValue: string) => {
        setFirstName(newValue)
    }

    const handleLastNameChange = (newValue: string) => {
        setLastName(newValue)
    }

    const handleEmailChange = (newValue: string) => {
        setEmail(newValue)
    }

    const handleManagerChange = (newValue: string) => {
        setAccountManagerEmail(newValue)
    }

    const closePermission = (accessList: any[]) => {
        setAccess(accessList)
        setShowPermission(false)
    }

    const handleConfirm = (selectedOptions: string[]) => {
        // Handle the selected options from the Permissions component here
        console.log('Selected Options:', selectedOptions)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (user?.id) {
            editAccount()
        } else {
            createAccount()
        }
    }

    const createAccount = useCallback(async () => {
        const token = auth.user?.token?.token
        setIsSaving(true)
        await sendRequest(`${baseUrl}/user/register-company-user`, {
            firstName,
            lastName,
            email,
            access,
            accountManagerEmail,
        })
            .then(() => {
                onClose()
                setIsSaving(false)
                toast.success('User Created', { hideProgressBar: true })
                addNotification({
                    message: 'User has been created successfully',
                    type: 'success',
                })
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    hideProgressBar: true,
                })
                setIsSaving(false)
            })
    }, [
        access,
        accountManagerEmail,
        auth.user?.token?.token,
        email,
        firstName,
        lastName,
        onClose,
        sendRequest,
    ])

    const editAccount = useCallback(async () => {
        const token = auth.user?.token?.token
        sendEditRequest(`${baseUrl}/user/edit-company-user/${user?.id}`, {
            firstName,
            lastName,
            access,
            accountManagerEmail,
            profile: user?.user?.profile?._id,
        })
            .then(() => {
                onClose()
                toast.success('User Updated', { hideProgressBar: false })
                addNotification({
                    message: 'User has been updated successfully',
                    type: 'success',
                })
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    hideProgressBar: false,
                })
            })
    }, [
        access,
        accountManagerEmail,
        auth.user?.token?.token,
        firstName,
        lastName,
        onClose,
        sendEditRequest,
        user?.id,
        user?.user?.profile?._id,
    ])

    // const getAccount = useCallback(async () => {
    //     const token = auth.user?.token?.token
    //     await axios
    //         .get(`${baseUrl}/user/get-user/${user?.id}`, {
    //             headers: {
    //                 Authorization: token,
    //                 Company: company._id,
    //             },
    //         })
    //         .then((data) => {
    //             console.log({ data });
    //             setFirstName(data.data.firstName)
    //             setLastName(data.data.lastName)
    //             setEmail(data.data.email)
    //             setAccountManagerEmail(data.data.accountManagerEmail)
    //             setAccess(data.data.access)
    //         })
    //         .catch((error) => {
    //             toast.error(error?.response?.data?.message, {
    //                 hideProgressBar: false,
    //             })
    //         })
    // }, [auth.user?.token?.token, company._id, user?.id])
    console.log(user?.user)
    useEffect(() => {
        if (user) {
            setFirstName(user?.user?.firstName)
            setLastName(user?.user?.lastName)
            setEmail(user?.user?.Email)
            setAccountManagerEmail(user?.user?.accountManagerEmail)
            setAccess(user?.user?.profile?.access)
        }
    }, [user])

    const handleReset = useCallback(() => {
        onClose()
        toast.success('Password reset email sent', { hideProgressBar: true })
        addNotification({
            message: 'Password reset email sent',
            type: 'success',
        })
    }, [onClose])

    // useEffect(() => {
    //     if (user?.id) {
    //         getAccount()
    //     }
    // }, [getAccount, user])
    return (
        <>
            <ModalBox
                heading={user ? 'Edit user' : 'Add new user'}
                onClose={onClose}
                top="50%"
                left="50%"
                height="400px"
                width="500px"
            >
                <Column className="gap-y-4">
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="title" weightV="bold">
                            First name
                        </Text>
                        <TextField
                            value={firstName}
                            onChange={handleFirstNameChange}
                            placeholder="Enter First name"
                            width="250px"
                        />
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="title" weightV="bold">
                            Last name
                        </Text>
                        <TextField
                            value={lastName}
                            onChange={handleLastNameChange}
                            placeholder="Enter last name"
                            width="250px"
                        />
                    </RowAlignCenterSpaced>
                    {!user?.id && (
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Email
                            </Text>
                            <TextField
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Enter email"
                                width="250px"
                            />
                        </RowAlignCenterSpaced>
                    )}
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="title" weightV="bold">
                            Manager Email
                        </Text>
                        <TextField
                            value={accountManagerEmail}
                            onChange={handleManagerChange}
                            placeholder="Manager Email"
                            width="250px"
                        />
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="title" weightV="bold">
                            Access
                        </Text>
                        <Button
                            padding="4px 8px"
                            backgroundColor="grey"
                            className="w-[250px]"
                            onClick={() => setShowPermission(true)}
                        >
                            <Flex className="gap-x-5">
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="normal"
                                >
                                    Select
                                </Text>
                                <MyIcon name="upTick" size={16} />
                            </Flex>
                        </Button>
                    </RowAlignCenterSpaced>
                    <Flex className="">
                        <Button
                            className="w-32"
                            onClick={handleSubmit}
                            backgroundColor="lgblue"
                        >
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Text>
                        </Button>
                        <Fill />
                        <Button
                            onClick={handleReset}
                            className="w-[250px]"
                            backgroundColor="blue"
                        >
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Reset Password
                            </Text>
                        </Button>
                    </Flex>
                </Column>
            </ModalBox>
            {showPermission && (
                <Permissions
                    accessIncoming={access}
                    onClose={closePermission}
                    onConfirm={handleConfirm}
                />
            )}
        </>
    )
}
