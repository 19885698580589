import React, { useCallback, useState } from 'react';
import { Text } from '../../../base/Text';
import { ImeiSearch } from './component/ImeiSearch'; // Import your SearchResults component
import { BulkGrading } from './component/BulkGrading'; // Import your BulkGrading component
import { Button } from '../../../base/Button';
import { TextField } from '../../../base/Textfield';
import { Column } from '../../../layout/Col';
import { Flex } from '../../../layout/Flex';
import { RowAlignCenter } from '../../../layout/RowAlignCenter';
import { PrimaryCard } from '../../Page/PrimaryCard';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../utils/auth';
import { usePost } from '../../../../hooks/usePost';
import { downloadTemplate } from '../../../../download/downloadService';
const baseUrl = process.env.REACT_APP_baseUrl

export const Grading = () => {
  const [Imei, setImei] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showBulkGrading, setShowBulkGrading] = useState(false);
  const [searchData, setSearchData] = useState<any[] | undefined>(undefined);
  const auth = useAuth();
  const token = auth?.user?.token?.token;
  const { sendRequest } = usePost();

  const handleImeiChange = (newValue: any) => {
    setImei(newValue);
  };

  const handleSearch = useCallback(async () => {
    if (!Imei.trim()) {
      toast.warning("IMEI cannot be empty", {
        hideProgressBar: true,
      });
      return;
    }
    await sendRequest(
      `${baseUrl}/inventory/find-imei`,
      {
        Imei,
      },
    )
      .then((response) => {
        const responseData = response?.data;
        if (responseData !== null && responseData.length !== 0) {
          setSearchData(responseData);
          setShowSearchResults(true);
        } else {
          toast.warning("No data found ", {
            hideProgressBar: true,
          });
          setShowSearchResults(false);
        }
      })
      .catch((error) => {
        toast.error(`Error: ${error.response.data.message}`, {
          hideProgressBar: true,
        });
        setShowSearchResults(false); // Ensure it's set to false when an error occurs
      });
  }, [Imei, sendRequest]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleButtonClick = () => {
    handleSearch();
  };

  const handleBulkGrading = () => {
    setShowBulkGrading(true);
  };

  const handleBack = useCallback(() => {
    setSearchData(null);
    setImei('')
}, []);

  return (
    <>
      {searchData ? (
        <ImeiSearch data={searchData} handleBack={handleBack} />
      ) : showBulkGrading ? (
        <BulkGrading />
      ) : (
        <Flex
          inlineStyle={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PrimaryCard className="p-5 justify-center items-center text-center">
            <Column className="gap-y-5">
              <Text
              
              colorV="blue" weightV="bold" variant="heading">
                Input IMEI
              </Text>
              <TextField
                value={Imei}
                onChange={handleImeiChange}
                onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                placeholder="IMEI"
                width="300px"
                autoFocus
                backgroundColor='white'
              />
            </Column>
            <RowAlignCenter className="gap-x-4 mt-5">
              <Button onClick={handleButtonClick} backgroundColor="lgblue">
                <Text colorV="white" variant="title" weightV="normal">
                  Search
                </Text>
              </Button>
              <Button onClick={handleBulkGrading} backgroundColor="lgblue">
                <Text colorV="white" variant="title" weightV="normal">
                  Bulk grading
                </Text>
              </Button>
            </RowAlignCenter>
            {showSearchResults && searchData?.length === 0 && (
              <Text colorV="blue" variant="title" weightV="normal">
                No items found.
              </Text>
            )}
          </PrimaryCard>
        </Flex>
      )}
    </>
  );
};
