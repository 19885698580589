import React, { ReactNode } from 'react'
import { Pagebg } from '../../components/compound/Page/Pagebg'

interface AuthLayoutProps {
    children: ReactNode
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    return <Pagebg variant="brown">{children}</Pagebg>
}

export default AuthLayout
