import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { PrimaryCard } from '../Page/PrimaryCard';
import { Table } from '../../shared/Table';
import { useAuth } from '../../../utils/auth';
import moment from 'moment';
import { EventImeiHistory } from './EventImeiHistory';
import { ItemSearchModal } from '../../shared/SearchModal/ItemSearchModal';
import { usePost } from '../../../hooks/usePost';
import { EventNameEnum } from '../../../constant/SortingLogicEvent';
const baseUrl = process.env.REACT_APP_baseUrl;

export const ImeiHistory: React.FC<{ onClose: () => void; item: any; customer }> = ({
    onClose,
    item,
    customer,
}) => {
    const auth = useAuth();
    const token = auth?.user?.token?.token;
    const [clickedItem, setClickedItem] = useState<any>(null);
    const [apiResponse, setApiResponse] = useState<any>(null);
    const [showEventModal, setShowEventModal] = useState<boolean>(false)
    const { sendRequest } = usePost()
    let url = `${baseUrl}/history/item-history/${item?._id}`
    if (customer) {
        url = `${baseUrl}/history/customer-item-history/${item?._id}`
    }
    useEffect(() => {
        const fetchData = async () => {
            if (item) {
                try {
                    const response = await sendRequest(
                        url,
                        {},
                    );
                    setApiResponse(response?.data);
                } catch (error) {
                    toast.error(`Error: ${error?.response?.data}`, {
                        hideProgressBar: true,
                    });
                }
            }
        };
        fetchData();
    }, [item, token, onClose, url, sendRequest]);
    const columns = [
        'IMEI',
        'SKU',
        'Date',
        'Model',
        'VAT',
        'Status',
        'User',
        'Comment',
    ];
    let extractedData = [];
    if (apiResponse) {
        extractedData = apiResponse?.map((item) => {
            let sku = '';
            if ([EventNameEnum.Set_On_Sale, EventNameEnum.Sold, EventNameEnum.Customer_RMA_Claimed, EventNameEnum.WarrantyRepair, EventNameEnum.RMA_Accepted, EventNameEnum.RMA_Declined].includes(item?.event?.name) && !customer) {
                sku = item?.itemId?.sku?.sku;
            }
            console.log(item)
            return {
                Id: item?._id,
                IMEI: item?.imei,
                SKU: sku || " -- ",
                Date: new Date(item?.createdAt).toLocaleDateString(),
                Model: item?.itemId?.model?.modelName,
                VAT: item?.itemId?.vat,
                Status: item?.event?.name,
                User: item?.user?.email,
                Comment: (item.itemId?.comment === "undefined") ? '' : item.itemId?.comment,
            };
        }).reverse();
        
    }
    const handleItemClick = useCallback((clickedItem) => {
        setClickedItem(clickedItem);
        setShowEventModal(true)
    }, []);

    const handleClose = useCallback(() => {
        setShowEventModal(false)
    }, [])

    return (
        <>
            <ItemSearchModal width="auto" height="550px" heading='History' onClose={onClose}>
                <PrimaryCard className="w-full bg-b9white overflow-auto">
                    <Table
                        clickable={true}
                        columns={columns}
                        data={extractedData}
                        showActions={false}
                        handleRowClick={handleItemClick}
                    />
                </PrimaryCard>
            </ItemSearchModal>
            {/* <ModalBox heading="History" onClose={onClose} width="auto" > */}

            {/* </ModalBox> */}
            {showEventModal && <EventImeiHistory item={clickedItem} onClose={handleClose} />}
        </>
    );
};



