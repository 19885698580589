import React, { ChangeEvent, CSSProperties } from 'react';

interface TextAreaProps {
    name?: string;
    value?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    className?: string;
    borderRadius?: string;
    padding?: string;
    width?: string;
    inputStyle?: CSSProperties;
    backgroundColor?: string;
    icon?: boolean;
    height?: string; // Add height property
    color?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
    value,
    name,
    onChange,
    placeholder,
    className,
    borderRadius = '10px', // Provide a default value for borderRadius
    padding = '8px',
    inputStyle,
    backgroundColor = '#F2F0ED', // Set default background color here
    height = '100px', // Set the default height for the textarea
    color = '#112D56', // Set the default text color
}) => {
    const defaultInputStyle: CSSProperties = {
        backgroundColor: backgroundColor,
        borderRadius,
        padding,
        height,
        position: 'relative',
        color,
        resize: 'none', // Set the resize property to none
        fontFamily: 'Inter Tight, sans-serif', // Add font family here
        fontSize: '16px'
    };

    const mergedInputStyle: CSSProperties = {
        ...defaultInputStyle,
        ...inputStyle,
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        onChange(newValue);
    };

    return (
        <div className="text-field relative">
            <textarea
                name={name}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                className={className}
                style={mergedInputStyle}
            />
        </div>
    );
};
