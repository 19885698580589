import { Text } from '../../../base/Text';
import { Flex } from '../../../layout/Flex';
import { Row } from '../../../layout/Row';

export default function Head() {
    

    return (
        <Flex className="py-3 w-full">
            <Row className="w-full gap-x-7">
                <Text style={{width:'350px'}} colorV="blue" variant="head" weightV="bold">
                    RMA Archive 
                </Text>
            </Row>
        </Flex>
    );
}
