import React, { useCallback } from 'react';
import { ModalBox } from '../../../../base/ModelBox';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { Text } from '../../../../base/Text';
import { Button } from '../../../../base/Button';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as XLSX from 'xlsx';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};

export const ExportContent: React.FC<{ onClose: () => void, items: any }> = ({ onClose, items }) => {
    console.log(items);

    const handleSalePDF = useCallback(async () => {
        if (items?.length > 0) {
            const body = items?.map((item: any) => {
                return [
                    item?.sku?.sku || '',
                    item?.model?.modelName,
                    item?.sku?.storage,
                    item?.sku?.color,
                    item?.sku?.vat,
                    item?.imei,
                    item?.sortingEvent.name || '',
                    moment(item?.createdAt).format('DD.MM.YYYY'),
                ];
            });
            body.unshift([
                'Sku',
                'Model',
                'Storage',
                'Color',
                'VAT',
                'IMEI',
                'Stauts',
                'Date'
            ]);
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            };
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('sale-items-PDF');
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [items]);

    const handleSaleXLSX = useCallback(async () => {
        if (items?.length > 0) {
            const xlsxArray = items.map((element) => ({
                Sku: element?.sku?.sku || '',
                Model: element?.model?.modelName,
                Storage: element?.sku?.storage,
                Color: element?.sku?.color,
                Vat: element?.sku?.vat,
                Imei: element?.imei,
                Stauts: element?.sortingEvent.name || '',
                Date: moment(element?.createdAt).format('DD.MM.YYYY'),
            }));

            const ws = XLSX.utils.json_to_sheet(xlsxArray);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sale Items');

            XLSX.writeFile(wb, 'sale-items.xlsx');
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [items]);

    return (
        <ModalBox heading="Export" onClose={onClose}>
            <RowAlignCenter className="text-center gap-x-4">
                <Button
                    onClick={handleSalePDF}
                    backgroundColor="purple"
                >
                    <Text colorV="white" variant="title" weightV="normal">
                        Export PDF
                    </Text>
                </Button>
                <Button
                    onClick={handleSaleXLSX}
                    backgroundColor="blue"
                >
                    <Text colorV="white" variant="title" weightV="normal">
                        Export XLSX
                    </Text>
                </Button>
            </RowAlignCenter>
        </ModalBox>
    );
}
