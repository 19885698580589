import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '../../../../layout/Flex';
import { Table } from '../../../../shared/Table';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import moment from 'moment';
import { EditInventory } from '../../../Inventory/Inhouse/components/EditInventoryModel';
import { DeleteInventory } from '../../../Inventory/Inhouse/components/DeleteInventoryModel';
import { WarrantyModal } from './WarrantyModal';
import { PrintLabel } from '../../../Inventory/Inhouse/components/PrintLabel';

const baseUrl = process.env.REACT_APP_baseUrl;

export default function WarrantyTable({ itemsData,  refetch }: any) {
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isPrintLabel, setIsPrintLabel] = useState(false)
    const [isRepair, setIsRepair] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState<any>();

    let extractedData = [];
    if (itemsData) {
        extractedData = itemsData?.map((item) => {
            return {
                Id: item?._id,
                IMEI: item?.imei,
                Date: moment(item?.createdAt).format('DD/MM/YYYY'),
                Model: item?.model?.modelName,
                Storage: item?.storage,
                VAT: item?.vat,
                Stauts: item?.sortingEvent?.name,
                Code: item?.codes.toString(),
                Comment: item?.comment !== "undefined" ? item?.comment : "",
                Repair: item?.repair,
                item: item
            };
        });
    }

    const columns = ['IMEI', 'Date', 'Model', 'Storage', 'VAT', 'Stauts', 'Code'];

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setSelectedOption(null);
        setIsEdit(false);
        setIsDelete(false);
        setIsRepair(false);
        setIsPrintLabel(false);
        refetch()
    }, [refetch]);

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item);
        setSelectedOption(optionValue);
        if (optionValue === 'Edit') {
            setIsEdit(true);
            setIsDelete(false);
            setIsRepair(false);
            setIsPrintLabel(false);
        } else if (optionValue === 'Delete') {
            setIsDelete(true);
            setIsEdit(false);
            setIsRepair(false);
            setIsPrintLabel(false); // Corrected to false
        }
        else if (optionValue === 'Repair') {
            setIsRepair(true);
            setIsEdit(false);
            setIsDelete(false);
            setIsPrintLabel(false); // Corrected to false
        } else if (optionValue === 'Print label') { // Added condition for 'Print label'
            setIsPrintLabel(true);
            setIsEdit(false);
            setIsDelete(false);
            setIsRepair(false);
        } else {
            setIsEdit(false);
            setIsDelete(false);
            setIsRepair(false);
            setIsPrintLabel(false);
        }
        setIsModalOpen(true);
    };
    

    const handleRowClick = (item) => {
        setIsRepair(true);
        setSelectedOption('Repair');
        setSelectedModel(item);
    };

    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditInventory item={selectedModel} onClose={closeModal} />;
        } else if (isDelete) {
            return <DeleteInventory item={selectedModel} onClose={closeModal} />;
        } else if (isPrintLabel) {
            return <PrintLabel item={selectedModel} onClose={closeModal} />;
        }
    }, [isEdit, isDelete, isPrintLabel, selectedModel, closeModal]);

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        options={['Print label', 'Edit', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        clickable={true}
                        handleRowClick={handleRowClick}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
            {isModalOpen && (renderModalComponent()
            )}
            {isRepair && (
                <WarrantyModal item={selectedModel} onClose={closeModal} />
            )}
        </>
    );
}
