import React, { ReactNode } from 'react'
import { Column } from '../../components/layout/Col'
import { ProductNavbar } from '../Navbars/ProductNavbar'
import { Fill } from '../../components/layout'

interface CustomerLayoutProps {
    children: ReactNode
}

export const ProductLayout: React.FC<CustomerLayoutProps> = ({ children }) => {
    const initialNavItems = [
        { text: 'Home', icon: 'home', to: '/home', iconSize: 20 },
        {
            text: 'User Management',
            icon: 'rma',
            to: '/user-management',
            iconSize: 20,
        },
    ]
    const belowNavItems = [
        { text: 'Logout', icon: 'logout', to: '/logout', iconSize: 20 },
    ]

    return (
        <>
            <div style={{ position: 'fixed', top: 20, left: 20, bottom: 20 }}>
                <Column className="flex h-full">
                    <ProductNavbar initialNavItems={initialNavItems} />
                    <Fill />
                    <ProductNavbar
                        showLogo={false}
                        initialNavItems={belowNavItems}
                    />
                </Column>
            </div>
            <div style={{ marginLeft: 300, marginRight: 20, marginTop: 20 }}>
                {children}
            </div>
        </>
    )
}
