import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import moment from 'moment'
import { PrintLabel } from '../../../Inventory/Inhouse/components/PrintLabel'
import { generatePDF } from '../../../../shared/GenerateLabel'
import { useFetch } from '../../../../../hooks/useFetch'
import JsBarcode from 'jsbarcode'
import { EventNameEnum } from '../../../../../constant/SortingLogicEvent'
const baseUrl = process.env.REACT_APP_baseUrl

export default function RmaTable({
    data,
    codesData,
    onSelectedItemsChange,
}: any) {
    const [selectedItems, setSelectedItems] = useState<any>([])
    const [allSelected, setAllSelected] = useState(false)
    const barCode = useRef<HTMLDivElement>(null)
    console.log({ selectedItems })

    const extractedData = data
        ?.map((item) => {
            const createdAt = moment(item.createdAt)
            const currentDate = moment()
            const differenceInDays = currentDate.diff(createdAt, 'days')
            const totalDays = Math.max(
                parseInt(item.rmaSupplier) - differenceInDays,
                0
            ) // Ensure totalDays is not below 0
            const issues = item?.codes?.map((code) => {
                const matchingCode = codesData?.find(
                    (data) => data.label === code
                )
                return matchingCode ? matchingCode.description : code
            })
            return {
                item: item,
                id: item?._id,
                Supplier: item?.supplier?.companyName,
                'RMA Batch#': item?.batchNumber,
                'Invoice number': item?.invoiceNumber,
                IMEI: item?.imei,
                Status: item?.sortingEvent.name,
                'Days left': totalDays,
                Issue: item.codes.toString(),
                'Issue Description': issues.join(', '),
            }
        })
        .filter((item) => item.Status !== EventNameEnum.My_RMA_Sent_To_Supplier)

    const handleCheckboxChange = useCallback(
        (e, selectedItem) => {
            if (e.target.checked) {
                setSelectedItems((prevSelectedItems) => {
                    if (
                        !prevSelectedItems.some(
                            (item) => item.id === selectedItem.id
                        )
                    ) {
                        return [...prevSelectedItems, selectedItem]
                    }
                    return prevSelectedItems
                })
            } else {
                setSelectedItems((prevSelectedItems) =>
                    prevSelectedItems.filter(
                        (item) => item.id !== selectedItem.id
                    )
                )
            }
        },
        [setSelectedItems]
    )

    useEffect(() => {
        onSelectedItemsChange(selectedItems)
        const allSelected =
            extractedData?.length > 0 &&
            extractedData.every((item) =>
                selectedItems.some((selectedItem) => item.id == selectedItem.id)
            )
        setAllSelected(allSelected)
        console.log(extractedData)
    }, [onSelectedItemsChange, selectedItems, extractedData])

    const handleAllChange = useCallback((e, allItems) => {
        console.log({ allItems })
        const allSelectedIds = allItems.map((item) => item)

        if (e.target.checked) {
            setSelectedItems(allSelectedIds) // Select all items
        } else {
            setSelectedItems([]) // Deselect all items
        }
    }, [])

    const columns = [
        'Supplier',
        'RMA Batch#',
        'Invoice number',
        'IMEI',
        'Status',
        'Days left',
        'Issue',
        'Issue Description',
    ]

    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    )

    useEffect(() => {
        getLabels()
    }, [getLabels])

    const handleDropdownChange = useCallback(
        (selectedOption, item) => {
            JsBarcode('#barcode', `${item?.item?.imei}`, {
                lineColor: 'black',
                textAlign: 'center',
                width: 1,
                background: 'transparent',
                height: 40,
                displayValue: false,
                xmlDocument: document,
            })
            generatePDF(item, labelsData, barCode)
        },
        [labelsData]
    )

    if (!extractedData) return <></>

    return (
        <>
            <Flex className="w-full ">
                <PrimaryCard className="w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <input
                                        type="checkbox"
                                        checked={allSelected}
                                        onChange={(e) =>
                                            handleAllChange(e, extractedData)
                                        }
                                    />
                                </th>

                                {columns.map((column) => (
                                    <th
                                        key={column}
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        {column}
                                    </th>
                                ))}

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {extractedData.map((item) => (
                                <tr key={item.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <input
                                            type="checkbox"
                                            checked={selectedItems.some(
                                                (selectedItem) =>
                                                    selectedItem.id === item.id
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(e, item)
                                            }
                                        />
                                    </td>

                                    {columns.map((column) => (
                                        <td
                                            key={column}
                                            className="px-6 py-4 whitespace-nowrap"
                                        >
                                            {item?.[column]}
                                        </td>
                                    ))}

                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button
                                            onClick={() => {
                                                generatePDF(
                                                    item,
                                                    labelsData,
                                                    barCode
                                                )
                                            }}
                                        >
                                            Print Label
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </PrimaryCard>
            </Flex>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </>
    )
}
