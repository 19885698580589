import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

interface LineChartProps {
  data?: any;
  labels?: any;
  gradientColors?: string[]; // Array of colors for the linear gradient
}

export const SingleDatasetLineChart: React.FC<LineChartProps> = ({ data, labels, gradientColors = '#3B65F680', }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    if (!ctx) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, chartRef.current.height);
    gradient?.addColorStop(0, `${gradientColors}`); // Start color
    gradient?.addColorStop(1, 'rgba(255, 255, 255, 0)');

    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: '',
          data: data,
          borderColor: gradientColors, // Set borderColor to the linear gradient
          borderWidth: 1,
          pointRadius: 1, // Remove dots
          fill: {
            target: 'origin',
            above: gradient,  // Area will be filled with gradient color above the origin
          },
        }]
      },
      options: {
        scales: {
          y: {
            grid: {
              display: false,
          },
            beginAtZero: true, // Force the y-axis to begin at zero
          },
          x: {
            grid: {
              display: false,
          },
            beginAtZero: true, // Force the y-axis to begin at zero
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, labels, gradientColors]);

  return <canvas ref={chartRef} />;
};
