import React, { useState, useEffect, useRef } from 'react';
import { MyIcon } from '../MyIcon';

interface SearchBarProps {
  width?: string;
  onSearch?: (searchTerm: string) => void;
  onSearchBarClick?: () => void; // Add onSearchBarClick prop
  zIndex?: string;
  disabled?: boolean;
  className?: string;
  inputRef?:any
}

export const SearchBar: React.FC<SearchBarProps> = ({
  width = '550px',
  onSearch,
  onSearchBarClick,
  zIndex,
  disabled,
  className,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputStyle: React.CSSProperties = {
    flex: 1,
    border: 'none',
    background: 'none',
    outline: 'none',
    padding: '5px',
    color: '#112D56B2',
    width: width,
    fontSize: '13px',
  };

  const iconStyle: React.CSSProperties = {
    marginRight: '5px',
    paddingLeft: '5px',
    fontSize: '20px',
    color: 'white',
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    setSearchTerm(inputText);
    setLoading(true);

    setTimeout(() => {
      onSearch && onSearch(inputText);
      setLoading(false);
    }, 1000);
  };

  const handleSearchBarClick = () => {
    onSearchBarClick && onSearchBarClick();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      style={{ height: 40, zIndex: zIndex }}
      className={`flex items-center rounded border bg-base01 p-2 ${className}`}
      onClick={handleSearchBarClick}
    >
      <span style={iconStyle}>
        <MyIcon name="search" size={20} />
      </span>
      <input
        ref={inputRef}
        type="text"
        disabled={disabled}
        style={inputStyle}
        placeholder="Search..."
        className="w-full"
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};
