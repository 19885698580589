import { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../../../base/Button';
import { Text } from '../../../../../base/Text';
import { TextField } from '../../../../../base/Textfield';
import { Column } from '../../../../../layout/Col';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { toast } from 'react-toastify';
import { TextBackground } from '../../../../../base/TextBackground';
import { useFetch } from '../../../../../../hooks/useFetch';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { Row } from '../../../../../layout/Row';
import { TextArea } from '../../../../../base/Textarea';
import { usePost } from '../../../../../../hooks/usePost';
import { Icon } from '../../../../../base/Icon';
import { addNotification } from '../../../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl;

export const Send = () => {
  const [imei, setImei] = useState<any>();
  const [imeis, setImeis] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState('')
  const [comment, setComment] = useState('')

  const { sendRequest } = usePost()

  const { getRequest, data } = useFetch(`${baseUrl}/partner/get-partners`);
  useEffect(() => {
    getRequest()
  }, [getRequest])
  console.log({ data });

  const handleImeiChange = (newValue: string) => {
    setImei(newValue)
  };

  const handleVendorChange = (event: any) => {
    setVendor(event.target.value)
  }

  const handleCommentChange = (newValue: any) => {
    setComment(newValue)
  }

  const handleAddImei = useCallback(() => {
    setImeis([...imeis, imei]);
    setImei('')
  }, [imei, imeis])


  const handleSend = useCallback(async (e: any) => {
    e.preventDefault();
    if (!imeis || imeis.length === 0) {
      toast.error('Please add at least one IMEI');
      return;
    }
    if (!vendor) {
      toast.error('Please add vendor');
      return;
    }
    setIsLoading(true);
    const urlItem = `${baseUrl}/transit/send-transit`;
    try {
      const response = await sendRequest(urlItem, {
        imeis,
        comment,
        vendor
      });
      if (response?.data) {
        const { successes, errors } = response.data;
        console.log(response.data);
        successes.forEach(success => {
          toast.success(`${success.imei}: ${success.message}`, { hideProgressBar: true });
          addNotification({
            message: `IMEIs sent to transit: ${success.imei}`,
            type: 'success',
          });
        });
        errors.forEach(error => {
          toast.error(`${error.imei}: ${error.message}`, { hideProgressBar: true });
        });
        setImeis([]);
        setComment('');
        setVendor('');
      } else {
        toast.error('Error: Failed to get response data', { hideProgressBar: true });
      }
    } catch (error) {
      toast.error(`Error: ${error?.response?.data?.message}`, {
        hideProgressBar: true,
      });
    } finally {
      setIsLoading(false);
      setImeis([])
    }
  }, [comment, imeis, sendRequest, vendor]);

  const handleRemoveImei = (imeiToRemove) => {
    const updatedImeis = imeis.filter((imei) => imei !== imeiToRemove);
    setImeis(updatedImeis);
  };
  console.log(imeis);
  return (
    <>
      <Row className='gap-x-10 w-full'>
        <Column className="w-8/12">
          <PrimaryCard className='p-5 '>
            <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'> Scan IMEI</Text>
            <div className='flex mt-10 mb-16 h-20 items-center justify-center'>
              <div className=" h-28 px-4  rounded bg-white relative border-radius-5 w-full  my-auto">
                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', left: '0', borderRadius: '5px 0 0 0', borderTop: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', right: '0', borderRadius: '0 5px 0 0', borderTop: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', right: '0', borderRadius: '0 0 5px 0', borderBottom: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', left: '0', borderRadius: '0 0 0 5px', borderBottom: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                <Row className="gap-x-3 mt-10 items-center justify-center">
                  <TextField
                    value={imei}
                    onChange={handleImeiChange}
                    placeholder="IMEI"
                    width="20rem"
                    autoFocus
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent form submission
                        handleAddImei();
                      }
                    }}
                  />
                  <Button
                    onClick={handleAddImei}
                    className=''
                    backgroundColor="lgblue"
                    disabled={isLoading}
                  >
                    <Text colorV="white" variant="title" weightV="normal">
                      Add IMEI
                    </Text>
                  </Button>
                </Row>
              </div>
            </div>

          </PrimaryCard>
        </Column>
        <Column className="w-1/2">
          <PrimaryCard className='p-5 '>
            <RowAlignCenterSpaced className='mb-7'>
              <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'>
                IMEI list
              </Text>
              <Column className='my-3 w-72'>
                {imeis.map((imei) => (
                  <RowAlignCenterSpaced key={imei} className='gap-x-20 my-1'>
                    <Text colorV="blue" variant="title" weightV="bold">
                      {imei}
                    </Text>
                    <Icon
                      className='hover:cursor-pointer'
                      onClick={() => handleRemoveImei(imei)}
                      name="cross" color="black" size={20} />
                  </RowAlignCenterSpaced>
                ))}
              </Column>
            </RowAlignCenterSpaced>
            <RowAlignCenterSpaced className='mb-7'>
              <Text colorV="blue" variant="title" weightV="normal">
                Supplier
              </Text>
              {data?.length > 0 ? (
                <div style={{ width: '18rem' }}>
                  <select
                    id="supplier"
                    value={vendor}
                    onChange={handleVendorChange}
                    className="w-full border-none hover:cursor-pointer  rounded-md py-2 px-1 bg-white"
                  >
                    <option>Select</option>
                    {data?.map((item) => (
                      <option key={item?._id} value={item?._id}>
                        {item?.companyName}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <TextBackground className='w-72' backgroundColor='b9white'>
                  Please, add vendor
                </TextBackground>
              )}
            </RowAlignCenterSpaced>
            <Row className='justify-between'>
              <Text colorV="blue" variant="body" weightV="bold">
                Comment
              </Text>
              <TextArea
              backgroundColor='white'
                className='w-72 '
                value={comment}
                onChange={handleCommentChange}
                placeholder="write comment ..."
              />
            </Row>
            <Row className='justify-end my-4'>
              {imeis.length > 0 &&
                <Button
                  onClick={handleSend}
                  className='w-72'
                  backgroundColor="lgblue"
                  disabled={isLoading}
                >
                  <Text colorV="white" variant="title" weightV="normal">
                    {isLoading ? 'Loading...' : 'Send to transit'}
                  </Text>
                </Button>
              }
            </Row>

          </PrimaryCard>
        </Column>
      </Row>

    </>
  );
};
