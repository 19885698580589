import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { Button } from '../../../base/Button'
import { ModalBox } from '../../../base/ModelBox'
import { Text } from '../../../base/Text'
import { TextBackground } from '../../../base/TextBackground'
import { Column } from '../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { Row } from '../../../layout/Row'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { usePost } from '../../../../hooks/usePost'

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
const baseUrl = process.env.REACT_APP_baseUrl

export const InvoiceModal: React.FC<{ onClose: () => void; item: any; itemsData: any }> = ({
    onClose,
    item,
    itemsData
}) => {
    const { sendRequest } = usePost()
    const handleDownload = useCallback(async() => {
        const invoiceNumber = item?.['Invoice Number'];
        const urlItem = `${baseUrl}/inventory/items-pre-invoice`
        const matchedData:any = await sendRequest(
            urlItem,
            {
                invoiceNumber
            },
        )
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data}`, {
                    hideProgressBar: true,
                })
            })
        const csvArray = []
        if (matchedData?.data?.length > 0) {
            matchedData?.data?.forEach((itm) => {
                csvArray.push({
                    Imei: itm?.imei || '',
                    Model: itm?.model?.modelName || "",
                    Storage: itm?.storage || "",
                    Grade: itm?.grade?.grade || ""
                });
            });
            let csv = ' '
            for (let row = 0; row < csvArray?.length; row++) {
                const keysAmount = Object.keys(csvArray[row]).length
                let keysCounter = 0
                if (row === 0) {
                    for (const key in csvArray[row]) {
                        csv +=
                            key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                }
                keysCounter = 0
                for (const key in csvArray[row]) {
                    csv +=
                        csvArray[row][key] +
                        (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
                keysCounter = 0
            }
            const data = new Blob([csv], { type: 'text/csv' })
            saveCsv(data, 'invoice.csv')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [item, sendRequest]);

    function saveCsv(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = filename
        a.click()
    }
    return (
        <ModalBox
            heading="Invoice detail"
            onClose={onClose}
            top="50%"
            height="auto"
            width="430px"
        >
            <Column className="mt-3 gap-y-4 h-[290px] overflow-auto">
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Invoice number
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.['Invoice Number']}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Supplier
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.Supplier}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Received
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.Received}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        RMA
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        <Text colorV="mid-blue" variant="title" weightV="normal">
                            {item?.RMA}

                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <Row className="gap-x-4 mt-4">
                    <Button
                        className='w-52'
                        onClick={handleDownload}
                        backgroundColor="lgblue"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Download IMEI list
                        </Text>
                    </Button>

                </Row>
            </Column>
        </ModalBox>
    )
}
