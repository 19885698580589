import { useState } from 'react'
import { Button } from '../../../../base/Button'
import { Dropdown } from '../../../../base/Dropdown'
import { PrimaryCard } from '../../../../compound/Page/PrimaryCard'
import { Column } from '../../../../layout/Col'
import { Row } from '../../../../layout/Row'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { FileUpload } from '../../../../shared/Fileupload'
import { Text } from '../../../../base/Text'
import { Icon } from '../../../../base/Icon'
export const GraphInsights = () => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null)
    const handleFileUpload = (file: File) => {
        // Do something with the uploaded file, such as storing it in state
        setUploadedFile(file)
    }

    return (
        <>
            <RowAlignCenterSpaced className="gap-x-2 mt-5 w-full">
                <PrimaryCard >
                    <RowAlignCenterSpaced className="mb-5">
                        <Text colorV="blue" variant="title" weightV="bold">
                            Report
                        </Text>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Column>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Calander here
                            </Text>
                        </Column>
                        <Column>
                            <RowAlignCenterSpaced className="mb-4">
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="bold"
                                >
                                    Report
                                </Text>
                                <Dropdown
                                    label=""
                                    options={['Week', 'Day', 'Month']}
                                    onChange={(selectedOption) => {
                                        // Handle the selected option here
                                        console.log(
                                            `Selected option: ${selectedOption}`
                                        )
                                    }}
                                />
                            </RowAlignCenterSpaced>
                            <Column className="gap-y-3">
                                <Row>
                                    <FileUpload
                                        height="191px"
                                        width="471px"
                                        onFileUpload={handleFileUpload}
                                        backgroundColor="#FFFFFF"
                                    />
                                    {uploadedFile && (
                                        <Text
                                            colorV="blue"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            Uploaded File: {uploadedFile.name}
                                        </Text>
                                    )}
                                </Row>
                                <Row>
                                    <FileUpload
                                        height="191px"
                                        width="471px"
                                        onFileUpload={handleFileUpload}
                                        backgroundColor="#FFFFFF"
                                    />
                                    {uploadedFile && (
                                        <Row className='gap-x-3 items-center'>
                                        <Text colorV="blue" variant="title" weightV="normal">
                                            Uploaded File: {uploadedFile.name}
                                        </Text>
                                        <span className='cursor-pointer' onClick={() => setUploadedFile(null)}>
                                            <Icon name="cross" color="black" size={16} />
                                        </span>
                                    </Row>
                                    )}
                                </Row>
                            </Column>
                        </Column>
                    </RowAlignCenterSpaced>
                    <Row>
                        <Button backgroundColor="lgblue">
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Download
                            </Text>
                        </Button>
                    </Row>
                </PrimaryCard>
            </RowAlignCenterSpaced>
        </>
    )
}
