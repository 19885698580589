import React, { useState } from 'react'
import { Button } from '../../../base/Button'
import { Text } from '../../../base/Text'
import { Flex } from '../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { RowAlignCenter } from '../../../layout/RowAlignCenter'
import { UploadNewCreditNote } from './component/UploadNewCreditNote'
import { useNavigate } from 'react-router-dom'
import { CreditNoteArchive } from './Archive/CreditNotesArchive'
export const Head: React.FC<{ refetchData: () => void }> = ({ refetchData }) => {
    const [isUploadComponentOpen, setIsUploadComponentOpen] = useState(false)
    const navigate = useNavigate()

    const openUploadComponent = () => {
        setIsUploadComponentOpen(true)
    }

    const openArchiveComponent = () => {
        navigate('archive')
    }

    const closeUploadComponent = () => {
        setIsUploadComponentOpen(false)
        refetchData()
    }


    return (
        <Flex className="  w-full">
            <RowAlignCenterSpaced className="gap-x-4  w-full">
                <Text
                    style={{ width: '250px' }}
                    colorV="blue"
                    variant="head"
                    weightV="bold"
                >
                    Credit notes
                </Text>
                <RowAlignCenter className='gap-x-4'>
                    <Button onClick={openUploadComponent}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Upload my credit note
                        </Text>
                    </Button>
                    <Button
                        backgroundColor="yellow"
                        onClick={openArchiveComponent}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            My Archive
                        </Text>
                    </Button>
                </RowAlignCenter>
            </RowAlignCenterSpaced>

            {isUploadComponentOpen && (
                <UploadNewCreditNote onClose={closeUploadComponent} />
            )}
            {/* {isArchiveComponentOpen && (
                <CreditNoteArchive onClose={closeArchiveComponent} />
            )} */}
        </Flex>
    )
}
