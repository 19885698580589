import React, { useCallback, useEffect, useState } from 'react'
import { operationSystemList } from '../../../../../../../constant/Storage'
import { Column } from '../../../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../../../base/Text'
import { useFetch } from '../../../../../../../hooks/useFetch'
import { Button } from '../../../../../../base/Button'
import { Flex } from '../../../../../../layout/Flex'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../../utils/auth'
import { ModalBox } from '../../../../../../base/ModelBox'
import { TextBackground } from '../../../../../../base/TextBackground'
import Select from 'react-select'
import { usePost } from '../../../../../../../hooks/usePost'
import MultiSelect from '../../../../../../base/MultiSelect/MultiSelect'
import { addNotification } from '../../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl;

export const EditCodeEvent: React.FC<{ onClose: () => void; selectedCode: any }> = ({ onClose, selectedCode }) => {
    const [models, setModels] = useState<string[]>([])
    const [operatingSystem, setOperatingSystem] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [codes, setCodes] = useState([])
    const [localCodes, setLocalCodes] = useState<any[]>([]);
    const [prevCodes, setPrevCodes] = useState<any[]>([]);
    const [prevModels, setPrevModels] = useState<any[]>([]);
    const [selectedModels, setSelectedModels] = useState<any[]>([])
    const [selectedCodes, setSelectedCodes] = useState<any[]>([])


    const auth = useAuth();
    const { sendRequest } = usePost()
    const token = auth?.user?.token?.token;

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )
    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    )

    useEffect(() => {
        getCodes()
    }, [getCodes])

    useEffect(() => {
        getModels()
    }, [getModels])
    useEffect(() => {
        if (codesData) {
            setLocalCodes(codesData.map((code: any) => ({
                label: code.label,
                value: code._id
            })));
        }
        if (selectedCode) {
            setSelectedCodes(selectedCode?.item?.code?.map((code) => code._id))
            setSelectedModels(selectedCode?.item?.models.map((model) => model._id))
            setPrevCodes(selectedCode?.item?.code)
            setPrevModels(selectedCode?.item?.models)
            setOperatingSystem(selectedCode?.item?.operatingSystem)
        }
    }, [codesData, modelsData, selectedCode])

    useEffect(() => {
        const localModel = []
        modelsData?.forEach((element) => {
            localModel.push({ label: element?.modelName, value: element?._id })
        })
        setModels(localModel)
    }, [modelsData])

    const handleModelChange = useCallback((e: any) => {
        setSelectedModels(Array.isArray(e) ? e.map((model) => model?.value) : [])
    }, [])

    const handleCodeChange = useCallback((e: any) => {
        console.log(e);
        setSelectedCodes(e)
    }, []);

    const handleOperatingSystemChange = (event: any) => {
        const selectedOS = event.target.value;
        setOperatingSystem(selectedOS);
    };

    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault();
        if (!models || !codes || !operatingSystem) {
            alert('Please fill in all fields.');
            return;
        }
        setIsLoading(true);
        const urlItem = `${baseUrl}/sorting-logic/edit-code-and-add-to-event/${selectedCode?.Id}`;
        try {
            const modelsParsed =
                typeof selectedModels[0] === 'string'
                    ? selectedModels
                    : selectedModels.map((model) => model?.value)
            const response = await sendRequest(
                urlItem,
                {
                    models: modelsParsed,
                    code: selectedCodes,
                    operatingSystem
                },
            );
            if (response?.data) {
                toast.success(response?.data?.message || 'Added Successfully', { hideProgressBar: true });
                addNotification({
                    message: 'Code has been updated successfully',
                    type: 'success',
                });
                onClose();
            } else {
                toast.error(response?.data?.error || 'Something went wrong', { hideProgressBar: true });
            }
        } catch (error) {
            toast.error(`Error: ${error?.response?.data?.error || 'Something went wrong'}`, {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [models, codes, operatingSystem, selectedCode?.Id, selectedModels, sendRequest, selectedCodes, onClose])

    const items = []

    if (prevModels) {
        for (const model of prevModels) {
            const item = models?.find(
                (instance: any) => instance?.label === model?.modelName
            )
            if (item) {
                items.push(item)
            }
        }
    }

    const itemCodes = []

    if (prevCodes) {
        for (const code of prevCodes) {
            const item = localCodes?.find(
                (instance: any) => instance?.label === code?.label
            )
            if (item) {
                itemCodes.push(item?.value)
            }
        }
    }
    return (
        <ModalBox
            heading="Edit code event"
            onClose={onClose}
            top="50%"
            height="auto"
            width="600px"
        >
            <Column className="mt-3 gap-y-4  overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Code
                    </Text>
                    <div style={{ width: '22rem' }}>
                        {itemCodes?.length > 0 &&
                            itemCodes?.length === prevCodes?.length && (<MultiSelect
                                defaultValues={itemCodes}
                                options={localCodes}
                                onChange={handleCodeChange}
                                isMulti
                                placeholder="Select codes..."
                            />)}
                        {
                            prevModels?.length === 0 && (
                                <MultiSelect
                                    defaultValues={itemCodes}
                                    options={localCodes}
                                    onChange={handleCodeChange}
                                    isMulti
                                    placeholder="Select codes..."
                                />
                            )
                        }
                        {/* {itemCodes?.length > 0 &&
                            itemCodes?.length === prevCodes?.length && (
                                <Select
                                    id="code"
                                    defaultValue={itemCodes}
                                    options={localCodes}
                                    onChange={handleCodeChange}
                                    isMulti
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            )}
                        {prevModels?.length === 0 && (
                            <Select
                                id="code"
                                defaultValue={itemCodes}
                                options={localCodes}
                                onChange={handleCodeChange}
                                isMulti
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                    }),
                                }}
                            />
                        )} */}
                    </div>
                </RowAlignCenterSpaced>

                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Operating system
                    </Text>
                    <div style={{ width: '22rem' }}>
                        <select
                            id="os"
                            value={operatingSystem}
                            onChange={handleOperatingSystemChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {operationSystemList?.map((os: any) => (
                                <option key={os} value={os}>
                                    {os}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>

                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <div style={{ width: '22rem' }}>
                        {items.length > 0 &&
                            items.length === prevModels?.length && (
                                <Select
                                    id="model"
                                    defaultValue={items}
                                    options={models}
                                    onChange={handleModelChange}
                                    isMulti
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            )}
                        {prevModels?.length === 0 && (
                            <Select
                                id="model"
                                defaultValue={items}
                                options={models}
                                onChange={handleModelChange}
                                isMulti
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                    }),
                                }}
                            />
                        )}
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button
                        className='w-32'
                        backgroundColor="lgblue"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Saving...' : 'Save'}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
