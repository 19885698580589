import update from 'immutability-helper';
import { useCallback, useState, FC, useEffect } from 'react';
import { Card } from './Card';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Text } from '../../../../../base/Text';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../utils/auth';
import { useEdit } from '../../../../../../hooks/useEdit';
import { addNotification } from '../../../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl

export interface Item {
  _id: number;
  name: string;
  type: string;
}

export interface ContainerState {
  cardItems: Item[];
  refetch: () => void;
  handleItemRowClick: (id: any) => void;
}

interface ContainerProps {
  cardItems: any[]; // Update this type according to the actual structure of your card items
}

export const Container: FC<ContainerState> = ({ cardItems, refetch, handleItemRowClick }) => {
  const [cards, setCards] = useState<Item[]>(cardItems);
  const [modifiedOrder, setModifiedOrder] = useState<number[] | null>(null);

  const auth = useAuth()
  const token = auth?.user?.token?.token
  const { sendEditRequest } = useEdit()
  useEffect(() => {
    setCards(cardItems);
  }, [cardItems]);
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newOrder = update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, cards[dragIndex]],
        ],
      });
      setCards(newOrder);
      setModifiedOrder(newOrder.map((card) => card._id));
    },
    [cards],
  );
  
  const renderCard = useCallback(
    (card: { _id: number; name: string; type: string }, index: number) => {
      return (
        <Card
          key={card._id}
          index={index}
          id={card._id}
          name={card.name}
          type={card.type}
          moveCard={moveCard}
          refetch={refetch}
          cardItems={cardItems}
          handleItemRowClick={handleItemRowClick}
        />
      );
    },
    [cardItems, handleItemRowClick, moveCard, refetch],
  );

  const handleSave = useCallback(
    async (modifiedOrder) => {
      const urlInventoryItem = `${baseUrl}/sorting-logic/update-manual-sorting-index`
      try {
        const response = await sendEditRequest(
          urlInventoryItem,
          {
            modifiedOrder,
          },
        )
        toast.success(response?.data?.message, {
          hideProgressBar: true,
        })
        addNotification({
          message: 'Manual sorting has been updated successfully',
          type: 'success',
        })
      } catch (error) {
        console.error(error)
        toast.error(`Error: ${error?.response?.data?.message}`, {
          hideProgressBar: true,
        })
      }
    },
    [sendEditRequest]
  );

  useEffect(() => {
    if (modifiedOrder) {
      handleSave(modifiedOrder);
    }
  }, [modifiedOrder, handleSave]);
  return (
    <>
      <PrimaryCard variant='white' className='p-3'>
        <RowAlignCenterSpaced className='my-1 p-2.5 bg-base01 bg-opacity-50 rounded'>
          <Text colorV='blue' variant='title' weightV='bold' className='flex-1'>
            Status Name
          </Text>
          <Text colorV='blue' variant='title' weightV='bold' className='flex-1'>
            Type
          </Text>
          <Text colorV='blue' variant='title' weightV='bold'>
            Actions
          </Text>
        </RowAlignCenterSpaced>
        <div>
          {cards?.map((card, i) => renderCard(card, i))}</div>
      </PrimaryCard>
    </>
  );
};
