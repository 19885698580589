import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { ValueCard } from './ValueCard'

export const TotalInsights = ({data}:any) => {
console.log({data});
    return (
        <>
            <RowAlignCenterSpaced className="gap-x-2 w-full">
                <ValueCard
                    title="Total diagnostics completed"
                    currencyIcon="diagnostics"
                    count={data?.diagnosticsCount}
                    change="+ 0.25"
                    percentage = "35%"
                    comparedText="Compared to Jan 2022"
                />
                <ValueCard
                    title="Total repaired devices"
                    currencyIcon="repair"
                    count={data?.repairCount }
                    change="+ 0.25"
                    percentage = "35%"
                    comparedText="Compared to Jan 2022"
                />
                 <ValueCard
                    title="Total devices polished"
                    currencyIcon="polish"
                    count={data?.polishedCount}
                    change="+ 0.25"
                    percentage = "35%"
                    comparedText="Compared to Jan 2022"
                />
                <ValueCard
                    title="Total graded completed"
                    currencyIcon="diagnostics"
                    count={data?.gradedCount}
                    change="+ 0.25"
                    percentage = "35%"
                    comparedText="Compared to Jan 2022"
                />
            </RowAlignCenterSpaced>
        </>
    )
}
