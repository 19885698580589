import React from 'react';

interface MyIconProps {
  name: string;
  size?: number;
  color?: string;
}

export const MyIcon: React.FC<MyIconProps> = ({ name, size = 24, color = 'black' }) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const iconPath = require(`../../../assets/Icons/${name || 'search'}.svg`);

  return (
    <img
      src={iconPath}
      alt={name}
      width={size}
      height={size}
      style={{ color }}
    />
  );
};
