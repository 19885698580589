import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { ModalBox } from '../../../../base/ModelBox';
import { usePost } from '../../../../../hooks/usePost';
import { Button } from '../../../../base/Button';
import { TextField } from '../../../../base/Textfield';
import { Column } from '../../../../layout/Col';
import { Row } from '../../../../layout/Row';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import { Text } from '../../../../base/Text';
import { useEdit } from '../../../../../hooks/useEdit';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
const baseUrl = process.env.REACT_APP_baseUrl;

export const BackToInhouse = ({ onClose }: any) => {
    const [imei, setImei] = useState('');
    const { sendEditRequest } = useEdit()

    const [isLoading, setIsLoading] = useState(false);

    const handleImeiChange = useCallback((newValue) => {
        setImei(newValue);
    }, []);


    const handleBackToInhouse = useCallback(async () => {
        setIsLoading(true)
        await sendEditRequest(
            `${baseUrl}/item/back-to-in-house`,
            {imei},
        )
            .then((response) => {
                toast.success(`Item back to inhouse ${response?.data?.imei}`, { hideProgressBar: true })
                onClose()
                setIsLoading(false)
            })
            .catch((error) => console.error(`Error: ${error}`))
    }, [imei, onClose, sendEditRequest])


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleBackToInhouse();
        }
    };

    return (
        <ModalBox
            heading="Add unit back to inhouse"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Row className='w-full gap-x-5'>
                <Column className=" w-full">
                    <PrimaryCard className="p-4 w-full">
                        <Text weightV="bold" colorV="blue" variant="title">
                            Scan IMEI
                        </Text>
                        <div className="flex mb-16 mt-10 h-20 items-center justify-center">
                            <div className="items-center px-4 h-28 justify-center rounded bg-white relative border-radius-5 w-11/12 mx-auto my-auto">
                                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', left: '0', borderRadius: '5px 0 0 0', borderTop: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', right: '0', borderRadius: '0 5px 0 0', borderTop: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', right: '0', borderRadius: '0 0 5px 0', borderBottom: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                                <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', left: '0', borderRadius: '0 0 0 5px', borderBottom: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                                <Row className=" gap-x-3 mt-10 items-center justify-center">
                                    <TextField
                                        value={imei}
                                        onChange={handleImeiChange}
                                        onKeyPress={handleKeyPress}
                                        placeholder="IMEI"
                                        width="20rem"
                                        autoFocus
                                    />
                                </Row>
                            </div>
                        </div>
                        <RowAlignCenter>
                        <Button
                            onClick={handleBackToInhouse}
                            className='w-56'
                            backgroundColor="lgblue"
                            disabled={isLoading}
                        >
                            <Text colorV="white" variant="title" weightV="normal">
                                { isLoading ? "Adding.." :  "Add unit back to inhouse"}
                            </Text>
                        </Button>
                        </RowAlignCenter>
                        
                    </PrimaryCard>
                </Column>
            </Row>
        </ModalBox>
    );
};
