import { useCallback, useEffect, useState } from 'react'
import { Column } from '../../../../layout/Col';
import { Flex } from '../../../../layout/Flex';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import { Text } from '../../../../base/Text';
import { myStatus, rmaStatus } from '../../../../../constant/Storage';
import { TextBackground } from '../../../../base/TextBackground';
import { Button } from '../../../../base/Button';
import { IssuedCN } from './IssuedCN';
import { toast } from 'react-toastify';
import { useEdit } from '../../../../../hooks/useEdit';
import { Row } from '../../../../layout/Row';
import { ViewIssuedCN } from './ViewIssuedCN';
import { PdfDownload } from '../../CreditNotes/component/PdfDownload';
import { addNotification } from '../../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl

export const RmaSummary = ({ data, refetch }: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const { sendEditRequest } = useEdit()
    console.log({data});
    const openModal = () => {
        setIsModalOpen(true)
    }
    const closeModal = () => {
        setIsModalOpen(false)
        refetch()
    }
    const closeEdit = () => {
        setEdit(false)
        refetch()
    }
    let totalAccepted = 0;
    let totalDeclined = 0;

    if (data) {
        data?.items?.forEach(item => {
            if (item?.acceptance === "rma_accepted") {
                totalAccepted += parseFloat(item?.salePrice) || 0; // ParseFloat to handle potential non-numeric values
            } else if (item?.acceptance === "rma_declined") {
                totalDeclined += parseFloat(item?.salePrice) || 0;
            }
        });
    }
    const handleStatusChange = useCallback(async (e: any) => {
        await sendEditRequest(
            `${baseUrl}/rma/rma-status/${data?._id}`,
            {
                status: e.target.value,
            },
        )
            .then((response) => {
                toast.success(`Status: ${response?.data?.message}`, { hideProgressBar: true })
                addNotification({
                    message: `Status has been updated successfully to ${response?.data?.message}`,
                    type: 'success',
                })
                refetch()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }, [data?._id, refetch, sendEditRequest])
    const handleEditIssueCreditNote = useCallback(() => {
        setEdit(true)
    }, [])
    const item = {
        'Credit Note N':data?.creditNote?.creditNumber,
            'Customer':data?.customer?.companyName,
            'Status':data?.creditNote?.status,
            'Value':data?.creditNote?.creditValue,
            'Issued for RMA':data?.rmaNumber,
            path: data?.creditNote?.filePath,
    }
    return (
        <Row className="w-full">
            <PrimaryCard className="p-5 w-3/4">
                <Column className="gap-y-10 ">
                    <RowAlignCenterSpaced className=" justify-center">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="bold"
                        >
                            Report status
                        </Text>
                        <div style={{ width: '16rem', position: 'relative' }}>
                            <select
                                id="model"
                                defaultValue={data?.status}
                                onChange={handleStatusChange}
                                className="w-full border-none hover:cursor-pointer  rounded-lg  py-2 px-1 bg-white"
                            >
                                <option value={data?.status}>
                                    {data?.status || 'Select'}
                                </option>
                                {rmaStatus
                                    ?.filter((status: any) => status.value !== data?.status) // Exclude data?.status from options
                                    .map((status: any) => (
                                        <option key={status.value} value={status.value}>
                                            {status.label}
                                        </option>
                                    ))}
                            </select>
                        </div>

                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="bold"
                        >
                            Tracking number
                        </Text>
                        <TextBackground className='w-64' backgroundColor='base01'>
                            {data?.trackingNumber}
                        </TextBackground>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="bold"
                        >
                            Summary
                        </Text>
                        <TextBackground className='w-64'backgroundColor='base01'>
                            {totalAccepted}
                        </TextBackground>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="bold"
                        >
                            Credit Notes N
                        </Text>
                        {data?.creditNote ? <TextBackground onClick={handleEditIssueCreditNote} backgroundColor='b9white' className='w-64 hover:cursor-pointer hover:underline'>
                            {data?.creditNote?.creditNumber}
                        </TextBackground> :
                            <Button className='w-64 rounded-lg' backgroundColor="white" onClick={openModal}>
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="normal"
                                    style={{ textDecoration: 'underline' }}
                                >
                                    issued CN
                                </Text>
                            </Button>
                        }

                    </RowAlignCenterSpaced>
                </Column>
            </PrimaryCard>
            {isEdit && <PdfDownload item={item} onClose={closeEdit} />}
            {isModalOpen && <IssuedCN item={data} onClose={closeModal} />}
        </Row>
    )
}
