import React, { CSSProperties } from 'react';

interface CircleProps {
    className?: string;
    inlineStyle?: CSSProperties;
    backgroundColorVariant?: 'blue' | 'red' | 'green'; // Define color variants
}

export const Circle: React.FC<CircleProps> = ({
    className,
    inlineStyle,
    backgroundColorVariant,
}) => {
    const colorVariants: Record<string, string> = {
        blue: 'darkblue', // Updated to dark blue
        red: 'lightcoral',
        green: 'lightgreen',
    };

    const circleStyle: CSSProperties = {
        width: '6px',
        height: '6px',
        borderRadius: '50%', // Makes the element circular
        backgroundColor: backgroundColorVariant
            ? colorVariants[backgroundColorVariant]
            : '#112D56', // Default to dark blue if no variant specified
    };

    return (
        <div
            className={`circle ${className} lg:w-20 lg:h-20`}
            style={{ ...circleStyle, ...inlineStyle }}
        ></div>
    );
};
