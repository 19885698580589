/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import React, { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import TopHeader from '../components/TopHeader'
import { toast } from 'react-toastify'
import { useAuth } from '../utils/auth'
import loading from '../assets/loading.gif'
import { addNotification } from '../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md appearance-none  block  px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

const baseStyle = {
    display: 'flex',
    height: 200,
    width: 500,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    cursor: 'pointer',
    transition: 'border .24s ease-in-out',
}

const focusedStyle = {
    borderColor: '#A855F7',
}

const acceptStyle = {
    borderColor: '#00e676',
}

const rejectStyle = {
    borderColor: '#ff1744',
}

export default function UploadFile() {
    const [file, setFile] = useState(null)
    const [trackingNumber, setTrackingNumber] = useState<any>(null)
    const [isLoading, setisLoading] = useState<boolean>(false)
    const auth = useAuth()
    const token = auth?.user?.token?.token

    const onDrop: any = useCallback((acceptedFiles: any) => {
        const [acceptedFile] = acceptedFiles
        if (acceptedFile) {
            setFile(acceptedFile)
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        fileRejections,
    } = useDropzone({
        accept: {
            'text/xlsx': ['.xlsx'],
        },
        onDrop,
    })

    const style: any = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : { borderColor: '#A855F7' }),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    )
    const acceptedFileItems = acceptedFiles.map((file: any) => (
        // @ts-ignore
        <li key={file.path}>
            {file?.path} - {file.size} bytes
        </li>
    ))
    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
            // @ts-ignore
            <li key={file.path}>
                {/* @ts-ignore */}
                {file?.path} - {file.size} bytes
                <ul>
                    {errors.map((e) => (
                        <li key={e.code}>{e.message}</li>
                    ))}
                </ul>
            </li>
        )
    })
    const handleTrackingNumber = (e: any) => {
        setTrackingNumber(e.target.value)
    }
    const uploadFile = useCallback(
        async (e: any) => {
            if (file && trackingNumber) {
                setisLoading(true)
                const formData = new FormData()
                formData.append('file', file)
                formData.append('track', trackingNumber)
                const url = `${baseUrl}/file/upload`
                const options = {
                    url: url,
                    method: 'POST',
                    headers: {
                        'content-type': 'multipart/form-data',
                        Authorization: token as string,
                    },
                    data: formData,
                }
                try {
                    await axios(options)
                    setFile(null)
                    setisLoading(false)
                    setTrackingNumber('')
                    addNotification({
                        message: 'File uploaded successfully',
                        type: 'success',
                    })
                    toast.success('File uploaded successfully.', {
                        hideProgressBar: true,
                    })
                } catch (error: any) {
                    setisLoading(false)
                    toast.error(error?.response?.data?.message, {
                        hideProgressBar: true,
                    })
                }
            } else {
                toast.error('Please Add Tracking Number', {
                    hideProgressBar: true,
                })
            }
        },
        [file, token, trackingNumber]
    )
    return (
        <>
            <TopHeader />
            {isLoading ? (
                <div className="flex justify-center items-center h-screen flex-col">
                    <h2 className="mb-3">
                        Please, wait while file is uploading...
                    </h2>
                    <img src={loading} />
                </div>
            ) : (
                <div className="flex justify-center items-center h-screen flex-col">
                    <div className=" flex flex-col justify-start">
                        <h1 className="m-3 text-black font-bold text-l">
                            {' '}
                            Add Tracking Number
                        </h1>
                        <input
                            type="text"
                            name=""
                            id="trackingNumber"
                            onChange={(e) => {
                                handleTrackingNumber(e)
                            }}
                            value={trackingNumber}
                            className={fixedInputClass}
                            placeholder="Tracking Number"
                        />
                    </div>
                    <h1
                        className="m-5 text-black font-bold text-xl"
                        style={{ width: 500 }}
                    >
                        Upload Excel File
                    </h1>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Drag drop some file here, or click to select file</p>
                    </div>
                    {file && (
                        <div>
                            <div className="mb-4" style={{ width: 500 }}>
                                <h4 className="mt-3">Selected file</h4>
                                <ul className="text-purple-400">
                                    {acceptedFileItems}
                                </ul>
                            </div>
                            <div>
                                <button
                                    onClick={uploadFile}
                                    className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-700 focus:outline-none"
                                >
                                    Upload File
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
