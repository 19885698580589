import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import TopHeader from '../components/TopHeader'
import { useFetch } from '../hooks/useFetch'
import { useAuth } from '../utils/auth'
import { addNotification } from '../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md mt-3 appearance-none relative block w-80 px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

export const InsertStock = () => {
    const [sku, setSku] = useState('')
    const [name, setName] = useState('')
    const [models, setModels] = useState<any>([])
    const [modelVal, setModelVal] = useState<any[]>([])
    const [pieces, setPieces] = useState()
    const [price, setPrice] = useState()

    const navigate = useNavigate()
    const auth = useAuth()
    const token = auth?.user?.token?.token

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/getModels`
    )

    useEffect(() => {
        getModels()
    }, [getModels])

    useEffect(() => {
        const localModel = []
        modelsData?.forEach((element) => {
            localModel.push({ label: element?.modelName, value: element?._id })
        })
        setModels(localModel)
    }, [modelsData])

    const handleModels = useCallback((e: any) => {
        const vals = Array.isArray(e) ? e.map((model) => model?.value) : []
        setModelVal(vals)
    }, [])

    const handleInputChange = (e: any) => {
        e.preventDefault()
        const { id, value } = e.target
        if (id === 'sku') {
            e.preventDefault()
            setSku(value)
        }
        if (id === 'name') {
            e.preventDefault()
            setName(value)
        }
        if (id === 'pieces') {
            e.preventDefault()
            setPieces(value)
        }
        if (id === 'price') {
            e.preventDefault()
            setPrice(value)
        }
    }

    const handleAddSubmit = (e: any) => {
        e.preventDefault()
        insertStock()
    }

    const insertStock = () => {
        if (!sku) {
            alert('Please, add SKU')
            return
        }
        if (!name) {
            alert('Please, add name')
            return
        }
        if (pieces === undefined || pieces == 0) {
            alert('Please, enter pieces more then 0')
            return
        }

        if (modelVal.length === 0) {
            alert('Please select models')
            return
        }
        if (price === undefined || price == 0) {
            alert('Please add price more then 0')
            return
        }
        axios
            .post(
                `${baseUrl}/stock/insert`,
                {
                    sku,
                    name,
                    modelVal,
                    pieces,
                    price,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                toast.success('Data Inserted', { hideProgressBar: true })
                addNotification({
                    message: 'Data Inserted',
                    type: 'success',
                })
            })
    }

    const goBack = () => {
        navigate(-1)
    }

    return (
        <>
            <TopHeader />
            <div className="flex items-center m-3 flex-col ">
                <div className="overflow-x-auto">
                    <h1 className="font-bold text-xl text-l mt-6 ml-4">
                        Add Spare Part
                    </h1>

                    <form className="my-8 mx-4 space-y-6">
                        <div className="form-body">
                            <div className="my-3">
                                <label className="">SKU</label>
                                <input
                                    className={fixedInputClass}
                                    type="text"
                                    value={sku}
                                    onChange={(e) => handleInputChange(e)}
                                    id="sku"
                                    placeholder={'Enter SKU'}
                                />
                            </div>
                            <div className="name">
                                <label className="">Name</label>
                                <input
                                    type="text"
                                    name=""
                                    id="name"
                                    value={name}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={'Enter Name'}
                                />
                            </div>
                            <div className="pieces my-3">
                                <label className="">Pieces</label>
                                <input
                                    type="text"
                                    name=""
                                    id="pieces"
                                    value={pieces}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={'Enter pieces'}
                                />
                            </div>
                            <div className="price my-3">
                                <label className="">Price</label>
                                <input
                                    type="text"
                                    name=""
                                    id="price"
                                    value={price}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={'Enter Price'}
                                />
                            </div>
                            <div className="model">
                                <label className="">Model</label>
                                <div className="my-3">
                                    <Select
                                        id="model"
                                        options={models}
                                        onChange={handleModels}
                                        isMulti
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-start mt-5">
                            <button
                                type="button"
                                onClick={goBack}
                                className="relative mr-10 justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                                Back
                            </button>
                            <button
                                className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                onClick={handleAddSubmit}
                                type="submit"
                            >
                                Add Spare Part
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
