import React, { useCallback, useEffect, useState } from 'react'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Text } from '../../../../base/Text'
import { Button } from '../../../../base/Button'
import { Column } from '../../../../layout/Col'
import { ModalBox } from '../../../../base/ModelBox' // Updated import
import { AddGrade } from '../DialougeBoxes/Add/AddGrade' // Replace with your actual AddGrade component import
import { useFetch } from '../../../../../hooks/useFetch'
import { EditGrade } from '../DialougeBoxes/Edit/EditGrade'
import { DeleteGrade } from '../DialougeBoxes/Delete/DeleteGrade'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
const baseUrl = process.env.REACT_APP_baseUrl

export const Grades = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [selectedModel, setSelectedModel] = useState<any>()

    const { getRequest: getGrades, data: gradesData } = useFetch(
        `${baseUrl}/grade/get-grades`
    )

    const openModal = (option) => {
        setSelectedOption(option)
        if (option === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (option === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const closeModal = useCallback(() => {
        setIsModalOpen(false)
        setSelectedOption(null)
        setIsEdit(false)
        setIsDelete(false)
        getGrades()
    }, [getGrades])

    useEffect(() => {
        getGrades()
    }, [getGrades])

    let extractedData = []
    if (gradesData) {
        extractedData = gradesData?.map((item) => {
            return {
                id: item?._id,
                Grade: item?.grade,
                Description: item?.description,
            }
        })
    }

    const columns = ['Grade', 'Description']

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item)
        console.log(`Selected Option: ${optionValue}`)
        setSelectedOption(optionValue)
        if (optionValue === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (optionValue === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditGrade item={selectedModel} onClose={closeModal} />
        } else if (isDelete) {
            return <DeleteGrade item={selectedModel} onClose={closeModal} />
        } else {
            return <AddGrade onClose={closeModal} />
        }
    }, [isEdit, isDelete, selectedModel, closeModal])

    return (
        <>
            <Column className='w-full'>
                <RowAlignCenterSpaced className="w-full mb-5">
                    <Text
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        Manage Grades
                    </Text>
                    <Button
                        backgroundColor="lgblue"
                        onClick={() => openModal('Add New Model')}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Add new grade
                        </Text>
                    </Button>
                </RowAlignCenterSpaced>
                <PrimaryCard className='w-full'>
                    <Table
                        options={['Edit', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
                {isModalOpen && (
                        renderModalComponent()
                )}
            </Column>
        </>
    )
}
