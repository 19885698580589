import React, { useCallback, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { toast } from 'react-toastify'
import { usePost } from '../../../../../hooks/usePost'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

// ExportContent Component
export const ArchiveCreditNote: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item
}) => {
    const { sendRequest } = usePost()

    const handleArchive = useCallback(async (e: any) => {
        e.preventDefault()
        const urlItem = `${baseUrl}/creditnote/archive-credit-note/${item.id}`
        await sendRequest(
            urlItem,
            {}
        )
            .then(() => {
                toast.success('Archived', { hideProgressBar: true })
                addNotification({
                    message: 'Archived',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data}`, {
                    hideProgressBar: true,
                })
            })
    }, [item.id, onClose, sendRequest])
    return (
        <ModalBox
            heading="Archive"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className=" gap-y-4 items-center text-center">
                <Text colorV="blue" variant="head" weightV="normal">
                    Are you sure you want to archive <br />
                    {item?.['Credit Note N']}
                </Text>
                <Flex className="gap-x-3">
                    <Button backgroundColor="lgblue" onClick={handleArchive}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Yes
                        </Text>
                    </Button>
                    <Button backgroundColor="lgblue" onClick={onClose}>
                        <Text colorV="white" variant="title" weightV="normal">
                            No
                        </Text>
                    </Button>
                </Flex>
            </Column>

        </ModalBox>
    )
}
