import React, { useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { Text } from '../../../../base/Text'
import { Button } from '../../../../base/Button'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { AddInventoryContent } from '../../../Inventory/Inhouse/components/AddInventoryModel'
import { BulkInventoryContent } from '../../../Inventory/Inhouse/components/BulkInventoryModel'
import { ExportModel } from '../../../Inventory/Inhouse/components/ExportModel'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'

export default function Head({activeData}:any) {
    const [modalContent, setModalContent] = useState(null)

    const handleExport = () => {
        setModalContent(<ExportModel data={activeData} onClose={() => setModalContent(null)} />)
    }

    return (
        <Flex className="py-5  w-full">
            <RowAlignCenterSpaced className="gap-x-5  w-full">
                <Text colorV="blue" variant="head" weightV="normal">
                    Repair workflow
                </Text>
                <RowAlignCenter className="gap-x-3">
                    <Button onClick={handleExport} backgroundColor="green">
                        <Text colorV="white" variant="title" weightV="normal">
                            Export
                        </Text>
                    </Button>
                </RowAlignCenter>
            </RowAlignCenterSpaced>
            {modalContent && modalContent}
        </Flex>
    )
}
