import React, { useCallback, useEffect, useState } from 'react'
import { ModalBox } from '../../../../../base/ModelBox'
import { Flex } from '../../../../../layout/Flex'
import { Table } from '../../../../../shared/Table'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { ExportModel } from './ExportModel'
import { EditSaleSKU } from './EditSaleInventory'
import { DeleteSaleSKU } from './DeleteSaleInventory'
import { QrCodeViwer } from '../../../../../shared/QrCode/QrViwer'

const baseUrl = process.env.REACT_APP_baseUrl

export const SalesSKUTable : React.FC<{ items: any[]; handleSaleInventorySku: () => void }> = ({ items, handleSaleInventorySku }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState<any>();
console.log(items)
    let extractedData = []
    if (items) {
        extractedData = items?.map((item) => {
            return {
                Id:item?._id,
                SKU: item?.sku,
                Model: item?.modelName?.modelName,
                ModelId:item?.modelName?._id,
                Storage: item?.storage,
                VAT: item?.vat,
                Color: item?.color,
                Grade: item?.grade?.grade,
                GradeId: item?.grade?._id
            }
        })
    }

    const columns = ['SKU', 'Model', 'Storage', 'VAT', 'Grade', 'Color']
 
    const closeModal = useCallback(() => {
        setIsModalOpen(false)
        setIsEdit(false)
        setIsDelete(false)
        handleSaleInventorySku()
    }, [handleSaleInventorySku])

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item);
        if (optionValue === 'Edit') {
            setIsEdit(true);
            setIsDelete(false);
            setIsPrint(false);
        } else if (optionValue === 'Delete') {
            setIsDelete(true);
            setIsEdit(false);
            setIsPrint(false);
        } else if (optionValue === 'Print') {
            setIsEdit(false);
            setIsDelete(false);
            setIsPrint(true);
            
        } else {
            setIsEdit(false);
            setIsDelete(false);
        }
        setIsModalOpen(true);
    };


    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditSaleSKU item={selectedModel} onClose={closeModal} />;
        } else if (isDelete) {
            return <DeleteSaleSKU item={selectedModel} onClose={closeModal} />;
        }else if (isPrint) {
            return <QrCodeViwer item={selectedModel} onClose={closeModal} type="Spare part "/>
        } 
    }, [isEdit, isDelete, selectedModel, closeModal, isPrint]);

    return (
        <>
            <Flex className="mt-5 ">
                <PrimaryCard className='w-full'>
                    <Table
                        options={['Print','Edit', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
            {isModalOpen && renderModalComponent()}
        </>
    )
}
