import React, { useState } from 'react';
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced';
import { Text } from '../../base/Text';
import { Dropdown } from '../../base/Dropdown';
import { Column } from '../../layout/Col';
import { AtlasChart } from '../../shared/Chart';
import { PrimaryCard } from '../Page/PrimaryCard';
import { Row } from '../../layout/Row';
import { RowAlignCenter } from '../../layout/RowAlignCenter';
import { Flex } from '../../layout/Flex';

export const CardGraph = ({ heading, chartData, chartLabels, chartType, weeklyCount, color, gColor }: any) => {
  // Initialize state variable for selected option
  const [selectedOption, setSelectedOption] = useState('Week');

  return (
    <>
      <PrimaryCard className="p-5 w-full">
        <RowAlignCenterSpaced className='gap-x-8'>
          <Text
            style={{
              fontFamily: 'Archivo Black, sans-serif'
          }}
          colorV="blue" variant="title" weightV="normal">
            {heading}
          </Text>
          <Dropdown
            width="80px"
            label=""
            options={['Week', 'Day', 'Month']}
            defaultValue={selectedOption} // Set the default selected value
            onChange={(selectedOption) => {
              // Handle the selected option here
              console.log(`Selected option: ${selectedOption}`);
              setSelectedOption(selectedOption); // Update selected option in state
            }}
          />
        </RowAlignCenterSpaced>
        <Row className="mt-5 gap-x-3 w-full">
          <Column className="gap-y-5 justify-between">
            <Text colorV="blue" variant="title" weightV="normal">
              {chartData.reduce((sum, value) => sum + value, 0)}
            </Text>
            <Text colorV="green" variant="body" weightV="normal">
              {chartData.reduce((sum, value) => sum + value, 0)}
            </Text>
          </Column>
          <Flex className='w-full'>
            <AtlasChart labels={chartLabels} data={chartData} chartType={chartType} color={color} gColor={gColor} />
          </Flex>
        </Row>
      </PrimaryCard>
    </>
  );
};
