import React, { ReactNode } from 'react'
import { Navbar } from '../Navbars/Navbar'
import { Pagebg } from '../../components/compound/Page/Pagebg'
import { Column } from '../../components/layout/Col'
import { Flex } from '../../components/layout/Flex'
import { Row } from '../../components/layout/Row'
import { SubNavbar } from '../Navbars/SubNavbar'
import { BelowNavbar } from '../Navbars/BelowNavbar'
import Logo2 from '../../assets/logo2.png'
import { useAuth } from '../../utils/auth'
import { Permissions } from '../../constant/Storage'
import { AccessEnums, AccessPermissions } from '../../constant/UserAccess'
import { useNavigation } from './BaseLayout'

interface ProductionLayoutProps {
    children: ReactNode
}

export const ProductionLayout: React.FC<ProductionLayoutProps> = ({
    children,
}) => {
    const auth = useAuth()
    const { initialNavItems, belowNavItems } = useNavigation();
    const isTransit = auth?.selectedCompany?.permissions?.includes(Permissions.transit)
    const isPolishing = auth?.selectedCompany?.permissions?.includes(Permissions.polishing)
    const isRepair = auth?.selectedCompany?.permissions?.includes(Permissions.repair)
    const isGrading = auth?.selectedCompany?.permissions?.includes(Permissions.grading)
    const isManualSorting = auth?.selectedCompany?.permissions?.includes(Permissions.manualSorting)
    const isDiagnostics = auth?.selectedCompany?.permissions?.includes(Permissions.diagnostics)
    const repairCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Repair && acc.permission.includes(AccessPermissions.Admin))
    const gradingCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Grading && acc.permission.includes(AccessPermissions.Admin))
    const diagnosticsCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Diagnostics && acc.permission.includes(AccessPermissions.Admin))
    const manualSortingCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManualSorting && acc.permission.includes(AccessPermissions.Admin))
    const polishingCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Polishing && acc.permission.includes(AccessPermissions.Admin))
    const transitCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Transit && acc.permission.includes(AccessPermissions.Admin))
    console.log({transitCheck});
    console.log({isTransit});

    const subNavItems = [
        isRepair && repairCheck && { text: 'Repair', to: '/production/repair' },
        isRepair && repairCheck && { text: 'Warranty', to: '/production/warranty' },
        isGrading && gradingCheck && {
            text: 'Grading',
            to: '/production/grading',
        },
        isDiagnostics && diagnosticsCheck && {
            text: 'Diagnostic',
            to: '/production/diagnostics',
        },
        isManualSorting && manualSortingCheck && {
            text: 'Manual sorting',
            to: '/production/manual-sorting',
        },
        isPolishing && polishingCheck && {
            text: 'Polishing',
            to: '/production/polishing',
        },
        isTransit && transitCheck && {
            text: 'Transit',
            to: '/production/transit',
        },
    ].filter(Boolean);


    return (
        <div className="bg-base01 flex flex-row">
                <Column className="gap-y-1 p-5">
                    <div className="logo">
                        <img alt="" className="h-14 w-28" src={Logo2} />
                    </div>
                    <Row className="gap-x-2">
                        <Navbar initialNavItems={initialNavItems} />
                        <SubNavbar
                            heading="Production Environment"
                            initialNavItems={subNavItems}
                        />
                    </Row>
                    <BelowNavbar
                        className="mt-3"
                        initialNavItems={belowNavItems}
                    />
                </Column>
            <div className='flex-1 p-5'>
                {children}
            </div>
        </div>
    )
}
