import React, { useState } from 'react';
import { MyIcon } from '../MyIcon';


type ButtonProps = {
  backgroundColor?: string;
  borderRadius?: string;
  className?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  iconName?: string;
  size?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton: React.FC<ButtonProps> = ({
  backgroundColor = '#FFFFFF',
  borderRadius = 'rounded-md',
  className,
  onClick,
  iconName = 'add', // Default icon name is 'add'
  size = 20,
  ...rest
}) => {
  const borderRadiusOptions = {
    'rounded-md': { borderRadius: '5px' },
    'rounded-lg': { borderRadius: '6px' },
    'rounded-full': { borderRadius: '8px' },
  };

  const buttonStyles = {
    backgroundColor,
    ...(borderRadiusOptions[borderRadius] || borderRadiusOptions['rounded-md']),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s, opacity 0.3s',
  };

  const buttonHoverStyles = {
    ...buttonStyles,
    opacity: 0.8,
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      style={isHovered ? buttonHoverStyles : buttonStyles}
      className={className}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <MyIcon name={iconName} size={size} />
    </button>
  );
};
