import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../../utils/auth'
import { Button } from '../../../../../../base/Button'
import { ModalBox } from '../../../../../../base/ModelBox'
import { Column } from '../../../../../../layout/Col'
import { Flex } from '../../../../../../layout/Flex'
import { Text } from '../../../../../../base/Text'
import { addNotification } from '../../../../../../shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

export const DeleteCodeEvent: React.FC<{ onClose: () => void; item: any; selectedCode }> = ({
    onClose,
    item,
    selectedCode
}) => {
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany

console.log({item});
    console.log(selectedCode)

    const handleDelete = () => {
        axios
            .delete(`${baseUrl}/sorting-logic/delete-manual-sorting-event/${item?._id}`, {
                headers: {
                    Authorization: token as string,
                    Company: company._id
                },
                data: {
                    selectedCodeId: selectedCode.Id,
                },
            })
            .then((response) => {
                toast.success(response.data)
                addNotification({
                    message: 'Code has been deleted successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            )
    }

    return (
        <ModalBox
            heading="Delete code"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className=" gap-y-4 items-center text-center">
                <Text colorV="blue" variant="head" weightV="normal">
                    Are you sure you want to delete <br />
                    {item?.Code}
                </Text>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' backgroundColor="lgblue" onClick={handleDelete}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Yes
                        </Text>
                    </Button>
                    <Button className='w-32' backgroundColor="lgblue" onClick={onClose}>
                        <Text colorV="white" variant="title" weightV="normal">
                            No
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
