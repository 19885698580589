import React from 'react'
import { CompletePolishing } from './components/CompletePolishing'
import { Column } from '../../../../layout/Col'

export const Complete = () => {
    return (
        <>
            <CompletePolishing />
        </>
    )
}
