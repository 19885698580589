import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Text } from '../../../../../base/Text'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { TextArea } from '../../../../../base/Textarea'
import { useEdit } from '../../../../../../hooks/useEdit'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditGrade: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [grade, setGrade] = useState<string>('')
    const [gradeDescription, setGradeDescription] = useState<string>('')
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendEditRequest } = useEdit()
    useEffect(() => {
        if (item) {
            setGrade(item?.Grade)
            setGradeDescription(item?.Description)
        }
    }, [item])

    const handleGradeChange = (newValue: any) => {
        setGrade(newValue)
    }
    const handleGradeDescriptionChange = (newValue: any) => {
        setGradeDescription(newValue)
    }
    const handleSaveChange = useCallback(async (e: any) => {
        e.preventDefault()
        if (!grade) {
            alert('Please, add grade')
            return
        } else if (!gradeDescription) {
            alert('Please, add description')
            return
        }
        await sendEditRequest(
            `${baseUrl}/grade/grade-edit/${item?.id}`,
            {
                grade,
                gradeDescription,
            },
        )
            .then((response) => {
                toast.success(response.data, { hideProgressBar: true })
                addNotification({
                    message: 'Grade has been updated successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            )
    }, [grade, gradeDescription, item?.id, onClose, sendEditRequest])

    return (
        <ModalBox
            heading="Edit grade"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Grade
                    </Text>
                    <TextField
                        value={grade}
                        onChange={handleGradeChange}
                        placeholder="Enter code"
                        
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Grade Description
                    </Text>
                    <TextArea
                        value={gradeDescription}
                        onChange={handleGradeDescriptionChange}
                        placeholder="Enter description"
                        
                        className='w-64'
                    />
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button backgroundColor="lgblue" onClick={handleSaveChange}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Update
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
