import React from 'react'
// import { TotalInsights } from './components/TotalInsights'
import { Column } from '../../../layout/Col'
import { GraphInsights } from './components/GraphInsights'

export const Insights = () => {
    return (
        <>
            <Column>
                {/* <TotalInsights /> */}
                <GraphInsights />
            </Column>
        </>
    )
}
