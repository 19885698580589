import React, { useCallback } from 'react';
import { ModalBox } from '../../../../base/ModelBox';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { Text } from '../../../../base/Text';
import { Button } from '../../../../base/Button';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as XLSX from 'xlsx';

const baseUrl = process.env.REACT_APP_baseUrl;

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};

export const ExportModel: React.FC<{ onClose: () => void, sparepartData: any }> = ({ onClose, sparepartData }: any) => {
    const currentDate = moment().format('DDMMYYYY');

    const handleSparepartPDF = useCallback(async () => {
        if (sparepartData?.length > 0) {
            const body = sparepartData?.map((itm: any) => {
                return [
                    itm?.sku,
                    itm?.name,
                    itm?.models?.map((mdl) => mdl?.modelName).join(', '),
                    itm?.quantity,
                    itm?.price,
                ];
            });
            body.unshift(['SKU', 'Name', 'Model', 'Quantity', 'Price']);
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            };
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download(`${currentDate}-spare-parts-report`);
            onClose();
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [currentDate, onClose, sparepartData]);

    const handleSparepartXLSX = useCallback(async () => {
        if (sparepartData?.length > 0) {
            const wsData = sparepartData?.map((element: any) => ({
                SKU: element?.sku,
                Name: element?.name,
                Model: element?.models?.map((mdl) => mdl?.modelName).join(', '),
                Quantity: element?.quantity,
                Price: element?.price,
            }));

            const ws = XLSX.utils.json_to_sheet(wsData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Spare Parts Report");

            XLSX.writeFile(wb, `${currentDate}-spare-parts-report.xlsx`);
            onClose();
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [currentDate, sparepartData, onClose]);

    return (
        <ModalBox height="auto" heading="Export" onClose={onClose}>
            <RowAlignCenter>
                <Text colorV="blue" weightV="bold" variant="head">
                    Attention Required!
                </Text>
            </RowAlignCenter>
            <RowAlignCenter className="mt-3 text-center gap-x-4">
                <Button onClick={handleSparepartPDF} backgroundColor="purple">
                    <Text colorV="white" variant="title" weightV="normal">
                        Export PDF
                    </Text>
                </Button>
                <Button onClick={handleSparepartXLSX} backgroundColor="blue">
                    <Text colorV="white" variant="title" weightV="normal">
                        Export XLSX
                    </Text>
                </Button>
            </RowAlignCenter>
        </ModalBox>
    );
};
