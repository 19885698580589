import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useFetch } from '../../../../../hooks/useFetch'
import { Flex } from '../../../../layout/Flex'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { DropdownButtons } from '../../../../base/DropdownButton'
import { Text } from '../../../../base/Text'
import { DeleteRmaModel } from './DeleteRmaModel'
import { ArchiveRmaModel } from './ArchiveRmaModel'
import { useNavigate } from 'react-router-dom'


export default function RmaTable({ data, refetch }: any) {
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedItemData, setSelectedItemData] = useState(null)
    const navigate = useNavigate()
    console.log({ data });
    const openRmaDetails = (item) => {
        navigate(`/rma/manage-customer-rma/detail/${item?._id}`)
    }
    const openModal = (item, selectedItem) => {
        setSelectedItem(item)
        setSelectedItemData(selectedItem)
    }

    const onClose = useCallback(() => {
        setSelectedItem(null)
        setSelectedItemData(null)
        refetch()
    }, [refetch])
    return (
        <>
            <Flex className="w-full">
                <PrimaryCard className="w-full">
                    <div className="overflow-x-auto w-full">
                        <table className="min-w-full w-full table-auto text-b9blue bg-transparent">
                            <thead style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                // Corrected to camelCase and added fallback fonts
                            }}>
                                <tr >
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter Tight, sans-serif'
                                        }} className="px-4 py-2">RMA</th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter Tight, sans-serif'
                                        }} className="px-4 py-2">Customer</th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter Tight, sans-serif'
                                        }} className="px-4 py-2">
                                        Submission Date
                                    </th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter Tight, sans-serif'
                                        }} className="px-4 py-2">Report Status</th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter Tight, sans-serif'
                                        }} className="px-4 py-2">Credit Note</th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter Tight, sans-serif'
                                        }} className="px-4 py-2">
                                        Account Manager
                                    </th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter Tight, sans-serif'
                                        }} className="px-4 py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data
                                    ?.filter(
                                        (item) => item?.archiveStatus === false
                                    )
                                    .map((item, rowIndex) => (
                                        <tr
                                            className="cursor-pointer"
                                            key={rowIndex}
                                            onClick={() => openRmaDetails(item)}
                                        >
                                            <td className="px-4 py-2">
                                                <Text
                                                    variant='title'
                                                    colorV="blue" // Set the text color to blue
                                                    weightV="normal"
                                                >
                                                    {item?.rmaNumber}
                                                </Text>
                                            </td>
                                            <td className="px-4 py-2">
                                                <Text
                                                    variant='title'
                                                    colorV="blue" // Set the text color to blue
                                                    weightV="normal"
                                                >
                                                    {item?.customer?.companyName}
                                                </Text>
                                            </td>
                                            <td className="px-4 py-2">
                                                <Text
                                                    variant='title'
                                                    colorV="blue" // Set the text color to blue
                                                    weightV="normal"
                                                >
                                                    {moment(item?.createdAt).format(
                                                        'DD/MM/YYYY'
                                                    )}
                                                </Text>
                                            </td>
                                            <td className="px-4 py-2">
                                                {item?.status === 'credited' ? (
                                                    <div className="bg-b9midblue text-center px-2 py-1 rounded">
                                                        <Text colorV="white" variant='title'
                                                            weightV="normal">
                                                            Credited
                                                        </Text>
                                                    </div>
                                                ) : item?.status ===
                                                    'declined' ? (
                                                    <div className="bg-b9blue text-center px-2 py-1 rounded">
                                                        <Text colorV="white" variant='title'
                                                            weightV="normal">
                                                            Declined
                                                        </Text>
                                                    </div>
                                                ) : item?.status ===
                                                    'in_review' ? (
                                                    <div className="bg-b9green text-center px-2 py-1 rounded">
                                                        <Text colorV="white" variant='title'
                                                            weightV="normal">
                                                            In-review
                                                        </Text>
                                                    </div>
                                                ) : (
                                                    <div className="bg-b9yellow text-center px-2 py-1 rounded">
                                                        <Text colorV="white" variant='title'
                                                            weightV="normal">
                                                            Pending RMA units
                                                        </Text>
                                                    </div>
                                                )}
                                            </td>
                                            <td className="px-4 py-2">
                                                <Text
                                                    variant='title'
                                                    colorV="blue" // Set the text color to blue
                                                    weightV="normal"
                                                >
                                                    {item?.creditNumber}
                                                </Text>
                                            </td>
                                            <td className="px-4 py-2">
                                                <Text
                                                    variant='title'
                                                    colorV="blue" // Set the text color to blue
                                                    weightV="normal"
                                                >

                                                    {item?.customer?.accountManager?.firstName} {item?.customer?.accountManager?.lastName}

                                                </Text>
                                            </td>
                                            <td
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                className="px-4 py-2 relative"
                                            >
                                                <DropdownButtons
                                                    buttonLabel="View"
                                                    items={[
                                                        'Delete',
                                                        'Archive',
                                                    ]}
                                                    onItemClick={(
                                                        selectedItem
                                                    ) => {
                                                        openModal(
                                                            selectedItem,
                                                            data[rowIndex]
                                                        )
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </PrimaryCard>
                {selectedItem === 'Delete' && (
                    <DeleteRmaModel
                        item={selectedItemData}
                        onClose={onClose}
                    />
                )}
                {selectedItem === 'Archive' && (
                    <ArchiveRmaModel
                        item={selectedItemData}
                        onClose={onClose}
                    />
                )}
            </Flex>
        </>
    )
}
