import React, { useCallback, useEffect, useRef } from 'react'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { toast } from 'react-toastify'
import JsBarcode from 'jsbarcode'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Column } from '../../../../../layout/Col'
import { RowAlignCenter } from '../../../../../layout/RowAlignCenter'
import { Text } from '../../../../../base/Text'

const baseUrl = process.env.REACT_APP_baseUrl

;(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}

export const ExportModel: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    console.log({item})
    const barCode = useRef<HTMLDivElement>(null)
    const barCodeSvg = barCode.current?.innerHTML
    useEffect(() => {
        JsBarcode('#barcode', `${item?.ID}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 1.5,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        })
    }, [barCode, item?.ID])

    const handlePrintlabelPDF = useCallback(async () => {
        if (item) {
            const body = [
                ['SKU', 'Name', 'Model'],
                [
                    item?.SKU || '',
                    item?.Name || '',
                    item?.Model || '',
                ],
            ]
            const barcodeSvg = `<svg id="barcode">${barCode.current?.innerHTML}</svg>`
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                    {
                        svg: barcodeSvg,
                        height: 25,
                        width: 270,
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            }

            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('sparePartPdf')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [item])
    return (
        <ModalBox
            height="auto"
            width="auto"
            heading="Print label"
            onClose={onClose}
        >
            <RowAlignCenter>
                <Text colorV="blue" weightV="bold" variant="head">
                    Attention Required!
                </Text>
            </RowAlignCenter>
            <Column className="mt-5 text-center gap-y-4">
                <div ref={barCode} className="py-2 w-full px-2">
                    <svg id="barcode"></svg>
                </div>
                <Button className='w-56' onClick={handlePrintlabelPDF} backgroundColor="purple">
                    <Text colorV="white" variant="title" weightV="normal">
                        Download Label
                    </Text>
                </Button>
            </Column>
        </ModalBox>
    )
}
