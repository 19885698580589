// ImageUpload.tsx
import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

interface ImageUploadProps {
  onUpload: (file: File) => void;
  width?: string;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ onUpload, width = '100%' }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const uploadedFile = acceptedFiles[0];
        const imageUrl = URL.createObjectURL(uploadedFile);
        setUploadedImage(imageUrl);
        onUpload(uploadedFile);
      }
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image': ['.jpg', '.png', '.jpeg'], },
    onDrop,
  });

  const dropzoneStyle: React.CSSProperties = useMemo(
    () => ({
      border: '2px dashed #cccccc',
      borderRadius: '4px',
      padding: '20px',
      textAlign: 'center',
      width: width,
      margin: '0 auto',
    }),
    [width]
  );

  return (
    <div>
      <div {...getRootProps({ style: dropzoneStyle } as DropzoneRootProps)}>
        <input {...getInputProps() as DropzoneInputProps} />
        {uploadedImage ? (
          <img src={uploadedImage} alt="Uploaded" style={{ width: '100%', height: '100%', borderRadius: '4px' }} />
        ) : (
          <p>Upload an image (JPG, PNG)</p>
        )}
      </div>
    </div>
  );
};
