// RowAlignCenterSpaced.tsx
import React, { ReactNode, CSSProperties } from 'react'

interface RowAlignCenterSpacedProps {
    children: ReactNode
    className?: string
    inlineStyle?: CSSProperties
}

export const RowAlignCenterSpaced: React.FC<RowAlignCenterSpacedProps> = ({
    children,
    className,
    inlineStyle,
}) => {
    const centerSpacedStyle: CSSProperties = {
        justifyContent: 'space-between', // Space between child elements
        alignItems: 'center', // Center vertically
        display: 'flex',
        width: '100%', // Occupy the full width
    }

    return (
        <div
            className={`flex flex-row ${className}`}
            style={{ ...centerSpacedStyle, ...inlineStyle }}
        >
            {children}
        </div>
    )
}
