import React, { useCallback, useEffect, useState } from 'react'
import { Flex } from '../../../layout/Flex'
import { Text } from '../../../base/Text'
import { Button } from '../../../base/Button'
import { Column } from '../../../layout/Col'
import { Row } from '../../../layout/Row'
import { Device } from './Device/Device'
import { Spareparts } from './Spareparts/Spareparts'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { ScriptCalendar } from './ScriptCalendar'
import { useEdit } from '../../../../hooks/useEdit'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useAuth } from '../../../../utils/auth'
import { useFetch } from '../../../../hooks/useFetch'
import { addNotification } from '../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export default function Head() {
    const [activeTab, setActiveTab] = useState('Device')
    const [isModal, setIsModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany
    const { getRequest: getStocktakingItems, data: itemsData } = useFetch(
        `${baseUrl}/stocktaking-imei/get-imeis`
    )

    useEffect(() => {
        getStocktakingItems()
    }, [getStocktakingItems])
    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }
    const handleRunScript = () => {
        setIsModal(true)
    }
    const onClose = () => {
        setIsModal(false)
    }
    const handleRemoveDevices = async () => {
        setIsLoading(true)
        await axios
            .delete(`${baseUrl}/stocktaking-imei/delete-stocktaking-imeis`, {
                headers: {
                    Authorization: token as string,
                    Company: company._id,
                },
            })
            .then((response) => {
                setIsLoading(false)
                getStocktakingItems()
                addNotification({
                    message: 'Devices has been removed successfully',
                    type: 'success',
                })
                toast.success(response?.data, { hideProgressBar: true })
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    hideProgressBar: true,
                })
                setIsLoading(false)
            })
    }
    return (
        <Flex className="py-2 w-full">
            <Column className="gap-y-4 w-full">
                <RowAlignCenterSpaced className="gap-x-7">
                    <Text colorV="blue" variant="head" weightV="bold">
                        Script
                    </Text>
                    <div className="flex flex-row gap-x-4">
                        <Button onClick={handleRunScript}>
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Run the Script
                            </Text>
                        </Button>
                        {/* <Button onClick={handleBackToInhouse} backgroundColor="lgblue">
                            <Text colorV="white" variant="title" weightV="normal">
                                Back to inhouse
                            </Text>
                        </Button> */}
                    </div>
                </RowAlignCenterSpaced>

                <RowAlignCenterSpaced className="gap-x-5 w-full">
                    <Row className="gap-x-3">
                        <Button
                            onClick={() => handleTabClick('Device')}
                            backgroundColor={
                                activeTab === 'Device' ? 'blue' : 'grey'
                            }
                            className={
                                activeTab === 'Device'
                                    ? 'active-button'
                                    : 'inactive-button'
                            }
                        >
                            <Text
                                colorV={
                                    activeTab === 'Device' ? 'white' : 'blue'
                                }
                                variant="title"
                                weightV="normal"
                            >
                                Device
                            </Text>
                        </Button>
                        <Button
                            onClick={() => handleTabClick('Spareparts')}
                            backgroundColor={
                                activeTab === 'Spareparts' ? 'blue' : 'grey'
                            }
                            className={
                                activeTab === 'Spareparts'
                                    ? 'active-button'
                                    : 'inactive-button'
                            }
                        >
                            <Text
                                colorV={
                                    activeTab === 'Spareparts'
                                        ? 'white'
                                        : 'blue'
                                }
                                variant="title"
                                weightV="normal"
                            >
                                Spare parts
                            </Text>
                        </Button>
                    </Row>
                </RowAlignCenterSpaced>
                {activeTab === 'Device' && <Device itemsData={itemsData} />}
                {activeTab === 'Spareparts' && <Spareparts />}
                {isModal && <ScriptCalendar onClose={onClose} />}
            </Column>
        </Flex>
    )
}
