import React, { CSSProperties } from 'react';

interface CardProps {
  children: React.ReactNode;
  padding?: string;
  className?: string;
  borderColor?: string;
  style?: CSSProperties; // Add the style prop
  variant?: 'default' | 'white'; // Add a variant prop
}

export const PrimaryCard: React.FC<CardProps> = ({
  children,
  style,
  className,
  variant = 'default', // Set default value for the variant prop
}) => {
  const baseClassName = 'p-2 md:p-3 rounded-lg';
  const bgClassName = variant === 'white' ? 'bg-b9white' : 'bg-slate-100';

  return (
    <div style={style} className={`${bgClassName} ${baseClassName} ${className}`}>
      {children}
    </div>
  );
};
