export enum EventTypeEnum {
    InHouse = "in_house",
    OutOfHouse = "out_of_house",
  }
  export enum SortingLogicModules {
    Inventory = "inventory",
    Sales = "sales",
    Customer_Portal = "customer_portal",
    RMA = 'rma',
    MyRMA = 'my_rma',
    Stocktaking = 'stocktaking',
    Grading = 'grading',
    Repair = 'repair',
    Diagnostics = 'diagnostics',
    Polishing = 'polishing',
    ManualSorting = "manual_sorting",
    SupplierWarranty = "supplier_warranty"
  }
  
  export enum EventNameEnum {
    InHouse = "in_house",
    Sold = "sold",
    Customer_RMA_Claimed = "customer_rma_claimed",
    RMA_Declined = "rma_declined",
    RMA_Accepted = "rma_accepted",
    My_RMA = "My_RMA",
    My_RMA_Sent_To_Supplier = "my_rma_sent_to_supplier",
    Stocktaking = "Stocktaking",
    Missing = "Missing",
    Back_To_In_house = "Back_to_in_house",
    Set_On_Sale = "set_on_sale",
    Repair_Completed = "repair_completed",
    Repair_Cancelled = "repair_cancelled",
    Diagnostics_Received = "diagnostics_received",
    Diagnostics_Completed = "diagnostics_completed",
    Polishing_Received = "polishing_received",
    Polishing_Completed = "polishing_completed",
    WarrantyRepair = "warranty_repair",
    WarrantyRepaired = "warranty_repaired",
    WarrantyCantFix = "warranty_cant_fix",
    Deleted = "deleted"
  }
  export const sortingLogicEventType = [
    {
        title: "Inhouse",
        value: EventTypeEnum.InHouse
    },
    {
        title: "Out of House",
        value: EventTypeEnum.OutOfHouse
    }
  ]

  export const modules = [
    {
        value: SortingLogicModules.Inventory,
        title: 'Inventory'
    },
    {
        value: SortingLogicModules.Sales,
        title: 'Sales'
    },
    {
        value: SortingLogicModules.Customer_Portal,
        title: 'Customer portal'
    },
    {
        value: SortingLogicModules.RMA,
        title: 'RMA'
    },
    {
        value: SortingLogicModules.MyRMA,
        title: 'My Rma'
    },
    {
        value: SortingLogicModules.Stocktaking,
        title: 'Stocktaking'
    },
    {
        value: SortingLogicModules.Grading,
        title: 'Grading'
    },
    {
        value: SortingLogicModules.Repair,
        title: 'Repair'
    },
    {
        value: SortingLogicModules.Diagnostics,
        title: 'Diagnostics'
    },
    {
        value: SortingLogicModules.Polishing,
        title: 'Polishing'
    },
    {
        value: SortingLogicModules.ManualSorting,
        title: 'Manual sorting'
    },
    {
        value: SortingLogicModules.SupplierWarranty,
        title: 'Supplier warranty'
    },
];
