import React, { useEffect } from 'react'
import { Dropdown } from '../../base/Dropdown' // Import your Dropdown component
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced' // Import your RowAlignCenterSpaced component
import { ImageTextComponent } from '../../base/ImageText' // Import your ImageTextComponent component
import { Text } from '../../base/Text' // Import your Text component
import { Table } from '../../shared/Table'
import { PrimaryCard } from '../Page/PrimaryCard'
import moment from 'moment'
import { useFetch } from '../../../hooks/useFetch'
import { EventTypeEnum } from '../../../constant/SortingLogicEvent'
const baseUrl = process.env.REACT_APP_baseUrl;

type LoadingStatus = 'loading' | 'loaded' | 'error';

export const Stocktaking = () => {
    const { getRequest: getItems, data } = useFetch(
        `${baseUrl}/inventory/older-devices`
    );
    useEffect(() => {

        try{
            getItems();
        }catch(e){
            console.log(e)
        }  
    }, [getItems])
    let extractedData = []
    if (data) {
        extractedData = data?.map((item) => ({
            'IMEI/Serial number': item?.imei,
            Model: item?.model?.modelName,
            'Status': item?.sortingEvent?.name,
            Age: moment(item?.createdAt).fromNow() // Display age using Moment.js "fromNow" method
        }));
    }

    const columns = ['IMEI/Serial number', 'Model', 'Status', "Age"]
    return (
        <>
            <PrimaryCard className='w-full'>
                <div className="mb-5">
                    <Text colorV="blue" variant="heading" weightV="normal">
                        Inhouse devices older then 30 days
                    </Text>
                </div>
                <Table
                    columns={columns}
                    data={extractedData}
                    showActions={false}
                />
            </PrimaryCard>
        </>
    )
}
