import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useCallback, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Text } from '../../../../../base/Text'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { Button } from '../../../../../base/Button'
import { RenameManualSortingEvent } from '../components/Modals/RenameManualSortingEvent'

const style = {
  padding: '1rem 0.5rem',
  backgroundColor: 'transparent',
  cursor: 'default',
}
const ItemTypes = {
  CARD: 'card',
}

export interface CardProps {
  id: any
  name: string
  type: string
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  refetch: () => void
  cardItems: any
  handleItemRowClick: (id:any) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const Card: FC<CardProps> = ({ id, name, type, index, moveCard, refetch, cardItems, handleItemRowClick }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isRenameEventModalOpen, setIsRenameEventModalOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const onClose = useCallback(() => {
    setIsRenameEventModalOpen(false)
    setSelectedEvent(null)
    refetch()
  }, [refetch])

  const handleSelectChange = useCallback((id, name, type) => {
    setSelectedEvent({ id, name, type })
    setIsRenameEventModalOpen(true)
  },[])

  const rowClick = useCallback((id) => {
    handleItemRowClick(id);
  },[handleItemRowClick])

  return (
    <>
      <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        <div className='flex flex-row justify-between items-center' onClick={(e) => {
          e.stopPropagation();
          rowClick(id)
        }}>
          <Text
            colorV="blue"
            variant="title"
            weightV="normal"
            className='flex-1'
          >
            {name}

          </Text>
          <Text
            colorV="blue"
            variant="title"
            weightV="normal"
            className='flex-1'
          >
            {type}

          </Text>
          <button className='bg-transparent' onClick={(e) => {
            e.stopPropagation(); // Prevent row click event from triggering
            handleSelectChange(id, name, type);
          }}>
            <Text colorV="blue" variant="title" weightV="normal">
              Rename
            </Text>
          </button>
        </div>
      </div>
      {isRenameEventModalOpen && (
        <RenameManualSortingEvent
          onClose={onClose}
          eventData={selectedEvent}
          eventTypes={['in_house', 'out_of_house']}
        />
      )}
    </>

  )
}
