// RowSpaced.tsx
import React, { ReactNode, CSSProperties } from 'react'

interface RowSpacedProps {
    children: ReactNode
    className?: string
    inlineStyle?: CSSProperties
}

export const RowSpaced: React.FC<RowSpacedProps> = ({
    children,
    className,
    inlineStyle,
}) => {
    const spacedStyle: CSSProperties = {
        display: 'flex',
        gap: '22px', // Adjust the gap as needed
    }

    return (
        <div
            className={`flex ${className}`}
            style={{ ...spacedStyle, ...inlineStyle }}
        >
            {children}
        </div>
    )
}
