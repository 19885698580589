import React, { useCallback, useEffect, useState } from 'react';
import { Row } from '../../../../../layout/Row';
import { Text } from '../../../../../base/Text';
import { Table } from '../../../../../shared/Table';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Column } from '../../../../../layout/Col';
import { MyIcon } from '../../../../../base/MyIcon';
import { DeviceDetail } from './DeviceDetail'; // Import the DeviceDetail component
import { useFetch } from '../../../../../../hooks/useFetch';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as xlsx from 'xlsx'; // Import the xlsx library
import { Button } from '../../../../../base/Button';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';

const baseUrl = process.env.REACT_APP_baseUrl;

export const Devices = ({ handleBackButtonClick }:any) => {
    const [selectedRowData, setSelectedRowData] = useState(null); // State to store selected row data
    const [isBack, setIsBack] = useState(false);
    const { getRequest: getTransitItems, data: itemsData } = useFetch(
        `${baseUrl}/transit/transit-items`
    );
    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    );

    useEffect(() => {
        getTransitItems();
        getCodes();
    }, [getTransitItems, getCodes]);

    const handleItemClick = useCallback(async (e, comment, selectedItem) => {
        e.stopPropagation();
        e.preventDefault();
        const dataArray = [];
        if (selectedItem) {
            selectedItem.forEach((element) => {
                const codeDescriptions = (element.codes || []).map(code => {
                    const matchingCode = codesData.find(data => data.label === code);
                    return matchingCode ? matchingCode.description : ''; // Return description if code matches, otherwise return an empty string
                }).join(' ').replace(/,/g, ' '); // Replace commas with spaces

                dataArray.push({
                    Date: moment(element.createdAt).format('DD/MM/YYYY') || '',
                    Model: element.model?.modelName || '', // Use optional chaining to handle missing model
                    Storage: element.storage || '',
                    Imei: element.imei || '',
                    Code: (element.codes || []).join(' '), // Use default array if codes is missing
                    Status: element.sortingEvent?.name || '',
                    Description: codeDescriptions
                });
            });

            const worksheet = xlsx.utils.json_to_sheet(dataArray);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const currentDate = moment().format('YYYYMMDD');
            xlsx.writeFile(workbook, `${currentDate}-sale-order-imei.xlsx`);
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [codesData]);

    const handleRowClick = (selectedRow) => {
        setIsBack(true);
        setSelectedRowData(selectedRow);
    };

    const handleBack = useCallback(() => {
        setIsBack(false);
    }, []);

    return (
        <Column className='gap-y-5 w-full'>
            {selectedRowData && isBack ? (
                <DeviceDetail codesData={codesData} rowData={selectedRowData} handleIsBack={handleBack} />
            ) : (
                <>
                    <RowAlignCenterSpaced>
                        <Text
                            colorV="blue"
                            variant="head"
                            weightV="bold"
                        >
                            Devices in transit
                        </Text>
                        <Button backgroundColor='blue' onClick={handleBackButtonClick}>
                            <Text
                                colorV="white"
                                variant="body"
                                weightV="normal"
                            >
                                Back
                            </Text>
                        </Button>
                    </RowAlignCenterSpaced>
                    <Row>
                        <PrimaryCard className='w-full'>
                            <Table
                                columns={['Vendor name', 'Date when sent', 'Number of devices', 'Comment']}
                                data={itemsData?.map(item => ({
                                    'Vendor name': item.vendor.companyName,
                                    'Date when sent': item.dateOfGroup,
                                    'Number of devices': Object.keys(item.items).length,
                                    item: item.items,
                                    Comment: (
                                        <div className='flex flex-row items-center overflow-auto gap-x-3'>
                                            <div className='w-6 flex justify-center items-center' onClick={(e) => handleItemClick(e, item.comments[0], item.items)}>
                                                <MyIcon name="comment" />
                                            </div>
                                            <Text colorV='blue' weightV='normal' variant='body'>{item.comments[0]}</Text>
                                        </div>
                                    ),
                                }))}
                                showActions={false}
                                clickable={true}
                                handleRowClick={handleRowClick}
                                maxWidth='450px'
                            />
                        </PrimaryCard>
                    </Row>
                </>
            )}
        </Column>
    );
};
