import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Logo from '../assets/logo.png'
import { Button } from '../components/base/Button'
import { Text } from '../components/base/Text'
import { TextField } from '../components/base/Textfield'
import Card from '../components/compound/Page/Card/Card'
import { Pagebg } from '../components/compound/Page/Pagebg'
import { useAuth } from '../utils/auth'
import { RowAlignCenter } from '../components/layout/RowAlignCenter'
import { Column } from '../components/layout/Col'
import { Row } from '../components/layout/Row'
const baseUrl = process.env.REACT_APP_baseUrl

export default function LoginPage() {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [activeCompany, setActiveCompany] = useState<any>()

    const auth = useAuth()
    const navigate = useNavigate()

    const handleEmailChange = (newValue) => {
        setEmail(newValue)
    }
    const handlePasswordChange = (newValue) => {
        setPassword(newValue)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        authenticateUser()
    }

    const authenticateUser = useCallback(() => {
        axios
            .post(`${baseUrl}/auth/login`, {
                email,
                password,
            })
            .then((response) => {
                const role = response?.data?.role
                console.log(response?.data);
                if (response?.data?.company[0] && !role?.includes('superAdmin')) {
                    localStorage.setItem('activeCompany', JSON.stringify(response?.data?.company[0]))
                    if (response?.data?.user?.profiles.length) {
                        localStorage.setItem('activeProfile', JSON.stringify(response?.data?.user?.profiles[0]));
                    }
                }
                localStorage.setItem('user', JSON.stringify(response?.data))
                auth.login(response.data)
                if (role?.includes('admin')) {
                    navigate('/dashboard')
                } else if (role?.includes('customer')) {
                    navigate('/purchase')
                } else if (role?.includes('superAdmin')) {
                    navigate('/home')
                } else if (role?.includes('inventory')) {
                    navigate('/inventory')
                } else {
                    navigate('/')
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [auth, email, password, navigate])

    useEffect(() => {
        if (auth?.user) {
            if (auth.user.role?.includes('admin')) {
                navigate('/dashboard')
            } else if (auth.user?.role?.includes('customer')) {
                navigate('/home')
            } else if (auth.user?.role?.includes('superAdmin')) {
                navigate('/home')
            } else if (auth.user?.role?.includes('inventory')) {
                navigate('/inventory')
            } else {
                navigate('/')
            }
        }
    }, [auth.user, navigate])

    return (
        <Pagebg className="bg-gray-day-1 flex flex-col justify-center items-center h-full p-4 overflow-hidden">
            <Card className="bg-white max-w-lg w-full">
                <form onSubmit={handleSubmit}>
                    <div className="px-5 py-4">
                        <div className="flex justify-center">
                            <img alt="" className="h-20 w-36" src={Logo} />
                        </div>
                        <RowAlignCenter className="py-3">
                            <Text
                                colorV="mid-blue"
                                variant="heading"
                                weightV="bold"
                            >
                                Log in
                            </Text>
                        </RowAlignCenter>
                        <Column className="gap-y-3">
                            <Column>
                                <div className="my-2">
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Email
                                    </Text>
                                </div>
                                <TextField
                                    value={email}
                                    backgroundColor='#F2F0ED'
                                    type='email'
                                    onChange={handleEmailChange}
                                    placeholder="Enter email"
                                    className='w-full'
                                />
                            </Column>
                            <Column>
                                <div className="my-2">
                                    <Text
                                        colorV="blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Password
                                    </Text>
                                </div>
                                <TextField
                                    backgroundColor='#F2F0ED'
                                    icon={true}
                                    type="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    placeholder="Enter password"
                                    className='w-full'
                                />
                            </Column>
                            <Column className="flex justify-center mt-7 ">
                                <Button
                                    onClick={handleSubmit}
                                    className="w-full rounded-2xl py-3"
                                >
                                    <Text
                                        colorV="white"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Next
                                    </Text>
                                </Button>
                            </Column>
                            <Row className=" mt-7 ">
                                <Link to="/forgetPassword" className="">
                                    <Text
                                        colorV="mid-blue"
                                        variant="title"
                                        weightV="bold"
                                    >
                                        Forget Password
                                    </Text>
                                </Link>
                            </Row>
                        </Column>
                    </div>
                </form>
            </Card>
        </Pagebg>

        // <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        //   <div className="max-w-md w-full space-y-8">
        //     <Header
        //       heading="Login to your account"
        //       paragraph=""
        //       linkName=""
        //       linkUrl=""
        //     />
        //     <Login />
        //   </div>
        // </div>
    )
}
