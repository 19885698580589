import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../base/Button'
import { ModalBox } from '../../base/ModelBox'
import { TextBackground } from '../../base/TextBackground'
import { Column } from '../../layout/Col'
import { Flex } from '../../layout/Flex'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Text } from '../../base/Text'
import moment from 'moment'
import { toast } from 'react-toastify'
import { ItemSearchModal } from '../../shared/SearchModal/ItemSearchModal'
import * as XLSX from 'xlsx'

export const PonHistory: React.FC<{ onClose: () => void, item: any }> = ({ onClose, item }) => {
    const [rmaDays, setRmaDays] = useState()
    const handleImeiListDownload = useCallback(async () => {
        if (item?.purchaseId?.items) {
            const wsData = item?.purchaseId?.items?.map((element) => ({
                Imei: element?.imei,
                Model: element?.model?.modelName || "",
                Storage: element?.storage || "",
                Grade: element?.grade?.grade || ""
            }));

            const ws = XLSX.utils.json_to_sheet(wsData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "IMEI List");

            XLSX.writeFile(wb, "imeiList.xlsx");
            onClose();
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [item?.purchaseId?.items, onClose]);

    const handleInvoiceDownload = useCallback(() => {
        try {
            const blobUrl = item?.purchaseId?.filePath;
            const link = document.createElement('a');
            link.href = blobUrl;

            // Specify the desired filename here
            link.download = 'custom-filename.xlsx';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }, [item?.purchaseId?.filePath]);

    useEffect(() => {
        if (item) {
            const startDate = moment(item?.purchaseId?.createdAt);
            const endDate = moment(item?.createdAt).add(1, 'days').add(item?.purchaseId?.assignMyRmaDuration, 'days');

            if (startDate && startDate.day() === 5) {
                startDate.add(2, 'days');
            } else if (startDate && startDate.day() === 6) {
                startDate.add(1, 'day');
            }

            const remainingDays = endDate.diff(moment(), 'days');
            let remainingDaysString;

            if (remainingDays > 0) {
                remainingDaysString = `${remainingDays} day${remainingDays !== 1 ? 's' : ''} left`;
            } else if (remainingDays === 0) {
                remainingDaysString = 'Today is the last day';
            } else {
                remainingDaysString = 'The deadline has passed';
            }

            setRmaDays(remainingDaysString);
        }
    }, [item]);

    return (
        <ItemSearchModal heading="Order details" width='500px' height='550px' onClose={onClose}>
            <Column className="mt-3 gap-y-4 h-[515px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        PON
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant='title'>
                            {item?.purchaseId?.saleBatchNumber}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        Customer
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.purchaseId?.customer?.companyName}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant='title'>
                        Date the order is sent
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {moment(item?.purchaseId?.createdAt).format('DD.MM.YYYY')}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant='title' weightV="bold">
                        Logistic company
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.purchaseId?.logisticCompany?.companyName}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant='title' weightV="bold">
                        Tracking number
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.purchaseId?.trackingNumber}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant='title' weightV="bold">
                        Type of sale
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.purchaseId?.saleType}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant='title' weightV="bold">
                        RMA
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {rmaDays}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant='title' weightV="bold">
                        Total value
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-72">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.purchaseId?.totalValue}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="mt-4">
                    <Button onClick={handleImeiListDownload} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Download IMEI list
                        </Text>
                    </Button>
                    <Button onClick={handleInvoiceDownload} backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Download invoice
                        </Text>
                    </Button>
                </RowAlignCenterSpaced>
            </Column>
        </ItemSearchModal>
    )
}
