import React from 'react'
import { PrimaryCard } from '../../../compound/Page/PrimaryCard'
import { Flex } from '../../../layout/Flex'
import { Table } from '../../../shared/Table'
import moment from 'moment'

export const CustomerReportedRMA = ({ data }: any) => {
    console.log({ data })
    const columns = [
        'PON',
        'Purchase date',
        'IMEI',
        'Model',
        'Storage',
        'Price',
        'Vat',
        'Reported issue',
        'Status',
        'Comment',
    ]
    let extractedData = []
    if (data) {
        const filteredItems = data?.items?.filter(item => item?.rmaItemsStatus === 'reported_rma');
        extractedData = filteredItems.map((item) => {
            return {
                id: item?._id,
                PON: data?.purchaseOrder?.saleBatchNumber,
                'Purchase date': moment(item?.createdAt).format('DD.MM.YYYY'),
                IMEI: item?.imei,
                Model: item?.model?.modelName,
                Storage: item?.storage,
                Price: item?.salePrice,
                Vat: item?.vat,
                'Reported issue': item?.reasonOfReturn,
                Status: item?.acceptance,
                Comment: ""
                // item?.comment === "undefined" ? " " : item.comment,
            }
        })
    }

    return (
        <PrimaryCard className="">
            <div className="overflow-auto rounded-lg text-left" style={{ maxWidth: '100%', width: '100%', overflowX: 'auto' }}>
                <Table
                    className='table-fixed'
                    columns={columns}
                    data={extractedData}
                    clickable={false}
                    showActions={false}
                />
            </div>
        </PrimaryCard>
    )
}
