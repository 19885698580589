import { useCallback, useEffect } from 'react'
import HeadButtons from './components/HeadButtons'
import UsersTable from './components/UsersTable'
import { useFetch } from '../../../../hooks/useFetch'
const baseUrl = process.env.REACT_APP_baseUrl

export const UserAccess = () => {
    const { getRequest, data } = useFetch(`${baseUrl}/user/data`)

    useEffect(() => {
        getRequest()
    }, [getRequest])

    const refetch = useCallback(() => {
        getRequest()
    }, [getRequest])

    return (
        <>
            <HeadButtons data={data} refetch={refetch} />
            <UsersTable data={data} refetch={refetch} />
        </>
    )
}
