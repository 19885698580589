import { useState } from 'react'
import { Button } from '../../../base/Button'
import { Text } from '../../../base/Text'
import { Flex } from '../../../layout/Flex'

import { Column } from '../../../layout/Col'
import { Row } from '../../../layout/Row'
import { BulkSale } from './components/BulkSale'; // Import your Bulk Sale component
import { SingleSale } from './components/SingleSale'; // Import your Single Sale component

export default function Head() {
    const [activeTab, setActiveTab] = useState('Single Sale')

    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Flex className="pb-4 pt-2 w-full">
            <Column className='w-full'>
                <Row className="gap-x-4 mb-5">
                    <Text
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        Point of Sale
                    </Text>
                    <Button
                        backgroundColor={
                            activeTab === 'Single Sale' ? 'blue' : 'grey'
                        }
                        onClick={() => handleTabClick('Single Sale')}
                    >
                        <Text
                            colorV={
                                activeTab === 'Single Sale' ? 'white' : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Single Sale
                        </Text>
                    </Button>
                    <Button
                        backgroundColor={
                            activeTab === 'Bulk Sale' ? 'blue' : 'grey'
                        }
                        onClick={() => handleTabClick('Bulk Sale')}
                    >
                        <Text
                            colorV={
                                activeTab === 'Bulk Sale' ? 'white' : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Bulk Sale
                        </Text>
                    </Button>
                </Row>
                    {activeTab === 'Single Sale' && <SingleSale />}{' '}
                    {activeTab === 'Bulk Sale' && <BulkSale />}{' '}
            </Column>
        </Flex>
    )
}
