import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Flex } from '../../../../layout/Flex';
import { Table } from '../../../../shared/Table';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import { PdfDownload } from './PdfDownload';
import { ArchiveCreditNote } from './ArchiveCreditNote';
import { DeleteCreditNote } from './DeleteCreditNote';
import { EditCreditNote } from './EditCreditNote';
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess';
import { useAuth } from '../../../../../utils/auth';

export const CreditNoteTable: React.FC<{ data: any; refetchData: () => void }> = ({ data, refetchData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedModel, setSelectedModel] = useState<any>();
    const auth = useAuth();
    const editRmaCreditNotesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.RMA && acc.permission.includes(AccessPermissions.Edit));
    console.log({ data });
    const columns = [
        'Credit Note N',
        'Customer',
        'Issued',
        'Status',
        'Value',
        'Issued for RMA',
        'Account manager',
    ];

    let extractedData = [];
    if (data) {
        const withOutArchiveData = data?.filter((item) => item?.archiveStatus === false);
        extractedData = withOutArchiveData?.map((item) => {
            return {
                id: item?._id,
                'Credit Note N': item?.creditNumber,
                Customer: item?.customer?.companyName,
                Issued: moment(item?.createdAt).format('DD.MM.YY'),
                Value: item?.creditValue,
                'Issued for RMA': item?.issuedFor?.rmaNumber,
                Status: item?.status,
                'Account manager': item?.customer?.accountManager?.accountManager,
                path: item?.filePath,
                customerId: item?.customer?._id,
                item: item,
            };
        });
    }
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOption(null);
        refetchData()
    };

    const modalComponents = {
        Edit: <EditCreditNote item={selectedModel} onClose={closeModal} />,
        Delete: <DeleteCreditNote item={selectedModel} onClose={closeModal} />,
        Archive: <ArchiveCreditNote item={selectedModel} onClose={closeModal} />,
        'Download PDF': <PdfDownload item={selectedModel} onClose={closeModal} />,
    };

    const handleDropdownChange = (optionValue, item) => {
        setSelectedModel(item);
        setSelectedOption(optionValue);
        setIsModalOpen(true);
        refetchData()
    };

    return (
        <>
            <Flex className=" w-full">
                <PrimaryCard className="w-full">
                    <Table
                        columns={columns}
                        data={extractedData}
                        clickable={true}
                        showActions={true}
                        handleDropdownChange={handleDropdownChange}
                        options={editRmaCreditNotesCheck ? ['Edit', 'Delete', 'Archive', 'Download PDF'] : ['Delete', 'Archive', 'Download PDF']}
                    />
                </PrimaryCard>
            </Flex>
            {isModalOpen && modalComponents[selectedOption]}
        </>
    );
};
