import React, { useCallback } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { useAuth } from '../../../../../utils/auth'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TextBackground } from '../../../../base/TextBackground'
import { usePost } from '../../../../../hooks/usePost'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const RepairModal: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendRequest } = usePost()
    const navigate = useNavigate()

    const handleStartRepair = useCallback(async () => {
        await sendRequest(
            `${baseUrl}/repair/start-at/${item?.Id}`,
            {
                item: item?.Id,
            },
        )
            .then(() => {
                navigate(`repair-detail/${item?.Id}`)
                toast.success("Repair Time Started", { hideProgressBar: true });
                addNotification({
                    message: 'Repair Time Started',
                    type: 'success',
                })
            })
            .catch((error) => console.error(`Error: ${error}`));
    }, [item?.Id, navigate, sendRequest])

    const handleGoToRepair = useCallback(() => {
        navigate(`repair-detail/${item?.Id}`)
    }, [item?.Id, navigate])

    return (
        <ModalBox
            heading="View"
            onClose={onClose}
            top="50%"
            height="auto"
            width="430px"
        >
            <Column className="mt-3 gap-y-4 h-[350px] overflow-auto">
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Status
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.Event}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Model
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.Model} {item?.Storage}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        VAT
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.VAT}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Code
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.Code}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-4">
                    <Text colorV="blue" variant="title" weightV="normal">
                        Comment
                    </Text>
                    <TextBackground className='w-64' backgroundColor='b9white'>
                        {item?.Comment}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenter className="gap-x-4 mt-4">
                    {
                        item?.Repair?.startAt ?
                            <Button
                                className='w-64'
                                onClick={handleGoToRepair}
                                backgroundColor="lgblue"
                            >
                                <Text colorV="white" variant="title" weightV="normal">
                                    Go to repair
                                </Text>
                            </Button> :
                            <Button
                                className='w-64'
                                onClick={handleStartRepair}
                                backgroundColor="lgblue"
                            >
                                <Text colorV="white" variant="title" weightV="normal">
                                    Start repair
                                </Text>
                            </Button>
                    }
                </RowAlignCenter>
            </Column>
        </ModalBox>
    )
}
