import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

interface LineChartProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string | CanvasGradient;
      pointBorderColor: string;
      borderWidth: number;
      fill?: boolean | string | CanvasGradient;
      pointBackgroundColor?: string;
    }[];  
  };
  options?: any;
  gradientColors?: string[];
}

export const TwoDatasetLineChart: React.FC<LineChartProps> = ({ data, options, gradientColors }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  
  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    if (!ctx) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const updatedDatasets = data?.datasets.map((dataset, index) => {
      const gradient = ctx.createLinearGradient(0, 0, 0, chartRef.current!.height);

      return {
        ...dataset,
        borderColor: gradientColors[index % gradientColors.length],
        pointBorderColor: 'white',
        borderWidth: 2,
        pointRadius: 2,
        fill: {
          target: 'origin',
          above: gradient, // Fill above the line with gradient color
        },
      };
    });

    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        ...data,
        datasets: updatedDatasets,
      },
      options: {
        scales: {
          y: {
            grid: {
              display: false,
            },
            beginAtZero: true, // Force the y-axis to begin at zero
          },
          x: {
            grid: {
              display: false,
            },
            beginAtZero: true, // Force the y-axis to begin at zero
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, gradientColors, options]);

  return <canvas ref={chartRef} />;
};
