import axios from 'axios'
import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { TextField } from '../../../../base/Textfield'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { FileUpload } from '../../../../shared/Fileupload'
import { myStatus } from '../../../../../constant/Storage'
import { useFetch } from '../../../../../hooks/useFetch'
import { TextBackground } from '../../../../base/TextBackground'
import { Icon } from '../../../../base/Icon'
import { Row } from '../../../../layout/Row'
import { addNotification } from '../../../../shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

export const IssuedCN: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [customer, setCustomer] = useState<any>('')
    const [creditNoteNumber, setCreditNoteNumber] = useState<any>('')
    const [creditNoteValue, setCreditNoteValue] = useState<any>('')
    const [status, setStatus] = useState<any>('')
    const [issuedFor, setIssuedFor] = useState<any>('')
    const [pdfFile, setPdfFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth()
    const company = auth.selectedCompany
    const handlePdfFileUpload = (file: File) => {
        setPdfFile(file)
    }

    const token = auth?.user?.token?.token

    const { getRequest: getPartners, data: partnerData } = useFetch(
        `${baseUrl}/partner/get-partners`
    )

    useEffect(() => {
        getPartners()
        setIssuedFor(item?._id)
        setCustomer(item?.customer._id)
    }, [getPartners, item?._id, item?.customer._id])

    const handleCustomerChange = (event: any) => {
        setCustomer(event.target.value)
    }
    const handleCreditNoteNumberChange = (newValue) => {
        setCreditNoteNumber(newValue)
    }

    const handleCreditNoteValueChange = (newValue) => {
        setCreditNoteValue(newValue)
    }

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value)
    }

    const handleUpdate = useCallback(
        async (e: any) => {
            e.preventDefault()
            if (!creditNoteNumber) {
                alert('Please, add credit number')
                return
            } else if (!creditNoteValue) {
                alert('Please, add credit note value')
                return
            } else if (!pdfFile) {
                alert('Please, add PDF')
                return
            }
            setIsLoading(true);
            const formData = new FormData()
            formData.append('file', pdfFile)
            formData.append('customer', customer)
            formData.append('creditNoteNumber', creditNoteNumber)
            formData.append('creditNoteValue', creditNoteValue)
            formData.append('status', status)

            const url = `${baseUrl}/creditnote/add-credit-note/${item?._id}`
            const options = {
                url: url,
                method: 'POST',
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: token as string,
                    Company: company._id
                },
                data: formData,
            }
            try {
                await axios(options)
                toast.success('credit note uploaded successfully.', {
                    hideProgressBar: true,
                })
                addNotification({
                    message: 'Credit note uploaded successfully',
                    type: 'success',
                })
                onClose()
                setPdfFile(null)
            } catch (error) {
                toast.error(error?.response?.data?.message, {
                    hideProgressBar: true,
                })

            }
            finally {
                setIsLoading(false);
            }
        },
        [company._id, creditNoteNumber, creditNoteValue, customer, item?._id, onClose, pdfFile, status, token]
    )
    return (
        <ModalBox
            heading="Upload new credit note"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[520px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Customer
                    </Text>
                    <div style={{ width: '16rem' }}>
                        {item?.customer?.companyName ?
                            <TextBackground className='w-full' backgroundColor="b9white">
                                <Text colorV="blue" variant="title" weightV="normal">
                                    {item?.customer?.companyName}
                                </Text>
                            </TextBackground>

                            : <select
                                id="customer"
                                defaultValue={item?.customer?._id}
                                onChange={(e) => handleCustomerChange(e)}
                                className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 p-2"
                            >
                                <option value="">Select</option>
                                {partnerData?.map((partner: any) => (
                                    <option key={partner?._id} value={partner?._id}>
                                        {partner?.companyName}
                                    </option>
                                ))}
                            </select>
                        }

                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Credit note N
                    </Text>
                    <TextField
                        value={creditNoteNumber}
                        onChange={handleCreditNoteNumberChange}
                        placeholder="Enter Credit note"
                        
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Credit note value
                    </Text>
                    <TextField
                        value={creditNoteValue}
                        onChange={handleCreditNoteValueChange}
                        placeholder="Enter Credit note"
                        
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="model"
                            defaultValue={item?.creditNote?.status}
                            onChange={(e) => handleStatusChange(e)}
                            className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 p-2"
                        >
                            <option value={item?.creditNote?.status}>
                                {item?.creditNote?.status || 'Select'}{' '}
                            </option>
                            {myStatus?.map((status: any) => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Issued for
                    </Text>
                    {
                        item?.rmaNumber ?
                            <TextBackground className='w-64' backgroundColor='b9white'>

                                <Text colorV="blue" weightV="normal" variant="title">
                                    {item?.rmaNumber}
                                </Text>
                            </TextBackground>

                            :
                            <select
                                id="customer"
                                defaultValue={item?.customer?._id}
                                onChange={(e) => handleCustomerChange(e)}
                                className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 p-2"
                            >
                                <option value="">Select</option>
                                {partnerData?.map((partner: any) => (
                                    <option key={partner?._id} value={partner?._id}>
                                        {partner?.companyName}
                                    </option>
                                ))}
                            </select>
                    }

                </RowAlignCenterSpaced>
                <Column className="mt-5 gap-y-3">
                    <FileUpload
                        fileTypeDescription='PDF'
                        onFileUpload={(file) => handlePdfFileUpload(file)}
                        acceptedFileTypes=".pdf"
                    />
                    {pdfFile && <Row className='gap-x-3 items-center'>
                        <Text colorV="blue" variant="title" weightV="normal">
                            Uploaded File: {pdfFile?.name}
                        </Text>
                        <span className='cursor-pointer' onClick={() => setPdfFile(null)}>
                            <Icon name="cross" color="black" size={16} />
                        </span>
                    </Row>}
                </Column>
                <Flex className="gap-x-4 mt-2">
                    <Button className='w-56' backgroundColor="lgblue" onClick={handleUpdate}>
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Updating...' : 'Update'}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
