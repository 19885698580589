import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { Flex } from '../../../layout/Flex'
import { Table } from '../../../shared/Table'
import { PrimaryCard } from '../../Page/PrimaryCard'
import { InvoiceModal } from './InvoiceModal'


export default function InvoiceTable({ itemsData }: any) {
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    let extractedData = []
    const invoiceMap = new Map();
    if (itemsData) {
        Object.keys(itemsData).forEach((key) => {
            const item = itemsData[key];
            const endDate = moment(item?.createdAt).add(1, 'days').add(item?.rmaSupplier, 'days');
            const remainingDays = endDate.diff(moment(), 'days');

            const remainingDaysString =
                remainingDays > 0
                    ? `${remainingDays} day${remainingDays !== 1 ? 's' : ''} left`
                    : remainingDays === 0
                        ? 'Today is the last day'
                        : 'The deadline has passed';

            const invoiceNumber = item?.invoiceNumber;

            if (!invoiceMap.has(invoiceNumber)) {
                invoiceMap.set(invoiceNumber, {
                    'Invoice Number': invoiceNumber,
                    Supplier: item?.supplier?.companyName,
                    Received: moment(item?.createdAt).format('DD.MM.YYYY'),
                    RMA: remainingDaysString,
                });
            }
        });

        extractedData = Array.from(invoiceMap.values());
    }

    const columns = ['Invoice Number', 'Supplier', 'Received', 'RMA'];

    const handleRowClick = (rowData) => {
        setSelectedRow(rowData);
        setIsModalOpen(true);
    };

    const onClose = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Flex className="mt-5 ">
                <PrimaryCard className='w-full'>
                    <Table
                        columns={columns}
                        data={extractedData}
                        showActions={false}
                        clickable={true}
                        handleRowClick={handleRowClick}
                    />
                </PrimaryCard>
            </Flex>
            {isModalOpen && (
                <InvoiceModal
                    item={selectedRow}
                    onClose={onClose}
                    itemsData={itemsData}
                />
            )}
        </>
    )
}
