import React, { useState } from 'react'
import { Button } from '../../../../../base/Button'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenter } from '../../../../../layout/RowAlignCenter'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { ExportModel } from './ExportModel'
import { Text } from '../../../../../base/Text'
import { AddNewsku } from './AddNewsku'
interface HeadButtonsProps {
    items:any;
    handleSaleInventorySku: () => void; // Specify the correct type for your function
  }
export const HeadButtons: React.FC<HeadButtonsProps> = ({ handleSaleInventorySku, items }) => {

    const [modalContent, setModalContent] = useState(null);

    const handleExport = () => {
        setModalContent(<ExportModel items={items} onClose={() => setModalContent(null)} />);
    };

    const handleAddNewsku = () => {
        setModalContent(
            <AddNewsku onClose={() => { handleSaleInventorySku(); setModalContent(null); }} />
        );
    };

    return (
        <Flex className="w-full">
            <Column className="py-2 w-full">
                <RowAlignCenterSpaced className="gap-x-5 w-full">
                    <Text colorV="blue" variant="head" weightV="bold">
                        Manage Sales SKU
                    </Text>
                    <RowAlignCenter className="gap-x-5">
                        <Button onClick={handleExport} backgroundColor="green">
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Export
                            </Text>
                        </Button>
                        <Button
                            onClick={handleAddNewsku}
                            backgroundColor="lgblue"
                        >
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Add new sku
                            </Text>
                        </Button>
                    </RowAlignCenter>
                </RowAlignCenterSpaced>
                {modalContent}
            </Column>
        </Flex>
    )
}
