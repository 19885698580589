import React, { useCallback, useEffect, useState } from 'react'
import { operationSystemList } from '../../../../../../../constant/Storage'
import { Column } from '../../../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../../../base/Text'
import { useFetch } from '../../../../../../../hooks/useFetch'
import { Button } from '../../../../../../base/Button'
import { Flex } from '../../../../../../layout/Flex'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../../utils/auth'
import { ModalBox } from '../../../../../../base/ModelBox'
import { MyIcon } from '../../../../../../base/MyIcon'
import { CodeOptions } from './CodeOptions'
import { usePost } from '../../../../../../../hooks/usePost'
import { addNotification } from '../../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl;


export const AddSupplier: React.FC<{ onClose: () => void; partners: any }> = ({ onClose, partners }) => {
    const [supplier, setSupplier] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [codes, setCodes] = useState<string[]>([]);
    const [showCodes, setShowCodes] = useState(false);
    const auth = useAuth();
    const token = auth?.user?.token?.token;
const {sendRequest} = usePost()
    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    );

    useEffect(() => {
        getCodes();
    }, [getCodes]);

    const handleSupplierChange = (event: any) => {
        setSupplier(event.target.value);
    };

    const handleCodeSelection = (selectedCodes: string[]) => {
        setCodes(selectedCodes);
    };

    const closeCodes = () => {
        setShowCodes(false);
    };

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value);
    };

    const handleSubmit = useCallback(
        async (e: any) => {
            e.preventDefault();
            if (!status || codes.length === 0 || !supplier) {
                alert('Please fill in all fields.');
                return;
            }
            setIsLoading(true);
            const urlItem = `${baseUrl}/sorting-logic/create-supplier`;
            try {
                const response = await sendRequest(
                    urlItem,
                    {
                        supplier,
                        codes,
                        status,
                    },
                );
                if (response.data) {
                    toast.success(response.data.message || 'Added Successfully', { hideProgressBar: true });
                    addNotification({
                        message: 'Supplier has been added successfully',
                        type: 'success',
                    });
                    onClose();
                } else {
                    toast.error(response.data.error || 'Something went wrong', { hideProgressBar: true });
                }
            } catch (error) {
                toast.error(`Error: ${error.response?.data?.error || 'Something went wrong'}`, {
                    hideProgressBar: true,
                });
            } finally {
                setIsLoading(false);
            }
        },
        [status, codes, supplier, sendRequest, onClose]
    );

    return (
        <>
                <ModalBox
            heading="Add new rule"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[230px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Supplier
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="os"
                            value={supplier}
                            onChange={handleSupplierChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {partners?.map((partner: any) => (
                                <option key={partner._id} value={partner?._id}>
                                    {partner.companyName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="os"
                            value={status}
                            onChange={handleStatusChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            <option value='Active'>
                                Active
                            </option>
                            <option value='Inactive'>
                                Inactive
                            </option>
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Codes
                    </Text>
                    <Button
                        backgroundColor="grey"
                        className="w-[257px]"
                        onClick={() => setShowCodes(true)}
                    >
                        <Flex className="gap-x-5">
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                Select
                            </Text>
                            <MyIcon name="upTick" size={16} />
                        </Flex>
                    </Button>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button
                        className='w-32'
                        backgroundColor="lgblue"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Saving...' : 'Save'}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
        {showCodes && (
                <CodeOptions
                    codesData={codesData}
                    onClose={closeCodes}
                    onCodeSelection={handleCodeSelection}  // Pass the callback to the child
                />
            )}
        </>

    )
}
