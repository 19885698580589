import React, { useCallback, useState } from 'react';
import { Text } from '../../../base/Text';
import { Button } from '../../../base/Button';
import { TextField } from '../../../base/Textfield';
import { Column } from '../../../layout/Col';
import { Flex } from '../../../layout/Flex';
import { RowAlignCenter } from '../../../layout/RowAlignCenter';
import { PrimaryCard } from '../../Page/PrimaryCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../utils/auth';
import { SearchData } from './component/SearchData';
import { usePost } from '../../../../hooks/usePost';
import { Row } from '../../../layout/Row';
import { useEdit } from '../../../../hooks/useEdit';
import { BackToInhouse } from './component/BackToInhouse';
const baseUrl = process.env.REACT_APP_baseUrl;

export const ManualSorting = () => {
    const [Imei, setImei] = useState('');
    const [searchData, setSearchData] = useState(null);
    const { sendRequest } = usePost()
    const [isBackToInhouse, setIsBackToInhouse] = useState(false)
    const handleImeiChange = (newValue: any) => {
        setImei(newValue);
    };

    const handleSearch = useCallback(async () => {
        try {
            const response = await sendRequest(
                `${baseUrl}/inventory/search`,
                {
                    Imei,
                },
            );
            if (response?.data === null) {
                toast.error('No data found', {
                    hideProgressBar: true,
                });
            }
            setSearchData(response?.data);
        } catch (error) {
            console.log(error)
            if (error.response?.status === 404) {
                toast.error('No data found', {
                    hideProgressBar: true,
                });
                setSearchData(null);
            } else {
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                });
            }
        }
    }, [Imei, sendRequest])

    const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }, [handleSearch]);

    const handleBack = useCallback(() => {
        setSearchData(null);
        setImei('')
    }, []);

    const handleOpenBackToInhouse = useCallback(() => {
        setIsBackToInhouse(true)
    }, [])

    const onClose = useCallback(() => {
        setIsBackToInhouse(false)
    }, [])

    return (
        <>
            {searchData ? (
                <SearchData data={searchData} handleBack={handleBack} /> // Render the SearchResults component with the provided Imei
            ) : (
                <div style={{ height: '60vh', display: 'flex', flexDirection: 'column' }}>
                    <Row className='justify-end'>
                        <Button onClick={handleOpenBackToInhouse} backgroundColor="lgblue">
                            <Text colorV="white" variant="title" weightV="normal">
                                Add unit back to in house
                            </Text>
                        </Button>
                    </Row>
                    <Flex
                        inlineStyle={{
                            flex: 1,
                            justifyContent: 'center', // Center items horizontally
                            alignItems: 'center', // Center items vertically
                        }}
                    >
                        <PrimaryCard className="p-5 justify-center items-center text-center">
                            <Column className="gap-y-5">
                                <Text colorV="blue" weightV="bold" variant="heading">
                                    Input IMEI
                                </Text>
                                <TextField
                                    value={Imei}
                                    onChange={handleImeiChange}
                                    placeholder="IMEI"
                                    width="300px"
                                    backgroundColor='white'
                                    onKeyPress={handleKeyPress}
                                    autoFocus
                                />
                            </Column>
                            <RowAlignCenter className="gap-x-4 mt-5">
                                <Button onClick={handleSearch} backgroundColor="lgblue">
                                    <Text colorV="white" variant="title" weightV="normal">
                                        Search
                                    </Text>
                                </Button>
                            </RowAlignCenter>
                        </PrimaryCard>
                    </Flex>
                    {isBackToInhouse && <BackToInhouse onClose={onClose} />}
                </div>

            )}
        </>
    );
};
