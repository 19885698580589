import { motion } from 'framer-motion'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import asc from '../assets/asc.png'
import des from '../assets/desc.png'
import edit1 from '../assets/edit1.png'
import TopHeader from '../components/TopHeader'

import { useFetch } from '../hooks/useFetch'
import { useAuth } from '../utils/auth'

const baseUrl = process.env.REACT_APP_baseUrl

type SortOrder = 'asc' | 'des'

export default function Sheets() {
    const [sortedData, setSortedData] = useState([])
    const [sheet, setSheet] = useState<any>([])
    const [sortKey, setSortKey] = useState('')
    const [sortOrder, setSortOrder] = useState<SortOrder>('des')

    const navigate = useNavigate()
    const auth = useAuth()

    const isRma = auth.user.role?.includes('rma')
    const isAdmin = auth.user.role?.includes('admin')

    const { getRequest, data } = useFetch(`${baseUrl}/view/sheets`)
    useEffect(() => {
        getRequest()
    }, [getRequest])

    useEffect(() => {
        setSheet(data)
        setSortedData(data)
    }, [data])

    const headers = [
        { key: 'Company', isSortable: true, label: 'Company' },
        { key: 'Account_Manager', isSortable: true, label: 'Account Manager' },
        { key: 'Customer_Name', isSortable: true, label: 'Customer Name' },
        { key: 'RMA_Number', isSortable: true, label: 'RMA Number' },
        { key: 'Submitted_At', isSortable: true, label: 'Submitted At' },
        { key: 'Status', isSortable: true, label: 'Status' },
        (isRma || isAdmin) && {
            key: 'Action',
            isSortable: false,
            label: 'Action',
        },
    ]

    const SortButton = useCallback(
        ({
            columnKey,
            onClick,
            label,
            isSortable,
        }: {
            isSortable: boolean
            label: string
            columnKey: any
            onClick: any
        }) => {
            let src = des
            if (columnKey === sortKey) {
                src = sortOrder === 'asc' ? asc : des
            }
            return (
                <th
                    className="hover:cursor-pointer"
                    onClick={isSortable ? onClick : null}
                    key={columnKey}
                >
                    {label}
                    {isSortable && (
                        <button>
                            <img
                                src={src}
                                className="ml-2 w-3 h-2 mx-auto max-w-sm"
                                alt="edit"
                            />
                        </button>
                    )}
                </th>
            )
        },
        [sortKey, sortOrder]
    )

    const sortData = useCallback(
        ({ sheet, sortKey }) => {
            if (!sortKey) return sheet
            if (sortOrder === 'des') {
                const sortedData = sheet?.sort((a, b) => {
                    return b[sortKey] < a[sortKey] ? 1 : -1
                })
                setSortedData([...sortedData])
            } else {
                const sortedData = sheet?.sort((a, b) => {
                    return a[sortKey] < b[sortKey] ? 1 : -1
                })
                setSortedData([...sortedData])
            }
        },
        [sortOrder]
    )

    const openSheetDetails: any = (id: any, currency: any) => {
        localStorage.setItem('sheetId', id)
        localStorage.setItem('currency', currency)
        navigate(`/detail/${id}`)
    }

    useEffect(() => {
        if (sortKey && sheet && sortOrder) {
            sortData({ sheet, sortKey })
        }
    }, [sheet, sortData, sortKey, sortOrder])

    const handleEditStatus = (e: any, id: any, status: any) => {
        localStorage.setItem('status', status)
        e.stopPropagation()
        if (isRma || isAdmin) navigate(`/status/edit/${id}`)
    }

    const changeSort = useCallback(
        (key) => {
            setSortKey(key)
            setSortOrder(sortOrder === 'des' ? 'asc' : 'des')
        },
        [sortOrder]
    )
    return (
        <>
            <TopHeader />
            <div className="flex items-center m-3 p-2 flex-col">
                <div className="">
                    <div className="flex mx-2 mb-2">
                        <h1 className="font-bold text-xl ">All Sheets</h1>
                    </div>
                    <div className="py-2 px-2 inline-block min-w-ful mt-4">
                        <table className="table-fixed text-center">
                            <thead className="bg-b9green2 border-b h-14">
                                <tr className="text-md  font-bold">
                                    {headers?.map((row) => {
                                        return (
                                            <SortButton
                                                key={row.key}
                                                label={row.label}
                                                columnKey={row.key}
                                                isSortable={row.isSortable}
                                                onClick={() =>
                                                    changeSort(row.key)
                                                }
                                                {...{
                                                    sortOrder,
                                                    sortKey,
                                                }}
                                            />
                                        )
                                    })}
                                </tr>
                            </thead>
                            {sortedData?.map((shet: any) => (
                                <tbody
                                    key={shet._id}
                                    className=" hover:cursor-pointer"
                                >
                                    <tr
                                        onClick={() =>
                                            openSheetDetails(
                                                shet._id,
                                                shet?.user?.currency
                                            )
                                        }
                                        className="bg-white  border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                    >
                                        <td className="text-md  text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {shet?.user?.companyName}
                                        </td>
                                        <td className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap ">
                                            {shet?.user?.accountManagerEmail}
                                        </td>
                                        <td className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {shet?.user?.firstName}{' '}
                                            {shet?.user?.lastName}
                                        </td>
                                        <td className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {shet.rmaNumber}
                                        </td>

                                        <td className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {moment(shet?.createdAt).format(
                                                'DD/MM/YYYY, H:M'
                                            )}
                                        </td>
                                        <td className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {shet.status}
                                        </td>
                                        {(isRma || isAdmin) && (
                                            <td
                                                onClick={(e) => {
                                                    handleEditStatus(
                                                        e,
                                                        shet._id,
                                                        shet.status
                                                    )
                                                }}
                                                className="text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                                            >
                                                <motion.div
                                                    whileHover={{ scale: 1.2 }}
                                                >
                                                    <img
                                                        src={edit1}
                                                        className="h-6 mx-auto max-w-sm"
                                                        alt="edit"
                                                    />
                                                </motion.div>
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
