import React, { useCallback, useEffect, useState } from 'react';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { TextBackground } from '../../../../../base/TextBackground';
import { Text } from '../../../../../base/Text';
import { Column } from '../../../../../layout/Col';
import { useFetch } from '../../../../../../hooks/useFetch';
import { Row } from '../../../../../layout/Row';
import { useAuth } from '../../../../../../utils/auth';
const baseUrl = process.env.REACT_APP_baseUrl;

export const ReportName = ({ onDropdownChange }: any) => {
    const [selectedOption, setSelectedOption] = useState('');

    const auth = useAuth()
    const { getRequest: getSortingLogicEvents, data: sortingLogicEventsName } = useFetch(`${baseUrl}/sorting-logic/get-sorting-logic-events`);
    const { getRequest: getInventoryItems, data: itemsData } = useFetch(`${baseUrl}/inventory/get-invoice-items`);
    const { getRequest, data } = useFetch(`${baseUrl}/user/data`);
    
    useEffect(() => {
        getRequest();
        getInventoryItems();
        getSortingLogicEvents()
    }, [getRequest, getInventoryItems, getSortingLogicEvents]);

    const handleUserChange = useCallback((event) => {
        const selectedData = {
            type: 'user',
            value: event.target.value,
        };
        onDropdownChange(selectedData);
    }, [onDropdownChange]);

    const handleInventoryChange = useCallback((event) => {
        const selectedData = {
            type: 'inventory',
            value: event.target.value,
        };
        onDropdownChange(selectedData);
    }, [onDropdownChange]);

    const handleBatchChange = useCallback((event) => {
        const selectedData = {
            type: 'batch',
            value: event.target.value,
        };
        onDropdownChange(selectedData);
    }, [onDropdownChange]);

    const handleClick = (type: string) => {
        setSelectedOption(type);

        // Clear other select values
        const dropdowns = ['user', 'inventory', 'batch'];
        dropdowns.forEach((dropdownType) => {
            if (dropdownType !== type) {
                const otherSelect = document.getElementById(dropdownType) as HTMLSelectElement;
                if (otherSelect) {
                    otherSelect.value = '';
                }
            }
        });

        const selectedValue = (document.getElementById(type) as HTMLSelectElement)?.value;
        onDropdownChange({
            type: type,
            value: selectedValue,
        });
    };

    const uniqueInvoiceNumbers = [...new Set((itemsData || []).map((item) => item?.invoiceNumber))];
    return (
        <>
            <Column className='gap-y-5 w-full'>
                <div className='w-full'>
                    <TextBackground
                        backgroundColor={selectedOption === 'user' ? 'b9blue' : 'b9white'}
                        height='70px'
                        className='w-full group hover:bg-b9blue cursor-pointer'
                        onClick={() => handleClick('user')}
                    >
                        <RowAlignCenterSpaced className='w-full gap-x-16' inlineStyle={{ flex: 1 }}>
                            <Text
                                colorV={selectedOption === 'user' ? 'white' : 'blue'}
                                weightV='normal'
                                variant='title'
                                className='group-hover:text-white'
                                style={{ width: '200px' }}
                            >
                                Personal performance
                            </Text>
                            <div className=''>
                                <select
                                    id='user'
                                    style={{ width: '14rem' }}
                                    onChange={handleUserChange}
                                    className='border hover:cursor-pointer bg-white  rounded-lg  p-2 px-1 border-none'
                                >
                                    <option value=''>Select</option>
                                    {data?.filter((user) => user?.role.includes('admin')).map((adminUser) => (
                                        <option key={adminUser?._id} value={adminUser?._id}>
                                            {adminUser?.email}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                    </TextBackground>
                </div>
                <div className='w-full'>
                    <TextBackground
                        backgroundColor={selectedOption === 'inventory' ? 'b9blue' : 'b9white'}
                        height='70px'
                        className='w-full group hover:bg-b9blue cursor-pointer'
                        onClick={() => handleClick('inventory')}
                    >
                        <RowAlignCenterSpaced className='w-full gap-x-16' inlineStyle={{ flex: 1 }}>
                            <Text
                                colorV={selectedOption === 'inventory' ? 'white' : 'blue'}
                                weightV='normal'
                                variant='title'
                                className='group-hover:text-white'
                                style={{ width: '200px' }}
                            >
                                Inventory report
                            </Text>
                            <div className=''>
                                <select
                                    id='inventory'
                                    style={{ width: '14rem' }}
                                    onChange={handleInventoryChange}
                                    className='border hover:cursor-pointer bg-white  rounded-lg  p-2 px-1 border-none'

                                >
                                    <option value=''>Select</option>
                                    {sortingLogicEventsName?.sortingLogicEvents?.map((eventType: any) => (
                                        <option
                                            key={eventType._id}
                                            value={eventType._id}
                                            selected={eventType.name === selectedOption}
                                        >
                                            {eventType.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                    </TextBackground>
                </div>

                <div className='w-full'>
                    <TextBackground
                        backgroundColor={selectedOption === 'batch' ? 'b9blue' : 'b9white'}
                        height='70px'
                        className='w-full group hover:bg-b9blue cursor-pointer'
                        onClick={() => handleClick('batch')}
                    >
                        <RowAlignCenterSpaced className='w-full gap-x-16' inlineStyle={{ flex: 1 }}>
                            <Text
                                colorV={selectedOption === 'batch' ? 'white' : 'blue'}
                                weightV='normal'
                                variant='title'
                                className='group-hover:text-white'
                                style={{ width: '200px' }}
                            >
                                Batch report
                            </Text>
                            <div className=''>
                                <select
                                    id='batch'
                                    style={{ width: '14rem' }}
                                    onChange={handleBatchChange}
                                    className='border hover:cursor-pointer bg-white  rounded-lg  p-2 px-1 border-none'

                                >
                                    <option value=''>Select</option>
                                    {uniqueInvoiceNumbers.map((invoiceNumber: any) => (
                                        <option key={invoiceNumber} value={invoiceNumber}>
                                            {invoiceNumber}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                    </TextBackground>
                </div>
                {/* <TextBackground
                    backgroundColor={selectedOption === 'sparePart' ? 'b9blue' : 'b9white'}
                    height='70px'
                    className='w-full group hover:bg-b9blue cursor-pointer'
                    onClick={() => handleClick('sparePart')}
                >
                    <Row className='w-full ' inlineStyle={{ flex: 1 }}>
                        <Text
                            colorV={selectedOption === 'sparePart' ? 'white' : 'blue'}
                            weightV='normal'
                            variant='title'
                            className={`group-hover:text-white w-full ${selectedOption === 'sparePart' ? 'text-white' : ''}`}
                        >
                            Spare part report
                        </Text>
                    </Row>
                </TextBackground> */}
            </Column>
        </>
    );
};
