import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../hooks/useFetch'
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
const baseUrl = process.env.REACT_APP_baseUrl

export default function SummaryDetail() {
    const { id } = useParams()
    const [invoice, setInvoice] = useState<any>()
    const { getRequest: getSheetItems, data: sheetItemsData } = useFetch(
        `${baseUrl}/invoice/invoicedetail/${id}`
    )

    useEffect(() => {
        getSheetItems()
    }, [getSheetItems])

    useEffect(() => {
        setInvoice(sheetItemsData)
    }, [sheetItemsData])

    return (
        <>
            <div>
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="py-2 inline-block mt-2 mx-6">
                            <h1>Summary</h1>
                            <table className="table-fixed mt-3 text-center">
                                <thead className="bg-b9green2 border-b h-14">
                                    <tr className="text-md font-bold">
                                        <th
                                            style={{
                                                width: 75,
                                            }}
                                            className="px-1"
                                        >
                                            Imei Number
                                        </th>

                                        <th
                                            style={{
                                                width: 150,
                                            }}
                                            className="px-1"
                                        >
                                            Unit Price
                                        </th>
                                    </tr>
                                </thead>
                                {invoice?.items?.map((itm: any) => (
                                    <tbody
                                        key={itm._id}
                                        className={'hover:pointer'}
                                    >
                                        <tr
                                            //   onClick={() =>
                                            //     openDetail(itm, itm._id, itm.model, itm.vat)
                                            //   }
                                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                        >
                                            <td className="text-md w-14 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                {itm?.unitPrice}
                                            </td>
                                            <td className="text-md w-14 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                {itm?.unitPrice}
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
