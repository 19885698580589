import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '../../../layout/Flex';
import { Table } from '../../../shared/Table';
import { PrimaryCard } from '../../Page/PrimaryCard';
import { useFetch } from '../../../../hooks/useFetch';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx'; // Import XLSX library

export default function PurchaseOrderTable({ purchaseData }: any) {
  const [extractedData, setExtractedData] = useState([]);
  const currentDate = moment().format('DDMMYYYY'); 

  const navigate = useNavigate()

  useEffect(() => {
    if (purchaseData) {
      const updatedData = purchaseData.map((item) => {
        let startDate = item?.createdAt ? moment(item.createdAt).add(1, 'day') : null;

        if (startDate && startDate.day() === 5) { 
          startDate = startDate.add(2, 'days'); 
        } else if (startDate && startDate.day() === 6) { 
          startDate = startDate.add(1, 'day'); 
        }

        const endDate = startDate ? moment(startDate).add(item?.assignMyRmaDuration, 'days') : null;

        const remainingDays = endDate ? endDate.diff(moment(), 'days') : null;

        const remainingDaysString =
          remainingDays > 0
            ? `${remainingDays} day${remainingDays !== 1 ? 's' : ''} left`
            : remainingDays === 0
              ? 'Today is the last day'
              : 'The deadline has passed';

        const updatedItem = {
          Id: item?._id,
          SON: item?.saleBatchNumber || '',
          Customer: item?.customer?.companyName,
          'Date the order is sent': startDate ? startDate.format('DD.MM.YYYY') : '',
          RMA: remainingDaysString,
          'Total value': item?.totalValue,
          'Type of sale': item?.saleType,
          item: item
        };

        return updatedItem;
      });

      setExtractedData(updatedData);
    } else {
      setExtractedData([]);
    }
  }, [purchaseData, setExtractedData]);

  const columns = ['SON', 'Customer', 'Date the order is sent', 'RMA', 'Total value', 'Type of sale'];

  const handleRowClick = (item: any) => {
    navigate(`/sales/sales-order/sale-order-items/${item.Id}`)
  }

  const handleSelectChange = (optionValue, item) => {
    handleImeiListDownload(item)
  }

  const handleImeiListDownload = useCallback(async (selectedItem: any) => {
    const csvArray = [];
    
    if (selectedItem?.item) {
      selectedItem?.item?.items?.forEach((element) => {
        csvArray.push({
          Imei: element?.imei,
          Model: element?.model?.modelName || "",
          Storage: element?.storage || "",
          Grade: element?.grade?.grade || ""
        });
      });

      const worksheet = XLSX.utils.json_to_sheet(csvArray);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'IMEI List');

      const buffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const data = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveBlob(data, `${selectedItem?.SON}.xlsx`);
    } else {
      toast.error('Error: No data found', {
        hideProgressBar: true,
      });
    }
  }, []);

  function saveBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    document.body.removeChild(a);
  }

  return (
    <>
      <Flex className="mt-5 w-full">
        <PrimaryCard className=" w-full">
          <Table
            options={['Export']}
            clickable={true}
            handleDropdownChange={handleSelectChange}
            handleRowClick={handleRowClick}
            columns={columns}
            data={extractedData}
            showActions={true} />
        </PrimaryCard>
      </Flex>
    </>
  );
}
