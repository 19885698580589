import React from 'react';

interface AvatarProps {
    src?: string;
    alt?: string;
    name?: string;
    size?: number;
    borderRadius?: string;
    onClick?: () => void;
}

export const Avatar: React.FC<AvatarProps> = ({
    src,
    alt,
    name,
    size = 50,
    borderRadius = '50%',
    onClick,
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const commonAvatarStyles = {
        width: size + 'px',
        height: size + 'px',
        borderRadius: borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        cursor: onClick ? 'pointer' : 'auto', // Set cursor to pointer if onClick is provided
    };

    if (src) {
        return (
            <img
                src={src}
                alt={alt || ''}
                style={{
                    ...commonAvatarStyles,
                    objectFit: 'cover',
                }}
                className={` aspect-square`}
                onClick={handleClick}
            />
        );
    }

    if (name) {
        const initials = name
            .split(' ')
            .map((part) => part[0])
            .join('')
            .toUpperCase();

        return (
            <div
                style={{
                    ...commonAvatarStyles,
                    backgroundColor: 'lightgray',
                    fontSize: size / 2 + 'px',
                }}
                className='aspect-square'
                onClick={handleClick}
            >
                {initials}
            </div>
        );
    }

    return null;
};
