import React, { useEffect } from 'react'
import { useAuth } from '../utils/auth'
import { useNavigate } from 'react-router-dom'

export const Logout = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    useEffect(() => {
        auth?.logout()
        navigate('/')
    }, [auth, navigate])

    return <></>
}
