import React, { useState, CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { PrimaryCard } from '../../components/compound/Page/PrimaryCard'
import Logo from '../../assets/logo.png'
import { Text } from '../../components/base/Text'
import { MyIcon } from '../../components/base/MyIcon'
import { Flex } from '../../components/layout/Flex'
import { useLocation } from 'react-router-dom'

interface NavItem {
    text: string
    icon: string
    iconSize?: number
    to: string
    subNavigation?: React.ReactNode
}

interface NavbarProps {
    initialNavItems: NavItem[]
    showLogo?: boolean
    heading?: string
    className?: string
}

export const ProductNavbar: React.FC<NavbarProps> = ({
    initialNavItems,
    showLogo = true,
    heading,
    className,
}) => {
    const [navItems] = useState<NavItem[]>(initialNavItems)
    const location = useLocation()
    const currentPath = location.pathname

    const logoStyle: CSSProperties = {
        fontSize: '24px',
        marginBottom: '20px',
    }

    const navItemsStyle: CSSProperties = {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        marginTop: '15px',
    }

    const navItemStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        fontSize: '18px',
        cursor: 'pointer',
        color: 'white',
        borderLeft: '2px solid #f7f7f9',
    }

    const navIconStyle: CSSProperties = {
        margin: '0 15px 0 7px',
    }

    const activeItemStyle: CSSProperties = {
        borderLeft: '2px solid #3B65F6',
        transition: 'border 0.3s ease-in-out',
    }

    return (
        <div>
            <PrimaryCard
                style={{ width: 260, boxSizing: 'border-box' }}
                className={`px-3 ${className} shadow-none rounded`}
            >
                {showLogo && (
                    <div style={logoStyle} className="logo">
                        <img alt="" className="h-16 w-28" src={Logo} />
                    </div>
                )}

                {heading && (
                    <div className="mb-5">
                        <Text colorV="blue" variant="heading" weightV="bold">
                            {heading}
                        </Text>
                    </div>
                )}
                <ul style={navItemsStyle} className="nav-items">
                    {navItems.map((item: NavItem, index: number) => {
                        // Get the first segment of the path after the first "/"
                        const firstSegmentOfTo = item.to.split('/')[1]

                        return (
                            <li
                                key={index}
                                style={{
                                    ...navItemStyle,
                                    color: 'white',
                                    ...(currentPath.includes(firstSegmentOfTo)
                                        ? activeItemStyle
                                        : {}),
                                }}
                                className="nav-item"
                            >
                                <Link
                                    to={item.to}
                                    style={{
                                        textDecoration: 'none',
                                        color: currentPath.includes(
                                            firstSegmentOfTo
                                        )
                                            ? 'lgblue'
                                            : 'blue', // Change the active color here
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Flex>
                                        <span
                                            style={{
                                                ...navIconStyle,
                                                color: currentPath.includes(
                                                    firstSegmentOfTo
                                                )
                                                    ? 'lgblue'
                                                    : 'white',
                                            }}
                                            className="nav-icon"
                                        >
                                            <MyIcon
                                                size={item.iconSize}
                                                name={item?.icon}
                                            />
                                        </span>
                                        <Text
                                            colorV={
                                                currentPath.includes(
                                                    firstSegmentOfTo
                                                )
                                                    ? 'mid-blue'
                                                    : 'blue'
                                            }
                                            variant="title"
                                        >
                                            {item.text}
                                        </Text>
                                    </Flex>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </PrimaryCard>
        </div>
    )
}
