import React, { ReactNode, useEffect, useState } from 'react';
import { Column } from '../../components/layout/Col';
import { Navbar } from '../Navbars/Navbar';
import { useNavigation } from './BaseLayout';
import { Row } from '../../components/layout/Row'
import { SubNavbar } from '../Navbars/SubNavbar'
import { BelowNavbar } from '../Navbars/BelowNavbar'
import Logo2 from '../../assets/logo2.png'

interface MainLayoutProps {
    children: ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { initialNavItems, belowNavItems } = useNavigation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (initialNavItems) {
            setLoading(false);
        }
    }, [initialNavItems]);

    if (loading) {
        return <div>Loading...</div>; 
    }

    return (
        <div className="flex flex-row relative">
                <Column className="gap-y-1 p-5">
                    <div className="logo">
                        <img alt="" className="h-14 w-28" src={Logo2} />
                    </div>
                    <Row className="flex flex-col">
                        <Navbar initialNavItems={initialNavItems} showLogo={false} className='flex-1'/>
                    </Row>
                    <BelowNavbar
                        className="mt-3"
                        initialNavItems={belowNavItems}
                    />
                </Column>
            <div className='flex-1 hide-scrollbar p-5'>
                {children}
            </div>
        </div>
    );
};
