import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryCard } from '../../../compound/Page/PrimaryCard'
import { Flex } from '../../../layout/Flex'
import { Table } from '../../../shared/Table'
import { useFetch } from '../../../../hooks/useFetch'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const baseUrl = process.env.REACT_APP_baseUrl

export default function CustomerCreditNoteTable() {
    const [selectedItem, setSelectedItem] = useState<any>()
    const navigate = useNavigate()

    const { getRequest: getCreditNotes, data } = useFetch(
        `${baseUrl}/creditnote/get-credit-notes-customer`
    )
console.log({data});
    useEffect(() => {
        getCreditNotes()
    }, [getCreditNotes])
    const columns = [
        'Credit Note N',
        'Issued',
        'Total value',
        'Issued for RMA',
        'Status',
    ]
    let extractedData = []
    if (data) {
        extractedData = data?.map((item) => {
            return {
                id: item?._id,
                'Credit Note N': item?.creditNumber,
                Issued: moment(item?.createdAt).format('DD/MM/YY'),
                'Total value': item?.creditValue,
                'Issued for RMA': item?.issuedFor?.rmaNumber,
                Status: item?.status || 'field?',
                path: item?.filePath,
                item: item
            }
        })
    }
    const handleSelectChange = (optionValue, item) => {
        setSelectedItem(item);
        if (optionValue === 'Download PDF') {
            handlePdfDownload();
        } else if (optionValue === 'Open RMA report') {
            handleRmaReport(item);
        }
    }

    const handlePdfDownload = useCallback(() => {
        try {
            const blobUrl = selectedItem?.path;
            const link = document.createElement('a');
            link.href = blobUrl;

            // Specify the desired filename here
            link.download = 'custom-filename.xlsx';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }, [selectedItem]);

    const handleRmaReport = useCallback((item: any) => {
        navigate(`/rma/detail/${item?.item?.issuedFor?._id}`);

    }, [navigate])
    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        options={['Download PDF', 'Open RMA report']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
        </>
    )
}
