import React from 'react'
// import { TotalInsights } from './components/TotalInsights'
import { Column } from '../../../../layout/Col'
import { GraphInsights } from './components/GraphInsights'
import { TotalInsights } from './components/TotalInsights'

export const Insights = ({data}:any) => {
    console.log({data});
    return (
        <>
            <Column className='w-full'>
                <TotalInsights data={data} />
                <GraphInsights data={data}/>
            </Column>
        </>
    )
}
