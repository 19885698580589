// Flex.tsx
import React, { ReactNode, CSSProperties } from 'react'

interface FlexProps {
    children: ReactNode
    className?: string
    inlineStyle?: CSSProperties
    flexDirection?: 'row' | 'column' // Optional prop to specify flex direction
    justifyContent?: CSSProperties['justifyContent'] // Optional prop to specify justify-content
    alignItems?: CSSProperties['alignItems'] // Optional prop to specify align-items
    flexWrap?: CSSProperties['flexWrap'] // Optional prop to specify flex-wrap
}

export const Flex: React.FC<FlexProps> = ({
    children,
    className,
    inlineStyle,
    flexDirection = 'row',
    justifyContent = 'flex-start',
    alignItems = 'stretch',
    flexWrap = 'nowrap',
}) => {
    const flexContainerStyle: CSSProperties = {
        display: 'flex',
        flexDirection,
        justifyContent,
        alignItems,
        flexWrap,
    }

    return (
        <div
            className={`flex ${className}`}
            style={{ ...flexContainerStyle, ...inlineStyle }}
        >
            {children}
        </div>
    )
}
