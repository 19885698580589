import React from 'react'
import { Row } from '../../../../layout/Row'
import { RepairPointGraph } from './components/RepairPointGraph';
import { Cards } from './components/Cards';
import { Column } from '../../../../layout/Col';
import { RmaPerformance } from './components/RmaPerformance';
import { ValueCard } from '../Insights/components/ValueCard';

export const Focus = ({ data }: any) => {

    return (
        <>
            <Column className='w-full gap-y-4'>
                <Row className='gap-x-3 w-full'>
                    <ValueCard
                        title="Diagnostics completed"
                        currencyIcon="diagnostics"
                        count={data?.
                            diagnosticsCount
                        }
                        change="+ 0.25"
                        percentage="35%"
                        comparedText="Compared to Jan 2022"
                    />
                    <ValueCard
                        title="Repaired devices"
                        currencyIcon="diagnostics"
                        count={data?.repairCount}
                        change="+ 0.25"
                        percentage="35%"
                        comparedText="Compared to Jan 2022"
                    />
                    <ValueCard
                        title="Sold units"
                        currencyIcon="diagnostics"
                        count={data?.
                            soldCount}
                        change="+ 0.25"
                        percentage="35%"
                        comparedText="Compared to Jan 2022"
                    />
                    <ValueCard
                        title="Total devices graded "
                        currencyIcon="diagnostics"
                        count={data?.
                            gradedCount
                        }
                        change="+ 0.25"
                        percentage="35%"
                        comparedText="Compared to Jan 2022"
                    />
                </Row>
                <Row className='w-full gap-x-4'>
                    <Column className='w-1/2 gap-y-3'>
                        <RepairPointGraph data={data} />
                        <RmaPerformance />
                    </Column>
                    <Column className='w-1/2 gap-y-3'>
                        <Row className=' w-full gap-3'>
                            <ValueCard
                                title="Diagnostics queue"
                                currencyIcon="diagnostics"
                                count={data?.
                                    diagnosticsReceivedCount}
                                change="+ 0.25"
                                percentage="35%"
                                comparedText="Compared to Jan 2022"
                            />
                            <ValueCard
                                title="Custom_event 5"
                                currencyIcon="diagnostics"
                                count={data?.repairCount}
                                change="+ 0.25"
                                percentage="35%"
                                comparedText="Compared to Jan 2022"
                            />
                        </Row>
                        <Row className=' w-full gap-3'>

                            <ValueCard
                                title="Polishing queue"
                                currencyIcon="diagnostics"
                                count={data?.
                                    polishReceivedCount
                                }
                                change="+ 0.25"
                                percentage="35%"
                                comparedText="Compared to Jan 2022"
                            />
                            <ValueCard
                                title="Ready for sale, units"
                                currencyIcon="diagnostics"
                                count={data?.
                                    gradedCount
                                }
                                change="+ 0.25"
                                percentage="35%"
                                comparedText="Compared to Jan 2022"
                            />
                        </Row>
                        <Row className=' w-full gap-3'>
                            <ValueCard
                                title="Pending My_RMA units"
                                currencyIcon="diagnostics"
                                count={data?.
                                    myRmaUnitsCount
                                }
                                change="+ 0.25"
                                percentage="35%"
                                comparedText="Compared to Jan 2022"
                            />
                            <ValueCard
                                title="Total value of My_RMA units"
                                currencyIcon="diagnostics"
                                count={data?.repairCount}
                                change="+ 0.25"
                                percentage="35%"
                                comparedText="Compared to Jan 2022"
                            />
                        </Row>

                    </Column>
                </Row>
            </Column>
        </>
    )
}
