import React, { useCallback, useEffect, useState } from 'react';
import { ModalBox } from '../../base/ModelBox';
import { Text } from '../../base/Text';
import { Column } from '../../layout/Col';
import { Avatar } from '../../base/Avatar';
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced';
import { useAuth } from '../../../utils/auth';
import { MyIcon } from '../../base/MyIcon';
import ImageSelector from '../../base/ImageSelector/ImageSelector';
import { toast } from 'react-toastify';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_baseUrl;

export const ProfileDialog: React.FC<{
    onClose: () => void;
    currentUser: any;
    profilePic: any;
    refetchAccount: any
}> = ({ onClose, currentUser, profilePic, refetchAccount }) => {
    const [activeCompany, setActiveCompany] = useState<any>();
    const [isImageSelectorOpen, setIsImageSelectorOpen] = useState(false); // State for modal visibility
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false)
    const auth = useAuth();
    const company = auth.selectedCompany
    const f = new Intl.ListFormat('en-us')
    const token = auth.user?.token?.token
    useEffect(() => {
        const company = localStorage.getItem('activeCompany');
        console.log("Atvice company: " , { company });
        if (company) {
            setActiveCompany(JSON.parse(company));
        }
    }, []);

    // const { getRequest: getProfile, data: profileData } = useFetch(
    //     `${baseUrl}/user/profile/${activeCompany?._id}/${auth?.user?.user?._id}`
    // );

    const handleCompanyChange = useCallback(async(event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCompany = JSON.parse(event.target.value);
        const response = await axios.get( `${baseUrl}/user/profile/${selectedCompany?._id}/${auth?.user?.user?._id}`, {
            headers: {
                Authorization: token,
                Company: company._id
            },
        })
        localStorage.setItem("activeCompany", JSON.stringify(selectedCompany));
        setActiveCompany(selectedCompany);
        // getProfile()
        if (response) {
            console.log({response});
            localStorage.setItem("activeProfile", JSON.stringify(response.data));
            auth.login({ ...auth.user, selectedCompany: [selectedCompany] });
            window.location.reload();
        }
       
    }, [auth, company._id, token]);

    // Function to handle opening the image selector modal
    const handleImageSelectorOpen = () => {
        setIsImageSelectorOpen(true);
    };

    const handleImageUpload = useCallback(async (file: File) => {
        setSelectedImage(file)
        const token = auth.user?.token?.token
        setLoading(true)
        const formData = new FormData();
        if (file) {
            formData.append('file', file); // Corrected to use 'image' instead of 'selectedImage'
        }
        const urlItem = `${baseUrl}/user/edit-company-user/${auth?.user?.user?._id}`

        try {
            const response = await axios.put(urlItem, formData, {
                headers: {
                    Authorization: token as string,
                    'Content-Type': 'multipart/form-data',
                    Company: auth?.selectedCompany?._id
                },
            });
            toast.success('Profile pic update', { hideProgressBar: false });
            setIsImageSelectorOpen(false)
            refetchAccount()
            setLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                hideProgressBar: false,
            });
        }
    }, [auth?.selectedCompany?._id, auth.user?.token?.token, auth.user?.user?._id, refetchAccount]);

    return (
        <>
            <ModalBox
                onClose={onClose}
                top="32%"
                left="87%"
                height="320px"
                width="300px"
                isProfile={true}
            >
                <Column className=" gap-y-3 items-center text-center">
                    <Column className="relative">
                        <div className="absolute cursor-pointer top-0 right-0" onClick={handleImageSelectorOpen}>
                            <MyIcon name="edit" />
                        </div>
                        <Avatar
                            size={90}
                            src={profilePic}
                            name={currentUser?.firstName + " " + currentUser?.lastName}
                        />
                    </Column>
                    <Text colorV="blue" variant="heading" weightV="normal">
                        {currentUser?.lastName} {currentUser?.firstName}
                    </Text>
                    <RowAlignCenterSpaced className='w-full flex flex-col'>
                        <p className='text-sm text-slate-500'>Selected: {activeCompany?.companyName}</p>
                        <div className="w-64">
                            <select
                                onChange={handleCompanyChange}
                                value={activeCompany ? JSON.stringify(activeCompany) : ''}
                                id="code"
                                className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 p-2"
                            >
                                {currentUser?.parent?.map((company: any) => (
                                    <option
                                        key={company?._id}
                                        value={JSON.stringify(company)}
                                    >
                                        {company?.companyName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </RowAlignCenterSpaced>
                </Column>
            </ModalBox>
            {/* Modal for image selector */}
            {isImageSelectorOpen && (
                <ModalBox isProfile={true} onClose={() => setIsImageSelectorOpen(false)}>
                    <ImageSelector loading={loading} width='14rem' onUpload={handleImageUpload} />
                </ModalBox>
            )}
        </>
    );
};
