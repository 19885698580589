import React, { useCallback, useEffect, useState } from 'react'
import { Head } from './Head'
import { CreditNoteTable } from './component/CreditNoteTable'
import { useFetch } from '../../../../hooks/useFetch'
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess'
import { useAuth } from '../../../../utils/auth'
import { Filter } from './component/Filter'
import { Row } from '../../../layout/Row'
const baseUrl = process.env.REACT_APP_baseUrl

export const CreditNotes = () => {
    const [filteredData, setFilteredData] = useState(null); // State to store filtered data

    const auth = useAuth();
    const viewRmaCreditNotesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.RMA && acc.permission.includes(AccessPermissions.View));

    const { getRequest: getCreditNotes, data } = useFetch(
        `${baseUrl}/creditnote/get-credit-notes`
    )

    useEffect(() => {
        getCreditNotes()
    }, [getCreditNotes])

    const refetchData = () => {
        getCreditNotes()
    };
    const handleFilterChange = useCallback((data) => {
        setFilteredData(data);
    }, []);
    console.log({filteredData});
    return (
        <>
            <Row className='gap-x-3 items-center justify-center pb-5 pt-2'>
                <Head refetchData={refetchData} />
                <Filter refetch={refetchData} onDataChange={handleFilterChange} itemsData={data} />
            </Row>
            {viewRmaCreditNotesCheck && <CreditNoteTable refetchData={refetchData} data={ filteredData?.length > 0 ? filteredData : data} />}
        </>
    )
}
