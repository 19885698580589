import React from 'react'
import Head from './Head'
import RmaTable from './component/ArchiveTable'

export const ArchiveRma = () => {
    return (
        <>
            <Head />
            <RmaTable />
        </>
    )
}
