import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

interface AtlasChartProps {
    labels: string[];
    data: number[];
    chartType?: 'line' | 'bar' | 'pie' | 'doughnut';
    color?: string
    gColor?: string
}

export const AtlasChart: React.FC<AtlasChartProps> = ({
    labels,
    data,
    chartType,
    color = '#3B65F680',
    gColor = 'rgba(255, 255, 255, 0)',
}) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const hexToRgb = (hex: string): string => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };
    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        // Calculate the canvas width based on the parent container
        const parent = chartRef.current.parentElement;
        if (parent) {
            const parentWidth = parent.clientWidth;
            chartRef.current.width = parentWidth;
            chartRef.current.height = parentWidth * 0.6; // You can adjust the height as needed
        }

        // Define an array of colorful background colors
        const gradient = ctx.createLinearGradient(0, 0, 0, chartRef.current.height);
        gradient?.addColorStop(0, `${color}`); // Start color
        gradient?.addColorStop(1, `${gColor}`);

        chartInstanceRef.current = new Chart(ctx, {
            type: chartType,
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        fill: {
                            target: 'origin',
                            above: gradient,  // Area will be red above the origin
                          },
                        borderColor: color, // Customize line color
                        borderWidth: 1,
                        pointRadius: 1, // Remove dots
                        hoverOffset: 4, // Increase the hover offset for better visibility
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    x: {
                        
                        grid: {
                            display: false,
                        },
                        ticks: {
                            display: false, // Hide x-axis labels
                        },
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            display: false, // Hide x-axis labels
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    
                },
            },
        });
    }, [labels, data, chartType, color, gColor]);

    return (
        <div className='w-full'>
            <canvas ref={chartRef} className='w-full max-h-40'></canvas>
        </div>
    );
};

