import React, { useEffect } from 'react'
import { Head } from './Head'
import { UsersTable } from './UsersTable'
import moment from 'moment';
import { useFetch } from '../../../../hooks/useFetch';
const baseUrl = process.env.REACT_APP_baseUrl;

export const Users = () => {
  const { getRequest, data } = useFetch(`${baseUrl}/user/companies-data`);

  useEffect(() => {
    getRequest();
  }, [getRequest]);
  let extractedData = [];
  if (data) {
    console.log(data);
    extractedData = data?.map((item) => ({
      id: item?._id,
      Email: item?.email,
      Company: item?.parent[0]?.companyName,
      'Contact person': (
        <span>
          {item?.firstName} {item?.lastName}{' '}
        </span>
      ),
      Status: item?.parent[0]?.status,
      Manager: item?.accountManager,
      'Subscription type': item?.parent[0]?.subscriptionType,
      'Next invoice': moment(item?.parent[0]?.nextInvoiceDate).format('DD MMM YYYY'),
      'Contract ending': moment(item?.parent[0]?.contractEndingDate).format('DD MMM YYYY'),
    }));
  }
  const refetchData = () => {
    getRequest()
  }
  return (
    <>
      <Head data={data} refetchData={refetchData} />
      <UsersTable extractedData={extractedData} refetchData={refetchData} />
    </>
  )
}
