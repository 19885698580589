import React from 'react'
import { CompleteDiagnostics } from './components/CompleteDiagnostics'
import { Column } from '../../../../layout/Col'

export const Complete = () => {
    return (
        <>
            <CompleteDiagnostics />
        </>
    )
}
