export interface DownloadFile {
    fileName: string;
    file: Blob;
    type: DownloadFileType;
}

export type DownloadFileType = 'csv' | 'pdf' | 'xlsx';
type Template = 'bulkSale' | 'bulkGrading' | 'bulkUpload' | 'bulkStockTransfer';

export function downloadFile(file: DownloadFile) {
    const url = URL.createObjectURL(file.file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

export async function downloadTemplate(template: Template) {
    try {
        let fileUrl = '';
        if (template === 'bulkSale') {
            fileUrl = '/templates/bulk_sale.xlsx'; // Adjust the file path and extension as needed
        } else if (template === 'bulkGrading') {
            fileUrl = '/templates/bulk_grading.xlsx'; // Adjust the file path and extension as needed
        }else if (template === 'bulkUpload') {
            fileUrl = '/templates/bulk_upload.xlsx'; // Adjust the file path and extension as needed
        }

        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const fileToDownload: DownloadFile = {
            fileName: fileUrl.split('/').pop() || 'downloaded_file', // Extract the file name from the URL
            file: blob,
            type: 'xlsx' // Adjust the file type as needed
        };
        downloadFile(fileToDownload);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
}