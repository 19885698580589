import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import edit1 from '../assets/edit1.png'
import { Button } from '../components/base/Button'
import { Text } from '../components/base/Text'
import { useFetch } from '../hooks/useFetch'
import { useAuth } from '../utils/auth'

const baseUrl = process.env.REACT_APP_baseUrl

export default function Users() {
    const [users, setUsers] = useState<any>()
    const navigate = useNavigate()
    const auth = useAuth()
    const isAdmin = auth?.user?.role.includes('admin')

    const { getRequest, data } = useFetch(`${baseUrl}/user/data`)

    useEffect(() => {
        getRequest()
    }, [getRequest])

    useEffect(() => {
        setUsers(data)
    }, [data])

    const openSheetDetails: any = () => {
        navigate(`/users`)
    }

    const handleUserEdit = (e: any, id: any) => {
        e.stopPropagation()
        if (isAdmin) navigate(`/user/edit/${id}`)
    }

    const handleCreateUser = () => {
        navigate('/createUser')
    }

    return (
        <>
            <div className="">
                <div className=" flex justify-between mt-6">
                    <h1 className="font-bold text-xl px-3 ">Users</h1>
                    <Text colorV="green" variant="caption" weightV="bold">
                        This is a Heading
                    </Text>
                    <Button
                        backgroundColor="blue"
                        onClick={() => alert('Button clicked')}
                    >
                        Click Me
                    </Button>
                    <Button backgroundColor="green">Another Button</Button>
                    <button
                        onClick={handleCreateUser}
                        className="relative  mr-10 justify-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        Create User
                    </button>
                </div>
                <div className="py-2 inline-block mt-6">
                    <table className="table-fixed text-center">
                        <thead className="bg-b9green2 border-b h-14">
                            <tr className="text-md font-bold">
                                <th
                                    style={{
                                        width: 125,
                                    }}
                                    className="px-1"
                                >
                                    First Name
                                </th>
                                <th
                                    style={{
                                        width: 100,
                                    }}
                                    className="px-1"
                                >
                                    Last Name
                                </th>
                                <th
                                    style={{
                                        width: 270,
                                    }}
                                    className="px-1"
                                >
                                    Email
                                </th>
                                <th
                                    style={{
                                        width: 80,
                                    }}
                                    className="px-1"
                                >
                                    Currency
                                </th>
                                <th
                                    style={{
                                        width: 135,
                                    }}
                                    className="px-1"
                                >
                                    Company Name
                                </th>
                                <th
                                    style={{
                                        width: 270,
                                    }}
                                    className="px-1"
                                >
                                    Manager Email
                                </th>
                                <th
                                    style={{
                                        width: 240,
                                    }}
                                    className="px-1"
                                >
                                    Roles
                                </th>
                                <th
                                    style={{
                                        width: 60,
                                    }}
                                    className="px-1"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        {users?.map((user: any) => (
                            <tbody key={user?._id}>
                                <tr
                                    onClick={() => openSheetDetails(user._id)}
                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                >
                                    <td className="text-md text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {user.firstName}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {user.lastName}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {user.email}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {user.currency}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {user.companyName}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-2 py-4 whitespace-nowrap">
                                        {user.accountManagerEmail}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {user.role.toString()}
                                    </td>
                                    <td
                                        className="hover:cursor-pointer text-md text-gray-900 font-light px-2 py-2 whitespace-nowrap"
                                        onClick={(e) => {
                                            handleUserEdit(e, user._id)
                                        }}
                                    >
                                        <img
                                            src={edit1}
                                            className="h-6 mx-auto  max-w-sm"
                                            alt="edit"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </>
    )
}
