/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useNavigate } from 'react-router-dom'
import Logo from '../assets/logo-transparent.png'
import { useAuth } from '../utils/auth'
import { motion } from 'framer-motion'

export default function TopHeader() {
    const navigate = useNavigate()

    const handleUsers = () => {
        navigate(`/users`)
    }

    const handleProfile = () => {
        navigate(`/profile`)
    }

    const uploadFile = () => {
        navigate('/uploadFile')
    }

    const viewSheets = () => {
        navigate('/sheets')
    }

    const downloadFile = () => {
        navigate('/template')
    }

    const handleStock = () => {
        navigate('/stock')
    }

    const handleInventory = () => {
        navigate('/inventory')
    }

    const handleReports = () => {
        navigate('/reports')
    }

    const handleBatchOverview = () => {
        navigate('/item/batch')
    }
    const handleUploadImei = () => {
        navigate('/uploadimei')
    }
    const handleHistory = () => {
        navigate('/history')
    }
    const handleDashboard = () => {
        navigate('/dashboard')
    }
    const handleInvoiceImei = () => {
        navigate('/invoices')
    }

    const auth = useAuth()
    const isAdmin = auth.user.role.includes('admin')
    const isCustomer = auth.user.role.includes('customer')
    const isRma = auth.user.role.includes('rma')
    const isInventory = auth.user.role.includes('inventory')

    return (
        <div className="w-full sticky top-0 z-30 py-3 px-5 bg-b9green3 flex content-between justify-between">
            <div className="flex">
                <div className="mr-7">
                    <a href="/sheets">
                        <img alt="" className="h-9 w-9" src={Logo} />
                    </a>
                </div>
                <div>
                    <h2 className="mt-0 text-left text-3xl font-extrabold text-gray-900">
                        Bridge9 - Silta
                    </h2>
                </div>
            </div>

            <div className="flex-row">
                {(isCustomer || isAdmin) && (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        onClick={downloadFile}
                        className="py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                    >
                        Download Template
                    </motion.button>
                )}
                {(isCustomer || isRma || isAdmin) && (
                    <motion.button
                        onClick={uploadFile}
                        whileHover={{ scale: 1.1 }}
                        className="py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                    >
                        Upload xlsx
                    </motion.button>
                )}
                {(isRma || isCustomer || isAdmin) && (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        onClick={viewSheets}
                        className="py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                    >
                        Sheets
                    </motion.button>
                )}
                <>
                    {isAdmin && (
                        <>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                onClick={handleUsers}
                                className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                            >
                                Users
                            </motion.button>

                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                onClick={handleDashboard}
                                className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                            >
                                Dashboard
                            </motion.button>
                        </>
                    )}
                    {(isInventory || isRma || isAdmin) && (
                        <>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                onClick={handleInventory}
                                className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                            >
                                Inventory
                            </motion.button>
                        </>
                    )}
                </>
                {(isAdmin || isInventory) && (
                    <>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            onClick={handleStock}
                            className="py-2 px-2.5 border  border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                        >
                            Spare Parts
                        </motion.button>
                        <motion.button
                            onClick={handleReports}
                            whileHover={{ scale: 1.1 }}
                            className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                        >
                            Reports
                        </motion.button>
                    </>
                )}
                {(isRma || isInventory || isAdmin) && (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        onClick={handleHistory}
                        className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                    >
                        History
                    </motion.button>
                )}
                {isAdmin && (
                    <>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            onClick={handleBatchOverview}
                            className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                        >
                            Batches
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            onClick={handleUploadImei}
                            className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                        >
                            Upload INV
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            onClick={handleInvoiceImei}
                            className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                        >
                            Invoices
                        </motion.button>
                    </>
                )}
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    onClick={handleProfile}
                    className=" py-2 px-2.5 border border-transparent text-sm font-medium rounded-md text-black hover:text-purple-700 focus:outline-none"
                >
                    Profile
                </motion.button>
            </div>
        </div>
    )
}
