import React, { useState } from 'react';
import { Button } from '../../../base/Button';
import { Flex } from '../../../layout/Flex';
import { RowAlignCenter } from '../../../layout/RowAlignCenter';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../base/Text';
import { AddNewUser } from '../addUser/AddNewUser';

export const Head = ({ refetchData}:any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAddNewUser = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        refetchData()
    };
    return (
        <Flex className="w-full mb-4">
            <RowAlignCenterSpaced className="gap-x-4 w-full">
                <Text colorV="blue" variant="head" weightV="bold">
                    User management
                </Text>
                <RowAlignCenter className="gap-x-4">
                    <Button onClick={handleAddNewUser}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Add new user
                        </Text>
                    </Button>
                </RowAlignCenter>
            </RowAlignCenterSpaced>
            {isModalOpen && (
                <AddNewUser onClose={closeModal} />
            )}
        </Flex>
    );
}
