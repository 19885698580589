import React, { useCallback } from 'react'
import { Button } from '../../base/Button'
import { ModalBox } from '../../base/ModelBox'
import { TextBackground } from '../../base/TextBackground'
import { Column } from '../../layout/Col'
import { Flex } from '../../layout/Flex'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Text } from '../../base/Text'
import moment from 'moment'
import { toast } from 'react-toastify'

export const ModalDetailCustomerPurchase:React.FC<{ data: any,onClose: () => void,item:any  }>  = ({data:itemsData,onClose, item}) => {
    const selectedItem = itemsData?.find((selected) => selected?._id === item.Id)
console.log({selectedItem})
    const handleImeiListDownload = useCallback(async () => {
        const csvArray = []
        if (selectedItem?.items) {
            selectedItem?.items?.forEach((element) => {
                csvArray.push({
                    Imei: element?.imei,
                        Model: element?.model?.modelName || "",
                        Storage: element?.storage || "",
                        Grade: element?.grade?.grade || ""
                })
            })

            let csv = ' '
            for (let row = 0; row < csvArray?.length; row++) {
                const keysAmount = Object.keys(csvArray[row]).length
                let keysCounter = 0
                if (row === 0) {
                    for (const key in csvArray[row]) {
                        csv +=
                            key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                }
                keysCounter = 0
                for (const key in csvArray[row]) {
                    csv +=
                        csvArray[row][key] +
                        (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
                keysCounter = 0
            }
            const data = new Blob([csv], { type: 'text/csv' })
            saveCsv(data,`${selectedItem?.saleBatchNumber}-imei_list.csv`)
            onClose()
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [onClose, selectedItem?.items, selectedItem?.saleBatchNumber])

    function saveCsv(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = filename
        a.click()
    }
    const handleInvoiceDownload = useCallback(() => {
        try {
            console.log({selectedItem});
            const blobUrl = selectedItem?.filePath;
            const link = document.createElement('a');
            link.href = blobUrl;
            
            // Extract filename from the URL
            const fileName = blobUrl.substring(blobUrl.lastIndexOf('/') + 1);
            // Set the extracted filename as the new downloaded filename
            link.setAttribute('download', "myfile");
            
            console.log('Downloading file with filename:', fileName);
            console.log(link);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            onClose()
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }, [onClose, selectedItem]);

  return (
    <ModalBox
    heading='Order details'
    onClose={onClose}
    top="50%"
    height="auto"
    width="500px"
>
    <Column className="mt-3 gap-y-4 h-[515px] overflow-auto">
        <RowAlignCenterSpaced>
            <Text colorV="blue" weightV="bold" variant="body">
                PON
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="body">
                    {selectedItem?.saleBatchNumber}
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced>
            <Text colorV="blue" weightV="bold" variant="body">
                Customer
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="title">
                {selectedItem?.customer?.companyName}  
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced>
            <Text colorV="blue" weightV="bold" variant="body">
                Date the order is sent
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="title">
                {moment(selectedItem?.createdAt).format('DD.MM.YYYY')}
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced>
            <Text colorV="blue" variant="body" weightV="bold">
                Logistic company
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="title">
                    {selectedItem?.logisticCompany.companyName}
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced>
            <Text colorV="blue" variant="body" weightV="bold">
                Tracking number
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="title">
                    {selectedItem?.trackingNumber}
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced>
            <Text colorV="blue" variant="body" weightV="bold">
            Type of sale
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="title">
                    {selectedItem?.saleType}
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced>
            <Text colorV="blue" variant="body" weightV="bold">
            RMA
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="title">
                    {item?.RMA}
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced>
            <Text colorV="blue" variant="body" weightV="bold">
            Total value
            </Text>
            <TextBackground backgroundColor="b9white" className="w-56">
                <Text colorV="blue" weightV="normal" variant="title">
                    {selectedItem?.totalValue}
                </Text>
            </TextBackground>
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced className="mt-4">
            <Button onClick={handleImeiListDownload}  backgroundColor="lgblue">
                <Text colorV="white" variant="title" weightV="normal">
                    Download IMEI list
                </Text>
            </Button>
            <Button onClick={handleInvoiceDownload}  backgroundColor="blue">
                <Text colorV="white" variant="title" weightV="normal">
                    Download invoice
                </Text>
            </Button>
        </RowAlignCenterSpaced>
    </Column>
</ModalBox>
  )
}
