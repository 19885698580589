// RowAlignCenter.tsx
import React, { ReactNode, CSSProperties } from 'react'

interface RowAlignCenterProps {
    children: ReactNode
    className?: string
    inlineStyle?: CSSProperties
}

export const RowAlignCenter: React.FC<RowAlignCenterProps> = ({
    children,
    className,
    inlineStyle,
}) => {
    const centerStyle: CSSProperties = {
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        display: 'flex',
    }

    return (
        <div
            className={`flex ${className}`}
            style={{ ...centerStyle, ...inlineStyle }}
        >
            {children}
        </div>
    )
}
