import React, { ReactNode } from 'react'
import { Navbar } from '../Navbars/Navbar'
import { Pagebg } from '../../components/compound/Page/Pagebg'
import { Column } from '../../components/layout/Col'
import { Flex } from '../../components/layout/Flex'
import { Row } from '../../components/layout/Row'
import { SubNavbar } from '../Navbars/SubNavbar'
import { BelowNavbar } from '../Navbars/BelowNavbar'
import Logo2 from '../../assets/logo2.png'
import { useAuth } from '../../utils/auth'
import { Permissions } from '../../constant/Storage'
import { AccessEnums, AccessPermissions } from '../../constant/UserAccess'
import { useNavigation } from './BaseLayout'

interface ManagementLayoutProps {
    children: ReactNode
}

export const ManagementLayout: React.FC<ManagementLayoutProps> = ({
    children,
}) => {
    const auth = useAuth()
    const { initialNavItems, belowNavItems } = useNavigation();
    const dashboardCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Dashboard && acc.permission.includes(AccessPermissions.Admin)) ;
    const skuCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageSKU && acc.permission.includes(AccessPermissions.Admin)) ;
    const templateCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageTemplates && acc.permission.includes(AccessPermissions.Admin)) ;
    const userCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Users && acc.permission.includes(AccessPermissions.Admin));
    const eventAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageModels && acc.permission.includes(AccessPermissions.Admin)) 
    const modelAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageEvents && acc.permission.includes(AccessPermissions.Admin)) 
    const sortingAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManualSortingLogic && acc.permission.includes(AccessPermissions.Admin)) 
   const repairScoreAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageRepairPoints && acc.permission.includes(AccessPermissions.Admin)) 
   const partnerAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManagePartners && acc.permission.includes(AccessPermissions.Admin))
    
    
    const subNavItems = [
        dashboardCheck && { text: 'Dashboard & reports', to: '/management/dashboards-reports' },
        (eventAccess || modelAccess || sortingAccess || repairScoreAccess || partnerAccess) && {
            text: 'Manage production operations',
            to: '/management/manage-production',
        },
       skuCheck && {
            text: 'Manage SKU ',
            to: '/management/manage-sku',
        },
        userCheck && {
            text: 'Users ',
            to: '/management/users',
        },
        // templateCheck && {
        //     text: 'Manage template ',
        //     to: '/management/manage-template',
        // },
    ].filter(Boolean);
console.log({subNavItems});
    return (
        <div className="bg-base01 flex flex-row">
                <Column className="gap-y-1 p-5">
                    <div className="logo">
                        <img alt="" className="h-14 w-28" src={Logo2} />
                    </div>
                    <Row className="gap-x-2">
                        <Navbar initialNavItems={initialNavItems} />
                        <SubNavbar
                            heading="Management"
                            initialNavItems={subNavItems}
                        />
                    </Row>
                    <BelowNavbar
                        className="mt-3"
                        initialNavItems={belowNavItems}
                    />
                </Column>
            <div className='p-5 flex-1 hide-scrollbar'>
                {children}
            </div>
        </div>
    )
}
