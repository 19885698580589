import React, { useCallback, useEffect, useRef, useState } from 'react'
import { storageList } from '../../../../../constant/Storage'
import { useFetch } from '../../../../../hooks/useFetch'
import { Icon } from '../../../../base/Icon'
import { MyIcon } from '../../../../base/MyIcon'
import { Column } from '../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../base/Text'
import { SearchBar } from '../../../../base/Searchbar'
import { Row } from '../../../../layout/Row'
import Select from 'react-select'

const baseUrl = process.env.REACT_APP_baseUrl

export const Filter: React.FC<any> = ({
    handleSearchResult,
    setSelectedParentOptions,
    setSelectedParentEvents,
    setSelectedParentCodes,
    itemsData,
}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [selectedCodes, setSelectedCodes] = useState<string[]>([])
    const [selectedEvents, setSelectedEvents] = useState<string[]>([])
    const [selectedOptions, setSelectedOptions] = useState<any>()
    const [defaultEvents, setDefaultEvents] = useState<string[]>([])

    const handleButtonClick = () => {
        setShowDropdown(!showDropdown)
    }

    const { getRequest: getEventItems, data: eventsData } = useFetch(
        `${baseUrl}/sorting-logic/get-sorting-logic-events`
    )
    let eventData: any = []
    if (eventsData) {
        eventData = eventsData?.sortingLogicEvents?.map((item) => {
            return { label: item.name, value: item?._id }
        })
    }

    const handleSearch = (searchTerm: string) => {
        handleSearchResult(searchTerm)
    }

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )

    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    )
    const { getRequest: getSummaryData, data: summaryData } = useFetch(
        `${baseUrl}/inventory/summary-data`
    )

    useEffect(() => {
        getModels()
        getCodes()
        getEventItems()
        getSummaryData()
        console.log(itemsData)
    }, [getModels, getCodes, getEventItems, getSummaryData, itemsData])

    const handleLocalOptionSelect = (option: any, dropdownLabel: string) => {
        const updatedOptions = { ...selectedOptions }

        if (option !== '') {
            updatedOptions[dropdownLabel] = option
        } else {
            delete updatedOptions[dropdownLabel]
        }

        setSelectedOptions(updatedOptions)
        setSelectedParentOptions(updatedOptions)
    }

    const handleCodesChange = (e: any) => {
        const selectedCodesValues = Array.isArray(e)
            ? e.map((code) => code?.label)
            : []
        setSelectedCodes(selectedCodesValues)
        setSelectedParentCodes(selectedCodesValues)
    }
    const handleEventsChange = (e: any) => {
        console.log(e)
        const selectedEventValues = Array.isArray(e)
            ? e.map((event) => event?.value)
            : []
        setSelectedEvents(selectedEventValues)
        setSelectedParentEvents(selectedEventValues)
        setDefaultEvents(e)
    }
    const handleCloseIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation()
        setShowDropdown(false)
    }

    return (
        <Row className="gap-x-5">
            <SearchBar onSearch={handleSearch} width="400px" inputRef />
            <div ref={dropdownRef} className="relative w-96">
                <div
                    onClick={handleButtonClick}
                    className={`cursor-pointer w-fit px-4 p-2 rounded-lg ${
                        (selectedOptions &&
                            Object.keys(selectedOptions).length > 0) ||
                        selectedCodes?.length > 0 ||
                        selectedEvents?.length > 0
                            ? 'bg-b9green'
                            : 'bg-b9midblue'
                    }`}
                >
                    <div className="flex items-center gap-x-4">
                        <MyIcon name="filter" color="white" />
                        <Text colorV="white" variant="title" weightV="normal">
                            Filter
                        </Text>
                    </div>
                </div>
                {showDropdown && (
                    <div className="absolute w-full max-w-96 top-[calc(100% + 5px)] left-0 mt-2 w-full rounded shadow-lg z-40">
                        <div className=" bg-white w-full rounded-md pt-1">
                            <div className="flex justify-end pr-1 ">
                                <span
                                    onClick={handleCloseIconClick}
                                    className="cursor-pointer"
                                >
                                    <Icon
                                        name="cross"
                                        color="black"
                                        size={18}
                                    />
                                </span>
                            </div>
                            <Column className="p-4 gap-y-2">
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="body"
                                        weightV="bold"
                                    >
                                        Model
                                    </Text>
                                    <select
                                        value={selectedOptions?.['Model']}
                                        onChange={(e) =>
                                            handleLocalOptionSelect(
                                                e.target.value,
                                                'Model'
                                            )
                                        }
                                        className="w-44 mb-2 py-1"
                                    >
                                        <option value="">Select model</option>
                                        {modelsData &&
                                            modelsData.map((model: any) => (
                                                <option
                                                    key={model?._id}
                                                    value={model?._id}
                                                >
                                                    {model?.modelName}
                                                </option>
                                            ))}
                                    </select>
                                </RowAlignCenterSpaced>
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="body"
                                        weightV="bold"
                                    >
                                        Storage
                                    </Text>
                                    <select
                                        value={selectedOptions?.['Storage']}
                                        onChange={(e) =>
                                            handleLocalOptionSelect(
                                                e.target.value,
                                                'Storage'
                                            )
                                        }
                                        className="w-44 mb-2 py-1"
                                    >
                                        <option value="">Select storage</option>
                                        {storageList.map((list) => (
                                            <option
                                                key={list.label}
                                                value={list?.value}
                                            >
                                                {list?.label}
                                            </option>
                                        ))}
                                    </select>
                                </RowAlignCenterSpaced>
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="body"
                                        weightV="bold"
                                    >
                                        VAT
                                    </Text>
                                    <select
                                        value={selectedOptions?.['Type']}
                                        onChange={(e) =>
                                            handleLocalOptionSelect(
                                                e.target.value,
                                                'Type'
                                            )
                                        }
                                        className="w-44 mb-2 py-1"
                                    >
                                        <option value="">Select vat</option>
                                        <option value="Marginal">
                                            Marginal
                                        </option>
                                        <option value="Reverse">Reverse</option>
                                    </select>
                                </RowAlignCenterSpaced>
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="body"
                                        weightV="bold"
                                    >
                                        Status
                                    </Text>
                                    <div className="w-44">
                                        <div className="w-full">
                                            <Select
                                                defaultValue={defaultEvents?.map(
                                                    (event: any) => ({
                                                        label: event.label,
                                                        value: event?._id,
                                                    })
                                                )}
                                                options={eventData}
                                                onChange={handleEventsChange}
                                                isMulti
                                                placeholder="Select Status"
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        border: 'none',
                                                        borderColor:
                                                            state.isFocused
                                                                ? 'none'
                                                                : 'none',
                                                        backgroundColor:
                                                            '#F2F0ED',
                                                        fontStyle: '16px',
                                                        padding: '0px',
                                                        fontFamily:
                                                            'Inter Tight, sans-serif', // Add font family here
                                                        color: '#112D56',
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </RowAlignCenterSpaced>
                                <RowAlignCenterSpaced>
                                    <Text
                                        colorV="blue"
                                        variant="body"
                                        weightV="bold"
                                    >
                                        Code
                                    </Text>
                                    <div className="w-44">
                                        <div className="w-full">
                                            <Select
                                                defaultValue={selectedCodes?.map(
                                                    (code) => ({
                                                        label: code,
                                                        value: code,
                                                    })
                                                )}
                                                options={codesData?.map(
                                                    (code) => ({
                                                        label: code.label,
                                                        value: code.label,
                                                    })
                                                )}
                                                onChange={handleCodesChange}
                                                isMulti
                                                placeholder="Select code"
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        border: 'none',
                                                        borderColor:
                                                            state.isFocused
                                                                ? 'none'
                                                                : 'none',
                                                        backgroundColor:
                                                            '#F2F0ED',
                                                        fontStyle: '16px',
                                                        padding: '0px',
                                                        fontFamily:
                                                            'Inter Tight, sans-serif', // Add font family here
                                                        color: '#112D56',
                                                        height: '36px',
                                                        minHeight: '36px',
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </RowAlignCenterSpaced>
                            </Column>
                        </div>
                    </div>
                )}
                {showDropdown && (
                    <div
                        className="fixed inset-0 bg-black opacity-30 z-0"
                        onClick={handleButtonClick}
                    ></div>
                )}
            </div>

            <div className="w-full flex justify-end">
                <div className="mb-2 flex">
                    {/* Num of items */}
                    <div className="min-w-52 pr-10 text-sm">
                        <div className="flex justify-between w-full">
                            <span className="text-gray-night-3">
                                Total items in house:
                            </span>
                            <h1 className="font-bold text-blue-500">
                                {JSON.stringify(summaryData?.inHouseNum)}
                            </h1>
                        </div>
                        <div className="flex justify-between w-full">
                            <span className="text-gray-night-3">
                                Filter results:
                            </span>
                            <h1 className="font-bold text-blue-500">
                                {selectedOptions &&
                                Object.keys(selectedOptions).length > 0 ? (
                                    <>
                                        {JSON.stringify(itemsData.items.length)}
                                    </>
                                ) : (
                                    <>{JSON.stringify(summaryData?.itemsNum)}</>
                                )}
                            </h1>
                        </div>
                    </div>

                    {/* Sales data */}
                    <div className="min-w-52 pr-10  text-sm">
                        <div className="flex justify-between w-full">
                            <span className="text-gray-night-3">
                                Total items on sale:
                            </span>
                            <h1 className="font-bold text-blue-500">
                                {JSON.stringify(summaryData?.sales.totalSales)}
                            </h1>
                        </div>

                        <div className="flex justify-between w-full">
                            <span className="text-gray-night-3">
                                Total sales value:
                            </span>
                            <h1 className="font-bold text-blue-500">
                                {'€ '}
                                {JSON.stringify(
                                    summaryData?.sales.totalSalesValue
                                )}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    )
}

// import React, { useState } from 'react';
// import { SearchBar } from '../../../../base/Searchbar';
// import { FilterDropdown } from '../../../../base/ToggleButton'; // Correct import path
// import { Flex } from '../../../../layout/Flex';
// import { Row } from '../../../../layout/Row';

// export default function Filter({ handleSearchResult, onOptionSelect }: any) { // Rename onOptionsChange to onOptionSelect
//     const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});

//     const handleOptionSelectInternal = (options: Record<string, string>) => {
//         setSelectedOptions(options);
//         onOptionSelect(options); // Call onOptionSelect instead of onOptionsChange
//     };

//     const handleSearch = (searchTerm: string) => {
//         handleSearchResult(searchTerm);
//     };

//     return (
//         <Flex className=''>
//             <Row className="gap-x-5">
//                 <SearchBar
//                     onSearch={handleSearch}
//                     width="400px"
//                 />
//                 <FilterDropdown
//                     initialColor="blue"
//                     selectedOptions={selectedOptions}
//                     onOptionSelect={handleOptionSelectInternal} // Pass handleOptionSelectInternal as the callback
//                 />
//             </Row>
//         </Flex>
//     );
// }
