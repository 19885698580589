import React, { useCallback, useEffect, useState } from 'react'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Text } from '../../../../base/Text'
import { Button } from '../../../../base/Button'
import { Column } from '../../../../layout/Col'
import { ModalBox } from '../../../../base/ModelBox'
import { AddCode } from '../DialougeBoxes/Add/AddCode'
import { EditCode } from '../DialougeBoxes/Edit/EditCode' // Import your EditCode component
import { DeleteCode } from '../DialougeBoxes/Delete/DeleteCode' // Import your DeleteCode component
import { useFetch } from '../../../../../hooks/useFetch'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { toast } from 'react-toastify'
import moment from 'moment'
const baseUrl = process.env.REACT_APP_baseUrl

export const Codes = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [selectedCode, setSelectedCode] = useState<any>()

    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    )
    console.log({ codesData });
    useEffect(() => {
        getCodes()
    }, [getCodes])

    const openModal = (option) => {
        setSelectedOption(option)
        if (option === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (option === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedOption(null)
        setIsEdit(false)
        setIsDelete(false)
        getCodes()
    }

    let extractedData = []
    if (codesData) {
        extractedData = codesData?.map((item) => {
            return {
                id: item?._id,
                Code: item?.label,
                Code_Description: item?.description,
            }
        })
    }

    const columns = ['Code', 'Code_Description']

    const handleSelectChange = (optionValue, item) => {
        setSelectedCode(item)
        setSelectedOption(optionValue)
        if (optionValue === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (optionValue === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const renderModalComponent = () => {
        if (isEdit) {
            return <EditCode item={selectedCode} onClose={closeModal} />
        } else if (isDelete) {
            return <DeleteCode item={selectedCode} onClose={closeModal} />
        } else {
            return <AddCode onClose={closeModal} />
        }
    }

    const handleExport = useCallback(() => {
        if (codesData?.length > 0) {
            const body = codesData?.map((item: any) => {
                return [
                    item?.label,
                    item?.description,
                ]
            })
            body.unshift([
                'Code',
                'Code Description',
            ])
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            }
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('Codes')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [codesData])

    return (
        <>
            <Column className='w-full'>
                <RowAlignCenterSpaced className="mb-5 w-full">
                    <Text
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        Manage Codes
                    </Text>
                    <div className='flex flex-row gap-x-3'>
                        <Button
                            backgroundColor='green'
                            onClick={() => handleExport()}
                        >
                            <Text colorV="white" variant="title" weightV="normal">
                                Export
                            </Text>
                        </Button>
                        <Button
                            backgroundColor="lgblue"
                            onClick={() => openModal('Add New Code')}
                        >
                            <Text colorV="white" variant="title" weightV="normal">
                                Add new code
                            </Text>
                        </Button>
                    </div>

                </RowAlignCenterSpaced>
                <PrimaryCard className='w-full'>
                    <Table
                        handleDropdownChange={handleSelectChange}
                        columns={columns}
                        options={['Edit', 'Delete']}
                        data={extractedData}
                        showActions={true}
                    />
                </PrimaryCard>
                {isModalOpen && (
                    renderModalComponent()
                )}
            </Column>
        </>
    )
}
