import React from 'react'
import StocktakingSparepartTable from './component/StocktakingSparepartTable'

export const Spareparts = () => {
  return (
    <>
      <StocktakingSparepartTable />
    </>
  )
}
