import React, { useCallback } from 'react';
import { Button } from '../../../base/Button';
import { Flex } from '../../../layout/Flex';
import { RowAlignCenter } from '../../../layout/RowAlignCenter';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../base/Text';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { Row } from '../../../layout/Row';
import { RowSpaced } from '../../../layout/RowSpaced';

export default function Head({ itemsData }: any) {
    console.log({ itemsData });

    const handleExport = useCallback(async () => {
        if (!itemsData || itemsData.length === 0) {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
            return;
        }

        if (itemsData?.length > 0) {
            const uniqueInvoices = {};
            const uniqueData = itemsData.filter((item) => {
                if (!uniqueInvoices[item?.invoiceNumber]) {
                    uniqueInvoices[item?.invoiceNumber] = true;
                    return true;
                }
                return false;
            });

            const xlsxArray = uniqueData.map((itm) => ({
                "Invoice number": itm?.invoiceNumber || '',
                Supplier: itm?.supplier?.companyName || '',
                Received: moment(itm?.createdAt).format('DD/MM/YYYY') || '',
                RMA: (() => {
                    const remainingDays = moment(itm?.createdAt)
                        .add(itm?.rmaSupplier, 'days')
                        .diff(moment(), 'days');
                    if (remainingDays < 0) {
                        return 'Deadline is over';
                    } else {
                        return remainingDays + ' days left';
                    }
                })(),
            }));

            const ws = XLSX.utils.json_to_sheet(xlsxArray);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Invoices');

            XLSX.writeFile(wb, 'invoices.xlsx');
        }
    }, [itemsData]);

    return (
        <RowSpaced className="pb-2 mr-4 pt-2 w-full justify-between items-center">
            <Text colorV="blue" variant="head" weightV="bold">
                Invoices
            </Text>
            <RowAlignCenter className="gap-x-3">
                <Button onClick={handleExport} backgroundColor="green">
                    <Text colorV="white" variant="title" weightV="normal">
                        Export
                    </Text>
                </Button>
            </RowAlignCenter>
        </RowSpaced>
    );
}
