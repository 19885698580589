import React, { ReactNode } from 'react'

interface BreadcrumbItem {
    label: string
    url?: string
}

interface BreadcrumbProps {
    items: BreadcrumbItem[]
    separator?: ReactNode
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
    items,
    separator = '/',
}) => {
    const renderBreadcrumbItem = (item: BreadcrumbItem, index: number) => {
        return (
            <li className="breadcrumb-item" key={index}>
                {item.url ? (
                    <a href={item.url}>{item.label}</a>
                ) : (
                    <span>{item.label}</span>
                )}
            </li>
        )
    }
    return (
        <nav aria-label="breadcrumb">
            <ol
                className="breadcrumb row"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        {renderBreadcrumbItem(item, index)}
                        {index < items?.length - 1 && (
                            <li
                                className="breadcrumb-separator"
                                key={`separator-${index}`}
                            >
                                {separator}
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ol>
            <style>
                {`
          .breadcrumb-item {
            margin-right: 8px; /* Adjust the margin as needed */
            color: grey;
          }

          .breadcrumb-separator {
            margin-right: 8px; /* Adjust the margin as needed */
            color: grey;

          }
        `}
            </style>
        </nav>
    )
}
