import React, { useState, useEffect, useRef } from 'react';

interface DropdownButtonProps {
  buttonLabel: string;
  items: string[];
  onItemClick?: (item: string, e?: React.MouseEvent) => void;
}

export const DropdownButtons: React.FC<DropdownButtonProps> = ({
  buttonLabel,
  items,
  onItemClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      // Close the dropdown if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <button
        type="button"
        className="text-black bg-transparent focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-transparent"
        onClick={toggleDropdown}
      >
        {buttonLabel}
        <svg
          className={`w-2.5 h-2.5 ml-2.5 transition-transform transform ${isOpen ? 'rotate-180' : ''
            }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="z-50 absolute mt-2 right-0 origin-top-right">
          <ul className="bg-white divide-y divide-gray-100 rounded-lg shadow w-full">
            {items?.map((item, index) => (
              <li key={index}>
                <a
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  fontFamily: 'Inter Tight, sans-serif'
              }}
                  href="#"
                  className="block px-4 py-2 hover-bg-gray-100 dark-hover-bg-gray-600 dark-hover-text-white whitespace-nowrap"
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemClick && onItemClick(item, e);
                    closeDropdown(); // Close the dropdown when an item is clicked
                  }}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

