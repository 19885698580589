import React from 'react'
import { Flex } from '../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../base/Text'
export default function Head() {
    return (
        <Flex className=" w-full">
            <RowAlignCenterSpaced className="gap-x-4  w-full">
                <Text colorV="blue" variant="head" weightV="bold">
                    Credit notes
                </Text>
            </RowAlignCenterSpaced>
        </Flex>
    )
}
