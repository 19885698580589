import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { Button } from '../../../../base/Button'
import Select from 'react-select'
import { useAuth } from '../../../../../utils/auth'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { TextArea } from '../../../../base/Textarea'
import { Attention } from './Attention'
import axios from 'axios'
import { toast } from 'react-toastify'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { useFetch } from '../../../../../hooks/useFetch'
import moment from 'moment'
import { useEdit } from '../../../../../hooks/useEdit'
import { generatePDF } from '../../../../shared/GenerateLabel'
import JsBarcode from 'jsbarcode'
import MultiSelect from '../../../../base/MultiSelect/MultiSelect'
import MultiSelectLabel from '../../../../base/MultiSelectLabel/MultiSelectLabel'
import { TextBackground } from '../../../../base/TextBackground'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl
    ; (pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
export const SearchData = ({ data, handleBack }: any) => {
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const [prevCodes, setPrevCodes] = useState<any[]>([])
    const [codeDescription, setCodeDescription] = useState<any>()
    const [codes, setCodes] = useState<string[]>([])
    const [comment, setComment] = useState<any>('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [sortingLogic, setSortingLogic] = useState<any>()
    const [eventType, setEventType] = useState<any>('')
    const [rules, setRules] = useState<any>()
    const [event, setEvent] = useState<string>('')
    const [manualSortingId, setManualSortingId] = useState<any>('')
    const [rmaDaysLeft, setRmaDaysLeft] = useState<boolean>(false);
    const [itemSupplier, setItemSupplier] = useState<any>()
    const [daysLeft, setDaysLeft] = useState<any>()
    const barCode = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 2000);

        // Clear the timeout when the component unmounts or when the event changes
        return () => clearTimeout(timer);
    }, [event]);
    const company = auth.selectedCompany;
    const { sendEditRequest } = useEdit()
    const extractAndSetRules = useCallback((rulesData) => {
        const manualSortingRules = rulesData?.manualSorting?.map((manualSort) => {
            const rules = manualSort?.rules.map((rule) => {
                if (rule?.models) {
                    for (const model of rule.models) {
                        if (model?._id === data?.model?._id) {
                            return rule?.code;
                        }
                    }
                }
                return null;
            });
            return rules.filter(Boolean);
        });
        const flattenedRules = manualSortingRules.flat();
        setRules(flattenedRules);
    }, [data]);

    const { getRequest: getSupplierRules, data: itemsData } = useFetch(
        `${baseUrl}/sorting-logic/get-supplier-rules`
    )
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);
    useEffect(() => {
        getSupplierRules()
    }, [getSupplierRules])

    const getSortingLogic = useCallback(async () => {
        const token = auth.user?.token?.token
        await axios
            .get(`${baseUrl}/sorting-logic/get-sorting-logic-events`, {
                headers: {
                    Authorization: token,
                    Company: company._id
                },
            })
            .then((data) => {
                setSortingLogic(data?.data)
                extractAndSetRules(data?.data)
                // if (data?.data) {
                //     const eventTypes: any = data?.data.map(item => item.type);
                //     //@ts-ignore
                //     const distinctEventTypes: any = [...new Set(eventTypes)];
                //     setEventTypes(distinctEventTypes);
                // }
            })
            .catch((error) => {
                toast.error(error.response?.data?.message, {
                    hideProgressBar: true,
                })
            })
    }, [auth.user?.token?.token, company._id, extractAndSetRules])

    useEffect(() => {
        getSortingLogic()
        return () => { }
    }, [getSortingLogic])

    useEffect(() => {
        if (data) {
            console.log({ data });
            setCodes(data?.codes)
            // setCodes(data?.codesAfterFix?.length > 0 ? data?.codesAfterFix : data?.codes);
            setEvent(data?.codes?.length <= 0 ? '' : data?.sortingEventKey);

            // setEvent(data?.codes?.length <= 0 || data?.codesAfterFix?.length <= 0 ? '' : data?.sortingEventKey);
            const createdAt = moment(data.createdAt);
            const currentDate = moment();
            const differenceInDays = currentDate.diff(createdAt, 'days');
            const matchingItem = itemsData?.find((item) => item.supplier?._id === data.supplier);
            setItemSupplier(matchingItem)
            if (differenceInDays >= data.rmaSupplier) {
                setRmaDaysLeft(false);
            } else {
                const totalDays = data.rmaSupplier - differenceInDays
                setDaysLeft(totalDays)
                setRmaDaysLeft(true);
            }
        }
    }, [data, itemSupplier, itemsData]);
    useEffect(() => {
        setPrevCodes(data?.codes)
        // setPrevCodes(data?.codesAfterFix?.length > 0 ? data?.codesAfterFix : data?.codes || [])
        if (rules) {
            const uniqueCodes = new Set();
            rules.forEach(innerRules => {
                innerRules.forEach(element => {
                    uniqueCodes.add({ label: element?.label });
                });
            });
            setCodeDescription(Array.from(uniqueCodes));
        }
    }, [data, rules]);
    const items = []
    if (prevCodes) {
        for (const cod of prevCodes) {
            const item = codeDescription?.find(
                (instance: any) => instance?.label === cod
            )
            if (item) {
                items.push(item?.label)
            }
        }
    }
    const handleCodesChange = useCallback((e: any) => {
        console.log({ e });
        setCodes(e)
        if (!e || e.length === 0) {
            setEvent('')
            return
        }
    }, [])
    const onClose = useCallback(() => {
        setIsModalOpen(false)
        handleBack()
    }, [handleBack])
    useEffect(() => {
        let localEvent: any
        const manualSorting = sortingLogic?.manualSorting || []
        for (const manualSort of manualSorting) {
            for (const code of codes) {
                const ruleLabels = manualSort.rules.map(rule => {
                    for (const model of rule.models) {
                        if (model?._id === data?.model?._id) {
                            return rule.code.map(code => code.label);
                        }
                    }
                    return null;
                });
                const flattenedRuleLabels = ruleLabels.flat();
                if (flattenedRuleLabels.includes(code)) {
                    localEvent = manualSort;
                    setEvent(manualSort.name);
                    setEventType(manualSort.type);
                    setManualSortingId(manualSort._id);
                    return;
                }
            }
        }

    }, [codes, data?.model?._id, rules, sortingLogic?.manualSorting])

    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }

    const handleSave = async () => {
        if (codes?.length === 0 || codeDescription?.length === 0) {
            toast.error("Select, codes")
            return
        }
        if (!data?.isBroken) {
            if (rmaDaysLeft && codes) {
                const foundCode = codes?.find((code: string) => itemSupplier?.codes?.some((itemCode: any) => itemCode?.label === code));
                if (foundCode) {
                    setIsModalOpen(true);
                    return;
                }
            }
        }

        await sendEditRequest(
            `${baseUrl}/item/event-code/${data?._id}`,
            {
                codes,
                id: data?._id,
                comment,
                eventType,
                manualSortingId,
                event,
            },
        )
            .then(() => {
                toast.success('Saved', { hideProgressBar: true })
                addNotification({
                    message: 'Manual sorting has been updated successfully',
                    type: 'success',
                })
                setComment('')
                setCodes([])
                handleBack()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }


    const handleBroken = useCallback(async () => {
        await sendEditRequest(
            `${baseUrl}/item/event-code/${data?._id}`,
            {
                codes,
                id: data?._id,
                comment,
                eventType,
                manualSortingId,
                event,
                isBroken: true
            },
        )
            .then(() => {

                toast.success('Saved', { hideProgressBar: true })
                addNotification({
                    message: 'Manual sorting has been updated successfully',
                    type: 'success',
                })
                onClose()
                setComment('')
                setCodes([])
                handleBack()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }, [codes, comment, data?._id, event, eventType, handleBack, manualSortingId, onClose, sendEditRequest])

    const handleSavePrint = useCallback(
        async (e: any) => {
            if (codes?.length === 0 || codeDescription?.length === 0) {
                toast.error("Select, codes")
                return
            }
            if (!data?.isBroken) {
                if (rmaDaysLeft && codes) {
                    const foundCode = codes?.find((code: string) => itemSupplier?.codes?.some((itemCode: any) => itemCode?.label === code));
                    if (foundCode) {
                        setIsModalOpen(true);
                        return;
                    }
                }
            }
            e.preventDefault()
            await sendEditRequest(
                `${baseUrl}/item/event-code/${data?._id}`,
                {
                    codes,
                    id: data?._id,
                    comment,
                    eventType,
                    manualSortingId,
                    event,
                },
            )
                .then((response) => {
                    JsBarcode('#barcode', `${response?.data?.imei}`, {
                        lineColor: 'black',
                        textAlign: 'center',
                        width: 1,
                        background: 'transparent',
                        height: 40,
                        displayValue: false,
                        xmlDocument: document,
                    });
                    const item = { item: response?.data }
                    toast.success('Updated', { hideProgressBar: true })
                    addNotification({
                        message: 'Manual sorting has been updated successfully',
                        type: 'success',
                    })
                    generatePDF(item, labelsData, barCode)
                    onClose()
                    handleBack()
                })
        },
        [codes, codeDescription?.length, data?.isBroken, data?._id, sendEditRequest, comment, eventType, manualSortingId, event, rmaDaysLeft, itemSupplier?.codes, labelsData, onClose, handleBack]
    )



    console.log({ items });
    return (
        <>
            <Column className="w-3/5 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text
                        style={{
                            fontFamily: 'Archivo Black, sans-serif'
                        }}
                        colorV="blue" weightV="bold" variant="head">
                        Manual sorting
                    </Text>
                    {/* <Button onClick={handleBackToInhouse} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Add unit back to in house
                        </Text>
                    </Button> */}
                </RowAlignCenterSpaced>
                <PrimaryCard className="p-5   items-center text-center">
                    <Column className="gap-y-4">
                        <RowAlignCenterSpaced className="">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                IMEI/ Serial number
                            </Text>
                            <TextBackground className='w-72  h-10 text-left' backgroundColor="base01">
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.imei || ''}
                                </Text>
                            </TextBackground>

                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">

                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Model
                            </Text>
                            <TextBackground className='w-72  h-10 text-left' backgroundColor="base01">
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.model?.modelName || ''}
                                </Text>

                            </TextBackground>

                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Storage
                            </Text>
                            <TextBackground className='w-72  h-10 text-left' backgroundColor="base01">

                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.storage || ''}
                                </Text>
                            </TextBackground>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                VAT
                            </Text>
                            <TextBackground className='w-72  h-10 text-left' backgroundColor="base01">

                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {data?.vat || ''}
                                </Text>
                            </TextBackground>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Code
                            </Text>
                            <div className="w-72">
                                {items?.length > 0 &&
                                    items?.length === prevCodes?.length && (<MultiSelectLabel
                                        defaultValues={items}
                                        options={codeDescription}
                                        onChange={handleCodesChange}
                                        isMulti
                                        placeholder="Select codes..."
                                    />)}
                                {prevCodes?.length === 0 && (<MultiSelectLabel
                                    defaultValues={items}
                                    options={codeDescription}
                                    onChange={handleCodesChange}
                                    isMulti
                                    placeholder="Select codes..."
                                />)}
                            </div>
                            {/* <div className="w-72">
                                {items?.length > 0 &&
                                    items?.length === prevCodes?.length && (
                                        <div className="w-full">
                                            <Select
                                                defaultValue={items}
                                                options={codeDescription}
                                                onChange={handleCodesChange}
                                                isMulti
                                            />
                                        </div>
                                    )}
                                {prevCodes?.length === 0 && (
                                    <div className="w-full">
                                        <Select
                                            defaultValue={items}
                                            options={codeDescription}
                                            onChange={handleCodesChange}
                                            isMulti
                                        />
                                    </div>
                                )}
                            </div> */}
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Status
                            </Text>
                            {isVisible && (
                                <TextBackground className='w-72  h-10 text-left' backgroundColor="base01">
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        {event}
                                    </Text>
                                </TextBackground>
                            )}
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5 ">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Comment
                            </Text>
                            <TextArea
                                className="w-72"
                                backgroundColor='white'
                                value={comment}
                                onChange={handleCommentChange}
                                placeholder="Enter comment"
                                height="100px" // You can adjust the height here
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenter className="gap-x-4 mt-5">
                            <Button
                                className="w-36"
                                onClick={handleBack}
                                backgroundColor="lgblue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    Back
                                </Text>
                            </Button>

                            <Button
                                className="w-36"
                                onClick={handleSave}
                                backgroundColor="lgblue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    Save
                                </Text>
                            </Button>
                            <Button
                                className="w-36"
                                onClick={handleSavePrint}
                                backgroundColor="blue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    Save & Print
                                </Text>
                            </Button>
                        </RowAlignCenter>
                    </Column>
                </PrimaryCard>
                <div ref={barCode} className="py-2 px-2 hidden">
                    <svg id="barcode"></svg>
                </div>
                {isModalOpen && itemsData.length > 0 && <Attention handleBroken={handleBroken} onClose={onClose} codes={codes} item={data} daysLeft={daysLeft} />}
            </Column>
        </>
    )
}
