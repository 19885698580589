import { useState, useEffect, useMemo } from 'react'; // Import useMemo
import { Button } from '../../../base/Button';
import { Text } from '../../../base/Text';
import { Column } from '../../../layout/Col';
import { Flex } from '../../../layout/Flex';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { Codes } from './Codes/Codes';
import { Grades } from './Grades/Grades';
import { Models } from './Models/Models';
import { Partners } from './Partners/Partners';
import { RepairScore } from './RepairScore/RepairScore';
import { SortingLogic } from './SortingLogic/SortingLogic';
import { Row } from '../../../layout/Row';
import { Label } from './Label/Label';
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess';
import { useAuth } from '../../../../utils/auth';

function TabButton({ label, isActive, onClick, accessCheck }: any) {
    return (
        // Only render the button if accessCheck is true
        accessCheck ? (
            <Button onClick={onClick} backgroundColor={isActive ? 'blue' : 'grey'}>
                <Text
                    colorV={isActive ? 'white' : 'blue'}
                    variant="title"
                    weightV="bold"
                >
                    {label}
                </Text>
            </Button>
        ) : null
    );
}

function TabContent({ activeTab }: any) {
    switch (activeTab) {
        case 'Models':
            return <Models />;
        case 'Codes':
            return <Codes />;
        case 'Sorting Logic':
            return <SortingLogic />;
        case 'Repair Score':
            return <RepairScore />;
        case 'Partner':
            return <Partners />;
        case 'Grades':
            return <Grades />;
        case 'Label':
            return <Label />;
        default:
            return null;
    }
}

export default function Head() {
    const auth = useAuth();
    const [activeTab, setActiveTab] = useState('Models');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const tabs = useMemo(() => [
        { label: 'Models', accessCheck: auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageModels && acc.permission.includes(AccessPermissions.Admin)) },
        { label: 'Codes', accessCheck: auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageEvents && acc.permission.includes(AccessPermissions.Admin)) },
        { label: 'Sorting Logic', accessCheck: auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManualSortingLogic && acc.permission.includes(AccessPermissions.Admin)) },
        { label: 'Repair Score', accessCheck: auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageRepairPoints && acc.permission.includes(AccessPermissions.Admin)) },
        { label: 'Partner', accessCheck: auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManagePartners && acc.permission.includes(AccessPermissions.Admin)) },
        { label: 'Grades', accessCheck: true },  // Add your access check logic here
        { label: 'Label', accessCheck: true },   // Add your access check logic here
    ], [auth]); // Memoize the tabs array
    
    useEffect(() => {
        if (!tabs.find(tab => tab.label === 'Models')?.accessCheck) {
            setActiveTab('Grades');
        }
    }, [auth, tabs]);
    return (
        <Flex className="py-5 w-full ">
            <Column className='w-full'>
                <Row className=" w-full gap-x-4 flex-row-reverse ">
                    {tabs.map((tab, index) => (
                        <TabButton
                            key={index}
                            label={tab?.label}
                            isActive={activeTab === tab?.label}
                            onClick={() => handleTabClick(tab?.label)}
                            accessCheck={tab?.accessCheck}
                        />
                    ))}
                </Row>

                <RowAlignCenterSpaced className="mt-5">
                    <TabContent activeTab={activeTab} />
                </RowAlignCenterSpaced>
            </Column>
        </Flex>
    );
}
