import axios from 'axios'
import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { TextField } from '../../../../base/Textfield'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { FileUpload } from '../../../../shared/Fileupload'
import { myStatus } from '../../../../../constant/Storage'
import { useFetch } from '../../../../../hooks/useFetch'
import { Icon } from '../../../../base/Icon'
import { Row } from '../../../../layout/Row'
import { useEdit } from '../../../../../hooks/useEdit'
import { useAuth } from '../../../../../utils/auth'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditCreditNote: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item
}) => {
    const [customer, setCustomer] = useState<any>('')
    const [creditNoteNumber, setCreditNoteNumber] = useState<any>('')
    const [creditNoteValue, setCreditNoteValue] = useState<any>('')
    const [status, setStatus] = useState<any>('')
    const [issuedFor, setIssuedFor] = useState<any>('')
    const [pdfFile, setPdfFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState(false);
const auth = useAuth()
const token = auth?.user?.token?.token
const company = auth.selectedCompany
    const handlePdfFileUpload = (file: File) => {
        setPdfFile(file)
    }

    const { sendEditRequest } = useEdit()
    const { getRequest: getPartners, data: partnerData } = useFetch(
        `${baseUrl}/partner/get-partners`
    )
    const { getRequest, data } = useFetch(`${baseUrl}/rma/get-rma-company`)
    useEffect(() => {
        getPartners()
        getRequest()
    }, [getPartners, getRequest])


    useEffect(() => {
        setCustomer(item?.customerId)
        setCreditNoteNumber(item?.["Credit Note N"])
        setStatus(item?.Status)
        console.log(item?.item?.issuedFor?._id);
        setIssuedFor(item?.item?.issuedFor?._id)
        setCreditNoteValue(item?.item?.creditValue)
    }, [item])
    const customers = partnerData ? partnerData.filter(partner => partner.tags.includes("customer")) : [];

    const handleCustomerChange = (event: any) => {
        setCustomer(event.target.value)
    }
    const handleIssuedFor = (event: any) => {
        setIssuedFor(event.target.value)
    }
    const handleCreditNoteNumberChange = (newValue) => {
        setCreditNoteNumber(newValue)
    }

    const handleCreditNoteValueChange = (newValue) => {
        setCreditNoteValue(newValue)
    }

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value)
    }

    const handleUpdate = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!creditNoteNumber || !creditNoteValue) {
            toast.error('Please fill in all required fields');
            return;
        }
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', pdfFile);
            formData.append('customer', customer);
            formData.append('creditNoteNumber', creditNoteNumber);
            formData.append('creditNoteValue', creditNoteValue);
            formData.append('issuedFor', issuedFor);
            formData.append('status', status);
    
            const response = await axios.put(`${baseUrl}/creditnote/edit-credit-note/${item?.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: token,
                    Company: company._id
                },
            });
            toast.success('Credit note edited and uploaded successfully.');
            addNotification({
                message: 'Credit note has been updated successfully',
                type: 'success',
            });
            onClose();
            setPdfFile(null);
        } catch (error) {
            console.error('Error updating credit note:', error);
            toast.error(error?.response?.data?.message || 'An error occurred while updating the credit note');
        } finally {
            setIsLoading(false);
        }
    };
    console.log(issuedFor);

    return (
        <ModalBox
            heading="Edit credit note"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[520px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Customer
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="customer"
                            value={customer}
                            defaultValue={customer}
                            onChange={handleCustomerChange}
                            className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 p-2"
                        >
                            <option value="">Select</option>
                            {customers?.map((partner: any) => (
                                <option key={partner?._id} value={partner?._id}>
                                    {partner?.companyName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Credit note N
                    </Text>
                    <TextField
                        value={creditNoteNumber}
                        onChange={handleCreditNoteNumberChange}
                        placeholder="Enter Credit note"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Credit note value
                    </Text>
                    <TextField
                        value={creditNoteValue}
                        onChange={handleCreditNoteValueChange}
                        placeholder="Enter Credit note"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="status"
                            value={status}
                            defaultValue={status}
                            onChange={handleStatusChange}
                            className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 p-2"
                        >
                            <option value=''>
                                Select
                            </option>
                            {myStatus?.map((status: any) => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Issued for
                    </Text>
                    <select
                        id="rma"
                        value={issuedFor}
                        defaultValue={issuedFor}
                        onChange={handleIssuedFor}
                        className="w-56 border hover:cursor-pointer text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 p-2"
                    >
                        {data?.map((item: any) => (
                            <option key={item?._id} value={item?._id} selected={issuedFor === item._id}>
                                {item?.rmaNumber}
                            </option>
                        ))}
                    </select>



                </RowAlignCenterSpaced>
                <Column className="mt-5 gap-y-3">
                    <FileUpload
                        onFileUpload={(file) => handlePdfFileUpload(file)}
                        acceptedFileTypes=".pdf"
                    />
                    {pdfFile && (
                        <Row className='gap-x-3 items-center'>
                            <Text colorV="blue" variant="title" weightV="normal">
                                Uploaded File: {pdfFile.name}
                            </Text>
                            <span className='cursor-pointer' onClick={() => setPdfFile(null)}>
                                <Icon name="cross" color="black" size={16} />
                            </span>
                        </Row>
                    )}
                </Column>
                <Flex className="gap-x-4 mt-2">
                    <Button className='w-56' backgroundColor="lgblue" onClick={handleUpdate}>
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Updating...' : 'Update'}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
