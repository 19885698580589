import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex } from '../../../../layout/Flex';
import { Table } from '../../../../shared/Table';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import { useFetch } from '../../../../../hooks/useFetch';
import moment from 'moment';
import { Pagination } from './Pagination';
import { EditInventory } from '../../../Inventory/Inhouse/components/EditInventoryModel';
import { DeleteInventory } from '../../../Inventory/Inhouse/components/DeleteInventoryModel';
import { RepairModal } from './RepairModal';
import JsBarcode from 'jsbarcode';
import { generatePDF } from '../../../../shared/GenerateLabel';
import PaginatedItems from '../../../Inventory/Inhouse/components/Pagination';

const baseUrl = process.env.REACT_APP_baseUrl;

export default function RepairTable({ itemsData, pageCount, handlePageChange, refetch }: any) {
    const [isRepair, setIsRepair] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState<any>();
    const barCode = useRef<HTMLDivElement>(null);

    let extractedData = [];
    if (itemsData?.items) {
        extractedData = itemsData?.items.map((item) => {
            return {
                Id: item?._id,
                IMEI: item?.imei,
                Date: moment(item?.createdAt).format('DD/MM/YYYY'),
                Model: item?.model?.modelName,
                Storage: item?.storage,
                VAT: item?.vat,
                Stauts: item?.sortingEvent?.name,
                Code: item?.codes?.toString(),

                // Code: item?.codesAfterFix?.length > 0 ? item?.codesAfterFix?.toString() : item?.codes.toString(),
                Comment: item?.comment !== "undefined" ? item?.comment : "",
                Repair: item?.repair,
                item: item
            };
        });
    }


    const columns = ['IMEI', 'Date', 'Model', 'Storage', 'VAT', 'Stauts', 'Code'];

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setSelectedOption(null);
        setIsRepair(false);
        refetch()
    }, [refetch]);

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item);
        setSelectedOption(optionValue);
        if (optionValue === 'Print') {
            handlePrintlabelPDF(item);
            setIsRepair(false);
        } else if (optionValue === 'Repair') {
            setIsRepair(true);
            setSelectedOption('Repair'); // Corrected placement
        } else {
            setIsRepair(false);
        }
        setIsModalOpen(true);
    };

    const handleRowClick = (item) => {
        setIsRepair(true);
        setSelectedOption('Repair');
        setSelectedModel(item);
    };
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);

    const handlePrintlabelPDF = useCallback(async (item) => {
        console.log(item);
        JsBarcode('#barcode', `${item?.item?.imei}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 1,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        });
        generatePDF(item, labelsData, barCode)
    }, [labelsData]);

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        options={['Print']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        clickable={true}
                        handleRowClick={handleRowClick}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
            <PaginatedItems
                pageCount={pageCount}
                onPageChange={handlePageChange}
            />
            {isRepair && (
                <RepairModal item={selectedModel} onClose={closeModal} />
            )}
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </>
    );
}
