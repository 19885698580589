import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '../../../../layout/Flex';
import { Table } from '../../../../shared/Table';
import { PrimaryCard } from '../../../Page/PrimaryCard';
import { EditSaleItem } from './EditSaleItem';
import { useNavigate } from 'react-router-dom';
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess';
import { useAuth } from '../../../../../utils/auth';

export default function SalesTable({ item, refetch }: any) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditOpen, setEditOpen] = useState(false);
  const navigate = useNavigate()
  const auth = useAuth();
  const editSalesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.SalesInventory && acc.permission.includes(AccessPermissions.Edit));
  const viewSalesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.SalesInventory && acc.permission.includes(AccessPermissions.View));

  const handleSelectChange = (action, item) => {
    if (action === 'Edit') {
      setSelectedItem(item);
      setEditOpen(true);
    }
  };
  console.log(item)

  let extractedData = [];
  if (item) {
    extractedData = item?.map((item) => {
      return {
        skuId: item?.skuId,
        itemsId: item.items,
        SKU: item?.sku,
        Model: item?.modelDetails,
        Storage: item?.storage,
        Color: item?.color,
        Vat: item?.vat,
        Grade: item?.grade?.grade,
        'Available pieces': item?.availablePieces,
        Status: item?.sortingEvent,
        Price: item?.salePrice,
      };
    });
  }

  const columns = [
    'SKU',
    'Model',
    'Storage',
    'Color',
    "Vat",
    "Grade",
    'Available pieces',
    'Status',
    'Price',
  ];

  const handleRow = (item) => {
    navigate(`/sales/sales-inventory/sale-items/${item?.skuId}`)

  };

  return (
   <>
   {viewSalesCheck && 
      <Flex className="mt-5 w-full">
        <PrimaryCard className="w-full">
          <Table
            options={['Edit']}
            columns={columns}
            data={extractedData}
            showActions={editSalesCheck && true}
            clickable={true}
            handleDropdownChange={handleSelectChange}
            handleRowClick={handleRow}
          />
        </PrimaryCard>
      </Flex>
    }
      {isEditOpen && (
        <EditSaleItem
          onClose={() => {
            setEditOpen(false);
            refetch();
          }}
          item={selectedItem}
        />
      )}
    </>
  );
}
