import React, { useState, CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { PrimaryCard } from '../../components/compound/Page/PrimaryCard'
import Logo from '../../assets/logo.png'
import { Text } from '../../components/base/Text'
import { MyIcon } from '../../components/base/MyIcon'
import { Flex } from '../../components/layout/Flex'
import { useLocation } from 'react-router-dom'

interface NavItem {
    text: string
    icon: string
    iconSize?: number
    to: string
    subNavigation?: React.ReactNode
}

interface NavbarProps {
    initialNavItems: NavItem[]
    showLogo?: boolean
    heading?: string
    className?: string
}

export const CustomerNavbar: React.FC<NavbarProps> = ({
    initialNavItems,
    showLogo = true,
    heading,
    className,
}) => {
    const [navItems] = useState<NavItem[]>(initialNavItems)
    const location = useLocation()
    const currentPath = location.pathname

    const logoStyle: CSSProperties = {
        fontSize: '24px',
        marginBottom: '20px',
    }

    const navItemsStyle: CSSProperties = {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        marginTop: '15px',
    }

    const navItemStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        fontSize: '18px',
        cursor: 'pointer',
        color: 'white',
        borderLeft: '2px solid #f7f7f9',

    }

    const navIconStyle: CSSProperties = {
        margin: '0 15px 0 7px',
    }

    const activeItemStyle: CSSProperties = {
        borderLeft: '2px solid #3B65F6',
        transition: 'border 0.3s ease-in-out',
    }
    const isFullNavbar =
        currentPath === '/home' ||
        currentPath === '/purchase' ||
        currentPath.startsWith('/customer-purchase-order')

    // ... (existing code)

    const isCustomerPurchaseOrder = currentPath.startsWith(
        '/customer-purchase-order'
    )

    return (
        <div>
            <PrimaryCard
                style={{
                    width: isFullNavbar ? 295 : 70,
                    boxSizing: 'border-box',
                }}
                className={`px-3 ${className} shadow-none rounded`}
            >
                {showLogo && isFullNavbar && (
                    <div style={logoStyle} className="logo">
                        <img alt="" className="h-16 w-28" src={Logo} />
                    </div>
                )}

                {heading && isFullNavbar && (
                    <div className="mb-5">
                        <Text colorV="blue" variant="heading" weightV="bold">
                            {heading}
                        </Text>
                    </div>
                )}

                <ul style={navItemsStyle} className="nav-items">
                    {navItems.map((item: NavItem, index: number) => (
                        <li
                            key={index}
                            style={{
                                ...navItemStyle,
                                color: 'white',
                                ...(isFullNavbar &&
                                (currentPath === item.to ||
                                    (isCustomerPurchaseOrder &&
                                        item.to === '/purchase'))
                                    ? activeItemStyle
                                    : {}),
                            }}
                            className="nav-item"
                        >
                            <Link
                                to={item.to}
                                style={{
                                    textDecoration: 'none',
                                    color: 'blue',
                                }}
                            >
                                <Flex>
                                    <span
                                        style={{
                                            ...navIconStyle,
                                            color: isFullNavbar
                                                ? currentPath === item.to
                                                    ? 'lgblue'
                                                    : 'white'
                                                : 'white',
                                        }}
                                        className="nav-icon"
                                    >
                                        <MyIcon
                                            size={item.iconSize}
                                            name={item?.icon}
                                        />
                                    </span>
                                    {isFullNavbar ? (
                                        <Text colorV="blue" variant="title">
                                            {item.text}
                                        </Text>
                                    ) : null}
                                </Flex>
                            </Link>
                        </li>
                    ))}
                </ul>
            </PrimaryCard>
        </div>
    )
}
