import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-datalabels';

interface AtlasChartProps {
    labels: string[];
    data: number[];
    chartType?: 'doughnut';
}

export const DoughnutChart: React.FC<AtlasChartProps> = ({
    labels,
    data,
}) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        const chartWidth = 50; // Set a smaller width
        const chartHeight = 50; // Set a smaller height
        const colors = [
            '#112D56',
            '#171156',
            '#115056',
            '#3B65F6',
            '#6E3BF6',
            '#3BC2F6',
            '#45948A',
            '#457794',
            '#459462',
            '#5748E6',
            '#A648E6',
            '#4888E6',
            '#E8C877',
            '#D0E877',
            '#E89077'
        ];

        chartRef.current.width = chartWidth;
        chartRef.current.height = chartHeight;

        chartInstanceRef.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: colors,
                        hoverOffset: 4,
                    },
                ],
            },

            options: {
                cutout: '70%',
                plugins: {
                    legend: {
                        position: 'bottom', // Place legend at bottom
                        display: true,
                    },

                    datalabels: {
                        formatter: (value) => {
                            if (value < 15) return '';
                            return value + '%';
                        },
                    },
                },
            },
        });
    }, [labels, data]);

    return (
        <div style={{ width: '70%', height: '70%' }}>
            <canvas ref={chartRef}></canvas>
        </div>
    );
};
