import { Text } from '../../../base/Text'
import { Flex } from '../../../layout/Flex'
import { Column } from '../../../layout/Col'
import { Row } from '../../../layout/Row'

export default function Head({purchaseData}:any) {

    return (
        <Flex className="py-2">
            <Column>
                <Row className="gap-x-4 ">
                    <Text
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        Sales orders
                    </Text>
                </Row>
            </Column>
        </Flex>
    )
}
