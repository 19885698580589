import React, { useCallback, useEffect, useState } from 'react'
import { useFetch } from '../../../../../../hooks/useFetch'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Text } from '../../../../../base/Text'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { usePost } from '../../../../../../hooks/usePost'
import Select from 'react-select'
import { addNotification } from '../../../../../shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

export const AddSparepartSKU: React.FC<{ onClose: () => void }> = ({
    onClose,
}) => {
    const [name, setName] = useState<string>('')
    const [sku, setSKU] = useState<any>('')
    const [models, setModels] = useState<string[]>([])
    const [localModel, setLocalModel] = useState<string[]>([])
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany;
    const { sendRequest } = usePost()
    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )
    useEffect(() => {
        const storedModel = modelsData?.map((model) => {
            return { value: model?._id, label: model?.modelName }
        })
        setLocalModel(storedModel )
    }, [modelsData])
    console.log(localModel);
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            position: "relative",
            boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : null,
            '&:hover': {
                borderColor: '#2684FF',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#2684FF',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: state.isSelected ? '#2684FF' : state.isFocused ? '#f0f0f0' : 'white',
            color: state.isSelected ? 'white' : '#333',
            '&:hover': {
                backgroundColor: '#2684FF',
                color: 'white',
            },
        }),
    };

    const handleModelChange = useCallback((e: any) => {
        setModels(Array.isArray(e) ? e.map((model) => model?.value) : [])
    }, [])

    useEffect(() => {
        getModels()
    }, [getModels])

    const handleSKU = (newOption) => {
        setSKU(newOption)
    }
    const handleName = (newOption) => {
        setName(newOption)
    }

    const handleSave = useCallback(async (e: any) => {
        e.preventDefault()
        if (!models) {
            alert('Please, select Model')
            return
        } else if (!sku) {
            alert('Please, add IMEI')
            return
        } else if (!name) {
            alert('Please, add vat')
            return
        }
        const urlInventoryItem = `${baseUrl}/sparepart/add-sparepart-sku`
        await sendRequest(
            urlInventoryItem,
            {
                models,
                sku,
                name,
            },
        )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Sparepart has been added successfully',
                    type: 'success',
                })
                setModels([]);
                setName('');
                setSKU('');
                onClose()
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data}`, {
                    hideProgressBar: true,
                })
            })
    }, [models, name, onClose, sendRequest, sku])
    return (
        <ModalBox
            heading="Create new SKU"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        SKU
                    </Text>
                    <TextField
                        value={sku}
                        onChange={handleSKU}
                        placeholder="Enter SKU"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Name
                    </Text>
                    <TextField
                        value={name}
                        onChange={handleName}
                        placeholder="spare part name"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>

                    <div className="w-56">
                        {localModel?.length > 0 && <Select
                            styles={customStyles}
                            maxMenuHeight={90}
                            options={localModel}
                            onChange={handleModelChange}
                            isMulti
                        />}
                    </div>
                    {/* <div style={{ width: '14rem' }}>
                        <select
                            id="device"
                            value={model}
                            onChange={handleModelChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {modelsData?.map((mdl: any) => (
                                <option key={mdl?._id} value={mdl?._id}>
                                    {mdl?.modelName}
                                </option>
                            ))}
                        </select>
                    </div> */}
                </RowAlignCenterSpaced>

                <Flex className="gap-x-4 mt-4">
                    <Button onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Save
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
