import React, { useEffect, useState } from 'react'
import { Partnercard } from '../../../../base/Partnercard'
import { Pagebg } from '../../../Page/Pagebg'
import { Button } from '../../../../base/Button'
import { Text } from '../../../../base/Text'
import { AddPartner } from '../DialougeBoxes/Add/AddPartner'
import { useFetch } from '../../../../../hooks/useFetch'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { EditPartner } from '../DialougeBoxes/Edit/EditPartner'
const baseUrl = process.env.REACT_APP_baseUrl

export const Partners = () => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const { getRequest: getPartners, data: partnersData } = useFetch(
        `${baseUrl}/partner/get-partners`
    )
    useEffect(() => {
        getPartners()
    }, [getPartners])
    const openEditModal = (partner) => {
        setSelectedPartner(partner);
        setIsEditModalOpen(true);
      };
      console.log({partnersData});
    
      const openAddModal = () => {
        setIsAddModalOpen(true);
      };
    
      const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedPartner(null);
        getPartners();
      };
    
      const closeAddModal = () => {
        setIsAddModalOpen(false);
        getPartners();
      };
console.log({partnersData});
    return (
        <Pagebg variant="brown">
            <RowAlignCenterSpaced className="mb-5">
                <Text colorV="blue" variant="head" weightV="bold">
                    Manage partner
                </Text>
                <Button
                    backgroundColor="lgblue"
                    onClick={openAddModal} 
                >
                    <Text colorV="white" variant="title" weightV="normal">
                        Add partner
                    </Text>
                </Button>
            </RowAlignCenterSpaced>
            <div className="flex flex-wrap gap-16">
                {partnersData?.map((partner: any) => (
                    <Partnercard
                        key={partner?._id}
                        onIconClick={() => openEditModal(partner)}
                        imageUrl={partner?.logoPath}
                        partnerTag = {partner?.tags}
                    >
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                {partner?.companyName}
                            </Text>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {partner?.tags.toString() }
                            </Text>
                        </RowAlignCenterSpaced>

                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Country
                            </Text>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {partner?.country}
                            </Text>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Site
                            </Text>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {partner?.site}
                            </Text>
                        </RowAlignCenterSpaced>
                    </Partnercard>
                ))}
            </div>
            {isEditModalOpen && (
        <EditPartner selectedPartner={selectedPartner} onClose={closeEditModal} />
      )}
      {isAddModalOpen && <AddPartner onClose={closeAddModal} />}
        </Pagebg>
    )
}
