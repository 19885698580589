import axios, { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useAuth } from '../utils/auth';

export const usePost = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [error, setError] = useState<any>('');
    const auth = useAuth();
    const token = auth?.user?.token?.token;
    const company = auth.selectedCompany;

    const sendRequest = useCallback(async (url: string, postData: any, signal?: AbortSignal) => {
        console.log(postData);
        setLoading(true);
        try {
            const response: AxiosResponse = await axios.post(
                url,
                postData,
                {
                    headers: {
                        Authorization: token as string,
                        Company: company._id, 
                    },
                    signal: signal, // Pass the AbortController signal here
                }
            );
            setData(response.data);
            return response;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    }, [company._id, token]);

    return { loading, data, error, sendRequest };
};
