import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Container } from '../DraggableList/Container'
import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { Button } from '../../../../../base/Button'
import { Text } from '../../../../../base/Text'
import { ManualSortingAddEvent } from './Modals/ManualSortingAddEvent'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { CodeEventManualSorting } from './Modals/CodeEventManualSorting'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { useEdit } from '../../../../../../hooks/useEdit'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl
export const ManualSortingEvent = ({
    sortingLogic,
    refetch,
    eventTypes,
}: {
    sortingLogic: any[]
    refetch
    eventTypes: any[]
}) => {
    const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false)
    const [isRenameEventModalOpen, setIsRenameEventModalOpen] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [isCode, setIsCode] = useState(false)
    const [clickedRow, setClickedRow] = useState(null) // New state to track clicked row
    const {sendEditRequest} = useEdit()
    const openAddEventModal = () => {
        setIsAddEventModalOpen(true)
    }

    const onClose = useCallback(() => {
        setIsAddEventModalOpen(false)
        setIsRenameEventModalOpen(false)
        setSelectedEvent(null)
        setIsCode(false)
        setClickedRow(null)
        refetch()
    }, [
        setIsAddEventModalOpen,
        setIsRenameEventModalOpen,
        setSelectedEvent,
        setIsCode,
        setClickedRow,
        refetch,
    ])

    const handleBack = () => {
        setIsCode(false)
    }

    const handleSave = useCallback(
        async (updatedRows) => {
            const urlInventoryItem = `${baseUrl}/sorting-logic/update-manual-sorting-index`
            try {
                const response = await sendEditRequest(
                    urlInventoryItem,
                    {
                        updatedRows,
                    },
                )

                toast.success(response?.data?.message, {
                    hideProgressBar: true,
                })
                addNotification({
                    message: 'Manual sorting has been updated successfully',
                    type: 'success',
                })
                onClose()
            } catch (error) {
                console.error(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            }
        },
        [onClose, sendEditRequest]
    )
    
    const handleItemRowClick = (id: any) => {
     const localData = sortingLogic.find((data:any) => data._id === id)
     setClickedRow(localData);
        setIsCode(true)
    }

    return (
        <>
            {clickedRow && isCode ? (
                <CodeEventManualSorting
                    onClose={onClose}
                    item={clickedRow}
                    onBack={handleBack}
                />
            ) : (
                <>
                    <RowAlignCenterSpaced className="mb-3">
                        <Text colorV="blue" variant="heading" weightV="bold">
                            Manual sorting
                        </Text>
                        <Button
                            className="w-40"
                            backgroundColor="lgblue"
                            onClick={openAddEventModal}
                        >
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Add new event
                            </Text>
                        </Button>
                    </RowAlignCenterSpaced>
                    <DndProvider backend={HTML5Backend}>
                        <Container cardItems={sortingLogic} refetch={refetch} handleItemRowClick={handleItemRowClick} />
                    </DndProvider>
                </>
            )}
            {isAddEventModalOpen && <ManualSortingAddEvent onClose={onClose} />}
            {/* {isRenameEventModalOpen && (
                <RenameManualSortingEvent
                    onClose={onClose}
                    eventData={selectedEvent}
                    eventTypes={['in_house', 'out_of_house']}
                />
            )} */}
        </>
    )
}

// import React, { useCallback, useEffect, useState } from 'react';
// import { PrimaryCard } from '../../../../Page/PrimaryCard';
// import { Button } from '../../../../../base/Button';
// import { Text } from '../../../../../base/Text';
// import { ManualSortingAddEvent } from './Modals/ManualSortingAddEvent';
// import { RenameManualSortingEvent } from './Modals/RenameManualSortingEvent';
// import { useFetch } from '../../../../../../hooks/useFetch';
// import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
// import { CodeEventManualSorting } from './Modals/CodeEventManualSorting';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { useAuth } from '../../../../../../utils/auth';
// import { Table } from '../../../../../shared/Table';

// const baseUrl = process.env.REACT_APP_baseUrl;

// export const ManualSortingEvent = ({ sortingLogic, refetch, eventTypes }: { sortingLogic: any[], refetch, eventTypes: any[] }) => {
//   const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
//   const [isRenameEventModalOpen, setIsRenameEventModalOpen] = useState(false);
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [isCode, setIsCode] = useState(false);
//   const [clickedRow, setClickedRow] = useState(null); // New state to track clicked row
//   const auth = useAuth();
//   const token = auth?.user?.token?.token;

//   const openAddEventModal = () => {
//     setIsAddEventModalOpen(true);
//   };

//   const onClose = useCallback(() => {
//     setIsAddEventModalOpen(false);
//     setIsRenameEventModalOpen(false);
//     setSelectedEvent(null);
//     setIsCode(false);
//     setClickedRow(null);
//     refetch();
//   }, [setIsAddEventModalOpen, setIsRenameEventModalOpen, setSelectedEvent, setIsCode, setClickedRow, refetch,]);

//   const handleSelectChange = (optionValue, item) => {
//     setSelectedEvent(item);
//     setIsRenameEventModalOpen(true);
//   };

//   const handleRowClick = (item) => {
//     setClickedRow(item);
//     setIsCode(true);
//   };

//   let extractedData = [];
//   if (sortingLogic) {
//     extractedData = sortingLogic?.map((item) => {
//       return {
//         Id: item?._id,
//         'Event name': item?.name,
//         'Event type': item?.type,
//       };
//     });
//   }
// // add Action if draggable
//   const headers = ['Event name', 'Event type'];

//   const handleBack = () => {
//     setIsCode(false)
//   };

//   const handleDragEnd = (updatedRows) => {
//     handleSave(updatedRows)
//   };

//   const handleSave = useCallback(
//     async (updatedRows) => {
//       const urlInventoryItem = `${baseUrl}/sorting-logic/update-manual-sorting-index`;
//       try {
//         const response = await axios.put(
//           urlInventoryItem,
//           {
//             updatedRows,
//           },
//           {
//             headers: {
//               Authorization: token,
//             },
//           }
//         );

//         toast.success(response?.data?.message, { hideProgressBar: true });
//         onClose();
//       } catch (error) {
//         console.error(error);
//         toast.error(`Error: ${error?.response?.data?.message}`, {
//           hideProgressBar: true,
//         });
//       }
//     },
//     [onClose, token]
//   );

//   return (
//     <>
//       {clickedRow && isCode ? (
//         <CodeEventManualSorting
//           onClose={onClose}
//           item={clickedRow}
//           onBack={handleBack}
//         />
//       ) : (
//         <>
//           <RowAlignCenterSpaced>
//             <Text colorV="blue" variant="heading" weightV="bold">
//               Manual sorting
//             </Text>
//             <Button className="w-40" backgroundColor="lgblue" onClick={openAddEventModal}>
//               <Text colorV="white" variant="title" weightV="normal">
//                 Add new event
//               </Text>
//             </Button>
//           </RowAlignCenterSpaced>
//           <PrimaryCard className="w-full mt-4">
//           <Table
//           options={['Edit']}
//           columns={headers}
//           data={extractedData}
//           showActions={true}
//           handleRowClick={handleRowClick}
//           handleDropdownChange={handleSelectChange}
//         />
//             {/* <DraggableTable headers={headers} data={extractedData}
//               showActions={true}
//               options={['Rename']}
//               handleDropdownChange={handleSelectChange}
//               handleRowClick={handleRowClick}
//               onDragEnd={handleDragEnd}
//             /> */}
//           </PrimaryCard>
//         </>
//       )}

//       {isAddEventModalOpen && <ManualSortingAddEvent onClose={onClose} />}
//       {isRenameEventModalOpen && (
//         <RenameManualSortingEvent onClose={onClose} eventData={selectedEvent} eventTypes={["in_house", "out_of_house"]} />
//       )}
//     </>
//   );
// };
