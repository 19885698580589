import * as XLSX from 'xlsx';
import PropTypes, { string } from 'prop-types';

interface ExcelButtonProps {
    data: any[];
    filename?: string;
    title?: string;
}

export const ExcelButton: React.FC<ExcelButtonProps> = ({ data = [], filename = 'default', title = 'Export to Excel' }) => {

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "RMA Data");
        const fileName = filename ? `${filename}.xlsx` : "rma-data.xlsx";
        XLSX.writeFile(workbook, fileName);
    }

    return (
        <button 
            onClick={() => handleExport()} 
            className={`py-2 px-4 bg-[#45948A] rounded-md text-white hover:bg-opacity-70 ease-in-out duration-300 flex flex-row items-center group/excel`}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-0 group-hover/excel:size-5 group-hover/excel:mr-2 duration-150 ease-in-out">
                <path d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z" />
                <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
            </svg>

            {title}
        </button>
    )
}

ExcelButton.propTypes = {
    data: PropTypes.array.isRequired,
    filename: PropTypes.string,
    title: PropTypes.string
};

export default ExcelButton;