import { useState } from 'react';
import { Button } from '../../../base/Button';
import { Text } from '../../../base/Text';
import { Flex } from '../../../layout/Flex';
import { Row } from '../../../layout/Row';
import { Link } from 'react-router-dom';
import { ReportRMA } from './component/ReportRMA'; // Import your modal component
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';

export default function Head({refetch}:any) {
  const [activeTab, setActiveTab] = useState('Report RMA');
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
    refetch()

  };

  return (
    <>
      <RowAlignCenterSpaced className="w-full ">
        <Text
          colorV="blue"
          variant="head"
          weightV="bold"
        >
          Manage customer RMA
        </Text>
        <Row className=" gap-x-2 ">
         <Button
            className="w-48"
            backgroundColor={
              activeTab === 'Report RMA' ? 'lgblue' : 'grey'
            }
            onClick={openUploadModal}
          >
            <Text
              colorV={activeTab === 'Report RMA' ? 'white' : 'blue'}
              variant="title"
              weightV="normal"
            >
              Report RMA
            </Text>
          </Button>
          <Link to="/rma/archive-customer-rma">
            <Button
              className="w-32"
              backgroundColor={activeTab === 'My Archive' ? 'yellow' : 'grey'}
              onClick={() => handleTabClick('My Archive')}
            >
              <Text
                colorV={activeTab === 'My Archive' ? 'white' : 'blue'}
                variant="title"
                weightV="normal"
              >
                My Archive
              </Text>
            </Button>
          </Link>
        </Row>
      </RowAlignCenterSpaced>
      {isUploadModalOpen && (
        <ReportRMA onClose={closeUploadModal}  />
      )}
    </>
  );
}
