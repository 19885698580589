export enum Events {
    rma = 'RMA',
    return = 'Return',
    tounlock = 'To_unlock',
    hardRepair = 'Hard_Repair',
    mediumRepair = 'Medium_Repair',
    easyRepair = 'Easy_Repair',
    salesInventory = 'Sales inventory',
    scrap = 'Scrap',
    warranty = 'Warranty',
    ms_repair = 'MS_Repair',
}
