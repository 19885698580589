/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */

import TopHeader from '../components/TopHeader'

const baseUrl = process.env.REACT_APP_baseUrl

export default function DownloadTemplate() {
    return (
        <>
            <TopHeader />
            <div className="flex items-center flex-col m-5">
                <div className="m-5 ">
                    <h1 className="font-bold text-xl mt-6">Template</h1>
                    {
                        <a
                            href={`${baseUrl}/static/template-1.0.0.xlsx`}
                            className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
                        >
                            Download Template
                        </a>
                    }
                </div>
            </div>
        </>
    )
}
