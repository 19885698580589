import React, { ReactNode, useEffect, useState } from 'react';
import { PrimaryCard } from '../../../compound/Page/PrimaryCard';
import { useFetch } from '../../../../hooks/useFetch';
import moment from 'moment';
import { EditUser } from '../editUser/EditUser';


interface UserData {
  _id?: string;
  email?: string;
  companyName?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  accountManagerEmail?: string;
  subscriptionType?: string;
  createdAt?: string;
}
export const UsersTable = ({extractedData, refetchData}:any) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  
  const handleRowClick = (item: UserData) => {
    setModalContent(
      React.createElement(EditUser, { item, onClose: () => {setModalContent(null),refetchData()} })
    );
  };

  return (
    <>
      <PrimaryCard className=" ">
        <div className="overflow-auto rounded-lg text-left" style={{ maxWidth: '100%', width: '100%', overflowX: 'auto' }}>
          <table className="table-fixed w-full hover:none">
            <thead 
               style={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
               // Corrected to camelCase and added fallback fonts
              }}>
              <tr>
                {/* All th elements for each field */}
                <th
                  className="px-4 py-2 "
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Email
                </th>
                <th
                  className="px-4 py-2 "
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Company
                </th>
                <th
                  className="px-4 py-2 "
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Contact Person
                </th>
                <th
                  className="px-4 py-2 "
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Status
                </th>
                <th
                  className="px-4 py-2 "
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Manager
                </th>
                <th
                  className="px-4 py-2"
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Subscription Type
                </th>
                <th
                  className="px-4 py-2"
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Next Invoice
                </th>
                <th
                  className="px-4 py-2"
                  style={{
                    width: '250px',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Inter Tight, sans-serif'
                }}
                >
                  Contract Ending
                </th>
              </tr>
            </thead>
            <tbody>
              {extractedData.map((item, rowIndex) => (
                <tr key={rowIndex} onClick={() => handleRowClick(item)} className="cursor-pointer ">
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {item.Email}
                  </td>
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {item.Company}
                  </td>
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {item['Contact person']}
                  </td>
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {item.Status}
                  </td>
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {item.Manager}
                  </td>
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {item['Subscription type']}
                  </td>
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {moment(item['Next invoice']).format('DD.MM.YYYY')}
                  </td>
                  <td
                    className="px-4 truncate py-2  overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[250px]"
                  >
                    {moment(item['Contract ending']).format('DD.MM.YYYY')}
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </PrimaryCard>
      {modalContent}
    </>
  );
}

