import React from 'react'
import { PrimaryCard } from '../Page/PrimaryCard'
import { Text } from '../../base/Text'
import { Table } from '../../shared/Table' // Update the import path to your Table component

export const SalesTable = ({ purchaseOrder }: any) => {
    const columns = ['Sale order number', 'Customer', 'Total value']
    let extractedData = []

    if (purchaseOrder) {
        extractedData = purchaseOrder?.map((item) => {
            return {
                'Sale order number': item?.saleBatchNumber || '',
                Customer: item?.customer?.companyName,
                'Total value': item?.totalValue,
            };
        }).filter(Boolean); // Remove null values from the array
    }
    return (
        <PrimaryCard className='w-full'>
            <div className="mb-5">
                <Text colorV="blue" variant="heading" weightV="normal">
                    Latest orders
                </Text>
            </div>
            <Table
                columns={columns}
                data={extractedData}
                showActions={false}
            />
        </PrimaryCard>
    )
}
