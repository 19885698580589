import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../../../hooks/useFetch'
import { Flex } from '../../../../layout/Flex'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { EditUser } from './EditUser'
import { DeleteUser } from './DeleteUser'
import { ArchiveUser } from './ArchiveUser'
import { AddUser } from './AddUser'

export default function UsersTable({ data, refetch  }: any) {
    const [selectedOption, setSelectedOption] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState<any>([])
    console.log(data);

    let extractedData = []
    if (data) {
      extractedData = data
      .filter((item) => item?.profile?.role?.includes('admin') )
        .map((item) => {
          return {
            id: item?._id,
            "First name": item?.firstName,
            "Last name": item?.lastName,
            Email: item?.email,
            "Manager Email": item?.profile?.accountManagerEmail || item?.accountManager,
            user:item,
          };
        });
    }

    const columns = [
        'First name',
        'Last name',
        'Email',
        'Manager Email',
    ]

    const openModal = (option, selectedItem) => {
        setSelectedOption(option)
        setSelectedItem(selectedItem)
    }

    const closeAllModals = () => {
        setSelectedOption(null)
        setSelectedItem(null)
        refetch()
    }

    const handleCheckboxChange = (e: any, selectedItem: any) => {
        e.stopPropagation()
        if (e.target.checked) {
            setSelectedUsers([...selectedUsers, selectedItem])
        } else {
            if (selectedUsers.includes(selectedItem)) {
                setSelectedUsers(
                    selectedUsers.filter((e) => e !== selectedItem)
                )
            }
        }
    }
    
    return (
        <>
            <Flex className="mt-5">
                <PrimaryCard className='w-full'>
                    <Table
                        options={['Edit', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        whiteSpace='pre-wrap'
                        showUserCheckboxes={true}
                        showCheckboxes={false}
                        selectedRows={selectedUsers}
                        onCheckboxChange={handleCheckboxChange}
                        handleDropdownChange={(selectedOption, item) =>
                            openModal(selectedOption, item)
                        }
                    />
                </PrimaryCard>
                {selectedOption === 'Edit' && (
                    <AddUser onClose={closeAllModals} user={selectedItem} />
                )}

                {selectedOption === 'Delete' && (
                    <DeleteUser onClose={closeAllModals} item={selectedItem} />
                )}
            </Flex>
        </>
    )
}
