import React, { useCallback, useEffect, useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { useFetch } from '../../../../../hooks/useFetch'
import { useParams } from 'react-router-dom'
import { Row } from '../../../../layout/Row'
import { Text } from '../../../../base/Text'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Column } from '../../../../layout/Col'
import moment from 'moment'
import RepairCodes from './RepairCodes'
import { Button } from '../../../../base/Button'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { TextBackground } from '../../../../base/TextBackground'
import { useEdit } from '../../../../../hooks/useEdit'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export default function RepairDetail() {
    const [notes, setNotes] = useState('')
    const [isNotes, setIsNotes] = useState(false)

    const [rerenderCodes, setRerenderCodes] = useState(false);
    const { id } = useParams()
    const auth = useAuth()
    const { sendEditRequest } = useEdit()

    const token = auth?.user?.token?.token
    const { getRequest: getInventoryItem, data } = useFetch(
        `${baseUrl}/item/${id}`
    )
    useEffect(() => {
        getInventoryItem()
    }, [getInventoryItem])
console.log({data});
    const handleCommentFromChild = (comment) => {
        setNotes(comment)
    }

    const repair = data?.repair

    const calculateRepairDuration = (startAt, finishAt, cancelAt) => {
        if (startAt) {
            const currentDate = moment();
            const repairStartDate = moment(startAt);

            let endDate;
            if (finishAt) {
                endDate = moment(finishAt);
            } else if (cancelAt) {
                endDate = moment(cancelAt);
            } else {
                endDate = currentDate;
            }

            const duration = moment.duration(endDate.diff(repairStartDate));
            const daysDifference = duration.days();
            const hoursDifference = duration.hours();
            const minutesDifference = duration.minutes();

            return {
                days: daysDifference,
                hours: hoursDifference,
                minutes: minutesDifference,
            };
        }

        return {
            days: 0,
            hours: 0,
            minutes: 0,
        };
    };

    const getRepairDurationText = () => {
        if (repair?.startAt) {
            const { days, hours, minutes } = calculateRepairDuration(
                repair.startAt,
                repair.finishAt,
                repair.cancelAt
            );

            const formattedDuration =
                (days > 0 ? `${days} Days :` : '') +
                (hours > 0 ? `${hours} h: ` : '') +
                (minutes > 0 ? `${minutes} Min` : '');

            return (
                <>
                    <Text className='h-8' colorV="mid-blue" weightV="bold" variant="heading">
                        {formattedDuration.trim()}
                    </Text>
                </>
            );
        }
        return null;
    };

    const handleCancelRepair = useCallback(async () => {
        if (notes === '') {
            alert('Add notes before cancel repair')
            return
        }
        await sendEditRequest(
            `${baseUrl}/repair/cancel-at/${data?._id}`,
            {
                itemId: id,
                note: notes
            },
        )
            .then(() => {
                getInventoryItem()
                setNotes('');
                setIsNotes(true)
                toast.error('Repair Canceled', { hideProgressBar: true })
            })
            .catch((error) => console.error(`Error: ${error}`))
    }, [data?._id, getInventoryItem, id, notes, sendEditRequest])

    const handleFinishRepair = useCallback(async () => {
        await sendEditRequest(
            `${baseUrl}/repair/finish-at/${data?._id}`,
            {
                repairId: data?.repair._id,
            },
        )
            .then(() => {
                toast.success('You Finished Repair', {
                    hideProgressBar: true,
                })
                addNotification({
                    message: 'Repair has been finished successfully',
                    type: 'success',
                })
                getInventoryItem()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }, [data?._id, data?.repair, getInventoryItem, sendEditRequest])

    const handleRestartRepair = useCallback(async () => {
        await sendEditRequest(
            `${baseUrl}/repair/restart-repair/${data?._id}`,
            {
                repairId: data?.repair,
            },
        )
            .then((response) => {
                getInventoryItem()
                toast.success(response?.data?.message, {
                    hideProgressBar: true,
                })
                addNotification({
                    message: 'Repair has been restarted successfully',
                    type: 'success',
                })
                setRerenderCodes((prev) => !prev);
            })

            .catch((error) => console.error(`Error: ${error}`))
    }, [data?._id, data?.repair, getInventoryItem, sendEditRequest])

    const handleRefetch = useCallback(() => {
        getInventoryItem()
    }, [getInventoryItem])

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full p-3">
                    <Row className="gap-x-16 flex flex-row items-center">
                        <p style={{
                            color:'#112D56'
                        }}
                            className='font-semibold text-3xl' 
                        >
                            {data?.model?.modelName}
                        </p>
                        {
                            repair?.finishAt ?
                                <TextBackground className='font-semibold' backgroundColor='b9green'>
                                    <Text
                                        colorV="white"
                                        variant="title"
                                        weightV="normal"
                                    >
                                        Finished
                                    </Text>
                                </TextBackground>
                                : repair?.cancelAt ? <TextBackground className='font-semibold bg-red'>
                                    <Text
                                        colorV="white"
                                        variant="title"
                                        weightV="normal"
                                    >
                                        Cancelled
                                    </Text>
                                </TextBackground>
                                    :
                                    <TextBackground className='bg-purple'>
                                        <Text
                                            colorV="white"
                                            variant="title"
                                            weightV="normal"
                                        >
                                            Ongoing
                                        </Text>
                                    </TextBackground>
                        }
                        {(repair?.finishAt || repair?.cancelAt) &&
                            <Button
                                onClick={handleRestartRepair}
                                backgroundColor="lgblue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    Restart repair
                                </Text>
                            </Button>}
                    </Row>
                    <Row className="my-4 items-center">
                        <div className="  gap-x-10 flex items-center">
                            <Text
                                style={{ width: 285 }}
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Repair in progress
                            </Text>
                            <div className="w-full bg-b9white rounded-full h-2.5  dark:bg-b9white">
                                <div
                                    className="bg-blue-600 h-2.5 rounded-full dark:bg-blue-500"
                                    style={{ width: repair?.finishAt || repair?.cancelAt ? '100%' : '50%' }}
                                ></div>
                            </div>
                            <Text
                                style={{ width: 280 }}
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Repair finished
                            </Text>
                        </div>
                    </Row>
                    <PrimaryCard className=" p-5 w-4/6">
                        <RowAlignCenterSpaced>
                            <Column className="text-center">
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    Storage
                                </Text>
                                <Text
                                    colorV="mid-blue"
                                    variant="heading"
                                    className=''
                                >
                                    {data?.storage}
                                </Text>
                            </Column>
                            <Column className="text-center">
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    IMEI
                                </Text>
                                <Text
                                    colorV="mid-blue"
                                    className='font-semibold'
                                    variant="heading"
                                >
                                    {data?.imei}
                                </Text>
                            </Column>
                            <Column className="text-center">
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    Time stamp
                                </Text>
                                <Text
                                    colorV="mid-blue"
                                    weightV="bold"
                                    variant="heading"
                                >
                                    {moment(data?.createdAt).format(
                                        'DD/MM/YYYY'
                                    )}
                                </Text>
                            </Column>
                            <Column className="text-center">
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    Stopwatch
                                </Text>
                                {getRepairDurationText()}
                            </Column>
                        </RowAlignCenterSpaced>
                    </PrimaryCard>
                    <RepairCodes refetch={handleRefetch} data={data} setIsNotes={isNotes}  handleNotes={handleCommentFromChild} />
                    <Flex className="gap-x-4 mt-4">
                        {
                            repair?.finishAt ?
                                <TextBackground className='font-semibold bg-slate-300 text-zinc-800 text-sm'>
                                    This repair is finished
                                </TextBackground>
                                : repair?.cancelAt ? <TextBackground className='font-semibold ' backgroundColor='b9white'>
                                    This repair is cancelled
                                </TextBackground>
                                    :
                                    <>
                                        <Button
                                            onClick={handleFinishRepair}
                                            backgroundColor="lgblue"
                                        >
                                            <Text
                                                colorV="white"
                                                variant="title"
                                                weightV="normal"
                                            >
                                                Finish repair
                                            </Text>
                                        </Button>
                                        <Button
                                            onClick={handleCancelRepair}
                                            backgroundColor="blue"
                                        >
                                            <Text
                                                colorV="white"
                                                variant="title"
                                                weightV="normal"
                                            >
                                                Cancel repair
                                            </Text>
                                        </Button>
                                    </>
                        }

                    </Flex>
                </PrimaryCard>
            </Flex>
        </>
    )
}
