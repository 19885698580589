import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { storageList, operationSystemList, myDevice, myVat } from '../../../../constant/Storage'
import { useFetch } from '../../../../hooks/useFetch'
import { Button } from '../../../base/Button'
import { Dropdown } from '../../../base/Dropdown'
import { ModalBox } from '../../../base/ModelBox'
import { TextField } from '../../../base/Textfield'
import { Column } from '../../../layout/Col'
import { Flex } from '../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { TextBackground } from '../../../base/TextBackground'
import { Text } from '../../../base/Text'
import { useAuth } from '../../../../utils/auth'
import { useEdit } from '../../../../hooks/useEdit'
import { addNotification } from '../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditItem: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [salePrice, setSalePrice] = useState('')
    const {sendEditRequest} = useEdit()

    const handleSalePrice = (newValue: any) => {
        setSalePrice(newValue)
    }
    useEffect(() => {
        setSalePrice(item?.['Sale Price'])
    }, [item])

    const handleSave = useCallback(async (e: any) => {
        e.preventDefault()
        if (!salePrice) {
            alert('Please, add sale price')
            return
        }
        await 
            sendEditRequest(
                `${baseUrl}/purchase/edit-sales-item/${item?.Id}`,
                {
                    imei: item?.IMEI,
                    salePrice,
                },
            )
            .then((response) => {
                toast.success(response?.data?.message, { hideProgressBar: true })
                addNotification({
                    message: 'Item has been updated successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) =>
                toast.error(error?.response?.data, {
                    hideProgressBar: true,
                })
            )
    },[item?.IMEI, item?.Id, onClose, salePrice, sendEditRequest])

    return (
        <ModalBox
            heading="Edit purchase"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[350px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        IMEI/Serial Number
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item.IMEI}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        Model
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item.Model}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        Storage
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item.Storage}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        VAT
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item.VAT}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Price
                    </Text>
                    <TextField
                        value={salePrice}
                        onChange={handleSalePrice}
                        placeholder="Enter price"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button onClick={handleSave} className='w-40' backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Save
                        </Text>
                    </Button>

                </Flex>
            </Column>
        </ModalBox>
    )
}
