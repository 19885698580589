import React, { useState, CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { PrimaryCard } from '../../components/compound/Page/PrimaryCard'
import Logo from '../../assets/logo.png'
import { Text } from '../../components/base/Text'
import { MyIcon } from '../../components/base/MyIcon'
import { Flex } from '../../components/layout/Flex'
import { useLocation } from 'react-router-dom'

interface NavItem {
    text: string
    icon: string
    iconSize?: number
    to: string
    subNavigation?: React.ReactNode
}

interface NavbarProps {
    initialNavItems: NavItem[]
    showLogo?: boolean
    heading?: string
    className?: string
}

export const Navbar: React.FC<NavbarProps> = ({
    initialNavItems,
    showLogo = true,
    heading,
    className,
}) => {
    const [navItems] = useState<NavItem[]>(initialNavItems)
    const location = useLocation()
    const currentPath = location.pathname

    const navItemsStyle: CSSProperties = {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        marginTop: '15px',
    }

    const navItemStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        fontSize: '18px',
        cursor: 'pointer',
        color: 'white',
        borderLeft: '2px solid #F2F0ED',
    }

    const navIconStyle: CSSProperties = {
        margin: '0 15px 0 7px',
    }

    const activeItemStyle: CSSProperties = {
        borderLeft: '2px solid #3B65F6',
        transition: 'border 0.3s ease-in-out',
    }

    const isDashboard = currentPath === '/dashboard'

    const stickyStyle: CSSProperties = {
        position: 'relative',
        top: 0,
    }
    const currentPathSegment = currentPath?.split('/')[1]
    return (
        <div style={stickyStyle}>
            <PrimaryCard
                style={{
                    width: isDashboard ? '' : 70,
                    boxSizing: 'border-box',
                }} // Adjust the width based on the dashboard path
                className={`px-3 ${className} shadow-none rounded`}
            >
                {showLogo && isDashboard && (
                    <div className="logo">
                        <img alt="" className="h-16 w-28" src={Logo} />
                    </div>
                )}

                {heading && isDashboard && (
                    <div className="mb-5">
                        <Text colorV="blue" variant="heading" weightV="bold">
                            {heading}
                        </Text>
                    </div>
                )}

                <ul style={navItemsStyle} className="nav-items">

                    {navItems?.map((item: NavItem, index: number) => (
                        item && (
                            <React.Fragment key={index}>
                                <li
                                    style={{
                                        ...navItemStyle,
                                        color: 'white',
                                        ...(currentPathSegment === item?.to?.split('/')[1]
                                            ? activeItemStyle
                                            : {}),
                                    }}
                                    className="nav-item"
                                >
                                    <Link
                                        to={item?.to}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'blue',
                                        }}
                                    >
                                        <Flex>
                                            <span
                                                style={{
                                                    ...navIconStyle,
                                                    color: isDashboard
                                                        ? currentPathSegment ===
                                                            item?.to?.split('/')[1]
                                                            ? 'lgblue'
                                                            : 'white'
                                                        : 'white',
                                                }}
                                                className="nav-icon"
                                            >
                                                <MyIcon
                                                    size={item?.iconSize}
                                                    name={item?.icon}
                                                />
                                            </span>
                                            {isDashboard ? (
                                                <Text colorV="blue" variant="title">
                                                    {item?.text}
                                                </Text>
                                            ) : null}
                                        </Flex>
                                    </Link>
                                </li>
                            </React.Fragment>
                        )
                    ))}



                </ul>
            </PrimaryCard>
        </div>
    )
}
