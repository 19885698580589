import React from 'react';
import ReactPaginate from 'react-paginate';

interface PaginatedItemsProps {
    pageCount: number;
    onPageChange: (selectedPage: number) => void;
}

const PaginatedItems: React.FC<PaginatedItemsProps> = ({ pageCount, onPageChange }) => {
    const handlePageClick = (event: { selected: number }) => {
        const selectedPage = event.selected + 1; // Adjust for zero-based index
        onPageChange(selectedPage);
    };

    console.log(pageCount);

    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={8}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            breakClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            activeLinkClassName="active"
        />
    );
};

export default PaginatedItems;



// import React, { useState } from 'react'

// export const Pagination = ({ pageCount, onPageChange }: any) => {
//     const [page, setPage] = useState(1)

//     const handleNext = () => {
//         if (page < pageCount) {
//             setPage(page + 1)
//             onPageChange(page + 1)
//         }
//     }

//     const handlePrevious = () => {
//         if (page > 1) {
//             setPage(page - 1)
//             onPageChange(page - 1)
//         }
//     }

//     const handlePageClick = (pageNumber) => {
//         setPage(pageNumber)
//         onPageChange(pageNumber)
//     }

//     return (
//         <div className="flex mt-10 w-full justify-center">
//             <nav aria-label="Page navigation example">
//                 <ul className="list-style-none flex">
//                     <li className="mr-3">
//                         <button
//                             disabled={page === 1}
//                             className={`relative block ${
//                                 page === 1
//                                     ? 'disabled:opacity-25 bg-gray-300 text-gray-600'
//                                     : 'bg-b9blue hover:bg-b9white hover:text-b9blue hover: text-white'
//                             } rounded py-1.5 px-3 text-sm transition-all text-white`}
//                             onClick={handlePrevious}
//                         >
//                             Previous
//                         </button>
//                     </li>
//                     {Array.from({ length: pageCount }, (_, index) => (
//                         <li key={index}>
//                             <button
//                                 className={`relative mx-2 block focus:bg-b9blue active:bg-b9blue dark:hover:bg-b9blue rounded py-1.5 px-3 text-sm transition-all duration-300 hover:bg-b9blue hover:text-white ${
//                                     page === index + 1
//                                         ? 'bg-b9blue active-page text-white'
//                                         : ''
//                                 }`}
//                                 onClick={() => handlePageClick(index + 1)}
//                             >
//                                 {index + 1}
//                             </button>
//                         </li>
//                     ))}
//                     <li className="ml-3">
//                         <button
//                             disabled={page === pageCount}
//                             className={`relative block ${
//                                 page === pageCount
//                                     ? 'disabled:opacity-25 bg-gray-300 text-gray-600'
//                                     : 'bg-b9blue hover:bg-b9white hover:text-b9blue text-white'
//                             } rounded py-1.5 px-3 z-1 text-sm transition-all text-white`}
//                             onClick={handleNext}
//                         >
//                             Next
//                         </button>
//                     </li>
//                 </ul>
//             </nav>
//         </div>
//     )
// }
