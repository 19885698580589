
type Notification_type = 'info' | 'success' | 'error' | 'warning';

export interface Notification_data {
    message: string;
    type: Notification_type;
    createdAt?: Date;
}

export function addNotification(notification: Notification_data) {
    notification.createdAt = new Date();
    const notifications = JSON.parse(localStorage.getItem('notifications') || '[]');
    notifications.push(notification);
    localStorage.setItem('notifications', JSON.stringify(notifications));
}

export function removeNotification(index: number) {
    const notifications = JSON.parse(localStorage.getItem('notifications') || '[]');
    notifications.splice(index, 1);
    localStorage.setItem('notifications', JSON.stringify(notifications));
}

export function getNotifications() {
    const notifications = JSON.parse(localStorage.getItem('notifications') || '[]');
    console.log(notifications);
    return notifications;
}
export function clearNotifications() {
    localStorage.removeItem('notifications');
}