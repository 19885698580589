import React, { ReactNode, MouseEvent } from 'react';
import { Flex } from '../../layout/Flex';

type BackgroundColorProps =
  | 'b9blue'
  | 'b9green'
  | 'b9purple'
  | 'b9mid-blue'
  | 'b9yellow'
  | 'b9white'
  | 'base01';

interface CustomComponentProps {
  backgroundColor?: BackgroundColorProps;
  children: ReactNode; // Allow any HTML node as children
  style?: React.CSSProperties; // Allow custom styling
  className?: string;
  height?: string;
  onClick?: (event: MouseEvent) => void; // Optional onClick handler
}

export const TextBackground: React.FC<CustomComponentProps> = ({
  backgroundColor = 'b9green', // Default to 'b9green' for green background
  children, // Accept any HTML node as children
  style, // Custom style prop
  className,
  height = '40px',
  onClick,
}) => {
  const containerStyle: React.CSSProperties = {
    ...style, // Merge custom style with component styles
    height: height, // Set the height to 40px
  };

  return (
    <Flex>
      <div
        className={`bg-${backgroundColor} ${className} rounded-lg px-2 py-2 flex items-center`}
        style={containerStyle}
        onClick={onClick} // Attach onClick handler if provided
      >
        {children}
      </div>
    </Flex>
  );
};
