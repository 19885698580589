import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Head from './Head';
import { ReportRMA } from './ReportRMA';
import { useBreadcrumb } from '../../../../../hooks/useBreadcrumb';
import { useFetch } from '../../../../../hooks/useFetch';
import Header from '../../../../shared/Header/Header';
import { Breadcrumb } from '../../../../base/Breadcrumb';
import { PrimaryCard } from '../../../../compound/Page/PrimaryCard';
import { Column } from '../../../../layout/Col';
import { Flex } from '../../../../layout/Flex';
import { Row } from '../../../../layout/Row';
import { ProposeRepair } from './ProposeRepair';
import { Text } from '../../../../base/Text';
import { DropdownButtons } from '../../../../base/DropdownButton';

const baseUrl = process.env.REACT_APP_baseUrl;

export const CustomerPurchaseItemsTable = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null); // Track selected action
  const [isModalOpen, setModalOpen] = useState(false);
  const [claimed, setClaimed] = useState(false)

  const { id } = useParams();
  const breadcrumbItems = useBreadcrumb();

  const { getRequest: getPurchaseItems, data } = useFetch(
    `${baseUrl}/purchase/get-items-purchase/${id}`
  );

console.log({data})

  useEffect(() => {
    getPurchaseItems();
  }, [getPurchaseItems]);

  const openModal = (action, item) => {
    setSelectedAction(action); // Set the selected action
    setSelectedItem(item); // Set the selected item
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAction(null);
    setSelectedItem(null);
    setModalOpen(false);
    getPurchaseItems();
  };

  return (
    <>
      <Flex className="mb-3 ml-5">
        <Column className="w-full">
          <Header />
          <Column className="">
            <Row className="my-1.5 w-full">
              <Breadcrumb items={breadcrumbItems} separator="/" />
            </Row>
            <Head claimed={claimed} onClose={closeModal} refetchData={getPurchaseItems} itemsData={data} />
          </Column>
          <PrimaryCard className="w-full">
            <table className="min-w-full text-left w-full table-auto text-b9blue bg-transparent">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2">IMEI/Serial number</th>
                  <th className="px-4 py-2">Model</th>
                  <th className="px-4 py-2">Storage</th>
                  <th className="px-4 py-2">VAT</th>
                  <th className="px-4 py-2">Purchase Price</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.items?.map((item, rowIndex) => {
                  return (
                    <tr
                      className="cursor-pointer"
                      key={rowIndex}
                      onClick={() => openModal('View', item)}
                    >
                      <td className="px-4 py-2">{item?.imei}</td>
                      <td className="px-4 py-2">{item?.model?.modelName}</td>
                      <td className="px-4 py-2">{item?.storage}</td>
                      <td className="px-4 py-2">{item?.vat}</td>

                      <td className="px-4 py-2">{item?.salePrice}</td>
                      <td
                        onClick={(e) => e.stopPropagation()}
                        className="px-4 py-2 relative"
                      >
                        {item?.rmaItemsStatus ? (
                          <Text colorV='green' weightV='normal' variant='title'>{item?.rmaItemsStatus}</Text>
                        ) : (
                          <DropdownButtons
                            buttonLabel="View"
                            items={['Report RMA', 'Propose repair']}
                            onItemClick={(selectedAction) =>
                              openModal(selectedAction, { ...item, saleStatus: status })
                            }
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </PrimaryCard>
        </Column>
      </Flex>

      {isModalOpen && selectedAction === 'Report RMA' && (
        <ReportRMA setClaimed={setClaimed} data={data} item={selectedItem} onClose={closeModal} />
      )}

      {isModalOpen && selectedAction === 'Propose repair' && (
        <ProposeRepair setClaimed={setClaimed} data={data} item={selectedItem} onClose={closeModal} />
      )}
    </>
  );
};
