import React, { HTMLProps } from 'react'

interface CardProps extends HTMLProps<HTMLDivElement> {
    children: React.ReactNode
}

const Card: React.FC<CardProps> = ({ children, className }) => {
    return <div className={`md:p-4 rounded-lg ${className}`}>{children}</div>
}

export default Card
