import React, { useState, CSSProperties } from 'react';
import { Flex } from '../../../../../layout/Flex';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Text } from '../../../../../base/Text';

interface NavbarProps {
    navItems: string[];
    className?: string;
    activeItem: string;
    onItemClick: (item: string) => void;
}

export const LabelBar: React.FC<NavbarProps> = ({ navItems, className, activeItem, onItemClick }) => {
    const navItemsStyle: CSSProperties = {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        marginTop: '15px',
    };

    const navItemStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '13px',
        fontSize: '18px',
        cursor: 'pointer',
        color: 'white',
        paddingLeft: '10px', // Add padding for the gap
        position: 'relative', // Add relative positioning
        opacity: 0.5, // Default opacity
        transition: 'opacity 0.3s ease-in-out',
    };

    const dotStyle: CSSProperties = {
        content: '""',
        position: 'absolute',
        left: '0',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: '#112D56', // Blue color
        top: '50%',
        transform: 'translateY(-50%)',
    };

    const activeItemStyle: CSSProperties = {
        opacity: 1,
    };

    const textMarginStyle: CSSProperties = {
        marginLeft: '10px', // Adjust the margin as needed
    };

    const handleClick = (index: number) => {
        onItemClick(navItems[index]);
    };

    return (
        <div>
            <PrimaryCard
                style={{
                    width: 240,
                    boxSizing: 'border-box',
                }}
                className={`px-3 ${className} shadow-none rounded`}
            >
                <ul style={navItemsStyle} className="nav-items">
                    {navItems.map((item, index) => (
                        <li
                            key={index}
                            style={{
                                ...navItemStyle,
                                ...(activeItem === item ? activeItemStyle : {}),
                            }}
                            onClick={() => handleClick(index)}
                        >
                            <Flex>
                                <span style={dotStyle}></span>
                                <Text colorV="blue" variant="title" style={textMarginStyle}>
                                    {item}
                                </Text>
                            </Flex>
                        </li>
                    ))}
                </ul>
            </PrimaryCard>
        </div>
    );
};
