import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../../base/Text'
import { TextBackground } from '../../../../../base/TextBackground'
import { TextArea } from '../../../../../base/Textarea'
import Select from 'react-select'
import { useEdit } from '../../../../../../hooks/useEdit'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import JsBarcode from 'jsbarcode'
import { generatePDF } from '../../../../../shared/GenerateLabel'
import { useFetch } from '../../../../../../hooks/useFetch'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
export const ReportRmaModal: React.FC<{ onClose: () => void; item: any; data: any }> = ({ onClose, item, data }) => {
    const [comment, setComment] = useState<any>('')
    const [acceptance, setAcceptance] = useState<any>()
    const [event, setEvent] = useState<string>('')
    const [itemData, setItemData] = useState<any>()
    const barCode = useRef<HTMLDivElement>(null);
    const [isSave, setIsSave] = useState(false)
    const [isSavePrint, setIsSavePrint] = useState(false)
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany
    const { sendEditRequest } = useEdit()
    useEffect(() => {
        const itemData = data?.items?.find(myItem => myItem._id === item?.id);
        setItemData(itemData)
    }, [data?.items, item?.id])

    const getSortingLogic = useCallback(async () => {
        await axios
            .get(`${baseUrl}/sorting-logic/get-sorting-logic-events`, {
                headers: {
                    Authorization: token,
                    Company: company._id
                },
            })
            .then((data) => {
                console.log({ data });
            })
            .catch((error) => {
                toast.error(error.response?.data?.message, {
                    hideProgressBar: true,
                })
            })
    }, [company._id, token])

    useEffect(() => {
        getSortingLogic()
        return () => { }
    }, [getSortingLogic])

    useEffect(() => {
        if (itemData) {
            console.log({ itemData });
            setAcceptance(itemData.acceptance)
            setEvent(itemData?.codes?.length <= 0 ? "" : itemData.sortingEventKey)
            setComment(itemData?.comment !== "undefined" ? itemData?.comment : "",
            )
        }
    }, [data.codes?.length, data.comment, data.sortingEventKey, item?.comment, itemData])
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);
    const handleAcceptanceChange = (event: any) => {
        setAcceptance(event.target.value)
        setEvent(event.target.value)
    }

    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }
    const handleSave = async (e: any) => {
        setIsSave(true)
        await sendEditRequest(
            `${baseUrl}/rma/update-rma-item-status/${itemData?._id}`,
            {
                comment,
                acceptance,
                event,
            },
        )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Item has been updated successfully',
                    type: 'success',
                })
                setComment('')
                setAcceptance("")
                onClose()
                setIsSave(false)
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data}`, {
                    hideProgressBar: true,
                })
            })
    }
    console.log({ itemData });
    const handleSavePrint = useCallback(
        async () => {
            setIsSavePrint(true)
            await sendEditRequest(
                `${baseUrl}/rma/update-rma-item-status/${itemData._id}`,
                {
                    comment,
                    acceptance,
                    event,
                },
            )
                .then((response) => {
                    console.log({ response });
                    setIsSavePrint(false)
                    const item = response?.data
                    JsBarcode('#barcode', `${response?.data?.item?.imei}`, {
                        lineColor: 'black',
                        textAlign: 'center',
                        width: 1,
                        background: 'transparent',
                        height: 40,
                        displayValue: false,
                        xmlDocument: document,
                    });
                    toast.success('Updated', { hideProgressBar: true })
                    addNotification({
                        message: 'Item has been updated successfully',
                        type: 'success',
                    })
                    generatePDF(item, labelsData, barCode)
                })
        },
        [sendEditRequest, itemData?._id, comment, acceptance, event, labelsData]
    )
    return (
        <ModalBox
            heading={item?.IMEI}
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[500px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            {item?.Model}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Storage
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            {item?.Storage}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        VAT
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            {item?.Vat}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Sale price
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            {item?.Price}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Reason of return
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            {itemData?.reasonOfReturn}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Codes
                    </Text>
                    <div style={{ width: "18rem" }}>
                        <TextBackground className='w-full' backgroundColor='b9white'>
                            <Text colorV="blue" variant="title" weightV="normal">
                                {itemData?.codes.toString()}
                            </Text>
                        </TextBackground>
                        {/* {items?.length > 0 &&
                            items?.length === prevCodes?.length && (
                                <div className="w-full">
                                    <Select
                                        defaultValue={items}
                                        options={codeDescription}
                                        onChange={handleCodesChange}
                                        isMulti
                                    />
                                </div>
                            )}
                        {prevCodes?.length === 0 && (
                            <div className="w-full">
                                <Select
                                    defaultValue={items}
                                    options={codeDescription}
                                    onChange={handleCodesChange}
                                    isMulti
                                />
                            </div>
                        )} */}
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status
                    </Text>

                    <div style={{ width: '18rem' }}>
                        <select
                            id="acceptance"
                            value={acceptance}
                            onChange={handleAcceptanceChange}
                            className="w-full hover:cursor-pointer  py-2 px-1"                        >
                            <option value="" >Status</option>
                            <option value="rma_accepted">Accepted</option>
                            <option value="rma_declined">Declined</option>
                            <option value="warranty_repair">Warranty repair</option>
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Stauts
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            {event}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Comment
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <TextArea
                            className='w-full'
                            value={comment}
                            onChange={handleCommentChange}
                            placeholder="write here ..."
                        />
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isSave ? "Saving" : "Save"}
                        </Text>
                    </Button>
                    <Button onClick={handleSavePrint} className='w-36' backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                           {isSavePrint ? "Saving" : "Save & Print"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </ModalBox>
    )
}
