import React, { useCallback, useEffect, useRef, useState } from 'react'
import Head from './components/Head'
import { Filter } from './components/Filter'
import InventoryTable from './components/InventoryTable'
import FilterTable from './components/FilterTable'
import { useFetch } from '../../../../hooks/useFetch'
import axios, { CancelTokenSource } from 'axios' // Import CancelTokenSource from axios
import { toast } from 'react-toastify'
import { useAuth } from '../../../../utils/auth'
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess'
import { Loader } from '../../../base/Loader'
const baseUrl = process.env.REACT_APP_baseUrl

export default function Inhouse() {
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsData, setItemsData] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [selectedParentCodes, setSelectedParentCodes] = useState<string[]>([])
    const [selectedParentEvents, setSelectedParentEvents] = useState<string[]>(
        []
    )
    const [selectedParentOptions, setSelectedParentOptions] = useState<any>({})
    const [filteredData, setFilteredData] = useState(null) // State to hold filtered data
    const [isFilterActive, setIsFilterActive] = useState(false)
    const [search, setSearch] = useState<string>('')
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany
    const viewInventoryCheck = auth?.selectedProfile?.access?.some(
        (acc) =>
            acc?.module === AccessEnums.InHouseInventory &&
            acc.permission.includes(AccessPermissions.View)
    )
    const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null) // Ref to store the cancel token source

    useEffect(() => {
        return () => {
            cancelPreviousRequest()
        }
    }, [])

    const buildQueryParams = () => {
        const params = new URLSearchParams()
        params.append('page', currentPage.toString())
        if (selectedParentCodes.length > 0)
            params.append('codes', selectedParentCodes.join(','))
        if (selectedParentEvents.length > 0)
            params.append('events', selectedParentEvents.join(','))
        if (
            selectedParentOptions &&
            Object.keys(selectedParentOptions).length > 0
        ) {
            Object.keys(selectedParentOptions).forEach((key) => {
                if (selectedParentOptions[key]) {
                    params.append(key, selectedParentOptions[key])
                }
            })
        }
        return params.toString()
    }

    const { getRequest: getInventoryItems, data: fetchedItemsData } = useFetch(
        `${baseUrl}/inventory/get-inhouse-items?${buildQueryParams()}`
    )

    const { getRequest: getDownloadItems, data: downloadData } = useFetch(
        `${baseUrl}/inventory/get-inventory-pdf`
    )

    useEffect(() => {
        getInventoryItems()
        getDownloadItems()
    }, [getDownloadItems, getInventoryItems])

    useEffect(() => {
        if (fetchedItemsData) {
            setItemsData(fetchedItemsData)
        }
    }, [fetchedItemsData])

    const totalPages = fetchedItemsData?.pagination?.pageCount

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber)
    }

    const handleSearchResult = useCallback(
        async (searchTerm: string, cancelTokenSource: CancelTokenSource) => {
            try {
                setSearch(searchTerm)
                if (searchTerm === '') {
                    getInventoryItems()
                    setFilteredData(null)
                    return
                } else {
                    setLoading(true)
                    cancelPreviousRequest()
                    cancelTokenSourceRef.current = axios.CancelToken.source()
                    const response = await axios.post(
                        `${baseUrl}/inventory/inhouse-search`,
                        { imei: searchTerm },
                        {
                            headers: {
                                Authorization: token as string,
                                Company: company._id,
                            },
                            cancelToken: cancelTokenSourceRef.current.token, // Pass the cancel token to the request config
                        }
                    )
                    setFilteredData(response?.data?.items)
                    setItemsData(null)
                    setLoading(false)
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message)
                } else {
                    console.error(error)
                    toast.error('Error in search')
                }
                setLoading(false)
            }
        },
        [company._id, getInventoryItems, token]
    )

    const cancelPreviousRequest = () => {
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel('Previous request canceled')
        }
    }

    const refetch = useCallback(() => {
        console.log('Refetching data')
        getInventoryItems()
    }, [getInventoryItems])

    useEffect(() => {
        // Determine if any filters are active
        const filterActive =
            selectedParentCodes.length > 0 ||
            selectedParentEvents.length > 0 ||
            Object.keys(selectedParentOptions).length > 0 ||
            search != ''
        setIsFilterActive(filterActive)
        console.log('selectedParentOptions', selectedParentOptions)
    }, [
        search,
        selectedParentCodes,
        selectedParentEvents,
        selectedParentOptions,
        filteredData,
    ])
    return (
        <>
            <Head
                refetch={refetch}
                activeData={
                    isFilterActive
                        ? filteredData?.length
                            ? { items: filteredData }
                            : itemsData
                        : downloadData
                }
            />
            <Filter
                handleSearchResult={handleSearchResult}
                setSelectedParentOptions={setSelectedParentOptions}
                setFilteredData={setFilteredData}
                setSelectedParentEvents={setSelectedParentEvents}
                setSelectedParentCodes={setSelectedParentCodes}
                itemsData={itemsData}
            />
            {loading ? (
                <Loader />
            ) : (
                <>
                    {viewInventoryCheck && (
                        <>
                            <InventoryTable
                                refetch={refetch}
                                itemsData={itemsData}
                                pageCount={totalPages}
                                handlePageChange={handlePageChange}
                                selectedParentOptions={selectedParentOptions}
                            />
                        </>
                    )}
                </>
            )}
        </>
    )
}
