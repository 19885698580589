import React, { useCallback, useEffect, useState } from 'react'
import { Flex } from '../../../../../layout/Flex'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { useFetch } from '../../../../../../hooks/useFetch'
import moment from 'moment'
import { MyIcon } from '../../../../../base/MyIcon'
import { Row } from '../../../../../layout/Row'
import { Text } from '../../../../../base/Text'
import { Button } from '../../../../../base/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { usePost } from '../../../../../../hooks/usePost'
const baseUrl = process.env.REACT_APP_baseUrl

export default function DeviceTable() {
    const auth = useAuth()
    const [isDownloading, setIsDownloading] = useState(false)
    const token = auth.user?.token?.token
    const { sendRequest } = usePost()
    const { getRequest: getItems, data } = useFetch(
        `${baseUrl}/stocktaking-imei/generated-report`
    )
    useEffect(() => {
        getItems()
    }, [getItems])

    const handleReportCSV = useCallback(async (itemsData) => {
        console.log({ itemsData })
        const csvArray = []
        if (itemsData.length > 0) {
            itemsData?.forEach((element) => {
                csvArray.push({
                    IMEI: element?.imei,
                    'Scanned By': element?.scannedBy?.email || 'N/A',
                    'Scan date':
                        `${moment(element?.scannedDate).format(
                            'DD/MM/YYYY'
                        )} - ${moment(element?.scannedDate).format(
                            'HH:mm:ss'
                        )}` || 'N/A',
                    'Last event': element?.previousEvent?.eventKey || 'N/A',
                    Status: element?.currentEvent?.eventKey || 'N/A',
                })
            })

            let csv = ' '
            for (let row = 0; row < csvArray?.length; row++) {
                const keysAmount = Object.keys(csvArray[row]).length
                let keysCounter = 0
                if (row === 0) {
                    for (const key in csvArray[row]) {
                        csv +=
                            key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                }
                keysCounter = 0
                for (const key in csvArray[row]) {
                    csv +=
                        csvArray[row][key] +
                        (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
                keysCounter = 0
            }
            const data = new Blob([csv], { type: 'text/csv' })
            saveCsv(
                data,
                `Stocktaking-device-${moment(itemsData?.scriptDate).format(
                    'DD.MM.YYYY'
                )}.csv`
            )
            setIsDownloading(false)
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [])

    const handleDownloadReport = useCallback(
        async (id) => {
            setIsDownloading(true)
            try {
                const response = await sendRequest(
                    `${baseUrl}/stocktaking-imei/data-report-date`,
                    {
                        reportId: id,
                    }
                )
                if (response) {
                    handleReportCSV(response?.data?.devices)
                }
                // You can handle the response here, for example, trigger a file download
                // using the response data.
            } catch (error) {
                toast.error(error.response?.data?.message, {
                    hideProgressBar: true,
                })
            }
        },
        [handleReportCSV, sendRequest]
    )

    function saveCsv(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = filename
        a.click()
    }

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <div className="overflow-x-auto w-full text-left">
                        <table className="min-w-full w-full table-auto text-b9blue bg-transparent">
                            <thead
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                    // Corrected to camelCase and added fallback fonts
                                }}
                            >
                                <tr>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily:
                                                'Inter Tight, sans-serif',
                                        }}
                                        className="px-4 py-2"
                                    >
                                        Date
                                    </th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily:
                                                'Inter Tight, sans-serif',
                                        }}
                                        className="px-4 py-2"
                                    >
                                        Report
                                    </th>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily:
                                                'Inter Tight, sans-serif',
                                        }}
                                        className="px-4 py-2"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, rowIndex) => (
                                    <tr
                                        className="cursor-pointer"
                                        key={rowIndex}
                                    >
                                        <td className="px-4 py-2">
                                            <Text
                                                colorV="blue"
                                                weightV="normal"
                                                variant="title"
                                            >
                                                {moment(item?.createdAt).format(
                                                    'DD.MM.YYYY'
                                                )}
                                            </Text>
                                        </td>
                                        <td className="px-4 py-2">
                                            <Text
                                                colorV="mid-blue"
                                                weightV="normal"
                                                variant="body"
                                            >
                                                Stocktaking - device -{' '}
                                                {moment(item?.createdAt).format(
                                                    'DD.MM.YYYY'
                                                )}
                                            </Text>
                                        </td>
                                        <td
                                            // onClick={(e) =>
                                            //     e.stopPropagation()
                                            // }
                                            className="px-4 py-2 relative"
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleDownloadReport(
                                                        item?._id
                                                    )
                                                }
                                                backgroundColor="transparent"
                                            >
                                                <Row className="gap-x-3">
                                                    <MyIcon
                                                        size={20}
                                                        name="cloud"
                                                    />
                                                    <Text
                                                        colorV="blue"
                                                        weightV="normal"
                                                        variant="body"
                                                    >
                                                        Download(xlsx)
                                                    </Text>
                                                </Row>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </PrimaryCard>
            </Flex>
        </>
    )
}
