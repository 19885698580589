import React, { useEffect, useState } from 'react';
import { SearchBar } from '../../base/Searchbar';
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced';
import { Profile } from '../../shared/Profile';
import { CardGraph } from './CardGraph';
import { ValueOfSale } from './ValueOfSale';
import { SalesTable } from './SaleTable';
import { Row } from '../../layout/Row';
import { Stocktaking } from './Stocktaking';
import { useFetch } from '../../../hooks/useFetch';

const baseUrl = process.env.REACT_APP_baseUrl;

export const Dashboard = () => {
    const { getRequest: getItems, data } = useFetch(`${baseUrl}/report/dashboard-report`);
    const { getRequest: getOrders, data: purchaseData } = useFetch(`${baseUrl}/purchase/recent-company-purchase-orders`);

    useEffect(() => {
        getItems();
        getOrders()
    }, [getItems, getOrders]);

    const [chartData, setChartData] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [inHouseChartData, setInHouseChartData] = useState([]);
    const [inHouseChartLabels, setInHouseChartLabels] = useState([]);
    const [totalValueChartData, setTotalValueChartData] = useState([]);
    const [totalValueChartLabels, setTotalValueChartLabels] = useState([]);
    const [salesChartData, setSalesChartData] = useState([]);
    const [salesChartLabels, setSalesChartLabels] = useState([]);
    const [valueOfSaleChartData, setValueOfSaleChartData] = useState([]);
    const [valueOfSaleChartLabels, setValueOfSaleChartLabels] = useState([]);
    useEffect(() => {
        if (data) {
            const dailyCounts = data.dailyCounts;
            const daysOfWeek = Object.keys(dailyCounts);
            const counts = Object.values(dailyCounts);
            setChartLabels(daysOfWeek);
            setChartData(counts);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            const dailyCounts = data.dailyCounts;
            const daysOfWeek = Object.keys(dailyCounts);
            const inHouseCounts = daysOfWeek.map(day => dailyCounts[day].inHouseItemCount);
            const inHouseTotalValues = daysOfWeek.map(day => dailyCounts[day].inHouseTotalPurchasePrice);
            const saleCounts = daysOfWeek.map(day => dailyCounts[day].setOnSaleCount);
            const valueOfSaleData = daysOfWeek.map(day => dailyCounts[day].valueOfSale);

            setInHouseChartLabels(daysOfWeek);
            setInHouseChartData(inHouseCounts);
            setTotalValueChartLabels(daysOfWeek);
            setTotalValueChartData(inHouseTotalValues);
            setSalesChartLabels(daysOfWeek);
            setSalesChartData(saleCounts);
            setValueOfSaleChartLabels(daysOfWeek);
            setValueOfSaleChartData(valueOfSaleData);
        }
    }, [data]);

    return (
        <>
            <RowAlignCenterSpaced className='mt-5 gap-x-3 w-full'>
                <CardGraph heading="In-house inventory units" chartData={inHouseChartData} chartLabels={inHouseChartLabels} chartType="line" color="#45948A" gColor="rgba(69, 148, 138, 0)" />
                <CardGraph heading="Total in-house value" chartData={totalValueChartData} chartLabels={totalValueChartLabels} chartType="line" color="#5748E6" gColor="rgba(87, 72, 230, 0)" />
                <CardGraph heading="Sales inventory units" chartData={salesChartData} chartLabels={salesChartLabels} chartType="line" color="#E8C877" gColor="rgba(232, 200, 119, 0)" />
            </RowAlignCenterSpaced>

            <Row className=' mt-3 gap-x-3 w-full'>
                <ValueOfSale chartData={valueOfSaleChartData} chartLabels={valueOfSaleChartLabels} chartType="line" color="#3B65F6" />
                <SalesTable purchaseOrder={purchaseData} />
            </Row>
            <Row className='mt-3 gap-x-3'>
                {/* <CardGraph heading="Average stock age" chartData={chartData} chartLabels={chartLabels} chartType="line" /> */}
                <Stocktaking />
            </Row>
        </>
    );
};
