import React, { useState } from 'react';
import { Flex } from '../../../../layout/Flex';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../base/Text';
import { Button } from '../../../../base/Button';
import { Next } from '../Next';

export default function Head({ itemsData, onClose, claimed }: any) {
  const [isNext, setIsNext] = useState(false);

  const handleNext = () => {
    setIsNext(true);
  };

  const handleCloseNext = () => {
    setIsNext(false);
    if (onClose) {
      onClose();
    }
  };
  console.log({claimed});
  return (
    <Flex className="pb-4 pt-2">
      <RowAlignCenterSpaced className="gap-x-5 w-full">
        <Text colorV="blue" variant="head" weightV="bold">
          Purchase order <span className='ml-9'></span> {itemsData?.saleBatchNumber}
        </Text>
       {claimed && <Button onClick={handleNext} backgroundColor="green">
          <Text colorV="white" variant="title" weightV="normal">
            Next
          </Text>
        </Button>}
      </RowAlignCenterSpaced>
      {isNext &&  <Next onClose={handleCloseNext} data={itemsData} />}
    </Flex>
  );
}
