import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Icon } from '../../../../base/Icon';
import { MyIcon } from '../../../../base/MyIcon';
import { SearchBar } from '../../../../base/Searchbar';
import { Column } from '../../../../layout/Col';
import { Flex } from '../../../../layout/Flex';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../base/Text';

type FilterProps = {
    onDataChange: (data: any) => void;
    itemsData: any[];
    refetch: () => void;
};

export const Filter: React.FC<FilterProps> = ({ onDataChange, itemsData, refetch }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleButtonClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCloseIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation(); // Stop the event propagation to prevent closing the dropdown
        setShowDropdown(false);
    };

    const handleDocumentClick = (e: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setShowDropdown(false);
        }
    };

    const handleOptionsChange = useCallback(
        (key: string, value: string) => {
            if (key === 'Supplier') {
                setSelectedCustomer(value);
            } else if (key === 'Invoice') {
                setSelectedStatus(value);
            } else if (key === 'Status') {
                setSelectedStatus(value);
            }
            const filteredData = itemsData.filter(item => {
                if (key === 'Supplier' && value !== '') {
                    return item.supplier?._id === value;
                } else if (key === 'Invoice' && value !== '') {
                    return item.invoiceNumber === value;
                } else if (key === 'Status' && value !== '') {
                    return item?.sortingEvent?.name === value;
                }
                return true;
            });
            onDataChange(filteredData);
        },
        [itemsData, onDataChange]
    );


    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showDropdown]);

    const uniqueSupplierIds = Array.from(new Set(itemsData?.map(item => item.supplier?._id)));
    const uniqueSupplier = uniqueSupplierIds?.map(id => itemsData.find(item => item.supplier?._id === id)?.supplier);
    const uniqueEvents = Array.from(new Set(itemsData?.map(item => item?.sortingEvent?.name)));

    return (
        <Flex className="relative">
            <RowAlignCenter className="gap-x-5">
                <div ref={dropdownRef} className="relative ">
                    <div
                        onClick={handleButtonClick}
                        className={`cursor-pointer p-2 px-3 rounded-lg ${selectedCustomer !== '' || selectedStatus !== '' ? 'bg-b9green' : 'bg-b9midblue'}`}

                    >
                        <div className="flex items-center gap-x-4 w-28">
                            <MyIcon name="filter" color="white" />
                            <Text colorV="white" variant="title" weightV="normal">
                                Filter
                            </Text>
                        </div>
                    </div>
                    {showDropdown && (
                        <div className="fixed w-60 top-[calc(100% + 5px)] right-4 mt-2 w-full rounded z-40 flex flex-row justify-end">
                            <div className=' bg-white rounded-md pt-1 shadow-lg'>
                                <div className="flex justify-end pr-1 ">
                                    <span onClick={handleCloseIconClick} className='cursor-pointer' >
                                        <Icon name="cross" color="black" size={18} />
                                    </span>
                                </div>
                                <Column className="p-4 gap-y-3" >
                                    <RowAlignCenterSpaced className='justify-center'>
                                        <Text colorV='blue' variant='title' weightV='bold' >Supplier</Text>
                                        <select
                                            value={selectedCustomer}
                                            onChange={(e) => handleOptionsChange('Supplier', e.target.value)}
                                            className=" mb-2 py-1 w-36"
                                        >
                                            <option value="">Select supplier</option>
                                            {uniqueSupplier.map(customer => (
                                                <option key={customer?._id} value={customer?._id}>
                                                    {customer?.companyName}
                                                </option>
                                            ))}
                                        </select>
                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='justify-center'>
                                        <Text colorV='blue' variant='title' weightV='bold' >Invoice</Text>
                                        <select
                                            value={selectedStatus}
                                            onChange={(e) => handleOptionsChange('Invoice', e.target.value)}
                                            className="mb-2 py-1 w-36"
                                        >
                                            <option value="">Select invoice</option>
                                            {
                                                [...new Set(itemsData.map(item => item?.invoiceNumber))].map((invoiceNumber) => (
                                                    <option key={invoiceNumber} value={invoiceNumber}>{invoiceNumber}</option>
                                                ))
                                            }
                                        </select>

                                    </RowAlignCenterSpaced>
                                    <RowAlignCenterSpaced className='justify-center'>
                                        <Text colorV='blue' variant='title' weightV='bold' >Status</Text>
                                        <select
                                            value={selectedStatus}
                                            onChange={(e) => handleOptionsChange('Event', e.target.value)}
                                            className=" mb-2 py-1 w-36"
                                        >
                                            <option value="">Select status</option>
                                            {uniqueEvents.map((event, index) => (
                                                <option key={index} value={event}>{event}</option>
                                            ))}
                                        </select>

                                    </RowAlignCenterSpaced>
                                </Column>
                            </div>
                        </div>
                    )}
                </div>
            </RowAlignCenter>
            {showDropdown && (
                <div
                    className="fixed inset-0 bg-black opacity-30 z-0"
                    onClick={handleButtonClick}
                ></div>
            )}
        </Flex>
    );
};
