import React, { useEffect, useState } from 'react'
import { SalesSKUTable } from './component/SalesSKUTable'
import { Column } from '../../../../layout/Col'
import { HeadButtons } from './component/HeadButtons'
import { useFetch } from '../../../../../hooks/useFetch'
import { Row } from '../../../../layout/Row'
import { Filter } from './component/Filter'
const baseUrl = process.env.REACT_APP_baseUrl

export const SalesInventory = () => {
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

    const { getRequest: getSaleInventorySkuItems, data: itemsData } = useFetch(
        `${baseUrl}/sale-sku/get-sale-inventory-sku`
    )
    useEffect(() => {
        getSaleInventorySkuItems()
    }, [getSaleInventorySkuItems])

    const getSaleSKU = () => {
        getSaleInventorySkuItems()
    };
    const handleFilterChange = (data) => {
setFilteredItems(data)
     }
    return (
        <>
            <Column className='w-full'>
                <Row className='gap-x-3'>
                    <HeadButtons items={itemsData} handleSaleInventorySku={getSaleSKU} />
                    <Filter onDataChange={handleFilterChange} itemsData={itemsData} />
                </Row>
                <SalesSKUTable items={filteredItems?.length > 0 ? filteredItems : itemsData} handleSaleInventorySku={getSaleSKU} />
            </Column>
        </>
    )
}
