import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TopHeader from '../components/TopHeader'
import { managersList } from '../constants/mangersList'
import { toast } from 'react-toastify'
import { useAuth } from '../utils/auth'
import { useFetch } from '../hooks/useFetch'
import { addNotification } from '../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md mt-3 appearance-none relative block w-96  px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

export default function EditUser() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    const [currency, setCurrency] = useState('')
    const [managerEmail, setManagerEmail] = useState('')
    const [roles, setRoles] = useState([])
    const [user, setUser] = useState<any>()
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { id } = useParams()
    const navigate = useNavigate()

    const handleInputChange = (e: any) => {
        e.preventDefault()
        const { id, value } = e.target
        if (id === 'firstName') {
            e.preventDefault()
            setFirstName(value)
        }
        if (id === 'lastName') {
            e.preventDefault()
            setLastName(value)
        }
        if (id === 'email') {
            e.preventDefault()
            setEmail(value)
        }
        if (id === 'currency') {
            e.preventDefault()
            setCurrency(value)
        }
        if (id === 'companyName') {
            e.preventDefault()
            setCompany(value)
        }
        if (id === 'managerEmail') {
            e.preventDefault()
            setManagerEmail(value)
        }
    }

    const handleChange = useCallback(
        (e) => {
            const isChecked = e.target.checked
            if (isChecked) {
                setRoles([...roles, e.target.value])
            } else {
                const index = roles.indexOf(e.target.value)
                const rolesNew = [...roles]
                rolesNew.splice(index, 1)
                setRoles(rolesNew)
            }
        },
        [roles]
    )

    const handleManagerChange = (e: any) => {
        setManagerEmail(e.target.value)
    }

    const handleCurrencyChange = (e: any) => {
        setCurrency(e.target.value)
    }

    const { getRequest: getProfile, data: userData } = useFetch(
        `${baseUrl}/user/data`
    )

    useEffect(() => {
        getProfile()
    }, [getProfile])

    useEffect(() => {
        const user = userData?.find((usr: any) => usr._id === id)
        setUser(user)
        setFirstName(user?.firstName)
        setLastName(user?.lastName)
        setEmail(user?.email)
        setCompany(user?.companyName)
        setCurrency(user?.currency)
        setManagerEmail(user?.accountManagerEmail)
        setRoles(user?.role)
    }, [id, userData])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        await axios
            .put(
                `${baseUrl}/user/edit/${id}`,
                {
                    firstName,
                    lastName,
                    email,
                    company,
                    currency,
                    roles,
                    managerEmail,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                addNotification({
                    message: 'User Updated Successfully',
                    type: 'success',
                })
                toast.success('User Updated Successfully', {
                    hideProgressBar: true,
                })
            })
            .catch((error) => console.error(`Error: ${error}`))
    }

    const goBack = () => {
        navigate(-1)
    }

    const isChecked = (role: string) => {
        return roles?.includes(role)
    }

    return (
        <>
            <TopHeader />
            <div className="flex items-center flex-col m-5 p-3">
                <div className="mx-5">
                    <h1 className="font-bold text-xl">Update User</h1>
                    <form className=" my-7">
                        <div className="form-body">
                            <div className="my-3">
                                <label className="font-medium">
                                    First name{' '}
                                </label>
                                <input
                                    className={fixedInputClass}
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => handleInputChange(e)}
                                    id="firstName"
                                    placeholder={
                                        user?.firstName || 'First Name'
                                    }
                                />
                            </div>
                            <div className="lastname">
                                <label className="font-medium">
                                    Last name{' '}
                                </label>
                                <input
                                    type="text"
                                    name=""
                                    id="lastName"
                                    value={lastName}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={user?.lastName || 'Last Name'}
                                />
                            </div>
                            <div className="my-3">
                                <label className="font-medium">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    className={fixedInputClass}
                                    value={email}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={user?.email || 'Email'}
                                />
                            </div>
                            <div className=" my-3">
                                <label className="font-medium">Company</label>
                                <input
                                    type="text"
                                    name=""
                                    id="companyName"
                                    value={company}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={
                                        user?.companyName || 'Company Name'
                                    }
                                />
                            </div>
                            <div className="accountMangerEmail my-3">
                                <label className="font-medium">
                                    Manager email
                                </label>
                                <div className="mt-3">
                                    <select
                                        id="accountManagerEmail"
                                        onChange={(e) => handleManagerChange(e)}
                                        className="w-96 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-2"
                                    >
                                        <option
                                            className="bg-b9green1"
                                            value={managerEmail}
                                        >
                                            {managerEmail
                                                ? managerEmail
                                                : 'Manager Email'}
                                        </option>
                                        {managersList.map((list) => (
                                            <option
                                                key={list.email}
                                                value={list.email}
                                            >
                                                {list.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="">
                                <label className="font-medium"> Currency</label>
                                <div className="my-3">
                                    <select
                                        id="currency"
                                        onChange={(e) => {
                                            handleCurrencyChange(e)
                                        }}
                                        className="w-96 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-2"
                                    >
                                        <option
                                            className="bg-b9green1 h-2/4"
                                            value={currency}
                                        >
                                            {currency ? currency : 'Currency'}
                                        </option>
                                        <option value="EUR" label="Euro">
                                            EUR
                                        </option>
                                        <option
                                            value="GBP"
                                            label="British pound"
                                        >
                                            GBP
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label className="font-medium"> Roles</label>
                                <div className="flex my-3 justify-evenly">
                                    <div className="mr-3">
                                        <input
                                            name="usersList"
                                            id="admin"
                                            type="checkbox"
                                            checked={isChecked('admin')}
                                            onChange={(e) => handleChange(e)}
                                            value="admin"
                                            className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="ml-2 text-sm font-medium  dark:text-gray-900">
                                            Admin
                                        </label>
                                    </div>
                                    <div className="mr-3">
                                        <input
                                            name="usersList"
                                            id="customer"
                                            type="checkbox"
                                            checked={isChecked('customer')}
                                            value="customer"
                                            onChange={(e) => handleChange(e)}
                                            className="w-3 m-0 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="ml-2 text-sm font-medium  dark:text-gray-900">
                                            Customer
                                        </label>
                                    </div>
                                    <div className="mr-3">
                                        <input
                                            name="usersList"
                                            id="rma"
                                            type="checkbox"
                                            value="rma"
                                            checked={isChecked('rma')}
                                            onChange={(e) => handleChange(e)}
                                            className="w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="ml-2 text-sm font-medium dark:text-gray-900">
                                            RMA
                                        </label>
                                    </div>
                                    <div className="">
                                        <input
                                            name="usersList"
                                            id="inventory"
                                            type="checkbox"
                                            value="inventory"
                                            checked={isChecked('inventory')}
                                            onChange={(e) => handleChange(e)}
                                            className="w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="ml-2 text-sm font-medium  dark:text-gray-900">
                                            Inventory
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex  ">
                            <button
                                type="button"
                                onClick={goBack}
                                className="relative mr-10 justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
                            >
                                Back
                            </button>
                            <button
                                className="group relative  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
                                onClick={handleSubmit}
                                type="submit"
                            >
                                Update User
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
