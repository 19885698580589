import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Row } from '../../../../layout/Row'
// import { useFetch } from '../../../../../hooks/useFetch'
import { TextArea } from '../../../../base/Textarea'
import { IconButton } from '../../../../base/IconButton/IconButton'
import { SparepartsDetail } from './SparepartsDetail'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { MyIcon } from '../../../../base/MyIcon'
import { CodeAfterRepair } from './CodeAfterRepair'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl
interface RepairCodesProps {
    handleNotes: (newValue: any) => void
    data: any
    refetch: () => void
    setIsNotes?: boolean
}
export default function RepairCodes({ handleNotes, data, refetch, setIsNotes }: RepairCodesProps) {
    // const { getRequest, data: sparepartsData } = useFetch(`${baseUrl}/stock/getStock`);
    const [isSparepartsDetailOpen, setIsSparepartsDetailOpen] = useState(false)
    const [spareParts, setSpareParts] = useState([])
    const [isCodeAfterRepairOpen, setIsCodeAfterRepairOpen] = useState(false)
    const [codesAfterFix, setCodesAfterFix] = useState<any>([])
    const [notes, setNotes] = useState<any>('')
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany;

    const handleNotesChange = (newValue: any) => {
        setNotes(newValue)
        handleNotes(newValue)
    }
    useEffect(() => {
        if (setIsNotes) {
            setNotes('')
        }
    }, [setIsNotes])

    const handleSpareparts = () => {
        setIsSparepartsDetailOpen(true)
        refetch()
    }
    const handleCodeAfterRepair = () => {
        setIsCodeAfterRepairOpen(true)
        refetch()
    }

    const handleDeleteSparePart = (sparepart: any) => {
        axios({
            method: 'delete',
            url: `${baseUrl}/item/delete-sparePart/${data?._id}`,
            data: {
                sparePartId: sparepart?.sparePart?._id,
                pieces: sparepart?.pieces,
            },
            headers: {
                Authorization: token,
                Company: company._id,

            },
        })
            .then((response) => {
                toast.success(response.data)
                addNotification({
                    message: 'Sparepart has been deleted successfully',
                    type: 'success',
                })
                refetch()
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            )
    }

    const list = useCallback((sparePartResponse, item) => {
        const innerSpareParts = []
        sparePartResponse?.map((sparePart) => {
            const models = sparePart?.models.map((mdl) => {
                return mdl?.modelName
            })
            if (models.includes(item?.model?.modelName)) {
                innerSpareParts.push({
                    label: sparePart?.name,
                    value: sparePart?._id,
                })
            }
        })
        setSpareParts(innerSpareParts)
    }, [])

    const getSparePart = useCallback(
        async (itemLocal) => {
            try {
                const response = await axios.get(
                    `${baseUrl}/stock/get-spare-part-pdf`,
                    {
                        headers: {
                            Authorization: token,
                            Company: company._id,
                        },
                    }
                )
                list(response?.data, itemLocal)
            } catch (error) {
                toast.error(error.response?.data?.message, {
                    hideProgressBar: true,
                })
            }
        },
        [company._id, list, token]
    )

    useEffect(() => {
        if (data) {
            getSparePart(data)
            setCodesAfterFix(data?.codes)
        }
    }, [getSparePart, data])

    const handleCodeAfterFixDelete = async (deletedCode) => {
        const updatedCodesAfterFix = codesAfterFix.filter(
            (code) => code !== deletedCode
        )
        await axios
            .put(
                `${baseUrl}/item/codeUpdate/${data?._id}`,
                {
                    codes: updatedCodesAfterFix,
                    id: data?._id,
                },
                {
                    headers: {
                        Authorization: token as string,
                        Company: company._id,

                    },
                }
            )
            .then(() => {
                toast.success('Saved', { hideProgressBar: true })
                addNotification({
                    message: 'Code has been deleted successfully',
                    type: 'success',
                })
                setCodesAfterFix(updatedCodesAfterFix)
                refetch()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }
    return (
        <>
            <Flex className="w-full mt-5 gap-x-3">
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full">
                        <Text colorV="blue" weightV="bold" variant="title">
                            Issue to be fixed
                        </Text>
                        <Row className="gap-x-3 mt-4">
                            {data?.codes?.map((code: any) => (
                                <div
                                    key={code}
                                    className="bg-b9blue   h-9 rounded-lg px-4  flex justify-center items-center"
                                >
                                    <Text
                                        colorV="white"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        {code}
                                    </Text>
                                </div>
                            ))}
                        </Row>
                    </Column>
                </PrimaryCard>
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full">
                        <Row className="gap-x-4">
                            <Text colorV="blue" weightV="bold" variant="title">
                                Code after repair
                            </Text>
                            <IconButton
                                name="add"
                                onClick={handleCodeAfterRepair}
                            />
                        </Row>
                        <Row className="gap-x-3 mt-4 flex flex-wrap  gap-y-3">
                            {codesAfterFix?.map((code: any) => (
                                <div
                                    key={code}
                                    className="bg-b9midblue relative  h-9 rounded-lg px-4  flex justify-center items-center"
                                >
                                    <Text
                                        colorV="white"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        {code}
                                    </Text>
                                    <div
                                        onClick={() =>
                                            handleCodeAfterFixDelete(code)
                                        }
                                        style={iconContainerStyles}
                                    >
                                        <MyIcon size={16} name="cross"></MyIcon>
                                    </div>
                                </div>
                            ))}
                        </Row>
                    </Column>
                </PrimaryCard>
            </Flex>
            <Flex className="w-full mt-5 gap-x-3">
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full gap-y-8">
                        <Row className="gap-x-3">
                            <Text colorV="blue" weightV="bold" variant="title">
                                Spare parts
                            </Text>
                            {spareParts?.length > 0 ? (
                                <>
                                    {!data?.repair?.finishAt && !data?.repair?.cancelAt ? (
                                        <IconButton name="add" onClick={handleSpareparts} />
                                    ) : (
                                        <Text colorV="blue" weightV="normal" variant="title">
                                            Repair is finished / cancelled.
                                        </Text>
                                    )}
                                </>
                            ) : (
                                <Text colorV="blue" weightV="normal" variant="title">
                                    No sparepart found for {data?.model?.modelName}
                                </Text>
                            )}

                        </Row>
                        <Row className="flex flex-wrap gap-x-3 gap-y-3">
                            {data?.spareParts?.map((sparepart: any) => (
                                <div
                                    key={data?._id}
                                    className="bg-b9white relative h-8 rounded-lg px-7 py-1 flex justify-center items-center"
                                >
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="title"
                                    >
                                        {sparepart?.sparePart?.name} (
                                        {sparepart?.pieces})
                                    </Text>
                                    {!data?.repair?.finishAt && !data?.repair?.cancelAt && (
                                        <div
                                            onClick={() => handleDeleteSparePart(sparepart)}
                                            style={iconContainerStyles}
                                        >
                                            <MyIcon size={16} name="cross"></MyIcon>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Row>
                    </Column>
                </PrimaryCard>
                <PrimaryCard className="w-full p-7" style={{ flex: 1 }}>
                    <Column className="w-full gap-y-2">
                        <Text colorV="blue" weightV="bold" variant="title">
                            Notes
                        </Text>
                        <TextArea
                            className="w-full"
                            value={notes}
                            onChange={handleNotesChange}
                            placeholder="Enter comment"
                        />
                    </Column>
                </PrimaryCard>
                {isSparepartsDetailOpen && (
                    <SparepartsDetail
                        item={data}
                        sparepartsData={spareParts}
                        onClose={() => {
                            setIsSparepartsDetailOpen(false)
                            getSparePart(data)
                            refetch()
                        }}
                    />
                )}
                {isCodeAfterRepairOpen && (
                    <CodeAfterRepair
                        item={data}
                        onClose={() => {
                            setIsCodeAfterRepairOpen(false)
                            refetch()
                        }}
                    />
                )}
            </Flex>
        </>
    )
}

const iconContainerStyles: CSSProperties = {
    position: 'absolute',
    top: -10,
    right: -9,
    padding: '4px', // Add padding for better aesthetics
    cursor: 'pointer', // Add cursor style to indicate it's clickable
}
