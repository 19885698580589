import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetch } from '../../../../../hooks/useFetch'
import { DropdownButtons } from '../../../../base/DropdownButton'
import { Flex } from '../../../../layout/Flex'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { DeleteRmaModel } from '../../CustomerRma/component/DeleteRmaModel'
import { Text } from '../../../../base/Text'
import { Row } from '../../../../layout/Row'
import { Table } from '../../../../shared/Table'
import JsBarcode from 'jsbarcode'
import { generatePDF } from '../../../../shared/GenerateLabel'
import { EventNameEnum } from '../../../../../constant/SortingLogicEvent'
const baseUrl = process.env.REACT_APP_baseUrl

export default function MyRMAArchiveTable() {
    const barCode = useRef<HTMLDivElement>(null);

    const { getRequest, data } = useFetch(`${baseUrl}/rma/archive-items-my-rma`)
    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    )
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );

    useEffect(() => {
        getLabels();
    }, [getLabels]);

    useEffect(() => {
        getRequest()
        getCodes()
    }, [getCodes, getRequest])

    const extractedData = data?.map((item) => {
        const createdAt = moment(item.createdAt);
        const currentDate = moment();
        const differenceInDays = currentDate.diff(createdAt, 'days');
        const totalDays = Math.max(parseInt(item.rmaSupplier) - differenceInDays, 0); // Ensure totalDays is not below 0
        const issues = item?.codes?.map((code) => {
            const matchingCode = codesData?.find((data) => data.label === code);
            return matchingCode ? matchingCode.description : code;
        });
        return {
            item: item,
            id: item?._id,
            Supplier: item?.supplier?.companyName,
            'RMA Batch#': item?.batchNumber,
            'Invoice number': item?.invoiceNumber,
            IMEI: item?.imei,
            Status: item?.sortingEvent.name,
            'Days left': totalDays,
            Issue: item.codes.toString(),
            'Issue Description': issues.join(', '),
        };
    }).filter((item) => item.Event === EventNameEnum.My_RMA_Sent_To_Supplier);

    const columns = [
        'Supplier',
        'RMA Batch#',
        'Invoice number',
        'IMEI',
        'Status',
        'Days left',
        'Issue',
        'Issue Description',
    ];

    const handleDropdownChange = useCallback((selectedOption, item) => {
        JsBarcode('#barcode', `${item?.item?.imei}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 1,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        });
        generatePDF(item, labelsData, barCode)
    }, [labelsData]);

    return (
        <>
            <Flex className="py-3 w-full">
                <Row className="w-full gap-x-7">
                    <Text style={{ width: '350px' }} colorV="blue" variant="head" weightV="bold">
                        Manage my RMA Archive
                    </Text>
                </Row>
            </Flex>
            <>
                <Flex className="w-full ">
                    <PrimaryCard style={{ maxHeight: '500px' }} className="w-full">
                        <Table
                            showAll={false}
                            options={['Print label']}
                            columns={columns}
                            data={extractedData}
                            showActions={true}
                            showCheckboxes={false}
                            // selectedRows={selectedItems}
                            // onCheckboxChange={handleCheckboxChange}
                            handleDropdownChange={handleDropdownChange}
                        // onAllCheckboxChange={handleAllChange}
                        />
                    </PrimaryCard>
                </Flex>
                <div ref={barCode} className="py-2 px-2 hidden">
                    <svg id="barcode"></svg>
                </div>
            </>
        </>
    )
}
