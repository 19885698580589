import React, { useCallback } from 'react';
import { ModalBox } from '../../../../base/ModelBox';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { Text } from '../../../../base/Text';
import { Button } from '../../../../base/Button';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};

export const ExportContent: React.FC<{ onClose: () => void, item: any }> = ({ onClose, item }) => {
    console.log(item);

    const handleInhouseInventoryPDF = useCallback(async () => {
        if (item?.length > 0) {
            const body = item?.map((itm: any) => {
                return [
                    itm?.sku || '',
                    itm?.modelDetails || '',
                    itm?.storage || '',
                    itm?.color || " ",
                    itm?.vat || " ",
                    itm?.grade?.grade || " ",
                    itm?.availablePieces || '',
                    itm?.sortingEvent || '',
                    itm?.salePrice || ''
                ];
            });
            body.unshift([
                'SKU',
                'Model',
                'Storage',
                'Color',
                "Vat",
                "Grade",
                'Available pieces',
                'Stauts',
                'Price'
            ]);
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            };
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('on-sale');
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [item]);

    const handleInhouseInventoryXLSX = useCallback(async () => {
        if (item?.length > 0) {
            const xlsxArray = item.map((itm) => ({
                SKU: itm?.sku || '',
                Model: itm?.modelDetails || '',
                Storage: itm?.storage || '',
                Color: itm?.color || " ",
                Vat: itm?.vat || " ",
                Grade: itm?.grade?.grade || "",
                "Available pieces": itm?.availablePieces || '',
                Stauts: itm?.sortingEvent || '',
                Price: itm?.salePrice || ''
            }));

            const ws = XLSX.utils.json_to_sheet(xlsxArray);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'On Sale');

            XLSX.writeFile(wb, 'on-sale.xlsx');
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [item]);

    return (
        <ModalBox heading="Export" onClose={onClose}>
            <RowAlignCenter className="text-center gap-x-4">
                <Button
                    onClick={handleInhouseInventoryPDF}
                    backgroundColor="purple"
                >
                    <Text colorV="white" variant="title" weightV="normal">
                        Export PDF
                    </Text>
                </Button>
                <Button
                    onClick={handleInhouseInventoryXLSX}
                    backgroundColor="blue"
                >
                    <Text colorV="white" variant="title" weightV="normal">
                        Export XLSX
                    </Text>
                </Button>
            </RowAlignCenter>
        </ModalBox>
    );
}
