import React, { useCallback, useEffect, useState } from 'react'
import { ModalBox } from '../../../../base/ModelBox'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { Text } from '../../../../base/Text'
import { Button } from '../../../../base/Button'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { Column } from '../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { TextBackground } from '../../../../base/TextBackground'
import { Row } from '../../../../layout/Row'

const baseUrl = process.env.REACT_APP_baseUrl

    ; (pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}

export const PdfDownload: React.FC<{ onClose: () => void; item: any }> = ({ onClose, item }) => {
    console.log({item});
    const handleDownload = () => {
        try {
            const blobUrl = item?.path;
            const link = document.createElement('a');
            link.href = blobUrl;
    
            // Specify the desired filename here
            link.download = 'custom-filename.xlsx';
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    return (
        <ModalBox
            heading='Export'
            top="50%"
            height="auto"
            width="500px"
            onClose={onClose}
        >
            <Column className='gap-y-4'>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="normal" variant="title">
                        Customer
                    </Text>
                    <TextBackground className='w-64' backgroundColor="b9white">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.Customer}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="normal" variant="title">
                        Credit note N
                    </Text>
                    <TextBackground className='w-64' backgroundColor="b9white">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.['Credit Note N']}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="normal" variant="title">
                        Credit note value
                    </Text>
                    <TextBackground className='w-64' backgroundColor="b9white">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.Value}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="normal" variant="title">
                        Status
                    </Text>
                    <TextBackground className='w-64' backgroundColor="b9white">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.Status}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="normal" variant="title">
                        Issued for:
                    </Text>
                    <TextBackground className='w-64' backgroundColor="b9white">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.['Issued for RMA']}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenter className="text-center gap-x-4">
                    <Button
                        className='w-64'
                        onClick={handleDownload}
                        backgroundColor="purple"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Export PDF
                        </Text>
                    </Button>
                </RowAlignCenter>
            </Column>
        </ModalBox>
    )
}
