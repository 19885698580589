import React from 'react';
import { Icon } from '../Icon';
import icon_green from '../../../assets/icon_green.png'
import icon_white from '../../../assets/icon_white.png'
import icon_violet from '../../../assets/icon_violet.png'
const cardStyle: React.CSSProperties = {
  width: '300px',
  height: 'auto',
  backgroundColor: '#F2F0ED',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
  padding: '15px',

};

const imageStyle: React.CSSProperties = {
  width: '100%',
  height: '70%', // Adjust the height for the image
};

const textContainerStyle: React.CSSProperties = {
  padding: '10px',
};

const editIconStyle: React.CSSProperties = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  borderRadius: '50%',
  padding: '5px',
  cursor: 'pointer',
};

interface CardProps {
  imageUrl: string;
  children: React.ReactNode;
  onIconClick: () => void;
  partnerTag: any
}

export const Partnercard: React.FC<CardProps> = ({ imageUrl, children, onIconClick,partnerTag }) => {
  let defaultImageUrl;
  if (partnerTag) {
    if (partnerTag.includes('lsp')) {
        defaultImageUrl = imageUrl || icon_white; // Show the provided image or white icon
    } else if (partnerTag.includes('customer')) {
        defaultImageUrl = imageUrl || icon_violet; // Show the provided image or violet icon
    } else if (partnerTag.includes('supplier')) {
        defaultImageUrl = imageUrl || icon_green; // Show the provided image or green icon
    } else {
        defaultImageUrl = imageUrl || icon_green; // Default to green icon
    }
}

  return (
    <div style={cardStyle}>
      <img src={defaultImageUrl} alt="Card Image" style={imageStyle} />
      <div style={textContainerStyle}>{children}</div>
      <div onClick={onIconClick} style={editIconStyle}>
        <Icon name="edit" size={15} />
      </div>
    </div>
  );
};
