import React, { useEffect, useState } from 'react';
import { Flex } from '../../../layout/Flex';
import { Text } from '../../../base/Text';
import { Button } from '../../../base/Button';
import { AddInventoryContent } from './components/AddSparepartsBox';
import { ExportModel } from './components/ExportModel';
import { ModalBox } from '../../../base/ModelBox';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { RowAlignCenter } from '../../../layout/RowAlignCenter';
import { useFetch } from '../../../../hooks/useFetch';
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess';
import { useAuth } from '../../../../utils/auth';
import { RowSpaced } from '../../../layout/RowSpaced';

export default function Head({ refetch, sparepartData }: any) {
    const [modalContent, setModalContent] = useState(null);
    const auth = useAuth();
    const addSparePartCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.SparePartsInventory && acc.permission.includes(AccessPermissions.Add));

    const handleExport = () => {
        setModalContent( addSparePartCheck && <ExportModel sparepartData={sparepartData} onClose={() => { setModalContent(null); refetch(); }} />);
    };

    const handleAddSpareparts = () => {
        setModalContent(
            addSparePartCheck && <AddInventoryContent onClose={() => { setModalContent(null); refetch(); }} />
        );
    };

    return (
        <RowSpaced className="pb-2 mr-4 pt-2 w-full justify-between items-center" >
                <Text colorV="blue" variant="head" weightV="bold">
                    Spare parts inventory
                </Text>
                <RowAlignCenter className='gap-3'>
                    {addSparePartCheck && <Button onClick={handleExport} backgroundColor="green">
                        <Text colorV="white" variant="title" weightV="normal">
                            Export
                        </Text>
                    </Button>}
                    {addSparePartCheck && <Button onClick={handleAddSpareparts} backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Add spare parts
                        </Text>
                    </Button>}
                </RowAlignCenter>
                {modalContent}
            </RowSpaced>
    );
}
