import React, { useState } from 'react'
import { Flex } from '../../../layout/Flex'
import { Text } from '../../../base/Text'
import { Button } from '../../../base/Button'
import { Column } from '../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { Row } from '../../../layout/Row'
import { Device } from './Device/Device'
import { Spareparts } from './Spareparts/Spareparts'

export default function Head() {
    const [activeTab, setActiveTab] = useState('Device')

    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Flex className="py-5 w-full">
            <Column className='w-full'>
                <Row className="gap-x-5 w-full">
                    <Text
                        style={{ width: '100px' }}
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        {activeTab}
                    </Text>
                    <Button
                        onClick={() => handleTabClick('Device')}
                        backgroundColor={
                            activeTab === 'Device' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Device'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={activeTab === 'Device' ? 'white' : 'blue'}
                            variant="title"
                            weightV="normal"
                        >
                            Device
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Spareparts')}
                        backgroundColor={
                            activeTab === 'Spareparts' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Spareparts'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={activeTab === 'Spareparts' ? 'white' : 'blue'}
                            variant="title"
                            weightV="normal"
                        >
                            Spare parts
                        </Text>
                    </Button>
 
                </Row>
                <RowAlignCenterSpaced className="mt-5">
                    {activeTab === 'Device' && <Device />}
                    {activeTab === 'Spareparts' && <Spareparts />}
                </RowAlignCenterSpaced>
            </Column>
        </Flex>
    )
}
