import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { myVat } from '../../../../../constant/Storage'
import { useFetch } from '../../../../../hooks/useFetch'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { TextBackground } from '../../../../base/TextBackground'
import { TextArea } from '../../../../base/Textarea'
import { usePost } from '../../../../../hooks/usePost'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { generatePDF } from '../../../../shared/GenerateLabel'
import JsBarcode from 'jsbarcode'

const baseUrl = process.env.REACT_APP_baseUrl
    ; (pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
export const AddInventoryContent: React.FC<{ onClose: () => void }> = ({
    onClose,
}) => {
    const [model, setModel] = useState<any>('')
    const [storage, setStorage] = useState<any>()
    const [comment, setComment] = useState<any>('')
    const [device, setDevice] = useState<any>('')
    const [invoiceNumber, setInvoiceNumber] = useState<any>('')
    const [imei, setImei] = useState<string>('')
    const [vat, setVat] = useState<any>('')
    const [purchasePrice, setPurchasePrice] = useState<string>('')
    const [supplier, setSupplier] = useState<any>('')
    const [os, setOs] = useState<any>('')
    const [rmaSupplier, setRmaSupplier] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false)
    const barCode = useRef<HTMLDivElement>(null);

    const [selectedModel, setSelectedModel] = useState<any>()
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const [filteredDevices, setFilteredDevices] = useState<any[]>([]);
    const [filteredModels, setFilteredModels] = useState<any[]>([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState<any[]>([]);
    const { getRequest, data } = useFetch(`${baseUrl}/partner/get-partners`);

    const { sendRequest } = usePost();
    useEffect(() => {
        getRequest();
    }, [getRequest]);

    useEffect(() => {
        if (data) {
            const suppliers = data.filter((item) => item.tags.includes('supplier'));
            setFilteredSuppliers(suppliers);
        }
    }, [data]);

    const handleInvoiceChange = (newValue: any) => {
        setInvoiceNumber(newValue)
    }

    const handleModelChange = (event: any) => {
        const value = event.target.value
        setModel(value)
        const selectedModelName = modelsData?.find((model: any) => model._id.includes(value))
        const modelNames = modelsData?.filter((model) => model?.modelName === selectedModelName?.modelName)
        setSelectedModel(modelNames)
    }

    const handleStorageChange = (event: any) => {
        setStorage(event.target.value)
    }

    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }

    const handleDeviceChange = (event: any) => {
        setDevice(event.target.value)
        const models = filteredDevices?.filter((model) => model.device === event.target.value);
        setFilteredModels(models);
    }

    const handleImeiChange = (newValue: any) => {
        setImei(newValue)
    }

    const handleVatChange = (event: any) => {
        setVat(event.target.value)
    }

    const handlePurchasePriceChange = (newValue: any) => {
        setPurchasePrice(newValue)
    }

    const handleSupplierChange = (event: any) => {
        setSupplier(event.target.value)
    }

    const handleOsChange = (event: any) => {
        setOs(event.target.value)
        const devices = modelsData?.filter((model) => model?.os === event.target.value);
        setFilteredDevices(devices);
        setDevice('');
        setModel('');
    }

    const handleRmaSupplierChange = (newValue: any) => {
        setRmaSupplier(newValue)
    }

    const { getRequest: getModel, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )

    useEffect(() => {
        getModel()
    }, [getModel])

    useEffect(() => {
        if (modelsData && os && device) {
            const devices = modelsData.filter((model) => model.os === os);
            setFilteredDevices(devices);
            const models = devices.filter((model) => model.device === device);
            setFilteredModels(models);
        }
    }, [modelsData, os, device]);

    const handleSave = async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, select Model')
            return
        } else if (!imei) {
            alert('Please, add IMEI')
            return
        } else if (!vat) {
            alert('Please, add vat')
            return
        } else if (!storage) {
            alert('Please, add storage')
            return
        } else if (!device) {
            alert('Enter device ')
            return
        } else if (!purchasePrice) {
            alert('Please, add purchase price')
            return
        } else if (!supplier) {
            alert('Please, add supplier')
            return
        } else if (!rmaSupplier) {
            alert('Please, add RMA supplier')
            return
        }
        else if (!os) {
            alert('Please, add operating system')
            return
        } else if (!invoiceNumber) {
            alert('Please, add invoice number')
            return
        }
        setIsLoading(true)
        const urlInventoryItem = `${baseUrl}/inventory/add-inventory`
        await sendRequest(
            urlInventoryItem,
            {
                invoiceNumber,
                vat,
                model,
                storage,
                imei,
                purchasePrice,
                comment,
                device,
                supplier,
                rmaSupplier,
                os,
            },
        )
            .then((response) => {
                toast.success(response?.data?.message, { hideProgressBar: true })
                setModel('')
                setStorage('')
                setComment('')
                setDevice('')
                setInvoiceNumber('')
                setImei('')
                setVat('')
                setPurchasePrice('')
                setSupplier('')
                setOs('')
                setRmaSupplier('')
                setIsLoading(false)
                onClose()
            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
                setIsLoading(false)

            })
    }
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);

    const handleSavePrint = async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, select Model')
            return
        } else if (!imei) {
            alert('Please, add IMEI')
            return
        } else if (!vat) {
            alert('Please, add vat')
            return
        } else if (!storage) {
            alert('Please, add storage')
            return
        } else if (!device) {
            alert('Enter device ')
            return
        } else if (!purchasePrice) {
            alert('Please, add purchase price')
            return
        } else if (!supplier) {
            alert('Please, add supplier')
            return
        } else if (!rmaSupplier) {
            alert('Please, add RMA supplier')
            return
        }
        else if (!os) {
            alert('Please, add operating system')
            return
        } else if (!invoiceNumber) {
            alert('Please, add invoice number')
            return
        }
        setIsLoadingSave(true)
        const urlInventoryItem = `${baseUrl}/inventory/add-inventory`
        await sendRequest(
            urlInventoryItem,
            {
                invoiceNumber,
                vat,
                model,
                storage,
                imei,
                purchasePrice,
                comment,
                device,
                supplier,
                rmaSupplier,
                os,
            },
        )
            .then((response) => {
                const item = response?.data ?? {};
                JsBarcode('#barcode', `${response?.data?.item?.imei}`, {
                    lineColor: 'black',
                    textAlign: 'center',
                    width: 1,
                    background: 'transparent',
                    height: 40,
                    displayValue: false,
                    xmlDocument: document,
                });
                toast.success(response?.data?.message, { hideProgressBar: true })
                generatePDF(item, labelsData, barCode )
                onClose()
                setModel('')
                setStorage('')
                setComment('')
                setDevice('')
                setInvoiceNumber('')
                setImei('')
                setVat('')
                setPurchasePrice('')
                setSupplier('')
                setOs('')
                setRmaSupplier('')
                setIsLoadingSave(false)
                onClose()
            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }

    return (
        <ModalBox
            heading="Add Inventory Item"
            onClose={onClose}
            width="500px"
            footer={
                <RowAlignCenterSpaced
                    className="gap-x-4"
                    inlineStyle={{
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button className='w-32' onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Saving..' : "Save"}
                        </Text>
                    </Button>
                    <Button className='w-36' onClick={handleSavePrint} backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                           {isLoadingSave ? "Saving.." : "Save & Print"}
                        </Text>
                    </Button>
                </RowAlignCenterSpaced>
            }
        >
            <Column className="gap-y-4 overflow-y-auto h-96 ">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Supplier
                    </Text>
                    {filteredSuppliers?.length > 0 ? (
                        <div style={{ width: '18rem' }}>
                            <select
                                id="supplier"
                                value={supplier}
                                onChange={handleSupplierChange}
                                className="w-full border hover:cursor-pointer  rounded-md py-2 px-1"
                            >
                                <option>Select</option>
                                {filteredSuppliers?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.companyName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        <TextBackground className='w-72' backgroundColor='b9white'>
                            Please, add supplier
                        </TextBackground>
                    )}
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Invoice number
                    </Text>
                    <TextField
                        value={invoiceNumber}
                        onChange={handleInvoiceChange}
                        placeholder="Enter invoice number"
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Vendor RMA
                    </Text>
                    <TextField
                        value={rmaSupplier}
                        onChange={handleRmaSupplierChange}
                        placeholder="00 days"
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Operating systems
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="os"
                            value={os}
                            onChange={handleOsChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"                        >
                            <option>Select</option>
                            {Array.from(new Set(modelsData?.map(model => model.os))).map((osValue: any, index: any) => (
                                <option key={index} value={osValue}>
                                    {osValue}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Device
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={device}
                            onChange={handleDeviceChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"                        >
                            <option>Select</option>
                            {Array.from(new Set(filteredDevices?.map(model => model.device))).map((deviceValue: any, index: any) => (
                                <option key={index} value={deviceValue}>
                                    {deviceValue}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={model}
                            onChange={handleModelChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"                        >
                            <option>Select</option>
                            {filteredModels?.map((uniqueModelName) => (
                                <option key={uniqueModelName._id} value={uniqueModelName?._id}>
                                    {uniqueModelName.modelName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Storage
                    </Text>
                    {selectedModel ? <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={storage}
                            onChange={handleStorageChange}
                            className="w-full hover:cursor-pointer  py-2 px-1"                        >
                            <option>Select</option>
                            {selectedModel?.map((model) => (
                                model?.storage?.map((uniqueStorage) => (
                                    <option key={uniqueStorage} value={uniqueStorage}>
                                        {uniqueStorage}
                                    </option>
                                ))
                            ))}
                        </select>
                    </div> : <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text weightV='normal' variant='title' colorV='blue'> Select model first</Text>
                    </TextBackground>}
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        IMEI
                    </Text>
                    <TextField
                        value={imei}
                        onChange={handleImeiChange}
                        placeholder="Enter imei"
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Purchase price
                    </Text>
                    <TextField
                        value={purchasePrice}
                        onChange={handlePurchasePriceChange}
                        placeholder="Enter price"
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        VAT
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={vat}
                            onChange={handleVatChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"                        >
                            <option>Select</option>
                            {myVat?.map((vat: any) => (
                                <option key={vat} value={vat}>
                                    {vat}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                    <Text colorV="blue" variant="title" weightV="normal">
                       
                        In House
                    </Text>

                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Comment
                    </Text>
                    <TextArea
                        className='w-72'
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="write comment ..."
                        padding="5px 13px"
                    />
                </RowAlignCenterSpaced>
            </Column>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </ModalBox>
    )
}
