import { Breadcrumb } from '../components/base/Breadcrumb'
import { MyCustomerHome } from '../components/customerPortal/CustomerHome/MyCustomerHome'
import { Row } from '../components/layout/Row'
import Header from '../components/shared/Header/Header'
import { useBreadcrumb } from '../hooks/useBreadcrumb'

export default function CustomerHome() {
    const breadcrumbItems = useBreadcrumb()

    return (
        <>
            <Header />
            <Row className="my-2 w-full">
                <Breadcrumb items={breadcrumbItems} separator="/" />
            </Row>
            <MyCustomerHome />
        </>
    )
}
