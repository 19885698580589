import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useFetch } from '../hooks/useFetch'

const baseUrl = process.env.REACT_APP_baseUrl

;(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}

export default function SparepartsReports() {
    const [items, setItems] = useState<any>()

    const { getRequest, data } = useFetch(`${baseUrl}/stock/getStock`)

    useEffect(() => {
        getRequest()
    }, [getRequest])

    useEffect(() => {
        setItems(data)
    }, [data])

    const handleDownloadPDF = async () => {
        if (items) {
            const body = items?.map((itm: SparepartType) => {
                return [itm?.name, itm?.pieces, itm?.price]
            })
            body.unshift(['Spare part name', 'Pieces', 'Price'])
            const content = []
            content.push({
                margin: [0, 15, 0, 8],
                bold: true,
                text: 'Spare part report',
            })
            content.push({ table: { body: body } })
            const docDefinition = {
                pageOrientation: 'landscape',
                content,
            }
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('SparepartPDF')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }

    const handleDownloadCSV = async () => {
        if (items.length > 0) {
            const csvArray = []
            items?.forEach((element) => {
                csvArray.push({
                    name: element?.name,
                    pieces: element?.pieces,
                    price: element?.price,
                })
            })
            let csv = ' '
            for (let row = 0; row < csvArray?.length; row++) {
                const keysAmount = Object.keys(csvArray[row]).length
                let keysCounter = 0
                if (row === 0) {
                    for (const key in csvArray[0]) {
                        csv +=
                            key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                }
                keysCounter = 0
                for (const key in csvArray[row]) {
                    csv +=
                        csvArray[row][key] +
                        (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
            }
            const data = new Blob([csv], { type: 'text/csv' })
            saveCsv(data, 'spareparts.csv')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }

    function saveCsv(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = filename
        a.click()
    }
    return (
        <>
            <div className="flex  ">
                <div className="">
                    <button
                        onClick={handleDownloadPDF}
                        className="relative w-65 mr-2 mb-3 justify-right py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        Download spare part report PDF
                    </button>
                    <button
                        onClick={handleDownloadCSV}
                        className="relative w-70 justify-right py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        Download spare part report CSV
                    </button>
                </div>
                <div
                    className="mx-10"
                    style={{ borderLeft: '1px solid silver' }}
                ></div>
            </div>
        </>
    )
}
type SparepartType = {
    name: string
    pieces: number
    price: number
}
