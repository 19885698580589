import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { Text } from '../../../../base/Text'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { TextBackground } from '../../../../base/TextBackground'
import { useEdit } from '../../../../../hooks/useEdit'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditSaleItem: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [salePrice, setSalePrice] = useState('')
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendEditRequest } = useEdit()

    useEffect(() => {
        setSalePrice(item?.Price)
    }, [item?.Price])

    const handleSalePrice = (newValue: any) => {
        setSalePrice(newValue)
    }
    const handleSaveChange = useCallback(async (e: any) => {
        e.preventDefault()
        if (!salePrice) {
            alert('Please, add sale price')
            return
        }
        await
            sendEditRequest(
                `${baseUrl}/sale-sku/edit-sale-inventory-sku/${item?.skuId}`,
                {
                    salePrice,
                    itemsId: item.itemsId
                },
            )
                .then((response) => {
                    onClose()
                    toast.success(response?.data?.message, { hideProgressBar: true })
                    addNotification({
                        message: 'Item has been updated successfully',
                        type: 'success',
                    })
                })
                .catch((error) =>
                    toast.error(error.response.data.message, {
                        hideProgressBar: true,
                    })
                )
    }, [item.itemsId, item?.skuId, onClose, salePrice, sendEditRequest])
    return (
        <ModalBox
            heading="Edit "
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Sku
                    </Text>
                    <TextBackground className='w-56  h-10 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {item?.SKU}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">

                        Models
                    </Text>
                    <TextBackground className='w-56 h-10 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {item?.Model}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Storage
                    </Text>
                    <TextBackground className='w-56 h-10 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {item?.Storage}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">

                        Colors
                    </Text>
                    <TextBackground className='w-56 h-10 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {item?.Color}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">

                        Available pieces
                    </Text>
                    <TextBackground className='w-56 h-10 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {item?.["Available pieces"]}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Price
                    </Text>
                    <TextField
                        value={salePrice}
                        onChange={handleSalePrice}
                        placeholder="Enter price"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-40' backgroundColor="lgblue" onClick={handleSaveChange}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Update
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
