import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import TopHeader from '../components/TopHeader'
import Select from 'react-select'
import { useAuth } from '../utils/auth'
import { useFetch } from '../hooks/useFetch'
import { addNotification } from '../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md mt-3 appearance-none relative block w-80 px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

export const UpdateStock = () => {
    const [sku, setSku] = useState('')
    const [name, setName] = useState('')
    const [models, setModels] = useState<any>([])
    const [preModel, setPreModel] = useState<any[]>([])
    const [selectedModels, setSelectedModels] = useState<any[]>([])
    const [pieces, setPieces] = useState()
    const [price, setPrice] = useState()

    const { id } = useParams()
    const navigate = useNavigate()
    const auth = useAuth()
    const token = auth?.user?.token?.token

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/getModels`
    )
    const { getRequest: getSparePart, data: sparePartData } = useFetch(
        `${baseUrl}/stock/getStock/${id}`
    )
    useEffect(() => {
        getModels()
        getSparePart()
    }, [getModels, getSparePart])

    useEffect(() => {
        const localModel = []
        modelsData?.forEach((element) => {
            localModel.push({ label: element?.modelName, value: element?._id })
        })
        const previousLocalModel = []
        sparePartData?.models?.forEach((element) => {
            previousLocalModel.push({
                label: element?.modelName,
                value: element?._id,
            })
        })
        setModels(localModel)
        setSku(sparePartData?.sku)
        setName(sparePartData?.name)
        setSelectedModels(previousLocalModel || [])
        setPieces(sparePartData?.pieces)
        setPreModel(previousLocalModel || [])
        setPieces(sparePartData?.pieces)
        setPrice(sparePartData?.price)
    }, [
        modelsData,
        sparePartData,
        sparePartData?.models,
        sparePartData?.name,
        sparePartData?.pieces,
        sparePartData?.price,
        sparePartData?.sku,
    ])

    useEffect(() => {
        getSparePart()
        getModels()
    }, [getModels, getSparePart])

    const handleModels = useCallback((e: any) => {
        const vals = Array.isArray(e) ? e.map((model) => model?.value) : []
        setSelectedModels(vals)
    }, [])
    const handleInputChange = (e: any) => {
        e.preventDefault()
        const { id, value } = e.target
        if (id === 'sku') {
            e.preventDefault()
            setSku(value)
        }
        if (id === 'name') {
            e.preventDefault()
            setName(value)
        }
        if (id === 'pieces') {
            e.preventDefault()
            setPieces(value)
        }
        if (id === 'price') {
            e.preventDefault()
            setPrice(value)
        }
    }

    const handleUpdateSubmit = (e: any) => {
        e.preventDefault()
        updateStock()
    }

    const updateStock = () => {
        if (!sku) {
            alert('Please, add SKU')
            return
        }
        if (!name) {
            alert('Please, add name')
            return
        }
        if (pieces === undefined || pieces == 0) {
            alert('Please, enter pieces more then 0')
            return
        }
        if (price === undefined || price == 0) {
            alert('Please, enter price more then 0')
            return
        }
        if (selectedModels.length === 0) {
            alert('Please select models')
            return
        }
        const modelsParsed =
            typeof selectedModels[0] === 'string'
                ? selectedModels
                : selectedModels.map((model) => model?.value)
        axios
            .put(
                `${baseUrl}/stock/update/${id}`,
                {
                    sku,
                    name,
                    modelVal: modelsParsed,
                    pieces,
                    price,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                addNotification({
                    message: 'Record Updated Successfully',
                    type: 'success',
                })
                toast.success('Record Updated Successfully', {
                    hideProgressBar: true,
                })
            })
            .catch((error) =>
                toast.error(`Error: ${error}`, { hideProgressBar: true })
            )
    }

    const goBack = () => {
        navigate(-1)
    }

    const items = []

    if (preModel) {
        for (const cod of preModel) {
            const item = models?.find(
                (instance: any) => instance?.label === cod?.label
            )
            if (item) {
                items.push(item)
            }
        }
    }
    return (
        <>
            <TopHeader />
            <div className="flex items-center m-3 flex-col ">
                <div className="overflow-x-auto">
                    <h1 className="font-bold text-xl text-l mt-6 ml-4">
                        Update Spare Part
                    </h1>

                    <form className="my-8 mx-4 space-y-6">
                        <div className="form-body">
                            <div className="my-3">
                                <label className="">SKU</label>
                                <input
                                    className={fixedInputClass}
                                    type="text"
                                    value={sku}
                                    onChange={(e) => handleInputChange(e)}
                                    id="sku"
                                    placeholder={sku ? sku : 'Enter SKU'}
                                />
                            </div>
                            <div className="name">
                                <label className="">Name</label>
                                <input
                                    type="text"
                                    name=""
                                    id="name"
                                    value={name}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={name ? name : 'Enter Name'}
                                />
                            </div>
                            <div className="pieces my-3">
                                <label className="">Pieces</label>
                                <input
                                    type="text"
                                    name=""
                                    id="pieces"
                                    value={pieces}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={
                                        pieces ? pieces : 'Enter pieces'
                                    }
                                />
                            </div>
                            <div className="price my-3">
                                <label className="">Price</label>
                                <input
                                    type="text"
                                    name=""
                                    id="price"
                                    value={price}
                                    className={fixedInputClass}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={price ? price : 'Enter Price'}
                                />
                            </div>
                            <div className="model">
                                <label className="">Model</label>
                                <div className="my-3">
                                    {items.length > 0 &&
                                        items.length === preModel?.length && (
                                            <Select
                                                id="model"
                                                defaultValue={items}
                                                options={models}
                                                onChange={handleModels}
                                                isMulti
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                            />
                                        )}
                                    {preModel?.length === 0 && (
                                        <Select
                                            id="model"
                                            defaultValue={items}
                                            options={models}
                                            onChange={handleModels}
                                            isMulti
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-start mt-5">
                            <button
                                type="button"
                                onClick={goBack}
                                className="relative mr-10 justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                                Back
                            </button>
                            <button
                                className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                onClick={handleUpdateSubmit}
                                type="submit"
                            >
                                Update Spare Part
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
