// FileUpload.tsx
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Text } from '../../base/Text'
import { Icon } from '../../base/Icon'
import { Column } from '../../layout/Col'

interface FileUploadProps {
    onFileUpload?: (file: File) => void
    width?: string
    height?: string
    backgroundColor?: string
    isDragAccept?: boolean
    fileTypeDescription?: string
    acceptedFileTypes?: string // Specify accepted file types (e.g., ['.csv', '.xlsx'])
}

export const FileUpload: React.FC<FileUploadProps> = ({
    onFileUpload,
    width = '100%',
    height = '150px',
    backgroundColor = 'white',
    isDragAccept = true,
    fileTypeDescription = "CSV or XLSX"
}) => {
    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: any[]) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                if (isDragAccept || fileRejections.length === 0) {
                    onFileUpload(file);
                }
            }
        },
        [onFileUpload, isDragAccept]
    )

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'text/xlsx': ['.xlsx', '.csv', '.pdf'],
        }
    })

    const dropzoneStyle: React.CSSProperties = {
        width,
        height,
        border: isDragReject
            ? '2px dashed red'
            : isDragActive
            ? '2px dashed #00e676'
            : '2px dashed #112D56',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '10px',
        backgroundColor,
    }

    return (
        <div {...getRootProps()} style={dropzoneStyle} className="dropzone">
            <input {...getInputProps()} />
            <Column className="gap-y-2 items-center">
                <Icon name="upload" size={30} color="#112D56" />
                <Text colorV="blue" weightV="bold" variant="heading">
                    {isDragReject
                        ? 'Unsupported file type'
                        : isDragActive
                        ? 'Drop the file here'
                        : `Upload your ${fileTypeDescription}`}
                </Text>
            </Column>
        </div>
    )
}
