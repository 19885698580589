import React, { useState } from 'react';
import { Flex } from '../../../layout/Flex';
import { Text } from '../../../base/Text';
import { Button } from '../../../base/Button';
import { Column } from '../../../layout/Col';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { Row } from '../../../layout/Row';
import { Send } from './components/send/Send';
import { Receive } from './components/receive/Receive';
import { Devices } from './components/devices/Devices';

export default function Head() {
    const [activeTab, setActiveTab] = useState('Send');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    }
    const handleBackButtonClick = () => {
        setActiveTab('Send'); // Change the active tab back to 'Send'
    };

    return (
        <Flex className="py-5">
            {activeTab === 'Device' ? <Devices handleBackButtonClick={handleBackButtonClick}/> : (
                <Column className='w-full gap-y-3'>
                    <RowAlignCenterSpaced>
                        <Text
                            style={{ width: '100px', marginRight:'20px' }}
                            colorV="blue"
                            variant="head"
                            weightV="normal"
                        >
                            Transit
                        </Text>
                        <Button
                            onClick={() => handleTabClick("Device")}
                        >
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Device in transit
                            </Text>
                        </Button>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced className="gap-x-5">
                        <Row className='gap-x-5'>
                            <Button
                                onClick={() => handleTabClick('Send')}
                                backgroundColor={
                                    activeTab === 'Send' ? 'blue' : 'grey'
                                }
                                className={
                                    activeTab === 'Send'
                                        ? 'active-button'
                                        : 'inactive-button'
                                }
                            >
                                <Text
                                    colorV={activeTab === 'Send' ? 'white' : 'blue'}
                                    variant="title"
                                    weightV="normal"
                                >
                                    Send
                                </Text>
                            </Button>
                            <Button
                                onClick={() => handleTabClick('Receive')}
                                backgroundColor={
                                    activeTab === 'Receive' ? 'blue' : 'grey'
                                }
                                className={
                                    activeTab === 'Receive'
                                        ? 'active-button'
                                        : 'inactive-button'
                                }
                            >
                                <Text
                                    colorV={activeTab === 'Receive' ? 'white' : 'blue'}
                                    variant="title"
                                    weightV="normal"
                                >
                                    Receive
                                </Text>
                            </Button>
                        </Row>
                    </RowAlignCenterSpaced>
                        {activeTab === 'Send' && <Send />}
                        {activeTab === 'Receive' && <Receive />}
                        {activeTab === 'Device' && <Devices />}
                </Column>
            )}

        </Flex>
    );
}
