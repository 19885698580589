import React from 'react'
// import { TotalInsights } from '../Reports/components/TotalInsights'
import { ReportsData } from './components/ReportsData'
import { Column } from '../../../../layout/Col'

export const Reports = () => {
    return (
        <>
            <Column className='w-full'>
                <ReportsData />
            </Column>
        </>
    )
}
