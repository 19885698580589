import { Dropdown } from '../../../../../base/Dropdown'
import { Icon } from '../../../../../base/Icon'
import { Text } from '../../../../../base/Text'
import { Column } from '../../../../../layout/Col'
import { Row } from '../../../../../layout/Row'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { BarChart } from '../../../../../shared/Chart'
import { DoughnutChart } from '../../../../../shared/Chart/DoughnutChart'
import { PrimaryCard } from '../../../../Page/PrimaryCard'

export const GraphInsights = ({data}:any) => {
    console.log({data});
    const purchaseData = data?.allPurchaseOrders;

    // Prepare datasets for the bar chart
    const barChartData = purchaseData?.map((order: any) => order?.averagePriceDifference);

    const barChartLabels = purchaseData?.map((order: any) => order?.saleBatchNumber);

    // const barChartData1 = [25, 40, 30, 20, 35];
    // const barChartLabels = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'];

    const chartData = [65, 59, 80, 81, 56, 55, 40]
    const chartLabels = [
        'Label 1',
        'Label 2',
        'Label 3',
        'Label 4',
    ]
let pieData:any;
let pieLabels:any;
if(data){
     pieData = Object.values(data?.saleInventoryItems).map((value: any) => Number(value));
     pieLabels = Object.keys(data?.saleInventoryItems);
}


    return (
        <>
            <Row className="gap-x-2 mt-5 w-full">
                <PrimaryCard className='bg-b9white p-5 w-full md:w-1/2'>
                    <RowAlignCenterSpaced className="mb-5 w-full">
                        <Text colorV="blue" variant="heading" weightV="normal">
                            DPM per purchase order
                        </Text>
                        <Dropdown
                            style={{ border: 'none !important', backgroundColor: "transparent !important" }}
                            width='75px'
                            options={['Week', 'Day', 'Month']}
                            onChange={(selectedOption) => {
                                console.log(`Selected option: ${selectedOption}`);
                            }}
                        />
                    </RowAlignCenterSpaced>
                    <Column className='w-full h-full' >
                    <BarChart
                            labels={barChartLabels}
                            dataset={barChartData}
                        />
                    </Column>
                </PrimaryCard>
                <PrimaryCard className='bg-b9white p-5 w-full md:w-1/2'>
                    <RowAlignCenterSpaced className="mb-5">
                        <Text colorV="blue" variant="heading" weightV="normal">
                            Sale inventory
                        </Text>
                    </RowAlignCenterSpaced>
                    <Column className='w-11/12 items-center justify-center'>
                    <DoughnutChart
                        labels={pieLabels}
                        data={pieData}
                    />
                </Column>
                </PrimaryCard>
            </Row>
        </>
    )
}
