import React, { useEffect, useState } from 'react';
import { Filter } from './components/Filter';
import Head from './components/Head';
import SalesTable from './components/SalesTable';
import { useFetch } from '../../../../hooks/useFetch';
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess';
import { useAuth } from '../../../../utils/auth';

const baseUrl = process.env.REACT_APP_baseUrl;

export const SaleInventory = () => {
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const auth = useAuth();
  const viewSalesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.SalesInventory && acc.permission.includes(AccessPermissions.View));

  const { getRequest: getSalesItems, data: itemsData, error: salesError } = useFetch(
    `${baseUrl}/saleinventory/mobile-devices`
  );
  
  useEffect(() => {
    getSalesItems();
  }, [getSalesItems]);


  const handleFilterChange = (filteredData: any) => {
    console.log({ filteredData });
    setFilteredItems(filteredData);
  };

  const refetch = () => {
    getSalesItems();
  };

  return (
    <>
      <Head handleFilterChange={handleFilterChange} item={filteredItems.length > 0 ? filteredItems : itemsData} refetch={refetch} itemsData={itemsData} />
      {/* {viewSalesCheck && <Filter onDataChange={handleFilterChange} itemsData={itemsData} />} */}
      {viewSalesCheck && !salesError && <SalesTable item={filteredItems?.length > 0 ? filteredItems : itemsData} refetch={refetch} />}
      {salesError && <div>Error: {salesError.message}</div>}
    </>
  );
};
