import React, { useCallback, useState } from 'react'
import { Button } from '../../../../base/Button'
import { Text } from '../../../../base/Text'
import {Filter} from '../../ManageSKU/SalesInventory/component/Filter'
import { AddUser } from './AddUser'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Column } from '../../../../layout/Col'
import { RowAlignCenter } from '../../../../layout/RowAlignCenter'
import { Flex } from '../../../../layout/Flex'
import moment from 'moment'
import { toast } from 'react-toastify'

export default function HeadButtons({data, refetch}:any) {
    const [modalContent, setModalContent] = useState(null)

    const handleExport = useCallback(async () => {
        if (data?.length > 0) {
            const body = data?.map((itm: any) => {
                return [
                    itm?.firstName || '',
                    itm?.lastName || '',
                    itm?.email || '',
                    itm?.accountManagerEmail || '',
                ]
            })
            body.unshift([
                'First name',
                'Last name',
                'Email',
                'Manager',
            ])
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            }
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('users')
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            })
        }
    }, [data])

    const handleAddUser = () => {
        setModalContent(<AddUser onClose={() => {setModalContent(null), refetch()}} />)
    }

    return (
        <Flex className="w-full">
            <Column className="py-2 w-full">
                <RowAlignCenterSpaced className="gap-x-5 w-full">
                    <Text colorV="blue" variant="head" weightV="bold">
                        Users
                    </Text>
                    <RowAlignCenter className="gap-x-5">
                        <Button onClick={handleExport} backgroundColor="green">
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Export
                            </Text>
                        </Button>
                        <Button
                            onClick={handleAddUser}
                            backgroundColor="lgblue"
                        >
                            <Text
                                colorV="white"
                                variant="title"
                                weightV="normal"
                            >
                                Add new User
                            </Text>
                        </Button>
                        {/* <Filter /> */}
                    </RowAlignCenter>
                </RowAlignCenterSpaced>
                {modalContent && (modalContent
                )}
            </Column>
        </Flex>
    )
}
