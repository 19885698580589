import React, { CSSProperties, ReactNode } from 'react';
import { Text } from '../Text';
import { Icon } from '../Icon';

interface ModalProps {
    heading?: string;
    onClose: () => void;
    children?: ReactNode;
    footer?: ReactNode;
    height?: string;
    width?: string;
    top?: string;
    left?: string;
    right?: string;
    zIndex?: number;
    showClose?: boolean;
    button?: ReactNode; // Add button prop
    onButtonClick?: () => void; // Add onButtonClick prop
    isProfile?: boolean
}

export const ModalBox: React.FC<ModalProps> = ({
    heading,
    onClose,
    children,
    footer,
    height,
    width,
    top = '50%',
    left = '50%',
    right = 'auto',
    showClose = true,
    button,
    onButtonClick,
    isProfile
}) => {
    const modalStyle: CSSProperties = {
        position: 'fixed',
        top: top,
        left: left,
        right: right,
        transform: 'translate(-50%, -50%)',
        width: width,
        height: height,
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        overflow: 'hidden',
    };

    const headerStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '14px 20px',
    };

    const contentStyle: CSSProperties = {
        padding: '0px 20px 10px',
        overflowY: 'auto',
        maxHeight: height,
    };

    const footerStyle: CSSProperties = {
        padding: '14px 20px',
    };

    const withoutHeaderStyle: CSSProperties = {
        marginBottom: '1px',
    };

    const closeIconStyle: CSSProperties = {
        cursor: 'pointer',
        fontSize: '24px',
        marginLeft: 'auto', // Aligns the close icon to the right
    };

    const buttonContainerStyle: CSSProperties = {
        marginRight: '10px', // Adjust the margin as needed
    };

    const handleCloseIconClick = () => {
        onClose();
    };

    const handleButtonClick = () => {
        if (onButtonClick) {
            onButtonClick();
        }
    };

    return (
        <>
            <button style={{ zIndex: '999' }} className='fixed inset-0 bg-black bg-opacity-25 backdrop-sepia-1 flex justify-center items-center cursor-default left-0 top-0'>
                <div style={modalStyle} className='z-10'>
                    <div style={heading ? headerStyle : withoutHeaderStyle}>
                        <Text colorV="blue" variant="heading" weightV="bold">
                            {heading}
                        </Text>
                        <div className={`flex gap-x-4 items-center ${isProfile ? 'mr-3 mt-3' : ''}`}>
                            {button && (
                                <div style={buttonContainerStyle}>
                                    {button}
                                </div>
                            )}
                            {showClose && (
                                <span
                                    style={closeIconStyle}
                                    onClick={handleCloseIconClick}
                                >
                                    <Icon name="cross" color="black" size={25} />
                                </span>
                            )}
                        </div>
                    </div>
                    <div style={contentStyle}>{children}</div>
                    {footer && <div style={footerStyle}>{footer}</div>}
                </div>
            </button>
        </>
    );
};
