import React, { useCallback, useEffect, useState } from 'react';
import { DropdownButtons } from '../../../../../base/DropdownButton';
import { Column } from '../../../../../layout/Col';
import { Flex } from '../../../../../layout/Flex';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { ReportRMA } from './component/ReportRMA';
import { ProposeRepair } from './component/ProposeRepair';
import { Text } from '../../../../../base/Text';
import { ModalBox } from '../../../../../base/ModelBox';
import { useAuth } from '../../../../../../utils/auth';
import { useFetch } from '../../../../../../hooks/useFetch';
import { Button } from '../../../../../base/Button';
import { SubmitRma } from './component/SubmitRma';
import { Row } from '../../../../../layout/Row';
const baseUrl = process.env.REACT_APP_baseUrl;

export const SaleOrder = ({ data, onClose }: any) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null); // Track selected action
  const [isModalOpen, setModalOpen] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false)
  const auth = useAuth()
  const token = auth?.user?.token?.token

  const { getRequest: getSaleOrder, data: saleOrderData } = useFetch(
    `${baseUrl}/purchase/get-items-purchase/${data._id}`
  )
  useEffect(() => {
    getSaleOrder()
  }, [getSaleOrder])

  const refetch = useCallback(() => {
    getSaleOrder()
  }, [getSaleOrder])

  const openModal = (action, item) => {
    setSelectedAction(action); // Set the selected action
    setSelectedItem(item); // Set the selected item
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAction(null);
    setSelectedItem(null);
    setModalOpen(false);
  };
  const handleButtonClick = useCallback(() => {
    setShowSubmit(true)
  }, [])

  return (
    <>
      <ModalBox
        heading={`Sales Order${'\u00A0\u00A0\u00A0\u00A0'}  ${data.saleBatchNumber}`}
        onClose={onClose}
        top="50%"
        height="90%"
        width="90%"
        button={<Button backgroundColor='green' onClick={handleButtonClick}><Text weightV='normal' variant='body' colorV='white'>Submit</Text></Button>}
      >
        <Row className="mb-3 ml-5 h-full min-h-[200px]  max-h-full"  >
            <PrimaryCard className="w-full h-full">
              <table className="min-w-full text-left w-full  h-full table-auto text-b9blue bg-transparent">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2">IMEI/Serial number</th>
                    <th className="px-4 py-2">Model</th>
                    <th className="px-4 py-2">Storage</th>
                    <th className="px-4 py-2">VAT</th>
                    <th className="px-4 py-2">Sale Price</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {saleOrderData?.items?.map((item, rowIndex) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        key={rowIndex}
                        onClick={() => openModal('View', item)}
                      >
                        <td className="px-4 py-2">{item?.imei}</td>
                        <td className="px-4 py-2">{item?.model?.modelName}</td>
                        <td className="px-4 py-2">{item?.storage}</td>
                        <td className="px-4 py-2">{item?.vat}</td>

                        <td className="px-4 py-2">{item?.salePrice}</td>
                        <td
                          onClick={(e) => e.stopPropagation()}
                          className="px-4 py-2 relative"
                        >
                          {item?.rmaItemsStatus ? (
                            <Text colorV='green' weightV='normal' variant='title'>{item?.rmaItemsStatus}</Text>
                          ) : (
                            <DropdownButtons
                              buttonLabel="View"
                              items={['Report RMA', 'Propose repair']}
                              onItemClick={(selectedAction) =>
                                openModal(selectedAction, { ...item, saleStatus: status })
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </PrimaryCard>
        </Row>
        {isModalOpen && selectedAction === 'Report RMA' && (
          <ReportRMA data={data} item={selectedItem} refetch={refetch} onClose={closeModal} />
        )}

        {isModalOpen && selectedAction === 'Propose repair' && (
          <ProposeRepair data={data} item={selectedItem} refetch={refetch} onClose={closeModal} />
        )}
      </ModalBox>
      {showSubmit && <SubmitRma onClose={onClose} data={data} />}
    </>
  );
};
