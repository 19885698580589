import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { myDevice, operationSystemList, storageList } from '../../../../../../constant/Storage'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Text } from '../../../../../base/Text'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import Select from 'react-select'
import { useEdit } from '../../../../../../hooks/useEdit'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditModel: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [model, setModel] = useState<string>('')
    const [device, setDevice] = useState<string>('')
    const [storage, setStorage] = useState<any[]>([])
    const [prevStorage, setPrevStorage] = useState<any[]>([])
    const [os, setOs] = useState<any>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const auth = useAuth()
    const token = auth?.user?.token?.token
    const {sendEditRequest} = useEdit()
    useEffect(() => {
        if (item) {
            setModel(item?.Model)
            setDevice(item?.Device)
            setOs(item?.OS)
        }
    }, [item])

    const handleModelChange = (newValue: any) => {
        setModel(newValue)
    }
    const handleDeviceChange = (event: any) => {
        setDevice(event.target.value)
    }

    const handleOsChange = (newValue: any) => {
        setOs(newValue)
    }

    const handleStorageChange = useCallback((e: any) => {
        setStorage(Array.isArray(e) ? e.map((storage) => storage?.label) : [])
    }, [])


    const handleSaveChange = useCallback(async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, add mobile model')
            return
        } else if (!device) {
            alert('Please, add device ')
            return
        }
        setIsLoading(true)
        await sendEditRequest(
                `${baseUrl}/mobile/model-edit/${item?.id}`,
                {
                    modelName: model,
                    device,
                    os,
                    storage,
                },
            )
            .then((response) => {
                toast.success(response?.data, { hideProgressBar: true })
                addNotification({
                    message: 'Model has been updated successfully',
                    type: 'success',
                })
                addNotification({
                    message: 'Model has been updated successfully',
                    type: 'success',
                })
                onClose()
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
                toast.error(error?.response?.data, {
                    hideProgressBar: true,
                })
                setIsLoading(false)
            }
            )
    },[device, item?.id, model, onClose, os, sendEditRequest, storage])

    useEffect(() => {
        if(item){
            const localPrevStorage = item?.Storage ? item.Storage.split(',').map(value => value.trim()) : []
            setPrevStorage(localPrevStorage);
            setStorage(localPrevStorage)
        }

    }, [item]);

    const items = []
    if (prevStorage) {
        for (const storage of prevStorage) {
            const item = storageList?.find(
                (instance: any) => instance?.label === storage
            )
            if (item) {
                items.push(item)
            }
        }
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            position: "relative",
            boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : null,
            '&:hover': {
                borderColor: '#2684FF',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#2684FF',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: state.isSelected ? '#2684FF' : state.isFocused ? '#f0f0f0' : 'white',
            color: state.isSelected ? 'white' : '#333',
            '&:hover': {
                backgroundColor: '#2684FF',
                color: 'white',
            },
        }),
    };

    return (
        <ModalBox
            heading="Edit model"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[300px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <TextField
                        value={model}
                        onChange={handleModelChange}
                        placeholder="Enter model"
                        
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Device
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="device"
                            value={device}
                            onChange={handleDeviceChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {myDevice?.map((device: any) => (
                                <option key={device} value={device}>
                                    {device}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Operating system
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="device"
                            value={os}
                            onChange={handleOsChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {operationSystemList?.map((os: any) => (
                                <option key={os} value={os}>
                                    {os}
                                </option>
                            ))}
                        </select>
                    </div>

                </RowAlignCenterSpaced>{' '}
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Storage
                    </Text>

                    <div className="w-64">
                        {items?.length > 0 &&
                            items?.length === prevStorage?.length && (
                                <div className="w-full">
                                    <Select
                                        styles={customStyles}
                                        maxMenuHeight={90}
                                        defaultValue={items}
                                        options={storageList}
                                        onChange={handleStorageChange}
                                        isMulti
                                    />
                                </div>
                            )}
                        {prevStorage?.length === 0 && (
                            <div className="w-full">
                                <Select
                                    styles={customStyles}
                                    maxMenuHeight={90}
                                    defaultValue={items}
                                    options={storageList}
                                    onChange={handleStorageChange}
                                    isMulti
                                />
                            </div>
                        )}
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button backgroundColor="lgblue" onClick={handleSaveChange}>
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? "Updating..." : "Update model"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}




