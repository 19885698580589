import React, { useEffect, useState } from 'react'
import { Flex } from '../../../../../layout/Flex'
import { Table } from '../../../../../shared/Table'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { useFetch } from '../../../../../../hooks/useFetch'
import moment from 'moment'

const baseUrl = process.env.REACT_APP_baseUrl

export default function StocktakingSparepartTable() {
    const { getRequest: getStocktakingItems, data: itemsData } = useFetch(
        `${baseUrl}/stocktaking-sparepart/get-spareparts`
    )

    useEffect(() => {
        getStocktakingItems()
    }, [getStocktakingItems])

    let extractedData = []
    if (itemsData) {
        extractedData = itemsData?.map((item) => {
            return {
                SKU: item?.sku,
                QTY: item?.quantity,
                'Counted by': item?.countedBy?.email,
                Date: moment(item?.createdAt).format('DD/MM/YYYY'),
            }
        })
    }
    const columns = ['SKU', 'QTY', 'Counted by', 'Date']

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        options={['Edit', 'Delete', 'Download PDF']}
                        columns={columns}
                        data={extractedData}
                        showActions={false}
                    />
                </PrimaryCard>
            </Flex>
        </>
    )
}
