import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import Select from 'react-select'
import { useEdit } from '../../../../../hooks/useEdit'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { myCurrency } from '../../../../../constant/Storage'
import { useFetch } from '../../../../../hooks/useFetch'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditSparepart: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [sku, setSku] = useState('')
    const [name, setName] = useState('')
    const [models, setModels] = useState<any>([])
    const [quantity, setQuantity] = useState<any>()
    const [price, setPrice] = useState<any>()
    const [currency, setCurrency] = useState<string>('')
    const [preModel, setPreModel] = useState<any[]>([])
    const [selectedModels, setSelectedModels] = useState<any[]>([])

    const auth = useAuth()
    const { sendEditRequest } = useEdit()
    const token = auth?.user?.token?.token

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )
    const { getRequest: getSparePart, data: sparePartData } = useFetch(
        `${baseUrl}/stock/get-spare-part/${item?.ID}`
    )
    useEffect(() => {
        getModels()
        getSparePart()
    }, [getModels, getSparePart])

    useEffect(() => {
        const localModel = []
        modelsData?.forEach((element) => {
            localModel.push({ label: element?.modelName, value: element?._id })
        })
        const previousLocalModel = []
        sparePartData?.models?.forEach((element) => {
            previousLocalModel.push({
                label: element?.modelName,
                value: element?._id,
            })
        })
        setModels(localModel)
        setSku(sparePartData?.sku)
        setName(sparePartData?.name)
        setSelectedModels(previousLocalModel || [])
        setQuantity(sparePartData?.quantity)
        setPreModel(previousLocalModel || [])
        setPrice(sparePartData?.price)
        setCurrency(sparePartData?.currency)

    }, [
        modelsData,
        sparePartData,
        sparePartData?.models,
        sparePartData?.name,
        sparePartData?.currency,

        sparePartData?.quantity,
        sparePartData?.price,
        sparePartData?.sku,
    ])
    const handleModels = useCallback((e: any) => {
        const vals = Array.isArray(e) ? e.map((model) => model?.value) : []
        setSelectedModels(vals)
    }, [])

    const handleSkuChange = (newValue: any) => {
        setSku(newValue)
    }
    const handleNameChange = (newValue: any) => {
        setName(newValue)
    }

    const handleQuantityChange = (newValue: any) => {
        setQuantity(newValue)
    }

    const handleCurrencyChange = (event: any) => {
        setCurrency(event.target.value)
    }

    const handlePriceChange = (newValue: any) => {
        setPrice(newValue)
    }

    const items = []

    if (preModel) {
        for (const cod of preModel) {
            const item = models?.find(
                (instance: any) => instance?.label === cod?.label
            )
            if (item) {
                items.push(item)
            }
        }
    }

    const handleEditSpareparts = useCallback(async () => {
        if (!sku) {
            alert('Please, add SKU')
            return
        }
        if (!name) {
            alert('Please, add name')
            return
        }
        if (quantity === undefined) {
            alert('Please, enter quantity more then 0')
            return
        }
        if (price === undefined || price == 0) {
            alert('Please, enter price more then 0')
            return
        }
        if (selectedModels.length === 0) {
            alert('Please select models')
            return
        }
        const modelsParsed =
            typeof selectedModels[0] === 'string'
                ? selectedModels
                : selectedModels.map((model) => model?.value)
        await sendEditRequest(
            `${baseUrl}/stock/update/${item?.ID}`,
            {
                sku,
                name,
                modelVal: modelsParsed,
                quantity,
                price,
                currency,
            },
        )
            .then(() => {
                toast.success('Record Updated Successfully', {
                    hideProgressBar: true,
                })
                addNotification({
                    message: 'Sparepart updated successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) =>
                toast.error(`Error: ${error}`, { hideProgressBar: true })
            )
    }, [currency, item?.ID, name, onClose, price, quantity, selectedModels, sendEditRequest, sku])

    return (
        <ModalBox
            heading="Edit Spare parts"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[400px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        SKU
                    </Text>
                    <TextField
                        value={sku}
                        onChange={handleSkuChange}
                        placeholder="Enter SKU"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Name
                    </Text>
                    <TextField
                        value={name}
                        onChange={handleNameChange}
                        placeholder="Enter name"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Available quantity
                    </Text>
                    <TextField
                        value={quantity}
                        onChange={handleQuantityChange}
                        placeholder="Enter quantity"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Price
                    </Text>
                    <TextField
                        value={price}
                        onChange={handlePriceChange}
                        placeholder="supplier"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <div style={{ width: '220px' }}>
                        {items?.length > 0 &&
                            items?.length === preModel?.length && (
                                <Select
                                    id="model"
                                    defaultValue={items}
                                    options={models}
                                    onChange={handleModels}
                                    isMulti
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            )}
                        {preModel?.length === 0 && (
                            <Select
                                id="model"
                                defaultValue={items}
                                options={models}
                                onChange={handleModels}
                                isMulti
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                    }),
                                }}
                            />
                        )}
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Currency
                    </Text>
                    <div style={{ width: '220px' }}>
                        <select
                            id="currency"
                            value={currency}
                            onChange={handleCurrencyChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {myCurrency?.map((currency: any) => (
                                <option key={currency} value={currency}>
                                    {currency}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' onClick={onClose} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Back
                        </Text>
                    </Button>
                    <Button
                        className='w-32'
                        onClick={handleEditSpareparts}
                        backgroundColor="blue"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Save
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
