import React, { useCallback, useEffect } from 'react'
import { Flex } from '../../../../../layout/Flex'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { Button } from '../../../../../base/Button'
import { Text } from '../../../../../base/Text'
import { Row } from '../../../../../layout/Row'
import { MyIcon } from '../../../../../base/MyIcon'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useFetch } from '../../../../../../hooks/useFetch'

type Report = {
    createdAt: string
    parts: Array<{
        sku: string
        quantity: string
        countedBy?: {
            email: string
        }
        createdAt: string
    }>
}

const baseUrl = process.env.REACT_APP_baseUrl

export default function ReportTable() {
    const { getRequest: fetchReports, data: reportsData } = useFetch(
        `${baseUrl}/stocktaking-sparepart/generated-report`
    )

    useEffect(() => {
        fetchReports()
    }, [fetchReports])

    const generateCSV = (dataArray: Array<Record<string, string>>): string => {
        if (!dataArray.length) return ''

        const headers = Object.keys(dataArray[0])
        const rows = dataArray.map((row) =>
            headers.map((key) => row[key] || 'N/A').join(',')
        )

        return [headers.join(','), ...rows].join('\r\n')
    }

    const saveCsv = (blob: Blob, filename: string): void => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
    }

    const handleReportCSV = useCallback((report: Report): void => {
        if (!Array.isArray(report.parts) || !report.parts.length) {
            toast.error('Error: No data found', { hideProgressBar: true })
            return
        }

        const csvArray = report.parts.map((part) => ({
            SKU: part.sku || 'N/A',
            Quantity: part.quantity || 'N/A',
            'Counted By': part.countedBy?.email || 'N/A',
            'Scan Date':
                moment(part.createdAt).format('DD/MM/YYYY HH:mm:ss') || 'N/A',
        }))

        try {
            const csvContent = generateCSV(csvArray)
            if (!csvContent) throw new Error('CSV generation failed')

            const filename = `Stocktaking-spareparts-${moment().format(
                'DD.MM.YYYY'
            )}.csv`
            const blob = new Blob([csvContent], { type: 'text/csv' })
            saveCsv(blob, filename)
        } catch (error) {
            console.error('Error generating CSV:', error)
            toast.error('Error: Could not generate CSV file', {
                hideProgressBar: true,
            })
        }
    }, [])

    if (!reportsData) return <div>Loading...</div>

    return (
        <Flex className="mt-5 w-full">
            <PrimaryCard className="w-full">
                <div className="overflow-x-auto w-full text-left">
                    <table className="min-w-full w-full table-auto text-b9blue bg-transparent">
                        <thead className="bg-opacity-50">
                            <tr>
                                {['Date', 'Report', 'Actions'].map((header) => (
                                    <th
                                        key={header}
                                        className="px-4 py-2 text-left text-16 font-semibold font-inter-tight"
                                    >
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {reportsData.map((report, index) => (
                                <tr key={index} className="cursor-pointer">
                                    <td className="px-4 py-2">
                                        <Text
                                            colorV="blue"
                                            weightV="normal"
                                            variant="title"
                                        >
                                            {moment(report.createdAt).format(
                                                'DD.MM.YYYY'
                                            )}
                                        </Text>
                                    </td>
                                    <td className="px-4 py-2">
                                        <Text
                                            colorV="mid-blue"
                                            weightV="normal"
                                            variant="body"
                                        >
                                            Stocktaking - spareparts -{' '}
                                            {moment(report.createdAt).format(
                                                'DD.MM.YYYY'
                                            )}
                                        </Text>
                                    </td>
                                    <td className="px-4 py-2">
                                        <Button
                                            backgroundColor="transparent"
                                            onClick={() =>
                                                handleReportCSV(report)
                                            }
                                        >
                                            <Row className="gap-x-3">
                                                <MyIcon
                                                    size={20}
                                                    name="cloud"
                                                />
                                                <Text
                                                    colorV="blue"
                                                    weightV="normal"
                                                    variant="body"
                                                >
                                                    Download (CSV)
                                                </Text>
                                            </Row>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </PrimaryCard>
        </Flex>
    )
}
