import React from 'react'
import Head from './Head'

export const ManageProduction = () => {
    return (
        <>
            <Head />
        </>
    )
}
