import { useCallback, useEffect, useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import Head from './Head'
import PurchaseOrderTable from './PurchaseOrderTable'
import { Filter } from './Filter';
import { Row } from '../../../layout/Row';
const baseUrl = process.env.REACT_APP_baseUrl;

export const PurchaseOrders = () => {
    const [filteredData, setFilteredData] = useState(null); // State to store filtered data

    const { getRequest: getOrders, data: purchaseData } = useFetch(
        `${baseUrl}/purchase/company-purchase-orders`
    );

    useEffect(() => {
        getOrders();
    }, [getOrders]);
    const refetch = () => {
        getOrders();
    }
    const handleFilterChange = useCallback((data: any) => {
        setFilteredData(data)
    }, [])
    return (
        <>
            <Row className='gap-x-7'>
                <Head purchaseData={purchaseData} />
                <Filter itemsData={purchaseData} refetch={refetch} onDataChange={handleFilterChange} />
            </Row>
            <PurchaseOrderTable purchaseData={filteredData?.length > 0 ? filteredData : purchaseData} />
        </>
    )
}
