import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../base/Button'
import { Flex } from '../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { ExportContent } from './component/ExportModel'
import { Text } from '../../../base/Text'
import { useFetch } from '../../../../hooks/useFetch'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../../../base/Breadcrumb'
import { Column } from '../../../layout/Col'
import { Row } from '../../../layout/Row'
import { useBreadcrumb } from '../../../../hooks/useBreadcrumb'
import Header from '../../../shared/Header/Header'
import { Filter } from '../PurchaseOrders/Filter'

export default function Head({itemsData}:any) {
    const breadcrumbItems = useBreadcrumb()

    return (
        <Flex className="pb-3 ">
            <Column className='w-full'>
          <Header />
            <Column className="">
            <Row className="my-1.5 w-full">
              <Breadcrumb items={breadcrumbItems} separator="/" />
            </Row>
          </Column>     
        </Column>
        </Flex>
    )
}
