import { Icon } from "../../../../base/Icon"
import { PrimaryCard } from "../../../../compound/Page/PrimaryCard"
import { Column } from "../../../../layout/Col"
import { RowAlignCenterSpaced } from "../../../../layout/RowAlignCenterSpaced"
import { AtlasChart } from "../../../../shared/Chart"
import { Text } from "../../../../base/Text"
export const GraphInsights = () => {
    const chartData = [65, 59, 80, 81, 56, 55, 40]
    const chartLabels = [
        'Label 1',
        'Label 2',
        'Label 3',
        'Label 4',
        'Label 5',
        'Label 6',
        'Label 7',
    ]
    const chartType = 'line' // Change this to 'bar' or 'pie' as needed

    return (
        <>
            <RowAlignCenterSpaced className="gap-x-2 mt-5 w-full">
                <PrimaryCard >
                    <RowAlignCenterSpaced className="mb-5">
                        <Text colorV="blue" variant="title" weightV="bold">
                            Available Money
                        </Text>
                        <Icon name="dots" size={20} />
                    </RowAlignCenterSpaced>
                    <Column>
                        <AtlasChart
                            labels={chartLabels}
                            data={chartData}
                            chartType={chartType}
                        />
                    </Column>
                </PrimaryCard>
            </RowAlignCenterSpaced>
        </>
    )
}
