import React, { useRef, useState, useEffect, CSSProperties, useCallback } from 'react'
import { Text } from '../../base/Text'
import { useNavigate } from 'react-router-dom'

interface ItemData {
    imei: string
}

interface SkuData {
    sku: string
}

interface UserData {
    email: string
}

interface SearchResultsDropdownProps {
    item?: ItemData[]
    sku?: SkuData[]
    user?: UserData[]
    handleItemClick: (data: any) => void
}

export const SearchResultsDropdown: React.FC<any> = ({
    item,
    sku,
    user,
    handleItemClick,
    customer,
    isCustomer
}) => {
    const [showImei, setShowImei] = useState(true)
    const [showSku, setShowSku] = useState(true)
    const [showUser, setShowUser] = useState(true)
    const navigate = useNavigate()
console.log({item});
    const toggleImeiVisibility = () => {
        setShowImei(!showImei)
    }
    const toggleSkuVisibility = () => {
        setShowSku(!showSku)
    }

    const toggleUserVisibility = () => {
        setShowUser(!showUser)
    }

    const handleRmaItemClick = useCallback((item: any, type: string) => {
        console.log(item);
       if(isCustomer) {
        navigate(`/rma/detail/${item}`);
       } else {
        navigate(`/rma/manage-customer-rma/detail/${item}`);
       }
    }, [isCustomer, navigate])

    return (
        <div className="bg-base rounded-md overflow-y-auto">
            <div>
                <div className='overflow-y-auto max-h-52'>
                    {user && Array.isArray(user) && user.length > 0 ? (
                        <div style={{ borderBottom: '1px solid  #D3D3D3' }}>
                            <div className="py-1  flex  items-center justify-between px-3 m-3 rounded-md bg-white">
                                <Text
                                    colorV="blue"
                                    weightV="bold"
                                    variant="title"
                                >
                                    People ({user?.length})
                                </Text>
                                <span
                                    className="flex cursor-pointer items-center"
                                    onClick={toggleUserVisibility}
                                >
                                    <svg
                                        className={`w-2.5 h-2.5 ml-2.5 transition-transform transform ${showUser ? 'rotate-180' : ''
                                            }`}
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 10 6"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 4 4 4-4"
                                        />
                                    </svg>
                                </span>
                            </div>
                            {showUser &&
                                user?.map((userData, userIndex) => (
                                    <div
                                        key={userIndex}
                                        className="p-2 mx-3 overflow-auto cursor-pointer transition duration-300 hover:bg-gray-100"
                                        onClick={() =>
                                            handleItemClick(userData, 'user')
                                        }
                                        style={{
                                            padding: '10px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Text
                                            colorV="blue"
                                            weightV="normal"
                                            variant="body"
                                        >
                                            {userData?.email}
                                        </Text>
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div className="mx-3 p-2.5">
                            <Text colorV="blue" weightV="normal" variant="body">
                                {customer ? '' : " No user found"}
                            </Text>
                        </div>
                    )}
                </div>
                {item && Array.isArray(item) && item.length > 0 ? (
                    <div
                        className="overflow-y-auto max-h-52"
                        style={{ borderBottom: '1px solid  #D3D3D3' }}
                    >
                        <div className="py-1 flex items-center justify-between px-3 m-3 rounded-md bg-white">
                            <Text colorV="blue" weightV="bold" variant="title">
                                IMEI ({item.length})
                            </Text>
                            <span
                                className="cursor-pointer"
                                onClick={toggleImeiVisibility}
                            >
                                <svg
                                    className={`w-2.5 h-2.5 ml-2.5 transition-transform transform ${showImei ? 'rotate-180' : ''
                                        }`}
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </span>
                        </div>
                        {showImei &&
                            item.map((itemData, itemIndex) => (
                                <div
                                key={itemIndex}
                                onClick={isCustomer ? undefined : () => handleItemClick(itemData, 'item')}
                                className={`p-2 mx-3 overflow-y-auto overflow-x-auto transition duration-300 hover:bg-gray-100 ${isCustomer ? 'cursor-default' : 'cursor-pointer'}`}
                            >
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="body"
                                    >
                                        {itemData.imei}
                                    </Text>
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="mx-3 p-2.5">
                        <Text colorV="blue" weightV="normal" variant="body">
                            No imei found
                        </Text>
                    </div>
                )}
            </div>
            <div>
                {item && Array.isArray(item) && item.length > 0 && (
                    <div className="overflow-y-auto my-1 max-h-40" >
                        {
                            item.filter(itemData => itemData?.purchaseId?.saleBatchNumber).map((itemData, itemIndex) => (
                                <div
                                    key={itemIndex}
                                    onClick={() =>
                                        handleItemClick(itemData, 'pon')
                                    }
                                    className="p-1 px-3 mx-3 mb-1 flex flex-row rounded-md bg-white justify-between cursor-pointer overflow-auto transition duration-300 hover:bg-gray-100"
                                >
                                    <Text colorV="blue" weightV="bold" variant="title">
                                        PON
                                    </Text>
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="body"
                                    >
                                        {itemData?.purchaseId?.saleBatchNumber}
                                    </Text>
                                </div>
                            ))}
                    </div>
                )
                //  : (
                //     <div className="mx-3 p-2.5">
                //         <Text colorV="blue" weightV="normal" variant="body">
                //             No PON found
                //         </Text>
                //     </div>
                // )
                }
            </div>
            <div>
                {item && Array.isArray(item) && item.length > 0 && (
                    <div className="overflow-y-auto mb-1 max-h-40" >
                        {
                            item.filter(itemData => itemData.rmaNumber).map((itemData, itemIndex) => (
                                <div
                                    key={itemIndex}
                                    onClick={() =>
                                        handleRmaItemClick(itemData.rma?._id, 'rma')
                                    }
                                    className="px-3 py-1 mx-3 mb-1 cursor-pointer justify-between flex flex-row overflow-auto rounded-md bg-white transition duration-300 hover:bg-gray-100"
                                >
                                    <Text colorV="blue" weightV="bold" variant="title">
                                        RMA
                                    </Text>
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="body"
                                    >
                                        {itemData.rmaNumber}
                                    </Text>
                                </div>
                            ))}
                    </div>
                ) 
                // : (
                //     <div className="mx-3 p-2.5">
                //         <Text colorV="blue" weightV="normal" variant="body">
                //             No RMA found
                //         </Text>
                //     </div>
                // )
                }
            </div>
            <div>
                {item && Array.isArray(item) && item.length > 0 && (
                    <div className="overflow-y-auto max-h-40" >
                        {
                           !isCustomer && item.filter(itemData => itemData.invoiceNumber).map((itemData, itemIndex) => (
                                <div
                                    key={itemIndex}
                                    onClick={() =>
                                        handleItemClick(itemData, 'invoice')
                                    }
                                    className="px-3 py-1 mx-3 mb-1 cursor-pointer justify-between flex flex-row overflow-auto rounded-md bg-white transition duration-300 hover:bg-gray-100"
                                >
                                    <Text colorV="blue" weightV="bold" variant="title">
                                        Invoice
                                    </Text>
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="body"
                                    >
                                        {itemData.invoiceNumber}
                                    </Text>
                                </div>
                            ))}
                    </div>
                )
                //  : (
                //     <div className="mx-3 p-2.5">
                //         <Text colorV="blue" weightV="normal" variant="body">
                //             No Invoice found
                //         </Text>
                //     </div>
                // )
                }
            </div>
            <div>
                {sku && Array.isArray(sku) && sku.length > 0 ? (
                    <div className='overflow-y-auto max-h-52' style={{ borderBottom: '1px solid  #D3D3D3' }}>
                        <div className="py-1 flex items-center justify-between px-3 m-3 rounded-md bg-white">
                            <Text colorV="blue" weightV="bold" variant="title">
                                SKU ({sku.length})
                            </Text>
                            <span
                                className="cursor-pointer"
                                onClick={toggleSkuVisibility}
                            >
                                <svg
                                    className={`w-2.5 h-2.5 ml-2.5 transition-transform transform ${showSku ? 'rotate-180' : ''
                                        }`}
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </span>
                        </div>
                        {showSku &&
                            sku.map((skuData, skuIndex) => (
                                <div
                                    key={skuIndex}
                                    className="p-2 mx-3 cursor-pointer transition overflow-auto duration-300 hover:bg-gray-100"
                                    onClick={() =>
                                        handleItemClick(skuData, 'sku')
                                    }
                                    style={{
                                        padding: '10px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Text
                                        colorV="blue"
                                        weightV="normal"
                                        variant="body"
                                    >
                                        {skuData.sku}
                                    </Text>
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="mx-3 p-2.5">
                        <Text colorV="blue" weightV="normal" variant="body">
                            {customer ? "" : "No sku found"}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    )
}
