import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { ProposedRepairModal } from './Modal/ProposedRepairModal'

export const RmaProposedRepair = ({ data, refetch }: any) => {
    const [showModal, setShowModal] = useState<any>(false)
    const [selectedData, setSelectedData] = useState<any>()
    console.log(data)
    const columns = [
        'PON',
        'Purchase date',
        'IMEI',
        'Model',
        'Storage',
        'Purchase price',
        'VAT',
        'Reason',
        'Price proposal',
        'Status',
    ]
    console.log({ data })
    let extractedData = []
    if (data) {
        const filteredItems = data?.items?.filter(item =>
            item?.rmaItemsStatus === 'proposed_repair'
        );
        extractedData = filteredItems.map((item) => {
            return {
                id: item?._id,
                PON: data?.purchaseOrder?.saleBatchNumber,
                'Purchase date': moment(item?.salePrice).format('DD.MM.YYYY'),
                IMEI: item?.imei,
                Model: item?.model?.modelName,
                Storage: item?.storage,
                'Purchase price': item?.salePrice,
                VAT: item?.vat,
                'Reason': item?.reasonOfReturn,
                'Price proposal': item?.priceProposal,
                Status:item?.acceptance,
            };
        });
    }

    const handleRowClick = useCallback((item) => {
        setSelectedData(item)
        setShowModal(true)
    }, [])

    const onClose = useCallback(() => {
        setShowModal(false)
        setSelectedData('')
        refetch()
    }, [refetch])

    return (
        <>
            <PrimaryCard className="w-full">
                <div className="overflow-auto w-full rounded-lg text-left" style={{ maxWidth: '100%', width: '100%', overflowX: 'auto' }}>
                    <Table
                        handleRowClick={handleRowClick}
                        columns={columns}
                        data={extractedData}
                        clickable={true}
                        showActions={false}
                    />
                </div>
            </PrimaryCard>
            {showModal && <ProposedRepairModal data={data} onClose={onClose} item={selectedData} />}

        </>

    )
}
