import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { useEdit } from '../../../../../hooks/useEdit'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const ArchiveRmaModel: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const { sendEditRequest } = useEdit()
    const handleArchive = useCallback(async (e: any) => {
        e.stopPropagation()
        await sendEditRequest(
            `${baseUrl}/rma/status/${item?._id}`,
            {
                status: true
            },
        )
            .then((response) => {
                toast.success(response?.data, {
                    hideProgressBar: false,
                })
                addNotification({
                    message: 'RMA has been archived successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) =>
                toast.error(error?.response?.data?.message, {
                    hideProgressBar: false,
                })
            )
    }, [item?._id, onClose, sendEditRequest])

    return (
        <ModalBox
            heading="Archive RMA"
            onClose={onClose}
            top="50%"
            height="270px"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 items-center text-center">
                <Text colorV="blue" variant="head" weightV="normal">
                    Are you sure you want to Archive <br />
                    {item?.rmaNumber} with {item?.items.length} devices
                </Text>
                <Flex className="gap-x-4 mt-4">
                    <Button backgroundColor="lgblue" onClick={handleArchive}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Yes
                        </Text>
                    </Button>
                    <Button backgroundColor="lgblue" onClick={onClose}>
                        <Text colorV="white" variant="title" weightV="normal">
                            No
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
