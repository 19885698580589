import React, { useCallback, useEffect, useRef } from 'react';
import { ModalBox } from '../../../../base/ModelBox';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { Text } from '../../../../base/Text';
import { Button } from '../../../../base/Button';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'react-toastify';
import moment from 'moment';
import JsBarcode from 'jsbarcode';
import { useFetch } from '../../../../../hooks/useFetch';
import { Column } from '../../../../layout/Col';

const baseUrl = process.env.REACT_APP_baseUrl;

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};

// ... (other imports)

export const PrintLabel: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const barCode = useRef<HTMLDivElement>(null);
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);

    useEffect(() => {
        JsBarcode('#barcode', `${item?.item?.imei}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 2.5,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        });
    }, [barCode, item?.item?.imei]);

    const handlePrintlabelPDF = useCallback(async () => {
        if (item) {
            const barcodeSvg = `<svg id="barcode">${barCode.current?.innerHTML}</svg>`;
            const docDefinition = {
                content: [
                    labelsData?.labels[1]?.isLabel && {
                        svg: barcodeSvg,
                        width: 200,
                        height: 35,
                        margin: [150, 0, 0, 5],

                    },
                    labelsData?.labels[1]?.isLabel && {
                        text: item?.item?.imei,
                        margin: [200, 0, 0, 5],
                    },
                    {
                        columns: [
                            {
                                stack: [
                                    labelsData?.labels[0]?.isLabel && { text: 'Model :     ' + (item?.item?.model?.modelName || ''), margin: [0, 0, 0, 3,], },
                                    labelsData?.labels[2]?.isLabel && { text: 'Storage :  ' + (item?.item?.storage || ''), margin: [0, 0, 0, 3,], },
                                    labelsData?.labels[4]?.isLabel && { text: 'VAT :         ' + (item?.item?.vat || ''), margin: [0, 0, 0, 3,], },
                                    labelsData?.labels[8]?.isLabel && { text: 'Status :      ' + (item?.item?.sortingEventKey || ''), margin: [0, 0, 0, 0,], },
                                ].filter(Boolean),
                                width: 200, // Set the width of this column to take up remaining space
                            },
                            // {
                            //     stack: [
                            //         labelsData?.labels[9]?.isLabel && { text: 'SKU :  ' + (item?.item?.sku?.sku || ''), margin: [0, 20, 0, 2,], },
                            //     ].filter(Boolean),
                            //     width: 150, // Set the width of this column to fit content
                            // },
                            {
                                stack: [
                                    labelsData?.labels[7]?.isLabel && { text: 'Purchase Order :   ' + (item?.item?.purchaseId?.saleBatchNumber || ''), margin: [0, 0, 0, 3,], },
                                    labelsData?.labels[3]?.isLabel && { text: 'Color :            ' + (item?.item?.color || ''), margin: [0, 0, 0, 3,], },
                                    labelsData?.labels[5]?.isLabel && { text: 'Grade :            ' + (item?.item?.grade?.grade || ''), margin: [0, 0, 0, 3,], },
                                    labelsData?.labels[6]?.isLabel && { text: 'Battery :          ' + (item?.item?.battery || ''), margin: [0, 0, 0, 0,], },
                                    labelsData?.labels[9]?.isLabel && { text: 'SKU :               ' + (item?.item?.sku?.sku || ''), margin: [0, 20, 0, 2,], },
                                ].filter(Boolean),
                                width: 200, // Set the width of this column to fit content  
                            },
                        ],


                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
                pageMargins: [10, 0, 10, 0], // Top, Left, Bottom, Right
            };

            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('data');
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [item, labelsData?.labels]);



    return (
        <ModalBox
            height="auto"
            width="auto"
            heading="Print label"
            onClose={onClose}
        >
            <RowAlignCenter>
                <Text colorV="blue" weightV="bold" variant="head">
                    Attention Required!
                </Text>
            </RowAlignCenter>
            <Column className="mt-5 text-center gap-y-4">
                <div ref={barCode} className="py-2 px-2">
                    <svg id="barcode"></svg>
                </div>
                <Button onClick={handlePrintlabelPDF} backgroundColor="purple">
                    <Text colorV="white" variant="title" weightV="normal">
                        Download Label
                    </Text>
                </Button>
            </Column>
        </ModalBox>
    );
};


