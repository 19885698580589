import React, { useCallback, useEffect, useState } from 'react'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { Row } from '../../../layout/Row'
import { TextField } from '../../../base/Textfield'
import { Column } from '../../../layout/Col'
import { Text } from '../../../base/Text'
import { Button } from '../../../base/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../utils/auth'
import { ModalBox } from '../../../base/ModelBox'
import { TextArea } from '../../../base/Textarea'
import { useFetch } from '../../../../hooks/useFetch'
import moment from 'moment'
import { useEdit } from '../../../../hooks/useEdit'
import { addNotification } from '../../../shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

export const EditUser: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reset, setIsReset] = useState(false)
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendEditRequest } = useEdit()
    const { getRequest, data } = useFetch(
        `${baseUrl}/user/get-user/${item?.id}`
    )

    useEffect(() => {
        getRequest()
    }, [getRequest])
    const [formState, setFormState] = useState({
        companyName: '',
        adminEmail: '',
        firstName: '',
        lastName: '',
        companyWebPage: '',
        status: '',
        manager: '',
        companyAddress: '',
        currency: '',
        internalComment: '',
        zip: '',
        vatNumber: '',
        email: '',
        phoneNumber: '',
        nextInvoiceDate: '',
        contractEndingDate: '',
        subscription: '',
        selectedRoles: [],
        country: '',
        billingEmail: '',
    })

    useEffect(() => {
        if (data) {
            setFormState({
                companyName: data?.parent[0]?.companyName || '',
                adminEmail: data?.email || '',
                firstName: data?.firstName || '',
                lastName: data?.lastName || '',
                companyWebPage: data?.parent[0]?.companyWebPage || '',
                status: data?.parent[0]?.status || '',
                manager: data?.accountManager || '',
                companyAddress: data?.parent[0]?.billing?.companyAddress || '',
                currency: data?.parent[0]?.billing?.currency || '',
                internalComment: data?.parent[0]?.billing?.internalComment || '',
                zip: data?.parent[0]?.billing?.zip || '',
                vatNumber: data?.parent[0]?.billing?.vat || '',
                email: data?.parent[0]?.billing?.billingEmail || '',
                phoneNumber: data?.parent[0]?.billing?.phoneNumber || '',
                nextInvoiceDate: data?.parent[0]?.nextInvoiceDate || '',
                contractEndingDate: data?.parent[0]?.contractEndingDate || '',
                subscription: data?.parent[0]?.subscriptionType || '',
                selectedRoles: data?.parent[0]?.permissions || [],
                country: data?.parent[0]?.billing?.country || '',
                billingEmail: data?.parent[0]?.billing?.billingEmail || '',
            })
        }
    }, [data])

    const handleFieldChange = (field, value) => {
        setFormState({
            ...formState,
            [field]: value,
        })
    }

    const basicRoles = ['Management', 'Inventory', 'Sales']
    const additionalRoles = [
        'Customer portal',
        'RMA',
        'My RMA',
        'Stocktaking + App',
        'Grading',
        'Manual Sorting',
        'Diagnostics',
        'Polishing',
        "Transit",
        "Repair"
    ]

    const handleRoleChange = (role) => {
        const updatedRoles = formState.selectedRoles.includes(role)
            ? formState.selectedRoles.filter((r) => r !== role)
            : [...formState.selectedRoles, role]

        handleFieldChange('selectedRoles', updatedRoles)
    }

    const handleUpdate = async () => {
        setIsLoading(true)
        try {
            const response = await axios.put(
                `${baseUrl}/user/user-edit/${item?.id}`,
                {
                    formState,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }

            )

            if (response.status === 200) {
                toast.success('User Updated', { hideProgressBar: true })
                addNotification({
                    message: 'User has been updated successfully',
                    type: 'success',
                })
                onClose()
                setIsLoading(false)
            } else {
                toast.error('Failed to update user', { hideProgressBar: true })
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error updating user:', error)
            toast.error('An error occurred while updating user', {
                hideProgressBar: true,
            })
        }
    }

    const handleResetPassword = useCallback(async () => {
        setIsReset(true)
        try {
            const response = await axios.put(
                `${baseUrl}/auth/company-reset-password/${item?.id}`,
                {
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )

            if (response.status === 200) {
                toast.success(response?.data?.message, { hideProgressBar: true })
                addNotification({
                    message: 'Password has been reset successfully',
                    type: 'success',
                })
                onClose()
                setIsReset(false)
            } else {
                toast.error('Failed to send email', { hideProgressBar: true })
            }
        } catch (error) {
            setIsReset(false)
            console.error('Error sending email:', error)
            toast.error('An error occurred while sending email', {
                hideProgressBar: true,
            })
        }
    }, [item?.id, onClose, token])

    return (
        <ModalBox
            heading=""
            onClose={onClose}
            top="50%"
            height="auto" // Adjust this value to your preference
            width="90vw" // Adjust this value to your preference
        >
            <Row className="w-full gap-x-10 h-[400px] overflow-auto">
                <Column className="w-1/2 gap-y-3">
                    <Text colorV="blue" variant="heading" weightV="normal">
                        User management
                    </Text>
                    <Column className="mt-3 gap-y-4">
                        <RowAlignCenterSpaced className="gap-x-10">
                            <Text colorV="blue" variant="title" weightV="bold">
                                Company name
                            </Text>
                            <TextField
                                value={formState.companyName}
                                onChange={(newValue) =>
                                    handleFieldChange('companyName', newValue)
                                }
                                placeholder="Enter company name"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Main admin Email
                            </Text>
                            <TextField
                                value={formState.adminEmail}
                                onChange={(newValue) =>
                                    handleFieldChange('adminEmail', newValue)
                                }
                                placeholder="Enter admin email"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                First name
                            </Text>
                            <TextField
                                value={formState.firstName}
                                onChange={(newValue) =>
                                    handleFieldChange('firstName', newValue)
                                }
                                placeholder="Enter first name"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Last name
                            </Text>
                            <TextField
                                value={formState.lastName}
                                onChange={(newValue) =>
                                    handleFieldChange('lastName', newValue)
                                }
                                placeholder="Enter last name"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Company Web Page
                            </Text>
                            <TextField
                                value={formState.companyWebPage}
                                onChange={(newValue) =>
                                    handleFieldChange(
                                        'companyWebPage',
                                        newValue
                                    )
                                }
                                placeholder="Enter company web page"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Status
                            </Text>
                            <div style={{ width: '14rem' }}>
                                <select
                                    id="status"
                                    value={formState.status}
                                    onChange={(e) =>
                                        handleFieldChange(
                                            'status',
                                            e.target.value
                                        )
                                    }
                                    className="w-full hover:cursor-pointer text-sm   py-2 px-1"
                                >
                                    <option>Select</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Manager
                            </Text>
                            <TextField
                                value={formState.manager}
                                onChange={(newValue) =>
                                    handleFieldChange('manager', newValue)
                                }
                                placeholder="Enter manager"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Next invoice date
                            </Text>
                            <TextField
                                type="date"
                                value={moment(formState.nextInvoiceDate).format(
                                    'YYYY-MM-DD'
                                )}
                                onChange={(newValue) =>
                                    handleFieldChange(
                                        'nextInvoiceDate',
                                        newValue
                                    )
                                }

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Contract ending date
                            </Text>
                            <TextField
                                type="date"
                                value={moment(
                                    formState.contractEndingDate
                                ).format('YYYY-MM-DD')}
                                onChange={(newValue) =>
                                    handleFieldChange(
                                        'contractEndingDate',
                                        newValue
                                    )
                                }

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Subscription type
                            </Text>
                            <div style={{ width: '14rem' }}>
                                <select
                                    id="subscription"
                                    value={formState.subscription}
                                    onChange={(e) =>
                                        handleFieldChange(
                                            'subscription',
                                            e.target.value
                                        )
                                    }
                                    className="w-full hover:cursor-pointer text-sm py-2 px-1"
                                >
                                    <option>Select</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Yearly">Yearly</option>
                                </select>
                            </div>

                        </RowAlignCenterSpaced>
                    </Column>
                </Column>
                <Column className="w-1/2 gap-y-3">
                    <Text colorV="blue" variant="heading" weightV="normal">
                        Billing information
                    </Text>
                    <Column className="mt-3 gap-y-4">
                        <RowAlignCenterSpaced className="gap-x-10">
                            <Text colorV="blue" variant="title" weightV="bold">
                                Company address
                            </Text>
                            <TextField
                                value={formState.companyAddress}
                                onChange={(newValue) =>
                                    handleFieldChange(
                                        'companyAddress',
                                        newValue
                                    )
                                }
                                placeholder="Enter company address"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Zip
                            </Text>
                            <TextField
                                value={formState.zip}
                                onChange={(newValue) =>
                                    handleFieldChange('zip', newValue)
                                }
                                placeholder="Enter zip"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Country
                            </Text>
                            <TextField
                                value={formState.country}
                                onChange={(newValue) =>
                                    handleFieldChange('country', newValue)
                                }
                                placeholder="Enter country"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                VAT number
                            </Text>
                            <TextField
                                value={formState.vatNumber}
                                onChange={(newValue) =>
                                    handleFieldChange('vatNumber', newValue)
                                }
                                placeholder="Enter VAT number"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Email
                            </Text>
                            <TextField
                                value={formState.billingEmail}
                                onChange={(newValue) =>
                                    handleFieldChange('billingEmail', newValue)
                                }
                                placeholder="Enter email"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Phone number
                            </Text>
                            <TextField
                                value={formState.phoneNumber}
                                onChange={(newValue) =>
                                    handleFieldChange('phoneNumber', newValue)
                                }
                                placeholder="Enter phone number"

                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Currency
                            </Text>
                            <div style={{ width: '14rem' }}>
                                <select
                                    id="currency"
                                    value={formState.currency}
                                    onChange={(e) =>
                                        handleFieldChange(
                                            'currency',
                                            e.target.value
                                        )
                                    }
                                    className="w-full border hover:cursor-pointer text-sm rounded-lg py-2 px-1"
                                >
                                    <option>Select</option>
                                    <option value="USD">Dollor</option>
                                    <option value="EUR">Euro</option>
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Internal comment
                            </Text>
                            <TextArea
                                value={formState.internalComment}
                                onChange={(newValue) =>
                                    handleFieldChange(
                                        'internalComment',
                                        newValue
                                    )
                                }

                                height="80px"
                                className="w-56"
                            />
                        </RowAlignCenterSpaced>
                    </Column>
                </Column>
                <Column className="w-1/3 gap-y-3">
                    <Text colorV="blue" variant="heading" weightV="normal">
                        Functionality
                    </Text>
                    <Column className="mt-3 w-full gap-y-4">
                        <Text colorV="blue" variant="title" weightV="bold">
                            Basic
                        </Text>
                        <div className="">
                            {basicRoles.map((role) => (
                                <RowAlignCenterSpaced
                                    key={role}
                                    className="mb-3 "
                                >
                                    <span className="mr-5">{role}</span>
                                    <input
                                        type="checkbox"
                                        checked={formState.selectedRoles.includes(
                                            role
                                        )}
                                        onChange={() => handleRoleChange(role)}
                                    />
                                </RowAlignCenterSpaced>
                            ))}
                        </div>
                        <Column className="gap-y-2">
                            <Text colorV="blue" variant="title" weightV="bold">
                                Additional
                            </Text>
                            <div className="w-full">
                                {additionalRoles.map((role) => (
                                    <RowAlignCenterSpaced
                                        key={role}
                                        className="mb-3"
                                    >
                                        <span>{role}</span>
                                        <input
                                            type="checkbox"
                                            checked={formState.selectedRoles.includes(
                                                role
                                            )}
                                            onChange={() =>
                                                handleRoleChange(role)
                                            }
                                        />
                                    </RowAlignCenterSpaced>
                                ))}
                            </div>
                        </Column>
                    </Column>
                </Column>
            </Row>
            <Row className="mt-5 gap-x-5">
                <Button
                    onClick={handleUpdate}
                    className="w-52"
                    backgroundColor="lgblue"
                    disabled={isLoading}
                >
                    <Text colorV="white" variant="title" weightV="normal">
                        {isLoading ? 'Updating..' : ' Update user'}
                    </Text>
                </Button>
                <Button
                    onClick={handleResetPassword}
                    className="w-52"
                    backgroundColor="blue"
                    disabled={isLoading}
                >
                    <Text colorV="white" variant="title" weightV="normal">
                        {reset ? "Resting..." : "Reset password"}
                    </Text>
                </Button>
            </Row>
        </ModalBox>
    )
}
