
import React, { useState, useEffect, useRef } from 'react';
import { MyIcon } from '../MyIcon';
import loadingGif from '../../../assets/loading.gif'
interface SearchBarProps {
  width?: string;
  onSearch?: (searchTerm: string) => void;
  onSearchBarClick?: () => void;
  zIndex?: string;
  disabled?: boolean;
  className?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  isLoading?: boolean
}

export const SearchBarGlobal: React.FC<SearchBarProps> = ({
  width = '550px',
  onSearch,
  onSearchBarClick,
  zIndex,
  disabled,
  className,
  inputRef: externalInputRef,
  isLoading
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const localInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus on the input element when the component mounts
    if (localInputRef.current) {
      localInputRef.current.focus();
    }
  }, []);

  const inputStyle: React.CSSProperties = {
    flex: 1,
    border: 'none',
    background: 'none',
    outline: 'none',
    padding: '5px',
    color: '#112D56B2',
    width: width,
    fontSize: '13px',
  };

  const iconStyle: React.CSSProperties = {
    marginRight: '5px',
    paddingLeft: '5px',
    fontSize: '20px',
    color: 'white',
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    setSearchTerm(inputText);

    setTimeout(() => {
      onSearch && onSearch(inputText);
    }, 1000);
  };

  const handleSearchBarClick = () => {
    onSearchBarClick && onSearchBarClick();
    if (localInputRef.current) {
      localInputRef.current.focus();
    }
  };

  return (
    <div
      style={{ height: 40, zIndex: zIndex }}
      className={`flex items-center rounded border bg-base01 p-2 ${className}`}
      onClick={handleSearchBarClick}
    >
      <span style={iconStyle}>
        <MyIcon name="search" size={20} />
      </span>
      <input
        ref={externalInputRef || localInputRef}
        type="text"
        disabled={disabled}
        style={inputStyle}
        placeholder="Search..."
        className="w-full"
        value={searchTerm}
        onChange={handleInputChange}
      />
      {isLoading ? (
        <span style={{ marginLeft: '5px', color: '#6B7280' }}>
          <img src={loadingGif} alt="Loading..." style={{ width: '24px', height: '24px' }} />
        </span>
      ) : null}
    </div>
  );
};

