import axios from 'axios'
import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Text } from '../../../../../base/Text'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const DeleteRepairScore: React.FC<{
    onClose: () => void
    item: any
}> = ({ onClose, item }) => {
    const auth = useAuth()
    const token = auth?.user?.token?.token
const company = auth.selectedCompany
    const handleDelete = useCallback(async() => {
       await axios
            .delete(`${baseUrl}/codePoint/delete-codepoint/${item?.id}`, {
                headers: {
                    Authorization: token as string,
                    Company: company._id
                },
            })
            .then((response) => {
                toast.success(response.data)
                addNotification({
                    message: 'Code deleted successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            )
    },[company._id, item?.id, onClose, token])

    return (
        <ModalBox
            heading="Delete code"
            onClose={onClose}
            top="50%"
            height="270px"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 items-center text-center">
                <Text colorV="blue" variant="head" weightV="normal">
                    Are you sure you want to delete <br />
                    {item?.Code}, {item?.Model} {item?.Score}
                </Text>
                <Flex className="gap-x-4 mt-4">
                    <Button backgroundColor="lgblue" onClick={handleDelete}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Yes
                        </Text>
                    </Button>
                    <Button backgroundColor="lgblue" onClick={onClose}>
                        <Text colorV="white" variant="title" weightV="normal">
                            No
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
