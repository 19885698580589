import React, { useCallback, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { toast } from 'react-toastify'
import { Row } from '../../../../layout/Row'
import { usePost } from '../../../../../hooks/usePost'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const SparepartsDetail: React.FC<{
    onClose: () => void
    item: any
    sparepartsData: any
}> = ({ onClose, item, sparepartsData }) => {
    const [quantity, setQuantity] = useState<any>('')
    const [sparePartId, setSparePartId] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const { sendRequest } = usePost()

    const handleQuantity = (e: any) => {
        setQuantity(e.target.value)
    }
    const handleSparePartsId = (e) => {
        setSparePartId(e.target.value)
    }
    const handleAddSparePart = useCallback(async () => {
        if (quantity === '') {
            alert('please add quantity')
        }
        setIsLoading(true)
        await sendRequest(
            `${baseUrl}/item/add-spare-part/${item?._id}`,
            {
                sparePartId,
                pieces: Number(quantity),
            },
        )
            .then(() => {
                onClose()
                toast.success('Spare part added', { hideProgressBar: true })
                addNotification({
                    message: 'Spare part added',
                    type: 'success',
                })
                setIsLoading(false)
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message)
                console.error(`Error: ${error}`)
                setIsLoading(false)
            })
    }, [item?._id, onClose, quantity, sendRequest, sparePartId])

    return (
        <ModalBox
            heading="Add Spare parts"
            onClose={onClose}
            top="50%"
            height="auto"
            width="300px"
        >
            <Column className="gap-y-4 h-[250px] overflow-auto">
                <Row className="w-full">
                    <Text variant="title" colorV="blue" weightV="normal">
                        Spare part
                    </Text>
                </Row>
                <Row className='w-full'>
                    <select className="h-8 w-full" onChange={handleSparePartsId}>
                        <option value="">Select</option>
                        {sparepartsData?.map((sparepart: any) => (
                            <option
                                key={sparepart?.value}
                                value={sparepart?.value}
                            >
                                {item?.model?.modelName} - {sparepart?.label}
                            </option>
                        ))}
                    </select>
                </Row>
                <Row>
                    <Text variant="title" colorV="blue" weightV="normal">
                        Quantity
                    </Text>
                </Row>
                <Row>
                    <select className="h-8 w-full" onChange={(e) => handleQuantity(e)}>
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                </Row>
                <Flex className="gap-x-4 mt-4">
                    <Button
                        className='w-full'
                        onClick={handleAddSparePart}
                        backgroundColor="lgblue"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? "Adding.." : "Add"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
