import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { myVat } from '../../../../../constant/Storage'
import { storageList } from '../../../../../constant/Storage'
import { operationSystemList } from '../../../../../constant/Storage'
import { myDevice } from '../../../../../constant/Storage'
import { useFetch } from '../../../../../hooks/useFetch'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { Dropdown } from '../../../../base/Dropdown'
import { TextArea } from '../../../../base/Textarea'
import { useEdit } from '../../../../../hooks/useEdit'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { generatePDF } from '../../../../shared/GenerateLabel'
import JsBarcode from 'jsbarcode'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl
    ; (pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
export const EditInventory: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [storage, setStorage] = useState<any>(storageList)
    const [comment, setComment] = useState<any>('')
    const [device, setDevice] = useState<any>('')
    const [invoiceNumber, setInvoiceNumber] = useState<any>('')
    const [imei, setImei] = useState<string>('')
    const [vat, setVat] = useState<any>('')
    const [event, setEvent] = useState<any>('')
    const [purchasePrice, setPurchasePrice] = useState<string>('')
    const [supplier, setSupplier] = useState<any>('')
    const [os, setOs] = useState<any>('')
    const [rmaSupplier, setRmaSupplier] = useState<any>()
    const [model, setModel] = useState('')
    const [filteredSuppliers, setFilteredSuppliers] = useState<any[]>([]);
    const [selectedModel, setSelectedModel] = useState<any>()
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isSavePrintLoading, setIsSavePrintLoading] = useState(false)
    const barCode = useRef<HTMLDivElement>(null);

    const auth = useAuth()
    const { sendEditRequest } = useEdit()

    const token = auth?.user?.token?.token

    const { getRequest: getInventoryItem, data: itemData } = useFetch(
        `${baseUrl}/item/${item?.Id}`
    )
    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )
    const { getRequest, data } = useFetch(`${baseUrl}/partner/get-partners`);

    useEffect(() => {
        getRequest();
        getInventoryItem()
        getModels()
    }, [getInventoryItem, getModels, getRequest]);


    useEffect(() => {
        if (data) {
            const suppliers = data.filter((item) => item.tags.includes('supplier'));
            setFilteredSuppliers(suppliers);
        }
    }, [data]);
    useEffect(() => {
        if (itemData) {
            setComment(itemData?.comment !== "undefined" ? itemData?.comment : "",)
            setInvoiceNumber(itemData?.invoiceNumber)
            setImei(itemData?.imei)
            setModel(itemData?.model?._id)
            setStorage(itemData?.storage)
            setPurchasePrice(itemData?.purchasePrice)
            setEvent(itemData?.sortingEvent.name)
            setOs(itemData?.operatingSystem)
            setDevice(itemData?.device)
            setVat(itemData?.vat)
            setSupplier(itemData?.supplier?._id)
            setRmaSupplier(itemData?.rmaSupplier)
            const selectedModelName = modelsData?.find((model: any) => model._id.includes(itemData?.model?._id))
            const modelNames = modelsData?.filter((model) => model?.modelName === selectedModelName?.modelName)
            setSelectedModel(modelNames)
        }
    }, [item, item.comment, itemData, modelsData])
    const handleInvoiceChange = (newValue: any) => {
        setInvoiceNumber(newValue)
    }

    const handleEventChange = (newValue: any) => {
        setEvent(newValue)
    }
    const handleModelChange = (event: any) => {
        const value = event.target.value
        setModel(value)
        const selectedModelName = modelsData?.find((model: any) => model._id.includes(value))
        const modelNames = modelsData?.filter((model) => model?.modelName === selectedModelName?.modelName)
        setSelectedModel(modelNames)
    }

    const handleStorageChange = (event: any) => {
        setStorage(event.target.value)
    }

    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }

    const handleDeviceChange = (newValue: any) => {
        setDevice(newValue)
    }

    const handleImeiChange = (newValue: any) => {
        setImei(newValue)
    }

    const handleVatChange = (event: any) => {
        setVat(event.target.value)
    }

    const handlePurchasePriceChange = (newValue: any) => {
        setPurchasePrice(newValue)
    }

    const handleSupplierChange = (event: any) => {
        setSupplier(event.target.value)
    }

    const handleOsChange = (newValue: any) => {
        setOs(newValue)
    }

    const handleRmaSupplierChange = (newValue: any) => {
        setRmaSupplier(newValue)
    }

    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);

    const handleSavePrint = async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, select Model')
            return
        } else if (!imei) {
            alert('Please, add IMEI')
            return
        } else if (!vat) {
            alert('Please, add vat')
            return
        } else if (!storage) {
            alert('Please, add storage')
            return
        } else if (!device) {
            alert('Enter device ')
            return
        } else if (!purchasePrice) {
            alert('Please, add purchase price')
            return
        }
        setIsSavePrintLoading(true)
        const urlInventoryItem = `${baseUrl}/item/update/${item?.Id}`
        await sendEditRequest(
            urlInventoryItem,
            {
                invoiceNumber,
                id: item?.Id,
                vat,
                model,
                storage,
                imei,
                purchasePrice,
                comment,
                device,
                supplier,
                rmaSupplier,
                os,
            },
        )
            .then((response) => {
                const item = { item: response?.data }
                console.log(item);
                JsBarcode('#barcode', `${response?.data?.imei}`, {
                    lineColor: 'black',
                    textAlign: 'center',
                    width: 1,
                    background: 'transparent',
                    height: 40,
                    displayValue: false,
                    xmlDocument: document,
                });
                setIsSavePrintLoading(false)
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Inventory model added successfully',
                    type: 'success',
                })
                onClose()
                generatePDF(item, labelsData, barCode)
            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data}`, {
                    hideProgressBar: true,
                })
            })
    }
    const handleSave = async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, select Model')
            return
        } else if (!imei) {
            alert('Please, add IMEI')
            return
        } else if (!vat) {
            alert('Please, add vat')
            return
        } else if (!storage) {
            alert('Please, add storage')
            return
        } else if (!device) {
            alert('Enter device ')
            return
        } else if (!purchasePrice) {
            alert('Please, add purchase price')
            return
        }
        setIsSaveLoading(true)
        const urlInventoryItem = `${baseUrl}/item/update/${item?.Id}`
        await sendEditRequest(
            urlInventoryItem,
            {
                invoiceNumber,
                id: item?.Id,
                vat,
                model,
                storage,
                imei,
                purchasePrice,
                comment,
                device,
                supplier,
                rmaSupplier,
                os,
            },
        )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Inventory model added successfully',
                    type: 'success',
                })
                onClose()
                setIsSaveLoading(false)

            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data}`, {
                    hideProgressBar: true,
                })
            })
    }

    return (
        <ModalBox
            heading="Edit Inventory Item"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[500px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Supplier
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="supplier"
                            value={supplier}
                            onChange={handleSupplierChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"
                        >
                            {filteredSuppliers?.map((supplier: any) => (
                                <option
                                    key={supplier._id}
                                    value={supplier._id}
                                >
                                    {supplier.companyName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Invoice number
                    </Text>
                    <TextField
                        value={invoiceNumber}
                        onChange={handleInvoiceChange}
                        placeholder="Enter invoice number"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Vendor RMA
                    </Text>
                    <TextField
                        value={rmaSupplier}
                        onChange={handleRmaSupplierChange}
                        placeholder="#3232"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Operating systems
                    </Text>
                    <Dropdown
                        width='252px'
                        style={{
                            padding: '0 2px',
                            borderRadius: '8px',
                        }}
                        defaultValue={os}
                        options={operationSystemList}
                        onChange={handleOsChange}
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Device
                    </Text>
                    <Dropdown
                                               width='252px'
                        style={{
                            padding: '0 2px',
                            borderRadius: '8px',
                        }}
                        defaultValue={device}
                        label=""
                        options={myDevice}
                        onChange={handleDeviceChange}
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="model"
                            value={model}
                            onChange={handleModelChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"                        >
                            {modelsData?.map((mdl: any) => (
                                <option
                                    selected={model === mdl?._id}
                                    key={mdl}
                                    value={mdl?._id}
                                >
                                    {mdl?.modelName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Storage
                    </Text>
                    {<div style={{ width: '16rem' }}>
                        <select
                            id="device"
                            value={storage}
                            onChange={handleStorageChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"                        >
                            <option>Select</option>
                            {selectedModel?.map((model) => (
                                model?.storage?.map((uniqueStorage) => (
                                    <option key={uniqueStorage} value={uniqueStorage}>
                                        {uniqueStorage}
                                    </option>
                                ))
                            ))}
                        </select>
                    </div>}
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        IMEI
                    </Text>
                    <TextField
                        value={imei}
                        onChange={handleImeiChange}
                        placeholder="Enter imei"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Purchase price
                    </Text>
                    <TextField
                        value={purchasePrice}
                        onChange={handlePurchasePriceChange}
                        placeholder="Enter price"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        VAT
                    </Text>

                    <div style={{ width: '16rem' }}>
                        <select
                            id="vat"
                            value={vat}
                            onChange={handleVatChange}
                            className="w-full hover:cursor-pointer    py-2 px-1"                        >
                            <option value=''>Select</option>
                            {myVat?.map((vat: any) => (
                                <option
                                    selected={vat === vat}
                                    key={vat}
                                    value={vat}
                                >
                                    {vat}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status
                    </Text>
                    <TextField
                        disable={true}
                        value={event}
                        onChange={handleEventChange}
                        placeholder="inhouse"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Comment
                    </Text>
                    <TextArea
                        className='w-64 '
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="write comment ..."
                    />
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-36' onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isSaveLoading ? "Updating" : "Save"}
                        </Text>
                    </Button>
                    <Button onClick={handleSavePrint} className='w-36' backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isSavePrintLoading ? "Updating" : "Save & Print"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </ModalBox>
    )
}
