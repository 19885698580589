import { Dashboard as DashboardComponent } from '../components/compound/Dashboard/Dashboard'
import Header from '../components/shared/Header/Header'
export default function Dashboard() {
    // const chartData = [65, 59, 80, 81, 56, 55, 40]
    // const chartLabels = [
    //     'Label 1',
    //     'Label 2',
    //     'Label 3',
    //     'Label 4',
    //     'Label 5',
    //     'Label 6',
    //     'Label 7',
    // ]
    // const chartType = 'line' // Change this to 'bar' or 'pie' as needed

    return (
        <>
            <Header />
            <DashboardComponent />
        </>
    )
}
