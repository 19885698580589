import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useFetch } from '../../../../../hooks/useFetch'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../base/Text'
import { SaleOrder } from './searchModal/SaleOrder'
import { usePost } from '../../../../../hooks/usePost'
import { TextBackground } from '../../../../base/TextBackground'
const baseUrl = process.env.REACT_APP_baseUrl

export const ReportRMA: React.FC<{ onClose: () => void; }> = ({
    onClose,
}) => {
    const [company, setCompany] = useState<any>('')
    const [saleOrder, setSaleOrder] = useState<any>('');
    const [saleOrders, setSaleOrders] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState<any>()
    const { sendRequest } = usePost()

    const { getRequest: getPartners, data: partnerData } = useFetch(
        `${baseUrl}/partner/get-partners`
    )
    const customers = partnerData ? partnerData.filter(partner => partner.tags.includes("customer")) : [];

    const { getRequest: getSaleOrder, data: saleOrderData } = useFetch(
        `${baseUrl}/purchase/purchase-orders-numbers`
    );
console.log({saleOrderData});
    useEffect(() => {
        getPartners()
        getSaleOrder()
    }, [getPartners, getSaleOrder])

    const handleCustomerChange = useCallback((event: any) => {
        const selectedCustomerId = event.target.value;
        setCompany(selectedCustomerId);

        // Filter sale orders based on selected customer ID
        const customerSaleOrders = saleOrderData?.filter((order: any) => order.customer?._id === selectedCustomerId);
        // Update sale orders state
        setSaleOrders(customerSaleOrders);
    },[saleOrderData])
    
    const handleSaleOrderChange = (event: any) => {
        setSaleOrder(event.target.value)
    }

    const handleNext = useCallback(async (e: any) => {
        e.preventDefault()
        if (!company) {
            toast.error('Please, select customer')
            return
        } else if (!saleOrder) {
            alert('Please, select sale order')
            return
        }
        setIsLoading(true)
        await sendRequest(
            `${baseUrl}/purchase/search-purchase-order`,
            {
                company,
                saleOrder,
            },
        )
            .then((response) => {
                setSearchData(response?.data)
                setIsLoading(false)
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [company, saleOrder, sendRequest])

    const handleSaleClose = useCallback(() => {
        setSearchData(null)
        getSaleOrder()
    }, [getSaleOrder])

    return (
        <>
            <ModalBox
                heading="Submit new report"
                onClose={onClose}
                top="50%"
                height="auto"
                width="500px"
            >
                <Column className="mt-3 gap-y-4 h-[170px] overflow-auto">
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="title" weightV="bold">
                            Customer
                        </Text>
                        <div style={{ width: '16rem' }}>
                            <select
                                id="customer"
                                value={company}
                                onChange={handleCustomerChange}
                                className="w-full border hover:cursor-pointer  rounded-lg "
                            >
                                <option value="">Select</option>
                                {customers?.map((customer: any) => (
                                    <option key={customer?._id} value={customer?._id}>
                                        {customer?.companyName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="title" weightV="bold">
                            Sales order
                        </Text>
                        {company ? <div style={{ width: '16rem' }}>
                            <select
                                id="customer"
                                value={saleOrder}
                                onChange={handleSaleOrderChange}
                                className="w-full border-none hover:cursor-pointer rounded-lg "
                            >
                                <option value="">Select</option>
                                {saleOrders?.map((order: any) => (
                                    <option key={order?._id} value={order?.saleBatchNumber}>
                                        {order?.saleBatchNumber}
                                    </option>
                                ))}
                            </select>
                        </div> : <TextBackground className='w-64' backgroundColor='b9white'>
                            Please, select customer
                        </TextBackground>}
                    </RowAlignCenterSpaced>
                    <Flex className="gap-x-4 mt-2">
                        <Button className='w-56' backgroundColor="lgblue" onClick={handleNext}>
                            <Text colorV="white" variant="title" weightV="normal">
                                {isLoading ? 'Next >>>' : 'Next >'}
                            </Text>
                        </Button>
                    </Flex>
                </Column>
            </ModalBox>
            {searchData && <SaleOrder data={searchData} onClose={handleSaleClose} />}
        </>

    )
}

