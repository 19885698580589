import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { ReportRmaModal } from './Modal/ReportRmaModal'

export const RmaReportedRMA = ({ data, refetch }: any) => {
    const [showModal, setShowModal] = useState<any>(false)
    const [selectedData, setSelectedData] = useState<any>()
    const columns = [
        'PON',
        'Purchase date',
        'IMEI',
        'Model',
        'Storage',
        'RMA',
        'Price',
        'Vat',
        'Reported issue',
        'Status',
        //'Comment',
    ]
    let extractedData = []
    if (data) {
        const filteredItems = data?.items?.filter(item => 
            item?.rmaItemsStatus === 'reported_rma'
        );
        extractedData = filteredItems.map((item) => {
            return {
                id: item?._id,
                PON: data?.purchaseOrder?.saleBatchNumber,
                'Purchase date': moment(item?.createdAt).format('DD.MM.YYYY'),
                IMEI: item?.imei,
                Model: item?.model?.modelName,
                Storage: item?.storage,
                RMA: item?.rmaNumber,
                Price: item?.salePrice,
                Vat: item?.vat,
                'Reported issue': item?.reasonOfReturn,
                Status:item?.acceptance,
                //Comment: item?.comment !== "undefined" ? item?.comment : "",

            }
        })
    }
    const handleRowClick = useCallback((item) => {
        setSelectedData(item)
        setShowModal(true)
    }, [])

    const onClose = useCallback(() => {
        setShowModal(false)
        setSelectedData('')
        refetch()
    }, [refetch])

    return (
        <>
            <PrimaryCard className="w-full">
                <div className="overflow-auto rounded-lg text-left" style={{ maxWidth: '100%', width: '100%', overflowX: 'auto' }}>
                    <Table
                        handleRowClick={handleRowClick}
                        columns={columns}
                        data={extractedData}
                        clickable={true}
                        showActions={false}
                    />
                </div>
            </PrimaryCard>
            {showModal && <ReportRmaModal data={data} onClose={onClose} item={selectedData} />}
        </>
    )
}
