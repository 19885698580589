import React from 'react'
import { ReceiveDiagnostics } from './components/ReceiveDiagnostics'
import { Column } from '../../../../layout/Col'

export const Received = () => {
    return (
        <>
            <ReceiveDiagnostics />
        </>
    )
}
