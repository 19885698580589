import React from 'react'

interface BackgroundColorVariantProps {
    children: React.ReactNode
    className?: string
    variant?: 'white' | 'brown' // Define the allowed variants
}

export const Pagebg: React.FC<BackgroundColorVariantProps> = ({
    children,
    className,
    variant,
}) => {
    // let backgroundColor = ''
    // let padding = ''

    // Set the appropriate background color and padding based on the selected variant
    if (variant === 'white') {
        // backgroundColor = '#FFFFFF' // White color
        // padding = '23px 15px' // Add the desired padding for the 'white' variant
    } else if (variant === 'brown') {
        // backgroundColor = '#F2F0ED' // Brown color
        // padding = '10px' // No padding for the 'brown' variant
    }

    const style: React.CSSProperties = {
        // backgroundColor: backgroundColor,
        // minHeight: '100vh', // Ensure the component takes full screen height
        height: '100%',
        // padding: padding, // Apply the padding
        width: '100%',
    }

    return (
        <div style={style} className={` ${className} hide-scrollbar`}>
            {children}
        </div>
    )
}
