import axios from 'axios'
import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { Text } from '../../../../base/Text'
import { useEdit } from '../../../../../hooks/useEdit'
import { generatePDF } from '../../../../shared/GenerateLabel'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const Attention: React.FC<{ onClose: () => void; item: any; codes: any; daysLeft: any; handleBroken: () => void }> = ({
    onClose,
    item,
    codes,
    daysLeft,
    handleBroken
}) => {
    const { sendEditRequest } = useEdit()
    const handleReturn = useCallback(async () => {
        axios
        sendEditRequest(
            `${baseUrl}/item/my-rma/${item?._id}`,
            {
                codes,
            },
        )
            .then((response) => {
                if (response) {
                    toast.success("Added in my RMA");
                    addNotification({
                        message: 'Device has been added in my RMA',
                        type: 'success',
                    })
                    onClose()
                }

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    hideProgressBar: true,
                });
            });
    }, [codes, item?._id, onClose, sendEditRequest])


    return (
        <ModalBox
            heading='Attention required'
            onClose={onClose}
            top="50%"
            height="270px"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 items-center text-center">
                <Text colorV="blue" variant="head" weightV="normal">
                    This device has {daysLeft} days left! <br />
                    Bring it to RMA department
                </Text>
                <Flex className="gap-x-4 mt-4">
                    <Button backgroundColor="blue" onClick={handleReturn}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Ok,return & Print label
                        </Text>
                    </Button>
                    <Button backgroundColor="lgblue" onClick={handleBroken}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Broken by us
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
