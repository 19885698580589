import { PrimaryCard } from '../../../compound/Page/PrimaryCard'
import { Column } from '../../../layout/Col'
import { Flex } from '../../../layout/Flex'
import { Text } from '../../../base/Text'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { TextBackground } from '../../../base/TextBackground'
import { Button } from '../../../base/Button'
import { useCallback, useEffect, useState } from 'react'
import { IssuedCN } from '../../../compound/Rma/CustomerItemsRma/component/IssuedCN'
import { PdfDownload } from '../../../compound/Rma/CreditNotes/component/PdfDownload'

export const CustomerSummary = ({ data }: any) => {
    const [isEdit, setEdit] = useState(false)
    let totalAccepted = 0;
    let totalDeclinedDevices = 0;
    let totalAcceptedRepairs = 0;

    if (data) {
        data?.items?.forEach(item => {
            const salePrice = parseFloat(item?.salePrice) || 0;
            console.log(salePrice);
            const purposedPrice = parseFloat(item?.priceProposal) || 0
            if (item?.acceptance === "rma_accepted") {
                console.log(salePrice);
                totalAccepted += salePrice;
                if (item.priceProposal) {
                    totalAcceptedRepairs += purposedPrice;
                }
            } else if (item?.acceptance === "rma_declined") {
                totalDeclinedDevices += salePrice;
            }
        });
    }
    const handleEditIssueCreditNote = useCallback(() => {
        setEdit(true)
    }, [])
    const closeEdit = () => {
        setEdit(false)
    }
    let item;
    if (data) {
        item = {
            'Credit Note N': data?.creditNote?.creditNumber,
            'Customer': data?.customer?.companyName,
            'Status': data?.creditNote?.status,
            'Value': data?.creditNote?.creditValue,
            'Issued for RMA': data?.rmaNumber,
            path: data?.creditNote?.filePath,
        };

    }


    return (
        <Flex className="w-3/5">
            <PrimaryCard className="p-5 w-full">
                <Column className="w-full ">
                    <Text colorV="blue" variant="heading" weightV="bold">
                        Summary of RMA {data?.rmaNumber}
                    </Text>
                    <RowAlignCenterSpaced className="  gap-x-20 my-5 justify-center">
                        <Column className='gap-y-4'>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                Accepted device
                            </Text>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="bold"
                            >
                                {totalAccepted}
                            </Text>
                        </Column>
                        <Column className='gap-y-4'>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                Accepted repairs
                            </Text>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="bold"
                            >
                                {totalAcceptedRepairs}
                            </Text>
                        </Column>
                        <Column className='gap-y-4'>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                Declined devices
                            </Text>
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="bold"
                            >
                                {totalDeclinedDevices}
                            </Text>
                        </Column>
                    </RowAlignCenterSpaced>
                    <Column className="gap-y-5 text-left">
                        <RowAlignCenterSpaced className="gap-y-4">
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                Total Credit note value
                            </Text>
                            <TextBackground className='w-52' backgroundColor="b9white">
                                <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                    {totalAccepted + totalAcceptedRepairs}
                                </Text>
                            </TextBackground>

                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-4 w-52">
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                Credit note Number
                            </Text>
                            {data?.creditNote && <TextBackground
                                onClick={handleEditIssueCreditNote}
                                backgroundColor='b9white'
                                className='w-52 hover:cursor-pointer underline'>
                                {data?.creditNote?.creditNumber}
                            </TextBackground >
                            }
                            {/* <TextBackground className='w-40' backgroundColor="b9white">
                            <Text
                                    colorV="blue"
                                    weightV="normal"
                                    variant="title"
                                >
                                   {data?.creditNote?.creditNumber}
                                </Text>
                            </TextBackground> */}
                        </RowAlignCenterSpaced>
                    </Column>
                </Column>
            </PrimaryCard>
            {isEdit && <PdfDownload item={item} onClose={closeEdit} />}
        </Flex>
    )
}
