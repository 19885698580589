import React from 'react';
import { Dropdown } from '../../base/Dropdown';
import { Column } from '../../layout/Col';
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced';
import { SingleDatasetLineChart } from '../../shared/Chart/SingleDatasetLineChart';
import { PrimaryCard } from '../Page/PrimaryCard';
import { Text } from '../../base/Text';
import { Row } from '../../layout/Row';
import { RowAlignCenter } from '../../layout/RowAlignCenter';

export const ValueOfSale = ({ chartData, chartLabels, color }: any) => {
    return (
        <PrimaryCard className='bg-b9white p-5 w-full'>
            <RowAlignCenterSpaced className="mb-5 ">
                <Text colorV="blue" variant="heading" weightV="normal">
                    Value of sales
                </Text>
                <Dropdown
                    style={{ border: 'none !important', backgroundColor: "transparent !important" }}
                    width='100px'
                    options={['Week', 'Day', 'Month']}
                    onChange={(selectedOption) => {
                        console.log(`Selected option: ${selectedOption}`);
                    }}
                />
            </RowAlignCenterSpaced>
            <SingleDatasetLineChart data={chartData} labels={chartLabels} gradientColors={color} />
        </PrimaryCard>
    );
};
