import React, { useEffect, useState } from 'react';
import { Flex } from '../../../layout/Flex';
import { Table } from '../../../shared/Table';
import { PrimaryCard } from '../../Page/PrimaryCard';
import moment from 'moment';
const baseUrl = process.env.REACT_APP_baseUrl

export default function SaleItemsTable({data}:any) {

  
console.log(data)
  let extractedData = [];
  if (data) {
    extractedData = data?.map((item) => {
      return {
        Id: item?._id,
        SKU: item?.sku?.sku,
        Model: item?.model?.modelName,
        Storage: item?.sku?.storage,
        Color: item?.sku?.color,
        VAT: item?.sku?.vat,
        'IMEI': item?.imei,
        Status: item?.sortingEvent.name || '',
        Date: moment(item?.updatedAt).format('DD.MM.YYYY'),
      };
    });
  }

  const columns = [
    'SKU',
    'Model',
    'Storage',
    'Color',
    'VAT',
    'IMEI',
    'Status',
    'Date',
  ];
  
  return (
    <>
      <Flex className="my-3">
          <PrimaryCard className="w-full">
            <Table
              columns={columns}
              data={extractedData}
              showActions={false} />
          </PrimaryCard>
      </Flex>
    </>
  );
}
