import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { operationSystemList, myDevice, storageList, myVat } from '../../../constant/Storage'
import { useFetch } from '../../../hooks/useFetch'
import { useAuth } from '../../../utils/auth'
import { Button } from '../../base/Button'
import { ModalBox } from '../../base/ModelBox'
import { TextBackground } from '../../base/TextBackground'
import { TextArea } from '../../base/Textarea'
import { TextField } from '../../base/Textfield'
import { Column } from '../../layout/Col'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Text } from '../../base/Text'
import { ItemSearchModal } from '../../shared/SearchModal/ItemSearchModal'
import { addNotification } from '../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const SkuHistory: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item
}) => {
    const [model, setModel] = useState<any>('')
    const [storage, setStorage] = useState<any>()
    const [comment, setComment] = useState<any>('')
    const [device, setDevice] = useState<any>('')
    const [invoiceNumber, setInvoiceNumber] = useState<any>('')
    const [imei, setImei] = useState<string>('')
    const [vat, setVat] = useState<any>('')
    const [purchasePrice, setPurchasePrice] = useState<string>('')
    const [supplier, setSupplier] = useState<any>('')
    const [os, setOs] = useState<any>('')
    const [rmaSupplier, setRmaSupplier] = useState<any>()
    const [isLoading, setisLoading] = useState<boolean>(false)

    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { getRequest, data } = useFetch(`${baseUrl}/partner/get-partners`);

    useEffect(() => {
        getRequest();
    }, [getRequest]);

    const [filteredSuppliers, setFilteredSuppliers] = useState<any[]>([]);

    useEffect(() => {
        if (data) {
            const suppliers = data.filter((item) => item.tags.includes('supplier'));
            setFilteredSuppliers(suppliers);
        }
    }, [data]);

    const handleInvoiceChange = (newValue: any) => {
        setInvoiceNumber(newValue)
    }

    const handleModelChange = (event: any) => {
        setModel(event.target.value)
    }

    const handleStorageChange = (event: any) => {
        setStorage(event.target.value)
    }

    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }

    const handleDeviceChange = (event: any) => {
        setDevice(event.target.value)
    }

    const handleImeiChange = (newValue: any) => {
        setImei(newValue)
    }

    const handleVatChange = (event: any) => {
        setVat(event.target.value)
    }

    const handlePurchasePriceChange = (newValue: any) => {
        setPurchasePrice(newValue)
    }

    const handleSupplierChange = (event: any) => {
        setSupplier(event.target.value)
    }

    const handleOsChange = (event: any) => {
        setOs(event.target.value)
    }

    const handleRmaSupplierChange = (newValue: any) => {
        setRmaSupplier(newValue)
    }

    const { getRequest: getModel, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )

    useEffect(() => {
        getModel()
    }, [getModel])

    const handleSave = async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, select Model')
            return
        } else if (!imei) {
            alert('Please, add IMEI')
            return
        } else if (!vat) {
            alert('Please, add vat')
            return
        } else if (!storage) {
            alert('Please, add storage')
            return
        } else if (!device) {
            alert('Enter device ')
            return
        } else if (!purchasePrice) {
            alert('Please, add purchase price')
            return
        } else if (!supplier) {
            alert('Please, add supplier')
            return
        } else if (!rmaSupplier) {
            alert('Please, add supplier')
            return
        }
        else if (!os) {
            alert('Please, add supplier')
            return
        } else if (!invoiceNumber) {
            alert('Please, add supplier')
            return
        }
        setisLoading(true)
        const urlInventoryItem = `${baseUrl}/inventory/add-inventory`
        await axios
            .post(
                urlInventoryItem,
                {
                    invoiceNumber,
                    vat,
                    model,
                    storage,
                    imei,
                    purchasePrice,
                    comment,
                    device,
                    supplier,
                    rmaSupplier,
                    os,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Added Successfully',
                    type: 'success',
                })
                setModel('')
                setStorage('')
                setComment('')
                setDevice('')
                setInvoiceNumber('')
                setImei('')
                setVat('')
                setPurchasePrice('')
                setSupplier('')
                setOs('')
                setRmaSupplier('')
                setisLoading(false)
                onClose()
            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data}`, {
                    hideProgressBar: true,
                })
            })
    }

    return (
        <ItemSearchModal heading="SKU" width='500px' height='550px' onClose={onClose}>
            <Column className="gap-y-4 ">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Supplier
                    </Text>
                    {filteredSuppliers?.length > 0 ? (
                        <div style={{ width: '18rem' }}>
                            <select
                                id="supplier"
                                value={supplier}
                                onChange={handleSupplierChange}
                                className="w-full border hover:cursor-pointer text-sm rounded-md py-2 px-2"
                            >
                                <option>Select</option>
                                {filteredSuppliers?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.companyName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        <TextBackground className='w-72' backgroundColor='b9white'>
                            Please, add supplier
                        </TextBackground>
                    )}
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Invoice number
                    </Text>
                    <TextField
                        value={invoiceNumber}
                        onChange={handleInvoiceChange}
                        placeholder="Enter invoice number"
                        
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Vendor RMA
                    </Text>
                    <TextField
                        value={rmaSupplier}
                        onChange={handleRmaSupplierChange}
                        placeholder="#3232"
                        
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Operating systems
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="os"
                            value={os}
                            onChange={handleOsChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {operationSystemList?.map((os: any) => (
                                <option key={os} value={os}>
                                    {os}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Device
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={device}
                            onChange={handleDeviceChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {myDevice?.map((device: any) => (
                                <option key={device} value={device}>
                                    {device}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Model
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={model}
                            onChange={handleModelChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {modelsData?.map((mdl: any) => (
                                <option key={mdl?._id} value={mdl?._id}>
                                    {mdl?.modelName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Storage
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={storage}
                            onChange={handleStorageChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {storageList?.map((stge: any) => (
                                <option key={stge.value} value={stge.value}>
                                    {stge.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        IMEI
                    </Text>
                    <TextField
                        value={imei}
                        onChange={handleImeiChange}
                        placeholder="Enter imei"
                        
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Purchase price
                    </Text>
                    <TextField
                        value={purchasePrice}
                        onChange={handlePurchasePriceChange}
                        placeholder="Enter price"
                        
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        VAT
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="device"
                            value={vat}
                            onChange={handleVatChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {myVat?.map((vat: any) => (
                                <option key={vat} value={vat}>
                                    {vat}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Status
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        In House
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Comment
                    </Text>
                    <TextArea
                        className='w-72'
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="write comment ..."
                        padding="5px 13px"
                    />
                </RowAlignCenterSpaced>
            </Column>
        </ItemSearchModal>
    )
}
