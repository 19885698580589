import React, { useState } from 'react'
import { Button } from '../../../../base/Button'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { Text } from '../../../../base/Text'
import { Row } from '../../../../layout/Row'
import { FileUpload } from '../../../../shared/Fileupload'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { Icon } from '../../../../base/Icon'
import { addNotification } from '../../../../shared/Notification/Notification'
import { downloadTemplate } from '../../../../../download/downloadService'
const baseUrl = process.env.REACT_APP_baseUrl

export const BulkGrading = () => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false) // Add loading state
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany

    const handleFileUpload = (file: File) => {
        setUploadedFile(file)
    }

    const handleUpload = async (e: any) => {
        e.preventDefault()
        if (!uploadedFile) return; // Prevent upload if no file is selected

        setIsLoading(true); // Set loading state to true

        const formData = new FormData()
        formData.append('file', uploadedFile)

        const urlItem = `${baseUrl}/item/bulk-grading`
        try {
            const response = await axios.put(urlItem, formData, {
                headers: {
                    Authorization: token as string,
                    'Content-Type': 'multipart/form-data',
                    Company: company._id,
                },
            })
            if (response) {
                console.log(response);
                toast.success(response?.data?.message, { hideProgressBar: true })
                addNotification({
                    message: 'Grading has been added successfully',
                    type: 'success',
                })
            }
        } catch (error) {
            console.log(error);
            toast.error(`Error: ${error?.response?.data?.message}`, {
                hideProgressBar: true,
            })
        } finally {
            setIsLoading(false);
            setUploadedFile(null);
        }
    }

    return (
        <PrimaryCard className='w-1/2 p-5'>
            <Column className="gap-y-4">
                <FileUpload onFileUpload={handleFileUpload} />
                {uploadedFile && (
                    <Row className='gap-x-3 items-center'>
                        <Text colorV="blue" variant="title" weightV="normal">
                            Uploaded File: {uploadedFile.name}
                        </Text>
                        <span className='cursor-pointer' onClick={() => setUploadedFile(null)}>
                            <Icon name="cross" color="black" size={16} />
                        </span>
                    </Row>
                )}
                <Flex className="gap-x-4 mt-4">
                    <Button
                        className='w-32'
                        onClick={handleUpload}
                        backgroundColor="lgblue"
                        disabled={!uploadedFile || isLoading} // Disable button when no file is uploaded or when loading
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Uploading...' : 'Upload'}
                        </Text>
                    </Button>
                    <Button
                        onClick={() => downloadTemplate('bulkGrading')}
                        backgroundColor="green"
                        className='flex flex-row items-center gap-2 text-white'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z" />
                            <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
                        </svg>

                        Download template
                    </Button>
                </Flex>
            </Column>
        </PrimaryCard>
    )
}
