import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { FiX } from 'react-icons/fi'; // Import the close icon from react-icons

interface ImageUploadProps {
    onUpload: (file: File) => void;
    width?: string;
    loading?:any
}

const ImageSelector: React.FC<ImageUploadProps> = ({ onUpload,loading, width = '100%' }) => {
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleImageUpload = () => {
        if (selectedFile) {
            onUpload(selectedFile);
        } else {
            // Handle case when no file is selected
        }
    };

    const handleRemoveImage = () => {
        setUploadedImage(null);
        setSelectedFile(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image': ['.jpg', '.png', '.jpeg'] },
        noDrag: true,
        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                const uploadedFile = acceptedFiles[0];
                const imageUrl = URL.createObjectURL(uploadedFile);
                setUploadedImage(imageUrl);
                setSelectedFile(uploadedFile);
            }
        },
    });

    const dropzoneStyle: React.CSSProperties = useMemo(
        () => ({
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '20px',
            textAlign: 'center',
            width: width,
            margin: '0 auto',
            position: 'relative', // Add position relative to parent div
        }),
        [width]
    );

    return (
        <div className="flex flex-col mt-5 items-center">
            <div {...getRootProps({ style: dropzoneStyle } as DropzoneRootProps)}>
                <input {...getInputProps() as DropzoneInputProps} />
                {uploadedImage && (
                    <>
                        <img src={uploadedImage} alt="Uploaded" style={{ width: '100%', height: '100%', borderRadius: '4px' }} />
                        <button className="absolute top-0 right-0 p-1" onClick={handleRemoveImage}>
                            <FiX size={16} color="#fff" /> {/* Close icon */}
                        </button>
                    </>
                )}
                {!uploadedImage && <p>Select an image (JPG, PNG)</p>}
            </div>
            {selectedFile && (
                <button
                    onClick={handleImageUpload}
                    className="bg-b9midblue hover:bg-blue text-white font-semibold py-2 px-4 rounded mt-4"
                >
                   {loading ? "Uploading.." :  "Upload Image"}
                </button>
            )}
        </div>
    );
};

export default ImageSelector;
