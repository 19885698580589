import axios from 'axios';
import { useCallback, useState } from 'react';
import { useAuth } from '../utils/auth';

export const useFetch = (url: string) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [error, setError] = useState<any>('');
    const auth = useAuth();
    const token = auth?.user?.token?.token || 'asdas';
    const company = auth.selectedCompany;
    const getRequest = useCallback(async () => {
        setLoading(true);
        await axios
            .get(url, {
                headers: {
                    Authorization: token as string,
                    Company: company?._id, 
                },
            })
            .then((response) => {
                setError('');
                setData(response?.data);
            })
            .catch((error) => {
                setError(error?.message);
            })
            .finally(() => setLoading(false));
    }, [token, url, company]);

    return { loading, data, error, getRequest };
};
