import React from 'react'
import { Row } from '../../../../../layout/Row';
import { Table } from '../../../../../shared/Table';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Button } from '../../../../../base/Button';
import { Column } from '../../../../../layout/Col';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../../base/Text'

export const DeviceDetail = ({ rowData, codesData, handleIsBack }: any) => {
    return (
        <Column className='gap-y-5 w-full'>
            <RowAlignCenterSpaced >
                <Text
                    colorV="blue"
                    variant="head"
                    weightV="bold"
                >
                    Devices in transit detail
                </Text>
                <Button backgroundColor='blue' onClick={handleIsBack}>
                    <Text
                        colorV="white"
                        variant="body"
                        weightV="normal"
                    >
                        Back
                    </Text>
                </Button>
            </RowAlignCenterSpaced>
            <Row>
                <PrimaryCard className='w-full'>
                    <Table
                        columns={['Model name', 'Storage', 'IMEI', 'Issue description']}
                        data={rowData?.item?.map(item => ({
                            'Model name': item.model.modelName,
                            'Storage': item.storage,
                            'IMEI': item.imei,
                            'Issue description': item.codes?.map(code => {
                                const matchingCode = codesData.find(data => data.label === code);
                                return matchingCode ? matchingCode.description : ''; // Return description if code matches, otherwise return an empty string
                            }).join(', ')
                        }))}
                        showActions={false}
                        clickable={true}
                    />
                </PrimaryCard>
            </Row>
        </Column>
    )
}
