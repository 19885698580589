import React, { useEffect, useState } from 'react'
import InvoiceTable from './InvoiceTable'
import Head from './Head'
import { Filter } from './Filter'
import { useFetch } from '../../../../hooks/useFetch'
import { RowAlignCenter } from '../../../layout/RowAlignCenter'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
const baseUrl = process.env.REACT_APP_baseUrl;

export const Invoice = () => {
    const [filteredItems, setFilteredItems] = useState<any[]>([]);

    const { getRequest: getInvoiceItems, data: itemsData } = useFetch(
        `${baseUrl}/inventory/get-invoice-items-in-inventory`
    );
    useEffect(() => {
        getInvoiceItems()
    }, [getInvoiceItems])
    const refetch = () => {
        getInvoiceItems()

    }
    const handleFilterChange = (filteredData: any) => {
        setFilteredItems(filteredData);
    };
    return (
        <>
        <RowAlignCenterSpaced>
        <Head itemsData={filteredItems?.length > 0 ? filteredItems : itemsData} />
        <Filter refetch={refetch} onDataChange={handleFilterChange} itemsData={itemsData} />
        </RowAlignCenterSpaced>
        
            <InvoiceTable itemsData={filteredItems?.length > 0 ? filteredItems : itemsData} />
        </>
    )
}
