/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png'
import { Text } from './base/Text'
import Card from './compound/Page/Card/Card'
import { Pagebg } from './compound/Page/Pagebg'
export default function Header({
    heading,
    paragraph,
    linkName,
    linkUrl = '#',
}) {
    return (
        <Pagebg variant="brown">
            <Card>
                <div className="mb-5">
                    <div className="flex justify-center">
                        <img alt="" className="h-14 w-14" src={Logo} />
                    </div>
                    <div className="flex justify-center">
                        <Text colorV="blue" variant="heading" weightV="bold">
                            {heading}
                        </Text>
                    </div>
                    <p className="text-center text-sm text-gray-600 mt-5">
                        {paragraph}{' '}
                        <Link
                            to={linkUrl}
                            className="font-medium text-purple-600 hover:text-purple-500"
                        >
                            {linkName}
                        </Link>
                    </p>
                </div>
            </Card>
        </Pagebg>
    )
}
