import React, { useCallback, useEffect, useState } from 'react'
import { Flex } from '../../../layout/Flex'
import { Text } from '../../../base/Text'
import { Button } from '../../../base/Button'
import { Focus } from './Focus/Focus'
import { Reports } from './Reports/Reports'
import { Insights } from './Insights/Insights'
import { Column } from '../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { Row } from '../../../layout/Row'
import { Dropdowns } from './Dropdowns'
import { toast } from 'react-toastify'
import { usePost } from '../../../../hooks/usePost'
const baseUrl = process.env.REACT_APP_baseUrl;

export default function Head() {
    const [activeTab, setActiveTab] = useState('Insights')
    const [year, setYear] = useState<string>(new Date().getFullYear().toString());
    const [month, setMonth] = useState<string>(new Date().toLocaleString('default', { month: 'long' }));
    const [insightsData, setInsightsData] = useState<any>(null); // State to store insights data
    const { sendRequest } = usePost();

    const handleInsight = useCallback(async () => {
        const urlInventoryItem = `${baseUrl}/report/insight-report`
        await sendRequest(
            urlInventoryItem,
            {
                month,
                year
            },
        )
            .then((response) => {
                setInsightsData(response?.data); // Set the fetched data to state
                toast.success(response?.data?.message, { hideProgressBar: true })
            })
            .catch((error) => {
                console.log(error)
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }, [month, sendRequest, year])
    useEffect(() => {
        handleInsight();
    }, [handleInsight]);
    useEffect(() => {
        if (year && month) {
            handleInsight();
        }
    }, [year, month, handleInsight]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Flex className="py-5 w-full">
            <Column className='w-full'>
                <RowAlignCenterSpaced>
                    <Row className="gap-x-5">
                        <Text
                            colorV="blue"
                            variant="head"
                            weightV="bold"
                        >
                            {activeTab}
                        </Text>
                        <Button
                            onClick={() => handleTabClick('Insights')}
                            backgroundColor={
                                activeTab === 'Insights' ? 'blue' : 'grey'
                            }
                            className={
                                activeTab === 'Insights'
                                    ? 'active-button'
                                    : 'inactive-button'
                            }
                        >
                            <Text
                                colorV={activeTab === 'Insights' ? 'white' : 'blue'}
                                variant="title"
                                weightV="normal"
                            >
                                Insights
                            </Text>
                        </Button>
                        <Button
                            onClick={() => handleTabClick('Focus')}
                            backgroundColor={
                                activeTab === 'Focus' ? 'blue' : 'grey'
                            }
                            className={
                                activeTab === 'Focus'
                                    ? 'active-button'
                                    : 'inactive-button'
                            }
                        >
                            <Text
                                colorV={activeTab === 'Focus' ? 'white' : 'blue'}
                                variant="title"
                                weightV="normal"
                            >
                                Focus
                            </Text>
                        </Button>
                        <Button
                            onClick={() => handleTabClick('Reports')}
                            backgroundColor={
                                activeTab === 'Reports' ? 'blue' : 'grey'
                            }
                            className={
                                activeTab === 'Reports'
                                    ? 'active-button'
                                    : 'inactive-button'
                            }
                        >
                            <Text
                                colorV={activeTab === 'Reports' ? 'white' : 'blue'}
                                variant="title"
                                weightV="normal"
                            >
                                Reports
                            </Text>
                        </Button>
                    </Row>
                    {/* 
                        <Row>
                            <Dropdowns
                                onYearChange={setYear}
                                onMonthChange={setMonth}
                            />
                        </Row>
                     */}

                </RowAlignCenterSpaced>

                <RowAlignCenterSpaced className="mt-5">
                    {/* Pass insights data to Insights component */}
                    {activeTab === 'Insights' && <Insights data={insightsData} />}
                    {activeTab === 'Focus' && <Focus data={insightsData}/>}
                    {activeTab === 'Reports' && <Reports />}
                </RowAlignCenterSpaced>
            </Column>
        </Flex>
    )
}
