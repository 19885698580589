import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Logo from '../assets/logo.png'
import { Button } from '../components/base/Button'
import { Text } from '../components/base/Text'
import { TextField } from '../components/base/Textfield'
import Card from '../components/compound/Page/Card/Card'
import { Pagebg } from '../components/compound/Page/Pagebg'

export default function VerifyCode() {
    const [code, setCode] = useState('')
    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_baseUrl

    const handleInputChange = (newValue:any) => {
        setCode(newValue)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        axios
            .post(`${baseUrl}/auth/verifyCode`, {
                code,
            })
            .then(() => {
                toast.success('Valid', { hideProgressBar: true })
                navigate(`/resetPassword/${code}`)
            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data.message}`, {
                    hideProgressBar: true,
                })
            })
    }
    return (
        <Pagebg className="bg-gray-day-1  flex justify-center items-center h-screen">
        <Card className="bg-gray-day-0">
                <form onSubmit={handleSubmit}>
                    <div className="px-5 py-10">
                        <div className="flex justify-center">
                            <img alt="" className="h-20 w-36" src={Logo} />
                        </div>
                        <div className="flex justify-center mt-5">
                            <Text
                                colorV="blue"
                                variant="heading"
                                weightV="bold"
                            >
                                Verify Code
                            </Text>
                        </div>
                        <div>
                            <div className="my-2">
                                <Text
                                    colorV="blue"
                                    variant="label"
                                    weightV="bold"
                                >
                                    Write code
                                </Text>
                            </div>
                            <TextField
                                value={code}
                                onChange={handleInputChange}
                                placeholder="..1234"
                                className='w-full'
                            />
                        </div>
                        <div className="flex justify-center mt-7 ">
                            <Button
                                onClick={handleSubmit}
                                className="w-full rounded-2xl py-3"
                            >
                                <Text
                                    colorV="white"
                                    variant="body"
                                    weightV="bold"
                                >
                                Verify
                                    
                                </Text>
                            </Button>
                        </div>
                    </div>
                </form>
            </Card>
        </Pagebg>
    )
}
