import React, { useState } from 'react'

export const Pagination = ({ pageCount, onPageChange }: any) => {
    const [page, setPage] = useState(1)

    const handleNext = () => {
        if (page < pageCount) {
            setPage(page + 1)
            onPageChange(page + 1)
        }
    }

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1)
            onPageChange(page - 1)
        }
    }

    const handlePageClick = (pageNumber) => {
        setPage(pageNumber)
        onPageChange(pageNumber)
    }

    return (
        <div className="flex mt-10 justify-center">
            <nav aria-label="Page navigation example">
                <ul className="list-style-none flex">
                    <li className="mr-3">
                        <button
                            disabled={page === 1}
                            className={`relative block ${
                                page === 1
                                    ? 'disabled:opacity-25 bg-gray-300 text-gray-600'
                                    : 'bg-b9blue hover:bg-b9white hover:text-b9blue text-white'
                            } rounded py-1.5 px-3 text-sm transition-all text-white`}
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>
                    </li>
                    {Array.from({ length: pageCount }, (_, index) => (
                        <li key={index}>
                            <button
                                className={`relative block focus:bg-b9blue active:bg-b9blue dark:hover:bg-b9blue rounded py-1.5 px-3 text-sm transition-all duration-300 hover:bg-b9blue hover:text-white ${
                                    page === index + 1
                                        ? 'bg-b9blue active-page text-white'
                                        : ''
                                }`}
                                onClick={() => handlePageClick(index + 1)}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className="ml-3">
                        <button
                            disabled={page === pageCount}
                            className={`relative block ${
                                page === pageCount
                                    ? 'disabled:opacity-25 bg-gray-300 text-gray-600'
                                    : 'bg-b9blue hover:bg-b9white hover:text-b9blue text-white'
                            } rounded py-1.5 px-3 text-sm transition-all text-white`}
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
