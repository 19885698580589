import React from 'react';
import { useAuth } from '../../utils/auth';
import { Permissions } from '../../constant/Storage';
import { AccessEnums, AccessPermissions } from '../../constant/UserAccess';

export const useNavigation = () => {
  const auth = useAuth();
  console.log({ auth });
  const isManagement = auth?.selectedCompany?.permissions?.includes(Permissions.management)
  const isRma = auth?.selectedCompany?.permissions?.includes(Permissions.rma)
  const isInventory = auth?.selectedCompany?.permissions?.includes(Permissions.inventory)
  const isSales = auth?.selectedCompany?.permissions?.includes(Permissions.sales)
  const isStocktaking = auth?.selectedCompany?.permissions?.includes(Permissions.stocktaking)
  const isCustomerRma = auth?.selectedCompany?.permissions?.includes(Permissions.rma)
    
  const scriptCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Script && acc.permission.includes(AccessPermissions.Admin))
  const stockingReportCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.StocktakingReport && acc.permission.includes(AccessPermissions.Admin))
  const isInventoryAccess = auth?.selectedProfile?.access?.some((acc) =>
    acc?.module === AccessEnums.InHouseInventory &&
    (acc.permission.includes(AccessPermissions.View) ||
      acc.permission.includes(AccessPermissions.Add) ||
      acc.permission.includes(AccessPermissions.Edit))
  );
  const isSparePartInventoryAccess = auth?.selectedProfile?.access?.some((acc) =>
    acc?.module === AccessEnums.SparePartsInventory &&
    (acc.permission.includes(AccessPermissions.View) ||
      acc.permission.includes(AccessPermissions.Add) ||
      acc.permission.includes(AccessPermissions.Edit))
  );
  const pointOfSaleAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.PointOfSale && acc.permission.includes(AccessPermissions.Admin));
  const SaleInventoryAccess = auth?.selectedProfile?.access?.some((acc) =>
    acc?.module === AccessEnums.SalesInventory &&
    acc.permission.includes(AccessPermissions.View) ||
    acc.permission.includes(AccessPermissions.Edit)
  );


  const dashboardAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Dashboard && acc.permission.includes(AccessPermissions.Admin));
  const skuAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageSKU && acc.permission.includes(AccessPermissions.Admin));
  const templateAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageTemplates && acc.permission.includes(AccessPermissions.Admin));
  const userAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Users && acc.permission.includes(AccessPermissions.Admin));
  const eventAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageModels && acc.permission.includes(AccessPermissions.Admin))
  const modelAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageEvents && acc.permission.includes(AccessPermissions.Admin))
  const sortingAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManualSortingLogic && acc.permission.includes(AccessPermissions.Admin))
  const repairScoreAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageRepairPoints && acc.permission.includes(AccessPermissions.Admin))
  const partnerAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManagePartners && acc.permission.includes(AccessPermissions.Admin))
  const myRmaAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.MyRMA && acc.permission.includes(AccessPermissions.Admin))
  const rmaAccess = auth?.selectedProfile?.access?.some((acc) =>
    acc?.module === AccessEnums.RMA && (acc.permission.includes(AccessPermissions.Edit) || acc.permission.includes(AccessPermissions.View))
);
  const isTransit = auth?.selectedCompany?.permissions?.includes(Permissions.transit)
  const isPolishing = auth?.selectedCompany?.permissions?.includes(Permissions.polishing)
  const isRepair = auth?.selectedCompany?.permissions?.includes(Permissions.repair)
  const isGrading = auth?.selectedCompany?.permissions?.includes(Permissions.grading)
  const isManualSorting = auth?.selectedCompany?.permissions?.includes(Permissions.manualSorting)
  const isDiagnostics = auth?.selectedCompany?.permissions?.includes(Permissions.diagnostics)

  const repairAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Repair && acc.permission.includes(AccessPermissions.Admin))
  const gradingAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Grading && acc.permission.includes(AccessPermissions.Admin))
  const diagnosticsAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Diagnostics && acc.permission.includes(AccessPermissions.Admin))
  const manualSortingAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManualSorting && acc.permission.includes(AccessPermissions.Admin))
  const polishingAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Polishing && acc.permission.includes(AccessPermissions.Admin))
  const transitAccess = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Transit && acc.permission.includes(AccessPermissions.Admin))

  let managementTo;

  if (dashboardAccess) {
    managementTo = '/management/dashboards-reports';

  } else if (eventAccess || modelAccess || sortingAccess || repairScoreAccess || partnerAccess) {
    managementTo = '/management/manage-production';
  } else if (skuAccess) {
    managementTo = '/management/manage-sku';
  } else if (userAccess) {
    managementTo = '/management/manage-users';
  }
  // else if (templateAccess) {
  //   managementTo = '/management/manage-templates';
  // }
  let repairTo;
  if (isRepair && repairAccess) {
    repairTo = '/production/repair'
  } else if (isGrading && gradingAccess) {
    repairTo = '/production/grading'
  } else if (isDiagnostics && diagnosticsAccess) {
    repairTo = '/production/diagnostics'
  } else if (isManualSorting && manualSortingAccess) {
    repairTo = '/production/manual-sorting'
  } else if (isPolishing && polishingAccess) {
    repairTo = '/production/polishing'
  } else if (isTransit && transitAccess) {
    repairTo = '/production/transit'
  }
console.log({rmaAccess});
  const initialNavItems = [
    { text: 'Home', icon: 'home', to: '/dashboard', iconSize: 20 },
    isInventory && (isInventoryAccess || isSparePartInventoryAccess) && {
      text: 'Inventory',
      icon: 'inventory',
      to: isInventoryAccess ? '/inventory/inhouse' : '/inventory/spareparts',
      iconSize: 20,
    },
    isSales && (pointOfSaleAccess || SaleInventoryAccess) && {
      text: 'Sales',
      icon: 'sales',
      to: SaleInventoryAccess ? '/sales/sales-inventory' : '/sales/point-of-sale',
      iconSize: 20,
    },
    isManagement && (dashboardAccess || skuAccess || templateAccess || userAccess) && {
      text: 'Management',
      icon: 'management',
      to: managementTo,
      iconSize: 20,
    },
    (isRma || isCustomerRma) && (rmaAccess || myRmaAccess) && { text: 'RMA', icon: 'rma', to: rmaAccess ? '/rma/manage-customer-rma' : '/rma/manage-my-rma' },
    isStocktaking && (scriptCheck || stockingReportCheck) && {
      text: 'Stocktaking',
      icon: 'stock',
      to: scriptCheck ? '/stocktaking/script' : '/stocktaking/stocktaking-report',
      iconSize: 20,
    },
    (isTransit || isPolishing || isManualSorting || isGrading || isDiagnostics || isRepair) && (transitAccess || gradingAccess || diagnosticsAccess || polishingAccess || manualSortingAccess || repairAccess) && {
      text: 'Production environment',
      icon: 'production',
      to: repairTo,
    },
  ];
  const belowNavItems = [
    // { text: 'Slack', icon: 'slack', to: '/slack', iconSize: 20 },
    // {
    //     text: 'Phone check',
    //     icon: 'tick-mobile',
    //     to: '/phonecheck',
    //     iconSize: 18,
    // },
    // { text: 'GDrive', icon: 'g-drive', to: '/gdrive', iconSize: 20 },
    { text: 'Logout', icon: 'logout', to: '/logout', iconSize: 20 },
  ];

  return { initialNavItems, belowNavItems };
};
