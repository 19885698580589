import { useState } from "react"
import { Button } from "../../../base/Button"
import { Column } from "../../../layout/Col"
import { Flex } from "../../../layout/Flex"
import { Row } from "../../../layout/Row"
import { RowAlignCenterSpaced } from "../../../layout/RowAlignCenterSpaced"
import { CustomerReportedRMA } from "./CustomerReportedRMA"
import { CustomerSummary } from "./CustomerSummary"
import { Text } from "../../../base/Text"
import { CustomerProposedRepair } from "./CustomerProposedRepair"
import { TextBackground } from "../../../base/TextBackground"

export const Head = ({ data }: any) => {
    const [activeTab, setActiveTab] = useState('Reported RMA')
    console.log(data)
    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Row className=" ">
            <Column className='w-full'>
                <RowAlignCenterSpaced className="mb-4">
                    <Text colorV="blue" weightV="bold" variant="head">
                        RMA {data?.rmaNumber}
                    </Text>
                    <Row className="gap-x-5 items-center justify-center">
                        <Text colorV="blue" weightV="normal" variant="title">
                            Tracking number
                        </Text>
                        <TextBackground backgroundColor="b9white">
                            <Text colorV="blue" weightV="bold" variant="title">
                                {data?.trackingNumber}
                            </Text>
                        </TextBackground>
                    </Row>
                </RowAlignCenterSpaced>
                <Row className="gap-x-10 w-full">
                    <Button
                        onClick={() => handleTabClick('Reported RMA')}
                        backgroundColor={
                            activeTab === 'Reported RMA' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Reported RMA'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Reported RMA'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Reported RMA
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Proposed repair')}
                        backgroundColor={
                            activeTab === 'Proposed repair'
                                ? 'blue'
                                : 'grey'
                        }
                        className={
                            activeTab === 'Proposed repair'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Proposed repair'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Proposed repair
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Summary')}
                        backgroundColor={
                            activeTab === 'Summary' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Summary'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Summary'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Summary
                        </Text>
                    </Button>
                </Row>
                <RowAlignCenterSpaced className="mt-5">
                    {activeTab === 'Reported RMA' && <CustomerReportedRMA data={data} />}
                    {activeTab === 'Proposed repair' && (
                        <CustomerProposedRepair data={data} />
                    )}
                    {activeTab === 'Summary' && (
                        <CustomerSummary data={data} />
                    )}
                </RowAlignCenterSpaced>
            </Column>
        </Row>
    )
}
