import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TopHeader from '../../components/TopHeader'
import { useFetch } from '../../hooks/useFetch'
import { useAuth } from '../../utils/auth'
import SummaryDetail from './SummaryDetail'
    ; (pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
const baseUrl = process.env.REACT_APP_baseUrl

export default function ImeiDetails() {
    const { id } = useParams()
    const [invoice, setInvoice] = useState<any>()

    const navigate = useNavigate()
    const auth = useAuth()
    const isAdmin = auth.user.role?.includes('admin')

    const { getRequest: getSheetItems, data: sheetItemsData } = useFetch(
        `${baseUrl}/invoice/invoicedetail/${id}`
    )

    useEffect(() => {
        getSheetItems()
    }, [getSheetItems])

    useEffect(() => {
        setInvoice(sheetItemsData)
    }, [sheetItemsData])

    const openDetail: any = (
        item: any,
        id: any,
        model: any,
        vat: any,
    ) => {
        localStorage.setItem('codes', item?.codes)
        localStorage.setItem('item', id)
        localStorage.setItem('vat', vat)
        localStorage.setItem('model', model)
        localStorage.setItem('sheetItem', JSON.stringify(item))
        if (isAdmin) navigate(`/detail/description/${id}`)
    }

    return (
        <>
            <TopHeader />
            <div>
                <div className="flex flex-row">
                    <div className="py-2 inline-block mt-2 mx-6">
                        <table className="table-fixed text-center">
                            <thead className="bg-b9green2 border-b h-14">
                                <tr className="text-md font-bold">
                                    <th
                                        style={{
                                            width: 75,
                                        }}
                                        className="px-1"
                                    >
                                        Imei Number
                                    </th>
                                    <th
                                        style={{
                                            width: 96,
                                        }}
                                        className="px-1"
                                    >
                                        Model
                                    </th>
                                    <th
                                        style={{
                                            width: 140,
                                        }}
                                        className="w-16 px-1"
                                    >
                                        Grade
                                    </th>
                                    <th
                                        style={{
                                            width: 64,
                                        }}
                                        className="px-1"
                                    >
                                        Capacity
                                    </th>
                                    <th
                                        style={{
                                            width: 44,
                                        }}
                                        className="px-1"
                                    >
                                        Color
                                    </th>
                                    <th
                                        style={{
                                            width: 150,
                                        }}
                                        className="px-1"
                                    >
                                        Unit Price
                                    </th>
                                </tr>
                            </thead>
                            {invoice?.items?.map((itm: any) => (
                                <tbody
                                    key={itm._id}
                                    className={'hover:pointer'}
                                >
                                    <tr
                                        onClick={() =>
                                            openDetail(
                                                itm,
                                                itm._id,
                                                itm.model,
                                                itm.vat
                                            )
                                        }
                                        className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                    >
                                        <td className="text-md w-11 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {itm?.imeiNumber}
                                        </td>
                                        <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {itm.model}
                                        </td>
                                        <td className="text-md w-12 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {itm.grade}
                                        </td>
                                        <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {itm?.capacity}
                                        </td>
                                        <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {itm?.color}
                                        </td>
                                        <td className="text-md w-14 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {itm?.unitPrice}
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                    <div>
                        <SummaryDetail />
                    </div>
                </div>
            </div>
        </>
    )
}
