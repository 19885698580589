export const storageList = [
  { value: '8GB', label: '8GB' },
  { value: '16GB', label: '16GB' },
  { value: '32GB', label: '32GB' },
  { value: '64GB', label: '64GB' },
  { value: '128GB', label: '128GB' },
  { value: '256GB', label: '256GB' },
  { value: '512GB', label: '512GB' },
  { value: '1TB', label: '1TB' },
  { value: '2TB', label: '2TB' },

  { value: '-', label: '-' }
];

export const myRmaActions = [
  { label: 'Export', value: 'export' },
  { label: 'Send', value: 'send' },
  { label: 'Declined', value: 'declined' },
  { label: 'Merge in batch', value: 'merge_in_batch' },
  { label: 'Unmerge the batch', value: 'un_merge_the_batch' }
];

export const rmaStatus = [
  {label:"Pending RMA units", value:"pending_rma_units"},
  {label:"In-review", value:"in_review"},
  {label:"Credited", value:"credited"},
  {label:"Declined", value:"declined"},]

export const operationSystemList = ['IOS', 'Android', 'MacOS', 'WatchOS']

export const myVat = ['Reverse', 'Marginal']

export const myDevice = ['iPhone', 'iPad', 'Macbook', 'Apple Watch', 'Samsung', 'Xiaomi']

export const myCurrency = ["EURO", "USD", "DKK", "SEK", "NOK"]

export const myColor = ["blue", "grey", "black"]

export const   myGrade = ["A+", "A", "B+", "B", "C", "C+"]

export const myStatus = ["Open", "Used"]


export const myTemplates = [ "Bulk Inventory Upload","Bulk Grading"]

export enum Permissions{
    management = "Management",
  inventory = "Inventory",
  sales = "Sales",
  customerPortal = "Customer portal",
  rma = "RMA",
  myRma = "My RMA",
  stocktaking = "Stocktaking + App",
  grading = "Grading",
  manualSorting = "Manual Sorting",
  repair = "Repair",
  diagnostics = "Diagnostics",
  polishing = "Polishing",
  transit = "Transit"

}

export enum Role {
  admin = "admin",
  owner = "owner",
  customer = "customer",
  superAdmin = "superAdmin",
}

