import moment from 'moment'
import { useEffect, useState } from 'react'
import { useFetch } from '../../../../../hooks/useFetch'
import { Flex } from '../../../../layout/Flex'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { DropdownButtons } from '../../../../base/DropdownButton'
import { Text } from '../../../../base/Text'
import { DeleteRmaModel } from '../../CustomerRma/component/DeleteRmaModel'
import { useNavigate } from 'react-router-dom'

const baseUrl = process.env.REACT_APP_baseUrl

export default function ArchiveTable() {
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedItemData, setSelectedItemData] = useState(null)
    const navigate = useNavigate()

    const { getRequest, data } = useFetch(`${baseUrl}/rma/get-rma-company`)

    useEffect(() => {
        getRequest()
    }, [getRequest])

    const openRmaDetails = (item) => {
        navigate(`/rma/manage-customer-rma/detail/${item?._id}`)
    }

    const openModal = (item, selectedItem) => {
        setSelectedItem(item)
        setSelectedItemData(selectedItem)
    }

    return (
        <>
            <Flex className="w-full">
                <PrimaryCard className="w-full">
                    <div className="overflow-x-auto w-full">
                        <table className="min-w-full w-full table-auto text-b9blue bg-transparent">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className="px-4 py-2">RMA</th>
                                    <th className="px-4 py-2">Customer</th>
                                    <th className="px-4 py-2">
                                        Submission Date
                                    </th>
                                    <th className="px-4 py-2">Report Status</th>
                                    <th className="px-4 py-2">Credit Note</th>
                                    <th className="px-4 py-2">
                                        Account Manager
                                    </th>
                                    <th className="px-4 py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data
                                    ?.filter(
                                        (item) => item?.archiveStatus === true
                                    )
                                    .map((item, rowIndex) => (
                                        <tr
                                            className="cursor-pointer"
                                            key={rowIndex}
                                            onClick={() => openRmaDetails(item)}
                                        >
                                            <td className="px-4 py-2">
                                                {item?.rmaNumber}
                                            </td>
                                            <td className="px-4 py-2">
                                                {item?.user?.firstName ||
                                                    'sarmad'}
                                            </td>
                                            <td className="px-4 py-2">
                                                {moment(item?.createdAt).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </td>
                                            <td className="px-4 py-2">
                                                {item?.status === 'Credited' ? (
                                                    <div className="bg-b9midblue text-center px-2 py-1 rounded">
                                                        <Text colorV="white">
                                                            Credited
                                                        </Text>
                                                    </div>
                                                ) : item?.status ===
                                                  'Declined' ? (
                                                    <div className="bg-b9blue text-center px-2 py-1 rounded">
                                                        <Text colorV="white">
                                                            Declined
                                                        </Text>
                                                    </div>
                                                ) : item?.status ===
                                                  'In-review' ? (
                                                    <div className="bg-b9green text-center px-2 py-1 rounded">
                                                        <Text colorV="white">
                                                            In-review
                                                        </Text>
                                                    </div>
                                                ) : item?.status ===
                                                  'Archive' ? (
                                                    <div className="bg-b9green text-center px-2 py-1 rounded">
                                                        <Text colorV="white">
                                                            Archive
                                                        </Text>
                                                    </div>
                                                ) : (
                                                    <div className="bg-b9yellow text-center px-2 py-1 rounded">
                                                        <Text colorV="white">
                                                            Pending RMA units
                                                        </Text>
                                                    </div>
                                                )}
                                            </td>
                                            <td className="px-4 py-2">
                                                {item?.creditNumber || 20}
                                            </td>
                                            <td className="px-4 py-2">
                                                {item?.user
                                                    ?.accountManagerEmail ||
                                                    'Sergio'}
                                            </td>
                                            <td
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                className="px-4 py-2 relative"
                                            >
                                                <DropdownButtons
                                                    buttonLabel="View"
                                                    items={[
                                                        'Delete',
                                                        // 'Archive',
                                                    ]}
                                                    onItemClick={(
                                                        selectedItem
                                                    ) => {
                                                        openModal(
                                                            selectedItem,
                                                            data[rowIndex]
                                                        )
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </PrimaryCard>
                {selectedItem === 'Delete' && (
                    <DeleteRmaModel
                        item={selectedItemData}
                        onClose={() => {
                            getRequest()
                            setSelectedItem(null)
                            setSelectedItemData(null)
                        }}
                    />
                )}
                {/* {selectedItem === 'Archive' && (
                    <ArchiveRmaModel
                        item={selectedItemData}
                        onClose={() => {
                            setSelectedItem(null)
                            setSelectedItemData(null)
                        }}
                    />
                )} */}
            </Flex>
        </>
    )
}
