import React, { useCallback, useEffect, useState } from 'react'
import { operationSystemList } from '../../../../../../../constant/Storage'
import { Column } from '../../../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../../../base/Text'
import { useFetch } from '../../../../../../../hooks/useFetch'
import { Button } from '../../../../../../base/Button'
import { Flex } from '../../../../../../layout/Flex'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../../utils/auth'
import { ModalBox } from '../../../../../../base/ModelBox'
import { TextBackground } from '../../../../../../base/TextBackground'
import Select from 'react-select'
import { usePost } from '../../../../../../../hooks/usePost'
import MultiSelect from '../../../../../../base/MultiSelect/MultiSelect'
import { addNotification } from '../../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl;

export const AddCodeEvent: React.FC<{ onClose: () => void; item: any }> = ({ onClose, item }) => {
    const [models, setModels] = useState<string[]>([])
    const [operatingSystem, setOperatingSystem] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [codes, setCodes] = useState([])
    const [filteredModels, setFilteredModels] = useState<any[]>([]);
    const [localCodes, setLocalCodes] = useState<any[]>([]);

    const auth = useAuth();
    const { sendRequest } = usePost()
    const token = auth?.user?.token?.token;
    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )
    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    )

    useEffect(() => {
        getCodes()
    }, [getCodes])

    useEffect(() => {
        getModels()
        if (codesData) {
            setLocalCodes(codesData?.map((code: any) => ({
                label: code?.label,
                value: code?._id
            })));
        }
    }, [codesData, getModels])
    console.log({ localCodes });
    const handleModelChange = useCallback((e: any) => {
        setModels(Array.isArray(e) ? e.map((model) => model?.value) : [])
    }, [])

    const handleCodeChange = useCallback((e: any) => {
        console.log({e});
        setCodes(e)
    }, []);

    const handleOperatingSystemChange = (event: any) => {
        const selectedOS = event.target.value;
        setOperatingSystem(selectedOS);
        const filteredModels = modelsData?.filter((mdl: any) => mdl.os === selectedOS);

        const dropdownOptions = filteredModels?.map((model: any) => ({
            label: model.modelName,
            value: model._id
        })) || [];
        setFilteredModels(dropdownOptions);
    };
console.log({codes});
    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault();
        if (!models || !codes || !operatingSystem) {
            alert('Please fill in all fields.');
            return;
        }
        setIsLoading(true);
        const urlItem = `${baseUrl}/sorting-logic/create-code-and-add-to-event/${item?._id}`;
        try {
            const response = await sendRequest(
                urlItem,
                {
                    models,
                    codes,
                    operatingSystem
                },
            );
            if (response?.data) {
                toast.success(response?.data?.message || 'Added Successfully', { hideProgressBar: true });
                addNotification({
                    message: 'Rule has been added successfully',
                    type: 'success',
                });
                onClose();
            } else {
                toast.error(response?.data?.error || 'Something went wrong', { hideProgressBar: true });
            }
        } catch (error) {
            toast.error(`Error: ${error?.response?.data?.error || 'Something went wrong'}`, {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [codes, item?._id, models, onClose, operatingSystem, sendRequest])

    const   customStyles = {
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #ccc',
          borderRadius: '4px',
          position:"relative",
          boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : null,
          '&:hover': {
            borderColor: '#2684FF',
          },
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#2684FF',
        }),
        menu: (provided) => ({
          ...provided,
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: "14px",
          fontWeight: "500",
          backgroundColor: state.isSelected ? '#2684FF' : state.isFocused ? '#f0f0f0' : 'white',
          color: state.isSelected ? 'white' : '#333',
          '&:hover': {
            backgroundColor: '#2684FF',
            color: 'white',
          },
        }),
      };
    return (
        <ModalBox
            heading="Create new rule"
            onClose={onClose}
            top="50%"
            height="auto"
            width="600px"
        >
            <Column className="mt-3 gap-y-4  overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Code
                    </Text>
                    <div style={{ width: '22rem' }}>
                        <MultiSelect
                            options={localCodes}
                            onChange={handleCodeChange}
                            isMulti
                            placeholder="Select codes..."
                        />
                        {/* <Select
                            options={localCodes}
                            onChange={handleCodeChange}
                            isMulti
                        /> */}
                    </div>
                </RowAlignCenterSpaced>

                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Operating system
                    </Text>
                    <div style={{ width: '22rem' }}>
                        <select
                            id="os"
                            value={operatingSystem}
                            onChange={handleOperatingSystemChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {operationSystemList?.map((os: any) => (
                                <option key={os} value={os}>
                                    {os}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>

                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <div style={{ width: '22rem' }}>
                        {filteredModels?.length === 0 ?
                            <TextBackground className='w-full' backgroundColor='b9white'>
                                <Text colorV='blue' weightV='normal' variant='body' >
                                    No model for selected OS
                                </Text>
                            </TextBackground> :
                            <div className="w-full">
                                <Select
                                    maxMenuHeight={100}
                                    options={filteredModels}
                                    onChange={handleModelChange}
                                    isMulti
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </div>
                            // <select
                            //     id="model"
                            //     value={model}
                            //     onChange={handleModelChange}
                            //     className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            //     <option value=''>Select</option>
                            //     {filteredModels?.map((mdl: any) => (
                            //         <option key={mdl?._id} value={mdl?._id}>
                            //             {mdl?.modelName}
                            //         </option>
                            //     ))}
                            // </select>
                        }
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button
                        className='w-32'
                        backgroundColor="lgblue"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Saving...' : 'Save'}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
