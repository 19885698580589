import React from 'react'
import { PrimaryCard } from '../../../compound/Page/PrimaryCard'
import { Flex } from '../../../layout/Flex'
import { Table } from '../../../shared/Table'
import moment from 'moment'

export const CustomerProposedRepair = ({ data }: any) => {

    const columns = [
        'PON',
        'Purchase date',
        'IMEI',
        'Model',
        'Storage',
        'Purchase price',
        'VAT',
        'Reason',
        'Price proposal',
        'Status',
    ]
    let extractedData = []
    if (data) {
        const filteredItems = data?.items?.filter(item => item?.rmaItemsStatus === 'proposed_repair');
        extractedData = filteredItems.map((item) => {
            return {
                id: item?._id,
                PON: data?.purchaseOrder?.saleBatchNumber ,
                'Purchase date': moment(item?.purchaseDate).format('DD.MM.YYYY'),
                IMEI: item?.imei,
                Model: item?.model?.modelName,
                Storage: item?.storage,
                'Purchase price': item?.salePrice,
                VAT: item?.vat,
                'Reason': item?.reasonOfReturn,
                'Price proposal': item?.priceProposal,
                Status: item?.acceptance
            };
        });
    }

    return (
        <PrimaryCard className="">
            <div className="overflow-auto rounded-lg text-left" style={{ maxWidth: '100%', width: '100%', overflowX: 'auto' }}>

                <Table
                    className='table-fixed'
                    columns={columns}
                    data={extractedData}
                    clickable={false}
                    showActions={false}
                />
            </div>
        </PrimaryCard>
    )
}
