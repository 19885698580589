import React from 'react'
import { CustomerPurchase } from '../components/customerPortal/Purchase/CustomerPurchase'
import Header from '../components/shared/Header/Header'
import { Breadcrumb } from '../components/base/Breadcrumb'
import { Row } from '../components/layout/Row'
import { useBreadcrumb } from '../hooks/useBreadcrumb'

export const Purchase = () => {
    const breadcrumbItems = useBreadcrumb()

    return (
        <>
            <Header />
            <Row className="my-2 w-full">
                <Breadcrumb items={breadcrumbItems} separator="/" />
            </Row>
            <CustomerPurchase />
        </>
    )
}
