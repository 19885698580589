import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import { Events } from '../constants/eventEnum'
import { useFetch } from '../hooks/useFetch'

const baseUrl = process.env.REACT_APP_baseUrl

export default function Codes({
    sendCodesBeforeRepair,
    sendEventAfterRepair,
    sendCodesAfterRepair,
    sendEventBeforeRepair,
}: any) {
    const [codeEvents, setCodeEvents] = useState<any[]>([])
    const [prevCodes, setPrevCodes] = useState<any[]>([])
    const [prevCodesAfterFix, setPrevCodesAfterFix] = useState<any[]>([])
    const [codeDescription, setCodeDescription] = useState<any>()
    const [codes, setCodes] = useState<string[]>([])
    const [codesAfterFix, setCodesAfterFix] = useState<string[]>([])
    const [eventAfterFix, setEventAfterFix] = useState('')
    const [event, setEvent] = useState('')
    const [item, setItem] = useState<any>()
    const { id } = useParams()

    const handleCodes = useCallback(
        (e: any) => {
            setCodes(Array.isArray(e) ? e.map((code) => code?.label) : [])
            sendCodesBeforeRepair(
                Array.isArray(e) ? e.map((code) => code?.label) : []
            )
            if (!codes) {
                setEvent('')
            }
        },
        [codes, sendCodesBeforeRepair]
    )
    const handleCodesAfterRepair = useCallback(
        (e: any) => {
            setCodesAfterFix(
                Array.isArray(e) ? e.map((code) => code?.label) : []
            )
            sendCodesAfterRepair(
                Array.isArray(e) ? e.map((code) => code?.label) : []
            )
        },
        [sendCodesAfterRepair]
    )
    const { getRequest: getItem, data: itemData } = useFetch(
        `${baseUrl}/item/${id}`
    )
    const { getRequest: getSheetsDescription, data: sheetDescriptionData } =
        useFetch(`${baseUrl}/code/getCodes`)
    const { getRequest: getCodeEvents, data: codeEventsData } = useFetch(
        `${baseUrl}/code-event/getEvents`
    )

    useEffect(() => {
        getItem()
        getSheetsDescription()
        getCodeEvents()
    }, [getCodeEvents, getItem, getSheetsDescription])

    useEffect(() => {
        setItem(itemData)
        setPrevCodes(itemData?.codes || [])
        setPrevCodesAfterFix(itemData?.codesAfterFix || [])
        setCodes(itemData?.codes)
        setCodesAfterFix(itemData?.codesAfterFix)
        setEvent(itemData?.event)
        setEventAfterFix(itemData?.eventAfterFix)
        const myCodes = sheetDescriptionData?.map((element) => {
            return { label: element?.label, value: element?.description }
        })
        setCodeDescription(myCodes)
        setCodeEvents(codeEventsData)
    }, [codeEventsData, itemData, sheetDescriptionData])

    useEffect(() => {
        const events: string[] = []
        if (codesAfterFix?.length > 0) {
            for (const code of codesAfterFix) {
                for (const eventCode of codeEvents || []) {
                    if (eventCode?.code === code) {
                        if (
                            eventCode?.modelName?.includes(
                                item?.model?.modelName
                            )
                        ) {
                            events.push(eventCode?.event)
                        }
                    }
                }
            }
            if (events.includes(Events.scrap)) {
                setEventAfterFix(Events.scrap)
                sendEventAfterRepair(Events.scrap)
            } else if (events.includes(Events.warranty)) {
                setEventAfterFix(Events.warranty)
                sendEventAfterRepair(Events.warranty)
            } else if (events.includes(Events.hardRepair)) {
                setEventAfterFix(Events.hardRepair)
                sendEventAfterRepair(Events.hardRepair)
            } else if (events.includes(Events.mediumRepair)) {
                setEventAfterFix(Events.mediumRepair)
                sendEventAfterRepair(Events.mediumRepair)
            } else if (events.includes(Events.easyRepair)) {
                setEventAfterFix(Events.easyRepair)
                sendEventAfterRepair(Events.easyRepair)
            } else if (events.includes(Events.return)) {
                setEventAfterFix(Events.return)
                sendEventAfterRepair(Events.return)
            } else if (events.includes(Events.salesInventory)) {
                setEventAfterFix(Events.salesInventory)
                sendEventAfterRepair(Events.salesInventory)
            } else if (events.includes(Events.rma)) {
                setEventAfterFix(Events.rma)
                sendEventAfterRepair(Events.rma)
            } else if (events.includes(Events.tounlock)) {
                setEventAfterFix(Events.tounlock)
                sendEventAfterRepair(Events.tounlock)
            } else if (events.includes(Events.ms_repair)) {
                setEventAfterFix(Events.ms_repair)
                sendEventAfterRepair(Events.ms_repair)
            }
        } else {
            setEventAfterFix('')
        }
    }, [
        codesAfterFix?.length,
        codeEvents,
        item?.model,
        sendEventBeforeRepair,
        sendEventAfterRepair,
        codesAfterFix,
    ])

    useEffect(() => {
        const events: string[] = []
        if (codes?.length > 0) {
            for (const code of codes) {
                for (const eventCode of codeEvents || []) {
                    if (eventCode?.code === code) {
                        if (
                            eventCode?.modelName?.includes(
                                item?.model?.modelName
                            )
                        ) {
                            events.push(eventCode?.event)
                        }
                    }
                }
            }
            if (events.includes(Events.scrap)) {
                setEvent(Events.scrap)
                sendEventBeforeRepair(Events.scrap)
            } else if (events.includes(Events.warranty)) {
                setEvent(Events.warranty)
                sendEventBeforeRepair(Events.warranty)
            } else if (events.includes(Events.hardRepair)) {
                setEvent(Events.hardRepair)
                sendEventBeforeRepair(Events.hardRepair)
            } else if (events.includes(Events.mediumRepair)) {
                setEvent(Events.mediumRepair)
                sendEventBeforeRepair(Events.mediumRepair)
            } else if (events.includes(Events.easyRepair)) {
                setEvent(Events.easyRepair)
                sendEventBeforeRepair(Events.easyRepair)
            } else if (events.includes(Events.return)) {
                setEvent(Events.return)
                sendEventBeforeRepair(Events.return)
            } else if (events.includes(Events.salesInventory)) {
                setEvent(Events.salesInventory)
                sendEventBeforeRepair(Events.salesInventory)
            } else if (events.includes(Events.rma)) {
                setEvent(Events.rma)
                sendEventBeforeRepair(Events.rma)
            } else if (events.includes(Events.tounlock)) {
                setEvent(Events.tounlock)
                sendEventBeforeRepair(Events.tounlock)
            } else if (events.includes(Events.ms_repair)) {
                setEvent(Events.ms_repair)
                sendEventBeforeRepair(Events.ms_repair)
            }
        } else {
            setEvent('')
        }
    }, [codes?.length, codeEvents, codes, item?.model, sendEventBeforeRepair])

    const items = []
    if (prevCodes) {
        for (const cod of prevCodes) {
            const item = codeDescription?.find(
                (instance: any) => instance?.label === cod
            )
            if (item) {
                items.push(item)
            }
        }
    }
    const itemsAfterRepair = []

    if (prevCodesAfterFix) {
        for (const cod of prevCodesAfterFix) {
            const item = codeDescription?.find(
                (instance: any) => instance.label === cod
            )
            if (item) {
                itemsAfterRepair.push(item)
            }
        }
    }

    return (
        <>
            <div className="py-2 mt-3 flex justify-between">
                <div className="w-72">
                    <div className="mb-3">
                        <label className="w-24 font-bold">
                            Codes before fix
                        </label>
                    </div>
                    {items.length > 0 && items.length === prevCodes?.length && (
                        <div className="w-full">
                            <Select
                                defaultValue={items}
                                options={codeDescription}
                                onChange={handleCodes}
                                isMulti
                            />
                        </div>
                    )}
                    {prevCodes?.length === 0 && (
                        <div className="w-full">
                            <Select
                                defaultValue={items}
                                options={codeDescription}
                                onChange={handleCodes}
                                isMulti
                            />
                        </div>
                    )}

                    <div className="flex mt-2 py-2">
                        <div className="w-36">
                            <label className="font-bold">
                                Status before fix:{' '}
                            </label>
                        </div>
                        <span className=" font-medium">{event}</span>
                    </div>
                </div>
                <div
                    className="mx-4"
                    style={{ borderLeft: '1px solid silver' }}
                ></div>
                <div className="w-72">
                    <div className="mb-3">
                        <label className="w-24 font-bold">
                            Codes after fix
                        </label>
                    </div>
                    {itemsAfterRepair.length > 0 &&
                        itemsAfterRepair.length ===
                            prevCodesAfterFix?.length && (
                            <div className="w-full">
                                <Select
                                    defaultValue={itemsAfterRepair}
                                    options={codeDescription}
                                    onChange={handleCodesAfterRepair}
                                    isMulti
                                />
                            </div>
                        )}
                    {prevCodesAfterFix?.length === 0 && (
                        <div className="w-full">
                            <Select
                                defaultValue={itemsAfterRepair}
                                options={codeDescription}
                                onChange={handleCodesAfterRepair}
                                isMulti
                            />
                        </div>
                    )}
                    <div className="flex mt-2 py-2">
                        <div className="w-32">
                            <label className="font-bold w-16">
                                Status after fix:{' '}
                            </label>
                        </div>
                        <span className=" font-medium">{eventAfterFix}</span>
                    </div>
                </div>
            </div>
        </>
    )
}
