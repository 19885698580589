import React, { useEffect, useState } from 'react';
import { Flex } from '../../../layout/Flex';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../base/Text';
import { TextBackground } from '../../../base/TextBackground';
import { Row } from '../../../layout/Row';
import moment from 'moment';

export default function Head({ itemsData }: any) {
  const [rmaDuration, setRmaDuration] = useState('');
  useEffect(() => {
    if (itemsData && itemsData) {
      const startDate = moment(itemsData?.createdAt);
      const endDate = moment(itemsData?.createdAt).add(1, 'days').add(itemsData?.assignMyRmaDuration, 'days');
      const remainingDays = endDate.diff(moment(), 'days');

      const remainingDaysString =
        remainingDays > 0
          ? `${remainingDays} day${remainingDays !== 1 ? 's' : ''} left`
          : remainingDays === 0
            ? 'Today is the last day'
            : 'The deadline has passed';
      setRmaDuration(remainingDaysString);
    }
  }, [itemsData]);

  return (
    <Flex className="pb-4 pt-2">
      <RowAlignCenterSpaced className="gap-x-5 w-full">
        <Row className='gap-x-10'>
          <Text colorV="blue" variant="head" weightV="bold">
            Sale order
          </Text>
          <Text colorV="blue" variant="head" weightV="bold">
            {itemsData?.saleBatchNumber}
          </Text>
        </Row>
        <TextBackground backgroundColor="b9blue" className="">
          <Row className="gap-x-5 items-center justify-center">
            <Text colorV="white" weightV="bold" variant="title">
              RMA
            </Text>
            <div className="bg-white rounded h-6 px-4">
              <Text colorV="blue" weightV="normal" variant="title">
                {rmaDuration}
              </Text>
            </div>
          </Row>
        </TextBackground>
      </RowAlignCenterSpaced>
    </Flex>
  );
}
