// ... (existing imports)

import axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../../../utils/auth";
import { Button } from "../../../../../../base/Button";
import { ModalBox } from "../../../../../../base/ModelBox";
import { TextField } from "../../../../../../base/Textfield";
import { Column } from "../../../../../../layout/Col";
import { Flex } from "../../../../../../layout/Flex";
import { RowAlignCenterSpaced } from "../../../../../../layout/RowAlignCenterSpaced";
import { Text } from "../../../../../../base/Text";
import PropTypes from 'prop-types';
import { sortingLogicEventType } from "../../../../../../../constant/SortingLogicEvent";
import { usePost } from "../../../../../../../hooks/usePost";
import { addNotification } from "../../../../../../shared/Notification/Notification";
const baseUrl = process.env.REACT_APP_baseUrl;

export const ManualSortingAddEvent: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [eventName, setEventName] = useState<string>('');
    const [eventType, setEventType] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const auth = useAuth();
    const token = auth?.user?.token?.token;
    const {sendRequest} = usePost()
    const handleEventNameChange = (newValue: any) => {
        setEventName(newValue);
    };

    const handleEventTypeChange = (event: any) => {
        setEventType(event.target.value);
    };

    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault();
        if (!eventName || !eventType) {
            alert('Please fill in all fields.');
            return;
        }
        setIsLoading(true);
        const urlItem = `${baseUrl}/sorting-logic/add-manual-sorting-event`;
        try {
            const response = await sendRequest(
                urlItem,
                {
                    eventName,
                    eventType,
                },
            );
            if (response.data) {
                toast.success(response.data.message || 'Added Successfully', { hideProgressBar: true });
                addNotification({
                    message: 'Event has been added successfully',
                    type: 'success',
                });
                onClose();
            } else {
                toast.error(response.data.error || 'Something went wrong', { hideProgressBar: true });
            }
        } catch (error) {
            toast.error(`Error: ${error.response?.data?.error || 'Something went wrong'}`, {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false);
        }
    },[eventName, eventType, onClose, sendRequest])
    

    return (
        <ModalBox
            heading="Create new event"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[200px] overflow-auto">
            <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status Name
                    </Text>
                    <TextField
                        value={eventName}
                        onChange={handleEventNameChange}
                        placeholder="Enter name"
                        width="18rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status type
                    </Text>
                    <select
                        id="key"
                        value={eventType}
                        style={{fontSize:'16px'}}
                        onChange={handleEventTypeChange}
                        className="w-72 hover:cursor-pointer text-sm h-10  px-5"
                    >
                        <option>Select</option>
                        {sortingLogicEventType?.map((event: any) => (
                            <option key={event.title} value={event.value}>
                                {event.title}
                            </option>
                        ))}
                    </select>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button
                        className='w-32'
                        backgroundColor="lgblue"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Saving...' : 'Save'}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    );
};

ManualSortingAddEvent.propTypes = {
    onClose: PropTypes.func.isRequired,
};
