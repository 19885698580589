import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopHeader from '../components/TopHeader'
import { useFetch } from '../hooks/useFetch'
import { useAuth } from '../utils/auth'

const baseUrl = process.env.REACT_APP_baseUrl

export default function Users() {
    const [user, setUser] = useState<any>()
    const auth = useAuth()
    const navigate = useNavigate()

    const { getRequest: getUser, data: userData } = useFetch(
        `${baseUrl}/user/me`
    )

    useEffect(() => {
        getUser()
    }, [getUser])

    useEffect(() => {
        setUser(userData)
    }, [userData])

    const logout = () => {
        auth?.logout()
        navigate('/')
    }

    const ChangePassword = () => {
        navigate('/changePassword')
    }
    return (
        <>
            <TopHeader />
            <div className="flex pb-5 flex-col">
                <div className="overflow-x-auto">
                    <div className="flex justify-between ">
                        <h1 className="font-bold text-xl ml-5 mt-5">Profile</h1>
                        <div>
                            <button
                                onClick={ChangePassword}
                                className="py-2 px-4 mt-5 border mr-2 border-transparent text-sm font-medium rounded-md bg-b9green3 text-white hover:bg-b9green4 focus:outline-none"
                            >
                                Change Password
                            </button>
                            <button
                                onClick={logout}
                                className="py-2 px-4 mt-5 border mr-2 border-transparent text-sm font-medium rounded-md bg-b9green3 text-white hover:bg-b9green4 focus:outline-none"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                    <div className="py-1 mx-5  inline-block min-w-ful mt-6">
                        <div className="flex flex-row">
                            <div className="w-44  ">First Name</div>{' '}
                            <div className="font-medium">
                                {' '}
                                {user?.firstName}
                            </div>
                        </div>
                        <div className="flex my-4">
                            <div className="w-44">Last Name </div>
                            <div className="font-medium"> {user?.lastName}</div>
                        </div>
                        <div className="flex">
                            <div className="w-44">Email </div>
                            <div className="font-medium"> {user?.email}</div>
                        </div>
                        <div className="flex my-4">
                            <div className="w-44">Role </div>
                            <div className="font-medium">
                                {' '}
                                {user?.role?.toString()}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-44">Company Name </div>
                            <div className="font-medium">
                                {' '}
                                {user?.companyName}
                            </div>
                        </div>
                        <div className="flex my-4">
                            <div className="w-44">Account Manager </div>
                            <div className="font-medium">
                                {' '}
                                {user?.accountManagerEmail}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-44">Currency </div>
                            <div className="font-medium"> {user?.currency}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
