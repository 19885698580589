import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Button } from '../../../../base/Button'
import { TextField } from '../../../../base/Textfield'
import { Text } from '../../../../base/Text'
import { Row } from '../../../../layout/Row'
import { FileUpload } from '../../../../shared/Fileupload'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { useFetch } from '../../../../../hooks/useFetch'
import { Icon } from '../../../../base/Icon/Icon'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const SingleSale = () => {
    const [customer, setCustomer] = useState<any>('')
    const [saleBatchNumber, setSaleBatchNumber] = useState<any>('')
    const [trackingNumber, setTrackingNumber] = useState<string>('')
    const [logisticCompany, setLogisticCompany] = useState<string>('')
    const [totalValue, setTotalValue] = useState<string>('')
    const [imei, setImei] = useState<string>('')
    const [rmaDuration, setRmaDuration] = useState<string>('')
    const [uploadedFile, setUploadedFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const auth = useAuth()

    const token = auth?.user?.token?.token
    const company = auth?.selectedCompany
    const { getRequest: getPartners, data: partnersData } = useFetch(
        `${baseUrl}/partner/get-partners`
    )
    useEffect(() => {
        getPartners()
    }, [getPartners])
    const filteredCustomerData = partnersData?.filter(partner => partner.tags.includes("customer"));
    const filteredLogisticData = partnersData?.filter(partner => partner.tags.includes("lsp"));

    const handleCustomerChange = (event: any) => {
        setCustomer(event.target.value)
    }

    const handleSalesBranchNumberChange = (newValue: any) => {
        setSaleBatchNumber(newValue)
    }

    const handleTrackingNumberChange = (newValue: any) => {
        setTrackingNumber(newValue)
    }

    const handleLogisticCompanyChange = (event: any) => {
        setLogisticCompany(event.target.value)
    }

    const handleTotalValueChange = (newValue: any) => {
        setTotalValue(newValue)
    }

    const handleImeiChange = (newValue: any) => {
        setImei(newValue)
    }

    const handleRmaDurationChange = (newValue: any) => {
        setRmaDuration(newValue)
    }

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault()
        if (customer === "") {
            alert("Add customer")
            return
        }
        if (saleBatchNumber === "") {
            alert("Add Sale branch number")
            return
        }
        if (trackingNumber === "") {
            alert("Add tracking number")
            return
        }
        if (logisticCompany === "") {
            alert("Add logistic company")
            return
        }
        if (rmaDuration === "") {
            alert("Add logistic company")
            return
        }
        if (totalValue === "") {
            alert("Add total value")
            return
        }
        if (uploadedFile === null) {
            alert("Add invoice")
            return
        }
        const formData = new FormData()
        formData.append('customer', customer)
        formData.append('saleBatchNumber', saleBatchNumber)
        formData.append('trackingNumber', trackingNumber)
        formData.append('logisticCompany', logisticCompany)
        formData.append('totalValue', totalValue)
        formData.append('imei', imei)
        formData.append('rmaDuration', rmaDuration)
        formData.append("saleType", "Single sale")
        if (uploadedFile) {
            formData.append('file', uploadedFile)
        }
        setIsLoading(true)
        const urlItem = `${baseUrl}/purchase/add-single-purchase-order`
        const config = {
            headers: {
                Authorization: token as string,
                'Content-Type': 'multipart/form-data', // Important for file upload
                Company: company._id
            },
        }

        try {
            const response = await axios.post(urlItem, formData, config)
            toast.success(response.data.message, { hideProgressBar: true })
            addNotification({
                message: 'Sale has been added successfully',
                type: 'success',
            })
            setIsLoading(false)
            setCustomer('')
            setImei('')
            setLogisticCompany('')
            setRmaDuration('')
            setTotalValue('')
            setTrackingNumber('')
            setSaleBatchNumber('')
            setUploadedFile(null)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            toast.error(`Error: ${error?.response?.data?.message}`, {
                hideProgressBar: true,
            })
        }
    }, [company._id, customer, imei, logisticCompany, rmaDuration, saleBatchNumber, token, totalValue, trackingNumber, uploadedFile])

    const handleFileUpload = (file: File) => {
        setUploadedFile(file)
    }

    return (
        <PrimaryCard className="p-5 w-full">
            <Row className="gap-x-5 w-full">
                <Row className="w-3/5">
                    <Column className="gap-y-5 w-full">
                        <RowAlignCenterSpaced className="">
                            <Text colorV="blue" variant="title" weightV="bold">
                                Customer
                            </Text>
                            <div style={{ width: '20.1rem' }}>
                                <select
                                    id="customer"
                                    value={customer}
                                    onChange={handleCustomerChange}
                                    className="w-full hover:cursor-pointer bg-white rounded-lg   py-2 px-1"                                >
                                    <option value="">Select</option>
                                    {filteredCustomerData?.map((partner: any) => (
                                        <option
                                            key={partner?._id}
                                            value={partner?._id}
                                        >
                                            {partner?.companyName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Sales batch number
                            </Text>
                            <TextField
                                name="salesBranchNumber"
                                value={saleBatchNumber}
                                onChange={handleSalesBranchNumberChange}
                                placeholder="Enter sales branch number"
                                backgroundColor='white'
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Assign my RMA duration
                            </Text>
                            <TextField
                                name="rmaDuration"
                                value={rmaDuration}
                                onChange={handleRmaDurationChange}
                                placeholder="Enter RMA duration"
                                backgroundColor='white'
                                
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Tracking number
                            </Text>
                            <TextField
                                name="trackingNumber"
                                value={trackingNumber}
                                onChange={handleTrackingNumberChange}
                                placeholder="Enter tracking number"
                                backgroundColor='white'
                                
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Logistic company
                            </Text>
                            <div style={{ width: '20.1rem' }}>
                                <select
                                    id="company"
                                    value={logisticCompany}
                                    onChange={handleLogisticCompanyChange}
                                    className="w-full hover:cursor-pointer bg-white rounded-lg   py-2 px-1"                                >
                                    <option value="">Select</option>
                                    {filteredLogisticData?.map((partner: any) => (
                                        <option
                                            key={partner?._id}
                                            value={partner?._id}
                                        >
                                            {partner?.companyName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Total value
                            </Text>
                            <TextField
                                name="totalValue"
                                value={totalValue}
                                onChange={handleTotalValueChange}
                                placeholder="Enter total value"
                                backgroundColor='white'
                                
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <Flex className="gap-x-4 mt-4">
                            <Button
                                onClick={handleSubmit}
                                backgroundColor="lgblue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    {isLoading ? "Saving..." : "Fullfill & Save"}
                                </Text>
                            </Button>
                        </Flex>
                    </Column>
                </Row>
                <Row className="w-2/5">
                    <Column className="w-full">
                        <TextField
                            name="imei"
                            value={imei}
                            onChange={handleImeiChange}
                            placeholder="Enter IMEI"
                            backgroundColor='white'
                            
                            className="w-full"
                        />
                        <Column className="mt-5">
                            <FileUpload
                                fileTypeDescription='invoice'
                                height="191px"
                                onFileUpload={handleFileUpload}
                                backgroundColor="#FFFFFF"
                            />
                            {uploadedFile && (
                                <Row className='gap-x-3 items-center'>
                                    <Text colorV="blue" variant="title" weightV="normal">
                                        Uploaded File: {uploadedFile.name}
                                    </Text>
                                    <span className='cursor-pointer' onClick={() => setUploadedFile(null)}>
                                        <Icon name="cross" color="black" size={16} />
                                    </span>
                                </Row>
                            )}
                        </Column>
                    </Column>
                </Row>
            </Row>
        </PrimaryCard>
    )
}
