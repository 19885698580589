import React, { ChangeEvent, CSSProperties, useState, KeyboardEvent } from 'react'
import { Icon } from '../Icon'

interface TextFieldProps {
    name?: string
    type?: string // Set a default value of 'text'
    value: string
    onChange: (value: string) => void
    onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void // Add onKeyPress event handler
    placeholder?: string
    className?: string
    inlineStyle?: CSSProperties
    borderRadius?: string
    padding?: string
    width?: string
    inputStyle?: CSSProperties
    backgroundColor?: string
    icon?: boolean // Add an icon prop
    height?: string // Add height property
    disable?: boolean
    defaultValue?: string
    autoFocus?: any
}

export const TextField: React.FC<TextFieldProps> = ({
    type = 'text',
    value,
    name,
    onChange,
    onKeyPress, // Add onKeyPress prop
    placeholder,
    className,
    borderRadius,
    padding = '12px',
    inputStyle,
    backgroundColor = '#F2F0ED', // Set default background color here
    icon,
    height = '40px',
    width,
    disable,
    defaultValue,
    autoFocus
}) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        onChange(newValue)
    }

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyPress) {
            onKeyPress(event);
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const inputType = showPassword ? 'text' : type

    const defaultInputStyle: CSSProperties = {
        backgroundColor: backgroundColor,
        borderRadius: borderRadius || '8px',
        padding,
        height,
        position: 'relative',
        width,
        fontSize: '16px',
        fontWeight: 400,
        color: "#112D56"
    }

    const mergedInputStyle: CSSProperties = {
        ...defaultInputStyle,
        ...inputStyle,
    }

    return (
        <div className="relative">
            <input
                name={name}
                type={inputType}
                disabled={disable}
                value={value}
                onChange={handleChange}
                onKeyPress={handleKeyPress} // Pass the handleKeyPress function
                placeholder={placeholder}
                className={` ${className}`}
                style={mergedInputStyle}
                defaultValue={defaultValue}
                autoFocus={autoFocus}
            />
            {type === 'password' && icon && (
                <div
                    className="password-icon"
                    onClick={togglePasswordVisibility}
                    style={{
                        position: 'absolute',
                        right: '2%',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                    }}
                >
                    {showPassword ? (
                        <Icon name="openEye" color="black" />
                    ) : (
                        <Icon name="closeEye" color="black" />
                    )}
                </div>
            )}
        </div>
    )
}
