import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { Row } from '../../../../layout/Row'
import { useEdit } from '../../../../../hooks/useEdit'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const CodeAfterRepair: React.FC<{
    onClose: () => void
    item: any
}> = ({ onClose, item }) => {
    const [codesAfterFix, setCodesAfterFix] = useState(item?.codes)
    const [sortingLogic, setSortingLogic] = useState<any>()
    const [eventType, setEventType] = useState<any>(item?.sortingEvent?.type)
    const [rules, setRules] = useState<any>()
    const [event, setEvent] = useState<string>(item?.sortingEvent?.name)
    const [manualSortingId, setManualSortingId] = useState<any>(item?.sortingEvent?._id)
    const auth = useAuth()
    const company = auth.selectedCompany
    const { sendEditRequest } = useEdit()

    const extractAndSetRules = useCallback((rulesData) => {
        const manualSortingRules = rulesData?.manualSorting?.map((manualSort) => {
            const rules = manualSort?.rules.map((rule) => {
                if (rule?.models) {
                    for (const model of rule.models) {
                        if (model?._id === item?.model?._id) {
                            return rule?.code;
                        }
                    }
                }
                return null;
            });
            return rules.filter(Boolean);
        });
        const flattenedRules = manualSortingRules.flat();
        setRules(flattenedRules);
    }, [item]);
    console.log({rules});
    const getSortingLogic = useCallback(async () => {
        const token = auth.user?.token?.token
        await axios
            .get(`${baseUrl}/sorting-logic/get-sorting-logic-events`, {
                headers: {
                    Authorization: token,
                    Company: company._id,

                },
            })
            .then((data) => {
                setSortingLogic(data?.data)
                extractAndSetRules(data?.data)
            })
            .catch((error) => {
                toast.error(error.response?.data?.message, {
                    hideProgressBar: true,
                })
            })
    }, [auth.user?.token?.token, company._id, extractAndSetRules])

    useEffect(() => {
        getSortingLogic()
        return () => { }
    }, [getSortingLogic])

    useEffect(() => {
        let localEvent: any
        const manualSorting = sortingLogic?.manualSorting || []
        for (const manualSort of manualSorting) {
            for (const code of codesAfterFix) {
                const ruleLabels = manualSort.rules.map(rule => {
                    for (const model of rule.models) {
                        if (model?._id === item?.model?._id) {
                            return rule.code.map(code => code.label);
                        }
                    }
                    return null;
                });
                const flattenedRuleLabels = ruleLabels.flat();
                if (flattenedRuleLabels.includes(code)) {
                    localEvent = manualSort;
                    setEvent(manualSort.name);
                    setEventType(manualSort.type);
                    setManualSortingId(manualSort._id);
                    return;
                }
            }
        }

    }, [codesAfterFix, item?.model?._id, rules, sortingLogic?.manualSorting])

    const handleCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCode = e.target.value;
        if (selectedCode && !codesAfterFix.includes(selectedCode)) {
            setCodesAfterFix((prevCodes) => [...prevCodes, selectedCode]);
        }
    };

    const handleSave = useCallback(async (e) => {
        e.preventDefault()
        await sendEditRequest(
            `${baseUrl}/item/event-code/${item?._id}`,
            {
                codes: codesAfterFix,
                id: item?._id,
                eventType,
                manualSortingId,
                event,
            },
        )
            .then(() => {
                toast.success('Saved', { hideProgressBar: true })
                addNotification({
                    message: 'Code has been added successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }, [codesAfterFix, event, eventType, item?._id, manualSortingId, onClose, sendEditRequest])

    const uniqueCodes = new Set();

    return (
        <ModalBox
            heading="Add code after fix"
            onClose={onClose}
            top="50%"
            height="auto"
            width="300px"
        >
            <Column className="gap-y-4 h-[170px] overflow-auto">
                <Row className="w-full">
                    <Text variant="title" colorV="blue" weightV="normal">
                        Code after fix
                    </Text>
                </Row>
                <Row>
                    {
                        <div className='w-full'>
                            <select
                                id="code"
                                style={{ width: "100%" }}
                                onChange={(e) => handleCodeChange(e)}
                                className="py-1.5 "
                            >
                                <option value="">Select</option>
                                {rules?.flat().map((code: any) => {
                                    if (!item?.codes?.includes(code?.label)) {
                                        if (!uniqueCodes.has(code?.label)) {
                                            uniqueCodes.add(code?.label);
                                            return (
                                                <option key={code?._id} value={code?.label}>
                                                    {code?.label}
                                                </option>
                                            );
                                        }
                                    }
                                    return null; // Skip rendering if the code is already in item.codesAfterFix
                                })}
                            </select>
                        </div>
                    }
                </Row>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-full' onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Add
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
