import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from '../../../layout/Col';
import { Row } from '../../../layout/Row';
import { useFetch } from '../../../../hooks/useFetch';
import Head from './Head';
import SaleItemsTable from './SaleItemsTable';
import { Button } from '../../../base/Button';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../base/Text';
import { ExportContent } from './component/ExportModel';
const baseUrl = process.env.REACT_APP_baseUrl

export default function SaleItems() {
  const [modalContent, setModalContent] = useState(null)

  const handleExport = () => {
    setModalContent(<ExportContent items={data} onClose={() => setModalContent(null)} />)
  }
  const { id } = useParams()
  const { getRequest: getSkuItems, data } = useFetch(
    `${baseUrl}/saleinventory/sku-items/${id}`
  )
  useEffect(() => {
    getSkuItems()
  }, [getSkuItems])
  const refetch = () => {
    getSkuItems()
  };

  return (
    <>
      <Column className="mb-5 ml-5">
        <Head />
        <RowAlignCenterSpaced className="gap-x-5 mb-2 w-full">
          <Row className='gap-x-5'>
            <Text colorV="blue" variant="head" weightV="bold">
              Sale inventory
            </Text>
          </Row>
          <Button onClick={handleExport} backgroundColor="green">
            <Text colorV="white" variant="title" weightV="normal">
              Export
            </Text>
          </Button>
        </RowAlignCenterSpaced>
        <SaleItemsTable data={data} />
        {modalContent}
      </Column>
    </>
  );
}
