import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../utils/auth';
import { Button } from '../../../../../base/Button';
import { Column } from '../../../../../layout/Col';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Text } from '../../../../../base/Text';
import { TextField } from '../../../../../base/Textfield';
import { usePost } from '../../../../../../hooks/usePost';
import { Icon } from '../../../../../base/Icon';
import { Row } from '../../../../../layout/Row';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { addNotification } from '../../../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl;

export const CompletePolishing = () => {
    const { sendRequest } = usePost();
    const [imei, setImei] = useState('');
    const [imeis, setImeis] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const handleImeiChange = useCallback((newValue) => {
        setImei(newValue);
    }, []);

    const handleAddImei = useCallback(() => {
        if (imei.trim() !== '') {
            setImeis([...imeis, imei]);
            setImei('');
        }
    }, [imei, imeis]);

    const handleRemoveImei = (imeiToRemove) => {
        const updatedImeis = imeis.filter((imei) => imei !== imeiToRemove);
        setImeis(updatedImeis);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleAddImei();
        }
    };

    const handleComplete = useCallback(async () => {
        if (imeis.length === 0) {
            toast.error('Please add code labels');
            return;
        }

        setIsLoading(true);

        const urlItem = `${baseUrl}/polishing/complete-polishing`;

        try {
            const response = await sendRequest(urlItem, { imeis });
            const { successes = [], errors = [] } = response?.data ?? {};

            successes.forEach((success) => {
                toast.success(`${success.message} for IMEI: ${success.imei}`, { hideProgressBar: true });
                addNotification({
                    message: `Polishing completed for IMEI ${success.imei}`,
                    type: 'success',
                });
            });

            errors.forEach((error) => {
                toast.error(`${error.message} for IMEI: ${error.imei}`, { hideProgressBar: true });
            });

            setImeis([]);
        } catch (error) {
            toast.error(`Error: ${error.response?.data?.message ?? 'Unknown error'}`, {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [imeis, sendRequest]);

    return (
        <Row className='w-full gap-x-5'>
            <Column className="w-8/12">
                <PrimaryCard  className="p-4 ">
                    <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'>
                        Scan IMEI
                    </Text>
                    <div className="flex mb-16 mt-10 h-20 items-center justify-center">
                        <div className="items-center px-4 h-28 justify-center rounded bg-white relative border-radius-5 w-11/12 mx-auto my-auto">
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', left: '0', borderRadius: '5px 0 0 0', borderTop: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', right: '0', borderRadius: '0 5px 0 0', borderTop: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', right: '0', borderRadius: '0 0 5px 0', borderBottom: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', left: '0', borderRadius: '0 0 0 5px', borderBottom: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                            <Row className=" gap-x-3 mt-10 items-center justify-center">
                                <TextField
                                    value={imei}
                                    onChange={handleImeiChange}
                                    onKeyPress={handleKeyPress}
                                    placeholder="IMEI"
                                    width="20rem"
                                    autoFocus
                                />
                                <Button
                                    onClick={handleAddImei}
                                    className='w-56'
                                    backgroundColor="lgblue"
                                    disabled={isLoading}
                                >
                                    <Text colorV="white" variant="title" weightV="normal">
                                       Add IMEI
                                    </Text>
                                </Button>
                            </Row>
                        </div>
                    </div>
                </PrimaryCard>
            </Column>
            <Column className="w-1/2">
                <PrimaryCard  className='p-5 '>
                    <RowAlignCenterSpaced className='mb-7'>
                        <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'>
                            IMEI list
                        </Text>
                        <Column className='my-3 w-64'>
                            {imeis.map((imei) => (
                                <RowAlignCenterSpaced key={imei} className='gap-x-20 my-1'>
                                    <Text colorV="blue" variant="title" weightV="bold">
                                        {imei}
                                    </Text>
                                    <Icon
                                        className='hover:cursor-pointer'
                                        onClick={() => handleRemoveImei(imei)}
                                        name="cross" color="black" size={20} />
                                </RowAlignCenterSpaced>
                            ))}
                        </Column>
                    </RowAlignCenterSpaced>
                    {imeis.length > 0 &&
                        <Row className='justify-end my-4'>
                            <Button
                                onClick={handleComplete}
                                className='w-56'
                                backgroundColor="lgblue"
                                disabled={isLoading}
                            >
                                <Text colorV="white" variant="title" weightV="normal">
                                    {isLoading ? 'Loading...' : 'Polishing completed'}
                                </Text>
                            </Button>
                        </Row>
                    }

                </PrimaryCard>
            </Column>
        </Row>
    );
};
