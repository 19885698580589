import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../utils/auth';
import { Button } from '../../../base/Button';
import { ModalBox } from '../../../base/ModelBox';
import { TextBackground } from '../../../base/TextBackground';
import { TextArea } from '../../../base/Textarea';
import { Column } from '../../../layout/Col';
import { Flex } from '../../../layout/Flex';
import { Text } from '../../../base/Text';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';
import { TextField } from '../../../base/Textfield';
import { addNotification } from '../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl;

export const Next: React.FC<{ onClose: () => void; data: any }> = ({
  onClose,
  data
}) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [isFirstModalOpen, setFirstModalOpen] = useState(true);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState<any>();
  const auth = useAuth();
  const token = auth?.user?.token?.token;

  const handleTrackingNumber = (newValue: any) => {
    setTrackingNumber(newValue);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    if (!trackingNumber) {
      alert('Please, enter tracking number');
      return;
    }

    axios
      .put(
        `${baseUrl}/purchase/edit-purchase-tracking-number/${data?._id}`,
        {
          trackingNumber,
        },
        {
          headers: {
            Authorization: token as string,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setUpdatedData(response?.data?.savedRma);
        setTrackingNumber('');
        toast.success(response?.data?.message, { hideProgressBar: true });
        addNotification({
          message: 'Tracking number has been added successfully',
          type: 'success',
        });
        setFirstModalOpen(false);
        setSecondModalOpen(true);
      })
      .catch((error) =>
        toast.error(error?.response?.data?.message, {
          hideProgressBar: true,
        })
      );
  };

  return (
    <>
      {isFirstModalOpen && (
        <ModalBox
          heading="Finalise your RMA report"
          onClose={onClose}
          top="50%"
          height="auto"
          width="500px"
        >
          <Column className="mt-3 gap-y-4 h-[150px] overflow-auto">
            <RowAlignCenterSpaced>
              <Text colorV="blue" variant="body" weightV="bold">
                Tracking number
              </Text>
              <TextField
                value={trackingNumber}
                onChange={handleTrackingNumber}
                placeholder="Enter tracking number"
                
                width="18rem"
              />
            </RowAlignCenterSpaced>
            <Flex className="gap-x-4 mt-4">
              <Button onClick={handleSave} className="w-40" backgroundColor="lgblue">
                <Text colorV="white" variant="title" weightV="normal">
                  Submit report
                </Text>
              </Button>
            </Flex>
          </Column>
        </ModalBox>
      )}

      {isSecondModalOpen && updatedData && (
        <ModalBox
          heading="Thank you for submitting RMA report"
          onClose={() => {
            setSecondModalOpen(false);
            onClose(); // Close the first modal
          }}
          top="50%"
          height="auto"
          width="500px"
        >
          <Column className="mt-3 gap-y-4 h-[150px] overflow-auto text-center justify-center">
            {updatedData && (
              <>
                <Text colorV="blue" weightV="normal" variant="title">
                  Please mark <b>{updatedData.rmaNumber} </b>on the box and ship it to us.
                </Text>
                <Text colorV="blue" weightV="normal" variant="title">
                  We will process it as soon as possible!
                </Text>
                <div className="mt-4 justify-center">
                  <Button onClick={() => setSecondModalOpen(false)} className="w-40" backgroundColor="lgblue">
                    <Text colorV="white" variant="title" weightV="normal">
                      Ok
                    </Text>
                  </Button>
                </div>
              </>
            )}
          </Column>
        </ModalBox>
      )}
    </>
  );
};
