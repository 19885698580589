import React from 'react'
import { CustomerRmaItem } from '../components/customerPortal/CustomerRma/CustomerRmaItems/CustomerRmaItem'
import Header from '../components/shared/Header/Header'
import { Breadcrumb } from '../components/base/Breadcrumb'
import { Row } from '../components/layout/Row'
import { useBreadcrumb } from '../hooks/useBreadcrumb'

export const MyCustomerRmaItem = () => {
    const breadcrumbItems = useBreadcrumb()

    return (
        <>
            <Header />
            <div className="">
            <Row className="my-2 w-full">
                    <Breadcrumb items={breadcrumbItems} separator="/" />
                </Row>
                <CustomerRmaItem />
            </div>
        </>
    )
}
