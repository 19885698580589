import React, { useEffect } from 'react'
import { Flex } from '../../../layout/Flex'
import { Column } from '../../../layout/Col'
import { OrderInfoTable } from './component/OrderInfoTable'
import { RepairProposal } from './component/RepairProposal'
import { Head } from './Head'
import Header from '../../../shared/Header/Header'
import { useBreadcrumb } from '../../../../hooks/useBreadcrumb'
import { Breadcrumb } from '../../../base/Breadcrumb'
import { Row } from '../../../layout/Row'
import { useFetch } from '../../../../hooks/useFetch'
import { useParams } from 'react-router-dom'
import { RmaButtons } from './RmaButtons'
const baseUrl = process.env.REACT_APP_baseUrl

export const ItemsRma = () => {
    const breadcrumbItems = useBreadcrumb()
    const { id } = useParams()
    const { getRequest: getRmaItems, data } = useFetch(
        `${baseUrl}/rma/get-rma-detail/${id}`
    )
    console.log({data});
    useEffect(() => {
        getRmaItems()
    }, [getRmaItems])

    const refetchData = () => {
        getRmaItems()
    };
    return (
        <Flex className="mb-3">
            <Column className='w-full'>
                <Header />
                <Column className="ml-5">
                    <Row className="mb-3">
                        <Breadcrumb items={breadcrumbItems} separator="/" />
                    </Row>
                    <Head refetchData={refetchData} rmaItemsData={data} />
                    <RmaButtons refetchData={refetchData} rmaItemsData={data} />
                </Column>
            </Column>
        </Flex>
    )
}
