import React from 'react'
import Head from './Head'

export const Transit = () => {
  return (
    <div>
        <Head />
    </div>
  )
}
