import React, { ReactNode } from 'react'
import { Pagebg } from '../../components/compound/Page/Pagebg'
import { Column } from '../../components/layout/Col'
import { CustomerNavbar } from '../Navbars/CustomerNavbar'

interface CustomerLayoutProps {
    children: ReactNode
}

export const CustomerLayout: React.FC<CustomerLayoutProps> = ({ children }) => {
    const initialNavItems = [
        // { text: 'Home', icon: 'home', to: '/home', iconSize: 20 },
        {
            text: 'Purchase orders',
            icon: 'inventory',
            to: '/purchase',
            iconSize: 20,
        },
        {
            text: 'RMA',
            icon: 'rma',
            to: '/rma-customer',
            iconSize: 20,
        },
    ]
    const belowNavItems = [
        // { text: 'Slack', icon: 'slack', to: 'slack', iconSize: 20 },
        // {
        //     text: 'Phone check',
        //     icon: 'tick-mobile',
        //     to: 'phonecheck',
        //     iconSize: 18,
        // },
        // { text: 'GDrive', icon: 'g-drive', to: 'gdrive', iconSize: 20 },
        { text: 'Logout', icon: 'logout', to: '/logout', iconSize: 20 },
    ]
    return (
        <Pagebg className="bg-base-01 p-6 w-full hide-scrollbar">
            <div style={{ position: 'fixed', top: 20, left: 20, bottom: 20 }}>
                <Column className="gap-y-4">
                    <CustomerNavbar initialNavItems={initialNavItems} />
                    <CustomerNavbar
                        showLogo={false}
                        initialNavItems={belowNavItems}
                    />
                </Column>
            </div>
            <div style={{ marginLeft: 300, marginRight: 20 }}>
                {children}
            </div>
        </Pagebg>
    )
}
