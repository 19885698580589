import React from 'react';
import { SingleDatasetLineChart } from '../../../../../shared/Chart/SingleDatasetLineChart'; // Replace with the actual path to your LineChart component
import { Dropdown } from '../../../../../base/Dropdown';
import { Column } from '../../../../../layout/Col';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../../base/Text';
import { PrimaryCard } from '../../../../Page/PrimaryCard';

export const RmaPerformance = () => {

const dummyData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Sample Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            fill: false,
        },
    ],
};

const color:string="#FFFFF"
    return (
        <PrimaryCard className='bg-b9white p-5 w-full'>
            <RowAlignCenterSpaced className="mb-5 w-full">
                <Text colorV="blue" variant="heading" weightV="normal">
                    RMA performance %
                </Text>
                <Dropdown
                    style={{ border: 'none !important', backgroundColor: "transparent !important" }}
                    width='75px'
                    options={['Week', 'Day', 'Month']}
                    onChange={(selectedOption) => {
                        console.log(`Selected option: ${selectedOption}`);
                    }}
                />
            </RowAlignCenterSpaced>
            {/* <Column className='w-full'>
                <SingleDatasetLineChart data={dummyData} gradientColors={color}  />
            </Column> */}
        </PrimaryCard>
    )
};

