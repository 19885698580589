import React, { useEffect, useState } from 'react'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced/RowAlignCenterSpaced'
import { Button } from '../../base/Button'
import { ModalBox } from '../../base/ModelBox'
import { TextBackground } from '../../base/TextBackground'
import { TextField } from '../../base/Textfield'
import { Column } from '../../layout/Col'
import { Flex } from '../../layout/Flex'
import { ColorProps, Text } from '../../base/Text'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../utils/auth'
import moment from 'moment'
import { EventNameEnum } from '../../../constant/SortingLogicEvent'
import { ItemSearchModal } from '../../shared/SearchModal/ItemSearchModal'
import { usePost } from '../../../hooks/usePost'
const baseUrl = process.env.REACT_APP_baseUrl;

export const EventImeiHistory: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [eventBaseHistory, setEventBaseHistory] = useState<any>()
    const auth = useAuth();
    const token = auth?.user?.token?.token;
    const { sendRequest } = usePost()
    useEffect(() => {
        const fetchData = async () => {
            if (item) {
                const urlInventoryItem = `${baseUrl}/history/event-item-history/${item.Id}`;
                try {
                    const response = await sendRequest(
                        urlInventoryItem,
                        {},
                    );
                    setEventBaseHistory(response.data);
                } catch (error) {
                    console.log(error);
                    toast.error(`Error: ${error?.response?.data}`, {
                        hideProgressBar: true,
                    });
                }
            }
        };
        fetchData();
    }, [item, token, onClose, sendRequest]);
    const getColorBasedOnEventType = (eventType: string): string => {
        if (eventType === EventNameEnum.InHouse || EventNameEnum.RMA_Accepted || EventNameEnum.My_RMA || EventNameEnum.Stocktaking || EventNameEnum.Back_To_In_house || EventNameEnum.Set_On_Sale || EventNameEnum.Repair_Completed || EventNameEnum.Repair_Cancelled || EventNameEnum.Diagnostics_Completed || EventNameEnum.Diagnostics_Received || EventNameEnum.Polishing_Completed || EventNameEnum.Polishing_Received) {
            return "green";
        } else if (eventType === EventNameEnum.Sold || EventNameEnum.Customer_RMA_Claimed || EventNameEnum.RMA_Declined || EventNameEnum.My_RMA_Sent_To_Supplier) {
            return "yellow";
        } else if (eventType === EventNameEnum.Missing) {
            return 'red';
        }
    };

    const eventTypeColor = getColorBasedOnEventType(eventBaseHistory?.event?.type);
    return (
        <ItemSearchModal heading={eventBaseHistory?.imei} width='500px' height='650px' onClose={onClose}>
            <Column className="mt-3 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Stauts
                    </Text>
                    <TextBackground className='w-72  h-10 text-left' backgroundColor="b9white">
                        <Text
                            colorV={eventTypeColor as ColorProps}
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.event?.name}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">

                        Stauts
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.event?.type}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        IMEI/Serial number
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.itemId?.imei}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">

                        Timestamp
                    </Text>
                    <TextBackground className='w-72  text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                           {moment(eventBaseHistory?.createdAt).format('DD.MM.YYYY HH:mm')}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Supplier
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.itemId?.supplier?.companyName}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Invoice number
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.itemId?.invoiceNumber}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Purchase price
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.itemId?.purchasePrice}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        VAT
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.itemId?.vat}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        RMA days
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        <Text
                            colorV="blue"
                            variant="title"
                            weightV="normal"
                        >
                            {eventBaseHistory?.itemId?.purchaseId?.assignMyRmaDuration}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        SKU
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        {
                            (eventBaseHistory?.event?.name === EventNameEnum.Sold ||
                                eventBaseHistory?.event?.name === EventNameEnum.Customer_RMA_Claimed ||
                                eventBaseHistory?.event?.name === EventNameEnum.RMA_Accepted ||
                                eventBaseHistory?.event?.name === EventNameEnum.RMA_Declined ||
                                eventBaseHistory?.event?.name === EventNameEnum.Set_On_Sale) ?
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="normal"
                                >
                                    {eventBaseHistory?.itemId?.sku?.sku}
                                </Text>
                                :
                                '---'

                        }
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Sale price
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        {(eventBaseHistory?.event?.name === EventNameEnum.Sold ||
                            eventBaseHistory?.event?.name === EventNameEnum.Customer_RMA_Claimed ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Accepted ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Declined) ?
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {eventBaseHistory?.itemId?.salePrice}
                            </Text>
                            : '---'}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Sale order
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        {(eventBaseHistory?.event?.name === EventNameEnum.Sold ||
                            eventBaseHistory?.event?.name === EventNameEnum.Customer_RMA_Claimed ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Accepted ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Declined) ?
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {eventBaseHistory?.itemId?.purchaseId?.saleBatchNumber}

                            </Text> : "---"}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Customer
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        {(eventBaseHistory?.event?.name === EventNameEnum.Sold ||
                            eventBaseHistory?.event?.name === EventNameEnum.Customer_RMA_Claimed ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Accepted ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Declined) ?
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {eventBaseHistory?.itemId?.purchaseId?.customer?.companyName || eventBaseHistory?.itemId?.soldTo?.companyName}

                            </Text> : "---"}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Purchase order N#
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        {(eventBaseHistory?.event?.name === EventNameEnum.Sold ||
                            eventBaseHistory?.event?.name === EventNameEnum.Customer_RMA_Claimed ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Accepted ||
                            eventBaseHistory?.event?.name === EventNameEnum.RMA_Declined) ?
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {eventBaseHistory?.itemId?.purchaseId?.saleBatchNumber}
                            </Text> : '---'}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        RMA
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        {
                            (
                                eventBaseHistory?.event?.name === EventNameEnum.Customer_RMA_Claimed ||
                                eventBaseHistory?.event?.name === EventNameEnum.RMA_Accepted ||
                                eventBaseHistory?.event?.name === EventNameEnum.RMA_Declined) ?
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="normal"
                                >
                                    {eventBaseHistory?.itemId?.rma?.rmaNumber}
                                </Text> : "---"}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        My RMA batch #
                    </Text>
                    <TextBackground className='w-72 text-left' backgroundColor="b9white">
                        {eventBaseHistory?.event?.name === EventNameEnum.My_RMA_Sent_To_Supplier ?
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                {item?.["Available pieces"]}
                            </Text> : "---"}
                    </TextBackground>
                </RowAlignCenterSpaced>
            </Column>
        </ItemSearchModal>

    )
}
