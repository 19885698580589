import React from 'react';
import loading from '../../../assets/loading.gif';

export const Loader = () => {
    return (
        <div
            className='w-full mt-32 h-full flex items-center justify-center'
        >
            <img src={loading} alt='Loading...' />
        </div>
    );
};
