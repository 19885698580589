import React, { useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { Text } from '../../../../base/Text'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'

export default function Head() {
    return (
        <Flex className="py-5  w-full">
            <RowAlignCenterSpaced className="gap-x-5  w-full">
                <Text colorV="blue" variant="head" weightV="normal">
                    Warranty Repair
                </Text>
            </RowAlignCenterSpaced>
        </Flex>
    )
}
