import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../../base/Text'
import { TextBackground } from '../../../../../base/TextBackground'
import { useEdit } from '../../../../../../hooks/useEdit'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { generatePDF } from '../../../../../shared/GenerateLabel'
import { useFetch } from '../../../../../../hooks/useFetch'
import JsBarcode from 'jsbarcode'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}
export const ProposedRepairModal: React.FC<{ onClose: () => void; item: any; data: any }> = ({ onClose, item, data }) => {
    const [acceptance, setAcceptance] = useState<any>()
    const [itemData, setItemData] = useState<any>()
    const [isSave, setIsSave] = useState(false)
    const [isSavePrint, setIsSavePrint] = useState(false)

    const barCode = useRef<HTMLDivElement>(null);
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendEditRequest } = useEdit()

    useEffect(() => {
        const itemData = data?.items?.find(myItem => myItem._id === item?.id);
        setItemData(itemData)
    }, [data?.items, item?.id])


    useEffect(() => {
        if (itemData) {
            setAcceptance(itemData?.acceptance)
        }
    }, [itemData])

    const handleAcceptanceChange = (event: any) => {
        setAcceptance(event.target.value)
    }

    const handleSave = async (e: any) => {
        setIsSave(true)
        await sendEditRequest(
            `${baseUrl}/rma/update-rma-item-status/${itemData._id}`,
            {
                acceptance,
            },
        )
            .then((response) => {
                toast.success(`Status added`, { hideProgressBar: true })
                addNotification({
                    message: 'Status has been updated successfully',
                    type: 'success',
                })
                setAcceptance("")
                onClose()
                setIsSave(false)
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);
    const handleSavePrint = async (e: any) => {
        setIsSavePrint(true)
        await sendEditRequest(
            `${baseUrl}/rma/update-rma-item-status/${itemData._id}`,
            {
                acceptance,
            },
        )
            .then((response) => {
                console.log({response});
                const item = response?.data
                JsBarcode('#barcode', `${response?.data?.item?.imei}`, {
                    lineColor: 'black',
                    textAlign: 'center',
                    width: 1,
                    background: 'transparent',
                    height: 40,
                    displayValue: false,
                    xmlDocument: document,
                });
                toast.success(`Status added`, { hideProgressBar: true })
                addNotification({
                    message: 'Status has been updated successfully',
                    type: 'success',
                })
                setIsSavePrint(false)
                setAcceptance("")
                onClose()
                generatePDF(item, labelsData, barCode)
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }

    return (
        <ModalBox
            heading={item?.IMEI}
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[460px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal'  variant='title'>
                            {item?.Model}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Storage
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal'  variant='title'>
                            {item?.Storage}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        VAT
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal'  variant='title'>
                            {item?.VAT}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Sale price
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal'  variant='title'>
                            {itemData?.salePrice}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Reason of return
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal'  variant='title'>
                            {itemData?.reasonOfReturn}
                        </Text>
                    </TextBackground>

                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Price Proposal
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        <Text colorV='blue' weightV='normal'  variant='title'>
                            {itemData?.priceProposal}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status
                    </Text>
                    <div style={{ width: '18rem' }}>
                        <select
                            id="acceptance"
                            value={acceptance}
                            onChange={handleAcceptanceChange}
                            className="w-full hover:cursor-pointer  py-2 px-1"                        >
                            <option value="">Status</option>
                            <option value="rma_accepted">Accepted</option>
                            <option value="rma_declined">Declined</option>
                            <option value="warranty_repair">Warranty repair</option>
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isSave ? "Saving" : "Save"}
                        </Text>
                    </Button>
                    <Button onClick={handleSavePrint} className='w-36' backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isSavePrint ?  "Saving" : "Save & Print"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </ModalBox>
    )
}
