import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../../utils/auth'
import { Avatar } from '../../base/Avatar'
import { MyIcon } from '../../base/MyIcon'
import { Text } from '../../base/Text'
import { Row } from '../../layout/Row'
import { RowAlignCenter } from '../../layout/RowAlignCenter'
import { ProfileDialog } from './ProfileDialog'
import axios from 'axios'
import { NotificationDialog } from '../Notification/NotificationDialog'
import { addNotification, Notification_data } from '../Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl;

export const Profile = () => {
    const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);
    const [isNotificationOpen, setNotificationOpen] = useState(false);
    const [profilePic, setProfilePic] = useState();
    const auth = useAuth();
    const currentUser = auth?.user?.user;
    const company = auth.selectedCompany;

    const handleAvatarClick = () => {
        setProfileDialogOpen(true);
    };

    const closeProfileDialog = () => {
        setProfileDialogOpen(false);
    };

    const handleEditClick = () => {
        const notification: Notification_data = {
            message: 'You clicked on the edit button',
            type: 'info',
        }
        addNotification(notification);
    };

    const closeNotificationDialog = () => {
        setNotificationOpen(false);
    };

    const getAccount = useCallback(async () => {
        const token = auth.user?.token?.token;
        await axios
            .get(`${baseUrl}/user/get-user/${auth.user?.user?._id}`, {
                headers: {
                    Authorization: token,
                    Company: company?._id,
                },
            })
            .then((data) => {
                console.log({ data });
                setProfilePic(data?.data?.profilePic);
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.message, {
                //     hideProgressBar: true,
                // })
            });
    }, [auth?.user?.token?.token, auth?.user?.user?._id, company?._id]);

    useEffect(() => {
        getAccount();
    }, [getAccount]);
    
    return (
        <RowAlignCenter className="gap-x-7 relative">
            <Row className="">
                <div
                    style={{
                        cursor: 'pointer',
                        marginRight: '15px',
                    }}
                    onClick={handleEditClick}
                >
                    <MyIcon name="mail" size={20} />
                </div>
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => setNotificationOpen(!isNotificationOpen)}
                >
                    <MyIcon name="bell" />
                </div>
            </Row>
            <h1 className=' text-nowrap text-[#112D56] font-extrabold text-xl'>{currentUser?.lastName} {currentUser?.firstName}</h1>
            <Avatar
                onClick={handleAvatarClick}
                size={40}
                src={profilePic}
                name={currentUser?.firstName + " " + currentUser?.lastName}
            />
            {isProfileDialogOpen && (
                <ProfileDialog refetchAccount={getAccount} profilePic={profilePic} onClose={closeProfileDialog} currentUser={currentUser} />
            )}
            {isNotificationOpen && (
                <NotificationDialog onClose={closeNotificationDialog}/>
            )}
        </RowAlignCenter>
    );
};