import Header from '../components/Headers'
import SignupForm from '../components/SignupForm'
import TopHeader from '../components/TopHeader'

export default function CreateUser() {
    return (
        <>
            <TopHeader />
            <div className=" mt-5 flex items-center flex-col">
                <div className="">
                    <Header
                        heading="Create User"
                        paragraph=" "
                        linkName=""
                        linkUrl="/"
                    />
                    <SignupForm />
                </div>
            </div>
        </>
    )
}
