// CustomCard.jsx
import React from 'react';
import { Icon } from '../../../../../base/Icon';
import { Flex } from '../../../../../layout/Flex';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Text } from '../../../../../base/Text';
import { MyIcon } from '../../../../../base/MyIcon';

export const ValueCard = ({ title, currencyIcon, count, change, comparedText, percentage }: any) => (
    <PrimaryCard className='p-5 w-full bg-b9white'>
        <RowAlignCenterSpaced className='w-full'>
            <Text colorV="blue" variant="title" weightV="normal">
                {title}
            </Text>
            <Icon name="dots" size={20} />
        </RowAlignCenterSpaced>
        <RowAlignCenterSpaced className="mt-5 gap-x-4">
            <MyIcon name={currencyIcon} size={25} />
            <Text colorV="blue" variant="heading" weightV="normal">
                {count}
            </Text>
            <Text colorV="green" variant="body" weightV="bold">
                {change}
            </Text>
            <Flex className="items-center gap-x-2">
                <MyIcon name="greenArrowUp" size={8} />
                <Text colorV='green' variant="body" weightV="bold">
                    {percentage}
                </Text>
            </Flex>
        </RowAlignCenterSpaced>
        <Flex className="mt-5">
            <Text colorV="blue" variant="label" weightV="normal">
                {comparedText}
            </Text>
        </Flex>
    </PrimaryCard>
);
