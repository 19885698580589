import React from 'react';

interface DropdownProps {
  label?: string;
  options: string[];
  defaultValue?: string; // Optional default selected value
  onChange: (selectedOption: string) => void;
  style?: React.CSSProperties; // Allow custom styling
  width?: string;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  defaultValue,
  onChange,
  style,
  width,
  className,
}) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    onChange(selectedOption);
  };

  const dropdownStyle: React.CSSProperties = {
    backgroundColor: '#F2F0ED', // Set to 'transparent' for a transparent background
    color: '#112D56',
       fontFamily: 'Inter Tight, sans-serif', // Add font family here
        fontSize: '16px',
    ...style,
  };

  const selectStyle: React.CSSProperties = {
    height: '36px',
    width: width,
  };

  return (
    <div className={className} style={dropdownStyle}>
      <label>{label}</label>
      <select value={defaultValue || ''} onChange={handleOptionChange} style={selectStyle} className='bg-transparent border-none '>
        {!defaultValue && <option value="">Select</option>}
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
