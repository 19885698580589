import React, { useState } from 'react';
import { Flex } from '../../../../../layout/Flex';
import { Text } from '../../../../../base/Text';
import { Button } from '../../../../../base/Button';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { RowAlignCenter } from '../../../../../layout/RowAlignCenter';
import { AddSparepartSKU } from './AddSparepartSKU';
interface HeadButtonsProps {
  handleSparepartSku: () => void; // Specify the correct type for your function
}

export const HeadButtons: React.FC<HeadButtonsProps> = ({ handleSparepartSku }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSparepartSKU = () => {
    setIsModalOpen(true); 
  };


  const onClose = () => {
    setIsModalOpen(false);
    handleSparepartSku()
  };

  return (
    <Flex className="py-2 w-full">
      <RowAlignCenterSpaced className="gap-x-5 w-full">
        <Text colorV="blue" variant="head" weightV="bold">
          Manage spare parts SKU
        </Text>
          <Button onClick={handleSparepartSKU} backgroundColor="lgblue">
            <Text colorV="white" variant="title" weightV="normal">
              Add Spare part SKU
            </Text>
          </Button>
      </RowAlignCenterSpaced>

      {isModalOpen && <AddSparepartSKU onClose={onClose} />}
    </Flex>
  );
}
