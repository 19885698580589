import { useLocation } from 'react-router-dom'

export function useBreadcrumb() {
    const location = useLocation()
    const pathname = location.pathname

    // Function to capitalize the first letter of a string
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    // Generate breadcrumb items based on the current pathname
    const generateBreadcrumbItems = () => {
        return pathname
            .split('/')
            .filter((segment) => segment !== '')
            .map((segment, index, segments) => ({
                label: capitalizeFirstLetter(segment), // Capitalize the first letter
                url: `/${segments.slice(0, index + 1).join('/')}`, // Construct the URL dynamically
            }))
    }

    return generateBreadcrumbItems()
}
