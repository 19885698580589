import React, { useEffect, useState } from 'react'
import { Head } from './Head'
import { CustomerPurchaseTable } from './CustomerPurchaseTable';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';

const baseUrl = process.env.REACT_APP_baseUrl;

export const CustomerPurchase = () => {
    const [extractedData, setExtractedData] = useState([]);

    const { getRequest: getPartners, data: purchaseData } = useFetch(
        `${baseUrl}/purchase/customer-purchase-orders`
    );
    console.log({purchaseData});
    useEffect(() => {
        getPartners();
    }, [getPartners]);

    useEffect(() => {
        if (purchaseData) {
            const updatedData = purchaseData.map((item) => {
                let startDate = moment(item?.createdAt);
                const endDate = moment(item?.createdAt).add(1, 'days').add(item?.assignMyRmaDuration, 'days');
                if (startDate && startDate.day() === 5) { 
                    startDate = startDate.add(2, 'days'); 
                  } else if (startDate && startDate.day() === 6) { 
                    startDate = startDate.add(1, 'day'); 
                  }
                const remainingDays = endDate.diff(moment(), 'days');

                const remainingDaysString =
                    remainingDays > 0
                        ? `${remainingDays} day${remainingDays !== 1 ? 's' : ''} left`
                        : remainingDays === 0
                            ? 'Today is the last day'
                            : 'The deadline has passed';

                return {
                    Id: item?._id,
                    PON: item?.saleBatchNumber,
                    Customer: item?.customer?.companyName,
                    'Logistic Company': item?.logisticCompany.companyName,
                    'Date the order is sent': moment(item?.createdAt).format('DD.MM.YYYY'),
                    RMA: remainingDaysString,
                    'Total value': item?.totalValue,
                    'Type of sale': item?.saleType,
                    status: item?.rmaStatus
                };
            });
            setExtractedData(updatedData);
        }
    }, [purchaseData]);


    return (
        <>
            <Head data={extractedData} />
            <CustomerPurchaseTable data={extractedData} fullData={purchaseData} />
        </>
    )
}
