import React, { ReactNode } from 'react'
import { Navbar } from '../Navbars/Navbar'
import { Pagebg } from '../../components/compound/Page/Pagebg'
import { Column } from '../../components/layout/Col'
import { Flex } from '../../components/layout/Flex'
import { Row } from '../../components/layout/Row'
import { SubNavbar } from '../Navbars/SubNavbar'
import { BelowNavbar } from '../Navbars/BelowNavbar'
import Logo2 from '../../assets/logo2.png'

interface RmaLayoutProps {
    children: ReactNode
}

export const CustomerRmaLayout: React.FC<RmaLayoutProps> = ({ children }) => {
    const initialNavItems = [
        // { text: 'Home', icon: 'home', to: '/home', iconSize: 20 },
        {
            text: 'Purchase',
            icon: 'inventory',
            to: '/purchase',
            iconSize: 20,
        },
        { text: 'RMA', icon: 'rma', to: '/rma-customer' },
    ]
    const subNavItems = [
        { text: 'My RMA', to: '/rma-customer' },
        {
            text: 'Credit notes',
            to: '/rma-customer/credit-notes-customer',
        },
    ]

    const belowNavItems = [
        // { text: 'Slack', icon: 'slack', to: '/slack', iconSize: 20 },
        // {
        //     text: 'Phone check',
        //     icon: 'tick-mobile',
        //     to: '/phonecheck',
        //     iconSize: 18,
        // },
        // { text: 'GDrive', icon: 'g-drive', to: '/gdrive', iconSize: 20 },
        { text: 'Logout', icon: 'logout', to: '/logout', iconSize: 20 },
    ]
    return (
        <Pagebg className="bg-base01 flex-row flex">
                <Column className="gap-y-1 p-5">
                    <div className="logo">
                        <img alt="" className="h-14 w-28" src={Logo2} />
                    </div>
                    <Row className="gap-x-2">
                        <Navbar initialNavItems={initialNavItems} />
                        <SubNavbar
                            heading="Production Environment"
                            initialNavItems={subNavItems}
                        />
                    </Row>
                    <BelowNavbar
                        className="mt-3"
                        initialNavItems={belowNavItems}
                    />
                </Column>
            <div className='flex-1 p-5'>
                {children}
            </div>
        </Pagebg>
    )
}
