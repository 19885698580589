import { useCallback, useState } from 'react';
import { Button } from '../../../../../base/Button';
import { Text } from '../../../../../base/Text';
import { TextField } from '../../../../../base/Textfield';
import { Column } from '../../../../../layout/Col';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { useAuth } from '../../../../../../utils/auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import { usePost } from '../../../../../../hooks/usePost';
import { Icon } from '../../../../../base/Icon';
import { Row } from '../../../../../layout/Row';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { addNotification } from '../../../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl;

export const ReceiveDiagnostics = () => {
    const [imei, setImei] = useState<any>('');
    const [imeis, setImeis] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth();
    const token = auth?.user?.token?.token;

    const { sendRequest } = usePost()
    const handleImeiChange = useCallback((newValue: any) => {
        setImei(newValue);
    }, []);

    const handleAddImei = useCallback(() => {
        if (imei.trim() !== '') {
            setImeis([...imeis, imei]);
            setImei('');
        }
    }, [imei, imeis]);

    const handleRemoveImei = (imeiToRemove) => {
        const updatedImeis = imeis.filter((imei) => imei !== imeiToRemove);
        setImeis(updatedImeis);
    };

    const handleReceiveDiagnostic = useCallback(async (e: any) => {
        e.preventDefault();
        if (!imeis) {
            toast.error('Please, add code label');
            return;
        }
        setIsLoading(true);
        const urlItem = `${baseUrl}/diagnostics/receive-diagnostic`;
        try {
            const response = await sendRequest(
                urlItem,
                {
                    imeis,
                },
            );

            const { data } = response;

            if (data.errors && data.errors.length > 0) {
                data.errors.forEach((error) => {
                    toast.error(`Error for IMEI ${error.imei}: ${error.message}`, {
                        hideProgressBar: true,
                    });
                });
            }

            if (data.successes && data.successes.length > 0) {
                data.successes.forEach((success) => {
                    toast.success(`Success for IMEI ${success.imei}: ${success.message}`, {
                        hideProgressBar: true,
                    });
                    addNotification({
                        message: `Diagnostics received for IMEI ${success.imei}`,
                        type: 'success',
                    });
                });
            }
            setImei('');
        } catch (error) {
            toast.error(`Error: ${error.response.data?.message}`, {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false); // Set loading to false after the request is completed
            setImeis([])
        }
    }, [imeis, sendRequest]);

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddImei();
        }
    };

    return (
        <Row className='w-full gap-x-5'>
            <Column className="w-1/2">
                <PrimaryCard  className='p-4 '>
                    <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'> Scan IMEI</Text>
                    <div className='flex mt-10 mb-16 h-20 items-center justify-center'>
                        <div className="items-center h-28 px-4 justify-center rounded bg-white relative border-radius-5 w-11/12 mx-auto my-auto">
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', left: '0', borderRadius: '5px 0 0 0', borderTop: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', top: '0', right: '0', borderRadius: '0 5px 0 0', borderTop: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', right: '0', borderRadius: '0 0 5px 0', borderBottom: '2px solid #000000', borderRight: '2px solid #000000' }}></div>
                            <div className="corner" style={{ background: 'none', height: '10px', width: '10px', position: 'absolute', bottom: '0', left: '0', borderRadius: '0 0 0 5px', borderBottom: '2px solid #000000', borderLeft: '2px solid #000000' }}></div>
                            <Row className=" mt-10 gap-x-3 items-center justify-center">
                                <TextField
                                    value={imei}
                                    onChange={handleImeiChange}
                                    onKeyPress={handleEnterKeyPress}
                                    placeholder="IMEI"
                                    width="20rem"
                                    autoFocus
                                />
                                <Button
                                    onClick={handleAddImei}
                                    className='w-56'
                                    backgroundColor="lgblue"
                                    disabled={isLoading} // Disable the button when loading
                                >
                                    <Text colorV="white" variant="title" weightV="normal">
                                        Add IMEI
                                    </Text>
                                </Button>
                            </Row>
                        </div>
                    </div>

                </PrimaryCard>
            </Column>
            <Column className="w-1/2">
                <PrimaryCard  className='p-5 '>
                    <RowAlignCenterSpaced className='mb-7'>
                        <Text style={{
                         fontFamily: 'Archivo Black, sans-serif'
                    }}
                    weightV='normal' colorV='blue' variant='heading'>
                            IMEI list
                        </Text>
                        <Column className='my-3 w-64'>
                            {imeis.map((imei) => (
                                <RowAlignCenterSpaced key={imei} className='gap-x-20 my-1'>
                                    <Text colorV="blue" variant="title" weightV="bold">
                                        {imei}
                                    </Text>
                                    <Icon
                                        className='hover:cursor-pointer'
                                        onClick={() => handleRemoveImei(imei)}
                                        name="cross" color="black" size={20} />
                                </RowAlignCenterSpaced>
                            ))}
                        </Column>
                    </RowAlignCenterSpaced>
                    {imeis?.length > 0 &&
                        <Row className='justify-end my-4'>
                            <Button
                                onClick={handleReceiveDiagnostic}
                                className='w-72'
                                backgroundColor="lgblue"
                                disabled={isLoading} // Disable the button when loading
                            >
                                <Text colorV="white" variant="title" weightV="normal">
                                    {isLoading ? 'Loading...' : 'Received in diagnostics'}
                                </Text>
                            </Button>
                        </Row>
                    }
                </PrimaryCard>
            </Column>
        </Row>
    );
};
