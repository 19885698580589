import axios from 'axios';
import { useCallback, useState } from 'react';
import { useAuth } from '../utils/auth';

export const useEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [error, setError] = useState<any>('');
    const auth = useAuth();
    const token = auth?.user?.token?.token;
    const company = auth.selectedCompany;
    const sendEditRequest = useCallback(async (url: string, editData: any) => {
        setLoading(true);
        return await axios.put(
            url,
            editData,
            {
                headers: {
                    Authorization: token as string,
                    Company: company?._id,
                },
            }
        );
    }, [company?._id, token]);

    return { loading, data, error, sendEditRequest };
};
