import React, { useEffect } from 'react'
import { Head } from './Head'
import { useFetch } from '../../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_baseUrl;

export const CustomerRmaItem = () => {
    const { id } = useParams()
    const { getRequest: getRmaData, data: rmaData } = useFetch(
        `${baseUrl}/rma/get-rma-items/${id}`
    );

    useEffect(() => {
        getRmaData();
    }, [getRmaData]);
    
    return (
        <>
            <Head data={rmaData} />
        </>
    )
}
