import React, { CSSProperties, useCallback, useState } from 'react'
import { DropdownButtons } from '../../base/DropdownButton/DropdownButtons'
import { Text } from '../../base/Text'
import { Button } from '../../base/Button'
import { MyIcon } from '../../base/MyIcon'
import { RowAlignCenter } from '../../layout/RowAlignCenter'

interface TableProps {
    columns: string[]
    data: any[]
    widths?: number[] | string[]
    showActions: boolean
    options?: string[]
    purchaseOptions?: string[]
    handleDropdownChange?: (selectedItem: string, item: any) => void
    className?: string
    backgroundColor?: string
    useOpacityColor?: boolean
    handleRowClick?: (item: any) => void
    clickable?: boolean
    showStatus?: boolean
    isStatusRma?: boolean
    rmaStatus?: string[]
    showCheckboxes?: boolean
    selectedRows?: any[]
    onCheckboxChange?: (item: any, isChecked?: boolean) => void
    handleRmaClick?: (item: any) => void
    showSupplierStatus?: boolean
    whiteSpace?: "pre" | 'pre-wrap'
    showAll?: boolean,
    onAllCheckboxChange?: (isChecked?: any, items?: any) => void,
    maxWidth?: string
    allSelected?: boolean
    showUserCheckboxes?:boolean
}

export const Table: React.FC<TableProps> = ({
    columns,
    data,
    showActions,
    options,
    purchaseOptions,
    widths,
    handleDropdownChange,
    className,
    backgroundColor,
    useOpacityColor,
    handleRowClick,
    clickable,
    showStatus,
    isStatusRma = false,
    rmaStatus,
    showCheckboxes = false,
    onCheckboxChange,
    handleRmaClick,
    showSupplierStatus,
    whiteSpace,
    showAll,
    onAllCheckboxChange,
    maxWidth = "200px",
    allSelected,
    selectedRows,
    showUserCheckboxes = false
}) => {
    const filteredColumns = columns.filter((column) => column !== 'ID')

    const getStatusBackgroundColor = (status) => {
        const colorMappings = {
            'in_review': 'bg-b9green rounded p-1',
            'pending RMA units': 'bg-b9yellow rounded p-1',
            'inProgress': 'bg-b9yellow rounded p-1',
            'completed': 'bg-b9midblue rounded p-1',
            'sending': 'bg-b9blue rounded p-1',
            'sent': 'bg-purple rounded p-1',
            'review': 'bg-b9green rounded p-1',
            'credited': 'bg-b9blue rounded p-1',
            'rma_accepted': 'bg-b9green rounded p-1',
            'rma_declined': 'bg-b9blue rounded p-1',
            'active': 'bg-b9green rounded p-1',
            'inactive': 'bg-purple rounded p-1',
            'Claim RMA': 'bg-b9midblue rounded p-1',
            'RMA claimed': 'bg-b9green rounded p-1',
            'Received': 'bg-b9green rounded p-1',
            'Used': 'bg-b9green rounded p-1',
            'Open': 'bg-b9yellow rounded p-1',
            'warranty_repair': 'bg-b9yellow rounded p-1',
        };

        // Default style if status not found in the mappings
        const defaultStyle = 'bg-b9blue rounded ';

        return colorMappings[status] || defaultStyle;
    };


    const getWidth = useCallback(
        (index: number) => {
            if (widths?.length === index + 1) {
                if (widths[index]) {
                    return widths[index]
                } else {
                    return 'auto'
                }
            } else {
                return 'auto'
            }
        },
        [widths]
    )

    const transformEventColumn = (value) => {
        switch (value) {
            case 'in_house':
                return { text: 'Inhouse', color: '#45948A' };
            case 'rma_accepted':
                return { text: 'RMA accepted', color: '#45948A' };
            case 'My_RMA':
                return { text: 'My RMA', color: '#45948A' };
            case 'stocktaking':
                return { text: 'Stocktaking', color: '#45948A' };
            case 'back_to_in_house':
                return { text: 'Back to inhouse', color: '#45948A' };
            case 'set_on_sale':
                return { text: 'Set on sale', color: '#45948A' };
            case 'repair_completed':
                return { text: 'Repair completed', color: '#45948A' };
            case 'repair_cancelled':
                return { text: 'Repair cancelled', color: '#45948A' };
            case 'diagnostics_received':
                return { text: 'Diagnostics received', color: '#45948A' };
            case 'diagnostics_completed':
                return { text: 'Diagnostics completed', color: '#45948A' };
            case 'polishing_received':
                return { text: 'Polishing received', color: '#45948A' };
            case 'polishing_completed':
                return { text: 'Polishing completed', color: '#45948A' };
            case 'missing':
                return { text: 'Missing', color: '#800000' };
            case 'sold':
                return { text: "Sold", color: '#E8C877' }
            case 'customer_rma_claimed':
                return { text: "Customer RMA claimed", color: '#E8C877' }
            case 'rma_declined':
                return { text: "RMA declined", color: '#E8C877' }
            case 'my_rma_sent_to_supplier':
                return { text: "My rma sent to supplier", color: '#E8C877' }
            case 'warranty_repair':
                return { text: "Warranty repair", color: '#E8C877' }
            default:
                return { text: value, color: '#45948A' }; // Default case, no transformation
        }
    };
    const transformRmaColumn = (value) => {
        return { text: value, color: '#3B65F6' };
    };
    const handleChange = (event: any, item: any) => {
        if (onCheckboxChange) {
            onCheckboxChange(event, item)
        }
    };

    const handleAllChange = (event: any) => {
        if (onAllCheckboxChange) {
            onAllCheckboxChange(event, data);
        }
    };
    return (
        <div className=" rounded-lg w-full text-left">
            <table
                className={`min-w-full w-full table-auto text-b9blue bg-transparent ${className}`}
            >
                <thead
               style={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
               // Corrected to camelCase and added fallback fonts
              }}
                >
                    <tr>
                    {showCheckboxes && showAll && data && (
                            <th className={`align-middle px-2  `}>
                                <input
                                    type="checkbox"
                                    checked={allSelected}
                                    onChange={handleAllChange}
                                />
                            </th>
                        )}
                          {showUserCheckboxes && data && (
                            <th className={`align-middle px-2  `}>
                               
                            </th>
                        )}

                        {columns?.map((column, index) => (
                            <th
                                key={index}
                                className="px-1 py-2 text-left"
                                style={{
                                    width: getWidth(index),
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    fontFamily: 'Inter Tight, sans-serif'
                                }}
                            >
                                {column}
                            </th>
                        ))}
                        {showSupplierStatus && (
                            <th
                            style={{
                                fontSize: '16px',
                                fontWeight: 600,
                                fontFamily: 'Inter Tight, sans-serif'
                            }}
                             className="px-1 py-2  text-center">Status</th>
                        )}
                        {showActions && (
                            <th
                            style={{
                                fontSize: '16px',
                                fontWeight: 600,
                                fontFamily: 'Inter Tight, sans-serif'
                            }}
                            className="px-1 py-2 text-center">Actions</th>
                        )}
                        {showStatus && (
                            <th
                            style={{
                                fontSize: '16px',
                                fontWeight: 600,
                                fontFamily: 'Inter Tight, sans-serif'
                            }}
                            className="px-1 py-2  text-center">Status</th>
                        )}

                        {isStatusRma && (
                            <th
                            style={{
                                fontSize: '16px',
                                fontWeight: 600,
                                fontFamily: 'Inter Tight, sans-serif'
                            }}
                            className="px-1 py-2 text-center">Status</th>

                        )}
                    </tr>
                </thead>
                <tbody>
                    {/* {data?.length > 0 && showAll && (
                        <td
                            onClick={(e) => e.stopPropagation()}
                            className="px-1 py-2 text-center "
                        >
                            <div className=''>
                                <input
                                    type="checkbox"
                                    checked={allSelected}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        onAllCheckboxChange(e, data)
                                    }}
                                />
                                <span> All</span>
                            </div>
                        </td>
                    )} */}
                    {data?.map((item, rowIndex) => (
                        <tr
                       
                            key={rowIndex}
                            onClick={() =>
                                handleRowClick && handleRowClick(item)
                            }
                        >
                            {showCheckboxes && (
                                    <td className={`align-middle px-2  ${rowIndex % 2 === 0
                                        ? 'bg-b9grey1 bg-opacity-30'
                                        : useOpacityColor
                                        }`}>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows?.includes(item.id)}
                                            onChange={(event) => handleChange(event, item)}
                                        />
                                    </td>
                                )}
                                {showUserCheckboxes && (
                                <td
                                    onClick={(e) => e.stopPropagation()}
                                    className="px-1 py-2 text-center"
                                >
                                    <input
                                        type="checkbox"
                                        onClick={(e) => {
                                            e.stopPropagation() // Prevent row click when clicking on the checkbox
                                            onCheckboxChange(e, item) // Call the onCheckboxChange function
                                        }}
                                    />
                                </td>
                            )}

                            {filteredColumns?.map((column, colIndex) => (
                                <td
                                    key={colIndex}
                                    className={`px-1 truncate ${clickable ? 'cursor-pointer' : ''} py-2 text-left overflow-hidden overflow-ellipsis overflow-x-auto whitespace-nowrap '`}
                                >
                                    <div style={{ display: 'inline-block', maxWidth: maxWidth, whiteSpace: whiteSpace, width: "100%" }}>
                                        <Text
                                        variant='title'
                                            colorV={column === 'Status' ? 'white' : (column === 'RMA' ? 'blue' : 'blue')}
                                            weightV="normal"
                                            className={`${column === '' ? getStatusBackgroundColor(item[column]) : ''}`}
                                        >
                                            {column === 'Status' ? (
                                                <span style={{ color: transformEventColumn(item['Status'])?.color }}>
                                                    {transformEventColumn(item['Status']).text}
                                                </span>
                                            ) : (
                                                // Check if the column is 'RMA', then use transformRmaColumn
                                                column === 'RMA' ? (
                                                    <span style={{ color: transformRmaColumn(item['RMA'])?.color }}>
                                                        {transformRmaColumn(item['RMA'])?.text}
                                                    </span>
                                                ) : (
                                                    item[column]
                                                )
                                            )}
                                        </Text>
                                    </div>
                                </td>
                            ))}
                            {
                                showSupplierStatus &&
                                <td
                                    style={{ alignItems: 'center' }}
                                    className="px-1 py-2 text-center !important"
                                >
                                    <Text
                                        variant='title'
                                        colorV="blue" // Set the text color to blue
                                        weightV="normal"
                                    >
                                        {item['Status']}
                                    </Text>
                                </td>
                            }
                            {showActions && (
                                <td onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                }}
                                    className="px-1 py-2 text-center">
                                    <DropdownButtons
                                        onItemClick={(selectedOption) =>
                                            handleDropdownChange(
                                                selectedOption,
                                                item
                                            )
                                        }
                                        buttonLabel="View"
                                        items={options}
                                    />
                                </td>
                            )}
                            {showStatus && (
                                <td
                                    style={{ alignItems: 'center' }}
                                    className="px-1 py-2 text-center !important"
                                >
                                    <Text
                                        variant='title'

                                        colorV="blue" // Set the text color to blue
                                        weightV="normal"
                                        className={`${getStatusBackgroundColor(item['Status'])
                                            } text-center`}
                                    >
                                        {item['Status']}
                                    </Text>
                                </td>
                            )}

                            {isStatusRma && (
                                <td onClick={(e) => { e.stopPropagation() }} className="px-1 py-2 text-center">
                                    {(item?.status === undefined) ? (
                                        <Button height='30px' padding='0 10px' onClick={() => handleRmaClick(item)}>
                                            <RowAlignCenter className="gap-x-2">
                                                <Text colorV="white" variant="title" weightV="normal">
                                                    Claim RMA
                                                </Text>
                                            </RowAlignCenter>
                                        </Button>
                                    ) : (
                                        <Button backgroundColor='green' height='30px' padding='0 10px' onClick={() => handleRmaClick(item)}>
                                            <RowAlignCenter className="gap-x-2">
                                                <Text colorV="white" variant="title" weightV="normal">
                                                    Reclaim RMA
                                                </Text>
                                            </RowAlignCenter>
                                        </Button>
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
