import React, { useEffect } from 'react'
import { CustomerRMATable } from './CustomerRMATable'
import { useFetch } from '../../../hooks/useFetch'
import { Row } from '../../layout/Row'
const baseUrl = process.env.REACT_APP_baseUrl

export const Customerrma = () => {
    const { getRequest: getRmaData, data: rmaData } = useFetch(
        `${baseUrl}/rma/get-rma-customer`
    );
    
    useEffect(() => {
        getRmaData();
    }, [getRmaData]);
    
    return (
        <Row>
            <CustomerRMATable data={rmaData} />
        </Row>
    )
}
