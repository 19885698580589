import React from 'react'
import Head from './Head'
import CustomerCreditNoteTable from './CustomerCreditNoteTable'
export const CustomerCreditNote = () => {
  return (
      <>
          <Head />
          <CustomerCreditNoteTable />
      </>
  )
}
