import React, { useState } from 'react'
import { PrimaryCard } from '../../compound/Page/PrimaryCard'
import { Flex } from '../../layout/Flex'
import { Table } from '../../shared/Table'
import { useNavigate } from 'react-router-dom'
import { ModalDetailCustomerPurchase } from './ModalDetailCustomerPurchase'

export const CustomerPurchaseTable: React.FC<{ data: any, fullData: any }> = ({ data, fullData }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate()
    const columns = [
        'PON',
        'Date the order is sent',
        'RMA',
        'Total value',
    ]
    const handleRmaClick = (item: any) => {
        navigate(`/customer-purchase-order/purchase-order-details/${item.Id}`)
    }
    const closeModal = () => {
        setSelectedItem(null);
    };

    const handleRowClick = (item: any) => {
        setSelectedItem(item);
    };
    return (
        <>
            <Flex className="mt-3 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        columns={columns}
                        data={data}
                        showActions={false}
                        clickable={true}
                        isStatusRma={true}
                        handleRowClick={handleRowClick}
                        handleRmaClick={handleRmaClick}
                    />
                </PrimaryCard>
                {selectedItem && (
                    <ModalDetailCustomerPurchase data={fullData} item={selectedItem} onClose={closeModal} />
                )}
            </Flex>
        </>
    )
}
