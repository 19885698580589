import React from 'react';
import { TwoDatasetLineChart } from '../../../../../shared/Chart/TwoDatasetLineChart';
import { Dropdown } from '../../../../../base/Dropdown';
import { Column } from '../../../../../layout/Col';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../../base/Text';
import { PrimaryCard } from '../../../../Page/PrimaryCard';

// Define an array of gradient colors
const gradientColors = ["#112D56", "#171156", "#115056", "#3B65F6", "#6E3BF6", "#3BC2F6", "#45948A", "#457794", "#459462", "#5748E6", "#A648E6", "#4888E6", "#E8C877", "#D0E877", "#E89077"];

export const RepairPointGraph = ({ data }: any) => {
    let datasets: any[] = [];
    if (data) {
        datasets = Object.entries(data?.repairPointsByUser || {}).map(([user, repairPoints]: [string, number[]], index: number) => {
            return {
                label: user,
                data: repairPoints,
                borderColor: gradientColors[index % gradientColors.length], // Use gradient colors from the array
                pointBackgroundColor: gradientColors[index % gradientColors.length], // Set pointBackgroundColor to white
                borderWidth: 2,
                fill: false,
            };
        });
    }


    return (
        <PrimaryCard className='bg-b9white p-5 w-full'>
            <RowAlignCenterSpaced className="mb-5 w-full">
                <Text colorV="blue" variant="heading" weightV="normal">
                    Repair point per user
                </Text>
                <Dropdown
                    style={{ border: 'none !important', backgroundColor: "transparent !important" }}
                    width='75px'
                    options={['Week', 'Day', 'Month']}
                    onChange={(selectedOption) => {
                        console.log(`Selected option: ${selectedOption}`);
                    }}
                />
            </RowAlignCenterSpaced>
            <Column className='w-full'>
                <TwoDatasetLineChart data={{ labels: [], datasets }} gradientColors={gradientColors} /> {/* Pass gradient colors as prop */}
            </Column>
        </PrimaryCard>
    );
}
