import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button } from "../../../../../../base/Button";
import { ModalBox } from "../../../../../../base/ModelBox";
import { Column } from "../../../../../../layout/Col";
import { Row } from "../../../../../../layout/Row";
import { RowAlignCenterSpaced } from "../../../../../../layout/RowAlignCenterSpaced";
import { Text } from "../../../../../../base/Text";

export const EditCodeOptions = ({ onClose, codesData, onCodeSelection, selectedData }: any) => {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCodes, setSelectedCodes] = useState([]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (selectedData && selectedData.Codes && selectedData.Codes?.length > 0) {
            const defaultSelectedCodesIds = selectedData.Codes
                .filter(code => codesData.some(data => data._id === code._id))
                .map(code => code._id);
            console.log({ defaultSelectedCodesIds })
            setSelectedCodes(defaultSelectedCodesIds);
            setSelectAll(defaultSelectedCodesIds.length === codesData.length);
        } else {
            setSelectedCodes([]);
            setSelectAll(false);
        }
    }, [codesData, selectedData]);

    const handleCheckboxChange = (id) => {
        const updatedSelectedCodes = [...selectedCodes];
        const index = updatedSelectedCodes.indexOf(id);

        if (index === -1) {
            updatedSelectedCodes.push(id);
        } else {
            updatedSelectedCodes.splice(index, 1);
        }

        setSelectedCodes(updatedSelectedCodes);
        setSelectAll(updatedSelectedCodes.length === codesData.length);
    };
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        setSelectedCodes(selectAll ? [] : codesData.map(option => option._id));
    };

    const saveSelect = useCallback(() => {
        onCodeSelection(selectedCodes);
        onClose();
    }, [selectedCodes, onCodeSelection, onClose]);

    const numColumns = 5; 

    const columns = Array.from({ length: Math.ceil(codesData.length / numColumns) }, (_, index) =>
        codesData.slice(index * numColumns, (index + 1) * numColumns)
    );

    return (
        <ModalBox
            heading="Codes"
            onClose={handleClose}
            top="50%"
            height="auto"
            width="auto"
        >
            <Column className=" w-full gap-y-3 ">
                <Column className="gap-y-3">
                    <Row className="gap-x-10">
                        <Text colorV="blue" variant="title" weightV="bold">
                            Select all
                        </Text>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                        </label>
                    </Row>
                    {columns.map((column, columnIndex) => (
                        <RowAlignCenterSpaced key={columnIndex} className="gap-x-10">
                            {column.map((option) => (
                                <RowAlignCenterSpaced
                                    className="custom-width-class"
                                    inlineStyle={{ width: '200px' }}
                                    key={option._id}
                                >
                                    <Text colorV="blue" variant="title" weightV="normal">
                                        {option.label}
                                    </Text>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedCodes.includes(option._id)}
                                            onChange={() => handleCheckboxChange(option._id)}
                                        />
                                    </label>
                                </RowAlignCenterSpaced>
                            ))}
                        </RowAlignCenterSpaced>
                    ))}
                    {/* {codesData
                        .map((option) => (
                            <RowAlignCenterSpaced
                                className="gap-x-10"
                                key={option._id}
                            >
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="normal"
                                >
                                    {option.label}
                                </Text>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedCodes.includes(option._id)}
                                        onChange={() => handleCheckboxChange(option._id)}
                                    />
                                </label>
                            </RowAlignCenterSpaced>
                        ))} */}
                </Column>
                <RowAlignCenterSpaced>
                    <Button className="w-40" onClick={saveSelect}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Update
                        </Text>
                    </Button>
                </RowAlignCenterSpaced>
            </Column>
        </ModalBox>
    );
};

