import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Text } from '../../../../../base/Text'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { ImageUpload } from '../../../../../base/ImageUplaod'
import { useFetch } from '../../../../../../hooks/useFetch'
import { RowAlignCenter } from '../../../../../layout/RowAlignCenter'
import { TextBackground } from '../../../../../base/TextBackground'
import { useEdit } from '../../../../../../hooks/useEdit'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditPartner: React.FC<{
    onClose: () => void
    selectedPartner: any
}> = ({ onClose, selectedPartner }) => {
    const [companyName, setCompanyName] = useState<string>('')
    const [companyCurrency, setCompanyCurrency] = useState<string>('')
    const [contactFirstName, setContactFirstName] = useState<string>('')
    const [contactLastName, setContactLastName] = useState<string>('')
    const [contactEmail, setContactEmail] = useState<string>('')
    const [contactRole, setContactRole] = useState<string>('')
    const [accountManager, setAccountManager] = useState<string>('')
    const [tags, setTags] = useState<string[]>([])
    const [country, setCountry] = useState<string>('')
    const [site, setSite] = useState<string>('')
    const [selectedImage, setSelectedImage] = useState<File | null>(null)
    const [imageUrl, setImageUrl] = useState('')
    const [isUser, setIsUser] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false)
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany
    const { getRequest, data } = useFetch(`${baseUrl}/user/manager-data`);

    useEffect(() => {
        getRequest()
    }, [getRequest])
    const hasCustomerPortalPermission = auth?.selectedCompany?.permissions?.includes('Customer portal')

    useEffect(() => {
        if (selectedPartner) {
            setCompanyName(selectedPartner.companyName || '')
            setCompanyCurrency(selectedPartner.companyCurrency || '')
            setContactFirstName(selectedPartner.contactFirstName || '')
            setContactLastName(selectedPartner.contactLastName || '')
            setContactEmail(selectedPartner.contactEmail || '')
            setContactRole(selectedPartner.contactRole || '')
            setAccountManager(selectedPartner?.accountManager?._id || '')
            setTags(selectedPartner.tags || []) // Ensure this is an array
            setCountry(selectedPartner.country || '')
            setSite(selectedPartner.site || '')
            setImageUrl(selectedPartner.logoPath || '')
        }
    }, [selectedPartner])

    const handleCompanyNameChange = (newValue: any) => {
        setCompanyName(newValue)
    }

    const handleCompanyCurrencyChange = (event: any) => {
        setCompanyCurrency(event.target.value)
    }

    const handleContactFirstNameChange = (newValue: any) => {
        setContactFirstName(newValue)
    }

    const handleContactLastNameChange = (newValue: any) => {
        setContactLastName(newValue)
    }

    const handleContactEmailChange = (newValue: any) => {
        setContactEmail(newValue)
    }

    const handleContactRoleChange = (newValue: any) => {
        setContactRole(newValue)
    }

    const handleAccountManagerChange = (event: any) => {
        setAccountManager(event.target.value)
    }

    const handleCountryChange = (newValue: any) => {
        setCountry(newValue)
    }

    const handleSiteChange = (newValue: any) => {
        setSite(newValue)
    }

    const handleImageUpload = (file: File) => {
        setSelectedImage(file)
    }

   
    const handleSubmit = async (e: any) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('companyName', companyName)
        formData.append('companyCurrency', companyCurrency)
        formData.append('contactFirstName', contactFirstName)
        formData.append('contactLastName', contactLastName)
        formData.append('contactEmail', contactEmail)
        formData.append('contactRole', contactRole)
        formData.append('accountManager', accountManager)
        // formData.append('tags', JSON.stringify(tags)) // Ensure tags are serialized correctly
        formData.append('country', country)
        formData.append('site', site)
        formData.append('isUser', isUser.toString());
        tags.forEach(tag => formData.append('tags[]', tag));
        if (selectedImage) {
            formData.append('file', selectedImage)
        }
        setIsLoading(true)
        const urlItem = `${baseUrl}/partner/edit-partner/${selectedPartner?._id}`

        try {
            const response:any = await axios.put(urlItem, formData, {
                headers: {
                    Authorization: token as string,
                    'Content-Type': 'multipart/form-data',
                    Company: company._id
                },
            })
            if(response) {
                console.log({response});
                setIsLoading(false)
                toast.success(response?.message, { hideProgressBar: true })
                addNotification({
                    message: 'Partner has been updated successfully',
                    type: 'success',
                })
                onClose()
                setCompanyName('')
                setAccountManager('')
                setCompanyCurrency('')
                setContactFirstName('')
                setContactLastName('')
                setCountry('')
                setSite('')
                setTags([])
                setContactEmail('')
                setContactRole('')
                setSelectedImage(null)
            }
           
        } catch (error) {
            toast.error(`Error: ${error.response.data}`, {
                hideProgressBar: true,
            })
        }
    }
    const renderIsUserRadio = () => {
        if (tags.includes('customer') && hasCustomerPortalPermission) {
            return (
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Access to Customer portal
                    </Text>
                    <RowAlignCenter className='gap-x-10'>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            <input
                                className='mr-3'
                                type="radio"
                                value="Yes"
                                checked={isUser}
                                onChange={() => setIsUser(true)}
                            />
                            Yes
                        </Text>
                        <Text colorV='blue' weightV='normal' variant='title'>
                            <input
                                className='mr-3'
                                type="radio"
                                value="No"
                                checked={!isUser}
                                onChange={() => setIsUser(false)}
                            />
                            No
                        </Text>
                    </RowAlignCenter>
                </RowAlignCenterSpaced>
            );
        }

        return null; // Return null if the selected value is not "Customer"
    };
    const handleTagToggle = (value: string) => {
        if (tags.includes(value)) {
            setTags(tags.filter(tag => tag !== value))
        } else {
            setTags([...tags, value])
        }
    }

    const tagOptions = [
        { value: 'customer', label: 'Customer' },
        { value: 'supplier', label: 'Supplier' },
        { value: 'lsp', label: 'LSP' }
    ];
console.log(tags)
const renderTagCheckboxes = () => {
    return tagOptions.map(option => (
        <label key={option.value} className="flex items-center">
            <input
                type="checkbox"
                checked={tags.includes(option.value)}
                onChange={() => handleTagToggle(option.value)}
                className="mr-2 cursor-pointer"
            />
            {option.label}
        </label>
    ));
};

    return (
        <ModalBox
            heading="Edit partner"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[430px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Company name
                    </Text>
                    <TextField
                        value={companyName}
                        onChange={handleCompanyNameChange}
                        placeholder="Enter company"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Contact point first name
                    </Text>
                    <TextField
                        value={contactFirstName}
                        onChange={handleContactFirstNameChange}
                        placeholder="Enter contact point first name"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Contact point last name
                    </Text>
                    <TextField
                        value={contactLastName}
                        onChange={handleContactLastNameChange}
                        placeholder="Contact point last name"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Contact point email
                    </Text>
                    <TextField
                        value={contactEmail}
                        onChange={handleContactEmailChange}
                        placeholder="Enter contact point email"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Contact point role
                    </Text>
                    <TextField
                        value={contactRole}
                        onChange={handleContactRoleChange}
                        placeholder="Enter contact point role"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Country
                    </Text>
                    <TextField
                        value={country}
                        onChange={handleCountryChange}
                        placeholder="Enter country"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Logo
                    </Text>
                    <Column>
                        <ImageUpload
                            width="14rem"
                            onUpload={handleImageUpload}
                        />
                    </Column>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Site
                    </Text>
                    <TextField
                        value={site}
                        onChange={handleSiteChange}
                        placeholder="Enter site"
                        
                        width="220px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Company currency
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="supplier"
                            value={companyCurrency}
                            onChange={handleCompanyCurrencyChange}
                            className="w-full border hover:cursor-pointer text-sm rounded-md py-2 px-2"
                        >
                            <option value="">Select</option>
                            <option value="Euro">Euro</option>
                            <option value="USD">USD</option>
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Account manager
                    </Text>
                    {data?.length > 0 ? (
                        <div style={{ width: '14rem' }}>
                            <select
                                id="device"
                                value={accountManager}
                                defaultValue={accountManager}
                                onChange={handleAccountManagerChange}
                                className="w-full hover:cursor-pointer text-sm py-2 px-1"
                            >
                                <option disabled>Select</option>
                                {data?.map((account) => (
                                    <option key={account.accountManagerEmail} value={account?._id}>
                                        {account?.firstName} {account?.lastName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        <TextBackground className='w-56  h-10 text-left' backgroundColor="b9white">
                            <Text
                                colorV="blue"
                                variant="title"
                                weightV="normal"
                            >
                                No manager found
                            </Text>
                        </TextBackground>
                    )}
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Tags
                    </Text>
                    <div style={{ width: '14rem' }}>
                        {renderTagCheckboxes()}
                    </div>
                </RowAlignCenterSpaced>
                {renderIsUserRadio()}
                <Flex className="gap-x-4 mt-4">
                    <Button backgroundColor="lgblue" onClick={handleSubmit}>
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? "Updating..": "Update"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
