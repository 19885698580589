import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useFetch } from '../../../../../../hooks/useFetch'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../../base/Text'
import { storageList, myColor, myVat } from '../../../../../../constant/Storage'
import { useEdit } from '../../../../../../hooks/useEdit'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const EditSaleSKU: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item,
}) => {
    const [sku, setSku] = useState('')
    const [grade, setGrade] = useState('')
    const [color, setColor] = useState('')
    const [storage, setStorage] = useState('')
    const [vat, setVat] = useState('')
    const [selectedModel, setSelectedModel] = useState('')
    const [model, setModel] = useState('')
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const {sendEditRequest} = useEdit()
    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )
    const { getRequest: getGrades, data: gradesData } = useFetch(
        `${baseUrl}/grade/get-grades`
    )
    useEffect(() => {
        getModels()
        getGrades()
        if (item) {
            setSku(item?.SKU)
            setColor(item?.Color)
            setStorage(item?.Storage)
            setVat(item?.VAT)
            setGrade(item?.GradeId)
            setModel(item?.ModelId)
        }
    }, [getModels, item, getGrades])
    
    useEffect(() => {
        if (modelsData) {
            const selectedModelObject = modelsData.find(
                (mdl: any) => mdl?.modelName === item?.Model
            )
            setSelectedModel(selectedModelObject?._id || '')
        }
    }, [item?.Model, modelsData])
    console.log(selectedModel)

    const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedModel(e.target.value)
    }

    const handleSKU = (newOption) => {
        setSku(newOption)
    }
    
    const handleStorageChange = (event: any) => {
        setStorage(event.target.value)
    }

    const handleColorChange = (newValue:any) => {
        setColor(newValue)
    }

    const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value
        setGrade(newValue)
    }

    const handleVatChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value
        setVat(newValue)
    }

    const handleEditSaleSku = useCallback(async() => {
        if (!sku) {
            alert('Please, add SKU')
            return
        }
        if (!grade) {
            alert('Please, add grade')
            return
        }
        if (!color) {
            alert('Please, add grade')
            return
        }
        if (!vat) {
            alert('Please, add grade')
            return
        }
        await sendEditRequest(
                `${baseUrl}/sale-sku/edit-sku/${item?.Id}`,
                {
                    sku,
                    storage,
                    color,
                    grade,
                    vat,
                    model: selectedModel,
                },
            )
            .then(() => {
                onClose()
                toast.success('Record Updated Successfully', {
                    hideProgressBar: true,
                })
                addNotification({
                    message: 'Record has been updated successfully',
                    type: 'success',
                })
            })
            .catch((error) =>
                toast.error(`Error: ${error}`, { hideProgressBar: true })
            )
    },[color, grade, item?.Id, onClose, selectedModel, sendEditRequest, sku, storage, vat])

    return (
        <ModalBox
            heading="Edit Spare parts"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[400px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        SKU
                    </Text>
                    <TextField
                        value={sku}
                        onChange={handleSKU}
                        placeholder="Enter SKU"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        Model
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="model"
                            onChange={handleModelChange}
                            className="w-full border hover:cursor-pointer text-sm rounded-lg py-2 px-1"
                            value={selectedModel}
                        >
                            {modelsData?.map((mdl: any) => (
                                <option key={mdl?._id} value={mdl?._id}>
                                    {mdl?.modelName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        Storage
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="storage"
                            value={storage}
                            onChange={handleStorageChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {storageList?.map((stge: any) => (
                                <option key={stge.value} value={stge.value}>
                                    {stge.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        Color
                    </Text>
                    <TextField
                        value={color}
                        onChange={handleColorChange}
                        placeholder="Enter device"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>

                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                Grade
                            </Text>
                            <div style={{ width: '14rem' }}>
                                <select
                                    id="grade"
                                    value={grade}
                                    onChange={handleGradeChange}
                                    className="w-full hover:cursor-pointer text-sm   py-2 px-1"                                >
                                    <option>Select</option>
                                    {gradesData?.map((grade: any) => (
                                        <option key={grade._id} value={grade._id}>
                                            {grade.grade}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced className="gap-y-5">
                            <Text
                                colorV="blue"
                                weightV="normal"
                                variant="title"
                            >
                                VAT
                            </Text>
                            <div style={{ width: '14rem' }}>
                                <select
                                    id="vat"
                                    value={vat}
                                    onChange={handleVatChange}
                                    className="w-full hover:cursor-pointer text-sm   py-2 px-1"                                >
                                    <option>Select</option>
                                    {myVat?.map((vat: any) => (
                                        <option key={vat} value={vat}>
                                            {vat}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button
                    className='w-32'
                        onClick={handleEditSaleSku}
                        backgroundColor="blue"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Save
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
