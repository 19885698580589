import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Button } from '../../../../../../base/Button';
import { Text } from '../../../../../../base/Text';
import { TextBackground } from '../../../../../../base/TextBackground';
import { RowAlignCenterSpaced } from '../../../../../../layout/RowAlignCenterSpaced';
import { PrimaryCard } from '../../../../../Page/PrimaryCard';
import { Table } from '../../../../../../shared/Table';
import { AddCodeEvent } from './AddCodeEvent';
import { DeleteCodeEvent } from './DeleteCodeEvent';
import { useFetch } from '../../../../../../../hooks/useFetch';
import { ModalBox } from '../../../../../../base/ModelBox';
import { EditCodeEvent } from './EditCodeEvent';

const baseUrl = process.env.REACT_APP_baseUrl;

export const CodeEventManualSorting: React.FC<{ onClose: () => void; item: any; onBack: () => void }> = ({ onClose, item, onBack }) => {
    const [isCodeModal, setIsCodeModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedCode, setSelectedCode] = useState();

    const { getRequest: getEvents, data: eventsData } = useFetch(
        `${baseUrl}/sorting-logic/get-manual-sorting-event-description/${item?._id}`
    );

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    const handleCodeEvent = () => {
        setIsCodeModal(true);
    };

    const handleSelectChange = (option, item) => {
        setSelectedCode(item);
        if (option === 'Edit') {
            setIsEdit(true);
        } else if (option === 'Delete') {
            setIsDeleteModal(true);
        }
    };

    const handleClose = () => {
        getEvents();
        setIsCodeModal(false);
        setIsDeleteModal(false);
        setIsEdit(false); // Close the edit modal as well
    };

    const handleBack = useCallback(() => {
        onBack()
    }, [onBack]);
    const columns = ['Code', 'Model', 'Operating system'];
    let extractedData = [];
    if (eventsData) {
        extractedData = eventsData?.rules?.map((item) => {
            return {
                item:item,
                Id: item?._id,
                'Code': item.code.map(code => code.label).join(', '),
                'Model': item?.models?.map(model => model.modelName).join(', '),
                'Operating system': item?.operatingSystem,
            };
        });
    }
    return (
        <>
            <RowAlignCenterSpaced className='gap-x-1'>
                <Text colorV="blue" variant="heading" weightV="bold">
                    Manual sorting
                </Text>
                <TextBackground className="" backgroundColor="b9white">
                    {item?.name}
                </TextBackground>
                <TextBackground className="" backgroundColor="b9white">
                    {item?.type}
                </TextBackground>
                <Button onClick={handleCodeEvent} className="" backgroundColor="lgblue">
                    <Text colorV="white" variant="title" weightV="normal">
                        Add new rule
                    </Text>
                </Button>
                <Button onClick={handleBack} className="" backgroundColor="blue">
                    <Text colorV="white" variant="title" weightV="normal">
                        Back
                    </Text>
                </Button>
            </RowAlignCenterSpaced>
            <PrimaryCard className="w-full mt-4">
                <Table
                    options={['Edit','Delete']}
                    columns={columns}
                    whiteSpace='pre-wrap'
                    showActions={true}
                    handleDropdownChange={handleSelectChange}
                    data={extractedData}
                />
            </PrimaryCard>
            {isCodeModal && <AddCodeEvent onClose={handleClose} item={item} />}
            {isEdit && <EditCodeEvent selectedCode={selectedCode} onClose={handleClose}/>}
            {isDeleteModal && <DeleteCodeEvent selectedCode={selectedCode} onClose={handleClose} item={item} />}
        </>
    );
};
