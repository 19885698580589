import React, { useState } from 'react'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Profile } from '../Profile/Profile'
import { Flex } from '../../layout/Flex'
import { SearchBar } from '../../base/Searchbar'
import { SearchInput } from '../SearchModal/SearchInput'

export default function Header() {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)

    const handleSearchBarClick = () => {
        setIsSearchModalOpen(true)
    }

    const handleCloseSearchModal = () => {
        setIsSearchModalOpen(false)
    }
    return (
        <Flex className="">
            <RowAlignCenterSpaced className="">
                <SearchBar
                    zIndex="0"
                    width="450px"
                    disabled={isSearchModalOpen}
                    onSearchBarClick={handleSearchBarClick}
                />
                <Profile />
            </RowAlignCenterSpaced>

            {isSearchModalOpen && (
                <SearchInput onClose={handleCloseSearchModal} />
            )}
        </Flex>
    )
}
