import React, { useCallback, useEffect, useRef, useState } from 'react';
import JsBarcode from 'jsbarcode';
import { RowSpaced } from '../../../../layout/RowSpaced';
import { Column } from '../../../../layout/Col';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../base/Text';
import { useFetch } from '../../../../../hooks/useFetch';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../utils/auth';
import { useEdit } from '../../../../../hooks/useEdit';
import { addNotification } from '../../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl

export const Label = () => {
    const [checkboxes, setCheckboxes] = useState([]);
    const barCode = useRef<HTMLDivElement>(null)
    const auth = useAuth()
    const token = auth?.user?.token?.token

    const { sendEditRequest } = useEdit()
    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    )

    useEffect(() => {
        getLabels()
    }, [getLabels])

    useEffect(() => {
        const initialCheckboxes = labelsData?.labels?.map((label) => label?.isLabel || false) || [];
        setCheckboxes(initialCheckboxes);
    }, [labelsData]);

    useEffect(() => {
        JsBarcode('#barcode', "23232322323232", {
            lineColor: 'black',
            textAlign: 'center',
            width: 4.1,
            background: 'transparent',
            height: 20,
            displayValue: false,
            xmlDocument: document,
        })
    }, [])

    const handleCheckboxChange = (index) => {
        const updatedCheckboxes = checkboxes.map((value, i) => (i === index ? !value : value));
        setCheckboxes(updatedCheckboxes);
        handleSaveChange(updatedCheckboxes);  // Pass the updatedCheckboxes array to handleSaveChange
    };


    const showTextBelowCheckbox = (index) => {
        const labelName = labelsData?.labels[index]?.labelName;

        if (checkboxes[index] && labelName) {
            const formattedLabelName = labelName
                .replace(/_/g, ' ')  // Replace underscores with spaces
                .replace(/\b\w/g, (char) => char.toUpperCase());  // Capitalize the first letter

            return (
                <Text colorV="blue" variant="title" weightV="bold">
                    {formattedLabelName}
                </Text>
            );
        }
        return null;
    };


    const checkboxElements = labelsData?.labels?.map((label, index) => {
        const formattedLabelName = label?.labelName
            .replace(/_/g, ' ')  // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase());  // Capitalize the first letter

        return (
            <div key={index} className='inline-block'>
                <RowAlignCenterSpaced className='gap-x-24 mb-4'>
                    <label htmlFor={`checkbox-${index}`}>{formattedLabelName}</label>
                    <input
                        type="checkbox"
                        value={label.isLabel}
                        id={`checkbox-${index}`}
                        checked={checkboxes[index]}
                        onChange={() => handleCheckboxChange(index)}
                    />
                </RowAlignCenterSpaced>
            </div>
        );
    });

    const handleSaveChange = useCallback(async (updatedCheckboxes) => {
        const updatedLabels = labelsData?.labels?.map((label, index) => ({
            _id: label?._id,
            labelName: label?.labelName || '',
            isLabel: updatedCheckboxes[index],
        })) || [];
        await sendEditRequest(
            `${baseUrl}/label/label-update/${labelsData?._id}`,
            {
                labels: updatedLabels,
            },
        )
            .then((response) => {
                toast.success(response?.data?.message, { hideProgressBar: true });
                addNotification({
                    message: 'Label has been updated successfully',
                    type: 'success',
                });
                getLabels();
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            );
    }, [getLabels, labelsData?._id, labelsData?.labels, sendEditRequest])


    return (
        <div className='gap-x-20 flex '>
            <RowSpaced className='flex-row'>
                <Column>
                    {checkboxElements}
                </Column>
            </RowSpaced>
            <div className="h-[310px] w-[500px] bg-white rounded-md shadow-lg p-6">
                <Column className='mb-4 justify-center items-center'>
                    <div ref={barCode} className="">
                        <svg id="barcode"></svg>
                    </div>
                    {showTextBelowCheckbox(1)}
                </Column>

                <RowAlignCenterSpaced className='gap-x-2'>
                    <Column className='gap-y-4'>
                        {showTextBelowCheckbox(0)} {/* Show text for Model */}
                        {showTextBelowCheckbox(2)} {/* Show text for Storage */}
                        {showTextBelowCheckbox(3)} {/* Show text for VAT */}
                        {showTextBelowCheckbox(4)} {/* Show text for VAT */}
                        {showTextBelowCheckbox(10)} {/* Show text for VAT */}
                    </Column>
                    <Column className='gap-y-4'>
                        {showTextBelowCheckbox(6)} {/* Show text for Purchase order */}
                        {showTextBelowCheckbox(7)} {/* Show text for Grade */}
                        {showTextBelowCheckbox(8)} {/* Show text for Color */}
                        {showTextBelowCheckbox(5)} {/* Show text for Battery */}
                        {showTextBelowCheckbox(9)} {/* Show text for SKU */}

                    </Column>
                </RowAlignCenterSpaced>
            </div>
        </div>
    );
};
