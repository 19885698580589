import React, { useEffect, useState } from 'react';
import { Table } from '../../../../../shared/Table';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { Button } from '../../../../../base/Button';
import { Text } from '../../../../../base/Text';
import { useFetch } from '../../../../../../hooks/useFetch';
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced';
import { AddSupplier } from './Modals/AddSupplier';
import { EditSupplier } from './Modals/EditSupplier';
import { SupplierCodeEvent } from './SupplierCodeEvent';

const baseUrl = process.env.REACT_APP_baseUrl;


export const SupplierWarrantyEvent = ({ partners, refetch }: { partners: any[]; refetch: any }) => {
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isRenameEventModalOpen, setIsRenameEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isCode, setIsCode] = useState(false);
  const [clickedRow, setClickedRow] = useState(null); // New state to track clicked row

  const { getRequest:getSupplierWarrantyData, data:supplierWarrantyData } = useFetch(`${baseUrl}/sorting-logic/get-supplier-rules`);
  const filterSupplier = partners?.filter((partner) => partner.tags.includes('supplier'));

  useEffect(() => {
    getSupplierWarrantyData();
  }, [getSupplierWarrantyData]);

  const openAddEventModal = () => {
    setIsAddEventModalOpen(true);
  };

  const onClose = () => {
    setIsAddEventModalOpen(false);
    setIsRenameEventModalOpen(false);
    setSelectedEvent(null);
    setIsCode(false);
    setClickedRow(null);
    getSupplierWarrantyData();
    refetch()
  };

  const handleSelectChange = (optionValue, item) => {
    setSelectedEvent(item);
    setIsRenameEventModalOpen(true);
  };

  let extractedData = [];
  if (supplierWarrantyData) {
    extractedData = supplierWarrantyData?.map((item) => {
      return {
        Id: item?._id,
        Supplier: item?.supplier?.companyName,
        Status: item?.status,
        Codes:item?.codes
      };
    });
  }

  const columns = ['Supplier'];
  const handleBack = () => {
    setIsCode(false)
  };
  return (
    <>
      {clickedRow && isCode ? (
        <SupplierCodeEvent
        onClose={onClose}
        item={clickedRow}
        onBack={handleBack}
          />
      ) : (
        <>
          <RowAlignCenterSpaced>
            <Text colorV="blue" variant="heading" weightV="bold">
              Supplier warranty
            </Text>
            <Button className="w-40" backgroundColor="lgblue" onClick={openAddEventModal}>
              <Text colorV="white" variant="title" weightV="normal">
                Add new rule
              </Text>
            </Button>
            
          </RowAlignCenterSpaced>
          <PrimaryCard  className="w-full mt-4 ">
            <Table
              options={['Edit']}
              columns={columns}
              showActions={true}
              handleDropdownChange={handleSelectChange}
              data={extractedData}
              showSupplierStatus={true}
            />
          </PrimaryCard>
        </>
      )}

      {isAddEventModalOpen && <AddSupplier onClose={onClose} partners={filterSupplier} />}
      {isRenameEventModalOpen && <EditSupplier onClose={onClose} selectedPartner={selectedEvent} partners={filterSupplier} />}
    </>
  );
};

