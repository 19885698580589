
export const generatePDF = (item, labelsData, barCodeRef) => {
    console.log({item});
    const barcodeSvg = `<svg id="barcode">${barCodeRef.current?.innerHTML}</svg>`;
    const docDefinition = {
        content: [
            labelsData?.labels[1]?.isLabel && {
                svg: barcodeSvg,
                height: 45,
                width: 250,
                margin: [0, 0, 0, 5],
            },
            labelsData?.labels[1]?.isLabel && {
                text: item?.item?.imei,
                margin: [40, 0, 0, 5],
            },
            {
                columns: [
                    {
                        stack: [
                            labelsData?.labels[0]?.isLabel && { text: 'Model :    ' + (item?.item?.model?.modelName || ''), margin: [0, 0, 0, 3,], },
                            labelsData?.labels[2]?.isLabel && { text: 'Storage :  ' + (item?.item?.storage || ''), margin: [0, 0, 0, 3,], },
                            labelsData?.labels[4]?.isLabel && { text: 'VAT :    ' + (item?.item?.vat || ''), margin: [0, 0, 0, 3,], },
                            labelsData?.labels[8]?.isLabel && { text: 'Status :  ' + (item?.item?.sortingEventKey || ''), margin: [0, 0, 0, 3,], },
                            labelsData?.labels[10]?.isLabel && !item?.item?.grade && { text: 'Codes :   ' + (item?.item?.codesAfterFix?.length > 0 ? item?.item?.codesAfterFix?.toString() : item?.item?.codes?.toString() || ''), margin: [0, 0, 0, 0,], },
                        ].filter(Boolean),
                        width: 120,
                    },
                    {
                        stack: [
                            labelsData?.labels[7]?.isLabel && { text: 'Purchase Order :  ' + (item?.item?.purchaseId?.saleBatchNumber || ''), margin: [0, 0, 0, 3,] },
                            labelsData?.labels[3]?.isLabel && { text: 'Color :      ' + (item?.item?.color || ''), margin: [0, 0, 0, 3,] },
                            labelsData?.labels[5]?.isLabel && { text: 'Grade :      ' + (item?.item?.grade?.grade || ''), margin: [0, 0, 0, 3,] },
                            labelsData?.labels[6]?.isLabel && { text: 'Battery :    ' + (item?.item?.battery || ''), margin: [0, 0, 0, 3,] },
                            labelsData?.labels[9]?.isLabel && { text: 'SKU :     ' + (item?.item?.sku?.sku || ''), margin: [0, 0, 0, 3,] },
                        ].filter(Boolean),
                        width: 110,
                    },
                ],
            },
        ],
        styles: {
            header: {
                fontSize: 8,
                bold: true
            },
            tableStyle: {
                margin: [0, 20, 20, 0],
            },
        },
        pageMargins: [10, 0, 10, 0], // Top, Left, Bottom, Right
    };

    // @ts-ignore
    pdfMake.createPdf(docDefinition).print();
};
