import React, { useCallback, useEffect, useState } from 'react'
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Row } from '../../../../layout/Row'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { InventoryEvent } from './components/InventoryEvent'
import { LabelBar } from './components/LabelBar'
import { SalesEvent } from './components/SalesEvent'
import { CustomerPortalEvent } from './components/CustomerPortalEvent'
import { ManualSortingEvent } from './components/ManualSortingEvent'
import { DiagnosticsEvent } from './components/DiagnosticsEvent'
import axios from 'axios'
import { GradingEvent } from './components/GradingEvent'
import { MyRMAEvent } from './components/MyRMAEvent'
import { PolishingEvent } from './components/PolishingEvent'
import { RMAEvent } from './components/RMAEvent'
import { RepairEvent } from './components/RepairEvent'
import { StocktakingEvent } from './components/StocktakingEvent'
import { SupplierWarrantyEvent } from './components/SupplierWarrantyEvent'
import { useAuth } from '../../../../../utils/auth'
import { useFetch } from '../../../../../hooks/useFetch'
import { toast } from 'react-toastify'
import { Transit } from './components/Transit'
const baseUrl = process.env.REACT_APP_baseUrl

export const SortingLogic = () => {
    const auth = useAuth()
    const company = auth.selectedCompany
    const [sortingLogic, setSortingLogic] = useState([])
    const [eventTypes, setEventTypes] = useState<any[]>([]);
    const [activeItem, setActiveItem] = useState('Inventory')
    const [manualSortingEvents, setManualSortingEvents] = useState<any>()
    const handleItemClick = (item) => {
        setActiveItem(item)
    }

    const getSortingLogic = useCallback(async () => {
        const token = auth.user?.token?.token
        await axios
            .get(`${baseUrl}/sorting-logic/get-sorting-logic-events`, {
                headers: {
                    Authorization: token,
                    Company: company._id
                },
            })
            .then((data) => {
                setSortingLogic(data?.data?.sortingLogicEvents)
                setManualSortingEvents(data?.data?.manualSorting)
                if (data?.data) {
                    const eventTypes: any = data?.data?.sortingLogicEvents.map(item => item.type);
                    //@ts-ignore
                    const distinctEventTypes: any = [...new Set(eventTypes)];
                    setEventTypes(distinctEventTypes);
                }
            })
            .catch((error) => {
                toast.error(error.response?.data?.message, {
                    hideProgressBar: true,
                })
            })
    }, [auth.user?.token?.token, company._id])
    const { getRequest: getPartnersData, data: partnersData } = useFetch(`${baseUrl}/partner/get-partners`);

    useEffect(() => {
        getSortingLogic()
        getPartnersData()
        return () => { }
    }, [getSortingLogic, getPartnersData])

    const refetch = useCallback(() => {
        getSortingLogic()
    }, [getSortingLogic])

    const components = {
        Inventory: <InventoryEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        Sales: <SalesEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        'Customer portal': <CustomerPortalEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        RMA: <RMAEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        'My RMA': <MyRMAEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        Stocktaking: <StocktakingEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        Grading: <GradingEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        Repair: <RepairEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        Transit: <Transit eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        Diagnostics: <DiagnosticsEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        Polishing: <PolishingEvent eventTypes={eventTypes} sortingLogic={sortingLogic} refetch={refetch} />,
        'Manual sorting': <ManualSortingEvent eventTypes={eventTypes} sortingLogic={manualSortingEvents} refetch={refetch} />,
        'Supplier warranty': <SupplierWarrantyEvent partners={partnersData} refetch={refetch} />,
    }

    const list = Object.keys(components)
    return (
        <>
            <Column className="w-full">
                <RowAlignCenterSpaced className="mb-2">
                    <Text
                        style={{ width: '300px' }}
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        Manage sorting logic
                    </Text>

                </RowAlignCenterSpaced>
                <Row className="gap-x-4">
                    <div className="w-10/12">{components[activeItem]}</div>
                    <LabelBar
                        className="rounded"
                        navItems={list}
                        activeItem={activeItem}
                        onItemClick={handleItemClick}
                    />
                </Row>
            </Column>
        </>
    )
}
