import React from 'react'
import DeviceTable from './component/DeviceTable'

export const Device = () => {
  return (
    <>
      <DeviceTable />
    </>
  )
}
