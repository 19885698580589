import { useState } from "react"
import { Button } from "../../../base/Button"
import { Column } from "../../../layout/Col"
import { Flex } from "../../../layout/Flex"
import { Row } from "../../../layout/Row"
import { RowAlignCenterSpaced } from "../../../layout/RowAlignCenterSpaced"
import { Text } from "../../../base/Text"
import { TextBackground } from "../../../base/TextBackground"
import { RmaReportedRMA } from "./component/RmaReportedRMA"
import { RmaProposedRepair } from "./component/RmaProposedRepair"
import { RmaSummary } from "./component/RmaSummary"

export const RmaButtons = ({ rmaItemsData, refetchData }: any) => {
    const [activeTab, setActiveTab] = useState('Reported RMA')
    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Flex className=" w-full">
            <Column className='w-full'>
                <Row className="gap-x-10 w-full">
                    <Button
                        onClick={() => handleTabClick('Reported RMA')}
                        backgroundColor={
                            activeTab === 'Reported RMA' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Reported RMA'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Reported RMA'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Reported RMA
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Proposed repair')}
                        backgroundColor={
                            activeTab === 'Proposed repair'
                                ? 'blue'
                                : 'grey'
                        }
                        className={
                            activeTab === 'Proposed repair'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Proposed repair'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Proposed repair
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Summary')}
                        backgroundColor={
                            activeTab === 'Summary' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Summary'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={
                                activeTab === 'Summary'
                                    ? 'white'
                                    : 'blue'
                            }
                            variant="title"
                            weightV="normal"
                        >
                            Summary
                        </Text>
                    </Button>
                </Row>
                <RowAlignCenterSpaced className="mt-5">
                    {activeTab === 'Reported RMA' && <RmaReportedRMA refetch={refetchData} data={rmaItemsData} />}
                    {activeTab === 'Proposed repair' && (
                        <RmaProposedRepair refetch={refetchData} data={rmaItemsData} />
                    )}
                    {activeTab === 'Summary' && (
                        <RmaSummary refetch={refetchData} data={rmaItemsData} />
                    )}
                </RowAlignCenterSpaced>
            </Column>
        </Flex>
    )
}
