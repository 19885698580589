import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import close from '../assets/close.png'
import edit1 from '../assets/edit1.png'
import myDelete from '../assets/myDelete.png'
import TopHeader from '../components/TopHeader'
import { useFetch } from '../hooks/useFetch'
import { useAuth } from '../utils/auth'
import { addNotification } from '../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

export default function Stock() {
    const [stock, setStock] = useState<any>()
    const [event, setEvent] = useState<any>()
    const navigate = useNavigate()
    const [models, setModels] = useState<string[]>([])
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const company = auth.selectedCompany

    const isAdmin = auth.user.role?.includes('admin')

    const { getRequest, data } = useFetch(`${baseUrl}/stock/getStock`)
    const { getRequest: getMobileModels, data: mobileData } = useFetch(
        `${baseUrl}/mobile/getModels`
    )

    useEffect(() => {
        getRequest()
        getMobileModels()
    }, [getMobileModels, getRequest])

    useEffect(() => {
        setStock(data)
        setModels(mobileData)
    }, [data, mobileData])

    const handleInsert = () => {
        navigate('/insertStock')
    }

    const handleStockEdit = (e: any, id: string, stock: any) => {
        localStorage.setItem('stock', JSON.stringify(stock))
        navigate(`/editStock/${id}`)
    }

    const handleDelete = (e: any, id: any) => {
        e.stopPropagation()
        axios
            .delete(`${baseUrl}/stock/delete/${id}`, {
                headers: {
                    Authorization: token as string,
                    Company: company._id
                },
            })
            .then((response) => {
                toast.success(response.data)
                addNotification({
                    message: 'Stock has been deleted successfully',
                    type: 'success',
                })
                getRequest()
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            )
    }

    const handleClose = () => {
        setEvent(undefined)
    }

    const handleEventChange = (e: any) => {
        getMobileModels()
        e.preventDefault()
        setEvent(e.target.value)
        axios
            .post(
                `${baseUrl}/stock/search`,
                {
                    event: e.target.value,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then((response) => {
                setStock(response.data.item)
            })
            .catch((error) =>
                toast.error(`Error: ${error.response.data.message}`, {
                    hideProgressBar: true,
                })
            )
    }
    console.log(stock)
    return (
        <>
            <TopHeader />
            <div>
                <div className="flex  p-3 flex-col">
                    <div className="overflow-x-auto">
                        <div className="my-2 pb-3 mx-4 flex flex-row justify-between">
                            <h1 className="font-bold text-xl">Spare parts</h1>
                            {isAdmin && (
                                <button
                                    onClick={() => handleInsert()}
                                    className="relative py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                    Add Spare Part
                                </button>
                            )}
                        </div>
                        <div className="mx-4">
                            <div>
                                <div>
                                    <div className="model flex flex-row justify-between mb-5">
                                        <select
                                            id="model"
                                            onChange={(e) =>
                                                handleEventChange(e)
                                            }
                                            className="w-2/4 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-2"
                                        >
                                            <option
                                                value=""
                                                className="bg-b9green1"
                                            >
                                                Search by model ...
                                            </option>
                                            {models?.map((mdl: any) => (
                                                <option
                                                    key={mdl?._id}
                                                    value={mdl?._id}
                                                >
                                                    {mdl?.modelName}
                                                </option>
                                            ))}
                                        </select>
                                        {event && (
                                            <button onClick={handleClose}>
                                                {' '}
                                                <img
                                                    src={close}
                                                    className="h-8 align-right cursor-pointer w-15"
                                                    alt="true "
                                                />
                                            </button>
                                        )}
                                    </div>
                                    <table className="w-full text-center">
                                        <thead className="bg-b9green2 border-b h-14">
                                            <tr className="text-md font-bold">
                                                <th
                                                    style={{
                                                        width: 50,
                                                    }}
                                                >
                                                    SKU
                                                </th>
                                                <th
                                                    style={{
                                                        width: 130,
                                                    }}
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    style={{
                                                        width: 150,
                                                    }}
                                                >
                                                    Model
                                                </th>
                                                <th
                                                    style={{
                                                        width: 50,
                                                    }}
                                                >
                                                    Pieces
                                                </th>
                                                <th
                                                    style={{
                                                        width: 60,
                                                    }}
                                                >
                                                    Price
                                                </th>
                                                {isAdmin && (
                                                    <>
                                                        <th
                                                            style={{
                                                                width: 50,
                                                            }}
                                                        >
                                                            Action
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: 50,
                                                            }}
                                                        >
                                                            Delete
                                                        </th>
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        {stock?.map((stk: any) => (
                                            <tbody key={stk?._id}>
                                                <tr
                                                    // onClick={() => openSheetDetails(stk._id)}
                                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                                >
                                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                        {stk.sku}
                                                    </td>
                                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                        {stk.name}
                                                    </td>
                                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                        {stk.models?.map(
                                                            (mdl) => (
                                                                <span
                                                                    key={
                                                                        mdl._id
                                                                    }
                                                                >
                                                                    {
                                                                        mdl?.modelName
                                                                    }
                                                                    ,
                                                                </span>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                        {stk.pieces}
                                                    </td>
                                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                        {stk.price}
                                                    </td>
                                                    {isAdmin && (
                                                        <>
                                                            <td
                                                                className="hover:cursor-pointer text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleStockEdit(
                                                                        e,
                                                                        stk?._id,
                                                                        stk
                                                                    )
                                                                }}
                                                            >
                                                                <img
                                                                    src={edit1}
                                                                    className="h-6 mx-auto  max-w-sm"
                                                                    alt="edit"
                                                                />
                                                            </td>

                                                            <td
                                                                className="hover:cursor-pointer text-md text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleDelete(
                                                                        e,
                                                                        stk?._id
                                                                    )
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        myDelete
                                                                    }
                                                                    className="h-6 mx-auto  max-w-sm"
                                                                    alt="edit"
                                                                />
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    )
}
