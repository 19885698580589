import React, { useEffect, useState } from 'react';
import { Flex } from '../../../layout/Flex';
import { Table } from '../../../shared/Table';
import { PrimaryCard } from '../../Page/PrimaryCard';
import { useParams } from 'react-router-dom';
import Header from '../../../shared/Header/Header';
import { Breadcrumb } from '../../../base/Breadcrumb';
import { Column } from '../../../layout/Col';
import { Row } from '../../../layout/Row';
import { useBreadcrumb } from '../../../../hooks/useBreadcrumb';
import { useFetch } from '../../../../hooks/useFetch';
import Head from './Head';
import { EditItem } from './EditItem';
const baseUrl = process.env.REACT_APP_baseUrl;

export default function PurchaseItemsTable() {
  const { id } = useParams();
  const breadcrumbItems = useBreadcrumb();

  const { getRequest: getPurchaseItems, data } = useFetch(
    `${baseUrl}/purchase/get-items-purchase/${id}`
  );

  useEffect(() => {
    getPurchaseItems();
  }, [getPurchaseItems]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSelectChange = (action, item) => {
    if (action === 'Edit') {
      setSelectedItem(item); // Set the selected item
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpen(false);
    getPurchaseItems();
  };
  let extractedData = [];
  if (data) {
    extractedData = data?.items?.map((item, index) => {
      return {
        Id: data?._id,
        'IMEI': item?.imei,
        Model: item?.model?.modelName,
        Storage: item?.storage,
        VAT: item?.vat,
        'Sale Price': item?.salePrice,
      };
    });
  }

  const columns = [
    'IMEI',
    'Model',
    'Storage',
    'VAT',
    'Sale Price',
  ];

  const refetchData = () => {
    getPurchaseItems();
  };

  return (
    <>
      <Flex className="mb-3 ml-5">
        <Column className='w-full'>
          <Header />
          <Column className="">
            <Row className="my-1.5 w-full">
              <Breadcrumb items={breadcrumbItems} separator="/" />
            </Row>
            <Head refetchData={refetchData} itemsData={data} />
          </Column>
          <PrimaryCard className="w-full">
            <Table
              options={['Edit']}
              columns={columns}
              data={extractedData}
              showActions={true}
              handleDropdownChange={handleSelectChange}
            />
          </PrimaryCard>
        </Column>
      </Flex>

      {isModalOpen && (
        <EditItem
          item={selectedItem}
          onClose={closeModal}
        />
      )}
    </>
  );
}
