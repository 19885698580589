import axios from 'axios'
import * as _ from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import close from '../../../assets/close.png'
import { useFetch } from '../../../hooks/useFetch'
import { useAuth } from '../../../utils/auth'
import TopHeader from '../../TopHeader'

const baseUrl = process.env.REACT_APP_baseUrl

export default function BatchOverview() {
    const [batch, setBatch] = useState<any>()
    const [batchSearch, setBatchSearch] = useState<any>()
    const auth = useAuth()
    const token = auth?.user?.token?.token


    // const url = `${baseUrl}/inventory/batch`;
    const { getRequest: getBatch } = useFetch(
        `${baseUrl}/inventory/batch`
    )

    useEffect(() => {
        getBatch()
    }, [getBatch])

    useEffect(() => {
        const batchData = []
        const batchObj = _.groupBy(batchData, 'batchNumber')
        for (const key in batchObj) {
            batchData.push({
                batchNumber: key,
                batchList: batchObj[key],
            })
        }
        setBatch(batchData)
    }, [])

    const handleSearchChange = (e: any) => {
        e.preventDefault()
        setBatchSearch(e.target.value)
    }

    const handleSearch = useCallback(
        (e: any) => {
            e.preventDefault()
            axios
                .post(
                    `${baseUrl}/inventory/search`,
                    {
                        batchNumber: batchSearch,
                    },
                    {
                        headers: {
                            Authorization: token as string,
                        },
                    }
                )
                .then((response) => {
                    const batch = response.data
                    const batchData = []
                    const batchObj = _.groupBy(batch, 'batchNumber')
                    for (const key in batchObj) {
                        if (batchObj[key].length > 0) {
                            batchData.push({
                                batchNumber: key,
                                batchList: batchObj[key],
                            })
                        }
                    }
                    setBatch(batchData)
                })
                .catch((error) =>
                    toast.error(`Error: ${error.response.data.message}`, {
                        hideProgressBar: true,
                    })
                )
        },
        [batchSearch, token]
    )

    const handleClose = () => {
        setBatchSearch('')
        getBatch()
    }

    return (
        <>
            <TopHeader />
            <div className="flex items-center flex-col">
                <div className="overflow-x-auto">
                    <div className="flex px-3 py-5 ">
                        {/* <div className=" mr-5 mt-1">
              <button type="button" onClick={goBack}>
                <img src={back} className="h-6 w-6 h-5 max-w-sm" alt="edit" />
              </button>
            </div> */}
                        <div className="mr-5 basis-1/4  text-left">
                            <h1 className="font-bold text-xl ">
                                Batch Overview
                            </h1>
                        </div>
                        <div className=" mr-5 basis-1/2  text-right">
                            <form>
                                <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
                                    Search
                                </label>
                                <div className="relative">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-4 text-gray-500 dark:text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                        </svg>
                                    </div>
                                    <input
                                        type="search"
                                        onChange={(e) => handleSearchChange(e)}
                                        id="default-search"
                                        className="block p-2 pl-10 w-full text-sm text-gray-900  bg-gray-50 rounded-lg border dark:placeholder-gray-400"
                                        placeholder="Search batch number"
                                        required
                                    />
                                    <button
                                        onClick={handleSearch}
                                        className="text-white absolute right-2.5 bottom-1.5  focus:outline-none font-medium rounded-lg text-sm px-4 py-1 hover:bg-b9green4 focus:outline-none  bg-b9green3"
                                    >
                                        Search
                                    </button>
                                </div>
                            </form>
                        </div>
                        {batchSearch && (
                            <div className="basis-1/5 text-right ">
                                <img
                                    src={close}
                                    onClick={handleClose}
                                    className="h-8 align-right cursor-pointer w-15"
                                    alt="true "
                                />
                            </div>
                        )}
                    </div>
                    <div className="pt-2 mx-3 inline-block min-w-ful mt-6">
                        <table className="table-fixed text-center">
                            <thead className="bg-b9green2 border-b h-14">
                                <tr className="text-md font-bold">
                                    <th
                                        style={{
                                            width: 150,
                                        }}
                                        className="px-1"
                                    >
                                        Batch
                                    </th>
                                    <th
                                        style={{
                                            width: 150,
                                        }}
                                        className="px-1"
                                    >
                                        Inventory
                                    </th>
                                    <th
                                        style={{
                                            width: 150,
                                        }}
                                        className="px-1"
                                    >
                                        Status
                                    </th>

                                    <th
                                        style={{
                                            width: 150,
                                        }}
                                        className="px-1"
                                    >
                                        Batch detail
                                    </th>

                                    <th
                                        style={{
                                            width: 150,
                                        }}
                                        className="px-1"
                                    >
                                        Date Invoiced
                                    </th>
                                    <th
                                        style={{
                                            width: 160,
                                        }}
                                        className="w-16 px-1"
                                    >
                                        Model
                                    </th>
                                    <th
                                        style={{
                                            width: 90,
                                        }}
                                        className="px-1"
                                    >
                                        Storage
                                    </th>
                                    <th
                                        style={{
                                            width: 80,
                                        }}
                                        className="px-1"
                                    >
                                        VAT
                                    </th>
                                    <th
                                        style={{
                                            width: 193,
                                        }}
                                        className="px-1"
                                    >
                                        Serial Number/ IMEI
                                    </th>

                                    <th
                                        style={{
                                            width: 190,
                                        }}
                                        className="px-1"
                                    >
                                        Codes
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="mx-3 inline-block min-w-ful">
                        {batch?.map(({ batchList, batchNumber }) => {
                            return (
                                <table
                                    key={batchNumber}
                                    className="table-fixed text-center"
                                >
                                    <thead className="bg-b9green2 border-b h-14">
                                        <tr className="text-md font-bold">
                                            <th
                                                style={{
                                                    width: 180,
                                                }}
                                                className="px-1"
                                            >
                                                # {batchNumber}
                                            </th>
                                            <th
                                                style={{
                                                    width: 150,
                                                }}
                                                className="px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 150,
                                                }}
                                                className="px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 150,
                                                }}
                                                className="px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 150,
                                                }}
                                                className="px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 150,
                                                }}
                                                className="px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 160,
                                                }}
                                                className="w-16 px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 90,
                                                }}
                                                className="px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 80,
                                                }}
                                                className="px-1"
                                            ></th>
                                            <th
                                                style={{
                                                    width: 193,
                                                }}
                                                className="px-1"
                                            ></th>
                                        </tr>
                                    </thead>
                                    {batchList?.map((batch: any) => (
                                        <tbody key={batch?._id}>
                                            <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                                <td className="text-md text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.batchNumber}
                                                </td>
                                                <td className="text-md text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.event}
                                                </td>
                                                <td className="text-md text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.status}
                                                </td>

                                                <td className="text-md text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.batchDescription}
                                                </td>

                                                <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch.dateInvoiced && (
                                                        <>
                                                            {moment(
                                                                batch.dateInvoiced
                                                            ).format(
                                                                'DD/MM/YYYY'
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                                <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.model?.modelName}
                                                </td>
                                                <td className="text-md w-12 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.storage}
                                                </td>
                                                <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.vat?.slice(0, 1)}
                                                </td>
                                                <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.imei}
                                                </td>

                                                <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                                    {batch?.codes?.toString()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}
