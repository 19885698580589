import React from 'react'
// import { TotalInsights } from './components/TotalInsights'
// import { GraphInsights } from './components/GraphInsights'
import { Column } from '../../../layout/Col'

export const Focus = () => {
    return (
        <>
            <Column>im foc  us
                {/* <TotalInsights /> */}
                {/* <GraphInsights /> */}
            </Column>
        </>
    )
}
