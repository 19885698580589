import { useEffect, useState } from "react";
import {QRCodeSVG} from 'qrcode.react';

interface NotificationDialogProps {
    /* eslint-disable react/prop-types */
    onClose?: () => void;
    item: any;
    type?: string;
}

export const QrCodeViwer: React.FC<NotificationDialogProps> = ({ onClose, item, type }) => {
    
    return (
        <div className="top-0 left-0 fixed z-50 w-full h-full flex flex-col items-center md:justify-center justify-end p-4">
            <button className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-40 cursor-default" onClick={onClose}>

            </button>
            <div className="z-20 bg-white p-4 rounded-md w-full max-w-2xl md:aspect-video grid md:grid-cols-2 grid-rows-2 md:grid-rows-1">
                <div className="p-2 flex flex-col">
                    <header>
                        {!type && (
                            <p className="text-lg font-bold text-zinc-700">Item Details</p>
                        )}
                        {type && (
                            <p className="text-lg font-bold text-zinc-700">{type} Details</p>
                        )}
                    </header>
                    {item.Name && (
                        <div className="flex flex-row items-center text-zinc-800 text-sm">
                            <p className="font-semibold">Name</p>
                            <p className="ml-auto">{item.Name}</p>
                        </div>
                    )}
                    {item.Model && (
                        <div className="flex flex-row items-center text-zinc-800 text-sm">
                            <p className="font-semibold">Model</p>
                            <p className="ml-auto">{item.Model}</p>
                        </div>
                    )}
                    {item.ID && (
                        <div className="flex flex-row items-center text-zinc-800 text-sm">
                            <p className="font-semibold">ID</p>
                            <p className="ml-auto">{item.ID}</p>
                        </div>
                    )}
                    {item.Storage && (
                        <div className="flex flex-row items-center text-zinc-800 text-sm">
                            <p className="font-semibold">Storage</p>
                            <p className="ml-auto">{item.Storage}</p>
                        </div>
                    )}
                    {item.VAT && (
                        <div className="flex flex-row items-center text-zinc-800 text-sm">
                            <p className="font-semibold">VAT</p>
                            <p className="ml-auto">{item.VAT}</p>
                        </div>
                    )}
                    {item.Grade && (
                        <div className="flex flex-row items-center text-zinc-800 text-sm">
                            <p className="font-semibold">Grade</p>
                            <p className="ml-auto">{item.Grade}</p>
                        </div>
                    )}
                    {item.Color && (
                        <div className="flex flex-row items-center text-zinc-800 text-sm">
                            <p className="font-semibold">Color</p>
                            <p className="ml-auto">{item.Color}</p>
                        </div>
                    )}
                    <div className="flex flex-row items-center text-zinc-800 text-sm gap-3 mt-auto">
                        <div className="flex-1 p-4 bg-slate-50 items-center justify-center rounded-lg text-center">
                            <p className="font-semibold">Quantity</p>
                            <p className="text-slate-600">{item.Quantity}</p>
                        </div>
                        <div className="flex-1 p-4 bg-slate-50 items-center justify-center rounded-lg text-center">
                            <p className="font-semibold">Price</p>
                            <p className="text-slate-600">{item.Price}</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    {/* <QRCode value={item.SKU} /> */}
                    <QRCodeSVG 
                        value={item.SKU} 
                        className="h-full aspect-square bg-slate-100 rounded-xl max-w-xs max-h-64"
                        level="H"
                        width={256}
                        height={256}
                    />
                    <p className="text-xs text-slate-500 mt-2">{item.SKU}</p>
                </div>
            </div>
        </div>
    );
};