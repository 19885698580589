import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useFetch } from '../../../../../../hooks/useFetch'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { Text } from '../../../../../base/Text'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { usePost } from '../../../../../../hooks/usePost'
import Select from 'react-select'
import { addNotification } from '../../../../../shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

export const AddRepairScore: React.FC<{ onClose: () => void }> = ({
    onClose,
}) => {
    const [code, setCode] = useState<string>('')
    const [models, setModels] = useState<string[]>([])
    const [point, setPoint] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { sendRequest } = usePost()
    const handleCodeChange = (event: any) => {
        setCode(event.target.value)
    }
    const handleModelChange = (e: any) => {
        setModels(Array.isArray(e) ? e.map((model) => model?.value) : [])

    }

    const handlePointChange = (newValue) => {
        setPoint(newValue)
    }

    const { getRequest: getCodes, data: codesData } = useFetch(
        `${baseUrl}/code/get-codes`
    )

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )

    const { getRequest: getCodePoints } = useFetch(
        `${baseUrl}/codePoint/get-code-point`
    )

    useEffect(() => {
        getCodes()
        getModels()
        getCodePoints()
    }, [getCodes, getModels, getCodePoints])

    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault()
        if (!models) {
            alert('Please, select mobile model')
            return
        } else if (!code) {
            alert('Please, select code ')
            return
        }
        setIsLoading(true)
        const urlItem = `${baseUrl}/codePoint/add-point`
        await sendRequest(
            urlItem,
            {
                code,
                point,
                models,
            },
        )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Repair score added successfully',
                    type: 'success',
                })
                onClose()
            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data}`, {
                    hideProgressBar: true,
                })
                setIsLoading(false)
            })
    }, [code, models, onClose, point, sendRequest])
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            position: "relative",
            boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : null,
            '&:hover': {
                borderColor: '#2684FF',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#2684FF',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: state.isSelected ? '#2684FF' : state.isFocused ? '#f0f0f0' : 'white',
            color: state.isSelected ? 'white' : '#333',
            '&:hover': {
                backgroundColor: '#2684FF',
                color: 'white',
            },
        }),
    };
    return (
        <ModalBox
            heading="Add new repair score"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3  gap-y-4 ">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Code
                    </Text>
                    <div >
                        <select
                            id="model"
                            onChange={(e) => handleCodeChange(e)}
                            className="w-56 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-2"
                        >
                            <option value="" className="bg-b9green1">
                                {code ? code : 'Select code'}
                            </option>
                            {codesData?.map((code: any) => (
                                <option key={code?._id} value={code?._id}>
                                    {code?.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Point
                    </Text>
                    <TextField
                        value={point}
                        onChange={handlePointChange}
                        placeholder="Enter point"
                        className='w-56'
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <div className="w-56">
                        <Select
                            styles={customStyles}
                            options={modelsData?.map((model) => ({ label: model.modelName, value: model._id }))}
                            onChange={handleModelChange}
                            isMulti
                            maxMenuHeight={70}
                        />
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' backgroundColor="lgblue" onClick={handleSubmit}>
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? "Saving.." : "Save"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
