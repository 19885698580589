import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TopHeader from '../components/TopHeader'
import { toast } from 'react-toastify'
import { useAuth } from '../utils/auth'
import back from '../assets/back.png'
import { storageList } from '../constant/Storage'
import { useFetch } from '../hooks/useFetch'
import { addNotification } from '../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md w-96 mt-3 appearance-none relative block  px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

export default function EditSheet() {
    const [orderNumber, setOrderNumber] = useState('')
    const [model, setModel] = useState<string[]>([])
    const [models, setModels] = useState<string[]>([])
    const [storage, setStorage] = useState('')
    const [unitPrice, setUnitPrice] = useState('')
    const [imei, setImei] = useState('')
    const [issueDescription, setIssueDescription] = useState('')
    const [acceptance, setAcceptance] = useState('')
    const [comment, setComment] = useState('')
    const [item, setItem] = useState<any>()
    const { id } = useParams()
    const sheetId = localStorage.getItem('sheetId')
    const navigate = useNavigate()
    const auth = useAuth()
    const token = auth?.user?.token?.token

    const accept: any = localStorage.getItem('acceptance')

    const handleInputChange = (e: any) => {
        e.preventDefault()
        const { id, value } = e.target
        if (id === 'orderNumber') {
            e.preventDefault()
            setOrderNumber(value)
        }
        if (id === 'model') {
            e.preventDefault()
            setModel(value)
        }
        if (id === 'unitPrice') {
            e.preventDefault()
            setUnitPrice(value)
        }
        if (id === 'imei') {
            e.preventDefault()
            setImei(value)
        }
        if (id === 'issueDescription') {
            e.preventDefault()
            setIssueDescription(value)
        }
        if (id === 'comment') {
            e.preventDefault()
            setComment(value)
        }
        if (id === 'dateInvoiced') {
            e.preventDefault()
        }
    }

    const handleStorage = (e: any) => {
        e.preventDefault()
        setStorage(e.target.value)
    }

    const handleAcceptanceChange = (e: any) => {
        setAcceptance(e.target.value)
    }

    const { getRequest: getSheetItems, data: sheetItemsData } = useFetch(
        `${baseUrl}/item/${id}`
    )
    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/getModels`
    )

    useEffect(() => {
        getSheetItems()
        getModels()
    }, [getModels, getSheetItems])
    useEffect(() => {
        setItem(sheetItemsData)
        setModels(modelsData)
        setOrderNumber(sheetItemsData?.orderNumber)
        setImei(sheetItemsData?.imei)
        setModel(sheetItemsData?.model?.modelName)
        setStorage(sheetItemsData?.storage)
        setUnitPrice(sheetItemsData?.unitPrice)
        setIssueDescription(sheetItemsData?.issueDescription)
        setAcceptance(sheetItemsData?.acceptance)
        setComment(sheetItemsData?.comment)
    }, [id, modelsData, sheetItemsData])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        await axios
            .put(
                `${baseUrl}/item/update/${sheetId}`,
                {
                    sheetId,
                    id,
                    orderNumber,
                    model,
                    storage,
                    imei,
                    unitPrice,
                    issueDescription,
                    acceptance,
                    comment,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                addNotification({
                    message: 'Record Updated Successfully',
                    type: 'success',
                })
                toast.success('Record Updated Successfully', {
                    hideProgressBar: true,
                })
            })
            .catch((error) =>
                toast.error(`Error: ${error?.response?.data}`, {
                    hideProgressBar: true,
                })
            )
    }
    const goBack = () => {
        navigate(-1)
    }

    const handleModelChange = (e: any) => {
        e.preventDefault()
        setModel(e.target.value)
    }

    return (
        <>
            <TopHeader />
            <div className=" mt-5 flex items-center flex-col">
                <div
                    className="bg-b9green1 rounded-lg overflow-x-auto p-10 align-center"
                    style={{ width: 500 }}
                >
                    <div className="overflow-x-auto">
                        <div className="flex">
                            <div className="mr-5">
                                <button type="button" onClick={goBack}>
                                    <img
                                        src={back}
                                        className="h-6 w-6 h-5 max-w-sm"
                                        alt="edit"
                                    />
                                </button>
                            </div>

                            <div>
                                <h1 className="font-bold text-xl mb-3">
                                    Edit mobile device
                                </h1>
                            </div>
                        </div>
                        <form className=" my-5 space-y-6">
                            <div className="form-body">
                                <div className="my-3">
                                    <label className="">Order Number </label>
                                    <input
                                        type="text"
                                        id="orderNumber"
                                        className={fixedInputClass}
                                        value={orderNumber}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={item?.orderNumber}
                                    />
                                </div>
                                <div className="my-3">
                                    <label className="mr-10">Model </label>
                                    <div className="">
                                        <select
                                            id="model"
                                            onChange={(e) =>
                                                handleModelChange(e)
                                            }
                                            className={fixedInputClass}
                                        >
                                            <option
                                                value={model ? model : ''}
                                                className="bg-b9green1"
                                            >
                                                {model ? model : 'Model'}
                                            </option>
                                            {models?.map((mdl: any) => (
                                                <option
                                                    key={mdl?._id}
                                                    value={mdl?._id}
                                                >
                                                    {mdl?.modelName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="storage mb-3 justify-between">
                                    <label className="mr-10">Storage </label>
                                    <div className="mt-2">
                                        <select
                                            id="vat"
                                            defaultValue={storage}
                                            onChange={(e) => handleStorage(e)}
                                            className={fixedInputClass}
                                        >
                                            <option
                                                value={storage ? storage : ''}
                                            >
                                                {storage
                                                    ? storage
                                                    : 'Select storage'}
                                            </option>
                                            {storageList.map((storage) => (
                                                <option
                                                    key={storage.value}
                                                    value={storage.value}
                                                >
                                                    {storage.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="unitPrice my-3 ">
                                    <label className="mr-10">Unit Price </label>
                                    <input
                                        type="text"
                                        id="unitPrice"
                                        value={unitPrice}
                                        className={fixedInputClass}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={item?.unitPrice}
                                    />
                                </div>
                                <div className="imei ">
                                    <label className="">Imei </label>
                                    <input
                                        type="text"
                                        id="imei"
                                        value={imei}
                                        className={fixedInputClass}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={item?.imei}
                                    />
                                </div>
                                <div className="issueDescription my-5 ">
                                    <label className="mr-10">
                                        Issue Description{' '}
                                    </label>
                                    <input
                                        type="text"
                                        id="issueDescription"
                                        value={issueDescription}
                                        className={fixedInputClass}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={item?.issueDescription}
                                    />
                                </div>
                                <div className="my-5 flex justify-between">
                                    <select
                                        id="acceptance"
                                        defaultValue={acceptance}
                                        onChange={(e) =>
                                            handleAcceptanceChange(e)
                                        }
                                        className="w-96 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2.5 px-1"
                                    >
                                        <option
                                            className="bg-b9green1"
                                            value={' '}
                                        >
                                            {accept === ' '
                                                ? 'Acceptance'
                                                : accept}
                                        </option>
                                        {accept === 'Accepted' ? (
                                            <option value="Declined">
                                                Declined
                                            </option>
                                        ) : accept === 'Declined' ? (
                                            <option value="Accepted">
                                                Accepted
                                            </option>
                                        ) : (
                                            <>
                                                <option value="Declined">
                                                    Declined
                                                </option>
                                                <option value="Accepted">
                                                    Accepted
                                                </option>
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className="comment my-5 ">
                                    <label className="mr-10">Comment </label>
                                    <textarea
                                        name="comment"
                                        id="comment"
                                        value={comment}
                                        className={fixedInputClass}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={item?.comment}
                                    />
                                </div>
                            </div>
                            <div className="flex ">
                                {/* <button
                type="button"
                onClick={goBack}
                className="relative mr-10 justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-5"
              >
                Back
              </button> */}
                                <button
                                    className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-5"
                                    onClick={handleSubmit}
                                    type="submit"
                                >
                                    Update Record
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
