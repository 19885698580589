import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFetch } from '../../../../../../../hooks/useFetch';
import { useAuth } from '../../../../../../../utils/auth';
import { Button } from '../../../../../../base/Button';
import { ModalBox } from '../../../../../../base/ModelBox';
import { TextField } from '../../../../../../base/Textfield';
import { Column } from '../../../../../../layout/Col';
import { Flex } from '../../../../../../layout/Flex';
import { Text } from '../../../../../../base/Text';
import { RowAlignCenterSpaced } from '../../../../../../layout/RowAlignCenterSpaced';
import { sortingLogicEventType } from '../../../../../../../constant/SortingLogicEvent';
import { useEdit } from '../../../../../../../hooks/useEdit';
import { addNotification } from '../../../../../../shared/Notification/Notification';

const baseUrl = process.env.REACT_APP_baseUrl;

export const RenameManualSortingEvent: React.FC<{ onClose: () => void, eventData: any, eventTypes }> = ({ onClose, eventData, eventTypes, }) => {
    const [name, setName] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const auth = useAuth();
    const token = auth?.user?.token?.token;
const {sendEditRequest} = useEdit()
    const handleEventNameChange = (newValue: any) => {
        setName(newValue);
    };

    const handleEventTypeChange = (event: any) => {
        setType(event.target.value);
    };

    useEffect(() => {
        setName(eventData?.name)
        setType(eventData?.type)
    }, [eventData, eventData.name, eventData.type])

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!name) {
            alert('Please, add event Name');
            return;
        } else if (!type) {
            alert('Please, add event type');
            return;
        }
        setIsLoading(true);
        const urlItem = `${baseUrl}/sorting-logic/edit-manual-sorting-event/${eventData?.id}`;
        try {
            const response = await sendEditRequest(
                urlItem,
                {
                    name,
                    type,
                },
            );

            if (response.status === 200) {
                toast.success('Updated Successfully', { hideProgressBar: true });

                addNotification({
                    message: 'Event has been updated successfully',
                    type: 'success',
                });
                onClose();
            } else {
                toast.error(`Error: ${response.data.error || 'Something went wrong'}`, {
                    hideProgressBar: true,
                });
            }
        } catch (error) {
            toast.error(`Error: ${error.response?.data || 'Something went wrong'}`, {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <ModalBox
            heading="Edit manual sorting event"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[180px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Status name
                    </Text>
                    <TextField
                        value={name}
                        onChange={handleEventNameChange}
                        placeholder="Enter event name"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Stauts
                    </Text>
                    <select
                        id="key"
                        value={type}
                        onChange={handleEventTypeChange}
                        className=" w-64 hover:cursor-pointer text-sm h-10  px-4"
                    >
                        <option>Select</option>
                        {sortingLogicEventType?.map((event: any) => (
                            <option key={event.title} value={event.value}>
                                {event.title}
                            </option>
                        ))}
                    </select>

                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button
                        className='w-32'
                        backgroundColor="lgblue"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Saving...' : 'Save'}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    );
};
