/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthLayout } from './pages/layouts/AuthLayout'
import { MainLayout } from './pages/layouts/MainLayout'
import AddItem from './components/AddItem'
import TopHeader from './components/TopHeader'
import BatchOverview from './components/compound/itemInventory/BatchOverview'
import ItemInventory from './components/compound/itemInventory/itemInventory'
import './index.css'
import ChangePassword from './pages/ChangePassword'
import Dashboard from './pages/Dashboard'
import DownloadTemplate from './pages/DownloadTemplate'
import EditSheet from './pages/EditSheet'
import EditStatus from './pages/EditStatus'
import EditUser from './pages/EditUser'
import ForgetPassword from './pages/ForgetPassword'
import History from './pages/History'
import { InsertStock } from './pages/InsertStock'
import Inventory from './pages/Inventory'
import LoginPage from './pages/Login'
import Management from './pages/Management'
import { NotFound } from './pages/NotFound'
import Profile from './pages/Profile'
import Reports from './pages/Reports'
import ResetPassword from './pages/ResetPassword'
import Rma from './pages/Rma'
import Sales from './pages/Sales'
// import SheetDetails from './pages/SheetDetail'
import Sheets from './pages/Sheets'
import CreateUser from './pages/Signup'
import Stock from './pages/Stock'
import UploadFile from './pages/UploadFile'
import Users from './pages/Users'
import VerifyCode from './pages/VerifyCode'
import ImeiDetails from './pages/invoice/ImeiDetails'
import InvoicesImei from './pages/invoice/InvoicesImei'
import UploadImei from './pages/invoice/UploadImei'
import { UpdateStock } from './pages/updateStock'
import { AuthProvider, useAuth } from './utils/auth'
import { RequireAuth } from './utils/requireAuth'
import Production from './pages/Production'
import Stocktaking from './pages/Stocktaking'
import { Logout } from './pages/Logout'
import { ItemsRma } from './components/compound/Rma/CustomerItemsRma/ItemsRma'
import CustomerHome from './pages/CustomerHome'
import { CustomerLayout } from './pages/layouts/CustomerLayout'
import { Purchase } from './pages/Purchase'
// import { CustomerRMA } from './pages/CustomerRMA'
import { MyCustomerRmaItem } from './pages/MyCustomerRmaItem'
import { CustomerCreditNote } from './components/customerPortal/CustomerRma/CustomerCreditNote/CustomerCreditNote'
import { Product } from './pages/Product'
import { ProductLayout } from './pages/layouts/ProductLayout'
// import { AddUser } from './pages/AddUser'
import Repair from './components/compound/Production/Repair/Repair'
import Inhouse from './components/compound/Inventory/Inhouse/Inhouse'
import { InventoryLayout } from './pages/layouts/InventoryLayout'
import { SalesLayout } from './pages/layouts/SalesLayout'
import { SaleInventory } from './components/compound/Sales/SaleInventory/SaleInventory'
import { PointOfSale } from './components/compound/Sales/PointOfSale/PointOfSale'
import { PurchaseOrders } from './components/compound/Sales/PurchaseOrders/PurchaeOrders'
import { ManagementLayout } from './pages/layouts/ManagementLayout'
import { DashboardReports } from './components/compound/Managment/DashboardReports/DashboardReports'
import { ManageProduction } from './components/compound/Managment/ManageProduction/ManageProduction'
import { ManageSKU } from './components/compound/Managment/ManageSKU/ManageSKU'
import { UserAccess } from './components/compound/Managment/UserAccess/UserAccess'
import { ManageTemplate } from './components/compound/Managment/ManageTemplate/ManageTemplate'
import { RmaLayout } from './pages/layouts/RmaLayout'
import { CustomerRma } from './components/compound/Rma/CustomerRma/CustomerRma'
import { CreditNotes } from './components/compound/Rma/CreditNotes/CreditNotes'
import { MyRma } from './components/compound/Rma/MyRma/MyRma'
import { StocktakingLayout } from './pages/layouts/StocktakingLayout'
import { Script } from './components/compound/Stocktaking/Script/Script'
import { StocktakingReport } from './components/compound/Stocktaking/StocktakingReport/StocktakingReport'
import { ProductionLayout } from './pages/layouts/ProductionLayout'
import RepairDetail from './components/compound/Production/Repair/components/RepairDetail'
import RepairLayout from './pages/layouts/RepairLayout'
import { Grading } from './components/compound/Production/Grading/Grading'
import { ManualSorting } from './components/compound/Production/Manual_sorting/ManualSorting'
import { Polishing } from './components/compound/Production/Polishing/Polishing'
import { Diagnostics } from './components/compound/Production/Diagnostics/Diagnostics'
import { Transit } from './components/compound/Production/Transit/Transit'
import Spareparts from './components/compound/Inventory/Spareparts/Spareparts'
import { CustomerRmaLayout } from './pages/layouts/CustomerRmaLayout'
import { CustomerMyRMA } from './pages/CustomerMyRMA'
import { CustomerMyCreditNotes } from './pages/CustomerMyCreditNotes'
import { CustomerMyRMAItem } from './pages/CustomerMyRMAItem'
import { UserManagement } from './pages/UserManagement'
import { ArchiveRma } from './components/compound/Rma/Archive/ArchiveRma'
import SaleItemsTable from './components/compound/Sales/ItemsSale/SaleItemsTable'
import PurchaseItemsTable from './components/compound/Sales/ItemsPurchaseOrder/PurchaseItemsTable'
import { CustomerPurchase } from './components/customerPortal/Purchase/CustomerPurchase'
import { CustomerPurchaseItemsTable } from './components/customerPortal/Purchase/CustomerItemsPurchaseOrder/component/CustomerPurchaseItemsTable'
import { Permissions, Role } from './constant/Storage'
import { Invoice } from './components/compound/Inventory/invoice/Invoice'
import Warranty from './components/compound/Production/Warranty/Warranty'
import WarrantyLayout from './pages/layouts/WarrentyLayout '
import WarrantyDetail from './components/compound/Production/Warranty/components/WarrantyDetail'
// import CustomerCreditNoteTable from './components/customerPortal/CustomerRma/CustomerCreditNote/CustomerCreditNoteTable'
// import { Purchase } from './pages/Purchase'
// import { CustomerRMA } from './pages/CustomerRMA'
import { useIdleTimer } from 'react-idle-timer';
import SaleItems from './components/compound/Sales/ItemsSale/SaleItems'
import { AccessEnums, AccessPermissions } from './constant/UserAccess'
import MyRMAArchiveTable from './components/compound/Rma/MyRma/Archive/MyRMAArchiveTable'
import { CreditNoteArchive } from './components/compound/Rma/CreditNotes/Archive/CreditNotesArchive'

function App() {
    const auth = useAuth()
    const idleTimeout = 90 * 60 * 1000;
    const onIdle = () => {
        auth.logout()
    };
    useIdleTimer({
        timeout: idleTimeout,
        onIdle: onIdle,
        debounce: 500
    });
    const isManagement = auth?.selectedCompany?.permissions?.includes(Permissions.management)
    const isRma = auth?.selectedCompany?.permissions?.includes(Permissions.rma)
    const isInventory = auth?.selectedCompany?.permissions?.includes(Permissions.inventory)
    const isCustomer = auth?.selectedCompany?.permissions?.includes(Permissions.customerPortal) || auth?.user?.user?.role.includes("customer")
    const isMyRma = auth?.selectedCompany?.permissions?.includes(Permissions.myRma)
    const isSales = auth?.selectedCompany?.permissions?.includes(Permissions.sales)
    const isStocktaking = auth?.selectedCompany?.permissions?.includes(Permissions.stocktaking)
    const isGrading = auth?.selectedCompany?.permissions?.includes(Permissions.grading)
    const isManualSorting = auth?.selectedCompany?.permissions?.includes(Permissions.manualSorting)
    const isDiagnostics = auth?.selectedCompany?.permissions?.includes(Permissions.diagnostics)
    const isTransit = auth?.selectedCompany?.permissions?.includes(Permissions.transit)

    const isPolishing = auth?.selectedCompany?.permissions?.includes(Permissions.polishing)
    const isRepair = auth?.selectedCompany?.permissions?.includes(Permissions.repair)
    const dashboardCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Dashboard && acc.permission.includes(AccessPermissions.Admin));
    const skuCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageSKU && acc.permission.includes(AccessPermissions.Admin));
    const templateCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManageTemplates && acc.permission.includes(AccessPermissions.Admin));
    const userCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Users && acc.permission.includes(AccessPermissions.Admin));
    const repairCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Repair && acc.permission.includes(AccessPermissions.Admin))
    const gradingCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Grading && acc.permission.includes(AccessPermissions.Admin))
    const diagnosticsCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Diagnostics && acc.permission.includes(AccessPermissions.Admin))
    const manualSortingCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.ManualSorting && acc.permission.includes(AccessPermissions.Admin))
    const polishingCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Polishing && acc.permission.includes(AccessPermissions.Admin))
    const transitCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Transit && acc.permission.includes(AccessPermissions.Admin))
    const isSuperAdmin = auth?.user?.role?.includes('superAdmin')
    const isAdmin = auth?.user?.role?.includes('admin')
    const scriptCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.Script && acc.permission.includes(AccessPermissions.Admin))
    const stockingReportCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.StocktakingReport && acc.permission.includes(AccessPermissions.Admin))
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <AuthLayout>
                            <LoginPage />
                        </AuthLayout>
                    }
                />
                {isCustomer && (
                    <>
                        <Route
                            path="/home"
                            element={
                                <RequireAuth>
                                    <CustomerLayout>
                                        <CustomerHome />
                                    </CustomerLayout>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/purchase"
                            element={
                                <RequireAuth>
                                    <CustomerLayout>
                                        <Purchase />
                                    </CustomerLayout>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/customer-purchase-order/purchase-order-details/:id"
                            element={
                                <RequireAuth>
                                    <CustomerLayout>
                                        <CustomerPurchaseItemsTable />
                                    </CustomerLayout>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/rma-customer"
                            element={
                                <RequireAuth>

                                    <CustomerRmaLayout>
                                        <CustomerMyRMA />
                                    </CustomerRmaLayout>
                                </RequireAuth>

                            }
                        />
                        <Route
                            path="/rma-customer/credit-notes-customer"
                            element={
                                <RequireAuth>

                                    <CustomerRmaLayout>
                                        <CustomerMyCreditNotes />
                                    </CustomerRmaLayout>
                                </RequireAuth>

                            }
                        />
                        <Route
                            path="/rma/detail/:id"
                            element={
                                <RequireAuth>

                                    <CustomerRmaLayout>
                                        <CustomerMyRMAItem />
                                    </CustomerRmaLayout>
                                </RequireAuth>

                            }
                        />
                    </>
                )}
                {isAdmin && (
                    <>
                        <Route
                            path="/users"
                            element={
                                <RequireAuth>
                                    <Users />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/createUser"
                            element={
                                <RequireAuth>
                                    <CreateUser />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/uploadimei"
                            element={
                                <RequireAuth>
                                    <UploadImei />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/invoices"
                            element={
                                <RequireAuth>
                                    <InvoicesImei />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/imei/:id"
                            element={
                                <RequireAuth>
                                    <ImeiDetails />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/item/batch"
                            element={
                                <RequireAuth>
                                    <BatchOverview />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/user/edit/:id"
                            element={
                                <RequireAuth>
                                    <EditUser />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/insertStock"
                            element={
                                <RequireAuth>
                                    <InsertStock />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/editStock/:id"
                            element={
                                <RequireAuth>
                                    <UpdateStock />
                                </RequireAuth>
                            }
                        />
                    </>
                )}
                {(isRma || isInventory || isAdmin) && (
                    <>
                        <Route
                            path="/item/repair/:id"
                            element={
                                <RequireAuth>
                                    <Repair />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/history"
                            element={
                                <RequireAuth>
                                    <History />
                                </RequireAuth>
                            }
                        />
                    </>
                )}
                {(isRma || isAdmin || isCustomer) && (
                    <Route
                        path="/sheets"
                        element={
                            <RequireAuth>
                                <Sheets />
                            </RequireAuth>
                        }
                    />
                )}

                {(isRma || isAdmin) && (
                    <>
                        <Route
                            path="/detail/addItem/:id"
                            element={
                                <RequireAuth>
                                    <AddItem />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/rma/manage-customer-rma/detail/:id"
                            element={
                                <RequireAuth>
                                    <RmaLayout>
                                        <ItemsRma />
                                    </RmaLayout>
                                </RequireAuth>

                            }
                        />
                        <Route
                            path="/detail/:id"
                            element={
                                <RequireAuth>
                                    <CustomerLayout>
                                        <MyCustomerRmaItem />
                                    </CustomerLayout>
                                </RequireAuth>

                            }
                        />

                        <Route
                            path="/status/edit/:id"
                            element={
                                <RequireAuth>
                                    <EditStatus />
                                </RequireAuth>
                            }
                        />
                    </>
                )}
                {isAdmin && (
                    <>
                        <Route
                            path="/detail/edit/:id"
                            element={
                                <RequireAuth>
                                    <EditSheet />
                                </RequireAuth>
                            }
                        />
                    </>
                )}
                {(isInventory || isRma || isAdmin) && (
                    <>
                        <Route
                            path="/inventory/item/:id"
                            element={
                                <RequireAuth>
                                    <ItemInventory />
                                </RequireAuth>
                            }
                        />

                        {/* <Route
                            path="/item/transit"
                            element={
                                <RequireAuth>
                                    <Transit />
                                </RequireAuth>
                            }
                        /> */}
                        <Route
                            path="/stock"
                            element={
                                <RequireAuth>
                                    <Stock />
                                </RequireAuth>
                            }
                        />
                    </>
                )}
                {(isAdmin || isRma) && (
                    <Route
                        path="/detail/addItem"
                        element={
                            <RequireAuth>
                                <AddItem />
                            </RequireAuth>
                        }
                    />
                )}
                {(isCustomer || isRma || isAdmin) && (
                    <>
                        <Route
                            path="/uploadFile"
                            element={
                                <RequireAuth>
                                    <UploadFile />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/customer-credit-notes"
                            element={
                                <RequireAuth>
                                    <CustomerLayout>
                                        <CustomerCreditNote />
                                    </CustomerLayout>
                                </RequireAuth>
                            }
                        />
                    </>
                )}
                {(isAdmin || isInventory) && (
                    <Route
                        path="/reports"
                        element={
                            <RequireAuth>
                                <Reports />
                            </RequireAuth>
                        }
                    />
                )}
                <Route
                    path="/template"
                    element={
                        <RequireAuth>
                            <DownloadTemplate />
                        </RequireAuth>
                    }
                />
                {isSuperAdmin && (
                    <>
                        <Route
                            path="/home"
                            element={
                                <RequireAuth>
                                    <ProductLayout>
                                        <Product />
                                    </ProductLayout>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/user-management"
                            element={
                                <RequireAuth>
                                    <ProductLayout>
                                        <UserManagement />
                                    </ProductLayout>
                                </RequireAuth>
                            }
                        />
                        {/* <Route
                    path="/user-management/add-user"
                    element={
                        <ProductLayout>
                            <AddUser />
                        </ProductLayout>
                    }
                /> */}
                    </>
                )}

                <Route
                    path="/header"
                    element={
                        <RequireAuth>
                            <TopHeader />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <RequireAuth>
                            <Profile />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/changePassword"
                    element={
                        <AuthLayout>
                            <ChangePassword />
                        </AuthLayout>
                    }
                />
                <Route
                    path="/resetPassword/:id"
                    element={
                        <AuthLayout>
                            <ResetPassword />
                        </AuthLayout>
                    }
                />
                <Route
                    path="/forgetPassword"
                    element={
                        <AuthLayout>
                            <ForgetPassword />
                        </AuthLayout>
                    }
                />
                <Route
                    path="/verifyCode"
                    element={
                        <AuthLayout>
                            <VerifyCode />
                        </AuthLayout>
                    }
                />
                <Route path="/404" element={<LoginPage />} />
                <Route path="*" element={<LoginPage />} />
                <Route
                    path="/dashboard"
                    element={
                        <RequireAuth>
                            <MainLayout>
                                <Dashboard />
                            </MainLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/logout"
                    element={
                        <RequireAuth>
                            <MainLayout>
                                <Logout />
                            </MainLayout>
                        </RequireAuth>
                    }
                />

                {isInventory && <Route
                    path="/inventory"
                    element={
                        <RequireAuth>
                            <InventoryLayout>
                                <Inventory />
                            </InventoryLayout>
                        </RequireAuth>
                    }
                >
                    <Route
                        path="inhouse"
                        element={
                            <RequireAuth>
                                <Inhouse />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="spareparts"
                        element={
                            <RequireAuth>
                                <Spareparts />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="invoice"
                        element={
                            <RequireAuth>
                                <Invoice />
                            </RequireAuth>
                        }
                    />
                </Route>}

                {isSales && <Route
                    path="/sales"
                    element={
                        <RequireAuth>
                            <SalesLayout>
                                <Sales />
                            </SalesLayout>
                        </RequireAuth>
                    }
                >
                    <Route
                        path="sales-inventory"
                        element={
                            <RequireAuth>
                                <SaleInventory />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="point-of-sale"
                        element={
                            <RequireAuth>
                                <PointOfSale />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="sales-order"
                        element={
                            <RequireAuth>
                                <PurchaseOrders />
                            </RequireAuth>
                        }
                    />
                </Route>
                }
                {isSales && <Route
                    path="/sales/sales-inventory/sale-items/:id"
                    element={
                        <RequireAuth>
                            <SalesLayout>
                                <SaleItems />
                            </SalesLayout>
                        </RequireAuth>
                    }
                />}
                {isSales && <Route
                    path="/sales/sales-order/sale-order-items/:id"
                    element={
                        <RequireAuth>
                            <SalesLayout>
                                <PurchaseItemsTable />
                            </SalesLayout>
                        </RequireAuth>
                    }
                />}
                {isManagement && <Route
                    path="/management"
                    element={
                        <RequireAuth>
                            <ManagementLayout>
                                <Management />
                            </ManagementLayout>
                        </RequireAuth>
                    }
                >
                    {dashboardCheck && (
                        <Route
                            path="dashboards-reports"
                            element={
                                <RequireAuth>
                                    <DashboardReports />
                                </RequireAuth>
                            }
                        />
                    )}
                    <Route
                        path="manage-production"
                        element={
                            <RequireAuth>
                                <ManageProduction />
                            </RequireAuth>
                        }
                    />
                    {skuCheck && (
                        <Route
                            path="manage-sku"
                            element={
                                <RequireAuth>
                                    <ManageSKU />
                                </RequireAuth>
                            }
                        />
                    )}
                    {userCheck && (
                        <Route
                            path="users"
                            element={
                                <RequireAuth>
                                    <UserAccess />
                                </RequireAuth>
                            }
                        />
                    )}
                    {/* {templateCheck && (
                        <Route
                            path="manage-template"
                            element={
                                <RequireAuth>
                                    <ManageTemplate />
                                </RequireAuth>
                            }
                        />
                    )} */}
                </Route>}
                {
                    isRma &&
                    <Route
                        path="/rma"
                        element={
                            <RequireAuth>
                                <RmaLayout>
                                    <Rma />
                                </RmaLayout>
                            </RequireAuth>
                        }
                    >
                        <Route
                            path="manage-customer-rma"
                            element={
                                <RequireAuth>
                                    <CustomerRma />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="archive-customer-rma"
                            element={
                                <RequireAuth>
                                    <ArchiveRma />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="manage-my-rma/archive"
                            element={
                                <RequireAuth>
                                    <MyRMAArchiveTable />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="credit-notes"
                            element={
                                <RequireAuth>
                                    <CreditNotes />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="credit-notes/archive"
                            element={
                                <RequireAuth>
                                    <CreditNoteArchive />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="manage-my-rma"
                            element={
                                <RequireAuth>
                                    <MyRma />
                                </RequireAuth>
                            }
                        />
                    </Route>
                }
                {
                    isStocktaking &&

                    <Route
                        path="/stocktaking"
                        element={
                            <RequireAuth>
                                <StocktakingLayout>
                                    <Stocktaking />
                                </StocktakingLayout>
                            </RequireAuth>
                        }
                    >
                        {scriptCheck && (
                            <Route
                                path="script"
                                element={
                                    <RequireAuth>
                                        <Script />
                                    </RequireAuth>
                                }
                            />
                        )}
                        {stockingReportCheck && (
                            <Route
                                path="stocktaking-report"
                                element={
                                    <RequireAuth>
                                        <StocktakingReport />
                                    </RequireAuth>
                                }
                            />
                        )}
                    </Route>
                }
                <Route
                    path="/production"
                    element={
                        <RequireAuth>
                            <ProductionLayout>
                                <Production />
                            </ProductionLayout>
                        </RequireAuth>
                    }
                >
                    <Route path="repair" element={<RepairLayout />}>
                        <Route
                            path=""
                            element={
                                <RequireAuth>
                                    <Repair />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="repair-detail/:id"
                            element={
                                <RequireAuth>
                                    <RepairDetail />
                                </RequireAuth>
                            }
                        />
                    </Route>
                    <Route
                        path="warranty"
                        element={
                            <RequireAuth>
                                <WarrantyLayout />
                            </RequireAuth>
                        }
                    >
                        <Route
                            path=""
                            element={
                                <RequireAuth>
                                    <Warranty />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="warranty-detail/:id"
                            element={
                                <RequireAuth>
                                    <WarrantyDetail />
                                </RequireAuth>
                            }
                        />
                    </Route>
                    {isGrading && gradingCheck && (
                        <Route
                            path="grading"
                            element={
                                <RequireAuth>
                                    <Grading />
                                </RequireAuth>
                            }
                        />
                    )}
                    {isDiagnostics && diagnosticsCheck && (
                        <Route
                            path="diagnostics"
                            element={
                                <RequireAuth>
                                    <Diagnostics />
                                </RequireAuth>
                            }
                        />
                    )}
                    {isTransit && transitCheck &&(
                        <Route
                            path="transit"
                            element={
                                <RequireAuth>
                                    <Transit />
                                </RequireAuth>
                            }
                        />
                    )}

                    {isManualSorting && manualSortingCheck && (
                        <Route
                            path="manual-sorting"
                            element={
                                <RequireAuth>
                                    <ManualSorting />
                                </RequireAuth>
                            }
                        />
                    )}
                    {isPolishing && polishingCheck && (
                        <Route
                            path="polishing"
                            element={
                                <RequireAuth>
                                    <Polishing />
                                </RequireAuth>
                            }
                        />
                    )}
                    {isRepair && repairCheck && (
                        <Route
                            path="repair-detail"
                            element={
                                <RequireAuth>
                                    <RepairDetail />
                                </RequireAuth>
                            }
                        />
                    )}
                </Route>
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    )
}

const AppWrapper = () => {
    return (
        <AuthProvider>
            <App />
        </AuthProvider>
    )
}
export default AppWrapper
