import axios from 'axios'
import JsBarcode from 'jsbarcode'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import back from '../assets/back.png'
import { storageList } from '../constant/Storage'
import { useAuth } from '../utils/auth'
import TopHeader from './TopHeader'

import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { Events } from '../constants/eventEnum'
import { useFetch } from '../hooks/useFetch'
import { addNotification } from './shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md w-96 mt-2 appearance-none relative block  px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

;(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}

export default function AddItem() {
    const [orderNumber, setOrderNumber] = useState('')
    const [models, setModels] = useState<string[]>([])
    const [model, setModel] = useState('')
    const [modelName, setModelName] = useState('')
    const [storage, setStorage] = useState('')
    const [unitPrice, setUnitPrice] = useState('')
    const [imei, setImei] = useState('')
    const [vat, setVat] = useState('')
    const [codes, setCodes] = useState<string[]>([])
    const [event, setEvent] = useState('')
    const [purchasePrice, setPurchasePrice] = useState('')
    // const [allCodeEvents, setAllCodeEvents] = useState<string[]>([]);
    const [battery, setBattery] = useState(0)
    const [codeEvents, setCodeEvents] = useState<any[]>([])
    const [codeDescription, setCodeDescription] = useState<any>()
    const [issueDescription, setIssueDescription] = useState('')

    const { id } = useParams()
    const navigate = useNavigate()
    const auth = useAuth()
    const barCode = useRef<HTMLDivElement>(null)
    const barCodeSvg = barCode.current?.innerHTML

    const token = auth?.user?.token?.token
    const handleInputChange = (e: any) => {
        e.preventDefault()
        const { id, value } = e.target
        if (id === 'orderNumber') {
            e.preventDefault()
            setOrderNumber(value)
        }
        if (id === 'unitPrice') {
            e.preventDefault()
            setUnitPrice(value)
        }
        if (id === 'imei') {
            e.preventDefault()
            setImei(value)
            handleImeiSearch(value)
        }
        if (id === 'issueDescription') {
            e.preventDefault()
            setIssueDescription(value)
        }
        if (id === 'purchasePrice') {
            e.preventDefault()
            setPurchasePrice(value)
        }
        if (id === 'battery') {
            e.preventDefault()
            setBattery(value)
        }
    }

    const handleImeiSearch = (value) => {
        const token = auth.user?.token?.token
        axios
            .post(
                `${baseUrl}/invoice/search`,
                {
                    searchImei: value,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then((response) => {
                if (response?.data) {
                    setModel(response?.data?.model)
                    setImei(response?.data?.imeiNumber)
                    setStorage(response?.data?.capacity)
                    setUnitPrice(response?.data?.unitPrice)
                }
            })
    }
    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/getModels`
    )
    const { getRequest: getAllCodeEvents, data: allCodeEventsData } = useFetch(
        `${baseUrl}/code-event/getEvents`
    )

    useEffect(() => {
        getModels()
        getAllCodeEvents()
    }, [getModels, getAllCodeEvents])

    useEffect(() => {
        setModels(modelsData)
    }, [modelsData, allCodeEventsData])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, add Model')
            return
        } else if (!imei) {
            alert('Please, add IMEI')
            return
        } else if (!vat) {
            alert('Please, add vat')
            return
        } else if (!storage) {
            alert('Please, add storage')
            return
        } else if (battery > 100) {
            alert('Enter battery below 100')
            return
        } else if (!unitPrice) {
            alert('Please, add unit price')
            return
        } else if (!purchasePrice) {
            alert('Please, add purchase price')
            return
        } else if (codes.length === 0) {
            alert('Please, add codes')
            return
        }
        const urlItem = `${baseUrl}/item/addItem/${id}`
        const urlInventoryItem = `${baseUrl}/item/addInventory`
        await axios
            .post(
                id ? urlItem : urlInventoryItem,
                {
                    orderNumber,
                    vat,
                    model,
                    storage,
                    imei,
                    unitPrice,
                    issueDescription,
                    codes,
                    event,
                    purchasePrice,
                    battery,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                addNotification({
                    message: 'Added Successfully',
                    type: 'success',
                })
                toast.success('Added Successfully', { hideProgressBar: true })
            })
            .catch((error) => {
                addNotification({
                    message: `Error: ${error.response.data}`,
                    type: 'error',
                })
                toast.error(`Error: ${error.response.data}`, {
                    hideProgressBar: true,
                })
            })
    }

    const handleVat = (e: any) => {
        e.preventDefault()
        setVat(e.target.value)
    }

    const handleStorage = (e: any) => {
        e.preventDefault()
        setStorage(e.target.value)
    }

    const handleModelChange = (e: any) => {
        e.preventDefault()
        const model = e.target.value.split(',')
        setModel(model[0])
        setModelName(model[1])
    }

    const goBack = () => {
        navigate(-1)
    }
    const { getRequest: getCodeEvents, data: codeEventsData } = useFetch(
        `${baseUrl}/code-event/getEvents`
    )

    const handleCodes = useCallback(
        (e: any) => {
            setCodes(Array.isArray(e) ? e.map((code) => code.label) : [])

            if (codes.length === 0) {
                setEvent('')
            }
        },
        [codes.length]
    )

    const { getRequest: getSheetsDescription, data: sheetDescriptionData } =
        useFetch(`${baseUrl}/code/getCodes`)
    // const { getRequest: getCodeEvents, data: codeEventsData } = useFetch(
    //   `${baseUrl}/view/sheet/code_events`
    // );

    useEffect(() => {
        getSheetsDescription()
        getCodeEvents()
    }, [getCodeEvents, getSheetsDescription])

    useEffect(() => {
        const myCodes = sheetDescriptionData?.map((element) => {
            return { label: element?.label, value: element?.description }
        })
        setCodeDescription(myCodes)
        setCodeEvents(codeEventsData)
    }, [codeEventsData, sheetDescriptionData])
    // useEffect(() => {
    //   setCodeEvents(codeEventsData);
    //   const theEvents = allCodeEventsData?.map((events: any) => {
    //     const updateEvents = {};
    //     updateEvents["code"] = events?.code?.label;
    //     for (const value of events?.modelName || []) {
    //       updateEvents[value?.modelName] = events?.event?.event;
    //     }
    //     return updateEvents;
    //   });
    //   setCodeEvents(theEvents);

    //   const theCode = allCodeEventsData?.map((codes) => {
    //     {
    //       return { label: codes.code?.label, value: codes?.code?.description };
    //     }
    //   });
    //   setCodeDescription(theCode);
    // }, [allCodeEvents, allCodeEventsData, codeEventsData, sheetDescriptionData]);

    useEffect(() => {
        const events: string[] = []
        if (codes?.length > 0) {
            for (const code of codes || []) {
                for (const eventCode of codeEvents || []) {
                    if (eventCode?.code === code) {
                        if (eventCode?.modelName?.includes(modelName)) {
                            events.push(eventCode?.event)
                        }
                    }
                }
            }
            if (events.includes(Events.scrap)) {
                setEvent(Events.scrap)
            } else if (events.includes(Events.warranty)) {
                setEvent(Events.warranty)
            } else if (events.includes(Events.hardRepair)) {
                setEvent(Events.hardRepair)
            } else if (events.includes(Events.mediumRepair)) {
                setEvent(Events.mediumRepair)
            } else if (events.includes(Events.easyRepair)) {
                setEvent(Events.easyRepair)
            } else if (events.includes(Events.return)) {
                setEvent(Events.return)
            } else if (events.includes(Events.salesInventory)) {
                setEvent(Events.salesInventory)
            } else if (events.includes(Events.rma)) {
                setEvent(Events.rma)
            } else if (events.includes(Events.tounlock)) {
                setEvent(Events.tounlock)
            } else if (events.includes(Events.ms_repair)) {
                setEvent(Events.ms_repair)
            }
        } else {
            setEvent('')
        }
        events
    }, [codes?.length, codeEvents, codes, model, modelName])

    useEffect(() => {
        if (imei) {
            JsBarcode('#barcode', `${imei}`, {
                lineColor: 'black',
                textAlign: 'center',
                width: 3.1,
                background: 'transparent',
                height: 40,
                displayValue: false,
                xmlDocument: document,
            })
        }
    }, [imei])

    const printLabel = useCallback(
        async (e: any) => {
            if (codes.length === 0) {
                alert('Please, add codes')
                return
            }
            if (!model) {
                alert('Please, add Model')
                return
            }
            if (!imei) {
                alert('Please, add IMEI')
                return
            }
            if (battery > 100) {
                alert('Enter battery below 100')
                return
            }
            e.preventDefault()
            const urlInventoryItem = `${baseUrl}/item/addInventory`
            await axios
                .post(
                    urlInventoryItem,
                    {
                        orderNumber,
                        vat,
                        model,
                        storage,
                        imei,
                        unitPrice,
                        issueDescription,
                        codes,
                        event,
                        purchasePrice,
                        battery,
                    },
                    {
                        headers: {
                            Authorization: token as string,
                        },
                    }
                )
                .then(() => {
                    toast.success('Added Successfully', {
                        hideProgressBar: true,
                    })
                    addNotification({
                        message: 'Added Successfully',
                        type: 'success',
                    })
                    const dd = {
                        pageMargins: [5, 5, 5, 5],
                        content: [
                            {
                                columns: [
                                    [
                                        {
                                            width: 50,
                                            stack: [
                                                {
                                                    text: `Model :      ${modelName}  ${storage}`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },

                                                {
                                                    text: `Codes :     ${codes}`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `Stauts :      ${event}`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `VAT :         ${vat.slice(
                                                        0,
                                                        1
                                                    )}`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },

                                                {
                                                    text: `Battery :   ${
                                                        battery + ' %'
                                                    }`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `IMEI:   ${imei}`,
                                                    margin: [1, 3],
                                                    fontSize: 11,
                                                    width: 100,
                                                    bold: true,
                                                },
                                                {
                                                    svg: barCodeSvg,
                                                    margin: [1, 3],
                                                    height: 25,
                                                    width: 170,
                                                },
                                            ],
                                        },
                                    ],
                                ],
                            },
                        ],
                    }
                    pdfMake.createPdf(dd as any).print()
                })
                .catch((error) =>   {
                    addNotification({
                        message: `Error: ${error.response.data}`,
                        type: 'error',
                    })
                    toast.error(`Error: ${error.response.data}`, {
                        hideProgressBar: true,
                    })
                }
                )
        },
        [
            barCodeSvg,
            battery,
            codes,
            event,
            imei,
            issueDescription,
            model,
            modelName,
            orderNumber,
            purchasePrice,
            storage,
            token,
            unitPrice,
            vat,
        ]
    )

    return (
        <>
            <TopHeader />
            <div className="flex m-3 items-center flex-col">
                <div
                    className="bg-b9green1 rounded-lg overflow-x-auto p-10 align-center"
                    style={{ width: 500 }}
                >
                    <div className="overflow-x-auto">
                        <div className="mx-5 flex">
                            <button type="button" onClick={goBack}>
                                <img
                                    src={back}
                                    className="h-6 w-6 max-w-sm"
                                    alt="edit"
                                />
                            </button>
                            <div className="ml-5">
                                <h1 className="font-bold text-xl ">
                                    {id ? (
                                        <span>Add new mobile device</span>
                                    ) : (
                                        <span>
                                            Add mobile device inventory{' '}
                                        </span>
                                    )}
                                </h1>
                            </div>
                        </div>

                        <form className="mx-4 my-4 space-y-6">
                            <div className="form-body">
                                {id && (
                                    <div className="mb-3">
                                        <label className="mb-3">
                                            Order Number{' '}
                                        </label>
                                        <input
                                            type="text"
                                            id="orderNumber"
                                            className={fixedInputClass}
                                            value={orderNumber}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            placeholder="Enter Order Number"
                                        />
                                    </div>
                                )}
                                <div className="model mb-3">
                                    <label className="mr-10">Model </label>
                                    <div className="mt-2">
                                        <select
                                            id="model"
                                            onChange={(e) =>
                                                handleModelChange(e)
                                            }
                                            className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-2"
                                        >
                                            <option
                                                value=""
                                                className="bg-b9green1"
                                            >
                                                {model ? model : 'Select model'}
                                            </option>
                                            {models?.map((mdl: any) => (
                                                <option
                                                    key={mdl?._id}
                                                    value={[
                                                        mdl?._id,
                                                        mdl?.modelName,
                                                    ]}
                                                >
                                                    {mdl?.modelName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="storage mb-3 justify-between">
                                    <label className="mr-10">Storage </label>
                                    <div className="mt-2">
                                        <select
                                            id="vat"
                                            defaultValue={storage}
                                            onChange={(e) => handleStorage(e)}
                                            className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2.5 px-1"
                                        >
                                            <option value="">
                                                {storage
                                                    ? storage
                                                    : 'Select storage'}
                                            </option>
                                            {storageList.map((storage) => (
                                                <option key={storage.value} value={storage.value}>
                                                    {storage.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="imei mb-3">
                                    <label className="mr-10">Imei </label>
                                    <input
                                        type="text"
                                        id="imei"
                                        value={imei}
                                        className={fixedInputClass}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Enter IMEI"
                                    />
                                </div>

                                <div className="unitPrice mb-3 justify-between">
                                    <label className="mr-10">Unit Price </label>
                                    <input
                                        type="text"
                                        id="unitPrice"
                                        value={unitPrice}
                                        className={fixedInputClass}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Enter Unit Price"
                                    />
                                </div>
                                {!id && (
                                    <div className="PurchasePrice mb-3 justify-between">
                                        <label className="mr-10">
                                            Purchase price{' '}
                                        </label>
                                        <input
                                            type="text"
                                            id="purchasePrice"
                                            value={purchasePrice}
                                            className={fixedInputClass}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            placeholder="Enter purchase price"
                                        />
                                    </div>
                                )}
                                <div className="issueDescription mb-3">
                                    <div>
                                        <label className="mr-10">
                                            Battery{' '}
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        id="battery"
                                        value={battery}
                                        className="rounded-md mt-2 appearance-none relative  px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                        style={{ width: 360 }}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Enter batter"
                                    />
                                    <span> %</span>
                                </div>
                                <div className="issueDescription mb-3">
                                    <label className="mr-10">
                                        Issue Description{' '}
                                    </label>
                                    <input
                                        type="text"
                                        id="issueDescription"
                                        value={issueDescription}
                                        className={fixedInputClass}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Enter Issue Description"
                                    />
                                </div>
                                <div className="my-3">
                                    <label className="mr-10">Vat </label>
                                    <div className="mt-2">
                                        <select
                                            id="vat"
                                            defaultValue={vat}
                                            onChange={(e) => handleVat(e)}
                                            className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2.5 px-1"
                                        >
                                            <option value="">Select VAT</option>
                                            <option value="Marginal">
                                                Marginal
                                            </option>
                                            <option value="Reverse">
                                                Reverse
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                {!id && (
                                    <div className="my-3">
                                        <label className="">Codes</label>
                                        <div className="mt-2">
                                            <Select
                                                defaultValue=""
                                                options={codeDescription}
                                                onChange={handleCodes}
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                )}
                                {!id && (
                                    <div className=" mb-3">
                                        <label className="">
                                            Stauts :{' '}
                                            <span className="font-semibold ml-3">
                                                {event}
                                            </span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            {!id && (
                                <div ref={barCode} className="py-2 px-2">
                                    <svg id="barcode"></svg>
                                </div>
                            )}

                            <div className="flex mt-5 justify-between">
                                <div className="algin-right">
                                    <button
                                        className="group w-36 relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 "
                                        onClick={handleSubmit}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                                {!id && (
                                    <div>
                                        <button
                                            onClick={printLabel}
                                            className="relative w-36 justify-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 "
                                        >
                                            Save & Print
                                        </button>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
