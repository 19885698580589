import React, { useCallback, useEffect, useState } from 'react'
import { ModalBox } from '../../../../base/ModelBox'
import { Column } from '../../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../base/Text'
import { TextField } from '../../../../base/Textfield'
import { Button } from '../../../../base/Button'
import { Flex } from '../../../../layout/Flex'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useFetch } from '../../../../../hooks/useFetch'
import { useAuth } from '../../../../../utils/auth'
import Select from 'react-select'
import { myCurrency } from '../../../../../constant/Storage'
import { usePost } from '../../../../../hooks/usePost'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

// ExportContent Component
export const AddInventoryContent: React.FC<{ onClose: () => void }> = ({
    onClose,
}) => {
    const [sku, setSku] = useState('')
    const [name, setName] = useState('')
    const [models, setModels] = useState<any>([])
    const [modelVal, setModelVal] = useState<any[]>([])
    const [quantity, setQuantity] = useState<any>()
    const [price, setPrice] = useState<any>()
    const [currency, setCurrency] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const auth = useAuth()
    const { sendRequest } = usePost()
    const token = auth?.user?.token?.token

    const handleSkuChange = (newValue: any) => {
        setSku(newValue)
    }
    const handleNameChange = (newValue: any) => {
        setName(newValue)
    }

    const handleQuantityChange = (newValue: any) => {
        setQuantity(newValue)
    }

    const handlePriceChange = (newValue: any) => {
        setPrice(newValue)
    }

    const handleCurrencyChange = (event: any) => {
        setCurrency(event.target.value)
    }

    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )

    useEffect(() => {
        getModels()
    }, [getModels])

    useEffect(() => {
        const localModel = []
        modelsData?.forEach((element) => {
            localModel.push({ label: element?.modelName, value: element?._id })
        })
        setModels(localModel)
    }, [modelsData])

    const handleModels = useCallback((e: any) => {
        const vals = Array.isArray(e) ? e.map((model) => model?.value) : []
        setModelVal(vals)
    }, [])

    const handleAddSpareparts = useCallback(async () => {
        if (!sku) {
            alert('Please, add SKU')
            return
        }
        if (!name) {
            alert('Please, add name')
            return
        }
        if (quantity === undefined) {
            alert('Please, enter pieces more then 0')
            return
        }

        if (modelVal.length === 0) {
            alert('Please select models')
            return
        }
        if (price === undefined || price == 0) {
            alert('Please add price more then 0')
            return
        }
        setIsLoading(true)
        await sendRequest(
            `${baseUrl}/stock/add-spare-part`,
            {
                sku,
                name,
                modelVal,
                quantity,
                price,
                currency,
            },
        )
            .then(() => {
                toast.success('Data Inserted', { hideProgressBar: true })
                addNotification({
                    message: 'Spare parts added successfully',
                    type: 'success',
                })
                setIsLoading(false)
                setSku('')
                setName('')
                setQuantity(0)
                setCurrency('')
                setPrice(0)
                setModelVal([])
                setModels('')
                onClose()
            })
    }, [currency, modelVal, name, onClose, price, quantity, sendRequest, sku])

    return (
        <ModalBox
            heading="Add Spare parts"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        SKU
                    </Text>
                    <TextField
                        value={sku}
                        onChange={handleSkuChange}
                        placeholder="Enter SKU"
                        width="250px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Name
                    </Text>
                    <TextField
                        value={name}
                        onChange={handleNameChange}
                        placeholder="Enter name"
                        width="250px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Available quantity
                    </Text>
                    <TextField
                        value={quantity}
                        onChange={handleQuantityChange}
                        placeholder="Enter quantity"
                        width="250px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Price
                    </Text>
                    <TextField
                        value={price}
                        onChange={handlePriceChange}
                        placeholder="price"

                        width="250px"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <div style={{ width: '250px' }}>
                        <Select
                            id="model"
                            options={models}
                            onChange={handleModels}
                            isMulti
                            placeholder='Select model'
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderColor: state.isFocused ? 'none' : 'none',
                                    backgroundColor: '#F2F0ED',
                                    fontStyle: "16px",
                                    padding: '0px',
                                    fontFamily: 'Inter Tight, sans-serif', // Add font family here
                                    color: '#112D56',
                                }),
                            }}
                        />
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Currency
                    </Text>
                    <div style={{ width: '250px' }}>
                        <select
                            id="currency"
                            value={currency}
                            onChange={handleCurrencyChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {myCurrency?.map((currency: any) => (
                                <option key={currency} value={currency}>
                                    {currency}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' onClick={onClose} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Back
                        </Text>
                    </Button>
                    <Button
                        className='w-32'
                        onClick={handleAddSpareparts}
                        backgroundColor="blue"
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? "Saving..." : "Save"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
