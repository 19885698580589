import React, { useState } from 'react'
import { Focus } from './Focus/Focus'
import { Reports } from './Reports/Reports'
import { Insights } from './Insights/Insights'
import { Button } from '../../base/Button'
import { Column } from '../../layout/Col'
import { Flex } from '../../layout/Flex'
import { Row } from '../../layout/Row'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Text } from '../../base/Text'
export default function Head() {
    const [activeTab, setActiveTab] = useState('Insights')

    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Flex className="py-5 w-full">
            <Column>
                <Row className="gap-x-5">
                    <Text
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        {activeTab}
                    </Text>
                    <Button
                        onClick={() => handleTabClick('Insights')}
                        backgroundColor={
                            activeTab === 'Insights' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Insights'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={activeTab === 'Insights' ? 'white' : 'blue'}
                            variant="title"
                            weightV="normal"
                        >
                            Insights
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Focus')}
                        backgroundColor={
                            activeTab === 'Focus' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Focus'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={activeTab === 'Focus' ? 'white' : 'blue'}
                            variant="title"
                            weightV="normal"
                        >
                            Focus
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Reports')}
                        backgroundColor={
                            activeTab === 'Reports' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Reports'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={activeTab === 'Reports' ? 'white' : 'blue'}
                            variant="title"
                            weightV="normal"
                        >
                            Reports
                        </Text>
                    </Button>
                </Row>
                <RowAlignCenterSpaced className="mt-5">
                    {activeTab === 'Insights' && <Insights />}
                    {activeTab === 'Focus' && <Focus />}
                    {activeTab === 'Reports' && <Reports />}
                </RowAlignCenterSpaced>
            </Column>
        </Flex>
    )
}
