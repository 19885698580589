import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import { Text } from '../../base/Text';
import { Icon } from '../../base/Icon';
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced';

interface ModalProps {
  children?: ReactNode;
  onClose?: () => void;
  heading?: string;
  height?: string; // Add height prop
  width?: string; // Add width prop
}

export const ItemSearchModal: React.FC<ModalProps> = ({ children, onClose, heading, height, width }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const overlayStyle: CSSProperties = {
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const modalStyle: CSSProperties = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflowY: 'auto',
    padding: '20px',
    width: width || '90%', // Use the provided width or default to '90%'
    height: height || 'auto', // Use the provided height or default to 'auto'
  };

  const handleClose = (e: any) => {
    if (e.target.id === 'wrapper') onClose();
  };

  return (
    <>
      <div id="wrapper" onClick={handleClose} style={overlayStyle}>
        <div ref={modalRef} style={modalStyle}>
          <RowAlignCenterSpaced >
            {heading && <Text className='mb-3' colorV='blue' variant='heading' weightV='normal' >{heading}</Text>}
            <span onClick={onClose} className='cursor-pointer'>
              <Icon name="cross" color="black" size={25} />
            </span>
          </RowAlignCenterSpaced>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};
