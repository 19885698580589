import React from 'react'
import Head from './Head'

export const Script = () => {
  return (
      <>
      <Head />
      </>
  )
}
