import axios from 'axios'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TopHeader from '../components/TopHeader'
import { toast } from 'react-toastify'
import { useAuth } from '../utils/auth'
import { addNotification } from '../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

export default function EditStatus() {
    const [status, setStatus] = useState('')
    const { id } = useParams()
    const auth = useAuth()
    const navigate = useNavigate()
    const token = auth?.user?.token?.token

    const statuss: any = localStorage.getItem('status')
    const handleStatusChange = (e: any) => {
        e.preventDefault()
        setStatus(e.target.value)
    }
    const url = `${baseUrl}/view/status/${id}`

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        await axios
            .put(
                url,
                {
                    status,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                addNotification({
                    message: 'Status Updated Successfully',
                    type: 'success',
                })
                toast.success('Status Updated Successfully', {
                    hideProgressBar: true,
                })
            })
            .catch((error) =>
                toast.error(`Error: ${error}`, { hideProgressBar: true })
            )
    }
    const goBack = () => {
        navigate(-1)
    }

    return (
        <>
            <TopHeader />
            <div className="flex items-center flex-col ">
                <div className="overflow-x-auto">
                    <h1 className="font-bold text-xl mt-6">Update Status</h1>
                    <form className="my-8 mx-4 space-y-6">
                        <div className="form-body mb-5">
                            <select
                                id="accountManagerEmail"
                                defaultValue={statuss}
                                onChange={(e) => handleStatusChange(e)}
                                className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2 p-1"
                            >
                                <option className="bg-b9green1" value={statuss}>
                                    {statuss}
                                </option>
                                <option value="Received">Received</option>
                                <option value="Processing">Processing</option>
                                <option value="Credit issued">
                                    Credit issued
                                </option>
                            </select>
                        </div>
                        <div className="flex justify-center ">
                            <button
                                type="button"
                                onClick={goBack}
                                className="relative mr-10 justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
                            >
                                Back
                            </button>
                            <button
                                className=" relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
                                onClick={handleSubmit}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
