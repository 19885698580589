import React, { useEffect, useState } from 'react'
import { Flex } from '../../../../../layout/Flex'
import { Table } from '../../../../../shared/Table'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { useFetch } from '../../../../../../hooks/useFetch'
import moment from 'moment'

const baseUrl = process.env.REACT_APP_baseUrl

export default function DeviceTable(itemsData:any) {
    console.log(itemsData);
    let extractedData = []
    if (itemsData?.itemsData?.itemsData?.length) {
        extractedData = itemsData?.itemsData?.itemsData?.map((item) => {
            return {
                IMEI: item?.imei,
                'Scanned by': item?.scannedBy?.email,
                Date: moment(item?.createdAt).format('DD/MM/YYYY'),
                Time: moment(item?.createdAt).format('HH:mm:ss'),
            }
        })
    }
    const columns = [
        'IMEI',
        'Scanned by',
        'Date',
        'Time',
    ]

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className='w-full'>
                    <Table
                        columns={columns}
                        data={extractedData}
                        showActions={false}
                    />
                </PrimaryCard>
            </Flex>
        </>
    )
}
