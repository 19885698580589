import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react'

interface ModalProps {
    children?: ReactNode
    onClose?: () => void // Add onClose prop
}

export const ModalSearch: React.FC<ModalProps> = ({ children, onClose }) => {
    const modalRef = useRef<HTMLDivElement | null>(null)

    const overlayStyle: CSSProperties = {
        position: 'fixed',
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 100,
    }

    const modalStyle: CSSProperties = {
        position: 'fixed',
        top: 20,
        left: 320,
        width: 500,
    }
    
    const handleClose = (e:any) => {
        if(e.target.id === 'wrapper') onClose()
    }

    return (
        <>
            <div id="wrapper" onClick={handleClose}  style={overlayStyle}>
                <div ref={modalRef} style={modalStyle}>
                    <div>{children}</div>
                </div>
            </div>
        </>
    )
}
