import { useCallback, useState } from 'react'
import { EditSparepartSKU } from './EditSparepartSKU'
import { DeleteSparepart } from './DeleteSparepart'
import { ModalBox } from '../../../../../base/ModelBox'
import { Flex } from '../../../../../layout/Flex'
import { Table } from '../../../../../shared/Table'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { ExportModel } from './ExportModel'
import PropTypes from 'prop-types'

export const SparepartSKUTable: React.FC<{
    items: any[]
    handleSparepartSku: () => void
}> = ({ items, handleSparepartSku }) => {
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isPrint, setIsPrint] = useState(false)
    const [selectedModel, setSelectedModel] = useState<any>()

    console.log({ items })
    const closeModal = useCallback(() => {
        setIsModalOpen(false)
        setIsEdit(false)
        setIsDelete(false)
        handleSparepartSku()
    }, [handleSparepartSku])

    let extractedData = []
    if (items) {
        extractedData = items?.map((item) => {
            return {
                ID: item?._id,
                SKU: item?.sku,
                Name: item?.name,
                Model: item?.modelName?.map((model, index) => (
                    index === item?.modelName?.length - 1 ? model?.modelName : `${model?.modelName}, `
                )),
                item:item
            }
        })
    }
    const columns = ['SKU', 'Name', 'Model']

    const handleSelectChange = (optionValue, item) => {
        setSelectedModel(item)
        if (optionValue === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
            setIsPrint(false)
        } else if (optionValue === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
            setIsPrint(false)
        } else if (optionValue === 'Print') {
            setIsPrint(true)
            setIsEdit(false)
            setIsDelete(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
            setIsPrint(false)
        }
        setIsModalOpen(true)
    }

    const renderModalComponent = useCallback(() => {
        if (isEdit) {
            return <EditSparepartSKU item={selectedModel} onClose={closeModal} />
        } else if (isDelete) {
            return <DeleteSparepart item={selectedModel} onClose={closeModal} />
        } else if (isPrint) {
            return <ExportModel item={selectedModel} onClose={closeModal} />
        }
    }, [isEdit, isDelete, isPrint, selectedModel, closeModal])

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        options={['Print', 'Edit', 'Delete']}
                        columns={columns}
                        data={extractedData}
                        showActions={true}
                        handleDropdownChange={handleSelectChange}
                    />
                </PrimaryCard>
            </Flex>
            {isModalOpen && renderModalComponent()}
        </>
    )
}

SparepartSKUTable.propTypes = {
    handleSparepartSku: PropTypes.func.isRequired,
    items: PropTypes.array, // Adjust the PropTypes based on the actual type of 'item'
}
