import React, {
  ReactNode,
  ButtonHTMLAttributes,
  KeyboardEvent,
  useState,
} from 'react';

type ButtonProps = {
  backgroundColor?: string;
  borderRadius?: string;
  height?: string;
  padding?: string;
  children: ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
  backgroundColor = 'lgblue',
  borderRadius = 'rounded-md',
  height,
  padding,
  children,
  className,
  onClick,
  onKeyDown,
  ...rest
}) => {
  const colorOptions = {
    blue: { backgroundColor: '#112D56' },
    lgblue: { backgroundColor: '#0074e4' },
    grey: { backgroundColor: '#F2F0ED' },
    green: { backgroundColor: '#45948A' },
    yellow: { backgroundColor: '#E8C877' },
    purple: { backgroundColor: '#3B65F6' },
    white: { backgroundColor: '#FFFFFF' },
    transparent: { backgroundColor: 'transparent' },
  };

  const borderRadiusOptions = {
    'rounded-md': { borderRadius: '5px' },
    'rounded-lg': { borderRadius: '6px' },
    'rounded-full': { borderRadius: '8px' },
  };

  const buttonStyles = {
    ...(colorOptions[backgroundColor] || colorOptions.lgblue),
    ...(borderRadiusOptions[borderRadius] || borderRadiusOptions['rounded-md']),
    height: height || '40px',
    padding: padding || '0 20px',
    transition: 'background-color 0.3s, opacity 0.3s',
  };

  const buttonHoverStyles = {
    ...buttonStyles,
    opacity: 0.8,
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      // Trigger the same effect as onClick when Enter key is pressed
      if (onClick) {
        onClick(event as any);
      }
    }

    // Allow customization of onKeyDown by the parent component
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return (
    <button
      style={isHovered ? buttonHoverStyles : buttonStyles}
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      {children}
    </button>
  );
};
