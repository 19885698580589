import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/auth'
import { TextBackground } from '../../base/TextBackground'
import { Column } from '../../layout/Col'
import { RowAlignCenterSpaced } from '../../layout/RowAlignCenterSpaced'
import { Text } from '../../base/Text'
import { ItemSearchModal } from '../../shared/SearchModal/ItemSearchModal'
const baseUrl = process.env.REACT_APP_baseUrl

export const UserHistory: React.FC<{ onClose: () => void; item: any }> = ({
    onClose,
    item
}) => {
    const auth = useAuth()
    const token = auth?.user?.token?.token
    return (
        <ItemSearchModal heading="User History" width='550px' height='450px' onClose={onClose}>
            <Column className="gap-y-4  ">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        First name
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        {item?.firstName}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Last name
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        {item?.lastName}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                       Email
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        {item?.email}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Account Manager
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        {auth?.selectedProfile?.accountManagerEmail}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Role
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                       {auth?.selectedProfile?.role?.toString()}
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Company
                    </Text>
                    <TextBackground className='w-72' backgroundColor='b9white'>
                        {auth?.selectedCompany?.companyName}
                    </TextBackground>
                </RowAlignCenterSpaced>
            </Column>
        </ItemSearchModal>
    )
}
