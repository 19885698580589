import axios from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import loading from '../../assets/loading.gif'
import TopHeader from '../../components/TopHeader'
import { useAuth } from '../../utils/auth'
import { addNotification } from '../../components/shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const baseStyle = {
    display: 'flex',
    height: 200,
    width: 500,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    cursor: 'pointer',
    transition: 'border .24s ease-in-out',
}

const focusedStyle = {
    borderColor: '#A855F7',
}

const acceptStyle = {
    borderColor: '#00e676',
}

const rejectStyle = {
    borderColor: '#ff1744',
}

export default function UploadImei() {
    const [file, setFile] = useState(null)
    const [isLoading, setisLoading] = useState<boolean>(false)
    const auth = useAuth()
    const token = auth?.user?.token?.token

    const onDrop: any = useCallback((acceptedFiles: any) => {
        const [acceptedFile] = acceptedFiles
        if (acceptedFile) {
            setFile(acceptedFile)
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        accept: {
            'text/xlsx': ['.xlsx'],
        },
        onDrop,
    })

    const style: any = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : { borderColor: '#A855F7' }),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    )
    const acceptedFileItems = acceptedFiles.map((file: any) => (
        <li key={file.path}>
            {file?.path} - {file.size} bytes
        </li>
    ))

    const uploadFile = useCallback(
        async () => {
            if (file) {
                setisLoading(true)
                const formData = new FormData()
                formData.append('file', file)
                const url = `${baseUrl}/imei/upload`
                const options = {
                    url: url,
                    method: 'POST',
                    headers: {
                        'content-type': 'multipart/form-data',
                        Authorization: token as string,
                    },
                    data: formData,
                }
                try {
                    await axios(options)
                    setFile(null)
                    setisLoading(false)
                    addNotification({
                        message: 'File uploaded successfully',
                        type: 'success',
                    })
                    toast.success('File uploaded successfully.', {
                        hideProgressBar: true,
                    })
                } catch (error: any) {
                    setisLoading(false)
                    toast.error(error?.response?.data?.Message, {
                        hideProgressBar: true,
                    })
                }
            } else {
                toast.error('Please Add Tracking Number', {
                    hideProgressBar: true,
                })
            }
        },
        [file, token]
    )
    return (
        <>
            <TopHeader />

            {isLoading ? (
                <div className="flex justify-center items-center h-screen flex-col">
                    <h2 className="mb-3">
                        Please, wait while file is uploading...
                    </h2>
                    <img src={loading} />
                </div>
            ) : (
                <div className="flex justify-center items-center h-screen flex-col">
                    <div className="flex flex-row mb-5">
                        <div>
                            <label>Suppiler</label>
                            <select
                                id="accountManagerEmail"
                                className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2 p-1"
                            >
                                <option
                                    className="bg-b9green1"
                                    value="d"
                                ></option>
                                <option value="Received">Received</option>
                                <option value="Processing">Processing</option>
                                <option value="Credit issued">
                                    Credit issued
                                </option>
                            </select>
                        </div>
                        <div className="mx-4">
                            <label>VAT</label>
                            <select
                                id="accountManagerEmail"
                                className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2 p-1"
                            >
                                <option
                                    className="bg-b9green1"
                                    value="d"
                                ></option>
                                <option value="Received">Received</option>
                                <option value="Processing">Processing</option>
                                <option value="Credit issued">
                                    Credit issued
                                </option>
                            </select>
                        </div>
                        <div>
                            <label>Warrenty</label>
                            <select
                                id="accountManagerEmail"
                                className="w-full border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2 p-1"
                            >
                                <option
                                    className="bg-b9green1"
                                    value="d"
                                ></option>
                                <option value="Received">Received</option>
                                <option value="Processing">Processing</option>
                                <option value="Credit issued">
                                    Credit issued
                                </option>
                            </select>
                        </div>
                    </div>
                    <h1
                        className="m-5 text-black font-bold text-xl"
                        style={{ width: 500 }}
                    >
                        Upload Invoice File
                    </h1>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Drag drop some file here, or click to select file</p>
                    </div>
                    {file && (
                        <div>
                            <div className="mb-4" style={{ width: 500 }}>
                                <h4 className="mt-3">Selected file</h4>
                                <ul className="text-purple-400">
                                    {acceptedFileItems}
                                </ul>
                            </div>
                            <div>
                                <button
                                    onClick={uploadFile}
                                    className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-700 focus:outline-none"
                                >
                                    Upload File
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
