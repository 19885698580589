import axios from 'axios'
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import { useAuth } from '../utils/auth'
import TopHeader from '../components/TopHeader'
import 'react-tabs/style/react-tabs.css'
import { toast } from 'react-toastify'

const baseUrl = process.env.REACT_APP_baseUrl

export default function History() {
    const [histories, setHistories] = useState<any>()
    const [search, setSearch] = useState<any>()
    const auth = useAuth()
    const token = auth?.user?.token?.token

    const handleSearchChange = (e: any) => {
        e.preventDefault()
        setSearch(e.target.value)
    }

    const handleSearch = useCallback(
        (e: any) => {
            e.preventDefault()
            axios
                .post(
                    `${baseUrl}/history/search`,
                    {
                        search,
                    },
                    {
                        headers: {
                            Authorization: token as string,
                        },
                    }
                )
                .then((response) => {
                    if (response?.data?.items.length > 0) {
                        setHistories(response?.data?.items)
                    } else if (response?.data?.sheets.length > 0) {
                        setHistories(response?.data?.sheets)
                    } else if (response?.data?.spareParts.length > 0) {
                        setHistories(response?.data?.spareParts)
                    } else {
                        toast.info('No history found')
                    }
                })
                .catch((error) =>
                    
                    toast.error(`Error: ${error.response.data.message}`, {
                        hideProgressBar: true,
                    })
                )
        },
        [search, token]
    )
    return (
        <>
            <TopHeader />
            <div className="px-5 pb-5">
                <div className="flex mt-5 mb-7">
                    <h1 className="font-bold text-xl">History</h1>
                </div>
                <div>
                    <div className="basis-1/2 mr-5  text-right">
                        <form>
                            <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
                                Search
                            </label>
                            <div className="relative">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-4 text-gray-500 dark:text-gray-400"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </div>
                                <input
                                    type="search"
                                    onChange={(e) => handleSearchChange(e)}
                                    id="default-search"
                                    className="block p-2 pl-10 w-full text-sm text-gray-900  bg-gray-50 rounded-lg border dark:placeholder-gray-400"
                                    placeholder="Search IMEI, Rma or Spare part name"
                                    required
                                />
                                <button
                                    onClick={handleSearch}
                                    className="text-white absolute right-2.5 bottom-1.5 w-20 outline-none font-medium rounded-lg text-sm px-4 py-1 hover:bg-b9green4  bg-b9green3"
                                >
                                    Find
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    {histories?.map((history) => (
                        <div className="my-5" key={history?.id}>
                            <div className="flex">
                                <b>
                                    {history?.user?.firstName}{' '}
                                    {history?.user?.lastName}
                                </b>
                                <div className="ml-2 px-2 py-0.5 rounded-full text-gray-500 bg-gray-200 text-sm self-center">
                                    {moment(history?.createdAt).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}
                                </div>
                            </div>
                            <div className="pt-4">{history?.update}</div>
                            <hr className="h-px my-3 bg-gray-300 border-0"></hr>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
