import React from 'react'

// Import the icons you need from the react-icons library
import { MdNotificationsActive } from 'react-icons/md'
import { HiOutlineMailOpen } from 'react-icons/hi'
import { AiOutlineHome } from 'react-icons/ai'
import { MdOutlineInventory2 } from 'react-icons/md'
import { GiProgression } from 'react-icons/gi'
import { FaPersonRays } from 'react-icons/fa6'
import { FaRegFloppyDisk } from 'react-icons/fa6'
import { BiSearchAlt2 } from 'react-icons/bi'
import { SiSlack } from 'react-icons/si'
import { FaGoogleDrive } from 'react-icons/fa'
import { SlLogout } from 'react-icons/sl'
import { MdMobileFriendly } from 'react-icons/md'
import { SiProton } from 'react-icons/si'
import { AiOutlineStock } from 'react-icons/ai'
import { VscSettings } from 'react-icons/vsc'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { RxCrossCircled } from 'react-icons/rx'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { AiOutlineEuroCircle } from 'react-icons/ai'
import { BsArrowUpShort } from 'react-icons/bs'
import { BiEditAlt } from 'react-icons/bi'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { AiOutlineEye } from 'react-icons/ai'
export const Icon = ({ name, ...props }: any) => {
    // Create a map of icon names to their corresponding components
    const iconComponents = {
        notification: MdNotificationsActive,
        mail: HiOutlineMailOpen,
        home: AiOutlineHome,
        inventory: MdOutlineInventory2,
        sale: GiProgression,
        management: FaPersonRays,
        rma: FaRegFloppyDisk,
        search: BiSearchAlt2,
        slack: SiSlack,
        drive: FaGoogleDrive,
        logout: SlLogout,
        mobile: MdMobileFriendly,
        production: SiProton,
        stock: AiOutlineStock,
        filter: VscSettings,
        check: IoMdCheckmarkCircleOutline,
        cross: RxCrossCircled,
        upload: AiOutlineCloudUpload,
        dots: BsThreeDotsVertical,
        euro: AiOutlineEuroCircle,
        arrowup: BsArrowUpShort,
        edit: BiEditAlt,
        closeEye: AiOutlineEyeInvisible,
        openEye: AiOutlineEye,
    }

    // Check if the specified icon name exists in the map
    const IconComponent = iconComponents[name]

    if (!IconComponent) {
        // Handle the case where the icon name is not found
        return <div>Icon not found</div>
    }

    // Render the selected icon component with the provided props
    return <IconComponent {...props} />
}
