import React, { useCallback, useEffect, useState } from 'react';
import Head from './Head';
import { Filter } from './components/Filter';
import SparepartTable from './components/SparepartTable';
import { useFetch } from '../../../../hooks/useFetch';
import { AccessEnums, AccessPermissions } from '../../../../constant/UserAccess';
import { useAuth } from '../../../../utils/auth';
import { toast } from 'react-toastify';
import { usePost } from '../../../../hooks/usePost';
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced';

const baseUrl = process.env.REACT_APP_baseUrl;

export default function Spareparts() {
    const [currentPage, setCurrentPage] = useState(1);
    const [sparepartData, setSparepartData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [isFilteredData, setIsFilteredData] = useState(true);
    const auth = useAuth();
    const { getRequest: getItems, data: itemsData } = useFetch(
        `${baseUrl}/stock/get-spare-part-pdf`
    );

    useEffect(() => {
        getItems()
    }, [getItems])

    const viewSparePartCheck = auth?.selectedProfile?.access?.some(
        (acc) => acc?.module === AccessEnums.SparePartsInventory && acc.permission.includes(AccessPermissions.View)
    );
    const { getRequest: getSparepartItems, data: sparepartDataResponse } = useFetch(
        `${baseUrl}/stock/get-spare-parts?page=${currentPage}`
    );

    useEffect(() => {
        getSparepartItems();
    }, [getSparepartItems]);

    useEffect(() => {
        if (!filteredData && sparepartDataResponse?.data) {
            setSparepartData(sparepartDataResponse);
        }
    }, [sparepartDataResponse, filteredData]);

    const handleFilterChange = useCallback((data) => {
        setFilteredData({ data });
    }, []);
    const totalPages = (filteredData?.pagination?.pageCount || sparepartData?.pagination?.pageCount) || 0;
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const refetch = () => {
        getSparepartItems();
    };

    return (
        <>
            <RowAlignCenterSpaced>
                <Head refetch={refetch} sparepartData={filteredData?.data?.length > 0 ? filteredData?.data : itemsData} />
                {viewSparePartCheck && <Filter refetch={refetch} onDataChange={handleFilterChange} itemsData={sparepartDataResponse?.data} />}
            </RowAlignCenterSpaced>

            {viewSparePartCheck && (filteredData || sparepartData) && ( // Show pagination if filteredData or sparepartData is available
                <SparepartTable
                    refetch={refetch}
                    itemsData={filteredData?.data?.length > 0 ? filteredData : sparepartData}
                    pageCount={totalPages}
                    handlePageChange={handlePageChange}
                />
            )}
        </>
    );
}
