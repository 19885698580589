import React from 'react'
import DeviceTable from './component/DeviceTable'

export const Device = (itemsData:any) => {
  return (

    <DeviceTable itemsData={itemsData} />
    
  )
}
