import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { Text } from '../../../../base/Text'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { useFetch } from '../../../../../hooks/useFetch'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { useParams } from 'react-router-dom'
import { generatePDF } from '../../../../shared/GenerateLabel'
import JsBarcode from 'jsbarcode'
import { useEdit } from '../../../../../hooks/useEdit'
import { TextArea } from '../../../../base/Textarea'
import { addNotification } from '../../../../shared/Notification/Notification'


const baseUrl = process.env.REACT_APP_baseUrl

export const AddNewUnit: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [comment, setComment] = useState<any>('')
    const [imei, setImei] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const { sendEditRequest } = useEdit()

    const barCode = useRef<HTMLDivElement>(null);

    const auth = useAuth()
    const { id } = useParams()
    const token = auth?.user?.token?.token

    

    const handleCommentChange = (newValue: any) => {
        setComment(newValue)
    }

    const handleImeiChange = (newValue: any) => {
        setImei(newValue)
    }

    const { getRequest: getModel, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )

    useEffect(() => {
        getModel()
    }, [getModel])

    const { getRequest: getLabels, data: labelsData } = useFetch(
        `${baseUrl}/label/labels-data`
    );
    useEffect(() => {
        getLabels();
    }, [getLabels]);

    const handleSave = async (e: any) => {
        e.preventDefault()
        if (!imei) {
            alert('Please, add IMEI')
            return
        } 
        setIsLoadingSave(true)
        await sendEditRequest(
                `${baseUrl}/rma/addItem-rma/${id}`,
                {
                    imei,
                    comment,
                },
            )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Item has been added successfully',
                    type: 'success',
                })
                setComment('')
                setImei('')
                setIsLoadingSave(false)
                onClose()
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }

    const handleSaveAndPrint = async (e: any) => {
        e.preventDefault()
         if (!imei) {
            alert('Please, add IMEI')
            return
        } 
        setIsLoading(true)
        await sendEditRequest(
                `${baseUrl}/rma/addItem-rma/${id}`,
                {
                    imei,
                    comment,
                },
            )
            .then((response) => {
                console.log({response});
                const item = response?.data
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Item has been added successfully',
                    type: 'success',
                })
                JsBarcode('#barcode', `${response?.data?.item?.imei}`, {
                    lineColor: 'black',
                    textAlign: 'center',
                    width: 1,
                    background: 'transparent',
                    height: 40,
                    displayValue: false,
                    xmlDocument: document,
                });
                setComment('')
                setImei('')
                setIsLoading(false)
                generatePDF(item, labelsData, barCode)
            })
            .catch((error) => {
                console.log(error);
                toast.error(`Error: ${error?.response?.data?.message}`, {
                    hideProgressBar: true,
                })
            })
    }

    return (
        <ModalBox
            heading="Add RMA Item"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[250px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        IMEI
                    </Text>
                    <TextField
                        value={imei}
                        onChange={handleImeiChange}
                        placeholder="Enter imei"
                        
                        className='w-72'
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                    Reported issue
                    </Text>
                    <TextArea
                    className='w-72'
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="write reported issue ..."
                        
                    />
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoadingSave ? "Saving" : "Save"}
                        </Text>
                    </Button>
                    <Button onClick={handleSaveAndPrint} backgroundColor="blue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? "Saving" : "Save & Print"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
            <div ref={barCode} className="py-2 px-2 hidden">
                <svg id="barcode"></svg>
            </div>
        </ModalBox>
    )
}
