import axios from 'axios'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JsBarcode from 'jsbarcode'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import TopHeader from '../../TopHeader'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useAuth } from '../../../utils/auth'
import back from '../../../assets/back.png'
import { Events } from '../../../constants/eventEnum'
import { useFetch } from '../../../hooks/useFetch'
import Codes from '../../Codes'
import { addNotification } from '../../shared/Notification/Notification'

const baseUrl = process.env.REACT_APP_baseUrl

const fixedInputClass =
    'rounded-md appearance-none w-40 relative block  px-1.5 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm'

;(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}

export default function ItemInventory() {
    const [item, setItem] = useState<any>()
    const [grade, setGrade] = useState('')
    const [codeDescription, setCodeDescription] = useState<any>()
    const [codeEvents, setCodeEvents] = useState<any[]>([])
    const [prevCodes, setPrevCodes] = useState<any[]>([])
    const [codes, setCodes] = useState<string[]>([])
    const [codesAfterFix, setCodesAfterFix] = useState<string[]>([])
    const [event, setEvent] = useState('')
    const [eventAfterFix, setEventAfterFix] = useState('')

    const [battery, setBattery] = useState<any>(0)
    const [repairTime, setRepairTime] = useState<any>()
    const barCode = useRef<HTMLDivElement>(null)
    const barCodeSvg = barCode.current?.innerHTML

    const navigate = useNavigate()
    const auth = useAuth()
    const { id } = useParams()
    const token = auth?.user?.token.token
    const sheetId: any = localStorage.getItem('sheetId')
    const isInventory = auth.user.role?.includes('inventory')
    const isAdmin = auth.user.role?.includes('admin')

    const { getRequest: getInventoryItem, data: itemData } = useFetch(
        `${baseUrl}/item/${id}`
    )
    const { getRequest: getSheetsDescription, data: sheetDescriptionData } =
        useFetch(`${baseUrl}/code/getCodes`)
    const { getRequest: getCodeEvents, data: codeEventsData } = useFetch(
        `${baseUrl}/view/sheet/code_events`
    )

    useEffect(() => {
        getInventoryItem()
        getSheetsDescription()
        getCodeEvents()
    }, [getCodeEvents, getInventoryItem, getSheetsDescription])

    useEffect(() => {
        setItem(itemData)
        setRepairTime(itemData?.repairs?.at(-1))
        setPrevCodes(itemData?.codes)
        setCodes(itemData?.codes)
        setCodesAfterFix(itemData?.codesAfterFix)
        setEvent(itemData?.event)
        setGrade(itemData?.grade)
        setBattery(itemData?.battery)
        setCodeDescription(sheetDescriptionData)
        setCodeEvents(codeEventsData)
    }, [codeEventsData, itemData, sheetDescriptionData])

    const items = []

    if (prevCodes) {
        for (const cod of prevCodes) {
            const item = codeDescription?.find(
                (instance: any) => instance.label === cod
            )
            if (item) {
                items.push(item)
            }
        }
    }

    useEffect(() => {
        JsBarcode('#barcode', `${item?.imei}`, {
            lineColor: 'black',
            textAlign: 'center',
            width: 3.1,
            background: 'transparent',
            height: 40,
            displayValue: false,
            xmlDocument: document,
        })
    }, [item?.imei])

    const calSparePartCost = (item) => {
        let cost = 0
        item?.spareParts?.map(({ sparePart }) => {
            cost = +sparePart?.price
        })
        return <span>{cost || 0}</span>
    }

    const handleStart = () => {
        navigate(`/item/repair/${id}`)
    }

    const goBack = () => {
        navigate(-1)
    }

    const handleSubmit = async (e) => {
        if (battery > 100) {
            alert('Enter battery below 100')
            return
        }
        e.preventDefault()
        await axios
            .put(
                `${baseUrl}/item/update/${sheetId}`,
                {
                    codes,
                    codesAfterFix,
                    id,
                    isPrinted: true,
                    event: codes?.length > 0 ? event : '',
                    eventAfterFix:
                        codesAfterFix?.length > 0 ? eventAfterFix : '',
                    grade,
                    battery,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then(() => {
                toast.success('Saved', { hideProgressBar: true })
                addNotification({
                    message: 'Item inventory updated successfully',
                    type: 'success',
                })
                getInventoryItem()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }

    const handleGrade = async (e: any) => {
        setGrade(e.target.value)
    }

    const handleBattery = async (e: any) => {
        e.preventDefault()
        setBattery(e.target.value)
    }

    const printLabel = useCallback(
        async (e: any) => {
            if (battery > 100) {
                alert('Enter battery below 100')
                return
            }
            e.preventDefault()
            await axios
                .put(
                    `${baseUrl}/item/update/${sheetId}`,
                    {
                        codes,
                        id,
                        isPrinted: true,
                        event: codes?.length > 0 ? event : '',
                        eventAfterFix:
                            codesAfterFix?.length > 0 ? eventAfterFix : '',
                        codesAfterFix,
                        grade,
                        battery,
                    },
                    {
                        headers: {
                            Authorization: token as string,
                        },
                    }
                )
                .then((response) => {
                    toast.success('Saved', { hideProgressBar: true })
                    addNotification({
                        message: 'Item inventory updated successfully',
                        type: 'success',
                    })
                    getInventoryItem()
                    const dd = {
                        pageMargins: [5, 5, 5, 5],
                        content: [
                            {
                                columns: [
                                    [
                                        {
                                            width: 50,
                                            stack: [
                                                {
                                                    text: `Model :      ${item?.model?.modelName}  ${item?.storage}`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `Code :        ${
                                                        repairTime?.cancelAt &&
                                                        repairTime?.startAt &&
                                                        repairTime?.finishAt ===
                                                            undefined
                                                            ? codes ||
                                                              response?.data?.codes.toString()
                                                            : repairTime?.finishAt &&
                                                              repairTime?.startAt &&
                                                              repairTime?.cancelAt ===
                                                                  undefined
                                                            ? codesAfterFix ||
                                                              response?.data?.codesAfterFix?.toString()
                                                            : repairTime?.cancelAt ===
                                                                  undefined &&
                                                              repairTime?.startAt &&
                                                              repairTime?.finishAt ===
                                                                  undefined
                                                            ? codes ||
                                                              response?.data?.codes.toString()
                                                            : codes ||
                                                              response?.data?.codes.toString()
                                                    }`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `Status :       ${
                                                        repairTime?.cancelAt &&
                                                        repairTime?.startAt &&
                                                        repairTime?.finishAt ===
                                                            undefined
                                                            ? event ||
                                                              response?.data
                                                                  ?.event
                                                            : repairTime?.finishAt &&
                                                              repairTime?.startAt &&
                                                              repairTime?.cancelAt ===
                                                                  undefined
                                                            ? eventAfterFix ||
                                                              response?.data
                                                                  ?.eventAfterFix
                                                            : repairTime?.cancelAt ===
                                                                  undefined &&
                                                              repairTime?.startAt &&
                                                              repairTime?.finishAt ===
                                                                  undefined
                                                            ? event ||
                                                              response?.data
                                                                  ?.event
                                                            : event ||
                                                              response?.data
                                                                  ?.event
                                                    }`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `VAT :          ${item?.vat.slice(
                                                        0,
                                                        1
                                                    )}`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `Grade :      ${
                                                        grade === undefined
                                                            ? ''
                                                            : grade
                                                            ? grade
                                                            : item?.grade
                                                    }`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `Battery :    ${
                                                        battery === undefined
                                                            ? ''
                                                            : battery
                                                            ? battery + ' %'
                                                            : item?.battery +
                                                              ' %'
                                                    }`,
                                                    margin: [1, 2],
                                                    fontSize: 11,
                                                    bold: true,
                                                },
                                                {
                                                    text: `${item?.imei}`,
                                                    margin: [1, 3],
                                                    fontSize: 11,
                                                    width: 100,
                                                    bold: true,
                                                },
                                                {
                                                    svg: barCodeSvg,
                                                    height: 25,
                                                    width: 170,
                                                },
                                            ],
                                        },
                                    ],
                                ],
                            },
                        ],
                    }
                    pdfMake.createPdf(dd as any).print()
                })
                .catch((error) => console.error(`Error: ${error}`))
        },
        [
            barCodeSvg,
            battery,
            codes,
            codesAfterFix,
            event,
            eventAfterFix,
            getInventoryItem,
            grade,
            id,
            item?.battery,
            item?.grade,
            item?.imei,
            item?.model,
            item?.storage,
            item?.vat,
            repairTime?.cancelAt,
            repairTime?.finishAt,
            repairTime?.startAt,
            sheetId,
            token,
        ]
    )

    useEffect(() => {
        getSheetsDescription()
        getCodeEvents()
    }, [getCodeEvents, getSheetsDescription])

    useEffect(() => {
        const events: string[] = []
        if (codes?.length > 0) {
            for (const code of codes || []) {
                for (const eventCode of codeEvents || []) {
                    if (eventCode?.code === code) {
                        events.push(eventCode[item?.model])
                    }
                }
            }

            if (events.includes(Events.scrap)) {
                setEvent(Events.scrap)
            } else if (events.includes(Events.warranty)) {
                setEvent(Events.warranty)
            } else if (events.includes(Events.hardRepair)) {
                setEvent(Events.hardRepair)
            } else if (events.includes(Events.mediumRepair)) {
                setEvent(Events.mediumRepair)
            } else if (events.includes(Events.easyRepair)) {
                setEvent(Events.easyRepair)
            } else if (events.includes(Events.return)) {
                setEvent(Events.return)
            } else if (events.includes(Events.salesInventory)) {
                setEvent(Events.salesInventory)
            } else if (events.includes(Events.rma)) {
                setEvent(Events.rma)
            } else if (events.includes(Events.tounlock)) {
                setEvent(Events.tounlock)
            } else if (events.includes(Events.ms_repair)) {
                setEvent(Events.ms_repair)
            }
        } else {
            setEvent('')
        }
    }, [codes?.length, codeEvents, codes, item?.model])

    const handleBackToInventory = useCallback(async () => {
        const url = `${baseUrl}/item/backfrom-transit/${id}`
        await axios
            .put(
                url,
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then(() => {
                toast.success('Removed from transit', { hideProgressBar: true })
                addNotification({
                    message: 'Item removed from transit successfully',
                    type: 'success',
                })
                // getItems();
            })
            .catch((error) => console.error(`Error: ${error}`))
    }, [id, token])

    const handleRestartRepair = async () => {
        await axios
            .post(
                `${baseUrl}/repair/startat/${id}`,
                {
                    item: id,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then(() => {
                toast.success('Repair restarted', { hideProgressBar: true })
                addNotification({
                    message: 'Repair restarted successfully',
                    type: 'success',
                })
                getInventoryItem()
            })
            .catch((error) => console.error(`Error: ${error}`))
    }

    const sendCodesBeforeRepair = (codes) => {
        setCodes(codes)
    }
    const sendCodesAfterRepair = (codes) => {
        setCodesAfterFix(codes)
    }
    const sendEventBeforeRepair = (event) => {
        setEvent(event)
    }
    const sendEventAfterRepair = (event) => {
        setEventAfterFix(event)
    }
    return (
        <>
            <TopHeader />
            <div className="mt-10 flex justify-center content-center">
                <div
                    className="bg-b9green1 overflow-x-auto p-10 align-center"
                    style={{ width: 700 }}
                >
                    <div className=" self-center mb-3 ">
                        <button type="button" onClick={goBack}>
                            <img
                                src={back}
                                className="h-6 w-6 h-5 max-w-sm"
                                alt="edit"
                            />
                        </button>
                    </div>
                    <div className="flex items-center justify-between mb-5">
                        <div className="flex mx-2  justify-between">
                            {(isInventory || isAdmin) && (
                                <div className="">
                                    {repairTime?.startAt &&
                                    repairTime?.finishAt === undefined &&
                                    repairTime?.cancelAt === undefined ? (
                                        <button
                                            onClick={handleStart}
                                            className="relative justify-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                        >
                                            Check Repair
                                        </button>
                                    ) : repairTime?.startAt &&
                                      repairTime?.finishAt &&
                                      repairTime?.cancelAt === undefined ? (
                                        <h1 className="font-bold">
                                            Repair Finished
                                        </h1>
                                    ) : repairTime?.startAt &&
                                      repairTime?.cancelAt &&
                                      repairTime?.finishAt === undefined ? (
                                        <h1 className="text-red-500 font-bold">
                                            Repair Cancelled
                                        </h1>
                                    ) : (
                                        <button
                                            onClick={handleStart}
                                            className="relative justify-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 "
                                        >
                                            Go for Repair
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        {(isInventory || isAdmin) && (
                            <div>
                                {repairTime?.startAt &&
                                    (repairTime?.finishAt ||
                                        repairTime?.cancelAt) && (
                                        <button
                                            onClick={handleRestartRepair}
                                            className=" w-32 relative justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                        >
                                            Restart repair
                                        </button>
                                    )}
                            </div>
                        )}
                        <div>
                            {item?.status && item?.batchNumber && (
                                <button
                                    onClick={handleBackToInventory}
                                    className=" w-40 relative justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                    Move to inventory
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="mb-5">
                        <div className="flex py-1">
                            <div className="w-40">
                                <label>Inventory :</label>
                            </div>
                            <label className=" font-medium">
                                {item?.event}
                            </label>
                        </div>
                        <div className="flex">
                            {' '}
                            <div className="w-40">
                                <label>Date of arrival :</label>
                            </div>
                            <label className="font-medium">
                                {item?.dateInvoiced && (
                                    <>
                                        {moment(item?.dateInvoiced).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </>
                                )}
                            </label>
                        </div>
                        <div className=" flex py-1">
                            <div className="w-40">
                                <label>IMEI : </label>
                            </div>
                            <label className="font-medium"> {item?.imei}</label>
                        </div>{' '}
                        <div className="flex">
                            <div className="w-40">
                                <label>Phone model :</label>
                            </div>
                            <label className="font-medium">
                                {' '}
                                {item?.model?.modelName}
                            </label>
                        </div>
                        <div className="flex py-1">
                            <div className="w-40">
                                <label>Storage : </label>
                            </div>
                            <label className="font-medium">
                                {' '}
                                {item?.storage}
                            </label>
                        </div>
                        <div className="flex">
                            <div className="w-40">
                                <label>VAT :</label>
                            </div>
                            <label className="font-medium"> {item?.vat}</label>
                        </div>
                        <div className="flex py-1">
                            <div className="w-40">
                                <label>Comment :</label>
                            </div>
                            <label className=" font-medium">
                                {' '}
                                {item?.comment}
                            </label>
                        </div>
                        <div className="flex ">
                            <div className="w-40">
                                <label>Repair Cost : </label>
                            </div>
                            <label className="font-medium">
                                {' '}
                                {item?.repairCost}
                            </label>
                        </div>
                        <div className="flex py-1">
                            <div className="w-40">
                                <label>Spare Parts : </label>
                            </div>
                            {item?.spareParts?.map(({ sparePart }) => (
                                <label
                                    key={sparePart?._id}
                                    className="font-medium"
                                >
                                    {sparePart?.name ? (
                                        <span>{sparePart?.name} ,</span>
                                    ) : (
                                        <span></span>
                                    )}
                                </label>
                            ))}
                        </div>
                        <div className="flex">
                            <div className="w-40">
                                <label>Spare parts cost :</label>
                            </div>
                            <label className="font-medium">
                                {' '}
                                {calSparePartCost(item)}
                            </label>
                        </div>
                        <div className="flex py-1 mt-2">
                            <Codes
                                sendEventBeforeRepair={sendEventBeforeRepair}
                                sendEventAfterRepair={sendEventAfterRepair}
                                sendCodesAfterRepair={sendCodesAfterRepair}
                                sendCodesBeforeRepair={sendCodesBeforeRepair}
                            />
                        </div>
                        {/* <div className="flex mt-1">
              <div className="w-40">
                <label className="font-bold w-24">Event: </label>
              </div>
              <span className=" font-medium">{event}</span>
            </div> */}
                        <div className="flex mt-3">
                            <div className="w-40">
                                <label className="font-bold mr-3">Grade:</label>
                            </div>
                            <select
                                id="vat"
                                defaultValue={grade}
                                onChange={(e) => handleGrade(e)}
                                className="w-40 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-1.5 px-1"
                            >
                                <option value={grade ? grade : ''}>
                                    {grade ? grade : 'Grade'}
                                </option>
                                <option value="A">A</option>
                                <option value="AB">AB</option>
                                <option value="B">B</option>
                                <option value="C+">C+</option>
                                <option value="C-">C-</option>
                                <option value="IM">IM</option>
                            </select>
                        </div>
                        <div className="flex mt-3">
                            <div className="w-40">
                                <label className="font-bold mr-3">
                                    Battery:
                                </label>
                            </div>
                            <input
                                className={fixedInputClass}
                                onChange={(e) => handleBattery(e)}
                                type="number"
                                id="battery"
                                name="battery"
                                min="0"
                                max="100"
                                value={battery}
                                placeholder={
                                    battery ? battery : 'Enter battery'
                                }
                            />
                        </div>
                    </div>
                    <div ref={barCode} className="py-2 px-2">
                        <svg id="barcode"></svg>
                    </div>
                    <div className="flex justify-between">
                        <button
                            onClick={handleSubmit}
                            className=" w-36 relative justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            Save
                        </button>
                        <button
                            onClick={printLabel}
                            className=" w-36 relative justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-b9purple3 hover:bg-b9purple4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            Save & Print
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
