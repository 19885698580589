import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'
import { TextField } from '../../../../base/Textfield'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { TextBackground } from '../../../../base/TextBackground'
import { Text } from '../../../../base/Text'
import { useAuth } from '../../../../../utils/auth'
import { TextArea } from '../../../../base/Textarea'
import { useEdit } from '../../../../../hooks/useEdit'
import { addNotification } from '../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const ProposeRepair: React.FC<{ onClose: () => void; item: any, data, setClaimed:any }> = ({
    onClose,
    item,
    data,
    setClaimed
}) => {
    const [reasonOfReturn, setReasonOfReturn] = useState('')
    const [acceptance, setAcceptance] = useState('')
    const [priceProposal, setPriceProposal] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendEditRequest } = useEdit()

    const handleReasonOfReturn = (newValue: any) => {
        setReasonOfReturn(newValue)
    }
    const handleAcceptanceChange = (event: any) => {
        setAcceptance(event.target.value)
    }
    const handleProposalPrice = (newValue: any) => {
        setPriceProposal(newValue)
    }
    const handleSave = async (e: any) => {
        e.preventDefault()
        if (!reasonOfReturn) {
            alert('Please, enter reason of return')
            return
        }
        setIsLoading(true)
        await sendEditRequest(
            `${baseUrl}/purchase/edit-purchase-item-propose-repair/${item?._id}`,
            {
                reasonOfReturn,
                priceProposal,
                acceptance
            },
        )
            .then((response) => {
                console.log(response)
                onClose()
                setIsLoading(false)
                setClaimed(true)
                addNotification({
                    message: 'Repair proposal has been added successfully',
                    type: 'success',
                })
                toast.success(response?.data?.message, { hideProgressBar: true })
            })
            .catch((error) =>
                toast.error(error.response.data.message, {
                    hideProgressBar: true,
                })
            )
    }
    return (
        <ModalBox
            heading={item?.imei}
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[350px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        Model
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.model?.modelName}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        Storage
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.storage}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" weightV="bold" variant="title">
                        VAT
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.vat}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Sale Price
                    </Text>
                    <TextBackground backgroundColor="b9white" className="w-56">
                        <Text colorV="blue" weightV="normal" variant="title">
                            {item?.salePrice}
                        </Text>
                    </TextBackground>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Reason for return
                    </Text>
                    <TextArea
                        className='w-56'
                        value={reasonOfReturn}
                        onChange={handleReasonOfReturn}
                        placeholder="Enter reason of return"
                        height="100px" // You can adjust the height here
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Price proposal
                    </Text>
                    <TextField
                        value={priceProposal}
                        onChange={handleProposalPrice}
                        placeholder="Enter price"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="body" weightV="bold">
                        Status
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="status"
                            value={acceptance}
                            onChange={handleAcceptanceChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            <option value='Accepted'>Accepted</option>
                            <option value='Declined'>Declined</option>

                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button onClick={handleSave} className='w-40' backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? "Saving" : "Save"}
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
