import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import {
    storageList,
    operationSystemList,
    myDevice,
} from '../../../../../../constant/Storage'
import { useFetch } from '../../../../../../hooks/useFetch'
import { useAuth } from '../../../../../../utils/auth'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { Text } from '../../../../../base/Text'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import Select from 'react-select'
import { usePost } from '../../../../../../hooks/usePost'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const AddModel: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [model, setModel] = useState<string>('')
    const [device, setDevice] = useState<string>('')
    const [storage, setStorage] = useState<string[]>([])
    const [os, setOs] = useState<any>('')
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { sendRequest } = usePost()
    const handleStorageChange = useCallback((e: any) => {
        setStorage(Array.isArray(e) ? e.map((storage) => storage?.label) : [])
    }, [])

    const { getRequest: getModels } = useFetch(`${baseUrl}/mobile/get-models`)
    const handleModelChange = (newValue: any) => {
        setModel(newValue)
    }
    const handleDeviceChange = (event: any) => {
        setDevice(event.target.value)
    }
    // const handleStorageChange = (e: any) => {
    //     setStorage(e.target.value)
    // }
    const handleOsChange = (e: any) => {
        setOs(e.target.value)
    }
    const handleSave = useCallback(async (e: any) => {
        e.preventDefault()
        if (model === '') {
            toast.error('Please, add model name', { hideProgressBar: true });
            return;
        } else if (os === '') {
            toast.error('Please, add Os', { hideProgressBar: true });
            return;
        } else if (device === '') {
            toast.error('Please, add device', { hideProgressBar: true });
            return;
        }

        const urlItem = `${baseUrl}/mobile/add-model`
        await sendRequest(
            urlItem,
            {
                modelName: model,
                os,
                storage,
                device,
            },
        )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'Model added successfully',
                    type: 'success',
                })
                
                getModels()
                setDevice('')
                setModel('')
                setStorage([])
                setOs('')
                onClose()
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data}`, {
                    hideProgressBar: true,
                })
            })
    }, [device, getModels, model, onClose, os, sendRequest, storage])

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            position: "relative",
            boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : null,
            '&:hover': {
                borderColor: '#2684FF',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#2684FF',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: state.isSelected ? '#2684FF' : state.isFocused ? '#f0f0f0' : 'white',
            color: state.isSelected ? 'white' : '#333',
            '&:hover': {
                backgroundColor: '#2684FF',
                color: 'white',
            },
        }),
    };

    return (
        <ModalBox
            heading="Create new model"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4 h-[300px] overflow-auto">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Model
                    </Text>
                    <TextField
                        value={model}
                        onChange={handleModelChange}
                        placeholder="Enter model"
                        
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Device
                    </Text>
                    <div style={{ width: '16rem' }}>
                        <select
                            id="device"
                            value={device}
                            onChange={handleDeviceChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {myDevice?.map((device: any) => (
                                <option key={device} value={device}>
                                    {device}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Operating system
                    </Text>
                    <select
                        value={os}
                        id="operating system"
                        onChange={handleOsChange}
                        className="w-64 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 p-2"
                    >
                        <option value="" >
                            Select operating system
                        </option>
                        {operationSystemList?.map((os: any) => (
                            <option key={os} value={os}>
                                {os}
                            </option>
                        ))}
                    </select>
                </RowAlignCenterSpaced>{' '}
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Storage
                    </Text>

                    <div className="w-64">
                        {storageList?.length > 0 && <Select
                            styles={customStyles}
                            maxMenuHeight={90}
                            options={storageList}
                            onChange={handleStorageChange}
                            isMulti
                        />}
                    </div>
                </RowAlignCenterSpaced>

                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' backgroundColor="lgblue" onClick={handleSave}>
                        <Text colorV="white" variant="title" weightV="normal">
                            Save
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
