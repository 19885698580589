import React, { useState } from 'react'
import { Flex } from '../../../../layout/Flex'
import { Text } from '../../../../base/Text'
import { Button } from '../../../../base/Button'
import { ExportContent } from './ExportModel'
import { ModalBox } from '../../../../base/ModelBox'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { AccessEnums, AccessPermissions } from '../../../../../constant/UserAccess'
import { useAuth } from '../../../../../utils/auth'
import { Filter } from './Filter'
import { Row } from '../../../../layout/Row'


export default function Head({ item, itemsData, handleFilterChange }: any) {
    const auth = useAuth();

    const [modalContent, setModalContent] = useState(null)
    const viewSalesCheck = auth?.selectedProfile?.access?.some((acc) => acc?.module === AccessEnums.SalesInventory && acc.permission.includes(AccessPermissions.View));

    const handleExport = () => {
        setModalContent(viewSalesCheck && <ExportContent item={item} onClose={() => setModalContent(null)} />)
    }

    return (
        <Flex className="pb-4 pt-2">
            <RowAlignCenterSpaced className="gap-x-5 w-full">
                <Row className='gap-x-10'>
                    <Text colorV="blue" variant="head" weightV="bold">
                        Sales inventory
                    </Text>
                    {viewSalesCheck && <Filter onDataChange={handleFilterChange} itemsData={itemsData} />}
                </Row>

                {viewSalesCheck && <Button onClick={handleExport} backgroundColor="green">
                    <Text colorV="white" variant="title" weightV="normal">
                        Export
                    </Text>
                </Button>}
            </RowAlignCenterSpaced>
            {modalContent}
        </Flex>
    )
}
