import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../../base/Button'
import { ModalBox } from '../../../../../base/ModelBox'
import { TextField } from '../../../../../base/Textfield'
import { Column } from '../../../../../layout/Col'
import { Flex } from '../../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../../layout/RowAlignCenterSpaced'
import { Text } from '../../../../../base/Text'
import { useFetch } from '../../../../../../hooks/useFetch'
import { useAuth } from '../../../../../../utils/auth'
import axios from 'axios'
import { toast } from 'react-toastify'
import { myVat, storageList } from '../../../../../../constant/Storage'
import { usePost } from '../../../../../../hooks/usePost'
import { addNotification } from '../../../../../shared/Notification/Notification'
const baseUrl = process.env.REACT_APP_baseUrl

export const AddNewsku: React.FC<{ onClose: () => void }> = ({
    onClose,
}) => {
    const [sku, setSKU] = useState<any>('')
    const [model, setModel] = useState<string>('')
    const [storage, setStorage] = useState<any>()
    const [grade, setGrade] = useState('')
    const [color, setColor] = useState('')
    const [vat, setVat] = useState('')
    const { sendRequest } = usePost()
    const auth = useAuth()
    const token = auth?.user?.token?.token
    const { getRequest: getModels, data: modelsData } = useFetch(
        `${baseUrl}/mobile/get-models`
    )
    const { getRequest: getGrades, data: gradesData } = useFetch(
        `${baseUrl}/grade/get-grades`
    )

    useEffect(() => {
        getModels()
        getGrades()
    }, [getModels, getGrades])


    const handleSKU = (newOption) => {
        setSKU(newOption)
    }
    const handleModelChange = (event: any) => {
        setModel(event.target.value)
    }
    const handleStorageChange = (event: any) => {
        setStorage(event.target.value)
    }

    const handleColorChange = (newValue: any) => {
        setColor(newValue)
    }

    const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value
        setGrade(newValue)
    }

    const handleVatChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value
        setVat(newValue)
    }

    const handleSave = useCallback(async (e: any) => {
        e.preventDefault()
        if (!model) {
            alert('Please, select Model')
            return
        } else if (!sku) {
            alert('Please, add sku')
            return
        } else if (!grade) {
            alert('Please, add grade')
            return
        }
        else if (!vat) {
            alert('Please, add vat')
            return
        }
        else if (!color) {
            alert('Please, add color')
            return
        }
        else if (!storage) {
            alert('Please, add color')
            return
        }
        const urlInventoryItem = `${baseUrl}/sale-sku/add-sale-inventory-sku`
        await sendRequest(
            urlInventoryItem,
            {
                model,
                sku,
                grade,
                color,
                vat,
                storage
            },
        )
            .then(() => {
                toast.success('Added Successfully', { hideProgressBar: true })
                addNotification({
                    message: 'SKU has been added successfully',
                    type: 'success',
                })
                setModel('');
                setColor('');
                setSKU('');
                setGrade('')
                setStorage('')
                setVat('')
                onClose()
            })
            .catch((error) => {
                toast.error(`Error: ${error?.response?.data}`, {
                    hideProgressBar: true,
                })
            })
    }, [color, grade, model, onClose, sendRequest, sku, storage, vat])
    return (
        <ModalBox
            heading="Create new Sale SKU"
            onClose={onClose}
            top="50%"
            height="auto"
            width="500px"
        >
            <Column className="mt-3 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        SKU
                    </Text>
                    <TextField
                        value={sku}
                        onChange={handleSKU}
                        placeholder="Enter SKU"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        Model
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="device"
                            value={model}
                            onChange={handleModelChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {modelsData?.map((mdl: any) => (
                                <option key={mdl?._id} value={mdl?._id}>
                                    {mdl?.modelName}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        Storage
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="storage"
                            value={storage}
                            onChange={handleStorageChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                        >
                            <option>Select</option>
                            {storageList?.map((stge: any) => (
                                <option key={stge.value} value={stge.value}>
                                    {stge.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="normal">
                        Color
                    </Text>
                    <TextField
                        value={color}
                        onChange={handleColorChange}
                        placeholder="Enter device"
                        
                        width="14rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-5">
                    <Text
                        colorV="blue"
                        weightV="normal"
                        variant="title"
                    >
                        Grade
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="grade"
                            value={grade}
                            onChange={handleGradeChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                                >
                            <option>Select</option>
                            {gradesData?.map((grade: any) => (
                                <option key={grade._id} value={grade?._id}>
                                    {grade.grade}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className="gap-y-5">
                    <Text
                        colorV="blue"
                        weightV="normal"
                        variant="title"
                    >
                        VAT
                    </Text>
                    <div style={{ width: '14rem' }}>
                        <select
                            id="vat"
                            value={vat}
                            onChange={handleVatChange}
                            className="w-full hover:cursor-pointer text-sm   py-2 px-1"                                >
                            <option>Select</option>
                            {myVat?.map((vat: any) => (
                                <option key={vat} value={vat}>
                                    {vat}
                                </option>
                            ))}
                        </select>
                    </div>
                </RowAlignCenterSpaced>
                <Flex className="gap-x-4 mt-4">
                    <Button className='w-32' onClick={handleSave} backgroundColor="lgblue">
                        <Text colorV="white" variant="title" weightV="normal">
                            Save
                        </Text>
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    )
}
