import React, { useCallback, useEffect, useState } from 'react'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { HeadButtons } from './component/HeadButtons'
import { SparepartSKUTable } from './component/SparepartsSKUTable'
import { useFetch } from '../../../../../hooks/useFetch'
import { Row } from '../../../../layout/Row'
import { Filter } from './component/Filter'
const baseUrl = process.env.REACT_APP_baseUrl

export const SparepartsInventory = () => {
    const [filteredData, setFilteredData] = useState(null);
    const { getRequest: getSparepartSkuItems, data: sparepartData } = useFetch(
        `${baseUrl}/sparepart/get-sparepart-sku`
    )
    useEffect(() => {
        getSparepartSkuItems()
    }, [getSparepartSkuItems])

    const getSparepartSKU = () => {
        getSparepartSkuItems()
    };
    const handleFilterChange = useCallback((data) => {
        setFilteredData(data);
    }, []);
    const refetch = () => {
        getSparepartSkuItems()
    }

    return (
        <Flex className='w-full'>
            <Column className='w-full'>
                <Row className="gap-x-3">
                    <HeadButtons handleSparepartSku={getSparepartSKU} />
                    <Filter onDataChange={handleFilterChange} itemsData={sparepartData} refetch={refetch} />
                </Row>
                <SparepartSKUTable handleSparepartSku={getSparepartSKU} items={filteredData?.length > 0 ? filteredData : sparepartData} />
            </Column>
        </Flex>
    )
}
