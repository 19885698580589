import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ImeiHistory } from '../../compound/history/ImeiHistory';
import { SkuHistory } from '../../compound/history/SkuHistory';
import { UserHistory } from '../../compound/history/UserHistory';
import { SearchResultsDropdown } from '../SearchResult';
import axios, { CancelTokenSource } from 'axios'; // Import CancelTokenSource
import { toast } from 'react-toastify';
import { useAuth } from '../../../utils/auth';
import { Column } from '../../layout/Col';
import { ModalSearch } from './ModalSearch';
import { SearchBarGlobal } from '../../base/SearchbarGlobal';
import { PonHistory } from '../../compound/history/PonHistory';
import { InvoiceHistory } from '../../compound/history/InvoiceHistory';

interface SearchResult {
  item?: string[];
  sku?: string[];
  searchedUser?: string[];
  isCustomer?: boolean;
}

const baseUrl = process.env.REACT_APP_baseUrl;

export const SearchInput: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [searchResults, setSearchResults] = useState<SearchResult>({});
  const [showResults, setShowResults] = useState(false);
  const [selectedItemType, setSelectedItemType] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();

  const token = auth.user?.token?.token;
  const searchInputRef = useRef<HTMLInputElement>(null);
  const company = auth.selectedCompany;
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null); // Ref to store the cancel token source
  //   const { getRequest: getInvoiceItems, data: itemsData } = useFetch(
  //     `${baseUrl}/inventory/get-invoice-items`
  // );

  // useEffect(() => {
  //     getInvoiceItems()
  // }, [getInvoiceItems])
  useEffect(() => {
    // Focus on the input element when the modal opens
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    return () => {
      // Cleanup function to cancel the request if component unmounts
      cancelPreviousRequest();
    };
  }, []);

  let url = `${baseUrl}/global-search/key-word`
  if (auth?.user?.role.includes('customer')) {
    url = `${baseUrl}/global-search/customer-key-word`
  }

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      if (!searchTerm.trim()) {
        setShowResults(false);
        return;
      }

      cancelPreviousRequest();
      setIsLoading(true)
      // Create a new cancel token source
      cancelTokenSourceRef.current = axios.CancelToken.source();

      try {
        const response = await axios.post<SearchResult>(
          url,
          { data: searchTerm },
          {
            headers: {
              Authorization: token,
              Company: company._id,
            },
            cancelToken: cancelTokenSourceRef.current.token, // Pass the cancel token to the request config
          }
        );

        setSearchResults(response?.data);
        setShowResults(true);
        setIsLoading(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);

        } else {
          console.error(error);
          onClose();
          toast.error('Error in search');
        }
      } 
    },
    [company._id, onClose, token, url]
  );

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Previous request canceled');
    }
  };

  const handleItemClick = (item: string, type: string) => {
    setShowResults(true);
    setSelectedItem(item);
    setSelectedItemType(type);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setSelectedItemType(null);
  };
  console.log({ searchResults });
  return (
    <>
      <ModalSearch onClose={onClose}>
        <Column>
          <SearchBarGlobal
            onSearch={handleSearch}
            isLoading={isLoading}
            className="sticky top-0 bg-white"
            inputRef={searchInputRef} // Pass the ref to the SearchBar
          />
          <div className="mt-4" />
          {showResults && (
            <SearchResultsDropdown
              id="search-results-dropdown"
              item={searchResults.item}
              sku={searchResults.sku}
              isCustomer={searchResults?.isCustomer}
              user={searchResults.searchedUser}
              handleItemClick={handleItemClick}
              customer={auth?.user?.role.includes('customer')}
            />
          )}
        </Column>
      </ModalSearch>

      {selectedItemType === 'item' && (
        <ImeiHistory customer={auth?.user?.role.includes('customer')} item={selectedItem} onClose={closeModal} />
      )}

      {selectedItemType === 'sku' && <SkuHistory item={selectedItem} onClose={closeModal} />}

      {selectedItemType === 'user' && <UserHistory item={selectedItem} onClose={closeModal} />}

      {selectedItemType === 'pon' && <PonHistory item={selectedItem} onClose={closeModal} />}

      {selectedItemType === 'invoice' && <InvoiceHistory item={selectedItem} onClose={closeModal} />}

    </>
  );
};
