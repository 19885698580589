import React, { useEffect } from 'react'
import { PrimaryCard } from '../../compound/Page/PrimaryCard'
import { Flex } from '../../layout/Flex'
import { Table } from '../../shared/Table'
import { useNavigate } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import moment from 'moment'

export const CustomerRMATable: React.FC<{ data: any }> = ({
    data,
}) => {
    const navigate = useNavigate()
    let extractedData = [];
    console.log(data)
    if (data) {
        extractedData = data?.map((item) => {
            return {
                id: item?._id,
                'RMA N': item?.rmaNumber,
                Submitted: moment(item?.createdAt).format('DD MMM YYYY'),
                Status: item?.status,
                'Total value': item?.purchaseOrder?.totalValue
            };
        });
    }

    const columns = ['RMA N', 'Submitted', 'Status', 'Total value']

    const handleRowClick: any = (item: any) => {
        navigate(`/rma/detail/${item?.id}`);
    };

    return (
        <>
            <Flex className="mt-5 w-full">
                <PrimaryCard className="w-full">
                    <Table
                        columns={columns}
                        data={extractedData}
                        clickable={true}
                        showActions={false}
                        handleRowClick={handleRowClick}
                    />
                </PrimaryCard>
            </Flex>
        </>
    )
}
