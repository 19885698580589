import React, { useCallback, useEffect, useState } from 'react';
import { ModalBox } from '../../../../base/ModelBox';
import { RowAlignCenter } from '../../../../layout/RowAlignCenter';
import { Text } from '../../../../base/Text';
import { Button } from '../../../../base/Button';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { useFetch } from '../../../../../hooks/useFetch';

const baseUrl = process.env.REACT_APP_baseUrl;

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};

export const ExportModel: React.FC<{ onClose: () => void, data: any }> = ({ onClose, data: itemsData }) => {
    const currentDate = moment().format('DDMMYYYY');
    const [isLoading, setIsLoading] = useState(false);
    // const { getRequest: getDownloadItems, data: downloadData } = useFetch(
    //     `${baseUrl}/inventory/get-inventory-pdf`
    // );
console.log({itemsData});
    // useEffect(() => {
    //     if (!itemsData?.items?.length) {
    //         setIsLoading(true);
    //         getDownloadItems().finally(() => setIsLoading(false));
    //     }
    // }, [itemsData, getDownloadItems]);

    const handleInhouseInventoryPDF = useCallback(async () => {
        // const dataToUse = itemsData?.items?.length ? itemsData.items : downloadData?.items;
        if (itemsData?.items?.length) {
            const body = itemsData?.items?.map((itm: any) => {
                return [
                    moment(itm?.dateInvoiced).format('DD/MM/YYYY') || '',
                    itm?.model?.modelName || '',
                    itm?.storage || '',
                    itm?.vat || '',
                    itm?.imei || '',
                    itm?.codes?.map((cd) => cd || ' ').join(' ') || '',
                    itm?.sortingEventKey || '',
                ];
            });
            body.unshift([
                'Date',
                'Model',
                'Storage',
                'VAT',
                'IMEI',
                'Codes ',
                'Status',
            ]);
            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            };
            // @ts-ignore
            pdfMake.createPdf(docDefinition).download(`${currentDate}-inventory-report`);
            onClose();
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [currentDate, itemsData, onClose]);

    const handleInhouseInventoryXLSX = useCallback(async () => {
        if (itemsData?.items?.length) {
            const wsData = itemsData?.items?.map((element: any) => ({
                Date: moment(element?.dateInvoiced).format('DD/MM/YYYY') || '',
                Model: element?.model?.modelName || '',
                Storage: element?.storage || '',
                VAT: element?.vat || '',
                IMEI: element?.imei || '',
                Codes: element?.codes?.map((cd) => cd || ' ').join(' ') || '',
                Status: element?.sortingEventKey || '',
            }));

            const ws = XLSX.utils.json_to_sheet(wsData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Inventory Report");

            XLSX.writeFile(wb, `${currentDate}-inventory-report.xlsx`);
            onClose();
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [currentDate, itemsData, onClose]);

    return (
        <ModalBox heading="Export" onClose={onClose}>
            <RowAlignCenter className="text-center gap-x-4">
                <Button onClick={handleInhouseInventoryPDF} backgroundColor="purple" disabled={isLoading}>
                    <Text colorV="white" variant="title" weightV="normal">
                        {isLoading ? 'Loading...' : 'Export PDF'}
                    </Text>
                </Button>
                <Button onClick={handleInhouseInventoryXLSX} backgroundColor="blue" disabled={isLoading}>
                    <Text colorV="white" variant="title" weightV="normal">
                        {isLoading ? 'Loading...' : 'Export XLSX'}
                    </Text>
                </Button>
            </RowAlignCenter>
        </ModalBox>
    );
};
