import { Outlet } from 'react-router-dom'
import { Breadcrumb } from '../components/base/Breadcrumb'
import { Column } from '../components/layout/Col'
import { Row } from '../components/layout/Row'
import { useBreadcrumb } from '../hooks/useBreadcrumb'
import Header from '../components/shared/Header/Header'

export default function Rma() {
    const breadcrumbItems = useBreadcrumb()

    return (
        <div>
            <Column className=" ml-5">
                <Header />
                <Row className="my-1.5">
                    <Breadcrumb items={breadcrumbItems} separator="/" />
                </Row>
                <Outlet />
            </Column>
        </div>
    )
}
