import React from 'react'
import Header from '../components/shared/Header/Header'
import { Breadcrumb } from '../components/base/Breadcrumb'
import { Column } from '../components/layout/Col'
import { useBreadcrumb } from '../hooks/useBreadcrumb'
import { Users } from '../components/productPortal/userManagement/users/Users'

export const UserManagement = () => {
    const breadcrumbItems = useBreadcrumb()

    return (
        <>
            <Header />
            <Column className="my-1.5">
                <Breadcrumb items={breadcrumbItems} separator="/" />
                <Users/>
            </Column>
        </>
    )
}
