import React, { useEffect, useState } from 'react';
import { ModalBox } from '../../../../base/ModelBox';
import { Button } from '../../../../base/Button';
import { TextField } from '../../../../base/Textfield';
import { Column } from '../../../../layout/Col';
import { Flex } from '../../../../layout/Flex';
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../base/Text';
import { Row } from '../../../../layout/Row';
import { FileUpload } from '../../../../shared/Fileupload';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../utils/auth';
import { useFetch } from '../../../../../hooks/useFetch';
import { TextBackground } from '../../../../base/TextBackground';
import { Icon } from '../../../../base/Icon';
import { usePost } from '../../../../../hooks/usePost';
import { downloadTemplate } from '../../../../../download/downloadService';
const baseUrl = process.env.REACT_APP_baseUrl;

export const BulkInventoryContent: React.FC<{ onClose: () => void }> = ({
    onClose,
}) => {
    const [supplier, setSupplier] = useState<string>('');
    const [rmaSupplier, setRmaSupplier] = useState<string>('');
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false); // Added isLoading state
    const [invoiceNumber, setInvoiceNumber] = useState<any>('')
    const { sendRequest } = usePost();

    const auth = useAuth();
    const token = auth?.user?.token?.token;
    const company = auth.selectedCompany;

    const { getRequest, data } = useFetch(`${baseUrl}/partner/get-partners`);

    useEffect(() => {
        getRequest();
    }, [getRequest]);

    const [filteredSuppliers, setFilteredSuppliers] = useState<any[]>([]);

    useEffect(() => {
        if (data) {
            const suppliers = data.filter((item) => item.tags.includes('supplier'));
            setFilteredSuppliers(suppliers);
        }
    }, [data]);
    const handleInvoiceChange = (newValue: any) => {
        setInvoiceNumber(newValue)
    }
    const handleFileUpload = (file: File) => {
        setUploadedFile(file);
    };

    const handleSupplierChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSupplier(event.target.value);
    };

    const handleRmaSupplierChange = (newValue: any) => {
        setRmaSupplier(newValue);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!supplier) {
            alert('Please, add supplier')
            return
        } else if (!rmaSupplier) {
            alert('Please, add RMA supplier')
            return
        }
        else if (!invoiceNumber) {
            alert('Please, add invoice number')
            return
        }
        setIsLoading(true); // Set loading to true on button click

        const formData = new FormData();
        formData.append('supplier', supplier);
        formData.append('rmaSupplier', rmaSupplier);
        formData.append('invoiceNumber', invoiceNumber);

        if (uploadedFile) {
            formData.append('file', uploadedFile);
        }
        const urlItem = `${baseUrl}/inventory/upload-bulk-inventory`;
        try {
            const response = await axios.post(urlItem, formData, {
                headers: {
                    Authorization: token as string,
                    'Content-Type': 'multipart/form-data',
                    Company: company._id,
                },
            });
            if (response) {
                toast.success(response?.data?.message, { hideProgressBar: true });
                const fileId = response?.data?.fileId
                const responseData = await sendRequest(`${baseUrl}/inventory/save-bulk-inventory`, {
                    fileId,
                    supplier,
                    rmaSupplier,
                    invoiceNumber
                });
                if (responseData) {
                    toast.success(response?.data?.message, { hideProgressBar: true });
                }

                setUploadedFile(null);
                setSupplier('');
                setRmaSupplier('');
                onClose();
            }
        } catch (error) {
            toast.error(`Error: ${error?.response?.data?.message}` || "System error", {
                hideProgressBar: true,
            });
        } finally {
            setIsLoading(false); // Set loading to false after the request completes
        }
    };

    return (
        <ModalBox
            heading="Bulk Inventory Upload"
            height="auto"
            width="500px"
            onClose={onClose}
        >
            <Column className="mt-3 gap-y-4">
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Supplier
                    </Text>
                    {filteredSuppliers.length > 0 ? (
                        <div style={{ width: '16rem' }}>
                            <select
                                id="supplier"
                                value={supplier}
                                onChange={handleSupplierChange}
                                className="w-full  hover:cursor-pointer  rounded-[8px] py-2 px-2"
                            >
                                <option>Select</option>
                                {filteredSuppliers.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.companyName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        <TextBackground className='w-56' backgroundColor='b9white'>
                            Please, add supplier
                        </TextBackground>
                    )}
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced className='gap-x-10'>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Vendor RMA
                    </Text>
                    <TextField
                        value={rmaSupplier}
                        onChange={handleRmaSupplierChange}
                        placeholder="00 days"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <RowAlignCenterSpaced>
                    <Text colorV="blue" variant="title" weightV="bold">
                        Invoice number
                    </Text>
                    <TextField
                        value={invoiceNumber}
                        onChange={handleInvoiceChange}
                        placeholder="Enter invoice number"
                        width="16rem"
                    />
                </RowAlignCenterSpaced>
                <Column className="mt-4 gap-y-2">
                    <FileUpload onFileUpload={handleFileUpload} />
                    {uploadedFile && (
                        <Row className='gap-x-3 items-center'>
                            <Text colorV="blue" variant="title" weightV="normal">
                                Uploaded File: {uploadedFile?.name}
                            </Text>
                            <span className='cursor-pointer' onClick={() => setUploadedFile(null)}>
                                <Icon name="cross" color="black" size={16} />
                            </span>
                        </Row>
                    )}
                </Column>
                <Flex className="gap-x-4 mt-3">
                    <Button
                        className='w-32'
                        onClick={handleSubmit}
                        backgroundColor="lgblue"
                        disabled={isLoading} // Disable the button when loading
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            {isLoading ? 'Uploading...' : 'Upload'}
                        </Text>
                    </Button>
                    <Button
                        onClick={() => downloadTemplate('bulkUpload')}
                        backgroundColor="green"
                        className='flex flex-row items-center gap-2 text-white'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z" />
                            <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
                        </svg>

                        Download template
                    </Button>
                </Flex>
            </Column>
        </ModalBox>
    );
};
