import React, { useState } from 'react'
import { Table } from '../../../../../shared/Table'
import { PrimaryCard } from '../../../../Page/PrimaryCard'
import { RenameEvent } from './Modals/RenameEvent'
import { Column } from '../../../../../layout/Col'
import { Text } from '../../../../../base/Text'
export const SalesEvent = ({ sortingLogic, refetch, eventTypes }: { sortingLogic: any[], refetch: () => void, eventTypes: any[] }) => {
  const columns = ['Stauts Name', 'Status Type']
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const closeModal = () => {
    setSelectedEvent(null);
    setModalOpen(false);
    refetch()
  };
  const filteredData = sortingLogic?.filter((item) => item.module === 'sales');

  const data = filteredData.map((item) => ({
    key: item.key,
    'Stauts Name': item.name,
    'Status Type': item.type,
    // Assuming you have a function or component for actions
  }));

  const handleSelectChange = (option, item) => {
    setSelectedEvent(item);
    setModalOpen(true);
  }

  return (
    <PrimaryCard className="w-full">
      <Column className='gap-y-4'>
        <Text
          colorV="blue"
          variant="heading"
          weightV="bold"
        >
          Sales
        </Text>
        <Table
          options={['Rename']}
          columns={columns}
          data={data}
          showActions={true}
          handleDropdownChange={handleSelectChange}
        />
      </Column>

      {isModalOpen && (
        <RenameEvent
          eventTypes={eventTypes}
          onClose={closeModal}
          eventData={selectedEvent}
        />
      )}
    </PrimaryCard>
  )
}
