import React, { useCallback } from 'react';
import { Button } from '../../../../../base/Button';
import { ModalBox } from '../../../../../base/ModelBox';
import { RowAlignCenter } from '../../../../../layout/RowAlignCenter';
import { Text } from '../../../../../base/Text';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx'; // Import XLSX library
import moment from 'moment'; // Assuming moment.js is used for date formatting

export const ExportModel: React.FC<{ onClose: () => void; items: any }> = ({
    onClose,
    items,
}) => {
    console.log(items);

    const handlePDF = useCallback(async () => {
        if (items?.length > 0) {
            const body = items?.map((itm: any) => {
                return [
                    itm?.sku,
                    itm?.modelName?.modelName || '',
                    itm?.storage,
                    itm?.vat,
                    itm?.grade.grade,
                    itm?.color,
                ];
            });
            body.unshift(['Sku', 'Model', 'Storage', 'VAT', 'Grade', 'Color']);

            const docDefinition = {
                pageOrientation: 'landscape',
                content: [
                    {
                        text: 'Manage Sales SKU', // Add your heading here
                        alignment: 'center',
                        fontSize: 18,
                        margin: [0, 0, 0, 10], // Adjust margins as needed
                    },
                    {
                        alignment: 'center',
                        table: {
                            headerRows: 1,
                            body,
                            margin: [10, 10],
                        },
                    },
                ],
                styles: {
                    tableStyle: {
                        margin: [0, 20, 20, 0],
                    },
                },
            };

            // @ts-ignore
            pdfMake.createPdf(docDefinition).download('Salessku');
            onClose();
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [items, onClose]);

    const handleXLSX = useCallback(async () => {
        if (items.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(
                items.map((element) => ({
                    Sku: element?.sku,
                    Model: element?.modelName?.modelName || '',
                    Storage: element?.storage,
                    Vat: element?.vat,
                    Grade: element?.grade?.grade,
                    Color: element?.color,
                }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales SKU');

            const buffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            const data = new Blob([buffer], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            saveBlob(data, 'salesSku.xlsx');
            onClose();
        } else {
            toast.error('Error: No data found', {
                hideProgressBar: true,
            });
        }
    }, [items, onClose]);

    function saveBlob(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
        document.body.removeChild(a);
    }

    return (
        <ModalBox height="auto" heading="Export" onClose={onClose}>
            <RowAlignCenter>
                <Text colorV="blue" weightV="bold" variant="head">
                    Attention Required!
                </Text>
            </RowAlignCenter>
            <RowAlignCenter className="mt-4 text-center gap-x-4">
                <Button onClick={handlePDF} backgroundColor="purple">
                    <Text colorV="white" variant="title" weightV="normal">
                        Export PDF
                    </Text>
                </Button>
                <Button onClick={handleXLSX} backgroundColor="green"> {/* Adjusted button color to reflect XLSX */}
                    <Text colorV="white" variant="title" weightV="normal">
                        Export XLSX
                    </Text>
                </Button>
            </RowAlignCenter>
        </ModalBox>
    );
};
