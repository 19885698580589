/* eslint-disable react/react-in-jsx-scope */
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Logo from '../assets/logo.png'
import { Button } from '../components/base/Button'
import { Text } from '../components/base/Text'
import { TextField } from '../components/base/Textfield'
import Card from '../components/compound/Page/Card/Card'
import { Pagebg } from '../components/compound/Page/Pagebg'

export default function ForgetPassword() {
    const [email, setEmail] = useState('')
    const navigate = useNavigate()
    const baseUrl = process.env.REACT_APP_baseUrl

    const handleInputChange = (newValue: any) => {
        setEmail(newValue)
    }
    console.log(email)
    const handleSubmit = (e: any) => {
        e.preventDefault()
        axios
            .put(`${baseUrl}/auth/forget-password`, {
                email,
            })
            .then(() => {
                toast.success('Please check your Email', {
                    hideProgressBar: true,
                })
                navigate('/verifyCode')
            })
            .catch((error) => {
                toast.error(`Error: ${error.response.data.message}`, {
                    hideProgressBar: true,
                })
            })
    }

    return (
        <Pagebg className="bg-gray-day-1  flex justify-center items-center h-screen">
            <Card className="bg-white max-w-lg w-full">
                <form onSubmit={handleSubmit}>
                    <div className="px-5 py-10">
                        <div className="flex justify-center">
                            <img alt="" className="h-20 w-36" src={Logo} />
                        </div>
                        <div className="flex justify-center mt-5">
                            <Text
                                colorV="mid-blue"
                                variant="heading"
                                weightV="normal"
                            >
                                Password recovery
                            </Text>
                        </div>
                        <div>
                            <div className="my-2">
                                <Text
                                    colorV="blue"
                                    variant="title"
                                    weightV="bold"
                                >
                                    Your email
                                </Text>
                            </div>
                            <TextField
                                value={email}
                                onChange={handleInputChange}
                                placeholder="sergei@opsarlas.io"
                                className='w-full'
                            />
                        </div>
                        <div className="flex justify-center mt-7 ">
                            <Button
                                onClick={handleSubmit}
                                className="w-full rounded-2xl py-3"
                            >
                                <Text
                                    colorV="white"
                                    variant="body"
                                    weightV="bold"
                                >
                                    Send Code
                                </Text>
                            </Button>
                        </div>
                    </div>
                </form>
            </Card>
        </Pagebg>
    )
}
