import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../../utils/auth';
import { Button } from '../../../../../../base/Button';
import { ModalBox } from '../../../../../../base/ModelBox';
import { TextField } from '../../../../../../base/Textfield';
import { Column } from '../../../../../../layout/Col';
import { Flex } from '../../../../../../layout/Flex';
import { RowAlignCenterSpaced } from '../../../../../../layout/RowAlignCenterSpaced';
import { Text } from '../../../../../../base/Text';
import { useEdit } from '../../../../../../../hooks/useEdit';
import { addNotification } from '../../../../../../shared/Notification/Notification';
const baseUrl = process.env.REACT_APP_baseUrl;

export const SubmitRma: React.FC<{ onClose: () => void; data: any }> = ({
  onClose,
  data
}) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [isFirstModalOpen, setFirstModalOpen] = useState(true);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState<any>();
  const [loading, setIsLoading] = useState(false)
  const { sendEditRequest } = useEdit()

  const handleTrackingNumber = (newValue: any) => {
    setTrackingNumber(newValue);
  };

  const handleSave = useCallback(async (e: any) => {
    e.preventDefault();
    if (!trackingNumber) {
      alert('Please, enter tracking number');
      return;
    }
setIsLoading(true)
    await sendEditRequest(
      `${baseUrl}/purchase/edit-purchase-tracking-number/${data?._id}`,
      {
        trackingNumber,
      },

    )
      .then((response) => {
        console.log(response);
        setUpdatedData(response?.data?.savedRma);
        setTrackingNumber('');
        toast.success(response?.data?.message, { hideProgressBar: true });
        addNotification({
          message: 'Item has been updated successfully',
          type: 'success',
        });
        setFirstModalOpen(false);
        setSecondModalOpen(true);
        setIsLoading(false)
      })
      .catch((error) =>
        toast.error(error?.response?.data?.message, {
          hideProgressBar: true,
        })
      );
  }, [data?._id, sendEditRequest, trackingNumber])

  return (
    <>
      {isFirstModalOpen && (
        <ModalBox
          heading="Finalise your RMA report"
          onClose={onClose}
          top="50%"
          height="auto"
          width="500px"
        >
          <Column className="mt-3 gap-y-4 h-[130px] overflow-auto">
            <RowAlignCenterSpaced>
              <Text colorV="blue" variant="body" weightV="bold">
                Tracking number
              </Text>
              <TextField
                value={trackingNumber}
                onChange={handleTrackingNumber}
                placeholder="Enter tracking number"
                
                width="18rem"
              />
            </RowAlignCenterSpaced>
            <Flex className="gap-x-4 mt-4">
              <Button onClick={handleSave} className="w-40" backgroundColor="lgblue">
                <Text colorV="white" variant="title" weightV="normal">
                 {loading ? "Submitting" : "Submit report"}
                </Text>
              </Button>
            </Flex>
          </Column>
        </ModalBox>
      )}

      {isSecondModalOpen && updatedData && (
        <ModalBox
          heading="Thank you for submitting RMA report"
          onClose={() => {
            setSecondModalOpen(false);
            onClose(); // Close the first modal
          }}
          top="50%"
          height="auto"
          width="500px"
        >
          <Column className="mt-3 gap-y-4 h-[150px] overflow-auto text-center justify-center">
            {updatedData && (
              <>
                <Text colorV="blue" weightV="normal" variant="title">
                  RMA report has been submitted
                </Text>
                <Text colorV="blue" weightV="normal" variant="title">
                  Please mark <b>{updatedData.rmaNumber} </b>on the box.

                </Text>
                <div className="mt-4 justify-center">
                  <Button onClick={() => setSecondModalOpen(false)} className="w-40" backgroundColor="lgblue">
                    <Text colorV="white" variant="title" weightV="normal">
                      Ok
                    </Text>
                  </Button>
                </div>
              </>
            )}
          </Column>
        </ModalBox>
      )}
    </>
  );
};
