import axios from 'axios'
import moment from 'moment'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { useCallback, useState } from 'react'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import SparepartsReports from '../components/SparepartReport'
import TopHeader from '../components/TopHeader'
import { Events } from '../constants/eventEnum'
import { useAuth } from '../utils/auth'

const baseUrl = process.env.REACT_APP_baseUrl

;(pdfMake as any).vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
}

export default function Reports() {
    const [reports, setReports] = useState<any>()
    const [event, setEvent] = useState<any>()
    const [endDate, setEndDate] = useState(
        new Date(moment().format('DD MMM YYYY'))
    )
    const [startDate, setStartDate] = useState(
        new Date(moment().subtract(1, 'months').format('DD MMM YYYY'))
    )

    const auth = useAuth()
    const token = auth?.user?.token?.token
    const getRepairTime = useCallback((reports) => {
        const repairTime = reports?.repairs?.at(-1)
        const startTime = moment(repairTime?.startAt).format(
            'DD/MM/YYYY HH:mm:ss'
        )
        const finishTime = moment(repairTime?.finishAt).format(
            'DD/MM/YYYY HH:mm:ss'
        )
        const ms = moment(finishTime, 'DD/MM/YYYY HH:mm:ss:SSS').diff(
            moment(startTime, 'DD/MM/YYYY HH:mm:ss:SSS')
        )
        const diffDuration = moment.duration(ms)
        if (repairTime?.startAt && repairTime?.finishAt) {
            return (
                <h1>
                    {' '}
                    <span> {diffDuration?.days()}d </span>
                    <span> {diffDuration?.hours()}h </span>
                    <span> {diffDuration?.minutes()}m </span>
                    <span> {diffDuration?.seconds()}s </span>
                </h1>
            )
        } else if (repairTime?.startAt && repairTime?.cancelAt) {
            return <h1>Repair cancelled</h1>
        } else {
            return <h1>Repair Not Started</h1>
        }
    }, [])

    const getRepairTimePdf = useCallback((reports) => {
        const repairTime = reports?.repairs?.at(-1)
        const startTime = moment(repairTime?.startAt).format(
            'DD/MM/YYYY HH:mm:ss'
        )
        const finishTime = moment(repairTime?.finishAt).format(
            'DD/MM/YYYY HH:mm:ss'
        )
        const ms = moment(finishTime, 'DD/MM/YYYY HH:mm:ss:SSS').diff(
            moment(startTime, 'DD/MM/YYYY HH:mm:ss:SSS')
        )
        const diffDuration = moment.duration(ms)
        if (repairTime?.startAt && repairTime?.finishAt) {
            return (
                diffDuration.days() +
                'd ' +
                diffDuration.hours() +
                'h ' +
                diffDuration.minutes() +
                'm ' +
                diffDuration.seconds() +
                's'
            )
        } else if (repairTime?.startAt && repairTime?.cancelAt) {
            return 'Repair cancelled'
        } else {
            return 'Repair Not Started'
        }
    }, [])

    const handleDownload = useCallback(async () => {
        await axios
            .post(
                `${baseUrl}/repair/repairtime`,
                {
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: moment(endDate).format('YYYY-MM-DD'),
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then((response) => {
                let reportData: ReportDataType[] = []
                for (const item of response?.data || []) {
                    for (const repair of item.repairs) {
                        const isExist = reportData?.find(
                            (report) =>
                                report?.userId === repair?.finishedBy?._id
                        )

                        if (isExist) {
                            const updatedReport = reportData.map((report) => {
                                const isExist =
                                    report.userId === repair?.finishedBy?._id
                                if (isExist) {
                                    return {
                                        user: report.user,
                                        userId: report.userId,
                                        items: [...report.items, item],
                                    }
                                } else {
                                    return report
                                }
                            })
                            reportData = updatedReport
                        } else {
                            reportData.push({
                                user: repair.finishedBy,
                                userId: repair.finishedBy?._id,
                                items: [item],
                            })
                        }
                    }
                }
                setReports(reportData)
                if (reportData) {
                    const content = []
                    content.push({
                        text: `Users Repairs report from ${moment(
                            startDate
                        ).format('"DD MMM YYYY"')} to ${moment(endDate).format(
                            '"DD MMM YYYY"'
                        )}`,
                        bold: true,
                        alignment: 'center',
                    })
                    for (const report of reportData) {
                        const tableContent = []
                        let repairPoints = 0
                        for (const item of report.items) {
                            console.log(item)
                            tableContent.push([
                                item?.model?.modelName || '',
                                item?.storage || '',
                                item?.vat || '',
                                item?.imei || '',
                                getRepairTimePdf(item),
                                moment(
                                    item?.repairs[item?.repairs?.length - 1]
                                        .finishAt
                                ).format('DD MMM YYYY'),
                                item?.spareParts?.map(
                                    ({ sparePart }) => sparePart?.name
                                ) || '',
                                item?.codes?.toString() || '',
                                item?.codesAfterFix?.toString() || '',
                                item?.repairs[item?.repairs?.length - 1]
                                    ?.repairPoints || 0,
                            ])
                            repairPoints +=
                                item?.repairs[item?.repairs?.length - 1]
                                    ?.repairPoints || 0
                        }
                        tableContent.unshift([
                            'Model',
                            'Storage',
                            'Vat',
                            'IMEI',
                            'Repair time',
                            'Finish At',
                            'Spare Parts',
                            'Codes before fix',
                            'Codes after fix',
                            'Repair points',
                        ])
                        content.push({
                            margin: [0, 15, 0, 8],
                            bold: true,
                            text: `${report.user?.firstName} ${report.user
                                ?.lastName} (${report?.user?.email}) repaired ${
                                tableContent.length - 1
                            } devices with total repair points ${repairPoints}`,
                        })
                        content.push({ table: { body: tableContent } })
                    }

                    // eslint-disable-next-line no-var
                    var docDefinition = {
                        pageOrientation: 'landscape',
                        content,
                    }
            // @ts-ignore
                    pdfMake.createPdf(docDefinition).download('Repair_reportPDF')
                }
            })
    }, [endDate, getRepairTimePdf, startDate, token])

    const calSparePartCost = useCallback((item) => {
        let cost = 0
        item?.spareParts?.map((sp) => {
            cost = cost + sp?.price
        })
        return cost
    }, [])

    const handleInventoryDownload = useCallback(async () => {
        await axios
            .post(
                `${baseUrl}/inventory/dates`,
                {
                    startDate,
                    endDate,
                    event,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then((response) => {
                const responseItem = response?.data
                if (responseItem.length > 0) {
                    const body = responseItem?.map((itm: any) => {
                        return [
                            moment(itm?.dateInvoiced).format('DD/MM/YYYY'),
                            itm?.model?.modelName || '',
                            itm?.storage,
                            itm?.vat.slice(0, 1),
                            itm?.imei,
                            itm?.unitPrice,
                            itm?.codes?.map((cd) => cd || ' ').join(' ') || '',
                            itm?.codesAfterFix
                                ?.map((cd) => cd || ' ')
                                .join(' ') || '',
                            itm?.comment || ' ',
                            itm?.spareParts
                                ?.map(({ sparePart }) => sparePart?.name || '')
                                .join(' ') || '',
                            calSparePartCost(itm) || ' ',
                            itm?.status || ' ',
                            itm?.repairCost || ' ',
                        ]
                    })
                    body.unshift([
                        'Date',
                        'Model',
                        'Storage',
                        'VAT',
                        'IMEI',
                        'Sales Price',
                        'Codes before fix',
                        'Codes after fix',
                        'Comment',
                        'Spare Parts',
                        'Spare Parts Cost',
                        'Status',
                        'Repair Cost',
                    ])
                    const docDefinition = {
                        pageOrientation: 'landscape',
                        content: [
                            {
                                alignment: 'center',
                                table: {
                                    headerRows: 1,
                                    body,
                                    margin: [10, 10],
                                },
                            },
                        ],
                        styles: {
                            tableStyle: {
                                margin: [0, 20, 20, 0],
                            },
                        },
                    }
            // @ts-ignore
                    pdfMake.createPdf(docDefinition).download('InventoryPDF')
                } else {
                    toast.error('Error: No data found', {
                        hideProgressBar: true,
                    })
                }
            })
            .catch((error) =>
                toast.error(`Error: ${error.response.data.message}`, {
                    hideProgressBar: true,
                })
            )
    }, [calSparePartCost, endDate, event, startDate, token])

    const handleInputChange = (e) => {
        const { id, value } = e.target
        if (id === 'event') {
            setEvent(value)
        }
    }

    const handleUserRepairDownloadCSV = useCallback(async () => {
        await axios
            .post(
                `${baseUrl}/repair/repairtime`,
                {
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: moment(endDate).format('YYYY-MM-DD'),
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then((response) => {
                let reportData: ReportDataType[] = []
                for (const item of response?.data || []) {
                    for (const repair of item.repairs) {
                        const isExist = reportData?.find(
                            (report) =>
                                report?.userId === repair?.finishedBy?._id
                        )
                        if (isExist) {
                            const updatedReport = reportData.map((report) => {
                                const isExist =
                                    report.userId === repair?.finishedBy?._id

                                if (isExist) {
                                    return {
                                        user: report.user,
                                        userId: report.userId,
                                        items: [...report.items, item],
                                    }
                                } else {
                                    return report
                                }
                            })
                            reportData = updatedReport
                        } else {
                            reportData.push({
                                user: repair.finishedBy,
                                userId: repair.finishedBy?._id,
                                items: [item],
                            })
                        }
                    }
                }
                setReports(reportData)
                const csvData = []
                if (reportData.length > 0) {
                    // csvData.push({reportDateTime : `Users Repairs report from ${moment(
                    //   startDate
                    // ).format('"DD MMM YYYY"')} to ${moment(endDate).format(
                    //   '"DD MMM YYYY"'
                    //   )}`
                    // })
                    for (const report of reportData) {
                        let repairPoints = 0
                        for (const item of report.items) {
                            console.log(item)
                            csvData.push({
                                model: item?.model?.modelName || '',
                                storage: item?.storage || '',
                                vat: item?.vat || '',
                                imei: item?.imei || '',
                                RepairTime: getRepairTimePdf(item) || '',
                                FinishBy:
                                    moment(
                                        item?.repairs[item?.repairs?.length - 1]
                                            .finishAt
                                    ).format('DD MMM YYYY') || '',
                                Spareparts:
                                    item?.spareParts
                                        ?.map(
                                            ({ sparePart }) =>
                                                sparePart?.name || ''
                                        )
                                        .join(' ') || '',
                                Codes_before_fix:
                                    item?.codes
                                        ?.map((cd) => cd || ' ')
                                        .join(' ') || '',
                                Codes_after_fix:
                                    item?.codesAfterFix
                                        ?.map((cd) => cd || ' ')
                                        .join(' ') || '',
                                RepairPoint:
                                    item?.repairs[item?.repairs?.length - 1]
                                        ?.repairPoints || 0,
                            })
                            repairPoints +=
                                item?.repairs[item?.repairs?.length - 1]
                                    ?.repairPoints || 0
                        }
                        const arrangedData = []
                        arrangedData.push({
                            userData: `${report.user?.firstName} ${report.user
                                ?.lastName}, (${report?.user?.email}) ${
                                csvData.length - 1
                            } , ${repairPoints}`,
                            csvData,
                        })
                        csvData.push({
                            userData: `${report.user?.firstName} ${report.user
                                ?.lastName}, (${report?.user?.email}),${
                                csvData.length - 1
                            } , ${repairPoints}`,
                        })
                    }
                    let csv = ' '
                    for (let row = 0; row < csvData?.length; row++) {
                        const keysAmount = Object.keys(csvData[row]).length
                        let keysCounter = 0
                        if (row === 0) {
                            for (const key in csvData[row]) {
                                csv +=
                                    key +
                                    (keysCounter + 1 < keysAmount
                                        ? ','
                                        : '\r\n')
                                keysCounter++
                            }
                        }
                        keysCounter = 0
                        for (const key in csvData[row]) {
                            csv +=
                                csvData[row][key] +
                                (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                        }

                        keysCounter = 0
                    }
                    const data = new Blob([csv], { type: 'text/csv' })
                    saveCsv(data, 'repair.csv')
                } else {
                    toast.error('Error: No data found', {
                        hideProgressBar: true,
                    })
                }
            })
    }, [endDate, getRepairTimePdf, startDate, token])

    const getRepairPoints = (report) => {
        let repairPoints = 0;
    
        if (report && report.repairs) {
            for (const item of report.repairs) {
                repairPoints += item?.repairPoints || 0;
            }
        }
    
        return repairPoints;
    };
    

    const handleInventoryDownloadCSV = useCallback(async () => {
        await axios
            .post(
                `${baseUrl}/inventory/dates`,
                {
                    startDate,
                    endDate,
                    event,
                },
                {
                    headers: {
                        Authorization: token as string,
                    },
                }
            )
            .then((response) => {
                const responseItem = response.data
                const csvArray = []
                if (responseItem.length > 0) {
                    responseItem?.forEach((element) => {
                        csvArray.push({
                            model: element?.model?.modelName || '',
                            storage: element?.storage,
                            VAT: element?.vat,
                            imei: element?.imei,
                            unitPrice: element?.unitPrice,
                            codes_before_fix:
                                element?.codes
                                    ?.map((cd) => cd || ' ')
                                    .join(' ') || '',
                            codes_after_fix:
                                element?.codesAfterFix
                                    ?.map((cd) => cd || ' ')
                                    .join(' ') || '',
                            comment: element?.comment ==="undefiend" ? '' : element?.comment,
                            status: element?.status || '',
                            repairCost: element?.repairCost || '',
                            spareParts:
                                element?.spareParts
                                    ?.map(
                                        ({ sparePart }) => sparePart?.name || ''
                                    )
                                    .join(' ') || '',
                        })
                    })

                    let csv = ' '
                    for (let row = 0; row < csvArray?.length; row++) {
                        const keysAmount = Object.keys(csvArray[row]).length
                        let keysCounter = 0
                        if (row === 0) {
                            for (const key in csvArray[row]) {
                                csv +=
                                    key +
                                    (keysCounter + 1 < keysAmount
                                        ? ','
                                        : '\r\n')
                                keysCounter++
                            }
                        }
                        keysCounter = 0
                        for (const key in csvArray[row]) {
                            csv +=
                                csvArray[row][key] +
                                (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                        }
                        keysCounter = 0
                    }
                    const data = new Blob([csv], { type: 'text/csv' })
                    saveCsv(data, 'inventory.csv')
                } else {
                    toast.error('Error: No data found', {
                        hideProgressBar: true,
                    })
                }
            })
    }, [endDate, event, startDate, token])

    function saveCsv(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = filename
        a.click()
    }
    return (
        <>
            <TopHeader />
            <div className=" px-4 py-5 ">
                <div className="mr-5 flex justify-between">
                    <div>
                        <h1 className="font-bold text-xl ">Reports</h1>
                    </div>
                    <div className="flex ">
                        <div className=" px-3 mx-2">
                            <label className="w-32"> Start Date</label>
                            <DatePicker
                                placeholderText="Select start date"
                                closeOnScroll={false}
                                selected={startDate}
                                className="border-2 p-1 mt-1 rounded"
                                onChange={(date) => {
                                    setStartDate(date)
                                }}
                            />
                        </div>
                        <div className=" px-3 mx-2">
                            <label className="w-28"> End Date</label>
                            <DatePicker
                                placeholderText="Select end date"
                                closeOnScroll={false}
                                className="border-2 p-1 mt-1 rounded"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex mt-10 justify-end">
                    <div>
                        <SparepartsReports />
                    </div>
                    <div className="">
                        <button
                            onClick={handleDownload}
                            className="relative w-65 mr-2 mb-3 justify-right py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            Download user repair report PDF
                        </button>
                        <button
                            onClick={handleUserRepairDownloadCSV}
                            className="relative w-70 justify-right py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            Download user repair report CSV
                        </button>
                    </div>
                    <div
                        className="mx-10"
                        style={{ borderLeft: '1px solid silver' }}
                    ></div>
                    <div className="mr-5 text-gray-900 ">
                        <select
                            id="event"
                            onChange={(e) => handleInputChange(e)}
                            defaultValue="USD"
                            className="w-full text-gray-900 border hover:cursor-pointer text-sm rounded-lg focus:ring-pruple-500 focus:border-purple-500 py-2 px-2"
                        >
                            <option value="" className="bg-b9green1">
                                Stauts
                            </option>
                            <option value={Events.return}>
                                {Events.return}
                            </option>
                            <option value={Events.rma}>{Events.rma}</option>
                            <option value={Events.tounlock}>
                                {Events.tounlock}
                            </option>
                            <option value={Events.easyRepair}>
                                {Events.easyRepair}
                            </option>
                            <option value={Events.mediumRepair}>
                                {Events.mediumRepair}
                            </option>
                            <option value={Events.hardRepair}>
                                {Events.hardRepair}
                            </option>
                            <option value={Events.scrap}>{Events.scrap}</option>
                            <option value={Events.salesInventory}>
                                {Events.salesInventory}
                            </option>
                            <option value={Events.warranty}>
                                {Events.warranty}
                            </option>
                            <option value={Events.ms_repair}>
                                {Events.ms_repair}
                            </option>
                        </select>
                    </div>
                    <div className="mr-3">
                        <button
                            onClick={handleInventoryDownload}
                            className="relative mb-3  w-60 mr-2 justify-right py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            Download Inventory report PDF
                        </button>
                        <button
                            onClick={handleInventoryDownloadCSV}
                            className="relative  w-60 justify-right py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-b9green3 hover:bg-b9green4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            Download Inventory report CSV
                        </button>
                    </div>
                </div>
            </div>
            <div className="py-2 mx-3 inline-block min-w-ful mt-6">
                {reports?.map(({ userId, items }) => {
                    return (
                        <table key={userId} className="table-fixed text-center">
                            <thead className="bg-b9green2 border-b h-14">
                                <tr className="text-md font-bold">
                                    <th
                                        style={{
                                            width: 500,
                                        }}
                                        className="px-1"
                                    >
                                        Repaired by: {}
                                    </th>
                                    <th
                                        style={{
                                            width: 160,
                                        }}
                                        className="w-16 px-1"
                                    >
                                        Model
                                    </th>
                                    <th
                                        style={{
                                            width: 90,
                                        }}
                                        className="px-1"
                                    >
                                        Storage
                                    </th>
                                    <th
                                        style={{
                                            width: 80,
                                        }}
                                        className="px-1"
                                    >
                                        VAT
                                    </th>
                                    <th
                                        style={{
                                            width: 193,
                                        }}
                                        className="px-1"
                                    >
                                        Serial Number/ IMEI
                                    </th>
                                    <th
                                        style={{
                                            width: 193,
                                        }}
                                        className="px-1"
                                    >
                                        Repair time
                                    </th>
                                    <th
                                        style={{
                                            width: 193,
                                        }}
                                        className="px-1"
                                    >
                                        Finish At
                                    </th>
                                    <th
                                        style={{
                                            width: 193,
                                        }}
                                        className="px-1"
                                    >
                                        Codes before fix
                                    </th>
                                    <th
                                        style={{
                                            width: 193,
                                        }}
                                        className="px-1"
                                    >
                                        Codes after fix
                                    </th>
                                    <th
                                        style={{
                                            width: 193,
                                        }}
                                        className="px-1"
                                    >
                                        Repair Points
                                    </th>
                                </tr>
                            </thead>
                            {items?.map((report: any) => (
                                <tbody key={report?._id}>
                                    <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {report?.repairs?.map((repair) => {
                                                return (
                                                    <span key={repair?._id}>
                                                        {
                                                            repair?.finishedBy
                                                                ?.firstName
                                                        }{' '}
                                                        {
                                                            repair?.finishedBy
                                                                ?.lastName
                                                        }{' '}
                                                        {''}(
                                                        {
                                                            repair?.finishedBy
                                                                ?.email
                                                        }
                                                        )
                                                    </span>
                                                )
                                            })}
                                        </td>
                                        {/* <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                      {
                        <span key={report?.repairedBy?._id}>
                          {report?.repairedBy?.email}
                        </span>
                      }
                    </td> */}
                                        <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {report?.model?.modelName}
                                        </td>
                                        <td className="text-md w-12 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {report?.storage}
                                        </td>
                                        <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {report?.vat?.slice(0, 1)}
                                        </td>
                                        <td className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap">
                                            {report?.imei}
                                        </td>
                                        <td
                                            style={{
                                                width: 100,
                                            }}
                                            className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap truncate"
                                        >
                                            {getRepairTime(report)}
                                        </td>
                                        <td
                                            style={{
                                                width: 100,
                                            }}
                                            className="text-md w-16 text-gray-900 font-light px-1 py-4 whitespace-nowrap truncate"
                                        >
                                            <label>
                                                {moment(
                                                    report?.repairs[
                                                        report?.repairs
                                                            ?.length - 1
                                                    ]?.finishAt
                                                )?.format('DD MMM YYYY')}
                                            </label>
                                        </td>
                                        <td
                                            style={{
                                                width: 150,
                                            }}
                                            className="text-md  text-gray-900 font-light px-1 py-4 whitespace-nowrap truncate"
                                        >
                                            {report?.codes?.toString()}
                                        </td>
                                        <td
                                            style={{
                                                width: 150,
                                            }}
                                            className="text-md  text-gray-900 font-light px-1 py-4 whitespace-nowrap truncate"
                                        >
                                            {report?.codesAfterFix?.toString()}
                                        </td>
                                        <td
                                            style={{
                                                width: 150,
                                            }}
                                            className="text-md  text-gray-900 font-light px-1 py-4 whitespace-nowrap truncate"
                                        >
                                            {getRepairPoints(report)}
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    )
                })}
            </div>
        </>
    )
}
type ReportDataType = {
    userId: string
    user: { lastName: string; firstName: string; email: string }
    items: any[]
}
