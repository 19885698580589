import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { Column } from '../../../../layout/Col'
import { Flex } from '../../../../layout/Flex'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
import { Button } from '../../../../base/Button'
import { TextField } from '../../../../base/Textfield'
import { Text } from '../../../../base/Text'
import { Row } from '../../../../layout/Row'
import { FileUpload } from '../../../../shared/Fileupload'
import { RowSpaced } from '../../../../layout/RowSpaced'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../utils/auth'
import { useFetch } from '../../../../../hooks/useFetch'
import { Icon } from '../../../../base/Icon/Icon'
import { addNotification } from '../../../../shared/Notification/Notification'
import { downloadTemplate } from '../../../../../download/downloadService'
const baseUrl = process.env.REACT_APP_baseUrl

export const BulkSale = () => {
    const [customer, setCustomer] = useState<any>('')
    const [salesBatchNumber, setSalesBatchNumber] = useState<any>('')
    const [trackingNumber, setTrackingNumber] = useState<string>('')
    const [logisticCompany, setLogisticCompany] = useState<string>('')
    const [totalValue, setTotalValue] = useState<string>('')
    const [imei, setImei] = useState<string>('')
    const [rmaDuration, setRmaDuration] = useState<string>('')
    const [uploadedPdf, setUploadedPdf] = useState<File | null>(null);
    const [uploadedXlsx, setUploadedXlsx] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth()

    const token = auth?.user?.token?.token
    const company = auth.selectedCompany
    const { getRequest: getPartners, data: partnersData } = useFetch(
        `${baseUrl}/partner/get-partners`
    )
    useEffect(() => {
        getPartners()
    }, [getPartners])
    const filteredCustomerData = partnersData?.filter(partner => partner.tags.includes("customer"));
    const filteredLogisticData = partnersData?.filter(partner => partner.tags.includes("lsp"));

    const handleFileUploadPdf = (file: File) => {
        setUploadedPdf(file);
    }

    const handleFileUploadXlsx = (file: File) => {
        setUploadedXlsx(file);
    }

    const handleCustomerChange = (event: any) => {
        setCustomer(event.target.value)
    }

    const handlesalesBatchNumberChange = (newValue: any) => {
        setSalesBatchNumber(newValue)
    }

    const handleTrackingNumberChange = (newValue: any) => {
        setTrackingNumber(newValue)
    }

    const handleLogisticCompanyChange = (event: any) => {
        setLogisticCompany(event.target.value)
    }

    const handleTotalValueChange = (newValue: any) => {
        setTotalValue(newValue)
    }

    const handleRmaDurationChange = (newValue: any) => {
        setRmaDuration(newValue)
    }

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (customer === "") {
            alert("Add customer")
            return
        }
        if (salesBatchNumber === "") {
            alert("Add Sale branch number")
            return
        }
        if (trackingNumber === "") {
            alert("Add tracking number")
            return
        }
        if (totalValue === "") {
            alert("Add total value")
            return
        }
        if (uploadedPdf === null) {
            alert("Add invoice")
            return
        }
        if (uploadedXlsx === null) {
            alert("Add xlsx file")
            return
        }
        const formData = new FormData();
        formData.append('customer', customer);
        formData.append('salesBatchNumber', salesBatchNumber);
        formData.append('trackingNumber', trackingNumber);
        formData.append('logisticCompany', logisticCompany);
        formData.append('totalValue', totalValue);
        formData.append('imei', imei);
        formData.append('rmaDuration', rmaDuration);
        formData.append('saleType', 'Bulk sale');
        if (uploadedXlsx) {
            console.log(uploadedXlsx);
            formData.append('file', uploadedXlsx);
        }
        if (uploadedPdf) {
            formData.append('file', uploadedPdf);
        }
        setIsLoading(true)
        const urlItem = `${baseUrl}/purchase/add-bulk-Purchase-order`
        const config = {
            headers: {
                Authorization: token as string,
                'Content-Type': 'multipart/form-data',
                Company: company._id
            },
        }

        try {
            await axios.post(urlItem, formData, config)
            setIsLoading(false)
            toast.success('Added Successfully', { hideProgressBar: true })
            addNotification({
                message: 'Bulk sale has been added successfully',
                type: 'success',
            })
            setCustomer('')
            setImei('')
            setLogisticCompany('')
            setRmaDuration('')
            setTotalValue('')
            setTrackingNumber('')
            setSalesBatchNumber('')
            setUploadedPdf(null)
            setUploadedXlsx(null)
        } catch (error) {
            console.log(error)  
            setIsLoading(false)
            toast.error(`Error: ${error?.response?.data?.message}`, {
                hideProgressBar: true,
            })
        }
    }, [company._id, customer, imei, logisticCompany, rmaDuration, salesBatchNumber, token, totalValue, trackingNumber, uploadedPdf, uploadedXlsx])

    return (
        <PrimaryCard className="p-5">
            <RowSpaced className="gap-x-5">
                <Row className="mr-2 w-3/5">
                    <Column className="gap-y-5 w-full">
                        <RowAlignCenterSpaced className="">
                            <Text colorV="blue" variant="title" weightV="bold">
                                Customer
                            </Text>
                            <div style={{ width: '20.1rem' }}>
                                <select
                                    id="customer"
                                    value={customer}
                                    onChange={handleCustomerChange}
                                    className="w-full hover:cursor-pointer bg-white rounded-lg   py-2 px-1"                                >
                                    <option value="">Select</option>
                                    {filteredCustomerData?.map((partner: any) => (
                                        <option
                                            key={partner?._id}
                                            value={partner?._id}
                                        >
                                            {partner?.companyName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Sales batch number
                            </Text>
                            <TextField
                                name="salesBatchNumber"
                                value={salesBatchNumber}
                                onChange={handlesalesBatchNumberChange}
                                placeholder="Enter sales branch number"
                                backgroundColor='white'
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Assign my RMA duration
                            </Text>
                            <TextField
                                name="rmaDuration"
                                value={rmaDuration}
                                onChange={handleRmaDurationChange}
                                placeholder="Enter RMA duration"
                                backgroundColor='white'
                                
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Tracking number
                            </Text>
                            <TextField
                                name="trackingNumber"
                                value={trackingNumber}
                                onChange={handleTrackingNumberChange}
                                placeholder="Enter tracking number"
                                backgroundColor='white'
                                
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Logistic company
                            </Text>
                            <div style={{ width: '20.1rem' }}>
                                <select
                                    id="customer"
                                    value={logisticCompany}
                                    onChange={handleLogisticCompanyChange}
                                    className="w-full hover:cursor-pointer bg-white rounded-lg   py-2 px-1"                                >
                                    <option value="">Select</option>
                                    {filteredLogisticData?.map((partner: any) => (
                                        <option
                                            key={partner?._id}
                                            value={partner?._id}
                                        >
                                            {partner?.companyName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </RowAlignCenterSpaced>
                        <RowAlignCenterSpaced>
                            <Text colorV="blue" variant="title" weightV="bold">
                                Total value
                            </Text>
                            <TextField
                                name="totalValue"
                                value={totalValue}
                                onChange={handleTotalValueChange}
                                placeholder="Enter total value"
                                backgroundColor='white'
                                
                                width="321px"
                            />
                        </RowAlignCenterSpaced>
                        <Flex className="gap-x-4 mt-4">
                            <Button
                                onClick={handleSubmit}
                                backgroundColor="lgblue"
                            >
                                <Text
                                    colorV="white"
                                    variant="title"
                                    weightV="normal"
                                >
                                    {isLoading ? "Saving..." : "Fullfill & Save"}
                                </Text>
                            </Button>
                            <Button
                                onClick={() => downloadTemplate('bulkSale')}
                                backgroundColor="green"
                                className='flex flex-row items-center gap-2 text-white'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z" />
                                    <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
                                </svg>

                                Download template
                            </Button>
                        </Flex>
                    </Column>
                </Row>
                <Column className="w-2/5 gap-y-7">
                    <div>
                        <FileUpload
                            fileTypeDescription="invoice"
                            acceptedFileTypes=".pdf"
                            height="160px"
                            onFileUpload={handleFileUploadPdf}
                            backgroundColor="#FFFFFF"
                        />
                        {uploadedPdf && (
                            <Row className='gap-x-3 items-center'>
                                <Text colorV="blue" variant="title" weightV="normal">
                                    Uploaded Pdf: {uploadedPdf.name}
                                </Text>
                                <span className='cursor-pointer' onClick={() => setUploadedPdf(null)}>
                                    <Icon name="cross" color="black" size={16} />
                                </span>
                            </Row>
                        )}
                    </div>
                    <div>
                        <FileUpload
                            fileTypeDescription="Xlsx"
                            acceptedFileTypes=".xlsx"
                            height="160px"
                            onFileUpload={handleFileUploadXlsx}
                            backgroundColor="#FFFFFF"
                        />
                        {uploadedXlsx && (
                            <Row className='gap-x-3 items-center'>
                                <Text colorV="blue" variant="title" weightV="normal">
                                    Uploaded File: {uploadedXlsx.name}
                                </Text>
                                <span className='cursor-pointer' onClick={() => setUploadedXlsx(null)}>
                                    <Icon name="cross" color="black" size={16} />
                                </span>
                            </Row>
                        )}
                    </div>

                </Column>
            </RowSpaced>
        </PrimaryCard>
    )
}
