import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

interface BarChartProps {
    labels: string[];
    dataset: number[];
}

export const BarChart: React.FC<BarChartProps> = ({ labels, dataset }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const chartWidth = 400; // Set a specific width
        const chartHeight = 300; // Set a specific height

        chartRef.current.width = chartWidth;
        chartRef.current.height = chartHeight;
        const barColors = ['rgba(17, 45, 86, 1)', 'rgba(69, 148, 138, 1)'];
        chartInstanceRef.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    barPercentage: 0.5,
                    barThickness: 6,
                    maxBarThickness: 8,
                    minBarLength: 2,
                    data: dataset,
                    backgroundColor: barColors,
                }]
                // datasets: [
                    
                //     {
                //         label: 'Sale Orders',
                //         data: dataset,
                //         backgroundColor: 'rgba(17, 45, 86, 0.5)', // Adjust the color as needed
                //         borderColor: 'rgba(17, 45, 86, 1)',
                //         borderWidth: 1,
                //         barPercentage: 0.4, // Adjust the bar width
                //     },
                // ],
            },
            options: {
                scales: {
                  y: {
                    grid: {
                      display: false,
                  },
                    beginAtZero: true, // Force the y-axis to begin at zero
                  },
                  x: {
                    grid: {
                      display: false,
                  },
                    beginAtZero: true, // Force the y-axis to begin at zero
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              },
        });
    }, [labels, dataset]);

    return (
        <div style={{ width: '100%', height:"100%" }}>
            <canvas ref={chartRef}></canvas>
        </div>
    );
};
