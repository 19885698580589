export enum AccessEnums {
    Dashboard = 'dashboard',
    ManageModels = 'manage_models',
    ManageEvents = 'manage_events',
    ManualSortingLogic = 'manage_sorting_logic',
    ManageRepairPoints = 'manage_repair_points',
    ManagePartners = 'manage_partner',
    ManageSKU = 'manage_sku',
    Users = 'users',
    ManageTemplates = 'manage_template',
    InHouseInventory = 'in_house_inventory',
    SparePartsInventory = 'spare_parts_inventory',
    PurchaseOrders = 'purchase_orders',
    PointOfSale = 'point_of_sale',
    SalesInventory = 'sales_inventory',
    RMA = 'rma',
    RMACreditNotes = 'rma_credit_notes',
    MyRMA = 'my_rma',
    StocktakingAndApp = 'stocktaking',
    Script = 'script',
    StocktakingReport = 'stocktaking_report',
    ProductionEnvironment = 'production_environment',
    Repair = 'repair',
    Grading = 'grading',
    Diagnostics = 'diagnostics',
    ManualSorting = 'manual_sorting',
    Polishing = 'polishing',
    ViewInHouseInventory = 'view_in_house_inventory',
    EditInHouseInventory = 'edit_in_house_inventory',
    AddInHouseInventory = 'add_in_house_inventory',
    ViewSparePartsInventory = 'view_spare_parts_inventory',
    EditSparePartsInventory = 'edit_spare_parts_inventory',
    AddSparePartsInventory = 'add_spare_parts_inventory',
    ViewRMACreditNotes = 'view_rma_credit_notes',
    EditRMACreditNotes = 'edit_rma_credit_notes',
    ViewSalesInventory = 'view_sales_inventory',
    EditSalesInventory = 'edit_sales_inventory',
    Transit = 'transit'
}

export enum AccessPermissions {
    Admin = 'admin',
    View = 'view',
    Add = 'add',
    Edit = 'edit',
}

export const accessList = [
    {
        module: AccessEnums.Dashboard,
        permission: [],
    },
    {
        module: AccessEnums.ManageModels,
        permission: [],
    },
    {
        module: AccessEnums.ManageEvents,
        permission: [],
    },
    {
        module: AccessEnums.ManualSortingLogic,
        permission: [],
    },
    {
        module: AccessEnums.ManageRepairPoints,
        permission: [],
    },
    {
        module: AccessEnums.ManagePartners,
        permission: [],
    },
    {
        module: AccessEnums.ManageSKU,
        permission: [],
    },
    {
        module: AccessEnums.Users,
        permission: [],
    },
    {
        module: AccessEnums.ManageTemplates,
        permission: [],
    },
    {
        module: AccessEnums.InHouseInventory,
        permission: [],
    },
    {
        module: AccessEnums.SparePartsInventory,
        permission: [],
    },
    {
        module: AccessEnums.SalesInventory,
        permission: [],
    },
    {
        module: AccessEnums.PointOfSale,
        permission: [],
    },
    {
        module: AccessEnums.RMA,
        permission: [],
    },
    {
        module: AccessEnums.MyRMA,
        permission: [],
    },
    {
        module: AccessEnums.StocktakingAndApp,
        permission: [],
    },
    {
        module: AccessEnums.StocktakingReport,
        permission: [],
    },
    {
        module: AccessEnums.Script,
        permission: [],
    },
    {
        module: AccessEnums.Repair,
        permission: [],
    },
    {
        module: AccessEnums.Grading,
        permission: [],
    },
    {
        module: AccessEnums.Diagnostics,
        permission: [],
    },
    {
        module: AccessEnums.ManualSorting,
        permission: [],
    },
    {
        module: AccessEnums.Polishing,
        permission: [],
    },
    {
        module: AccessEnums.Transit,
        permission: [],
    },
]

export const management = [
    // {
    //         title: "Management",
    //         value: "management"
    // },
    {
        title: 'Dashboard & reports',
        value: AccessEnums.Dashboard,
    },
    {
        title: 'Manage models',
        value: AccessEnums.ManageModels,
    },
    {
        title: 'Manage events',
        value: AccessEnums.ManageEvents,
    },
    {
        title: 'Manage sorting logic',
        value: AccessEnums.ManualSortingLogic,
    },
    {
        title: 'Manage repair points',
        value: AccessEnums.ManageRepairPoints,
    },
    {
        title: 'Manage partner',
        value: AccessEnums.ManagePartners,
    },
    {
        title: 'Manage SKU',
        value: AccessEnums.ManageSKU,
    },
    {
        title: 'Users / access',
        value: AccessEnums.Users,
    },
    {
        title: 'Manage template',
        value: AccessEnums.ManageTemplates,
    },
]
export const inventory = [
    {
        title: 'View In-house inventory',
        subPermission: AccessPermissions.View,
        value: AccessEnums.ViewInHouseInventory,
    },
    {
        title: 'Add In-house inventory',
        subPermission: AccessPermissions.Add,
        value: AccessEnums.AddInHouseInventory,
    },
    {
        title: 'Edit In-house inventory',
        subPermission: AccessPermissions.Edit,
        value: AccessEnums.EditInHouseInventory,
    },
]

export const sparePartsInventory = [
    {
        title: 'View spare-parts inventory',
        subPermission: AccessPermissions.View,
        value: AccessEnums.ViewSparePartsInventory,
    },
    {
        title: 'Add spare-parts inventory',
        subPermission: AccessPermissions.Add,
        value: AccessEnums.AddSparePartsInventory,
    },
    {
        title: 'Edit spare-parts inventory',
        subPermission: AccessPermissions.Edit,
        value: AccessEnums.EditSparePartsInventory,
    },
]
export const rma = [
    {
        title: 'View RMA & Credit notes',
        subPermission: AccessPermissions.View,
        value: AccessEnums.ViewRMACreditNotes,
    },
    {
        title: 'Edit RMA & Credit notes',
        subPermission: AccessPermissions.Edit,
        value: AccessEnums.EditRMACreditNotes,
    },
]

export const sales = [
    {
        title: "View sales inventory",
        subPermission: AccessPermissions.View,
        value: AccessEnums.ViewSalesInventory,

    },
    {
        title: "Edit sales inventory",
        subPermission: AccessPermissions.Edit,
        value: AccessEnums.EditSalesInventory,

    },
]

export const myRma = [
    {
        title: 'My RMA',
        value: AccessEnums.MyRMA,
    },
]

export const stocktaking = [
    {
        title: 'Stocktaking + App',
        value: AccessEnums.StocktakingAndApp,
    },
    {
        title: 'Script',
        value: AccessEnums.Script,
    },
    {
        title: 'Stock taking report',
        value: AccessEnums.StocktakingReport,
    },
]

export const production = [
    {
        title: 'Repair',
        value: AccessEnums.Repair,
    },
    {
        title: 'Grading',
        value: AccessEnums.Grading,
    },
    {
        title: 'Diagnostics',
        value: AccessEnums.Diagnostics,
    },
    {
        title: 'Manual Sorting',
        value: AccessEnums.ManualSorting,
    },
    {
        title: 'Polishing',
        value: AccessEnums.Polishing,
    },
    {
        title: 'Transit',
        value: AccessEnums.Transit,
    },
]
