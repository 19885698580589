import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import arrowIcon from '../../../assets/Icons/arrow.svg';  // Use the same icon for both states

const MultiSelect = ({ options, onChange, isMulti, placeholder, defaultValues }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(defaultValues || []);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedValues((prevSelected) => {
      if (isMulti) {
        const isSelected = prevSelected.includes(option.value);
        const newSelectedValues = isSelected
          ? prevSelected.filter((item) => item !== option.value)
          : [...prevSelected, option.value];
        onChange(newSelectedValues);
        return newSelectedValues;
      } else {
        onChange([option.value]);
        return [option.value];
      }
    });
    if (!isMulti) {
      setIsOpen(false);
    }
  };

  const handleDeselect = (value) => {
    setSelectedValues((prevSelected) => {
      const newSelectedValues = prevSelected.filter((item) => item !== value);
      onChange(newSelectedValues);
      return newSelectedValues;
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const filteredOptions = options?.filter((option) =>
    option.label.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative inline-block w-full" ref={dropdownRef}>
      <div
        className="cursor-pointer border border-gray-300 rounded p-1 flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <div className="flex-1 flex flex-wrap gap-1">
          {selectedValues?.length > 0 ? (
            selectedValues?.map((value) => (
              <div
                key={value}
                className="bg-blue-100 text-blue-700 text-[14px] rounded px-2 py-1 flex items-center"
              >
                {options.find((option) => option.value === value)?.label}
                <button
                  className="ml-2 text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeselect(value);
                  }}
                >
                  &times;
                </button>
              </div>
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>
        <div className={`text-gray-500 ${isOpen ? 'rotate-180' : ''}`}>
          <img src={arrowIcon} alt="Toggle" className="w-[9px] h-2 transition-transform duration-300" />
        </div>
      </div>
      {isOpen && (
        <div
          style={{ zIndex: 9999 }}
          className="absolute w-full mt-1 bg-white border border-gray-300 rounded shadow-lg max-h-40 overflow-y-auto"
        >
          <input
            type="text"
            className="w-full p-2 border-b border-gray-300"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={inputRef}
          />
          {filteredOptions.map((option) => (
            <div
              key={option.value}
              className={`cursor-pointer p-2 ${selectedValues.includes(option.value)
                ? 'bg-blue-500 text-white'
                : 'hover:bg-gray-200'
                }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValues: PropTypes.array,
};

MultiSelect.defaultProps = {
  isMulti: false,
  placeholder: 'Select...',
  defaultValues: [],
};

export default MultiSelect;
