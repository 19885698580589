import { useEffect, useState } from 'react'
import { useFetch } from '../../../../../hooks/useFetch'
import { Button } from '../../../../base/Button'
import { ModalBox } from '../../../../base/ModelBox'; // Updated import
import { Text } from '../../../../base/Text'
import { Column } from '../../../../layout/Col'
import { Table } from '../../../../shared/Table'
import { PrimaryCard } from '../../../Page/PrimaryCard'
import { AddRepairScore } from '../DialougeBoxes/Add/AddRepairScore'; // Replace with your actual AddRepairScore component import
import { DeleteRepairScore } from '../DialougeBoxes/Delete/DeleteRepairScore'
import { EditRepairScore } from '../DialougeBoxes/Edit/EditRepairScore'
import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced';
const baseUrl = process.env.REACT_APP_baseUrl

export const RepairScore = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [selectedPoint, setSelectedPoint] = useState<any>()

    const { getRequest: getPoints, data: pointsData } = useFetch(
        `${baseUrl}/codePoint/get-code-points`
    )
    console.log(pointsData)
    useEffect(() => {
        getPoints()
    }, [getPoints])

    const openModal = (option) => {
        setSelectedOption(option)
        if (option === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (option === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedOption(null)
        setIsEdit(false)
        setIsDelete(false)
        getPoints()
    }

    let extractedData = []
    if (pointsData) {
        extractedData = pointsData?.map((item) => {
            return {
                id: item?._id,
                Code: item?.code?.label,
                Score: item?.point,
                Model: item?.models?.map((model) => model.modelName).join(', '),
            }
        })
    }
    const columns = ['Code', 'Score', 'Model']

    const handleSelectChange = (optionValue, item) => {
        setSelectedPoint(item)
        setSelectedOption(optionValue)
        if (optionValue === 'Edit') {
            setIsEdit(true)
            setIsDelete(false)
        } else if (optionValue === 'Delete') {
            setIsDelete(true)
            setIsEdit(false)
        } else {
            setIsEdit(false)
            setIsDelete(false)
        }
        setIsModalOpen(true)
    }

    const renderModalComponent = () => {
        if (isEdit) {
            return <EditRepairScore item={selectedPoint} onClose={closeModal} />
        } else if (isDelete) {
            return (
                <DeleteRepairScore item={selectedPoint} onClose={closeModal} />
            )
        } else {
            return <AddRepairScore onClose={closeModal} />
        }
    }

    return (
        <>
            <Column className='w-full'>
                <RowAlignCenterSpaced className="mb-5">
                    <Text
                        colorV="blue"
                        variant="head"
                        weightV="bold"
                    >
                        Manage repair score
                    </Text>
                    <Button
                        backgroundColor="lgblue"
                        onClick={() => openModal('Add Repair Score')} // Updated option value
                    >
                        <Text colorV="white" variant="title" weightV="normal">
                            Add Repair Score
                        </Text>
                    </Button>
                </RowAlignCenterSpaced>
                <PrimaryCard className='w-full'>
                    <Table
                        handleDropdownChange={handleSelectChange}
                        columns={columns}
                        options={['Edit', 'Delete']}
                        data={extractedData}
                        showActions={true}
                    />
                </PrimaryCard>
                {isModalOpen && (
                    renderModalComponent()
                )}
            </Column>
        </>
    )
}
