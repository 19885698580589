import React, { useState } from 'react'
import { Flex } from '../../../layout/Flex'
import { Text } from '../../../base/Text'
import { Button } from '../../../base/Button'
import { Column } from '../../../layout/Col'
import { RowAlignCenterSpaced } from '../../../layout/RowAlignCenterSpaced'
import { Row } from '../../../layout/Row'
import { Received } from './Received/Received'
import { Complete } from './Complete/Complete'

export default function Head() {
    const [activeTab, setActiveTab] = useState('Received')

    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
    }

    return (
        <Flex className="py-5">
            <Column className='w-full'>
                <Row className="gap-x-5">
                    <Text
                         style={{ width: '100px', marginRight:'20px' }}
                         colorV="blue"
                         variant="head"
                         weightV="normal"
                    >
                        {activeTab}
                    </Text>
                    <Button
                        onClick={() => handleTabClick('Received')}
                        backgroundColor={
                            activeTab === 'Received' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Received'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={activeTab === 'Received' ? 'white' : 'blue'}
                            variant="title"
                            weightV="normal"
                        >
                            Received
                        </Text>
                    </Button>
                    <Button
                        onClick={() => handleTabClick('Complete')}
                        backgroundColor={
                            activeTab === 'Complete' ? 'blue' : 'grey'
                        }
                        className={
                            activeTab === 'Complete'
                                ? 'active-button'
                                : 'inactive-button'
                        }
                    >
                        <Text
                            colorV={activeTab === 'Complete' ? 'white' : 'blue'}
                            variant="title"
                            weightV="normal"
                        >
                            Complete
                        </Text>
                    </Button>
                    
                </Row>
                <RowAlignCenterSpaced className="mt-5">
                    {activeTab === 'Received' && <Received />}
                    {activeTab === 'Complete' && <Complete />}
                </RowAlignCenterSpaced>
            </Column>
        </Flex>
    )
}
