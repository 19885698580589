    import React, { useEffect, useState } from 'react'
    import axios from 'axios'
    import { toast } from 'react-toastify'
    import { Button } from '../../../../base/Button'
    import { ModalBox } from '../../../../base/ModelBox'
    import { Column } from '../../../../layout/Col'
    import { Flex } from '../../../../layout/Flex'
    import { RowAlignCenterSpaced } from '../../../../layout/RowAlignCenterSpaced'
    import { TextBackground } from '../../../../base/TextBackground'
    import { Text } from '../../../../base/Text'
    import { useAuth } from '../../../../../utils/auth'
    import { TextArea } from '../../../../base/Textarea'
import { useEdit } from '../../../../../hooks/useEdit' 
import { addNotification } from '../../../../shared/Notification/Notification'

    const baseUrl = process.env.REACT_APP_baseUrl

    export const ReportRMA: React.FC<{ onClose: () => void; item: any; data:any, setClaimed:any }> = ({
        onClose,
        item,
        data,
        setClaimed
    }) => {
        const [reasonOfReturn, setReasonOfReturn] = useState('')
        const [isLoading, setIsLoading] = useState(false)
        const { sendEditRequest } = useEdit()
        const handleReasonOfReturn = (newValue: any) => {
            setReasonOfReturn(newValue)
        }
        const handleSave = async (e: any) => {
            e.preventDefault()
            if (!reasonOfReturn) {
                alert('Please, enter reason of return')
                return
            }
            setIsLoading(true)
            await sendEditRequest(
                    `${baseUrl}/purchase/edit-purchase-item-report-rma/${item?._id}`,
                    {
                        reasonOfReturn,
                    },
                )
                .then((response) => {
                    console.log(response)
                    onClose()
                    setIsLoading(false)
                    addNotification({
                        message: 'RMA has been reported successfully',
                        type: 'success',
                    })
                    toast.success(response?.data?.message, { hideProgressBar: true })
                    setClaimed(true)
                })
                .catch((error) =>
                    toast.error(error.response.data.message, {
                        hideProgressBar: true,
                    })
                )
        }
        return (
            <ModalBox
                heading={item?.imei}
                onClose={onClose}
                top="50%"
                height="auto"
                width="500px"
            >
                <Column className="mt-3 gap-y-4 h-[350px] overflow-auto">
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" weightV="bold" variant="title">
                            Model
                        </Text>
                        <TextBackground backgroundColor="b9white" className="w-56">
                            <Text colorV="blue" weightV="normal" variant="title">
                                {item?.model?.modelName}
                            </Text>
                        </TextBackground>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" weightV="bold" variant="title">
                            Storage
                        </Text>
                        <TextBackground backgroundColor="b9white" className="w-56">
                            <Text colorV="blue" weightV="normal" variant="title">
                                {item?.storage}
                            </Text>
                        </TextBackground>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" weightV="bold" variant="title">
                            VAT
                        </Text>
                        <TextBackground backgroundColor="b9white" className="w-56">
                            <Text colorV="blue" weightV="normal" variant="title">
                                {item?.vat}
                            </Text>
                        </TextBackground>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="body" weightV="bold">
                            Sale Price
                        </Text>
                        <TextBackground backgroundColor="b9white" className="w-56">
                            <Text colorV="blue" weightV="normal" variant="title">
                            {item?.salePrice}
                            </Text>
                        </TextBackground>
                    </RowAlignCenterSpaced>
                    <RowAlignCenterSpaced>
                        <Text colorV="blue" variant="body" weightV="bold">
                            Reason for return
                        </Text>
                        <TextArea
                            className='w-56'
                            value={reasonOfReturn}
                            onChange={handleReasonOfReturn}
                            placeholder="Enter reason of return"
                            height="100px" // You can adjust the height here
                        />
                    </RowAlignCenterSpaced>
                    <Flex className="gap-x-4 mt-4">
                        <Button onClick={handleSave} className='w-40' backgroundColor="lgblue">
                            <Text colorV="white" variant="title" weightV="normal">
                               { isLoading ? "Saving" :  "Save"}
                            </Text>
                        </Button>
                    </Flex>
                </Column>
            </ModalBox>
        )
    }
