import React, { useState } from 'react';
import { Table } from '../../../../../shared/Table';
import { PrimaryCard } from '../../../../Page/PrimaryCard';
import { RenameEvent } from './Modals/RenameEvent';
import { Text } from '../../../../../base/Text';
import { Column } from '../../../../../layout/Col';

export const InventoryEvent = ({ sortingLogic, refetch, eventTypes }: { sortingLogic: any[], refetch: () => void, eventTypes: any[] }) => {
    const columns = ['Stauts Name', 'Status Type'];
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<any>(null);

    const closeModal = () => {
        setSelectedEvent(null);
        setModalOpen(false);
        refetch()
    };

    const filteredData = sortingLogic?.filter((item) => item.module === 'inventory');
    const data = filteredData?.map((item) => ({
        key: item.key,
        'Status Name': item.name,
        'Status Type': item.type,
    }));

    const handleSelectChange = (option, item) => {
        setSelectedEvent(item);
        setModalOpen(true);
    }

    return (
        <PrimaryCard className="w-full">
            <Column className='gap-y-4'>
                <Text
                    colorV="blue"
                    variant="heading"
                    weightV="bold"
                >
                    Inventory
                </Text>
                <Table
                    options={['Rename']}
                    columns={columns}
                    data={data}
                    showActions={true}
                    handleDropdownChange={handleSelectChange}
                />
            </Column>

            {isModalOpen && (
                <RenameEvent
                    eventTypes={eventTypes}
                    onClose={closeModal}
                    eventData={selectedEvent}
                />
            )}
        </PrimaryCard>
    );
};
