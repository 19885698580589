import { useEffect, useState } from "react";
import { Notification_data, clearNotifications, getNotifications } from "./Notification";

interface NotificationDialogProps {
    /* eslint-disable react/prop-types */
    onClose?: () => void;
}

export const NotificationDialog: React.FC<NotificationDialogProps> = ({ onClose }) => {
    const [notifications, setNotifications] = useState<Notification_data[]>([]);

    useEffect(() => {
        const loadNotifications = () => {
            const notifications_data = getNotifications() as Notification_data[];
            setNotifications(notifications_data);
        };
        loadNotifications();
    }, []);

    const removeItem = (index: number) => {
        const notifications_data = getNotifications() as Notification_data[];
        notifications_data.splice(index, 1);
        localStorage.setItem('notifications', JSON.stringify(notifications_data));
        setNotifications(notifications_data);
    };
    
    const clearNotificationsList = () => {
        clearNotifications();
        setNotifications([]);
    }

    return (
        <div className="top-0 left-0 fixed z-50 w-full h-full flex flex-col items-center justify-center ">
            <button className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-40 cursor-default" onClick={onClose}>

            </button>
            <div className="z-20 bg-white flex flex-col p-4 rounded-md w-full max-w-xl aspect-video">
                <header className="flex flex-row items-center">
                    <p className="text-lg font-bold text-zinc-700">Notifications</p>
                    <button onClick={clearNotificationsList} className="ml-auto py-1.5 px-3 hover:bg-slate-50 rounded-md text-sm  flex flex-row items-center gap-1 text-zinc-600 hover:text-zinc-800 font-semibold">
                        Clear
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                            <path d="M2 3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3Z" />
                            <path fillRule="evenodd" d="M13 6H3v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V6ZM5.72 7.47a.75.75 0 0 1 1.06 0L8 8.69l1.22-1.22a.75.75 0 1 1 1.06 1.06L9.06 9.75l1.22 1.22a.75.75 0 1 1-1.06 1.06L8 10.81l-1.22 1.22a.75.75 0 0 1-1.06-1.06l1.22-1.22-1.22-1.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                        </svg>

                    </button>
                    <button onClick={onClose} className="py-1.5 px-3 hover:bg-slate-50 rounded-md text-sm flex flex-row items-center gap-1 text-zinc-600 hover:text-zinc-800 font-semibold">
                        Close
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                            <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                        </svg>

                    </button>
                </header>
                <p className="text-slate-500 text-xs -mt-1">There is total {notifications.length}</p>
                <div className="flex flex-col overflow-y-auto text-sm bg-slate-50 h-full">
                    {notifications.map((notification, notificationIndex) => (
                        <div key={notificationIndex} className={`
                            hover:bg-slate-100 
                            p-2 
                            cursor-default 
                            relative 
                            group/not
                            ${notification.type === "info" ? 'border-l-zinc-500 border-l-2' : ''}
                            ${notification.type === "success" ? 'border-l-green-500 border-l-2' : ''}
                            ${notification.type === "error" ? 'border-l-red border-l-2' : ''}
                            ${notification.type === "warning" ? 'border-l-yellow-500 border-l-2' : ''}

                            ${notificationIndex === notifications.length - 1 ? '' : 'border-b border-slate-200'}
                        `}>
                            {notification.message}
                            <div className="top-0 left-0 absolute w-full h-full flex flex-row items-center hover:opacity-100 opacity-0 duration-150 ease-in-out">
                                <button className="ml-auto w-5 h-5" onClick={() => removeItem(notificationIndex)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="size-4 duration-300 ease-in-out text-slate-500 hover:text-red">
                                        <path fillRule="evenodd" d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))}
                    {notifications.length === 0 && 
                        <div className="text-center text-gray-500 h-full flex flex-row items-center justify-center rounded-md font-semibold gap-1">
                            No notifications
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 0 0-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634Zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 0 1-.189-.866c0-.298.059-.605.189-.866Zm-4.34 7.964a.75.75 0 0 1-1.061-1.06 5.236 5.236 0 0 1 3.73-1.538 5.236 5.236 0 0 1 3.695 1.538.75.75 0 1 1-1.061 1.06 3.736 3.736 0 0 0-2.639-1.098 3.736 3.736 0 0 0-2.664 1.098Z" clipRule="evenodd" />
                            </svg>

                        </div>
                    }
                </div>
            </div>
        </div>
    );
};